/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientMedicalDatasetEntity = new DocumentEntity(
  'PatientMedicalDataset',
  'patientMedicalDatasets.xml',
  'Contient les informations médicales des patients',
);

patientMedicalDatasetEntity.addField(new DocumentEntityField('bloodType', 'Le groupe sanguin (A+ | A- | B+ | B- | AB+ | AB- | O+ | O-)'));

export default patientMedicalDatasetEntity;