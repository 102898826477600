export default class ActionMenuItem {
  /**
   * Représente une action de menu
   * @param {String} icon L'icône de l'action
   * @param {String} text Le texte de l'action
   * @param {Function} callback La méthode exécutée par l'action
   * @param {Boolean} options.important Indique qu'il s’agit d'une action importante
   * @param {Boolean} options.disabled Désactive l'action
   * @param {Boolean} options.disabledTooltip Tooltip sur l'action désactivée
   * @param {Boolean} options.loading Met l'action dans un état de chargement
   */

  constructor (icon, text, callback, options = {}) {
    this.icon = icon;
    this.text = text;
    this.callback = callback;
    this.children = options.children || null;
    this.subtitle = options.subtitle || null;
    this.important = options.important || false;
    this.disabled = options.disabled || false;
    this.disabledTooltip = options.disabledTooltip || false;
    this.loading = options.loading || false;
  }
}