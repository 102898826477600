
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService';
import { getMssMessageForDocument } from '@/modules/secureMessaging/modules/messaging/utils/getMssMessageForDocument';
import NovaTools from '@/nova-tools/NovaTools';

import SendMessageModalForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageModalForm.vue';

/**
 * Ouvre une modale d'envoi de message avec en pièce jointe un fichier importé, un document de consultation ou une ordonnance
 * @param document un document de consultation (PatientDocument, ConsultationDocument ou Prescription)
 * @param {object} options les options de l'ActionMenuItem (@see /components/ui/actionsMenu/classes/ActionMenuItem.js)
 */
export default class DocumentMessagingSendingAction extends ActionMenuItem {
  constructor (document, options) {
    if (! document) {
      throw new Error('Un document est requis pour cette action');
    }
    super('messaging-transfert', 'Envoyer par messagerie', async () => {
      if (! AuthenticationServiceInstance.isAuthenticated) {
        NovaTools.notify.warning('Veuillez vous connecter au module de messagerie');
        return;
      }
      const mssMessage = await getMssMessageForDocument(document);
      const { id } = NovaTools.modal.append(SendMessageModalForm, {
        title: 'Envoyer le document',
        message: mssMessage,
        isOpen: true,
        patient: document.patient,
      }, {
        cancel: () => NovaTools.modal.destroy(id),
        submitSuccess: () => NovaTools.modal.destroy(id),
        'update:is-open': () => NovaTools.modal.destroy(id),
      });
    }, { ...options });
  }
}