export const CONTACTS_TYPES = Object.freeze({
  MEDICAL: {
    value: 'medical',
    label: 'Médical',
    icon: 'user-md',
  },
  PARAMEDICAL: {
    value: 'paramedical',
    label: 'Paramédical',
    icon: 'user-nurse',
  },
  OTHER: {
    value: 'other',
    label: 'Autre',
    icon: 'user',
  },
});