<template>
  <div
    class="messaging-document-bio-result-value-tag"
    :class="wrapperClassName"
  >
    <p>{{ bioResult.values[selectedUnitIndex].value }}</p>
    <app-tag
      v-if="isCritical || ! isInRange"
      label
      x-small
      :color="tagProps.color"
    >
      {{ tagProps.text }}
    </app-tag>
  </div>
</template>

<script>
import BioResultTableItem from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/classes/BioResultTableItem';

import AppTag from '@/components/ui/tag/AppTag.vue';


const CRITICALITY_TYPES = {
  HH: {
    value: 'hh',
    text: 'Critique haut',
  },
  LL: {
    value: 'll',
    text: 'Critique bas',
  },
  AA: {
    value: 'aa',
    text: 'Critique anormal',
  },
};

const CRITICAL_COLOR = 'error';
const DISCREPANCY_COLOR = 'warning';
const DISCREPANCY_TEXT = 'Écart';

export default {
  name: 'BioResultListValueTag',
  components: { AppTag },
  props: {
    bioResult: {
      type: BioResultTableItem,
      required: true,
    },
    selectedUnitIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isCritical () {
      return Object.values(CRITICALITY_TYPES).some(({ value }) => value.toUpperCase() === this.bioResult.interpretationCode?.toUpperCase());
    },
    isInRange () {
      const bioResultValue = parseFloat(this.bioResult.values[0].value);
      const rangeMin = parseFloat(this.bioResult.values[0].range.low);
      const rangeMax = parseFloat(this.bioResult.values[0].range.high);
      return (bioResultValue > rangeMin) && (bioResultValue < rangeMax);
    },
    wrapperClassName () {
      return {
        [`${CRITICAL_COLOR}--text`]: this.isCritical,
        [`${DISCREPANCY_COLOR}--text`]: ! this.isInRange && ! this.isCritical,
      };
    },
    tagProps () {
      if (this.isCritical) {
        return {
          text: CRITICALITY_TYPES[this.bioResult.interpretationCode.toUpperCase()].text,
          color: CRITICAL_COLOR,
        };
      }
      return {
        text: DISCREPANCY_TEXT,
        color: DISCREPANCY_COLOR,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.messaging-document-bio-result-value-tag {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 2);
}
</style>