<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    slim
    :rules="rules"
  >
    <app-buttons-group
      ref="genderInput"
      v-model="localValue"
      :items="gendersItems"
      :show-icon="false"
      :error-messages="errors"
      item-text="label"
      v-bind="{...$props, ...$attrs}"
      :label="fieldLabel"
      v-on="{...$listeners}"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import AppButtonsGroup from '../buttonsGroup/AppButtonsGroup.vue';
import { GENDERS } from '@/constants';
import fieldMixin from '@/mixins/fields';
import { getRefAsArray } from '@/utils/functions/refs';

export default {
  name: 'AppGenderPicker',
  components: {
    ValidationProvider,
    AppButtonsGroup,
  },
  mixins: [fieldMixin],
  props: {
    /**
     * Nom du champ
     */
    label: {
      type: String,
      default: 'Sexe',
    },
  },
  computed: {
    gendersItems () {
      return getRefAsArray(GENDERS);
    },
  },
};
</script>