/* eslint-disable max-len */
import { GENDERS } from '@/constants';

const checkTempNir = (nir, gender) => {
  /**
   * [78] 7 pour les hommes ou 8 pour les femmes les personnes en cours d'immatriculation en France
   * [0-9]{14} 14 digits non contrôlés
   */
  let temporaryNirRegex;
  switch (gender) {
    case GENDERS.MALE.value:
      temporaryNirRegex = /^7([0-9]{14})$/gui;
      break;
    case GENDERS.FEMALE.value:
      temporaryNirRegex = /^8([0-9]{14})$/gui;
      break;
    case GENDERS.UNKNOWN.value:
      temporaryNirRegex = /^[78]([0-9]{14})$/gui;
      break;
    default:
      temporaryNirRegex = /^[78]([0-9]{14})$/gui;
      break;
  }
  return temporaryNirRegex.test(nir);
};

/**
 * Permet de controler la clé d'un NIR
 * @param {String} nir le NIR à tester
 * @returns {Boolean} L'état de clé
 */
export const isKeyValid = (nir) => {
  const GREATEST_PRIME_NUMBER_LESS_THAN_ONE_HUNDRED = 97;
  const SOCIAL_SECURITY_NUMBER_LENGTH = 13;
  const KEY_LENGTH = 2;
  // Vérifie que la clé fournie correspond à celle calculée à partir du NIR renseigné
  // Pour les assurés Corse, le couple 2A prend la valeur 19 et le couple 2B la valeur 18. (CDC/Annexe2/R6bis)
  nir = nir.toUpperCase().replace('2A', '19').replace('2B', '18');
  const socialSecurityNumber = parseInt(nir.substring(0, SOCIAL_SECURITY_NUMBER_LENGTH), 10);
  const socialSecurityKey = parseInt(nir.slice(-KEY_LENGTH), 10);
  return GREATEST_PRIME_NUMBER_LESS_THAN_ONE_HUNDRED - (socialSecurityNumber % GREATEST_PRIME_NUMBER_LESS_THAN_ONE_HUNDRED) === socialSecurityKey;
};

const checkValidUsualNir = (nir, gender) => {
  /**
   * [1-4] sexe sur un caractère : 1 pour les hommes, 2 pour les femmes, 3, 4
   * [0-9]{2} année de naissance sur deux caractères : de 00 à 99
   * (0[1-9]|[235-9][0-9]|1[0-29]|4[0-2]) mois de naissance sur deux caractères
   * (0[1-9]|[1-9][0-9]|2[ab]) département de naissance sur deux caractères
   * (00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}){2} commune de naissance sur trois caractères
   * (0[1-9]|[1-8][0-9]|9[0-7]) numéro d’ordre dans le mois de naissance sur trois caractères
   */
  let regex = null;
  switch (gender) {
    case GENDERS.MALE.value:
      regex = /^[134][0-9]{2}(0[1-9]|[235-9][0-9]|1[0-29]|4[0-2])(0[1-9]|[1-9][0-9]|2[ab])(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}){2}(0[1-9]|[1-8][0-9]|9[0-7])/gui;
      break;
    case GENDERS.FEMALE.value:
      regex = /^[234][0-9]{2}(0[1-9]|[235-9][0-9]|1[0-29]|4[0-2])(0[1-9]|[1-9][0-9]|2[ab])(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}){2}(0[1-9]|[1-8][0-9]|9[0-7])/gui;
      break;
    case GENDERS.UNKNOWN.value:
      regex = /^[1-4][0-9]{2}(0[1-9]|[235-9][0-9]|1[0-29]|4[0-2])(0[1-9]|[1-9][0-9]|2[ab])(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}){2}(0[1-9]|[1-8][0-9]|9[0-7])$/gui;
      break;
    default:
      regex = /^[1-4][0-9]{2}(0[1-9]|[235-9][0-9]|1[0-29]|4[0-2])(0[1-9]|[1-9][0-9]|2[ab])(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}){2}(0[1-9]|[1-8][0-9]|9[0-7])$/gui;
      break;
  }
  return regex.test(nir) && isKeyValid(nir);
};

/**
 * Permet de vérifier la validité d'un NIR
 * @param {String} nir le NIR à tester
 * @param {String} gender le sexe à valider si NIR commençant par 1, 2, 7 ou 8
 * @returns {Boolean} L'état de validité
 */
export const isValidNir = (nir, gender) => checkValidUsualNir(nir, gender) || checkTempNir(nir, gender);
