<template>
  <!--
   Est déclenché lorsque le bouton est cliqué
   @event click
   -->
  <v-btn
    v-test="'v-btn'"
    class="n-button px-4"
    :color="bgColor"
    rounded
    depressed
    :x-small="xSmall"
    :small="small"
    :large="large"
    :text="transparent"
    :loading="loading"
    :outlined="outlined"
    :disabled="disabled"
    :icon="isButtonIcon && !keepColorStyleWhenNoLabel"
    @click="$emit('click',$event)"
  >
    <app-icon
      v-if="icon"
      :icon="icon"
      :class="{ 'mr-2': ! isButtonIcon }"
    />
    {{ label }}
  </v-btn>
</template>

<script>
import AppIcon from '@/components/ui/icon/AppIcon.vue';

/**
 * Composant bouton
 */
export default {
  name: 'NButton',
  components: { AppIcon },
  props: {
    /**
     * Le texte à afficher dans le bouton
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * L'icone à afficher dans le bouton
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * N'affiche pas de fond pour le bouton
     */
    transparent: {
      type: Boolean,
      default: false,
    },
    /**
     * N'affiche que les contours du boutons
     * @default false
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Modifie la taille globale du bouton
     * @values small, default, large
     */
    size: {
      type: String,
      default: 'default',
      validator: size => ['xSmall', 'small', 'default', 'large'].includes(size),
    },
    /**
     * La couleur de fond du bouton
     */
    bgColor: {
      type: String,
      default: null,
    },
    /**
     * Désactive le bouton
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche un spinner au milieu du bouton tout en désactivant toute interaction avec ce dernier
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Détermine si on force le même style de coloration pour le bouton qu'il ait un label ou non
     */
    keepColorStyleWhenNoLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isButtonIcon () {
      return this.icon && ! this.label;
    },
    xSmall () {
      return this.size === 'xSmall';
    },
    small () {
      return this.size === 'small';
    },
    large () {
      return this.size === 'large';
    },
  },
};
</script>

<style scoped lang="scss">
.n-button {
  background-color: transparent !important;
}
</style>