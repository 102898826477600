<template>
  <validation-provider
    v-slot="{ errors }"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-select
      ref="input"
      v-model="localValue"
      v-bind="{ ...$props, ...$attrs }"
      :rules="[]"
      :label="fieldLabel"
      :error="errors.length > 0"
      :error-messages="errors"
      :hide-details="hideErrors"
      :items="items"
      :item-value="itemValue"
      :item-text="itemText"
      :return-object="returnObject"
      data-test="app-select"
      :prepend-icon="fieldIcon.prepend ? fieldIcon.prepend : null"
      @click:clear="handleClickClear"
      @change="$emit('change',$event)"
    >
      <template
        v-if="$scopedSlots.item"
        #item="{ item }"
      >
        <slot
          name="item"
          :item="item"
        />
      </template>
      <template
        v-if="$scopedSlots.selection"
        #selection="{ item }"
      >
        <slot
          name="selection"
          :item="item"
        />
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { getPropertyFromItem } from 'vuetify/lib/util/helpers';

import fieldMixin from '@/mixins/fields';

/**
 * Champ select de l'application
 * @see https://vuetifyjs.com/en/components/selects/
 */
export default {
  name: 'AppSelect',
  components: { ValidationProvider },
  mixins: [fieldMixin],
  props: {
    /**
     * Valeur du champ
     * @model
     */
    value: {
      type: [Object, String, Number, Array],
      required: false,
      default: '',
    },
    /**
     * Un tableau de valeurs possibles pour le select
     * @see https://vuetifyjs.com/en/api/v-select/#items
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Permet d'indiquer au composant de returner un object
     * @default false
     */
    returnObject: {
      type: Boolean,
      required: false,
    },
    /**
     * La valeur de l'objet à retourner en tant que valeur
     * si returnObject est à false
     */
    itemValue: {
      type: [String, Array, Function],
      default: 'value',
    },
    /**
     * Le texte à afficher dans les éléments de la liste
     */
    itemText: {
      type: [String, Array, Function],
      default: 'text',
    },
    /**
     * Icône représentant l'état actif/inactif de la liste des résultats
     */
    appendIcon: {
      type: String,
      default: 'mdi mdi-menu-down',
    },
    /**
     * Permet la sélection automatique d'un élément correspondant dans la liste si celle-ci est marqué comme requise
     */
    disabledRequiredAutoSelect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    items: {
      // le immediate est necessaire que dans le cas d'items au chargement
      // Car si pas d'item, le immediate sur rules ne sert à rien
      // Cela évite de le lancer 2 fois au chargement (en plus)
      immediate: true,
      handler () {
        this.selectUniqueItem();
      },
    },
    rules () {
      this.selectUniqueItem();
    },
  },
  methods: {
    async handleClickClear () {
      this.$emit('click:clear');
      await this.$nextTick();
      this.blur();
    },
    selectUniqueItem () {
      if (this.disabledRequiredAutoSelect) {
        return;
      }
      if (this.isRequired && this.items.length === 1) {
        this.localValue = this.returnObject ? this.items[0] : getPropertyFromItem(this.items[0], this.itemValue);
      }
    },
  },
};
</script>