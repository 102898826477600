import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const externalMedicationEntity = new DocumentEntity(
  'ExternalMedication',
  'externalMedications.xml',
  'Contient les données des prescriptions effectuées par un médecin externe',
);

externalMedicationEntity.addField(new DocumentEntityField('product', 'Le produit (de type JSON)'));
externalMedicationEntity.addField(new DocumentEntityField('endDate', 'La date de fin de la prescription'));
externalMedicationEntity.addField(new DocumentEntityField('longTerm', 'Indique si la prescription a été établie sur le long terme'));

export default externalMedicationEntity;