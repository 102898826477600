import { isEqual } from 'lodash';

import { CARD_READER_EVENTS } from '@/services/card-reader/constants/cardReaderEvents';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';

export default class CardReader {
  /**
   * Informations sur les cartes
   */
  cards = {
    [CARD_TYPES.CPX]: {
      content: {},
      isLoadingContent: false,
      hasCard: false,
    },
    [CARD_TYPES.VITALE]: {
      content: {},
      isLoadingContent: false,
      hasCard: false,
    },
  };

  /**
   * Informations sur la carte vitale
   */
  vitaleCardSlot = null;

  /**
   * Liste des listeners
   */
  eventListeners = {};

  /**
   * Permet de s'abonner à une modification du lecteur de carte
   * @param {String} cardReaderEvent Type de modification du lecteur de carte
   * @param {Function} action L'action à exécuter
   */
  addEventListener (cardReaderEvent, action) {
    if (Object.values(this.getCardReaderEvents()).indexOf(cardReaderEvent) === - 1) {
      throw new Error(`le type d'évènement de carte "${cardReaderEvent}" n'est pas reconnu`);
    }

    if (! this.eventListeners[cardReaderEvent]) {
      this.eventListeners[cardReaderEvent] = [];
    }

    if (this.eventListeners[cardReaderEvent].indexOf(action) === - 1) {
      this.eventListeners[cardReaderEvent].push(action);
    }
  }

  /**
   * Permet de récupérer l'énumération des évènements compatibles
   * @returns {Object} L'énumération des évènements compatibles
   */
  getCardReaderEvents () {
    return CARD_READER_EVENTS;
  }

  /**
   * Permet de se désabonner à une modification du lecteur de carte
   * @param {String} cardReaderEvent Type de modification du lecteur de carte
   * @param {Function} action L'action à ne plus exécuter
   */
  removeEventListener (cardReaderEvent, action) {
    if (this.eventListeners[cardReaderEvent]) {
      const eventIndex = this.eventListeners[cardReaderEvent].indexOf(action);

      if (eventIndex > - 1) {
        this.eventListeners[cardReaderEvent].splice(eventIndex, 1);
      }
    }
  }

  /**
   * Notifie les listeners en cas de modification du lecteur de carte
   * @param {String} eventName Le nom de l'évènement à émettre
   * @param {Object} payload La modification effectuée
   */
  emitEvent (eventName, payload) {
    const eventListeners = this.eventListeners?.[eventName] || [];
    eventListeners.forEach(listener => listener(payload));
  }

  /**
   * Permet de définir si une carte est présente dans le lecteur de carte
   * @param {CardTypes} cardType Le type de la carte
   * @param {Boolean} hasCard Si une carte est insérée
   */
  setCardReaderHasCard (cardType, hasCard) {
    const currentCardState = this.cards[cardType].hasCard;

    if (! isEqual(currentCardState, hasCard)) {
      this.emitEvent(CARD_READER_EVENTS.STATE_CHANGED, {
        cardReaderType: cardType,
        hasCard,
      });
    }

    this.cards[cardType].hasCard = hasCard;
  }

  /**
   * Permet de définir le contenu de la carte du lecteur
   * @param {CardTypes} cardType Le type de la carte
   * @param {Object} cardContent Les données présentes sur la carte
   */
  setCardReaderContent (cardType, cardContent) {
    const currentCardContent = this.cards[cardType].content;

    if (! isEqual(currentCardContent, cardContent)) {
      this.emitEvent(CARD_READER_EVENTS.CONTENT_CHANGED, {
        cardReaderType: cardType,
        content: cardContent,
      });
    }

    this.cards[cardType].content = cardContent;
  }

  /**
   * Permet de définir si le lecteur de carte demandé est en cours de chargement
   * @param {CardTypes} cardType Le type de la carte
   * @param {Boolean} isLoading Si c'est en cours de chargement
   */
  setCardReaderContentLoading (cardType, isLoading) {
    const currentIsCardReaderLoading = this.cards[cardType].isLoadingContent;

    if (! isEqual(currentIsCardReaderLoading, isLoading)) {
      this.emitEvent(CARD_READER_EVENTS.CONTENT_LOADING, {
        cardReaderType: cardType,
        isLoading,
      });
    }

    this.cards[cardType].isLoadingContent = isLoading;
  }

  /**
   * Permet de valider un code PIN
   * @param {String} pincode le code PIN à valider
   * @throws {Error} Erreur de validation
   */
  // eslint-disable-next-line no-unused-vars
  async validatePincode (pincode) {
    throw new Error('La méthode "validatePincode" doit être réimplémentée');
  }
}