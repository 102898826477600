import { mapGetters, mapMutations } from 'vuex';

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader';


/**
 * Permet de contrôler une modale liée à un tlsi
 * @param {String} tlsi Le TLSI à utiliser {@link TLSI_TYPES}
 */
export default (tlsi) => ({
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasCpxCard']),
    tlsiModalMixin_isOpen: {
      get () {
        return this.isOpen;
      },
      set (isOpen) {
        this.$emit('update:is-open', isOpen);
      },
    },
    tlsiModalMixin_modalFormTitle () {
      return `Téléservice ${tlsi.label}`;
    },
  },
  watch: {
    getHasCpxCard (hasCpxCard) {
      if (! hasCpxCard) {
        this.tlsiModalMixin_closeModalForm();
      }
    },
    tlsiModalMixin_isOpen: {
      immediate: true,
      handler (isOpen) {
        if (isOpen) {
          this.updateVitaleCardContent();
        }
      },
    },
  },
  methods: {
    ...mapMutations('cardReaders', ['SET_CARD_CONTENT']),
    tlsiModalMixin_closeModalForm () {
      this.$emit('update:is-open', false);
    },
    async updateVitaleCardContent () {
      this.SET_CARD_CONTENT({
        cardReaderType: CARD_TYPES.VITALE,
        content: null,
      });
      this.SET_CARD_CONTENT({
        cardReaderType: CARD_TYPES.VITALE,
        content: await iCanopeeCardReaderInstance.fetchVitaleCardContent(),
      });
    },
  },
});