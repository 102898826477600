import Vue from 'vue';

import Header from '@/modules/documents/models/Header';
import { getFromAPI, postToAPI, putToAPI } from '@/services/api';

export const state = () => ({
  headers: {},
  isLoadingHeaders: false,
});

export const getters = {
  getHeaders: state => Object.values(state.headers),
  getHeaderByUuid: state => headerUuid => state.headers[`/api/document_headers/${headerUuid}`],
  isLoadingHeaders: state => state.isLoadingHeaders,
};

export const mutations = {
  SET_HEADER (state, header) {
    Vue.set(state.headers, header['@id'], new Header(header));
  },
  SET_IS_LOADING_HEADERS (state, isLoadingHeaders) {
    state.isLoadingHeaders = isLoadingHeaders;
  },
};

export const actions = {
  async fetchHeaders ({ commit }) {
    commit('SET_IS_LOADING_HEADERS', true);
    const response = await getFromAPI('/api/document_headers');
    response.data['hydra:member'].forEach(header => commit('SET_HEADER', header));
    commit('SET_IS_LOADING_HEADERS', false);
  },
  async fetchOneHeader ({ commit }, headerId) {
    const response = await getFromAPI(`/api/document_headers/${headerId}`);
    commit('SET_HEADER', response.data);
  },
  async insertHeader ({ commit }, header) {
    const response = await postToAPI('/api/document_headers', header);
    commit('SET_HEADER', response.data);
  },
  async updateHeader ({ commit }, header) {
    const { data } = await putToAPI(header['@id'], { data: header });
    commit('SET_HEADER', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};