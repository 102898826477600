export default {
  props: {
    /**
     * Permet le focus automatique du premier champ du formulaire
     */
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Permet le focus du premier formulaire
     * Utile en cas de wrapper de formulaire multiple
     */
    formMixin_focusFirstForm () {
      const hasMultipleForms = Object.keys(this.$refs).some(refName => /form-([0-9]+)/.test(refName));
      this.formMixin_focusForm(hasMultipleForms ? 0 : null);
    },
    /**
     * Permet de focus le premier champ d'un formulaire passé en paramètre
     * @param {Number} formIndex L'index du formulaire (ne tient pas compte de l'index si non renseigné)
     * @returns {Function} La méthode de focus du premier champ du formulaire
     */
    async formMixin_focusForm (formIndex) {
      const form = (formIndex !== undefined) ? this.getForm(formIndex)[0] : this.getForm();
      const firstFocusableField = this.formMixin_getFirstFocusableField(form);
      if (firstFocusableField) {
        firstFocusableField.focus();
      }
    },
    /**
     * Fonction récursive permettant de récupérer le premier champ focusable d'un composant
     * @param {Object} component Composant à traiter
     * @returns {Object} le premier champ focusable du composant
     */
    formMixin_getFirstFocusableField (component) {
      if (component.focus) {
        return component;
      }
      for (let i = 0; i < component.$children.length; i += 1) {
        const child = component.$children[i];
        const firstFocusableField = this.formMixin_getFirstFocusableField(child);
        if (firstFocusableField) {
          return firstFocusableField;
        }
      }

      return null;
    },
    submitForm (formWrapperRef, submitMethod) {
      return new Promise((resolve, reject) => {
        formWrapperRef.submitForm(submitMethod).then((submitted) => {
          /**
           * Remonte par évènement submitSuccess ce qui est retourné dans la méthode
           * de soumission du formulaire
           * @param submitted Valeur retournée par la méthode de soumission
           */
          this.$emit('submitSuccess', submitted);
          resolve(submitted);
        }).catch((error) => {
          /**
           * Remonte par évènement submitError l'erreur rencontrée
           * dans la méthode de soumission du formulaire
           * @param error {Error} Erreur remontée dans la méthode de soumission
           */
          this.$emit('submitError', error);
          reject(error);
        });
      });
    },
    /**
     * Permet de récupérer un formulaire embarqué.
     * L'index peut être fourni lorsqu'il y a plusieurs formulaires embarqués
     * @param {Number} index L'index du formulaire quand il y en a plusieurs
     * @returns {VNode} la référence vers le formulaire
     */
    getForm (index = null) {
      if (index === null) {
        return this.$refs.form;
      }

      return this.$refs[`form-${index}`];
    },
  },
};