import BaseFile from './BaseFile';
import { getFileFromAPI } from '@/services/api';

export default class ZipFile extends BaseFile {
  async fileGetter () {
    const response = await getFileFromAPI(this.fetchUrl);
    const name = response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], { type: 'application/zip' });
    return {
      name,
      blob,
    };
  }
}