import vuetifyBaseColors from 'vuetify/lib/util/colors';

/**
 * @see {@link https://www.figma.com/file/ZmkBPFBI8YMQlwnA13gS0G/Novalys?node-id=1%3A6}
 */
export const COLORS = {
  ...vuetifyBaseColors,
  white: { base: vuetifyBaseColors.shades.white },
  black: { base: vuetifyBaseColors.shades.black },
  primary: {
    base: '#4d7cff',
    lighten4: '#C0D0FE',
    lighten5: '#F1F5FF',
  },
  secondary: {
    base: '#8C94B2',
    lighten4: '#C3CBE6',
    lighten5: '#F5F8FA',
  },
  content: {
    base: '#37467A',
    darken4: '#1B223A',
  },
  error: {
    base: '#E43858',
    lighten5: '#FFEBEB',
  },
  warning: {
    base: '#E4AA38',
    lighten5: '#FCF1DB',
  },
  success: {
    base: '#00C28D',
    lighten5: '#D6FFF4',
  },
  info: { base: '#4D7CFF' },

  doctolib: { base: '#005799' },
};