<template>
  <validation-provider
    v-slot="{ errors }"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
    :mode="fieldValidationMode"
  >
    <div
      class="app-buttons-group-wrapper"
      :class="{ 'app-buttons-group-wrapper--rounded': rounded }"
    >
      <span
        v-if="label"
        class="app-buttons-group-wrapper__label v-label ml-n1"
      >{{ label }}</span>
      <v-btn-toggle
        v-model="activeIndex"
        v-bind="$attrs"
        color="primary"
        :multiple="multiple"
        :rounded="!cards"
        :dense="isMobile"
        class="app-buttons-group"
        active-class="app-buttons-group__button--active"
        :value="itemValue"
        :class="{
          'app-buttons-group--card': cards,
          'app-buttons-group--full-width': fullWidth,
          'app-buttons-group--vertical': direction === 'vertical' }"
      >
        <v-btn
          v-for="(item, index) in items"
          :key="index"
          :height="cards ? 80 : 36"
          outlined
          class="app-buttons-group__button px-5"
          :class="{ 'app-buttons-group__button--card': cards }"
          :disabled="disabled"
        >
          <div
            class="d-flex justify-center items-center"
            :class="{ 'flex-column' : direction === 'horizontal'}"
          >
            <v-icon
              v-if="showIcon"
              class="mb-2 app-buttons-group__button-icon"
            >
              fas fa-{{ item.icon }}
            </v-icon>
            <span class="app-buttons-group__button-text">
              {{ item[itemText] }}
            </span>
          </div>
        </v-btn>
      </v-btn-toggle>
      <div
        v-if="errors && errors.length || errorMessages.length > 0"
        class="app-buttons-group__messages v-messages v-messages__message error--text"
      >
        {{ errors[0] || errorMessages[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import isNumber from 'lodash/isNumber';
import { ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';

import fieldMixin from '@/mixins/fields';

/**
 * Représente un groupe de boutons de l'application
 */
export default {
  name: 'AppButtonsGroup',
  components: { ValidationProvider },
  mixins: [
    fieldMixin,
  ],
  props: {
    /**
     * Les élément à afficher dans le groupe de boutons
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Représente la clé du texte à afficher dans chaque boutons
     */
    itemText: {
      type: String,
      required: false,
      default: 'name',
    },
    /**
     * Représente la clé de la valeur à retourner.
     */
    itemValue: {
      type: String,
      required: false,
      default: 'value',
    },
    /**
     * Indique la valeur du groupe de boutons
     */
    value: {
      type: [String, Number, Array, Object, Boolean],
      required: false,
      default: null,
    },
    /**
     * Permet un affichage sur toute la largeur d'une zone
     */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Indique si le groupe de boutons doit être affiché sous la forme de cartes
     */
    cards: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Affiche l'icone à côté du label
     * False par défaut
     */
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Indique si le groupe de boutons doit être affiché sous la forme de ronds
     */
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Direction des boutons
     * Horizontal ou Vertical
     */
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
      validator (value) {
        return ['horizontal', 'vertical'].includes(value);
      },
    },
    /**
     * Les messages d'erreurs du champ
     * A utiliser avec vee-validate
     */
    errorMessages: {
      type: Array,
      default: () => [],
    },
    /**
     * Label du composant
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Permet de désactiver le groupe de bouton
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    activeIndex: {
      get () {
        return this.getIndex();
      },
      set (newVal) {
        if (this.multiple) {
          const items = newVal.map(index => this.items[index][this.itemValue]);
          this.$emit('input', items);
        } else {
          const item = this.items[newVal];
          this.$emit('input', item ? item[this.itemValue] : null);
        }
      },
    },
  },
  methods: {
    getIndex () {
      if (this.multiple) {
        return this.items
          .map((item, i) => this.value?.includes(item[this.itemValue]) && i)
          .filter(item => isNumber(item));
      }
      return this.items.findIndex(item => item[this.itemValue] === this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-buttons-group-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;

  &__label {
    line-height: 100%;
    height: auto;
    font-size: 12px;
    transform: scale(0.75);
    display: inline-block;
    pointer-events: none;
    user-select: none;
    color: rgba(55, 70, 122, 0.6);
    transform-origin: left;
    margin: 0 0 map-get($spacers, 1) 0 !important;
  }

  .app-buttons-group {
    &.v-btn-toggle--dense {
      .v-btn:not(.v-btn--round).v-size--default {
        min-width: 40px;
        padding: map-get($spacers, 3) !important;
        font-size: 13px;
      }
    }

    &--full-width {
      display: flex;
      width: 100%;

      .v-btn {
        flex: 1 0;
      }
    }

    &--card {
      width: 100%;
    }

    &--vertical {
      flex-direction: column;

      .app-buttons-group__button {
        justify-content: flex-start;
        margin-top: 10px;
        border-radius: 5px !important;
        border: 1px solid #ccc !important;
        padding: map-get($spacers, 3) !important;

        &-icon {
          margin: 0 !important;
          margin-right: 10px !important;
        }
      }
    }

    &__button {
      font-weight: 600;
      font-size: 12px;

      &--card {
        flex: 1;
      }

      &--active {
        box-shadow: inset 0 0 0 1px var(--v-primary-base) !important;
      }

      &-icon {
        color: inherit !important;
      }
    }

    &__messages {
      margin-top: 10px;
    }
  }

  &--rounded {
    .app-buttons-group {
      background: inherit !important;

      & > .app-buttons-group__button {
        border-radius: 50% !important;
      }

      &--full-width {
        display: block;

        & > .app-buttons-group__button {
          height: 47px !important;
          width: 47px !important;
          margin: 5px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>