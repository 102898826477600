<template>
  <app-data-table
    :headers="headers"
    :items="items"
    :item-actions="getDocumentActions"
    :loading="isFetching"
    :custom-sort="customSort"
    pageable
  >
    <template #[`item.event`]="{ item: document }">
      <app-tag
        v-test="'event-tag'"
        :color="document.getEventMetaData().color"
        outlined
        :class="{'documents-life-cycle-reports-panel__action-tag--mobile': isMobile}"
      >
        <div class="flex-list-2 align-center">
          <app-icon
            :icon="document.getEventMetaData().icon"
            :size="12"
          />
          <p>{{ document.getEventMetaData().text }}</p>
        </div>
      </app-tag>
    </template>
    <template #[`item.patient`]="{ item: document }">
      <document-meta-dataset-related-patient-details :meta-dataset="document.metaDataset" />
    </template>
    <template #[`item.title`]="{ item: document }">
      <div :class="{ 'py-1': isMobile }">
        <n-truncable-text
          v-test="'title'"
          style="max-width: 300px;"
          :class="{ 'documents-life-cycle-reports-panel__text--mobile': isMobile }"
        >
          {{ document.title }}
        </n-truncable-text>
        <app-tag
          v-test="'version'"
          x-small
          color="content"
        >
          Version {{ document.metaDataset.versionNumber }}
        </app-tag>
      </div>
    </template>
  </app-data-table>
</template>

<script>

import { cloneDeep } from 'lodash';
import { sortItems as vuetifySortItems } from 'vuetify/lib/util/helpers';

import MessagingBioResultsViewerAction from '@/modules/secureMessaging/modules/documents/classes/MessagingBioResultsViewerAction';
import { LIFE_CYCLE_REPORTS_EVENTS } from '@/modules/secureMessaging/modules/documents/constants';
import MssDocumentLifeCycleReport from '@/modules/secureMessaging/modules/documents/models/MssDocumentLifeCycleReport';
import { sortByMetaDatasetPatient } from '@/modules/secureMessaging/modules/documents/utils/pendingDocuments';
import NovaTools from '@/nova-tools/NovaTools';

import AppDataTable from '@/components/ui/tables/AppDataTable.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';
import DocumentMetaDatasetRelatedPatientDetails from '@/modules/users/components/dashboard/documents/DocumentMetaDatasetRelatedPatientDetails.vue';

export default {
  name: 'DocumentLifeCycleReportsTable',
  components: {
    AppDataTable,
    AppTag,
    DocumentMetaDatasetRelatedPatientDetails,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      headers: [
        {
          value: 'event',
          text: 'Action',
          width: 300,
        },
        {
          value: 'title',
          text: 'Document',
        },
        {
          value: 'patient',
          text: 'Patient concerné',
        },
        {
          value: 'createdAt',
          text: 'Reçu le',
          valueGetter: item => (item.metaDataset?.createdAt
            ? this.getFormattedDate(item.metaDataset.createdAt) : null),
        },
        {
          value: 'effectiveTime',
          text: 'Créé le',
          valueGetter: item => (item.effectiveTime
            ? this.getFormattedDate(item.effectiveTime) : null),
        },
      ],
      items: [],
      isFetching: false,
    };
  },
  async created () {
    this.isFetching = true;
    try {
      this.items = await MssDocumentLifeCycleReport.fetchAll();
    } finally {
      this.isFetching = false;
    }
  },
  methods: {
    getFormattedDate (date) {
      return NovaTools.dates.format(date, 'dd/MM/yyyy à HH:mm');
    },
    getDocumentActions (document) {
      if (document.event !== LIFE_CYCLE_REPORTS_EVENTS.DELETE.value) {
        return [new MessagingBioResultsViewerAction(document)];
      }
      return [];
    },
    sortByCreatedAt (documents, options = {}) {
      const documentsClone = cloneDeep(documents);
      documentsClone.sort((a, b) => {
        if (! a.metaDataset) {
          return 0;
        }
        if (! b.metaDataset) {
          return - 1;
        }
        const dateLeft = new Date(a.metaDataset.createdAt);
        const dateRight = new Date(b.metaDataset.createdAt);
        const isDateLeftAfterDateRight = (Date.parse(dateLeft) > Date.parse(dateRight));
        if (options.sortDesc) {
          return isDateLeftAfterDateRight ? - 1 : 1;
        }
        return isDateLeftAfterDateRight ? 1 : - 1;
      });
      return documentsClone;
    },
    customSort (items, sortBy, sortDesc, locale, customSorters) {
      if (sortBy.includes('createdAt')) {
        return this.sortByCreatedAt(items, { sortDesc: sortDesc?.[0] || false });
      }
      if (sortBy.includes('patient')) {
        return sortByMetaDatasetPatient(items, { sortDesc: sortDesc?.[0] || false });
      }
      return vuetifySortItems(items, sortBy, sortDesc, locale, customSorters);
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-life-cycle-reports-panel {
  &__action-tag {
    &--mobile {
      height: unset !important;
      text-align: center;
    }
  }

  &__text {
    &--mobile {
      ::v-deep {
        .ellipsis-text {
          white-space: unset !important;
          word-break: break-word;
        }
      }
    }
  }
}
</style>