export default class IcanopeeDocument {
  /**
   * @param {String} patientIns
   * @param {Object} options
   * @param {String} options.iri
   * @param {String} options.title
   * @param {String} options.description
   * @param {String} options.contentUrl
   * @param {String} options.format
   * @param {String} options.typeCode
   * @param {String} options.practice
   * @param {String} options.dmpUniqueId
   * @param {String} options.documentType
   * @param {String} options.fileContentBase64
   */
  constructor (patientIns, options = {}) {
    this.patientIns = patientIns;
    this.iri = options.iri || '';
    this.title = options.title || '';
    this.description = options.description || 'description';
    this.contentUrl = options.contentUrl || null;
    this.format = options.format || null;
    this.typeCode = options.typeCode || '11488-4';
    this.practice = options.practice || '07';
    this.dmpUniqueId = options.dmpUniqueId || null;
    this.documentType = options.documentType || null;
    this.fileContentBase64 = options.fileContentBase64 || null;
  }

}