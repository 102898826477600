/**
 * Liste des types de vue de calendrier disponibles dans l'agenda
 */
export const CALENDAR_VIEWS = Object.freeze({
  DAY: {
    text: 'Jour',
    value: 'category',
    icon: 'fas fa-th-large',
  },
  WEEK: {
    text: 'Semaine',
    value: 'week',
    icon: 'fas fa-th',
  },
  LIST: {
    text: 'Liste',
    value: 'custom-daily',
    icon: 'fas fa-th-list',
  },
});