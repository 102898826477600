import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import ApiEntity from '@/models/ApiEntity';
import NovaTools from '@/nova-tools/NovaTools';
import { deleteFromAPI, postToAPI, putToAPI } from '@/services/api';

export default class PatientDocumentFolder extends ApiEntity {

  constructor (options = {}) {
    super(options);
    this.patient = options.patient || null;
    this.title = options.title || null;
    this.description = options.description || null;
    this.parent = options.parent || null;
    this.children = options.children || [];
    this.documents = options.documents || [];
    this.editing = options.editing || false;
  }

  startEditing () {
    this.editing = true;
  }

  /**
   * Insère un dossier au path donné
   * @param {PatientDocumentFolder} patientDocumentFolder - folder à insérer en base
   * @returns {PatientDocumentFolder}
   */
  static async insert (patientDocumentFolder) {
    const { data } = await postToAPI('/api/patient_imported_document_folders', patientDocumentFolder);
    return new PatientDocumentFolder(data);
  }

  /**
   * Mettre à jours un dossier à partir de l'API
   * @param {PatientDocumentFolder} patientDocumentFolder
   * @param {Boolean} showNotification Permet d'afficher le message de succès
   * @returns {PatientDocumentFolder}
   */
  static async update (patientDocumentFolder, showNotification = true) {
    const { data } = await putToAPI(patientDocumentFolder['@id'], { data: patientDocumentFolder });
    if (showNotification) {
      NovaTools.notify.success('Le dossier a été modifié avec succès');
    }
    return new PatientDocumentFolder(data);
  }

  /**
   * Supprime un dossier
   * @param {PatientDocumentFolder} patientDocumentFolder
   * @param {Boolean} showNotification
   */
  static async delete (patientDocumentFolder, showNotification = true) {
    await deleteFromAPI(patientDocumentFolder['@id']);
    if (showNotification) {
      NovaTools.notify.success('Le dossier a été supprimé avec succès');
    }
  }

  static async moveToFolder (folderIri, folderIriTarget) {
    try {
      const { data } = await putToAPI(folderIri, { data: { parent: folderIriTarget } });
      return new PatientDocumentFolder(data);
    } catch (e) {
      return NovaTools.notify.error(e.response.data.violations[0].message);
    }
  }

  /**
   * Retourne l'ensemble des actions
   * @param {Object} options
   * @param {Object} options.patient L'instance du patient
   * @param {Function} options.onOpenImportFilesModal callback à l'ouverture de la modal pour importer des fichiers
   * @param {Function} options.onOpenNewDirectoryModal callback à louverture de la modal pour créer un dossier
   * @param {Function} options.onDeleted callback à l'ouverture de la modal pour supprimer un document
   * @returns {Array}
   */
  getMenuActions (options = {}) {
    const folder = this;

    const primaryActions = [];
    const secondaryActions = [];

    primaryActions.push(new ActionMenuItem('upload', 'Importer des fichiers', async () => {
      options.onOpenImportFilesModal?.(folder);
    }));
    primaryActions.push(new ActionMenuItem('folder-add', 'Créer un dossier', async () => {
      options.onOpenNewDirectoryModal?.(folder);
    }));
    secondaryActions.push(new ActionMenuItem('edit', 'Renommer', () => {
      folder.startEditing();
    }));
    secondaryActions.push(new DeleteActionMenuItem({
      callback: async () => {
        await deleteFromAPI(folder['@id']);
        options.onDeleted?.(folder);
      },
      options: {
        disabled: ((new PatientDocumentFolder(folder).children.length + new PatientDocumentFolder(folder).documents.length) > 0),
        disabledTooltip: 'La suppression n\'est pas possible car le répertoire n\'est pas vide',
        confirmTitle: 'Supprimer le dossier',
        confirmText: `Êtes-vous sûr(e) de vouloir supprimer le dossier "${folder.title}" ?`,
      },
    }));
    return [primaryActions, secondaryActions];
  }

}