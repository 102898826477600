<template>
  <medical-history-item
    v-test="'consultation'"
    :date="consultation.createdAt"
    :update-date="consultation.updatedAt"
    :author="consultation.practitioner"
    :title="consultation.observation.motive ? consultation.observation.motive : 'Consultation sans motif'"
    :actions="consultationActions"
    class="medical-history-consultation"
  >
    <template #title-append>
      <app-color-dot
        :color="consultationBillingState.color"
        :size="20"
        icon="euro"
        :tooltip="consultationBillingState.label"
      />
    </template>
    <section
      v-if="hasConsultationMedicalInfos"
      class="medical-history-consultation__infos"
    >
      <n-expandable :icon-rotation-angle="[-90, 0]">
        <template #header="{ toggle, expandIconStyle, isExpanded }">
          <h3
            class="medical-history-consultation__show-observations subtitle-3"
            @click="toggle"
          >
            <app-icon
              icon="caret"
              size="12"
              color="secondary"
              :style="expandIconStyle"
            />
            <span class="ml-2">
              {{ 'Examen clinique' }}
            </span>
            <span
              v-if="!isExpanded"
              class="medical-history-consultation__infos__item__text__first-line"
            >
              {{ consultation.observation.clinicalExamination }}
            </span>
          </h3>
        </template>
        <article class="medical-history-consultation__infos__item">
          <p
            v-test="'clinical-examination'"
            class="medical-history-consultation__infos__item__text"
          >
            {{ consultation.observation.clinicalExamination }}
          </p>
        </article>
        <article class="medical-history-consultation__infos__item">
          <h3 class="medical-history-consultation__show-observations subtitle-3 pt-4">
            <span class="ml-1">
              {{ 'Conduite à tenir' }}
            </span>
          </h3>
          <p
            v-test="'conduct-to-take'"
            class="medical-history-consultation__infos__item__text"
          >
            {{ consultation.observation.conductToTake }}
          </p>
        </article>
      </n-expandable>
    </section>

    <section v-if="consultation.vaccines.length > 0">
      <n-divider :spacing="3" class="mx-n3" />
      <medical-history-vaccines-list v-test="'consultation-vaccines-list'" :vaccines="consultation.vaccines" />
    </section>

    <template v-if="hasConsultationMeasureInEnabledSettings">
      <n-divider
        :spacing="3"
        class="mx-n3"
      />

      <consultation-measures-list
        :consultation="consultation"
        data-test="consultation-measures-list"
      />
    </template>

    <template v-if="consultation && consultationDocumentsCount > 0">
      <n-divider
        :spacing="3"
        class="mx-n3"
      />

      <n-expandable :icon-rotation-angle="[-90, 0]">
        <template #header="{ toggle, expandIconStyle }">
          <h3
            class="medical-history-consultation__show-documents subtitle-3"
            @click="toggle"
          >
            <app-icon
              icon="caret"
              size="12"
              color="secondary"
              :style="expandIconStyle"
            />
            <span class="ml-2">{{ 'Documents et ordonnances' }}</span>
            <app-tag
              x-small
              color="#c0d0fe"
              class="px-2 ml-2"
            >
              {{ consultationDocumentsCount }}
            </app-tag>
          </h3>
        </template>
        <section
          v-if="consultationPrescriptions.length"
          class="mb-3"
        >
          <h4 class="subtitle-3 mb-2">
            Ordonnances
          </h4>
          <consultation-prescriptions-list
            :prescriptions="consultationPrescriptions"
            :patient-iri="consultation.patient['@id']"
            :consultation-uuid="consultationUuid"
            hide-header
            dense
            class="ml-2"
            @update:prescription="updatePrescription"
          />
        </section>
        <section v-if="hasConsultationDocumentsNotPrescriptions">
          <h4 class="subtitle-3 mb-2">
            Documents
          </h4>
          <consultation-documents-list
            :consultation-uuid="consultationUuid"
            :patient-iri="consultation.patient['@id']"
            dense
            class="ml-2"
          />
        </section>
      </n-expandable>
    </template>
  </medical-history-item>
</template>

<script>


import { mapGetters, mapMutations } from 'vuex';

import { BILLING_STATUSES } from '@/modules/accounting/constants';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import Consultation from '@/modules/patient/models/Consultation';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

import AppColorDot from '@/components/ui/form/color/AppColorDot.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';
import ConsultationDocumentsList from '@/modules/patient/components/consultation/ConsultationDocumentsList.vue';
import ConsultationMeasuresList from '@/modules/patient/components/consultation/ConsultationMeasuresList.vue';
import ConsultationPrescriptionsList
  from '@/modules/patient/components/consultation/prescription/ConsultationPrescriptionsList.vue';
import MedicalHistoryItem from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryItem.vue';
import MedicalHistoryVaccinesList
  from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryVaccinesList.vue';

export default {
  name: 'MedicalHistoryConsultation',
  components: {
    MedicalHistoryVaccinesList,
    ConsultationPrescriptionsList,
    MedicalHistoryItem,
    ConsultationDocumentsList,
    ConsultationMeasuresList,
    AppColorDot,
    AppTag,
  },
  props: {
    consultation: {
      type: Consultation,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'getCurrentUser', 'isCurrentUserPractitioner']),
    ...mapGetters('patient', [
      'getConsultationPrescriptions',
      'getTotalConsultationDocumentsCount',
    ]),
    consultationPrescriptions () {
      return this.getConsultationPrescriptions(this.consultationUuid);
    },
    consultationUuid () {
      return this.consultation.getUuid();
    },
    isCurrentUserConsultationPractitioner () {
      return this.getCurrentUser.contact['@id'] === this.consultation.activityPlacePractitionerLink?.practitioner['@id'];
    },
    isAssignedToPractitioner () {
      return Boolean(this.consultation.activityPlacePractitionerLink);
    },
    hasConsultationMedicalInfos () {
      return Boolean(
        this.consultation.observation.clinicalExamination?.length > 0
        || this.consultation.observation.conductToTake?.length > 0,
      );
    },
    hasConsultationDocumentsNotPrescriptions () {
      return Boolean(
        this.consultation.documents.length > 0
        || this.consultation.certificateDocuments.length > 0
        || this.consultation.dischargeLetterDocument !== null
        || this.consultation.followUpLetterDocument !== null
        || this.consultation.telemedicineActDocument !== null,
      );
    },
    hasConsultationMeasureInEnabledSettings () {
      const enabledSettingType = this.consultationMeasuresEnabledSettings.map(setting => setting.type);
      return Boolean(
        this.consultationMeasuresEnabledSettings.length > 0
        && this.consultation.patientMeasures.filter(measure => enabledSettingType.includes(measure.name)).length > 0,
      );
    },
    consultationActions () {
      const editAction = { edit: this.goToConsultationEdition };
      // Donne l'édition a une secrétaire car elle n'a accès qu'aux consultations des praticiens auxquels elle est liée
      // Ne donne l'édition qu'au praticien qui a créé la consultation
      if ((! this.isCurrentUserPractitioner || this.isCurrentUserConsultationPractitioner) || ! this.isAssignedToPractitioner) {
        return editAction;
      }
      return {};
    },
    consultationBillingState () {
      return BILLING_STATUSES[this.consultation.billingState];
    },
    consultationDocumentsCount () {
      return this.getTotalConsultationDocumentsCount(this.consultationUuid);
    },
    consultationMeasuresEnabledSettings () {
      return this.getPatientMeasureUserSettings().filter(setting => setting.enabled);
    },
    consultationVaccineCount () {
      return this.consultation.vaccines.filter(item => !! item).length;
    },
  },
  methods: {
    ...mapGetters('auth', ['getPatientMeasureUserSettings']),
    ...mapMutations('patient', { setConsultationPrescription: 'SET_CONSULTATION_PRESCRIPTION' }),
    goToConsultationEdition () {
      this.$emit('edition');
      this.$router.push({
        name: ROUTE_NAMES.CREATE_CONSULTATION,
        params: {
          uuid: getUUIDFromIRI(this.consultation.patient['@id']),
          consultationUUID: this.consultationUuid,
        },
      });
    },
    updatePrescription (prescription) {
      this.setConsultationPrescription({
        consultation: this.consultation,
        prescription,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.medical-history-consultation {
  &__infos {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 2);

    &__item {
      font-size: 12px;
      display: grid;

      &__text {
        color: var(--v-secondary-base);

        &__first-line {
          color: var(--v-secondary-base);
          display: inline-block;
          flex-shrink: 1;
          font-size: 12px;
          font-weight: normal;
          left: 140px;
          overflow: hidden;
          position: absolute;
          text-overflow: ellipsis;
          top: 1px;
          width: 80%;
        }
      }
    }
  }

  &__show-documents, &__show-measures, &__show-observations {
    user-select: none;
    cursor: pointer;
  }

  &__show-observations {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }

  &__vaccine {
    display: flex;
    gap: map-get($spacers, 3);
    align-items: center;
  }

  ::v-deep {
    .medical-history-item__panel .panel__header__headline {
      overflow: visible;
    }

    .medical-history-item__panel .panel__title {
      text-overflow: initial;
      text-align: justify;
      white-space: normal;
    }
  }
}
</style>