<template>
  <object
    v-test="'pdf-file-preview'"
    class="pdf-file-preview"
    :data="pdfData"
    type="application/pdf"
  >Votre navigateur ne supporte pas la prévisualisation de fichiers PDF</object>
</template>

<script>
import { Buffer } from 'buffer';

import NovaTools from '@/nova-tools/NovaTools';

export default {
  name: 'PdfViewer',
  props: {
    base64FileContent: {
      type: String,
      default: null,
    },
    contentUrl: {
      type: String,
      default: null,
    },
  },
  data () {
    return { localBase64: null };
  },
  computed: {
    pdfData () {
      const blob = new Blob([this.b64toBlob(this.localBase64)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      return fileURL;
    },
  },
  watch: {
    base64FileContent: {
      immediate: true,
      handler (newValue) {
        if (newValue) {
          this.localBase64 = newValue;
        }
      },
    },
    contentUrl: {
      immediate: true,
      async handler (newValue) {
        if (newValue) {
          this.localBase64 = await NovaTools.files.getBase64Content(newValue);
        }
      },
    },
  },
  methods: {
    b64toBlob (b64Data) {
      return b64Data ? Buffer.from(b64Data, 'base64') : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-file-preview {
  min-width: 100%;
  height: 100%;
}
</style>