<template>
  <v-progress-linear
    indeterminate
    data-test="progress-linear"
  />
</template>

<script>
/**
 * La barre de chargement linéaire de l\'application
 * @see {@link https://vuetifyjs.com/en/components/progress-linear/}
 */
export default { name: 'AppLinearLoader' };
</script>