import ApiEntity from '@/models/ApiEntity';
import CheckDataset from '@/modules/patient/models/datasets/CheckDataset';
import { format } from '@/utils/functions/dates';
import { parseNumber } from '@/utils/functions/number';


export default class Payment extends ApiEntity {
  /**
   * @param {{ type: String, amount: [String, Number], date: Number, checkDataset: CheckDataset, consultation: String }} options
   */
  constructor (options = {}) {
    super(options);
    this.type = options.type || null;
    this.amount = options.amount ? parseFloat(parseNumber(options.amount).toFixed(2)) : 0;
    this.date = options.date || format(new Date(), 'yyyy-MM-dd');
    this.checkDataset = options.checkDataset ? new CheckDataset(options.checkDataset) : null;
    this.consultation = options.consultation || null;
  }
}