// https://solidarites-sante.gouv.fr/IMG/pdf/carte_vitale-2.pdf
export const BENEFICIARIES_QUALITY_CODES = Object.freeze({
  INSURED: {
    label: 'Assuré',
    value: '0',
  },
  ASCENDANT: {
    label: 'Ascendants, descendants, collatéraux ascendants',
    value: '1',
  },
  SPOUSE: {
    label: 'Conjoint',
    value: '2',
  },
  DIVORCED_SPOUSE: {
    label: 'Conjoint divorcé',
    value: '3',
  },
  COHABITANT: {
    label: 'Concubin',
    value: '4',
  },
  SEPARATED_SPOUSE: {
    label: 'Conjoint séparé',
    value: '5',
  },
  CHILD: {
    label: 'Enfant',
    value: '6',
  },
  WIDOWER_SPOUSE: {
    label: 'Conjoint veuf',
    value: '8',
  },
  OTHER_INSURED: {
    label: 'Autre Assuré',
    value: '9',
  },
});