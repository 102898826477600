export const UNSUBSTITUTION_PATTERNS = [
  {
    text: 'Marge Thérapeutique Étroite',
    value: 'MTE',
    synapseValue: 'narrow-therapeuthic-gap',
  },
  {
    text: 'Enfant Forme Galénique',
    value: 'EFG',
    synapseValue: 'child-galenic-form',
  },
  {
    text: 'Contre-Indication Formelle',
    value: 'CIF',
    synapseValue: 'formal-contraindication',
  },
];