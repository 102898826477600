<template>
  <app-form :submit-method="save">
    <app-text-field
      v-model="localProfessionalRiskFactor.label"
      v-test="'professional-risk-factor-label-field'"
      label="Nom du facteur de risque professionnel"
      hide-errors="auto"
      rules="required"
      class="mb-2"
    />
    <app-textarea-field
      v-model="localProfessionalRiskFactor.note"
      v-test="'professional-risk-factor-note-field'"
      label="Commentaire (optionnel)"
      hide-errors="auto"
      rows="1"
      auto-grow
    />
  </app-form>
</template>

<script>
import PatientProfessionalRiskFactor from '@/modules/patient/models/healthEntity/PatientProfessionalRiskFactor';
import Patient from '@/modules/patient/models/Patient';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';

export default {
  name: 'ProfessionalRiskFactorForm',
  components: {
    AppForm,
    AppTextField,
    AppTextareaField,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return { localProfessionalRiskFactor: null };
  },
  computed: {
    isEditing () {
      return !! this.localProfessionalRiskFactor['@id'];
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        this.localProfessionalRiskFactor = new PatientProfessionalRiskFactor(value ? {
          ...value,
          patient: this.patient['@id'],
        } : { patient: this.patient['@id'] });
      },
    },
  },
  methods: {
    async save () {
      if (this.isEditing) {
        await PatientProfessionalRiskFactor.update(this.localProfessionalRiskFactor);
      } else {
        await PatientProfessionalRiskFactor.insert(this.localProfessionalRiskFactor);
      }
    },
  },
};
</script>

<style></style>