<template>
  <nav class="nav-bar">
    <router-link
      v-for="(menuItem, index) in moduleItemsMixin_menuItems"
      :key="index"
      v-slot="{ navigate, isActive}"
      :to="menuItem.route"
      class="nav-bar__item"
      custom
      data-test="nav-bar-item"
    >
      <div
        v-ripple
        class="d-flex flex-column justify-center align-center px-4"
        :class="{ primary: isActive }"
        @click="navigate"
      >
        <svg
          data-test="nav-bar-item-icon"
          width="28"
          height="28"
          :name="menuItem.icon"
          :class="`mb-1 icon-${menuItem.icon}`"
        >
          <use :xlink:href="`#icon-${menuItem.icon}`" />
        </svg>
        <span
          data-test="nav-bar-item-text"
          class="white--text text-uppercase text-caption"
        >
          {{ menuItem.text }}
        </span>
      </div>
    </router-link>
  </nav>
</template>

<script>
import moduleItemsMixin from '@/layout/mixins/moduleItemsMixin';

export default {
  name: 'TheModulesNavBar',
  mixins: [moduleItemsMixin],
};
</script>

<style lang="scss" scoped>
.nav-bar {
  display: flex;
  height: 100%;

  &__item {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
</style>