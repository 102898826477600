<template>
  <app-form :submit-method="openAatiForm" class="flex-list-6 flex-column">
    <div v-if="!showApCv && !apCvData && !showSimpleForm">
      <vitale-card-switch-view-validator
        v-test="'vitale-card-view'"
        unfounded-card-message="Ce téléservice nécessite une carte vitale"
        :can-use-apcv="true"
        @use-apcv="showApCvModal"
        @remove-card="clearForm"
      >
        <beneficiary-selector
          :patient="patient"
          :insureds="insureds"
          :selected-beneficiary="selectedBeneficiary"
          readonly
          hint="L'assuré est automatiquement sélectionné en tant que bénéficiaire"
          persistent-hint
        />
        <aati-prescriber-form
          class="mt-8"
          :prescriber.sync="localPrescriberData"
          :practice-locations="practiceLocations"
        />
      </vitale-card-switch-view-validator>
      <div v-if="!insureds.length && !isVitaleLoading" class="d-flex justify-center">
        <n-button
          v-test="'simple-form-view-btn'"
          class="mt-4"
          bg-color="secondary"
          label="Utiliser les données du patient"
          @click="showSimpleForm = true"
        />
      </div>
    </div>
    <div v-else-if="!showApCv && !apCvData && showSimpleForm">
      <h3 class="subtitle-3 mb-1">
        Bénéficiaire
      </h3>
      <app-row dense>
        <app-col cols="12" md="6">
          <app-text-field
            v-model="patientVitaleData.nir"
            v-test="'nir-field'"
            :rules="{
              required: true,
              length: 15,
            }"
            label="NIR de l'ouvrant droit"
          />
        </app-col>
        <app-col cols="12" md="6">
          <app-text-field
            v-model="patientVitaleData.certifiedNir"
            v-test="'certified-nir-field'"
            rules="length:15"
            label="NIR certifié"
          />
        </app-col>
        <app-col cols="12" md="6">
          <app-date-field
            v-model="patientVitaleData.birthDate"
            v-test="'birth-date-field'"
            allow-lunar-date
            rules="required"
            label="Date de naissance"
          />
        </app-col>
        <app-col cols="12" md="6">
          <app-text-field
            v-model="patientVitaleData.birthRank"
            v-test="'birth-rank-field'"
            label="Rang de naissance"
            rules="required|numeric|max:1"
            hide-errors="auto"
            autocomplete="off"
          />
        </app-col>
        <app-col cols="12" md="10">
          <app-autocomplete
            v-model="patientVitaleData.grandRegime"
            v-test="'grand-regime-field'"
            :items="getGrandRegimes"
            item-text="libelle"
            item-value="@id"
            label="Grand régime"
            hide-errors="auto"
            clearable
            return-object
            rules="required"
          />
        </app-col>
        <app-col cols="12" md="1">
          <app-text-field
            v-model="patientVitaleData.fund"
            v-test="'fund-field'"
            label="Caisse"
            hide-errors="auto"
            rules="required|numeric|max:3"
            autocomplete="off"
          />
        </app-col>
        <app-col cols="12" md="1">
          <app-text-field
            v-model="patientVitaleData.center"
            v-test="'center-field'"
            label="Centre"
            hide-errors="auto"
            rules="required|numeric|max:4"
            autocomplete="off"
          />
        </app-col>
      </app-row>
      <aati-prescriber-form
        class="mt-8"
        :prescriber.sync="localPrescriberData"
        :practice-locations="practiceLocations"
      />
    </div>
    <div v-else v-test="'ap-cv-view'">
      <ap-cv-form
        v-if="!insureds.length"
        v-test="'ap-cv-form'"
        :patient="patient"
        @submit-success="getDataFromApCv"
      />
      <div v-else>
        <beneficiary-selector
          :patient="patient"
          :insureds="insureds"
          :selected-beneficiary="selectedBeneficiary"
          readonly
          hint="L'assuré est automatiquement sélectionné en tant que bénéficiaire"
          persistent-hint
        />
        <aati-prescriber-form
          class="mt-8"
          :prescriber.sync="localPrescriberData"
          :practice-locations="practiceLocations"
        />
      </div>
    </div>
  </app-form>
</template>

<script>
import { mapGetters } from 'vuex';

import Aati from '@/modules/patient/components/sidebar/tlsi/aati/models/Aati';
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import { getVitaleDataFromPatient } from '@/services/vendors/icanopee/utils/getVitaleDataFromPatient';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppDateField from '@/components/ui/form/AppDateField.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import VitaleCardSwitchViewValidator from '@/components/ui/form/VitaleCardSwitchViewValidator.vue';
import AatiPrescriberForm from '@/modules/patient/components/sidebar/tlsi/aati/AatiPrescriberForm.vue';
import ApCvForm from '@/modules/patient/components/sidebar/tlsi/apcv/ApCvForm.vue';
import BeneficiarySelector from '@/modules/patient/components/sidebar/tlsi/BeneficiarySelector.vue';



export default {
  name: 'AatiBeneficiarySelectorForm',
  components: {
    AppForm,
    AppTextField,
    AppDateField,
    AppAutocomplete,
    VitaleCardSwitchViewValidator,
    ApCvForm,
    BeneficiarySelector,
    AatiPrescriberForm,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    prescriberData: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      cardType: null,
      showApCv: false,
      apCvData: null,
      showSimpleForm: false,
      patientVitaleData: {
        birthName: null,
        firstName: null,
        familyName: null,
        nir: null,
        certifiedNir: null,
        birthDate: null,
        birthRank: null,
        center: null,
        fund: null,
        grandRegime: null,
        quality: null,
      },
      localPrescriberData: null,
      beneficiarySearchAlertText: [
        'Veuillez sélectionner le bénéficiaire de l\'arrêt de travail.',
        'Si le bénéficiaire n\'est pas le patient, vous devez sélectionner l\'ayant droit concerné.',
      ].join('\n'),

      prescriberSearchAlertText: [
        'Veuillez sélectionner le prescripteur de l\'arrêt de travail.',
        'Si vous n\'êtes pas le prescripteur, vous devez sélectionner le médecin concerné.',
      ].join('\n'),
    };
  },
  computed: {
    ...mapGetters('app', ['getGrandRegimes']),
    ...mapGetters('cardReaders', ['getCardContent', 'getHasVitaleCard', 'getIsLoadingCard']),
    prescriber () {
      return this.getCardContent(CARD_TYPES.CPX);
    },
    isVitaleLoading () {
      return this.getIsLoadingCard(CARD_TYPES.VITALE);
    },
    vitaleCardInsureds () {
      return this.getCardContent(CARD_TYPES.VITALE);
    },
    insureds () {
      return this.vitaleCardInsureds || this.apCvData?.insureds || [];
    },
    selectedBeneficiary () {
      return this.insureds.find(insured => insured.isMainInsured) || null;
    },
    beneficiaryVitaleCardIndex () {
      return this.insureds.indexOf(this.selectedBeneficiary);
    },
    source () {
      if (this.showApCv) {
        return 'ApCV';
      }
      if (this.showSimpleForm) {
        return 'manual';
      }
      return 'CV';
    },
    practiceLocations () {
      return this.prescriber?.PracticeLocations;
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient && ! this.getHasVitaleCard) {
          this.setPatientVitaleDataFields(patient);
        }
        if (! patient?.['@id']) {
          this.clearPatientVitaleDataFields();
        }
      },
    },
    prescriberData: {
      immediate: true,
      handler (newVal, oldVal) {
        this.localPrescriberData = this.prescriberData;

        if (oldVal && !! oldVal.rpps && ! newVal.rpps) {
          this.clearForm();
          this.fillLocalPrescriberData();
        }
      },
    },
    prescriber: {
      immediate: true,
      handler () {
        this.fillLocalPrescriberData();
      },
    },
    getHasVitaleCard (hasVitaleCard) {
      if (hasVitaleCard) {
        this.clearPatientVitaleDataFields();
      } else {
        this.setPatientVitaleDataFields(this.patient);
      }
    },
    vitaleCardInsureds () {
      this.showApCv = false;
    },
  },
  methods: {
    clearForm () {
      if (this.showApCv) {
        this.showApCv = false;
        this.apCvData = null;
        this.cardType = null;
      }
      this.showSimpleForm = false;
      this.needsFormClearing = false;
    },
    showApCvModal () {
      this.showApCv = true;
    },
    getDataFromApCv (apCvData) {
      this.apCvData = apCvData;
      this.cardType = apCvData.data.s_profileType;
    },
    setPatientVitaleDataFields (patient) {
      this.patientVitaleData = {
        birthName: patient.birthName,
        firstName: patient.firstName,
        familyName: patient.familyName,
        nir: patient.nir,
        certifiedNir: null,
        birthDate: patient.birthDate,
        birthRank: patient.billDataset?.birthRank,
        center: patient.billDataset?.center,
        fund: patient.billDataset?.fund,
        grandRegime: patient.billDataset?.grandRegime,
        quality: patient.billDataset?.quality,
      };
    },
    fillLocalPrescriberData () {
      if (! this.localPrescriberData.familyName) {
        this.localPrescriberData.familyName = this.prescriber?.s_name ?? null;
        this.localPrescriberData.firstName = this.prescriber?.s_given ?? null;
        this.localPrescriberData.rpps = this.prescriber?.s_internalId ?? null;
      }
    },
    clearPatientVitaleDataFields () {
      this.patientVitaleData = {
        birthName: null,
        firstName: null,
        familyName: null,
        nir: null,
        certifiedNir: null,
        birthDate: null,
        birthRank: null,
        center: null,
        fund: null,
        grandRegime: null,
        quality: null,
      };
    },
    async openAatiForm () {
      try {
        const apiRequestParams = await this.prepareApiRequestParams();
        this.checkCardsConsistency();
        return {
          selectedBeneficiary: this.source !== 'manual' ? this.selectedBeneficiary : {
            patient: new Patient({
              ...this.patient,
              nir: this.patientVitaleData.nir,
              certifiedNir: this.patientVitaleData.certifiedNir,
              birthDate: this.patientVitaleData.birthDate,
              billDataset: {
                ...this.patient.billDataset,
                birthRank: this.patientVitaleData.birthRank,
                center: this.patientVitaleData.center,
                fund: this.patientVitaleData.fund,
                grandRegime: this.patientVitaleData.grandRegime,
              },
            }),
          },
          dataSource: this.source,
          apiRequestParams,
        };
      } catch (error) {
        this.handleAatiFormOpeningError(error);
        return null;
      }
    },
    async prepareApiRequestParams () {
      let apiRequestParams = null;

      if (this.getHasVitaleCard) {
        apiRequestParams = await this.getVitaleCardFormData();
      } else if (this.apCvData) {
        apiRequestParams = this.getApCvFormData();
      } else {
        apiRequestParams = this.getManualFormData();
      }

      const template = await this.getAatiTemplate(apiRequestParams.aatiTemplateVitaleData);
      delete apiRequestParams.aatiTemplateVitaleData;
      return {
        patient: this.patient['@id'],
        prescriberData: this.localPrescriberData,
        template,
        ...apiRequestParams,
      };
    },
    async getVitaleCardFormData () {
      const { VitaleData, PatientData } = await Tlsi.getBeneficiaryData(this.beneficiaryVitaleCardIndex);
      PatientData.s_birthday = this.getBirthDayData();
      this.cardType = VitaleData.s_cardType;
      return {
        patientData: PatientData,
        aatiTemplateVitaleData: this.beneficiaryVitaleCardIndex,
      };
    },
    getApCvFormData () {
      const patientData = this.beneficiaryVitaleCardIndex === 0
        ? this.apCvData.data.User.VitaleData
        : this.apCvData.data.Wallet[this.beneficiaryVitaleCardIndex - 1].VitaleData;
      patientData.s_birthday = this.getBirthDayData();
      return {
        patientData,
        aatiTemplateVitaleData: this.beneficiaryVitaleCardIndex,
      };
    },
    getManualFormData () {
      const aatiPatientVitaleData = getVitaleDataFromPatient({
        ...this.patient,
        nir: this.patientVitaleData.nir,
        certifiedNir: this.patientVitaleData.certifiedNir,
        birthDate: this.patientVitaleData.birthDate,
        billDataset: {
          ...this.patient.billDataset,
          birthRank: this.patientVitaleData.birthRank,
          center: this.patientVitaleData.center,
          fund: this.patientVitaleData.fund,
          grandRegime: this.patientVitaleData.grandRegime,
        },
      });
      return {
        vitaleData: this.patientVitaleData,
        aatiTemplateVitaleData: {
          BeneficiaryVitaleData: aatiPatientVitaleData,
          RightsHolderNir: aatiPatientVitaleData.NirData.Nir,
        },
      };
    },
    getAatiTemplate (param) {
      const aatiInstance = new Aati({ patient: this.patient });
      return aatiInstance.getAatiTemplate(param, this.showApCv);
    },
    checkCardsConsistency () {
      const errorMessage = [
        'La saisie d\'un arrêt de travail pour un bénéficiaire issu d\'une carte vitale réelle',
        'n\'est pas permise avec une carte CPS de test.',
      ].join(' ');

      if (this.prescriber?.i_cpxCardType === 2 && this.cardType === 'R') {
        throw new Error(errorMessage);
      }
    },
    getBirthDayData () {
      if (!this.selectedBeneficiary) {
        const errorMessage = [
          'Impossible de récupérer le bénéficiaire.',
          this.source === 'CV' ? '\nVeuillez vérifier que la carte vitale est bien insérée.' : '',
        ].join('');
        throw new Error(errorMessage);
      }
      return this.selectedBeneficiary.patient.birthDate;
    },
    handleAatiFormOpeningError (error) {
      let message = 'Une erreur est survenue';
      const options = { timeout: 15000 };

      if (this.isHydraObjectError(error)) {
        message = error.response.data['hydra:description'].concat(error.response.data['hydra:detail']
          ? ` (${error.response.data['hydra:detail']})`
          : '');
        options.title = error.response.data['hydra:title'];
      }
      if (this.isSimpleObjectError(error)) {
        message = error.message;
      }
      if (typeof error === 'string') {
        message = error;
      }
      NovaTools.notify.error(message, options);
      throw error;
    },
    isHydraObjectError (error) {
      return typeof error === 'object' && error.response?.data;
    },
    isSimpleObjectError (error) {
      return typeof error === 'object' && error.message;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .app-autocomplete {
    position: relative;
  }

  .app-autocomplete .v-autocomplete__content {
    // Permet au menu de se positionner de manière fixe au dessus
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    margin-top: map-get($spacers, 8);
    max-height: 300px;
  }
}
</style>