<template>
  <section class="app-replacement-content-list flex-list-4 flex-column">
    <slot name="prepend" />
    <app-checkbox
      v-if="! readonly && (replacements.length > 1)"
      v-test="'toggle-all-checkbox'"
      label="Sélectionner tout"
      :input-value="isAllSelected"
      :indeterminate="isSelectionIndeterminate"
      @click="toggleAll()"
      @click.native="onToggleAll()"
    />
    <n-list
      :items="replacements"
      :spacing="readonly ? 4 : 1"
    >
      <template #item="{ item }">
        <div :class="{'d-flex': readonly}">
          <app-icon
            v-if="readonly"
            :icon="item.before === item.after ? 'check-circle' : 'warning'"
            :color="item.before === item.after ? 'success' : 'error'"
            :size="16"
            class="mr-4 align-self-center"
          />
          <app-replacement-content-item
            v-ripple="! readonly"
            class="app-replacement-content-list__item"
            :class="{
              'app-replacement-content-list__item--selected': isSelected(item),
              'app-replacement-content-list__item--readonly': readonly,
              'flex-grow-1': readonly,
            }"
            :item="item"
            @click.native="onItemClick(item)"
          >
            <template
              v-if="! readonly"
              #title="{ label }"
            >
              <app-checkbox
                :input-value="selection"
                :label="label"
                :value="valueGetter(item)"
              />
            </template>
          </app-replacement-content-item>
        </div>
      </template>
    </n-list>
  </section>
</template>

<script>
import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem';
import { useSelectable } from '@novalys/src/composables';

import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';
import AppReplacementContentItem from '@/components/ui/replacementContent/AppReplacementContentItem.vue';



export default {
  name: 'AppReplacementContentList',
  components: {
    AppReplacementContentItem,
    AppCheckbox,
  },
  props: {
    replacements: {
      type: Array,
      default: () => ([]),
      validator: actions => actions.every(action => action instanceof ReplacementItem),
    },
    value: {
      type: [Array, Object, String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const {
      toggle, toggleAll, isSelected, selection, valueGetter, isAllSelected, isSelectionIndeterminate,
    } = useSelectable(props.replacements, { multiple: true });
    return {
      toggle,
      selection,
      valueGetter,
      isAllSelected,
      isSelected,
      toggleAll,
      isSelectionIndeterminate,
    };
  },
  methods: {
    onItemClick (item) {
      if (this.readonly) {
        return;
      }
      this.toggle(item);
      this.$emit('update', this.selection);
    },
    onToggleAll () {
      this.$emit('update', this.selection);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-replacement-content-list {
  &__item {
    padding: map-get($spacers, 3);
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px var(--v-divider-base);
    color: var(--v-content-base);
    cursor: pointer;
    transition: box-shadow .2s ease;

    &--readonly {
      padding: 0;
      box-shadow: none;
      cursor: default;
    }

    &--selected {
      box-shadow: inset 0 0 0 1.5px var(--v-primary-base);
      color: var(--v-primary-base),
    }
  }
}
</style>