import Vue from 'vue';

import PatientDrugAllergy from '@/modules/patient/models/healthEntity/PatientDrugAllergy';
import { postToAPI, putToAPI, deleteFromAPI } from '@/services/api';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

export const state = () => ({
  drugAllergies: {},
  hasLoadedPatientDrugAllergies: {},
  isLoadingDrugAllergies: false,
});

export const getters = {
  getPatientDrugAllergies: state => patientUuid => Object.values(state.drugAllergies[patientUuid] || {}),
  getHasLoadedDrugAllergiesByPatientUuid: state => patientUuid => state.hasLoadedPatientDrugAllergies[patientUuid],
  isLoadingDrugAllergies: state => state.isLoadingDrugAllergies,
};

export const mutations = {
  SET_DRUG_ALLERGY (state, drugAllergy) {
    const patientUuid = getUUIDFromIRI(drugAllergy.patient);
    const drugAllergyUuid = getUUIDFromIRI(drugAllergy['@id']);
    if (state.drugAllergies[patientUuid] === undefined) {
      Vue.set(state.drugAllergies, patientUuid, {});
    }
    Vue.set(state.drugAllergies[patientUuid], drugAllergyUuid, new PatientDrugAllergy(drugAllergy));
  },
  DELETE_DRUG_ALLERGY (state, drugAllergy) {
    const patientUuid = getUUIDFromIRI(drugAllergy.patient);
    Vue.delete(state.drugAllergies[patientUuid], drugAllergy.getUuid());
  },
  SET_HAS_LOADED_PATIENT_DRUG_ALLERGIES (state, { patientUuid, hasLoadedDrugAllergies }) {
    state.hasLoadedPatientDrugAllergies[patientUuid] = hasLoadedDrugAllergies;
  },
  SET_IS_LOADING_DRUG_ALLERGIES (state, isLoadingDrugAllergies) {
    state.isLoadingDrugAllergies = isLoadingDrugAllergies;
  },
};

export const actions = {
  async fetchPatientDrugAllergies ({ state, commit, dispatch }, patientUuid) {
    commit('SET_IS_LOADING_DRUG_ALLERGIES', true);
    try {
      const patientdrugAllergies = await dispatch('fetchPatientRiskFactor', {
        route: `api/patients/${patientUuid}/drug_allergies`,
        patientUuid,
        riskFactorName: 'drugAllergies',
      });
      patientdrugAllergies.forEach(drugAllergy => commit('SET_DRUG_ALLERGY', drugAllergy));
    } finally {
      commit('SET_IS_LOADING_DRUG_ALLERGIES', false);

      if (! state.hasLoadedPatientDrugAllergies[patientUuid]) {
        commit('SET_HAS_LOADED_PATIENT_DRUG_ALLERGIES', {
          patientUuid,
          hasLoadedDrugAllergies: true,
        });
      }
    }
  },
  async insertPatientDrugAllergy ({ commit }, drugAllergy) {
    const { data } = await postToAPI('api/patient_drug_allergies', drugAllergy);
    commit('SET_DRUG_ALLERGY', data);
  },
  async updatePatientDrugAllergy ({ commit }, drugAllergy) {
    const { data } = await putToAPI(drugAllergy.getIri(), { data: drugAllergy });
    commit('SET_DRUG_ALLERGY', data);
  },
  async deletePatientDrugAllergy ({ commit }, drugAllergy) {
    await deleteFromAPI(drugAllergy.getIri());
    commit('DELETE_DRUG_ALLERGY', drugAllergy);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};