/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation';

const consultationEntity = new DocumentEntity('Consultation', 'consultations.xml', 'Contient les données des consultations');

consultationEntity.addField(new DocumentEntityField('prescriptions', 'Les prescriptions', new DocumentEntityRelation('Prescription', 'id')));
consultationEntity.addField(new DocumentEntityField('observation', 'L\'observation'));
consultationEntity.addField(new DocumentEntityField('documents', 'Les documents', new DocumentEntityRelation('ConsultationDocument', 'id')));
consultationEntity.addField(new DocumentEntityField('vaccine', 'Les vaccins', new DocumentEntityRelation('ConsultationVaccine', 'id')));

export default consultationEntity;