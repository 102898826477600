<template>
  <patient-sidebar-panel-listing-item
    :title="riskFactor.label"
    :description="riskFactor.note"
    :actions="actions"
  >
    <template
      v-if="riskFactor.icd10Code"
      #prepend-title
    >
      <app-tag
        v-test="'risk-factor-icd10-code'"
        x-small
        color="content"
        class="px-2"
      >
        {{ riskFactor.icd10Code }}
      </app-tag>
    </template>
    <template
      v-if="riskFactor.important || (isPathology && hasDate)"
      #append-title
    >
      <app-icon
        v-if="riskFactor.important"
        v-test="'risk-factor-important'"
        v-tooltip="'Important'"
        :size="13"
        color="error"
        icon="warning"
      />
      <app-tag
        v-if="isPathology && hasDate"
        v-test="'risk-factor-pathology-dates'"
        x-small
        color="secondary"
        class="ml-1"
        :title="datesString"
      >
        {{ datesState }}
      </app-tag>
    </template>
  </patient-sidebar-panel-listing-item>
</template>

<script>

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import PatientHealthEntity from '@/modules/patient/models/healthEntity/PatientHealthEntity';
import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';
import NovaTools from '@/nova-tools/NovaTools';

import AppTag from '@/components/ui/tag/AppTag.vue';
import PatientSidebarPanelListingItem from '@/modules/patient/components/PatientSidebarPanelListingItem.vue';

/**
 * Un élément du listing générique d'un facteur de risque
 */
export default {
  name: 'PatientSidebarRiskFactor',
  components: {
    PatientSidebarPanelListingItem,
    AppTag,
  },
  props: {
    riskFactor: {
      type: [PatientHealthEntity],
      required: true,
    },
    actions: {
      type: Array,
      default: null,
      validator: actions => actions.every(action => action instanceof ActionMenuItem),
    },
  },
  computed: {
    isPathology () {
      return this.riskFactor instanceof PatientPathology;
    },
    hasDate () {
      return (this.riskFactor.startDate !== null || this.riskFactor.endDate !== null);
    },
    datesState () {
      if (! this.isPathology || ! this.hasDate) {
        return null;
      }
      const now = NovaTools.dates.format(new Date(), 'yyyy-MM-dd');
      const startDate = this.riskFactor.startDate === null ? null : this.riskFactor.startDate;
      const endDate = this.riskFactor.endDate === null ? null : this.riskFactor.endDate;

      if (startDate !== null && startDate > now) {
        return 'À venir';
      }
      if ((startDate === null || startDate <= now) && (endDate === null || endDate >= now)) {
        return 'En cours';
      }
      return 'Terminée';
    },
    datesString () {
      if (! this.isPathology || ! this.hasDate) {
        return null;
      }
      if (this.riskFactor.startDate === null) {
        return `jusqu'au ${NovaTools.dates.format(this.riskFactor.endDate, 'dd/MM/yyyy')}`;
      }
      if (this.riskFactor.endDate === null) {
        return (
          (this.riskFactor.startDate > NovaTools.dates.format(new Date(), 'yyyy-MM-dd'))
            ? 'à partir du ' : 'depuis le '
        ) + NovaTools.dates.format(this.riskFactor.startDate, 'dd/MM/yyyy');
      }
      return `${NovaTools.dates.format(this.riskFactor.startDate, 'dd/MM/yyyy')} -
      ${NovaTools.dates.format(this.riskFactor.endDate, 'dd/MM/yyyy')}`;
    },
  },
};
</script>