<template>
  <validation-provider
    ref="validator"
    v-slot="{ errors }"
    :rules="rules"
    slim
    mode="passive"
  >
    <v-combobox
      ref="combobox"
      v-model="localValue"
      :label="fieldLabel"
      :items="localItems"
      :item-value="itemKey"
      :item-text="itemText"
      :item-disabled="itemDisabled"
      :search-input.sync="emailString"
      :hint="hint"
      persistent-hint
      :error="errors.length > 0"
      :error-messages="errors"
      :class="{ 'messaging-emails-selector--is-editing-email': isEditing }"
      class="messaging-emails-selector pt-2"
      multiple
      :return-object="false"
      hide-details="auto"
      :hide-no-data="localLoading"
      :loading="localLoading"
    >
      <template #selection="{ item, index }">
        <app-tag
          small
          :color="isEmailValid(item) ? 'secondary' : 'error'"
          :closable="! isEmailEdited(index)"
          class="messaging-emails-selector__chip"
          @click:close="deleteEmail(item)"
          @click="editEmail(index)"
        >
          <input
            v-if="isEmailEdited(index)"
            ref="emailInput"
            v-model="currentEditingEmail"
            v-test="'chip-input'"
            v-click-outside="blurChipInput"
            class="messaging-emails-selector__chip__input"
            type="text"
            @keydown.enter="event => handleEditionBlur(event.target.value, index)"
            @keydown.esc.stop="blurChipInput"
          >
          <span
            v-test="'chip-text'"
            :class="{'messaging-emails-selector__chip__text--hidden': isEmailEdited(index)}"
            class="messaging-emails-selector__chip__text"
          >
            {{ getChipText(item, index) }}
          </span>
        </app-tag>
      </template>
      <template #append-item>
        <slot name="append-item" />
      </template>
      <template #item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          @click="selectItem(item)"
          @keydown.enter="selectItem(item)"
        >
          <slot
            name="item"
            :item="item"
            :on="on"
          />
        </v-list-item>
      </template>
      <template #no-data>
        <slot name="no-data" />
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script>
import { validate, ValidationProvider } from 'vee-validate';

import fieldsMixin from '@/mixins/fields';

import AppTag from '@/components/ui/tag/AppTag.vue';

const ERROR_MESSAGE = 'Un ou plusieurs e-mails sont invalides';

export default {
  name: 'AppEmailsField',
  components: {
    AppTag,
    ValidationProvider,
  },
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: [String, Function],
      default: 'text',
    },
    itemKey: {
      type: [String, Function, Array],
      default: 'value',
    },
    itemDisabled: {
      type: [String, Function],
      default: 'disabled',
    },
    label: {
      type: String,
      default: 'Destinataires',
    },
    rules: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      localValue: this.value,
      localItems: this.items,
      localLoading: this.loading,
      selectedItems: [],
      emailString: null,
      invalidEmails: [],
      editedEmailIndex: null,
      currentEditingEmail: null,
    };
  },
  computed: {
    isEditing () {
      return this.editedEmailIndex !== null;
    },
    localValueLength () {
      return this.localValue.length;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (emails) {
        emails.forEach(this.validateEmail);
        this.localValue = emails;
      },
    },
    items (items) {
      this.localItems = items;
    },
    emailString (emailString) {
      this.$emit('input:search', emailString);
    },
    currentEditingEmail (currentEditingEmail) {
      this.$emit('input:search', currentEditingEmail);
    },
    localValueLength (localValueLength, oldLocalValueLength) {
      if (! localValueLength && this.rules) {
        this.validatePropsRules();
      }
      if (localValueLength < oldLocalValueLength) {
        this.$delete(this.invalidEmails, this.invalidEmails.findIndex(email => this.localValue.indexOf(email) < 0));
        return;
      }
      if (this.isEditing) {
        return;
      }
      this.validateEmail(this.localValue[localValueLength - 1]);
      this.emailString = '';
    },
    async invalidEmails (invalidEmails) {
      if (! invalidEmails.length) {
        this.$refs.validator.setErrors([]);
        return;
      }
      this.$refs.validator.setErrors([ERROR_MESSAGE]);
    },
    loading (loading) {
      this.localLoading = loading;
    },
  },
  methods: {
    deleteEmail (email) {
      this.$delete(this.localValue, this.localValue.indexOf(email));
      this.$delete(this.invalidEmails, this.invalidEmails.indexOf(email));
    },
    async editEmail (emailIndex) {
      if (this.isEmailEdited(emailIndex)) {
        return;
      }
      this.currentEditingEmail = this.getChipText(this.localValue[emailIndex], emailIndex);
      this.editedEmailIndex = emailIndex;
      await this.$nextTick();
      this.$refs.emailInput.focus();
      this.$refs.emailInput.select();
    },
    isEmailEdited (emailIndex) {
      return this.editedEmailIndex === emailIndex;
    },
    isEmailValid (email) {
      return ! this.invalidEmails.includes(email);
    },
    async validatePropsRules () {
      const { errors } = await validate(this.localValue, this.rules);
      this.$refs.validator.setErrors(this.$refs.validator.errors.includes(ERROR_MESSAGE) ? [ERROR_MESSAGE] : errors);
    },
    async validateEmail (email) {
      const { valid } = await validate(email, 'email');
      if (! valid) {
        if (this.isEmailValid(email)) {
          this.invalidEmails.push(email);
        }
        return;
      }
      this.$delete(this.invalidEmails, this.invalidEmails.indexOf(email));
      await this.validatePropsRules();
    },
    blurChipInput () {
      this.editedEmailIndex = null;
      this.currentEditingEmail = null;
    },
    handleEditionBlur (newEmail, emailIndex) {
      if (this.editedEmailIndex !== null) {
        this.blurChipInput();
      }
      const originalEmail = this.localValue[emailIndex];
      if (! this.isEmailValid(originalEmail)) {
        this.$set(this.invalidEmails, this.invalidEmails.indexOf(originalEmail), newEmail);
      }
      this.$set(this.localValue, emailIndex, newEmail);
      this.validateEmail(this.localValue[emailIndex]);
      this.currentEditingEmail = null;
    },
    getChipText (itemText, index) {
      if (this.isEmailEdited(index)) {
        return this.currentEditingEmail;
      }

      // Vérification parmi les éléments déjà sélectionnés
      let [selectedItem] = this.selectedItems.filter(item => item.mssEmail === itemText);
      if (selectedItem) {
        return this.itemText(selectedItem);
      }

      // Vérification parmi les éléments chargés par la recherche
      [selectedItem] = this.localItems.filter(item => item.mssEmail === itemText);
      if (selectedItem) {
        this.selectedItems.push(selectedItem);
        return this.itemText(selectedItem);
      }

      return itemText;
    },
    selectItem (item) {
      const email = item.mssEmail;
      if (this.localValue.indexOf(email) >= 0) {
        // un clic sur un élément sélectionné supprime la sélection
        const index = this.localValue.indexOf(email);
        this.localValue.splice(index, 1);
        return;
      }
      if (this.editedEmailIndex !== null) {
        if (! this.isEmailValid(this.localValue[this.editedEmailIndex])) {
          this.$delete(this.invalidEmails, this.invalidEmails.indexOf(this.localValue[this.editedEmailIndex]));
        }
        this.$set(this.localValue, this.editedEmailIndex, email);
        this.validateEmail(email);
        return;
      }
      this.localValue.push(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.messaging-emails-selector {
  ::v-deep {
    .v-chip__content {
      position: relative;
      padding: 0 1px;
    }
  }

  &--is-editing-email {
    ::v-deep {
      .v-select__selections > input {
        display: none;
      }

      .v-chip__content {
        width: 100%;
      }
    }
  }

  &--is-editing-email &__chip {
    user-select: all;
  }

  &__chip {
    display: grid;
    cursor: text;
    margin-left: 0;
    z-index: 0;

    &:before {
      display: none;
    }

    &__input {
      position: absolute !important;
      width: 100%;
      left: 0;
      color: white !important;
      padding: 0px;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;

      &--hidden {
        visibility: hidden;
      }
    }
  }
}

::v-deep {
  .v-subheader {
    height: 30px;
  }
}
</style>