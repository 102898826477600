<template>
  <!-- eslint-disable -->
  <v-dialog v-model="isOpen" fullscreen persistent>
  <!--eslint-enable-->
    <fullscreen-layout
      title="Revenir sur easy-care"
      @goBack="$emit('update:isOpen', false)"
    >
      <iframe
        v-if="isOpen"
        ref="iframeIcanopee"
        class="iframe-style"
        width="100%"
        loading="lazy"
        :src="url"
      />
    </fullscreen-layout>
  </v-dialog>
</template>

<script>


import { GENDERS } from '@/constants';
import { INS_FETCH_METHOD } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { getNirObject } from '@/services/vendors/icanopee/utils/dataNormalizer';
import { getEnv } from '@/utils/functions/env';

import FullscreenLayout from '@/layout/Fullscreen.vue';

export default {
  name: 'ICanopeeModal',
  components: { FullscreenLayout },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient,
      required: true,
    },
    callType: {
      type: String,
      required: true,
    },
    isPartnerAccess: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isCheckCall () {
      return this.callType === INS_FETCH_METHOD.IDENTITY_CHECK;
    },
    url () {
      if (this.callType === INS_FETCH_METHOD.CV) {
        return `${import.meta.env.VUE_APP_ICANOPEE_INSI_BASE_URL}/search/vitale`;
      }
      if (this.callType === INS_FETCH_METHOD.TRAIT) {
        return `${import.meta.env.VUE_APP_ICANOPEE_INSI_BASE_URL}/search/ins#${this.getQueryParams()}`;
      }
      return `${import.meta.env.VUE_APP_ICANOPEE_INSI_BASE_URL}/search/checkIdentity#${this.getQueryParamsAndDisabled()}`;
    },
    ins () {
      const { s_nir: sNir } = getNirObject(this.patient.insIdentity.ins);
      return sNir || null;
    },
    key () {
      const { s_key: sKey } = getNirObject(this.patient.insIdentity.ins);
      return sKey || null;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler (isOpen) {
        if (! isOpen) {
          window.removeEventListener('message', this.onMessage);
          return;
        }
        window.addEventListener('message', this.onMessage);
        if(this.isPartnerAccess) {
          await this.sendCodePinToIcanopeeIframe(5000);
        }
      },
    },
  },
  methods: {
    async sendCodePinToIcanopeeIframe (waitingTime) {
      const pinCode = NovaTools.security.codePin.getCodePin();
      if (pinCode === null) {
        return;
      }

      if (waitingTime !== 0) {
        await this.$nextTick();
      }

      const iframe = this.$refs.iframeIcanopee;
      if (iframe && iframe.contentWindow) {
        if (waitingTime === 0) {
          iframe.contentWindow.postMessage(JSON.stringify({ pinCode }), '*');
          return;
        }
        setTimeout(() => {
          iframe.contentWindow.postMessage(JSON.stringify({ pinCode }), '*');
        }, waitingTime);
      }
    },
    getQueryParams () {
      let sex;
      switch (this.patient.gender) {
        case GENDERS.MALE.value:
          sex = 2;
          break;
        case GENDERS.FEMALE.value:
          sex = 3;
          break;
        default:
          sex = 1;
      }

      const params = {
        name: this.patient.birthName || this.patient.familyName || '',
        given: this.patient.firstNames || this.patient.firstName || '',
        sex,
        birthday: this.patient.birthDate
          ? NovaTools.dates.format(this.patient.birthDate, 'dd/MM/yyyy')
          : '',
        birthplace: this.patient.birthPlaceCode,
        ins: this.ins,
        key: this.key,
      };
      return Object.keys(params)
        .filter(key => params[key] !== null && params[key] !== undefined)
        .map(key => `${key}=${params[key]}`).join('&');
    },
    getQueryParamsAndDisabled () {
      return `${this.getQueryParams()}&readOnly=name,given,birthday,sex,birthplace,ins,key`;
    },
    async onMessage ({ origin, data }) {
      if (origin !== getEnv('VUE_APP_ICANOPEE_INSI_BASE_URL')) {
        return;
      }

      if (data.type === 'waiting_for_pincode_entry') {
        await this.sendCodePinToIcanopeeIframe(0);
        return;
      }

      if (data.type !== 'expired_session') { // Message de session expirée. Affiche la page de connexion. Pas de fermeture de la modale.
        this.$emit('fetch-result', {
          data,
          callType: this.callType,
        });

        if (this.isCheckCall) {
          this.handleCheckMessage(data);
        } else {
          this.handleFetchMessage(data);
        }
      }
    },
    handleFetchMessage (message) {
      if (message.error) {
        this.$emit('error', message);
      } else if (message.CR) {
        this.$emit('success', message);
      }
    },
    handleCheckMessage (message) {
      if (message.identityIsValid) {
        this.$emit('success', message);
      } else {
        this.$emit('error', message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fullscreen-layout {
  background-color: #FFFFFF;
  ::v-deep {
    .page-layout {
      .page-layout__content {
        padding: 0;
        display: flex;
      }
    }
  }
}

.iframe-style {
  border: none;
  flex: 1;
}
</style>