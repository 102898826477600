export default class PatientMeasureUserSetting {
  constructor (options = {}) {
    this['@id'] = options['@id'];
    this.createdAt = options.createdAt || null;
    this.updatedAt = options.updatedAt || null;
    this.name = options.name || null;
    this.unit = options.unit || null;
    this.type = options.type || null;
    this.defaultSetting = options.defaultSetting || false;
    this.enabled = options.enabled || false;
    this.sortIndex = options.sortIndex || false;
    this.editable = options.editable ?? true;
  }
}