<template>
  <n-portal to="overlays">
    <v-navigation-drawer
      v-model="isOpen"
      :right="! fromLeft"
      fixed
      temporary
      overlay-color="content"
      data-test="drawer"
      v-bind="{...$attrs}"
      :width="width"
      @transitionend="$emit('transitionend')"
    >
      <v-card
        data-test="drawer-content"
        elevation="0"
        class="app-navigation-drawer d-flex flex-column flex-grow-1"
        :class="{ 'app-navigation-drawer--scrollable': scrollable }"
      >
        <v-card-title class="pa-0 d-block">
          <h2 class="app-navigation-drawer__headline">
            <slot name="header">
              <app-button
                dark
                icon="left"
                :color="cancelColor"
                class="app-navigation-drawer__back-button"
                @click="() => onPrevious ? onPrevious(closeDrawer) : closeDrawer()"
              />
              <span class="app-navigation-drawer__headline__title">{{ title }}</span>
            </slot>
          </h2>
          <app-divider v-if="scrollable" />

          <app-linear-loader
            v-if="loading"
            class="app-navigation-drawer__loader"
            data-test="drawer-loader"
          />
        </v-card-title>
        <v-card-text
          class="px-6 app-navigation-drawer__body white content--text"
          :class="{ 'pt-6': scrollable }"
        >
          <slot />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </n-portal>
</template>

<script>
import AppDivider from '@/components/ui/divider/AppDivider.vue';
import AppLinearLoader from '@/components/ui/loaders/AppLinearLoader.vue';

export default {
  name: 'AppNavigationDrawer',
  components: {
    AppDivider,
    AppLinearLoader,
  },
  props: {
    /**
     * Défini si le drawer est visible ou pas
     * @model
     */
    value: {
      type: Boolean,
      default: true,
    },
    /**
     * Intitulé de la modale
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * La couleur du bouton d'annulation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "blue-grey"
     */
    cancelColor: {
      type: String,
      required: false,
      default: 'content',
    },
    /**
     * Largeur du drawer
     */
    width: {
      type: [Number, String],
      default: 480,
    },
    /**
     * Indique si le contenu du drawer doit être scrollable
     * @default false
     */
    scrollable: {
      type: Boolean,
      default: false,
    },
    /**
     * Callback appelé lors de la pression du bouton précédent
     * Récupère en paramètre la méthode pour fermer le drawer
     */
    onPrevious: {
      type: Function,
      default: null,
    },
    /**
     * Applique une barre de chargement au composant
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },
    fromLeft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOpen: {
      get () {
        return this.value;
      },
      set (isOpen) {
        this.$emit('input', isOpen);
      },
    },
  },
  methods: {
    closeDrawer () {
      this.$emit('input', false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-navigation-drawer {
    &__content {
      flex-direction: column;
      display: flex;
      overflow: hidden;
    }
  }

  .app-navigation-drawer {
    height: 100vh;

    &--scrollable {
      .app-navigation-drawer__body {
        flex: 1;
        overflow: auto;
      }
    }

    &__content {
      flex-direction: column;
      display: flex;
    }

    &__headline {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 300;
      width: 100%;
      height: 60px;
      padding: 0 map-get($spacers, 4);

      @include media-md {
        height: 85px;
        padding: 0 map-get($spacers, 6);
      }

      &__title {
        font-size: 16px;
        font-weight: 600;
        margin-left: map-get($spacers, 2);

        @include media-md {
          font-size: 20px;
          font-weight: 700;
          margin-left: map-get($spacers, 4);
        }
      }
    }

    &__loader {
      position: absolute;
    }

    &__body {
      flex-direction: column;
      display: flex;
      flex: unset;
      overflow: unset;
      flex-basis: 0;
    }

    &:not(.app-navigation-drawer--scrollable) {
      .app-navigation-drawer__footer {
        padding-top: 0 !important;
      }
    }
  }
}
</style>