<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Type de l'arrêt de travail"
      class="medical-leave-nature-fields"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <app-buttons-group
        v-test="'type-form-section'"
        :value="localType"
        :items="[{ label: 'Initial', value: 'initial' }, { label: 'Prolongation', value: 'prolongation' }]"
        :show-icon="false"
        :mandatory="true"
        item-text="label"
        v-bind="{ ...$props, ...$attrs }"
        class="mb-1 ml-2"
        @input="handleTypeInput"
      />
      <n-radio-button-group
        v-if="showPrescriberFields"
        v-test="'prescriber-code-form-section'"
        :value="prescriberCode"
        label="Prolongé par le médecin :"
        :items="MEDICAL_LEAVE_PRESCRIBER_DATA.slice(0, -1)"
        required
        class="ml-2"
        @input="handlePrescriberCodeInput"
      >
        <template #append>
          <div class="medical-leave-nature-fields__prescriber--last">
            <n-radio-button
              :key="lastMedicalLeavePrescriberItemIndex"
              :value="lastMedicalLeavePrescriberItem.value"
              :label="lastMedicalLeavePrescriberItem.label"
            />
            <app-text-field
              ref="prescriber-label-text-input"
              v-test="'prescriber-label-text-input'"
              class="ml-2 pt-0"
              :value="prescriberLabel"
              :disabled="disablePrescriberLabelField"
              required
              :rules="{ required: !disablePrescriberLabelField }"
              @input="$emit('update:prescriber-label', $event)"
            />
          </div>
        </template>
      </n-radio-button-group>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { MEDICAL_LEAVE_PRESCRIBER_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeavePrescriberData';

import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue';



export default {
  name: 'MedicalLeaveTypeFields',
  components: {
    AatiFormStep,
    AppButtonsGroup,
    AppTextField,
    ValidationObserver,
  },
  props: {
    type: {
      type: String,
      default: 'initial',
    },
    prescriberCode: {
      type: String,
      default: null,
    },
    prescriberLabel: {
      type: String,
      default: null,
    },
    atmpIndication: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 1,
      MEDICAL_LEAVE_PRESCRIBER_DATA,
      localType: null,
    };
  },
  computed: {
    showPrescriberFields () {
      return this.type === 'prolongation' && ! this.atmpIndication;
    },
    disablePrescriberLabelField () {
      return this.prescriberCode !== '8';
    },
    lastMedicalLeavePrescriberItemIndex () {
      return MEDICAL_LEAVE_PRESCRIBER_DATA.length - 1;
    },
    lastMedicalLeavePrescriberItem () {
      return MEDICAL_LEAVE_PRESCRIBER_DATA[this.lastMedicalLeavePrescriberItemIndex];
    },
  },
  watch: {
    type: {
      immediate: true,
      handler () {
        if (! this.type) {
          this.handleTypeInput('initial');
        }
        this.localType = this.type;
      },
    },
    atmpIndication: {
      immediate: true,
      handler () {
        if (this.atmpIndication) {
          this.handlePrescriberCodeInput('MT');
        }
      },
    },
  },
  methods: {
    handleTypeInput (type) {
      this.$emit('update:type', type);
      if (type === 'initial') {
        this.$emit('update:prescriber-code', null);
        this.$emit('update:prescriber-label', null);
      }
    },
    handlePrescriberCodeInput (prescriberCode) {
      this.$emit('update:prescriber-code', prescriberCode);
      if (prescriberCode === '8') {
        requestAnimationFrame(() => this.$refs['prescriber-label-text-input']?.focus());
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.medical-leave-nature-fields__prescriber--last {
  display: flex;
  align-items: center;

  ::v-deep {
    .app-text-field {
        max-width: 50%;
    }
  }
}
</style>