<template>
  <app-panel
    title="Médecin traitant"
    class="mb-last-0"
    :actions="actionByReferringPhysician"
  >
    <app-modal-form
      v-model="editingReferringPhysician"
      :title="modaleTitleByReferringPhysician"
      :form="ReferringPhysicianForm"
      :patient="patient"
      :scrollable="false"
      :is-opened="isModaleOpened"
      @cancel="closeModale"
      @submitSuccess="onSubmitSuccess"
    />
    <div
      v-if="loading"
      class="mb-last-0"
    >
      <div
        v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
        :key="index"
        v-test="'referring-physician-skeleton'"
        class="mb-4"
      >
        <app-skeleton-loader
          type="text"
          width="40%"
        />
        <app-skeleton-loader
          type="text"
          width="60%"
        />
      </div>
    </div>
    <correspondents-item-body
      v-else-if="referringPhysician"
      v-test="'correspondents-item-body'"
      :correspondent="referringPhysician"
    />
  </app-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ReferringPhysicianForm from './ReferringPhysicianForm.vue';
import Patient from '@/modules/patient/models/Patient';
import { getFromAPI } from '@/services/api';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import AppModalForm from '@/components/ui/modal/AppModalForm.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import CorrespondentsItemBody
  from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/CorrespondentsItemBody.vue';




const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 1;
const CORRESPONDENT_BY_CONTACT_ROUTE = '/api/correspondents/contact/';

export default {
  name: 'ReferringPhysicianPanel',
  components: {
    AppSkeletonLoader,
    AppPanel,
    AppModalForm,
    CorrespondentsItemBody,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      addAction: {
        add: {
          callback: this.openModale,
          icon: 'add',
          text: 'Ajouter le médecin traitant',
        },
      },
      editAction: {
        edit: {
          callback: this.openModale,
          icon: 'edit',
          text: 'Modifier le médecin traitant',
        },
      },
      isModaleOpened: false,
      ReferringPhysicianForm,
      loading: false,
      editingReferringPhysician: null,
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
      CORRESPONDENT_BY_CONTACT_ROUTE,
    };
  },
  computed: {
    ...mapGetters('contact', ['getReferringPhysicianByPatient']),
    actionByReferringPhysician () {
      return this.referringPhysician ? this.editAction : this.addAction;
    },
    modaleTitleByReferringPhysician () {
      return this.referringPhysician ? 'Modifier le médecin traitant' : 'Ajouter un médecin traitant';
    },
    referringPhysician () {
      return this.getReferringPhysicianByPatient(this.patient['@id']);
    },
  },
  async beforeMount () {
    await this.loadReferringPhysician();
  },
  methods: {
    ...mapActions('contact', ['setReferringPhysicianByCorrespondent']),
    async loadReferringPhysician () {
      this.loading = true;
      await this.addReferringPhysicianInStore(this.patient);
    },
    openModale () {
      this.editingReferringPhysician = this.referringPhysician;
      this.isModaleOpened = true;
    },
    closeModale () {
      this.editingReferringPhysician = null;
      this.isModaleOpened = false;
    },
    async onSubmitSuccess (updatedPatient) {
      this.loading = true;
      this.closeModale();
      const patient = new Patient(updatedPatient?.data);
      await this.addReferringPhysicianInStore(patient);
    },
    async addReferringPhysicianInStore (patient) {
      let storedReferringPhysician = null;
      if (patient && patient.referringPhysician) {
        const correspondent = await getFromAPI(this.CORRESPONDENT_BY_CONTACT_ROUTE + patient.referringPhysician.getUuid());
        storedReferringPhysician = correspondent.data;
      }
      await this.setReferringPhysicianByCorrespondent({
        patientId: this.patient['@id'],
        referringPhysician: storedReferringPhysician,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.referring-physician-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>