import { GENDERS, CIVILITIES } from '@/constants';

/**
 * Permet d'obtenir le genre d'une personne à partir de sa civilité
 * @param {Object} civility l'objet civilité fournie par les tables de références
 */
export const getGenderFromCivility = civility => {
  const GENDER_BY_CIVILITY = {
    [CIVILITIES.MR.value]: GENDERS.MALE.value,
    [CIVILITIES.MRS.value]: GENDERS.FEMALE.value,
    [CIVILITIES.DR.value]: GENDERS.MALE.value,
    [CIVILITIES.DR_F.value]: GENDERS.FEMALE.value,
    [CIVILITIES.PR.value]: GENDERS.MALE.value,
    [CIVILITIES.PR_F.value]: GENDERS.FEMALE.value,
  };

  return GENDER_BY_CIVILITY[civility] || GENDERS.UNKNOWN.value;
};

/**
 * Permet d'obtenir la civilité d'une personne à partir de son genre
 * @param {String} gender le genre fourni par les tables de références
 */
export const getCivilityFromGender = gender => {
  const CIVILITY_BY_GENDER = {
    [GENDERS.MALE.value]: CIVILITIES.MR.value,
    [GENDERS.FEMALE.value]: CIVILITIES.MRS.value,
  };

  return CIVILITY_BY_GENDER[gender] || null;
};

/**
 * Permet d'afficher la civilité d'une personne en label court (ex : M, Mme, Dr)
 * @param {Object} civility l'objet civilité fournie par les tables de références
 */
export const getCivilityShortLabel = civility => {
  if (civility) {
    const civilityObject = Object.values(CIVILITIES).find(
      civ => civ.value === civility,
    );
    return civilityObject?.shortLabel || '';
  }
  return '';
};

/**
 * Permet d'obtenir une ref complète à partir d'une table de référence et d'une value
 * @param {Object} ref la table de référence souhaitée
 * @param {String} value la valeur cible
 */
export const getRefFromValue = (ref, value) => Object.values(ref).find(ref => ref.value === value);

export const getRefAsArray = ref => Object.keys(ref).map(el => ref[el]);
