import MedicalSpecialtyRef from './MedicalSpecialtyRef';
import PractitionerSignature from './PractitionerSignature';
import MssAccountDataset from '@/modules/secureMessaging/models/MssAccountDataset';

export default class PractitionerProfile {
  constructor (options = {}) {

    this['@id'] = options['@id'] || null;
    this.createdAt = options.createdAt || null;
    this.updatedAt = options.updatedAt || null;
    this.rpps = options.rpps || null;
    this.medicalSpecialtyRef = options.medicalSpecialtyRef
      ? new MedicalSpecialtyRef(options.medicalSpecialtyRef)
      : null;
    this.qualifications = options.qualifications || null;
    this.diplomas = options.diplomas || null;
    this.otherInformation = options.otherInformation || null;
    this.mssAccountDataset = options.mssAccountDataset ? new MssAccountDataset(options.mssAccountDataset) : null;
    this.signature = options.signature
      ? new PractitionerSignature(options.signature)
      : null;
  }
}