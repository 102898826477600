import ApiEntity from '@/models/ApiEntity';
import Consultation from '@/modules/patient/models/Consultation';
import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote';

export default class MedicalHistoryItem extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.type = options.type || null;
    this.item = options.item ? this.initItem(options.item) : null;
  }

  initItem (item) {
    if (this.isConsultation()) {
      return new Consultation(item);
    }
    if (this.isNote()) {
      return new MedicalHistoryNote(item);
    }
    return null;
  }

  isConsultation () {
    return (this.type === 'consultation');
  }

  isNote () {
    return (this.type === 'note');
  }
}