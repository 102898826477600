<template>
  <app-form :submit-method="updateBloodType">
    <app-autocomplete
      v-test="'blood-type-field'"
      name="bloodType"
      :value="bloodType"
      :loading="isUpdatingMedicalDataset"
      :readonly="isUpdatingMedicalDataset"
      :items="BLOOD_TYPES"
      hide-errors="auto"
      clearable
      label="Groupe sanguin"
      autofocus
      placeholder="Non renseigné"
      @change="(bloodType) => selectedBloodType = bloodType"
    />
  </app-form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import { BLOOD_TYPES } from '@/modules/patient/constants';
import MedicalDataset from '@/modules/patient/models/MedicalDataset';
import Patient from '@/modules/patient/models/Patient';


import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppForm from '@/components/ui/form/AppForm.vue';


export default {
  name: 'MedicalDatasetForm',
  components: {
    AppForm,
    AppAutocomplete,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    medicalDataset: {
      type: MedicalDataset,
      required: true,
    },
  },
  data () {
    return {
      isUpdatingMedicalDataset: false,
      selectedBloodType: this.medicalDataset.bloodType,
      BLOOD_TYPES,
    };
  },
  computed: {
    bloodType () {
      return this.medicalDataset.bloodType;
    },
  },
  methods: {
    ...mapActions('patient', ['updatePatientMedicalDataset']),
    ...mapMutations('app', ['SET_SNACK']),
    async updateBloodType () {
      this.isUpdatingMedicalDataset = true;
      try {
        await this.updatePatientMedicalDataset({
          patient: this.patient,
          medicalDataset: {
            ...this.medicalDataset,
            bloodType: this.selectedBloodType,
          },
        });
        this.SET_SNACK({ message: 'Les informations médicales ont été modifiées avec succès' });
      } finally {
        this.isUpdatingMedicalDataset = false;
      }
    },
  },
};
</script>