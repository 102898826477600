/**
 * Permet d'ajouter un nombre de charactère défini à un nombre
 * ex : 9 -> 009
 * @param {string} n le nombre de départ
 * @param {Number} width le nombre de caractère total souhaité
 * @param {String} [z=0] le caractère à ajouter (Optionnel)
 * @returns {String} Le nouveau nombre avec ses caractères additionnels
 */
export const leftPad = (n, width, z = '0') => {
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * Retourne un nombre à sa valeur inférieure multiple de step
 * Exemple : n = 58, step = 15 | Retourne 45
 * @param {Number} n le nombre de départ
 * @param {Number} step Le multiple
 * @returns {Number} Le nouveau nombre
 */
export const floorToNearest = (n, step) => n - (n % step);

/**
 * Retourne un nombre valide à partir d'une string, d'un entier ou d'un flottant
 * @param {Number|String} number le nombre de départ
 * @returns {Number} Le nouveau nombre
 */
export const parseNumber = (number) => {
  if (number.replace) {
    number = number.replace(',', '.');
  }

  return parseFloat(number);
};

/**
 * Permet d'obtenir l'évolution d'un nombre à partir d'un pourcentage
 * @param {Number} number
 * @param {Number} percentage
 * @returns {Number}
 */
export const getNumberEvolution = (number, percentage) => number + (number * percentage || 0);