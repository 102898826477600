import RProxy from 'recursive-proxy';

/**
 * Classe permettant de centraliser la logique du scrolling infini
 * Pour usage sur l'API easy-care seulement
 */

import '@/utils/classes/httpRequestParameters/filters/BaseFilter';
import { getFromAPI } from '@/services/api';

import { isEqual } from 'lodash';

export default class EcApiInfiniteScroller {
  /**
   * URL de la ressource
   */
  apiResourceUrl = null;

  /**
   * Métadonnées sur les pages en cours
   */
  pages = {
    next: 1,
    last: 1,
  };

  /**
   * Contient les items récupérés
   */
  items = [];

  /**
   * Permet d'identifier les requêtes
   */
  requestId = 0;

  /**
   * Indique si le contenu a déjà été initialisé
   */
  hasInitializedContent = false;

  /**
   * Indique si la page suivante est deja en cours de récupération
   */
  isFetchingNextPage = false;

  /**
   * Les paramètres de la requête appliquée à la page actuelle
   */
  currentRequestPayload = null;

  /**
   * Indique le nombre d'éléments total (même non récupérés)
   */
  count = null;

  /**
   * Indique le nombre d'éléments par page désiré
   */
  itemPerPage = 30;

  /**
   * Liste des filtres à appliquer lors de la requête
   */
  filters = [];

  /**
   * Classe à utiliser pour instancier les ressources récupérées
   */
  itemModelClass = null;

  /**
   * Callbacks à appeler en cas de mise à jour de l'ensemble de la list
   */
  itemsUpdatedListeners = [];

  /**
   * Callbacks à appeler en cas de récupération du contenu d'une seule page terminée
   */
  fetchedPageItemListeners = [];

  /**
   * Callbacks à appeler en cas de vidage du contenu
   */
  resetListeners = [];

  /**
   * Convertisseur custom des résultats.
   * Permet de retourner une version différente de chaque résultat selon un prédicat.
   */
  resultConverter = null;

  /**
   * Permet de construire un EcApiInfiniteScroller
   * @param {Object} options Les options
   */
  constructor (options = {}) {
    this.apiResourceUrl = options.apiResourceUrl || null;
    this.itemPerPage = options.itemPerPage || this.itemPerPage;
    this.filters = options.filters || this.filters;
    this.itemModelClass = options.itemModelClass || this.itemModelClass;
    this.resultConverter = options.resultConverter || this.resultConverter;
  }

  /**
   *
   * @param {*} page
   * @returns
   */
  getRequestPayload (page) {
    const filtersAsObject = this.filters.reduce((obj, filter) => {
      const { name, value } = filter.getQueryParam();
      obj[name] = value;
      return obj;
    }, {});
    return {
      pagination: true,
      page,
      itemsPerPage: this.itemPerPage,
      ...filtersAsObject,
    };
  }

  /**
   * Permet de récupérer le contenu de la page demandée
   * @param {Number} page le numéro de page à récupérer
   * @param {Number} requestId L'identifiant unique de requête
   * @returns {void}
   */
  async getPageItems (page, requestId) {
    this.currentRequestPayload = this.getRequestPayload(page);
    const { data } = await getFromAPI(this.apiResourceUrl, this.currentRequestPayload);

    /**
     * Si la réponse obtenue ne correspond plus à la requête en cours
     * On ne traite pas le retour, afin de ne pas polluer les résultats
     * Avec un item arrivé trop tard.
     */
    if (this.requestId !== requestId) {
      return;
    }

    this.count = data['hydra:totalItems'];

    const ModelClass = this.itemModelClass;
    const parsedResults = data['hydra:member'].map(item => {

      if (this.resultConverter) {
        item = this.resultConverter(item);
      }

      return ModelClass ? new ModelClass(item) : item;
    });

    this.fetchedPageItemListeners.forEach(listener => listener(parsedResults));
    this.setItems([...this.items, ...parsedResults]);

    if (data['hydra:view']['hydra:next']) {
      if (data['hydra:totalItems']) {
        // Il n'est pas nécessaire de déterminer la dernière page sachant que cette dernière est connue dès le premier fetch
        this.pages.last = page === 1 ? this.getPageFromUrl(data['hydra:view']['hydra:last']) : this.pages.last;
      } else {
        // Pagination personnalisée, la dernière page ne peut être déterminée
        this.pages.last += 1;
      }
    }

    if (! this.hasInitializedContent) {
      this.hasInitializedContent = true;
    }

    this.pages.next += 1;
  }

  /**
   * Permet de déclencher le chargement de la page suivante
   */
  async loadNextPage () {
    if (this.canLoadNextPage()) {
      this.isFetchingNextPage = true;
      await this.getPageItems(
        this.pages.next,
        this.requestId,
      );
      this.isFetchingNextPage = false;
    }
  }

  /**
   * Extrait la page d'une URL de ressource
   * @param {String} apiResourceUrl Url de la ressource
   * @returns {Number} la page
   */
  getPageFromUrl (apiResourceUrl) {
    const [, page] = apiResourceUrl.match('page=([0-9]+)');
    return parseInt(page, 10);
  }

  /**
   * Permet de réinitialiser le scrolling
   */
  reset () {
    this.hasInitializedContent = false;
    this.pages = {
      next: 1,
      last: 1,
    };
    this.setItems([]);
    this.count = null;
    this.resetListeners.forEach(listener => listener(this.items));
  }

  /**
   * Met à jour l'url de la ressource
   * @param {String} apiResourceUrl l'url de la ressource
   */
  setApiResourceUrl (apiResourceUrl) {
    this.requestId += 1;
    this.apiResourceUrl = apiResourceUrl;
    this.reset();
  }

  /**
   * Met à jour les filtres actifs
   * @param {Array.<BaseFilter>} filters les filtres
   */
  async setFilters (filters) {
    this.requestId += 1;
    this.filters = filters;
    this.reset();
  }

  /**
   * Met à jour la classe
   * @param {Class} itemModelClass la classe
   */
  setItemModelClass (itemModelClass) {
    this.requestId += 1;
    this.itemModelClass = itemModelClass;
    this.reset();
  }

  /**
   * Met à jour le nombre d'item par page
   * @param {Number} itemPerPage nombre d'item par page
   */
  setItemPerPage (itemPerPage) {
    this.requestId += 1;
    this.itemPerPage = itemPerPage;
    this.reset();
  }

  /**
     * Permet de définir le convertisseur de résultat
     * @param {Function} resultConverter le convertisseur de résultat
     */
  setResultConverter (resultConverter) {
    this.requestId += 1;
    this.resultConverter = resultConverter;
    this.reset();
  }

  /**
   * Permet de définir la liste des items
   * @param {Array.<Object>} items le nouveau tableau d'items à lister
   */
  setItems (items) {
    this.items = items;

    this.itemsUpdatedListeners.forEach(listener => listener(this.items));
  }

  /**
   * Permet de récupérer la dernière page
   * @returns {Number} la dernière page
   */
  getLastPage () {
    return this.pages.last;
  }

  /**
   * Permet de savoir si la page suivante peut être chargée
   * @returns {Boolean} Si la page suivante peut être chargée
   */
  canLoadNextPage () {
    if (this.isFetchingNextPage) {
      return (this.isFetchingNextPage && ! isEqual(this.currentRequestPayload, this.getRequestPayload(this.pages.next)));
    }
    return (this.pages.next <= this.getLastPage()) && ! this.isFetchingNextPage;
  }

  /**
   * Permet de s'abonner à l'évènement de changement
   * du contenu du listing total
   * @param {Function} listener le callback
   */
  addItemsUpdatedListener (listener) {
    if (! this.itemsUpdatedListeners.find(listener)) {
      this.itemsUpdatedListeners.push(listener);
    }
  }

  /**
   * Permet de s'abonner à l'évènement de réception
   * de nouveau résultat de page
   * @param {Function} listener le callback
   */
  addFetchedPageItemsListener (listener) {
    if (! this.fetchedPageItemListeners.find(listener)) {
      this.fetchedPageItemListeners.push(listener);
    }
  }

  /**
   * Permet de s'abonner à l'évènement de vidage de la liste
   * @param {Function} listener le callback
   */
  addResetListener (listener) {
    if (! this.resetListeners.find(listener)) {
      this.resetListeners.push(listener);
    }
  }

  /**
   * Permet de retirer l'abonnement à l'évènement de vidage de la liste
   * de nouveau résultat de page
   */
  removeListeners () {
    this.itemsUpdatedListeners.splice(0, this.itemsUpdatedListeners.length);
    this.fetchedPageItemListeners.splice(0, this.fetchedPageItemListeners.length);
    this.resetListeners.splice(0, this.resetListeners.length);
  }

  refresh () {
    this.reset();
  }
}

/**
 * Permet d'injecter les mutations pour gérer automatiquement
 * les transformations d'un InfiniteScrolling
 * @returns {Object}
 */
export const mapInfiniteScrollerMutations = () => ({
  SET_INFINITE_SCROLLER_VALUE (state, { target, property, value }) {
    Reflect.set(target, property, value);
  },
});

/**
 * Permet de forcer l'utilisation d'une mutation en cas d'usage dans un store VueX
 * Nécessite l'utilisation de ...mapInfiniteScrollerMutations() dans les mutations
 * @param {VueXStore} store le store VueX (module ou global)
 * @param {EcApiInfiniteScroller} infiniteScroller Le scroller infini
 * @returns {ProxyConstructor.<EcApiInfiniteScroller>} Le proxy vers le scroller infini
 */
export const createVuexStoreProxy = (store, infiniteScroller) => {
  const proxyConfig = {
    followArray: true,
    followNonPlainObject: true,
    setter: {
      '': (target, property, value, path) => {
        if (! path.find(part => part.startsWith('_'))) {
          store.commit('SET_INFINITE_SCROLLER_VALUE', {
            target,
            property,
            value,
          });
          return true;
        }
        return '';
      },
    },
  };

  return new RProxy(proxyConfig, infiniteScroller);
};