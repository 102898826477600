export const CIVILITIES = Object.freeze({
  MR: {
    value: 'mr',
    label: 'Monsieur',
    shortLabel: 'M.',
  },
  MRS: {
    value: 'mrs',
    label: 'Madame',
    shortLabel: 'Mme',
  },
  DR: {
    value: 'dr',
    label: 'Docteur',
    shortLabel: 'Dr',
  },
  DR_F: {
    value: 'dr_f',
    label: 'Docteure',
    shortLabel: 'Dr',
  },
  PR: {
    value: 'pr',
    label: 'Professeur',
    shortLabel: 'Pr',
  },
  PR_F: {
    value: 'pr_f',
    label: 'Professeure',
    shortLabel: 'Pr',
  },
  ME: {
    value: 'me',
    label: 'Maître',
    shortLabel: 'Me',
  },
});