<template>
  <div class="dashboard-events-timeline-appointment-sub-content">
    <div class="dashboard-events-timeline-appointment-sub-content__infos">
      <app-badge v-if="motive" v-test="'appointment-motive'" :color="motive.color">
        {{ motive.name }}
      </app-badge>
      <app-badge
        v-if="appointmentPractitioner && isCurrentUserSecretary"
        v-test="'appointment-practitioner'"
        icon="practitioner"
        color="secondary"
      >
        {{ appointmentPractitioner }}
      </app-badge>
      <app-badge
        v-if="appointmentPlace"
        v-test="'appointment-activity-place'"
        icon="address"
        color="secondary"
      >
        {{ appointmentPlace }}
      </app-badge>
    </div>
    <p
      v-if="localAppointment.note"
      v-test="'appointment-note'"
      class="dashboard-events-timeline-appointment-sub-content__note"
    >
      {{ localAppointment.note }}
    </p>
    <div class="dashboard-events-timeline-appointment-sub-content__settings">
      <appointment-waiting-room-switch
        v-test="'waiting-room-switch'"
        :appointment.sync="localAppointment"
        @change="isSwitched => showMenu = !isSwitched"
      />
      <v-fade-transition>
        <appointment-waiting-room-actions-menu
          v-if="showMenu"
          v-test="'waiting-room-actions-menu'"
          :appointment="localAppointment"
          class="dashboard-events-timeline-appointment__actions"
        />
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { APPOINTMENT_STATES } from '@/modules/agenda/constants';
import Appointment from '@/modules/agenda/models/events/Appointment';
import Motive from '@/modules/agenda/models/Motive';
import Schedule from '@/modules/agenda/models/Schedule';

import AppBadge from '@/components/ui/badge/AppBadge.vue';
import AppointmentWaitingRoomActionsMenu from '@/modules/users/components/dashboard/events/timeline/appointment/AppointmentWaitingRoomActionsMenu.vue';
import AppointmentWaitingRoomSwitch from '@/modules/users/components/dashboard/events/timeline/appointment/AppointmentWaitingRoomSwitch.vue';




export default {
  name: 'DashboardEventsTimelineAppointmentDetails',
  components: {
    AppBadge,
    AppointmentWaitingRoomSwitch,
    AppointmentWaitingRoomActionsMenu,
  },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return {
      localAppointment: new Appointment(this.appointment),
      showMenu: this.appointment.state === APPOINTMENT_STATES.CONFIRMED.value,
    };
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserSecretary']),
    ...mapGetters('agenda', ['getScheduleFromIri', 'getAppointmentMotiveFromIri']),
    schedule () {
      return new Schedule(this.getScheduleFromIri(this.localAppointment.schedule));
    },
    motive () {
      return new Motive(this.getAppointmentMotiveFromIri(this.localAppointment.motive));
    },
    appointmentPractitioner () {
      return this.schedule.getPractitioner()?.getCivilState({ shortenFirstNames: true }) || null;
    },
    appointmentPlace () {
      return this.schedule.getActivityPlace()?.name || null;
    },
  },
  watch: {
    deep: true,
    appointment: {
      handler (appointment) {
        this.localAppointment = appointment;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-events-timeline-appointment-sub-content {
  display: flex;
  flex-direction: column;
  gap: map-get($spacers, 3);

  @include media-md {
    gap: map-get($spacers, 2);
  }

  &__infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($spacers, 2);

    @include media-md {
      flex-direction: row;
      align-items: center;
      gap: map-get($spacers, 4);
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($spacers, 1);

    @include media-md {
      flex-direction: row;
      align-items: center;
      gap: map-get($spacers, 4);
    }
  }

  &__note {
    font-size: 13px;
    color: var(--v-secondary-base);
    font-style: italic;
  }
}
</style>