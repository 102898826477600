import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

/**
 * Modèle commun à toutes les entitées renvoyées par l'API
 */
export default class ApiEntity {
  constructor (options = {}) {
    this['@id'] = options['@id'] || null;
    this['@type'] = options['@type'] || null;

    this.createdAt = options.createdAt || null;
    this.createdBy = options.createdBy || null;
    this.updatedAt = options.updatedAt || null;
    this.updatedBy = options.updatedBy || null;
  }

  getUuid () {
    if (this['@id']) {
      return getUUIDFromIRI(this['@id']);
    }
    return null;
  }

  getIri () {
    return this['@id'];
  }
}