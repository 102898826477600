<template>
  <div class="agenda-toolbar-right">
    <div
      v-if="!isMobile"
      class="d-flex align-center"
    >
      <app-button
        v-if="hasPermission('use_doctolib')"
        fab
        small
        color="white"
        class="mr-4"
        tooltip="Accéder à l'agenda Doctolib"
        @click="openDoctolib"
      >
        <svg
          v-test="'doctolib-logo'"
          width="26px"
          height="26px"
          class="icon-brand-doctolib"
        >
          <use xlink:href="#icon-brand-doctolib" />
        </svg>
      </app-button>
      <appointments-display-selector
        data-test="appointments-display-selector"
        class="mr-5"
        :disabled="getView === CALENDAR_VIEWS.LIST.value"
      />
      <section
        class="agenda-views"
        data-test="view-selector"
      >
        <app-buttons-group
          :value="getView"
          :items="Object.values(CALENDAR_VIEWS)"
          item-text="text"
          mandatory
          @input="SET_VIEW"
        />
      </section>
      <section data-test="agenda-settings-desktop">
        <app-button
          color="primary"
          icon="settings"
          text
          @click="settingsOpened = true"
        />
      </section>
    </div>
    <div v-else>
      <app-button
        icon="schedule-day"
        small
        class="mr-2"
        data-test="today-button"
        @click="moveToToday"
      />
      <app-button
        icon="settings"
        small
        data-test="agenda-settings-mobile"
        @click="settingsOpened = true"
      />
    </div>
    <agenda-settings :is-open.sync="settingsOpened" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import { CALENDAR_VIEWS } from '@/modules/agenda/constants';

import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue';
import AgendaSettings from '@/modules/agenda/components/AgendaSettings.vue';
import AppointmentsDisplaySelector from '@/modules/agenda/components/AppointmentsDisplaySelector.vue';

export default {
  name: 'AgendaToolbarRight',
  components: {
    AppButtonsGroup,
    AgendaSettings,
    AppointmentsDisplaySelector,
  },
  data () {
    return {
      CALENDAR_VIEWS,
      settingsOpened: false,
    };
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapGetters('agenda', ['getView']),
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    ...mapMutations('agenda', ['SET_VIEW', 'SET_CURRENT_DATE']),
    moveToToday () {
      this.SET_CURRENT_DATE(new Date());
    },
    openDoctolib () {
      window.zipper('openCalendar');
    },
  },
};
</script>

<style>

</style>