/**
 * Structure définissant le query param d'un filtre
 * @typedef {Object} FilterQueryParam
 * @property {String} name Le nom du paramètre
 * @property {*} value la valeur du filtre
 */

/**
 * Callback pour la validation du componentValue
 * @callback ValidatorCallback
 * @param {*} componentValue La valeur du v-model
 * @returns {Boolean} Retourne l'état de la validation
 */

/**
 * Callback notifiant le changement de la valeur du v-model
 * @callback ComponentValueChangedCallback
 * @param {VueComponent} vm Le composant vue
 */

/**
 * Structure définissant les options de configuration d'un filtre de base
 * @typedef {Object} BaseFilterOptions
 * @property {String} name Le nom du filtre
 * @property {String} label le label du filtre
 * @property {String} chainWith Le nom du filtre associé
 * @property {String} queryParamName La valeur du paramètre à utiliser dans la requête
 * @property {ValidatorCallback} validator le callback de validation du componentValue
 * @property {ComponentValueChangedCallback} onComponentValueChanged le callback appelé après une modification du v-model
 * @property {*} componentValue la valeur du v-model
 * @property {Boolean} debounce Si le filtre utilise un debounce ou non
 */

/**
 * Filtre de recherche, est utilisé dans le listing patients, listing correspondants par ex
 * @param {String} name le nom du filtre, est également le paramètre utilisé dans la recherche
 * @param {Object} options si base de REF, passer l'objet complet afin d'avoir toutes ses propriétés (@id, ...)
 */

/**
 * Classe représentant un filtre de base
 * Sert de socle à l'élaboration de filtres plus complexes
 */
export default class BaseHttpRequestParameter {
  /**
   * Nom du filtre
   */
  name;

  /**
   * Label du filtre
   */
  label;

  /**
   * Valeur du filtre
   */
  value;

  /**
   * Permet d'indiquer que le filtre est lié à un autre
   * Corresponds au champ "name" du filtre correspondant
   */
  chainWith;

  /**
   * Permet de personnaliser le nom du paramètre au lieu d'utiliser la propriété name du filtre
   * @type {String}
   */
  queryParamName;

  /**
   * Callback qui peut être appelé afin de savoir si
   * la valeur du filtre est valide ou non.
   *
   * Cela peut permettre de définir si "value" peut être mis à jour
   * et donc d'éviter d'activer un filtre non valide
   * @type {ValidatorCallback}
   */
  validator;

  /**
   * Callback utilisé lorsque la valeur utilisée par un composant a changé
   * @type {ComponentValueChangedCallback}
   */
  onComponentValueChanged;

  /**
   * Valeur utilisée pour le v-model du composant associé
   */
  componentValue;

  /**
   * Indique si un debounce doit être appliqué
   * Peut être utile sur les champs qui s'édite plusieurs
   * fois en un temps restreint
   */
  debounce;

  /**
   * Permet de connaitre la dernière date et heure
   * d'utilisation, afin de pouvoir les trier dans l'ordre
   */
  #lastUsageDateTime;

  showHeaderTag;

  /**
   * Permet de créer un filtre de base
   * @param {BaseFilterOptions} options
   */
  constructor (options = {}) {
    if (! options.name) {
      throw new Error('Un filtre doit obligatoirement être nommé');
    }

    this.name = options.name;
    this.label = options.label || null;
    this.value = options.value || null;
    this.chainWith = options.chainWith || null;
    this.queryParamName = options.queryParamName || null;
    this.validator = options.validator || null;
    this.onComponentValueChanged = options.onComponentValueChanged || null;
    this.componentValue = options.componentValue || null;
    this.debounce = options.debounce || false;
    this.#lastUsageDateTime = options.lastUsageDateTime || Date.now();
    this.showHeaderTag = options.showHeaderTag || true;
  }

  /**
   * Met à jour la valeur ainsi que la nouvelle date et heure d'utilisation
   * @param {*} value
   */
  updateValue (value) {
    this.value = value;
    this.#lastUsageDateTime = Date.now();
  }

  /**
   * Permet de récupérer la date et l'heure de la dernière utilisation
   * @returns Number La date et heure de la dernière utilisation
   */
  getLastUsageDateTime () {
    return this.#lastUsageDateTime;
  }

  /**
   * Permet de récupérer un objet permettant de construire une requête
   * @returns {FilterQueryParam} Objet avec le nouveau nom et la nouvelle valeur
   */
  getQueryParam () {
    return {
      name: this.queryParamName
        ? this.queryParamName
        : this.name,
      value: this.value,
    };
  }

  isValid () {
    if (this.validator) {
      return this.validator(this.componentValue);
    }

    return true;
  }
}