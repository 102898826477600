import store from '@/store/instance';

/**
 * Classe permettant de définir un contexte pour une entité
 * Ce contexte permettra d'utiliser en l'occurrence le store qui,
 * lorsqu'il était utilisé dans un fichier JS pouvait provoquer une dépendance circulaire mettant en échec
 * un nombre important de tests dans l'application.
 */
class Context {
  #contextAccessor;

  /**
   * @param {Function<Object>} contextAccessor Fonction retournant les différentes propriétés du contexte sous forme d'objet
   */
  constructor (contextAccessor) {
    this.#contextAccessor = contextAccessor;
  }

  /**
   * Retourne le contexte complet de l'entité
   * @param {Object} contextPayload
   * @returns {Object}
   */
  getContext (contextPayload = null) {
    return this.#contextAccessor(contextPayload);
  }
}

export default {
  consultation: new Context(() => ({ updateDocument: (document) => store.dispatch('patient/updateDocument', document) })),
  patientDocument: new Context(({ patientIri }) => ({ patient: store.getters['patient/getPatientByIRI'](patientIri) })),
  user: new Context(() => ({ isCurrentUserPractitioner: store.getters['auth/isCurrentUserPractitioner'] })),
};