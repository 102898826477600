import ApiEntity from '@/models/ApiEntity';
import { INS_IDENTITY_ATTRIBUTE } from '@/modules/patient/constants/insIdentityAttribute';
import { INS_IDENTITY_STATUS } from '@/modules/patient/constants/insIdentityStatus';
import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice';
import NovaTools from '@/nova-tools/NovaTools';
import { postToAPI, putToAPI } from '@/services/api';

export default class PatientInsIdentity extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.status = options.status || INS_IDENTITY_STATUS.TEMPORARY.value;
    this.attribute = options.attribute || null;
    // Le dispositif d'identité pouvant être égal à un IRI ou l'objet complet, cela permet d'uniformiser
    // la structure dans l'ensemble des cas de figure
    this.identityDevice = options.identityDevice ? this.initIdentityDevice(options.identityDevice) : null;
    this.ins = options.ins || null;
    this.oid = options.oid || null;
  }

  initIdentityDevice (deviceOption) {
    return new InsIdentityDevice(deviceOption instanceof Object ? deviceOption : { '@id': deviceOption });
  }

  isQualified () {
    return this.status === INS_IDENTITY_STATUS.QUALIFIED.value;
  }

  isValidated () {
    return [INS_IDENTITY_STATUS.QUALIFIED.value, INS_IDENTITY_STATUS.FETCHED.value].includes(this.status);
  }

  /**
   * Permet de savoir si l'INS est de type NIA
   * @returns {Boolean}
   */
  isNia () {
    if (this.oid) {
      return this.oid.endsWith('.9');
    }
    return false;
  }

  getLabel () {
    return INS_IDENTITY_STATUS[this.status.toUpperCase()].label;
  }

  getColor () {
    return INS_IDENTITY_STATUS[this.status.toUpperCase()].color;
  }

  reset () {
    this.oid = null;
    this.ins = null;
    if (this.identityDevice === null
      || this.attribute === INS_IDENTITY_ATTRIBUTE.SUSPICIOUS.value
      || this.attribute === INS_IDENTITY_ATTRIBUTE.FICTITIOUS.value) {
      this.status = INS_IDENTITY_STATUS.TEMPORARY.value;
    } else {
      this.status = this.identityDevice.strongTrustLevel ? INS_IDENTITY_STATUS.VALIDATED.value : INS_IDENTITY_STATUS.TEMPORARY.value;
    }
  }

  canChangeDevice () {
    return (
      this.attribute !== INS_IDENTITY_ATTRIBUTE.SUSPICIOUS.value
        && this.attribute !== INS_IDENTITY_ATTRIBUTE.FICTITIOUS.value
    );
  }

  /**
   * Permet de récupérer les identités disponibles selon si l'ins est renseigné
   * @returns {Array}
   */
  getAvailableInsStatuses () {
    if (this.hasIns()) {
      return [
        INS_IDENTITY_STATUS.FETCHED,
        INS_IDENTITY_STATUS.QUALIFIED,
      ];
    }
    return [
      INS_IDENTITY_STATUS.TEMPORARY,
      INS_IDENTITY_STATUS.VALIDATED,
    ];
  }

  hasIns () {
    return Boolean(this.ins);
  }

  canGetIns () {
    const {attribute} = this;
    if (attribute === INS_IDENTITY_ATTRIBUTE.SUSPICIOUS.value || attribute === INS_IDENTITY_ATTRIBUTE.FICTITIOUS.value) {
      return false;
    }
    return NovaTools.security.permissions.hasPermission('use_insi');
  }

  getCurrentSelectedDevice (InsIdentityDevices) {
    if (! this.identityDevice) {
      return null;
    }
    return InsIdentityDevices.find(device => device['@id'] === this.identityDevice['@id']);
  }

  /**
   * Permet de générer un log d'appel à l'INS
   * @param {INS_FETCH_METHOD} callType
   * @param {String} options.patient L'IRI du patient concerné
   * @param {String} options.trace La trace complète de l'appel
   */
  async logCall (callType, { patient, trace }) {
    const params = {
      patient,
      callType,
      trace,
    };
    const { data: callLogsData } = await postToAPI('/api/insi_call_logs', params);
    return callLogsData;
  }

  async associatePatientIriToLog (logIri, patientIri) {
    await putToAPI(logIri, { data: { patient: patientIri } });
  }
}