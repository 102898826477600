export const BILLING_STATUS_NAMES = Object.freeze({
  NONE: 'none',
  UNPAID: 'unpaid',
  INCOMPLETED: 'incompleted',
  COMPLETED: 'completed',
  OVERPAID: 'overpaid',
});

export default Object.freeze({
  [BILLING_STATUS_NAMES.NONE]: {
    color: 'yellow',
    shortLabel: 'Non facturé',
    label: 'Non facturé',
  },
  [BILLING_STATUS_NAMES.UNPAID]: {
    color: 'warning',
    shortLabel: 'Aucun règlement',
    label: 'Aucun règlement',
  },
  [BILLING_STATUS_NAMES.INCOMPLETED]: {
    color: 'purple',
    shortLabel: 'Incomplet',
    label: 'Règlement incomplet',
  },
  [BILLING_STATUS_NAMES.COMPLETED]: {
    color: 'success',
    shortLabel: 'Complet',
    label: 'Règlement complet',
  },
  [BILLING_STATUS_NAMES.OVERPAID]: {
    color: 'error',
    shortLabel: 'Trop-perçu',
    label: 'Règlement complet avec trop-perçu',
  },
});