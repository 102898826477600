import base64 from '@/nova-tools/base64/Base64';
import dates from '@/nova-tools/dates/dates';
import dialog from '@/nova-tools/dialog/dialog';
import dmp from '@/nova-tools/dmp/dmp';
import files from '@/nova-tools/files/files';
import icanopee from '@/nova-tools/icanopee';
import modal from '@/nova-tools/modal/modal';
import notify from '@/nova-tools/notify/notify';
import requests from '@/nova-tools/request/requests';
import security from '@/nova-tools/security';
import utils from '@/nova-tools/utils/utils';

export default {
  security,
  dialog,
  files,
  modal,
  notify,
  dates,
  base64,
  icanopee,
  requests,
  dmp,
  utils,
};