<template>
  <div
    class="calendar-event"
    data-test="calendar-event"
    :style="{ backgroundColor, color }"
    :class="eventClasses"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div class="calendar-event__content">
      <div class="event-data">
        <span
          v-if="event.isLabelTimed()"
          class="event-time mr-2 font-weight-medium"
        >
          {{ startTime }}
        </span>
        <span
          class="event-name font-weight-medium"
          data-test="calendar-event-name"
        >
          <a
            v-if="isDisplayingPatientName"
            class="event-name__link"
            data-test="event-patient-name"
            @mouseup.stop="goToPatientFile"
          >
            {{ eventName }}
          </a>
          <span v-else>{{ eventName }}</span>
        </span>
      </div>
      <app-icon
        v-if="stateIcon"
        :color="color"
        :icon="stateIcon"
        class="event-icon"
        size="12"
        data-test="calendar-event-icon"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';


import { APPOINTMENT_STATES } from '@/modules/agenda/constants';
import Absence from '@/modules/agenda/models/events/Absence';
import Appointment from '@/modules/agenda/models/events/Appointment';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import NovaTools from '@/nova-tools/NovaTools';
import { getColorContrast, hexToRgba } from '@/utils/functions/colors';
import { differenceInMinutes } from '@/utils/functions/dates';

const SHORT_APPOINTMENT_MAX_HEIGHT = 30;

export default {
  name: 'CalendarEvent',
  props: {
    event: {
      type: [Appointment, Absence],
      required: true,
    },
  },
  computed: {
    ...mapGetters('agenda', ['getMovedAppointment', 'getAppointmentsDisplayType', 'getAppointmentMotiveFromIri']),
    backgroundColor () {
      let eventColor;
      if (this.event instanceof Appointment) {
        eventColor = this.getAppointmentMotiveFromIri(this.event.motive) ? this.getAppointmentMotiveFromIri(this.event.motive).color : '#ffffff';
      } else {
        eventColor = this.event.getColor();
      }

      if (this.event.state === APPOINTMENT_STATES.IN_WAITING_ROOM.value) {
        return hexToRgba(eventColor, 0.6);
      }
      return eventColor;
    },
    color () {
      if (this.event instanceof Appointment) {
        return this.getAppointmentMotiveFromIri(this.event.motive)
          ? getColorContrast(this.getAppointmentMotiveFromIri(this.event.motive).color)
          : '#000000';
      }
      return getColorContrast(this.event.getColor());
    },
    startTime () {
      if (this.event['@type'] === 'Absence') {
        return NovaTools.dates.format(this.event.startTime, 'HH:mm');
      }
      return NovaTools.dates.format(this.event.startDateTime, 'HH:mm');
    },
    eventName () {
      if (this.event['@type'] !== 'Absence') {
        return this.getAppointmentsDisplayType === 'motive'
          ? this.getAppointmentMotiveFromIri(this.event.motive).name
          : this.event.patient.getCivilState();
      }
      return this.event.getLabel();
    },
    eventClasses () {
      const eventDuration = differenceInMinutes(new Date(this.event.endDateTime), new Date(this.event.startDateTime));
      return {
        [this.event.state]: true,
        short: this.$parent?.minutesToPixels(eventDuration) < SHORT_APPOINTMENT_MAX_HEIGHT,
        moved: this.getMovedAppointment?.['@id'] === this.event['@id'],
      };
    },
    stateIcon () {
      const eventState = this.event.state;
      if (eventState && eventState !== APPOINTMENT_STATES.CONFIRMED.value) {
        return (Object.values(APPOINTMENT_STATES)).find(state => state.value === eventState).icon;
      }
      return null;
    },
    isDisplayingPatientName () {
      return this.getAppointmentsDisplayType !== 'motive' && this.event['@type'] !== 'Absence';
    },
  },
  methods: {
    goToPatientFile () {
      this.$emit('goToPatientFile');
      this.$router.push({
        name: ROUTE_NAMES.PATIENT_FILE,
        params: { uuid: this.event.patient.getUuid() },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-event {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: map-get($spacers, 1);
  pointer-events: all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;

  &.short {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
  }

  &.moved {
    opacity: .25;
    pointer-events: none;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .event-data {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    user-select: none;
  }

  &.excused_patient .event-name,
  &.not_excused_patient .event-name {
    text-decoration: line-through;
  }
}

.event-time {
  flex: 0 0 34px;
  display: block;
  pointer-events: none;
}

.event-name {
  text-overflow: ellipsis;
  overflow: hidden;

  &__link {
    color: var(--v-color-text);

    &:hover {
      text-decoration: underline;
    }
  }
}

.event-icon {
  color: inherit;
  padding-left: 4px;
}
</style>