<template>
  <app-form
    :submit-method="sendImtiRequest"
    v-on="{ ...$listeners }"
  >
    <div v-if="!showApCv && !apCvData && !showSimpleForm">
      <vitale-card-switch-view-validator
        v-test="'vitale-card-view'"
        unfounded-card-message="Ce téléservice nécessite une carte vitale"
        :can-use-apcv="true"
        @use-apcv="showApCvModal"
        @remove-card="clearForm"
      >
        <beneficiary-selector
          :patient="value"
          :insureds="insureds"
          :selected-beneficiary.sync="selectedBeneficiary"
        />
      </vitale-card-switch-view-validator>
      <div
        v-if="!insureds.length && !isVitaleLoading"
        class="d-flex justify-center"
      >
        <n-button
          v-test="'simple-form-view-btn'"
          class="mt-4"
          bg-color="secondary"
          label="Utiliser les données du patient"
          @click="showSimpleForm = true"
        />
      </div>
    </div>
    <div v-else-if="!showApCv && !apCvData && showSimpleForm">
      <app-row dense>
        <app-col
          cols="12"
          md="6"
        >
          <app-text-field
            v-model="patientVitaleData.nir"
            v-test="'nir-field'"
            rules="length:15"
            label="NIR de l'ouvrant droit"
          />
        </app-col>
        <app-col
          cols="12"
          md="6"
        >
          <app-text-field
            v-model="patientVitaleData.certifiedNir"
            v-test="'certified-nir-field'"
            rules="length:15"
            label="NIR certifié"
          />
        </app-col>
        <app-col
          cols="12"
          md="6"
        >
          <app-date-field
            v-model="patientVitaleData.birthDate"
            v-test="'birth-date-field'"
            allow-lunar-date
            rules="required"
            label="Date de naissance"
          />
        </app-col>
        <app-col
          cols="12"
          md="6"
        >
          <app-text-field
            v-model="patientVitaleData.birthRank"
            v-test="'birth-rank-field'"
            label="Rang de naissance"
            rules="required|numeric|max:1"
            hide-errors="auto"
            autocomplete="off"
          />
        </app-col>
        <app-col
          cols="12"
          md="10"
        >
          <app-autocomplete
            v-model="patientVitaleData.grandRegime"
            v-test="'grand-regime-field'"
            :items="getGrandRegimes"
            item-text="libelle"
            item-value="@id"
            label="Grand régime"
            hide-errors="auto"
            clearable
            return-object
            rules="required"
          />
        </app-col>
        <app-col
          cols="12"
          md="1"
        >
          <app-text-field
            v-model="patientVitaleData.fund"
            v-test="'fund-field'"
            label="Caisse"
            hide-errors="auto"
            rules="numeric|max:3"
            autocomplete="off"
          />
        </app-col>
        <app-col
          cols="12"
          md="1"
        >
          <app-text-field
            v-model="patientVitaleData.center"
            v-test="'center-field'"
            label="Centre"
            hide-errors="auto"
            rules="numeric|max:4"
            autocomplete="off"
          />
        </app-col>
      </app-row>
    </div>
    <div
      v-else
      v-test="'ap-cv-view'"
    >
      <ap-cv-form
        v-if="!insureds.length"
        v-test="'ap-cv-form'"
        :patient="value"
        @submit-success="getDataFromApCv"
      />
      <beneficiary-selector
        v-else
        :patient="value"
        :insureds="insureds"
        :selected-beneficiary.sync="selectedBeneficiary"
      />
    </div>
  </app-form>
</template>

<script>
import { Buffer } from 'buffer';
import { mapGetters } from 'vuex';

import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { postToAPI } from '@/services/api';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader';
import { getVitaleDataFromPatient } from '@/services/vendors/icanopee/utils/getVitaleDataFromPatient';
import { getCivilState } from '@/utils/functions/persons';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppDateField from '@/components/ui/form/AppDateField.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import VitaleCardSwitchViewValidator from '@/components/ui/form/VitaleCardSwitchViewValidator.vue';
import ApCvForm from '@/modules/patient/components/sidebar/tlsi/apcv/ApCvForm.vue';
import BeneficiarySelector from '@/modules/patient/components/sidebar/tlsi/BeneficiarySelector.vue';






export default {
  name: 'ImtiForm',
  components: {
    AppTextField,
    AppDateField,
    AppAutocomplete,
    AppForm,
    VitaleCardSwitchViewValidator,
    ApCvForm,
    BeneficiarySelector,
  },
  props: {
    value: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      selectedBeneficiary: null,
      cardType: null,
      showApCv: false,
      apCvData: null,
      showSimpleForm: false,
      patientVitaleData: {
        nir: null,
        certifiedNir: null,
        birthDate: null,
        birthRank: null,
        center: null,
        fund: null,
        grandRegime: null,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['getGrandRegimes']),
    ...mapGetters('cardReaders', ['getCardContent', 'getHasVitaleCard', 'getIsLoadingCard']),
    isVitaleLoading () {
      return this.getIsLoadingCard(CARD_TYPES.VITALE);
    },
    vitaleCardInsureds () {
      return this.getCardContent(CARD_TYPES.VITALE);
    },
    insureds () {
      return this.vitaleCardInsureds || this.apCvData?.insureds || [];
    },
    beneficiaryVitaleCardIndex () {
      return this.insureds.indexOf(this.selectedBeneficiary);
    },
    beneficiaryFullName () {
      return getCivilState({
        firstNames: this.selectedBeneficiary?.patient.firstName || this.value.firstName,
        familyName: this.selectedBeneficiary?.patient.familyName || this.value.familyName,
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (patient) {
        if (patient && ! this.getHasVitaleCard) {
          this.setPatientVitaleDataFields(patient);
        }
        if (! patient?.['@id']) {
          this.clearPatientVitaleDataFields();
        }
      },
    },
    getHasVitaleCard (hasVitaleCard) {
      if (hasVitaleCard) {
        this.clearPatientVitaleDataFields();
      } else {
        this.selectedBeneficiary = null;
        this.setPatientVitaleDataFields(this.value);
      }
    },
    vitaleCardInsureds () {
      this.showApCv = false;
    },
  },
  mounted () {
    this.$listeners.cancel = () => {
      this.clearForm();
    };
  },
  methods: {
    clearForm () {
      if (this.showApCv) {
        this.showApCv = false;
        this.apCvData = null;
        this.cardType = null;
      }
      this.selectedBeneficiary = null;
      this.showSimpleForm = false;
    },
    showApCvModal () {
      this.showApCv = true;
    },
    getDataFromApCv (apCvData) {
      this.apCvData = apCvData;
      this.cardType = apCvData.data.s_profileType;
    },
    setPatientVitaleDataFields (patient) {
      this.patientVitaleData = {
        nir: patient.nir,
        certifiedNir: null,
        birthDate: patient.birthDate,
        birthRank: patient.billDataset?.birthRank,
        center: patient.billDataset?.center,
        fund: patient.billDataset?.fund,
        grandRegime: patient.billDataset?.grandRegime,
      };
    },
    clearPatientVitaleDataFields () {
      this.patientVitaleData = {
        nir: null,
        certifiedNir: null,
        birthDate: null,
        birthRank: null,
        center: null,
        fund: null,
        grandRegime: null,
      };
    },
    async sendImtiRequest () {
      let imtiTemplateVitaleData = this.beneficiaryVitaleCardIndex;
      try {
        const apiRequestParams = {
          patient: this.value['@id'],
          template: null,
        };

        if (this.getHasVitaleCard) {
          apiRequestParams.patientData = await Tlsi.getBeneficiaryData(this.beneficiaryVitaleCardIndex, 'PatientData');
        } else if (this.apCvData) {
          apiRequestParams.patientData = this.beneficiaryVitaleCardIndex === 0
            ? this.apCvData.data.User.VitaleData
            : this.apCvData.data.Wallet[this.beneficiaryVitaleCardIndex - 1].VitaleData;
        } else {
          const imtiPatientVitaleData = getVitaleDataFromPatient({
            ...this.value,
            nir: this.patientVitaleData.nir,
            certifiedNir: this.patientVitaleData.certifiedNir,
            birthDate: this.patientVitaleData.birthDate,
            billDataset: {
              ...this.value.billDataset,
              birthRank: this.patientVitaleData.birthRank,
              center: this.patientVitaleData.center,
              fund: this.patientVitaleData.fund,
              grandRegime: this.patientVitaleData.grandRegime,
            },
          });
          imtiTemplateVitaleData = {
            BeneficiaryVitaleData: imtiPatientVitaleData,
            RightsHolderNir: imtiPatientVitaleData.NirData.Nir,
          };
          apiRequestParams.vitaleData = this.patientVitaleData;
        }

        if (Object.keys(apiRequestParams).includes('patientData')) {
          apiRequestParams.patientData.s_birthday = this.selectedBeneficiary.patient.birthDate;
        }
        const softwareInformations = await Tlsi.fetchSoftwareInformations();
        const template = await iCanopeeCardReaderInstance.getImtiTemplate(softwareInformations, imtiTemplateVitaleData, this.showApCv);
        apiRequestParams.template = Buffer.from(template).toString('base64');

        const { data } = await postToAPI('/api/tlsi/imti', apiRequestParams, { errorHandle: false });

        return {
          ...data,
          beneficiaryFullName: this.beneficiaryFullName,
        };

      } catch (error) {
        if (typeof error === 'object' && error.response && error.response.data) {
          const contentError = error.response.data['hydra:description'];
          if (error.response.data['hydra:detail']) {
            contentError.concat(`(${error.response.data['hydra:detail']})`);
          }
          NovaTools.notify.error(contentError, {
            title: error.response.data['hydra:title'],
            timeout: 15000,
          });
        } else {
          NovaTools.notify.error(error.message ?? error, { timeout: 15000 });
        }

        throw error;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .app-autocomplete {
    position: relative;
  }
  .app-autocomplete .v-autocomplete__content {
    // Permet au menu de se positionner de manière fixe au dessus
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    margin-top: map-get($spacers, 8);
    max-height: 300px;
  }
}
</style>