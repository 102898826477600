<template>
  <div
    class="patient-strict-traits-dataset"
    :style="wrapperStyle"
    :class="{ 'patient-strict-traits-dataset--dense': dense }"
  >
    <div
      v-for="(group, groupIndex) in strictTraitGroups"
      :key="groupIndex"
    >
      <app-dataset-item
        v-for="strictTrait in group"
        :key="strictTrait.label"
        :label="strictTrait.label"
        :value="strictTrait.value"
        :class="{'sentry-mask': strictTrait.dataPrivacy}"
      />
    </div>
  </div>
</template>

<script>

import { chunk } from 'lodash';

import Patient from '@/modules/patient/models/Patient';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';

const SPACING_RATIO_BETWEEN_COLS = 3;

export default {
  name: 'PatientStrictTraitsDatasetGrid',
  components: { AppDatasetItem },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    itemsPerCol: {
      type: Number,
      default: 3,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    strictTraitItems () {
      return [
        {
          label: 'Prénom(s)',
          value: this.patient.firstNames,
          dataPrivacy: false,
        },
        {
          label: '1er prénom',
          value: this.patient.firstName,
          dataPrivacy: false,
        },
        {
          label: 'Nom de naissance',
          value: this.patient.birthName,
          dataPrivacy: true,
        },
        {
          label: 'Lieu de naissance',
          value: this.patient.getBirthPlaceLabel(),
          dataPrivacy: false,
        },
        {
          label: 'Sexe',
          value: this.patient.getGenderLabel(),
          dataPrivacy: false,
        },
        {
          label: 'INS',
          value: this.patient.getInsLabel(),
          dataPrivacy: true,
        },
      ];
    },
    strictTraitGroups () {
      return chunk(this.strictTraitItems, this.itemsPerCol);
    },
    wrapperStyle () {
      return { gap: `${SPACING_RATIO_BETWEEN_COLS * 4}px` };
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-strict-traits-dataset {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  &--dense {
    ::v-deep {
      .app-dataset-item__content {
        font-size: 12px;
      }
    }
  }
}
</style>