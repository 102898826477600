<template>
  <patient-sidebar-panel-listing-item
    :title="title"
    :description="description"
    :summaries="summaries"
    :actions="actions"
  >
    <template #prepend>
      <p
        v-if="item.ald"
        class="ongoing-medication-listing-item__ald"
      >
        <app-icon
          :size="13"
          :icon="'userShield'"
          color="secondary"
          class="mr-2"
        />
        <span>ALD</span>
      </p>
      <p
        v-test="'ongoing-medication-end-date'"
        class="ongoing-medication-listing-item__end-date"
        :class="{ 'ongoing-medication-listing-item__end-date--no-end-date': noEndDate }"
      >
        <app-icon
          v-test="'ongoing-medication-icon'"
          :size="13"
          :icon="isLongTerm ? 'clock-outlined' : 'schedule-outlined'"
          color="secondary"
          class="mr-2"
        />
        <span v-if="isLongTerm">Traitement au long cours</span>
        <span v-else-if="item.endDate">Fini le {{ format(item.endDate,'dd/MM/yyyy') }}</span>
        <span v-else>Pas de date de fin</span>
      </p>
    </template>
  </patient-sidebar-panel-listing-item>
</template>

<script>
import { MEDICATION_TYPES } from '@/modules/patient/constants/medicationTypes';
import Medication from '@/modules/patient/models/Medication';
import { format } from '@/utils/functions/dates';

import PatientSidebarPanelListingItem from '@/modules/patient/components/PatientSidebarPanelListingItem.vue';



export default {
  name: 'OngoingMedicationListingItem',
  components: { PatientSidebarPanelListingItem },
  props: {
    item: {
      type: Medication,
      required: true,
    },
    actions: {
      type: Array,
      default: null,
    },
  },
  computed: {
    title () {
      return this.item.product.brandname || this.item.product.label;
    },
    description () {
      return this.item.product?.clinical_drug_label;
    },
    summaries () {
      return this.item.summaries;
    },
    noEndDate () {
      return (this.item.type === MEDICATION_TYPES.INTERNAL && ! this.item.endDate && ! this.item.chronic)
        || (this.item.type === MEDICATION_TYPES.EXTERNAL && ! this.item.endDate && ! this.item.longTerm);
    },
    isLongTerm () {
      return (this.item.type === MEDICATION_TYPES.INTERNAL && this.item.chronic)
        || (this.item.type === MEDICATION_TYPES.EXTERNAL && this.item.longTerm);
    },
  },
  methods: { format },
};
</script>

<style lang="scss" scoped>
.ongoing-medication-listing-item {
  &__end-date, &__ald {
    font-size: 12px;
    color: var(--v-secondary-base);

    &--no-end-date {
      opacity: .5;
    }
  }
}
</style>