import NovaTools from '@/nova-tools/NovaTools';

export class CodePin {
  codePin = null;

  getCodePin () {
    return this.codePin;
  }

  setCodePin (codePin) {
    this.codePin = codePin;
    if (codePin) {
      this.memorizeCode(codePin);
      NovaTools.icanopee.EfficienceBackground.startSynchro();
    } else {
      this.removeMemorizedCode();
      NovaTools.icanopee.EfficienceBackground.stopSynchro();
    }
  }

  removeMemorizedCode () {
    localStorage.removeItem('codePin');
  }

  isCodeValid (codePin) {
    return /^[0-9]{4}$/.test(codePin);
  }

  memorizeCode (codePin) {
    if(this.isCodeValid(codePin)) {
      localStorage.setItem('codePin', JSON.stringify(codePin));
    }
  }

  clearStoredCode () {
    this.removeMemorizedCode();
    this.setCodePin(null);
  }

  reloadMemorizedCode () {
    const storedCode = localStorage.getItem('codePin');
    if (! storedCode) {
      return null;
    }

    const parsedCode = JSON.parse(storedCode);
    if (! this.isCodeValid(parsedCode)) {
      this.clearStoredCode();
      return null;
    }
    this.setCodePin(parsedCode);
    return parsedCode;
  }
}

export default new CodePin();