<template>
  <section class="authentication-layout">
    <easy-care-logo
      class="authentication-layout__easy-care-logo"
      show-label
      @click.native="openEasyCareWebSite"
    />
    <app-row class="authentication-layout__inner my-0">
      <app-col
        v-if="! isMobile"
        v-test="'illustration-column'"
        cols="12"
        md="8"
        class="authentication-layout__illustration-col"
      >
        <v-slide-x-transition mode="out-in">
          <svg
            v-test="'illustration'"
            :name="illustrationName"
            class="authentication-layout__illustration-col__illustration"
          >
            <use :xlink:href="`#${illustrationName}`" />
          </svg>
        </v-slide-x-transition>
      </app-col>
      <app-col
        cols="12"
        md="4"
        class="authentication-layout__form-col"
      >
        <slot />
      </app-col>
    </app-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import EasyCareLogo from '@/components/ui/easyCareLogo/EasyCareLogo.vue';


export default {
  name: 'AuthenticationModuleLayout',
  components: { EasyCareLogo },
  props: {
    illustrationName: {
      type: String,
      required: true,
    },
  },
  computed: { ...mapGetters('app', ['isMobile']) },
  methods: {
    openEasyCareWebSite () {
      window.open('https://www.easy-care.fr/');
    },
  },
};
</script>

<style lang="scss">
.authentication-layout {
   height: 100vh;

  &__inner {
    height: 100%;
  }

  &__easy-care-logo {
    position: absolute;
    top: map-get($spacers, 4);
    left: map-get($spacers, 4);

    @include media-md {
      top: map-get($spacers, 10);
      left: map-get($spacers, 10);
    }
  }

  &__illustration-col,
  &__form-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__illustration-col {
    &__illustration {
      height: 100%;
      object-fit: contain;
      max-width: 670px;
      width: 50vw;
    }
  }

  &__form-col {
    background-color: white;
  }
}
</style>