import ConsultationBaseDocument from '@/modules/patient/models/ConsultationBaseDocuments';
import ConsultationDocumentContent from '@/modules/patient/models/ConsultationDocumentContent';

export default class ConsultationDocument extends ConsultationBaseDocument {
  constructor (options = {}) {
    super(options);

    this.name = options.name || null;
    this.content = this.setDocumentContent(options.content);
    this.template = options.template || null;
    this.title = options.title || null;
  }

  setDocumentContent (content) {
    if (typeof content === 'string') {
      try {
        return new ConsultationDocumentContent(JSON.parse(content));
      } catch {
        return new ConsultationDocumentContent({ body: content });
      }
    }
    return new ConsultationDocumentContent(content);
  }
}