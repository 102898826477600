import NovaTools from '@/nova-tools/NovaTools';

export class Dmp {
  async traceDmpSendFailed (iri, type) {
    await NovaTools.dmp.traceDmpSend(iri, type, { dmpSentFailed: true });
  }

  async traceDmpSend (iri, type, dataParams = null) {
    const updateWithPatch = [
      'ConsultationCertificateDocument',
      'ConsultationDischargeLetterDocument',
      'ConsultationTelemedicineActDocument',
      'CONSULTATION_CERTIFICATE_DOCUMENT',
      'CONSULTATION_DISCHARGE_LETTER_DOCUMENT',
      'CONSULTATION_TELEMEDICINE_ACT_DOCUMENT',
    ];
    const httpMethod = updateWithPatch.includes(type) ? NovaTools.requests.patch : NovaTools.requests.put;
    return httpMethod(iri, { data: dataParams });
  }
}
export default new Dmp();