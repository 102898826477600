<template>
  <app-form :submit-method="savePathology" class="flex-list-2 flex-column">
    <pathology-autocomplete-wrapper
      ref="pathology-autocomplete-wrapper"
      v-model="selectedPathology"
      :rules="{
        required: true,
        excluded: excludedPathologiesLabels,
      }"
    />
    <app-textarea-field
      v-model="localNote"
      v-test="'pathology-note'"
      label="Commentaire (optionnel)"
      class="mt-0"
      hide-errors="auto"
      rows="1"
      auto-grow
    />
    <app-switch
      v-model="localImportant"
      v-test="'pathology-switch-important'"
      dense
      class="mt-2"
      label="Important"
    />
    <app-date-picker
      v-model="localStartDate"
      v-test="'pathology-start-date'"
      label="Date de début"
      :max="maxStartDate"
      class="mt-0 pt-0"
      name="startDate"
      clearable
    />
    <app-date-picker
      v-model="localEndDate"
      v-test="'pathology-end-date'"
      label="Date de fin"
      :min="minEndDate"
      class="mt-0 pt-0"
      name="endDate"
      clearable
    />
  </app-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import PathologyAutocompleteWrapper from '@/components/lap/PathologyAutocompleteWrapper.vue';
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue';

export default {
  name: 'PathologyForm',
  components: {
    AppSwitch,
    AppForm,
    AppTextareaField,
    PathologyAutocompleteWrapper,
    AppDatePicker,
  },
  props: {
    value: {
      type: PatientPathology,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      selectedPathology: new PatientPathology(this.value || {}),
      localNote: null,
      localImportant: null,
      localStartDate: null,
      localEndDate: null,
    };
  },
  computed: {
    ...mapGetters('patient', ['getPatientPathologies']),
    isEditing () {
      return !! this.value?.['@id'];
    },
    patientPathologiesLabels () {
      return this.getPatientPathologies(this.patient.getUuid()).map(pathology => pathology.label);
    },
    excludedPathologiesLabels () {
      if (this.isEditing) {
        return this.patientPathologiesLabels.filter(pathologyLabel => pathologyLabel !== this.value?.label);
      }
      return this.patientPathologiesLabels;
    },
    minEndDate () {
      return this.localStartDate !== null ? new Date(this.localStartDate) : null;
    },
    maxStartDate () {
      return this.localEndDate !== null ? new Date(this.localEndDate) : null;
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler (patientPathology) {
        this.selectedPathology = patientPathology;
        this.localNote = patientPathology?.note || null;
        this.localImportant = patientPathology?.important || null;
        this.localStartDate = patientPathology?.startDate
          ? NovaTools.dates.format(patientPathology?.startDate, 'yyyy-MM-dd')
          : null;
        this.localEndDate = patientPathology?.endDate
          ? NovaTools.dates.format(patientPathology?.endDate, 'yyyy-MM-dd')
          : null;
      },
    },
  },
  methods: {
    ...mapActions('patient', ['insertPatientPathology', 'updatePatientPathology', 'setIsHealthSummaryRemindersUpdated']),
    async savePathology () {
      const patientPathology = new PatientPathology({
        ...this.selectedPathology,
        '@id': this.value?.['@id'],
        patient: this.patient['@id'],
        note: this.localNote,
        important: this.localImportant,
        startDate: this.localStartDate,
        endDate: this.localEndDate,
      });
      await (this.isEditing ? this.updatePatientPathology : this.insertPatientPathology)(patientPathology);
      NovaTools.notify.success(`La pathologie a été ${this.isEditing ? 'modifiée' : 'ajoutée'} avec succès`);
      this.setIsHealthSummaryRemindersUpdated(true);
      return this.localPathology;
    },
  },
};
</script>