<template>
  <validation-provider
    ref="validator"
    v-slot="{ errors, touched }"
    slim
  >
    <section
      v-test="'form-rule'"
      class="authentication-form-rule"
      :class="{ 'authentication-form-rule--valid': touched && errors.length === 0 }"
    >
      <figure
        v-if="ruleShortLabel"
        v-test="'rule-short-label'"
        class="authentication-form-rule__rule-short-label"
      >
        {{ ruleShortLabel }}
      </figure>
      <p
        v-test="'label'"
        class="authentication-form-rule__label"
      >
        {{ label }}
      </p>
    </section>
  </validation-provider>
</template>

<script>
import { validate, ValidationProvider } from 'vee-validate';

/**
 * Une règle de validation visuelle permettant de tester la validité d'une valeur
 */
export default {
  name: 'AuthenticationValidationRule',
  components: { ValidationProvider },
  props: {
    /**
     * La règle à tester
     * @see {@link https://vee-validate.logaretm.com/v2/guide/rules.html}
     */
    rule: {
      type: [String, Object],
      required: true,
    },
    /**
     * Le label de la règle au format court
     */
    ruleShortLabel: {
      type: String,
      default: null,
    },
    /**
     * Le label de la règle
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * La valeur à valider
     */
    value: {
      type: String,
      default: null,
    },
  },
  watch: {
    async value (value) {
      const { errors } = await validate(value, this.rule, { skipIfEmpty: false });
      this.$refs.validator.setErrors(errors);
      // Évite l'apparition d'une classe CSS marquant l'élément comme valide dans le cas ou
      // la valeur n'a pas été validé et qu'aucune erreur n'a donc été renvoyée
      this.$refs.validator.setFlags({ touched: true });
    },
  },
};
</script>

<style lang='scss' scoped>
.authentication-form-rule {
  display: flex;
  align-items: center;

  &--valid,
  &--valid &__rule-short-label {
    color: var(--v-success-base);
  }

  &__rule-short-label {
    font-weight: 600;
    font-size: 20px;
    width: 40px;
    color: var(--v-secondary-lighten4);
  }

  &__label {
    font-size: 13px;
  }
}
</style>