export const PRESCRIPTIONS_SOUS_TYPES = Object.freeze({
  BZN: {
    value: 'BZN',
    label: 'Bizone',
  },
  MEX: {
    value: 'MEX',
    label: 'Médicaments d\'exception',
  },
  OSC: {
    value: 'OSC',
    label: 'Ordonnance sécurisée',
  },
});