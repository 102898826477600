<template>
  <validation-provider ref="pathologyValidator" v-slot="{ errors }" tag="div">
    <div class="pathology-autocomplete-wrapper" :class="tagsAlignmentClass">
      <pathology-autocomplete-widget-v2
        v-if="multiple || !hasSelection"
        v-test="'pathology-autocomplete'"
        class="flex-grow-1"
        @input="handlePathologySelection($event)"
      />
      <div
        v-if="multiple && hasSelection"
        v-test="'selected-pathologies-tags'"
        class="pathology-autocomplete-wrapper__tags"
      >
        <app-tag
          v-for="(pathology, index) in value"
          :key="index"
          color="secondary"
          small
          closable
          @click:close="handlePathologyUnselection(index)"
        >
          {{ pathology.label }}
        </app-tag>
      </div>
    </div>
    <app-panel
      v-if="!multiple && hasSelection"
      v-test="'selected-pathology-panel'"
      is-outlined
      is-flat
      dense
      :title="value.label"
      :actions="actions"
    />
    <div v-if="errors.length > 0" class="pathology-autocomplete-wrapper__error-messages">
      <p v-for="(error, index) in errors" :key="index" class="mt-2">
        {{ error }}
      </p>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider, validate } from 'vee-validate';

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import fieldMixin from '@/mixins/fields';

import PathologyAutocompleteWidgetV2 from '@/components/lap/synapse-widgets/PathologyAutocompleteWidgetV2.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';



export default {
  name: 'PathologyAutocompleteWrapper',
  components: {
    PathologyAutocompleteWidgetV2,
    AppPanel,
    AppTag,
    ValidationProvider,
  },
  mixins: [fieldMixin],
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    tagsAlignment: {
      type: String,
      default: 'right',
      validator: alignment => ['bottom', 'right'].includes(alignment),
    },
  },
  computed: {
    hasSelection () {
      if (this.multiple) {
        return !! this.value && this.value.length > 0;
      }
      return !! this.value?.label;
    },
    actions () {
      return [new ActionMenuItem('edit', 'Modifier la pathologie', () => this.$emit('input', null))];
    },
    tagsAlignmentClass () {
      if (this.multiple) {
        return `pathology-autocomplete-wrapper--tags-${this.tagsAlignment}`;
      }
      return '';
    },
  },
  watch: {
    value: {
      deep: true,
      async handler () {
        const validationResult = await validate(this.value?.label, this.rules);
        this.$refs.pathologyValidator.applyResult(validationResult);
      },
    },
  },
  methods: {
    handlePathologySelection (pathology) {
      this.$emit('input', this.multiple ? [...this.value, pathology] : pathology);
    },
    handlePathologyUnselection (pathologyIndex) {
      const localSelection = [...this.value];
      localSelection.splice(pathologyIndex, 1);
      this.$emit('input', localSelection);
    },
  },
};
</script>
<style lang="scss" scoped>
.pathology-autocomplete-wrapper {
  display: flex;
  gap: map-get($spacers, 2);

  &--tags-right {
    align-items: flex-end;
  }

  &--tags-bottom {
    flex-direction: column;
  }

  &__error-messages {
    color: var(--v-error-base);
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacers, 2) map-get($spacers, 2);
  }
}

::v-deep {
  .panel__header {
    margin-bottom: 0 !important;
  }
  .panel__title {
    white-space: normal !important;
  }
  .synapse-widget {
    // Nouveau Design System
    .autocomplete-search-input {
      fieldset {
        margin: 0 !important;

        label {
          font-family: 'Poppins' !important;
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }
      }
    }
  }
}
</style>
<style type="text/css" id="synapse-menu">
/* Menu Synapse */
.synapse-widget > div[class*="menu"] {
  border: none;
  box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  border-radius: 0 0 3px 3px;
  margin-top: 0 !important;
}
.synapse-widget > div[class*="menu"] button:hover {
  background-color: var(--v-text-lighten5);
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label {
  font-family: 'Poppins';
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label > div:first-child {
  font-size: 13px;
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label > div:last-child > div > span {
  font-size: 11px;
}
</style>