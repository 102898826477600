<template>
  <app-text-field
    v-if="isEditing"
    v-model.trim="folderNameEditing"
    dense
    name="title"
    filled
    autofocus
    hide-errors="auto"
    style="width: 100%"
    @focusout="saveFolderNameEditing"
    @keydown.enter="$event.target.blur()"
  />
  <span v-else>{{ folder.title }}</span>
</template>

<script>
import PatientDocumentFolder
  from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/models/PatientDocumentFolder';
import NovaTools from '@/nova-tools/NovaTools';

import AppTextField from '@/components/ui/form/AppTextField.vue';

export default {
  name: 'FolderItem',
  components: { AppTextField },
  props: {
    folder: {
      type: PatientDocumentFolder,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      isEditing: false,
      folderNameEditing: null,
    };
  },
  watch: {
    'folder.editing': {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.startEditing();
        }
      },
    },
  },
  methods: {
    async saveFolderNameEditing () {
      try {
        if (this.folder.title !== this.folderNameEditing) {
          await PatientDocumentFolder.update({
            ...this.folder,
            children: null,
            patient: this.patientIri,
            parent: (this.level > 1) ? this.folder.parent : null,
            title: this.folderNameEditing,
          });
        }
        this.$emit('updated');
        this.isEditing = false;
      } catch (e) {
        NovaTools.notify.error(e.response.data.violations[0].message);
      }
    },
    startEditing () {
      this.folderNameEditing = this.folder.title;
      this.isEditing = true;
    },
  },
};
</script>