import { DOCUMENT_TYPES } from '@/modules/documents/constants/documentTypes';
import ConsultationBaseDocument from '@/modules/patient/models/ConsultationBaseDocuments';

export default class ConsultationTelemedicineAct extends ConsultationBaseDocument {

  constructor (options = {}) {
    super(options);
    this.content = options.content || '';
    this.recipients = options.recipients || [];
  }

  getLabel () {
    return DOCUMENT_TYPES.TELEMEDCINE_ACT.title;
  }

  getIcon () {
    return DOCUMENT_TYPES.TELEMEDCINE_ACT.icon;
  }
}