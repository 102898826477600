export const MEDICAL_LEAVE_NATURE_DATA = Object.freeze([
  {
    label: 'temps complet',
    value: 'TC',
  },
  {
    label: 'temps partiel',
    value: 'TP',
  },
  {
    label: 'temps complet suivi d\'une reprise à temps partiel',
    value: 'TCP',
  },
]);