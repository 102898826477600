export const PATIENT_RELATIONS = Object.freeze({
  SPOUSE: {
    name: 'spouse',
    label: 'Conjoint(e)',
  },
  FATHER: {
    name: 'father',
    label: 'Père',
  },
  MOTHER: {
    name: 'mother',
    label: 'Mère',
  },
  SON: {
    name: 'son',
    label: 'Fils',
  },
  DAUGHTER: {
    name: 'daughter',
    label: 'Fille',
  },
  BROTHER: {
    name: 'brother',
    label: 'Frère',
  },
  SISTER: {
    name: 'sister',
    label: 'Sœur',
  },
  GRANDSON: {
    name: 'grandson',
    label: 'Petit fils',
  },
  GRANDDAUGHTER: {
    name: 'granddaughter',
    label: 'Petite fille',
  },
  SON_IN_LAW: {
    name: 'son_in_law',
    label: 'Gendre',
  },
  DAUGHTER_IN_LAW: {
    name: 'daughter_in_law',
    label: 'Belle fille',
  },
  FRIEND: {
    name: 'friend',
    label: 'Ami(e)',
  },
  NEIGHBOR: {
    name: 'neighbor',
    label: 'Voisin(e)',
  },
  TUTOR: {
    name: 'tutor',
    label: 'Tuteur/Tutrice',
  },
  CURATOR: {
    name: 'curator',
    label: 'Curateur/Curatrice',
  },
  OTHER: {
    name: 'other',
    label: 'Autre',
  },
});