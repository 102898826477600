<template>
  <app-form
    :submit-method="sendDmtiRequest"
    v-on="{ ...$listeners }"
  >
    <vitale-card-switch-view-validator
      v-if="!showApCv && !apCvData"
      v-test="'vitale-card-view'"
      unfounded-card-message="Ce téléservice nécessite une carte vitale"
      :can-use-apcv="true"
      @use-apcv="showApCvModal"
      @remove-card="clearForm"
    >
      <dmti-beneficiary-selector
        :selected-beneficiary.sync="selectedBeneficiary"
        :patient="value"
        :practitioner="practitioner"
        :insureds="insureds"
      />
    </vitale-card-switch-view-validator>
    <div
      v-else
      v-test="'ap-cv-view'"
    >
      <ap-cv-form
        v-if="!insureds.length"
        v-test="'ap-cv-form'"
        :patient="value"
        @submit-success="getDataFromApCv"
      />
      <dmti-beneficiary-selector
        v-else
        :selected-beneficiary.sync="selectedBeneficiary"
        :patient="value"
        :practitioner="practitioner"
        :insureds="insureds"
      />
    </div>
  </app-form>
</template>

<script>

import { Buffer } from 'buffer';
import { mapGetters } from 'vuex';


import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { postToAPI } from '@/services/api';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader';
import { getCivilState } from '@/utils/functions/persons';

import AppForm from '@/components/ui/form/AppForm.vue';
import VitaleCardSwitchViewValidator from '@/components/ui/form/VitaleCardSwitchViewValidator.vue';
import ApCvForm from '@/modules/patient/components/sidebar/tlsi/apcv/ApCvForm.vue';
import DmtiBeneficiarySelector from '@/modules/patient/components/sidebar/tlsi/dmti/DmtiBeneficiarySelector.vue';

export default {
  name: 'DmtiForm',
  components: {
    AppForm,
    VitaleCardSwitchViewValidator,
    ApCvForm,
    DmtiBeneficiarySelector,
  },
  props: {
    value: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      selectedBeneficiary: null,
      cardType: null,
      showApCv: false,
      apCvData: null,
    };
  },
  computed: {
    ...mapGetters('cardReaders', ['getCardContent', 'getHasVitaleCard']),
    vitaleCardInsureds () {
      return this.getCardContent(CARD_TYPES.VITALE);
    },
    insureds () {
      return this.vitaleCardInsureds || this.apCvData?.insureds || [];
    },
    beneficiaryVitaleCardIndex () {
      return this.insureds.indexOf(this.selectedBeneficiary);
    },
    beneficiaryFullName () {
      return getCivilState({
        firstNames: this.selectedBeneficiary?.patient.firstName,
        familyName: this.selectedBeneficiary?.patient.familyName,
      });
    },
    practitioner () {
      return this.getCardContent(CARD_TYPES.CPX);
    },
    practitionerFullName () {
      return getCivilState({
        firstNames: this.practitioner?.s_given,
        familyName: this.practitioner?.s_name,
      });
    },
  },
  watch: {
    vitaleCardInsureds () {
      this.showApCv = false;
    },
  },
  mounted () {
    this.$listeners.cancel = () => {
      this.clearForm();
    };
  },
  methods: {
    clearForm () {
      if (this.showApCv) {
        this.showApCv = false;
        this.apCvData = null;
        this.cardType = null;
      }
      this.selectedBeneficiary = null;
    },
    showApCvModal () {
      this.showApCv = true;
    },
    getDataFromApCv (apCvData) {
      this.apCvData = apCvData;
      this.cardType = apCvData.data.s_profileType;
    },
    async sendDmtiRequest () {
      let patientData = null;
      let base64Template = null;

      try {
        const softwareInformations = await Tlsi.fetchSoftwareInformations();
        const template = await iCanopeeCardReaderInstance.getDmtiTemplate(
          softwareInformations,
          this.beneficiaryVitaleCardIndex,
          ! this.getHasVitaleCard,
        );
        base64Template = Buffer.from(template).toString('base64');

        if (this.getHasVitaleCard) {
          const { VitaleData, PatientData } = await Tlsi.getBeneficiaryData(this.beneficiaryVitaleCardIndex);
          patientData = PatientData;
          this.cardType = VitaleData.s_cardType;
        } else {
          patientData = this.beneficiaryVitaleCardIndex === 0
            ? this.apCvData.data.User.VitaleData
            : this.apCvData.data.Wallet[this.beneficiaryVitaleCardIndex - 1].VitaleData;
        }
        patientData.s_birthday = this.selectedBeneficiary.patient.birthDate;

        const apiRequestParams = {
          template: base64Template,
          patient: this.value['@id'],
          patientData,
          practitionerData: {
            am: this.practitioner.PracticeLocations[0].s_practiceLocationBillingNumber,
            rpps: this.practitioner.s_internalId,
            firstNames: this.practitioner.s_given,
            familyName: this.practitioner.s_name,
          },
          cardType: this.cardType,
        };

        const { data } = await postToAPI('/api/tlsi/dmti', apiRequestParams, { errorHandle: false });

        const messageStart = `La déclaration ${data.number} de ${this.practitionerFullName} pour le bénéficiaire ${
          this.beneficiaryFullName} a bien été transmise et déposée`;
        NovaTools.notify.success(messageStart);
        return {
          message: `${messageStart} le ${NovaTools.dates.format(data.time, 'dd MMMM yyyy à HH:mm')}`,
          time: data.time,
          returnReceiptId: data.returnReceiptId,
        };

      } catch (error) {
        if (typeof error === 'object' && error.response && error.response.data) {
          const contentError = error.response.data['hydra:description'];
          if (error.response.data['hydra:detail']) {
            contentError.concat(`(${error.response.data['hydra:detail']})`);
          }
          NovaTools.notify.error(contentError, {
            title: error.response.data['hydra:title'],
            timeout: 15000,
          });
        } else {
          NovaTools.notify.error(error.message ?? error, { timeout: 15000 });
        }
        throw error;
      } finally {
        this.clearForm();
      }
    },
  },
};
</script>