import { groupBy } from 'lodash';

import PractitionerProfile from './PractitionerProfile';
import Person from '@/models/Person';
import ContactActivityPlace from '@/modules/contact/models/ContactActivityPlace';


export default class Contact extends Person {
  constructor (options = {}) {
    super(options);
    this.profession = options.profession || null;
    this.practitioner = options.practitioner || false;
    this.healthProfessional = options.healthProfessional || null;
    this.practitionerProfile = null;
    if (options.practitionerProfile) {
      this.practitionerProfile = new PractitionerProfile(options.practitionerProfile);
    }
    if (!options.practitionerProfile && this.practitioner) {
      this.practitionerProfile = new PractitionerProfile();
    }
    this.contactActivityPlaces = options.contactActivityPlaces
      ? options.contactActivityPlaces?.map(contactActivityPlace => new ContactActivityPlace(contactActivityPlace))
      : [];
  }

  /**
   * Retourne l'état civil du praticien sous la forme "Civilité - Initiale du prénom - Nom de famille"
   * @returns {String} l'état civil du praticien
   * @deprecated Utiliser getCivilState() à la place
   */
  get civilState () {
    return this.getCivilState({ shortenFirstNames: true });
  }

  getMssContactPoints () {
    const mssContactActivityPlaces = this.contactActivityPlaces?.filter(item => item.mssAddress === true);
    const mssContactPoints = [];
    mssContactActivityPlaces.forEach((mssContactActivityPlace) => {
      mssContactActivityPlace.contactPoints.forEach((contactPoint) => {
        mssContactPoints.push(contactPoint.value);
      });
    });

    return mssContactPoints;
  }

  getNotMssContactPointsForContactActivityPlace (contactActivityPlace) {
    if (contactActivityPlace.mssAddress) {
      return [];
    }

    const contactPointPhones = contactActivityPlace.contactPoints?.filter(item => item.media === 'telephone');
    const contactPointFaxes = contactActivityPlace.contactPoints?.filter(item => item.media === 'fax');
    const contactPointEmails = contactActivityPlace.contactPoints?.filter(item => item.media === 'email');

    return [...contactPointPhones, ...contactPointFaxes, ...contactPointEmails].map(item => item.value);
  }

  /**
   * Retourne l'ensemble des points de contact de la personne regroupés par média (comprend ceux des lieux d'activités)
   * @returns { Object }
   */
  getAllContactPointsByMedia () {
    const allContactPoints = this.contactPoints;

    this.contactActivityPlaces?.filter(item => item.mssAddress === true)[0]?.contactPoints.forEach((item) => {
      allContactPoints.push(item);
    });

    this.contactActivityPlaces?.filter(item => item.mssAddress === false).forEach((contactActivityPlace) => {
      contactActivityPlace.contactPoints.forEach((item) => {
        allContactPoints.push(item);
      });
    });

    return groupBy(allContactPoints.filter(contactPoint => contactPoint.value), 'media');
  }
}