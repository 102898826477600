<template>
  <accounting-list-item-layout class="accounting-list-item">
    <template #date>
      <div data-test="date">
        {{ consultationDate }}
      </div>
    </template>
    <template #total-amount>
      <div data-test="total-amount">
        {{ formatAmount(totalAmount) }}
        <n-button
          icon="edit"
          size="xSmall"
          @click="updateTotalAmount"
        />
      </div>
    </template>
    <template #paid-amount-label>
      <div v-if="hasBill">
        Réglé :
      </div>
    </template>
    <template #paid-amount-value>
      <div
        v-if="hasBill"
        data-test="paid-amount-value"
      >
        {{ formatAmount(totalPaid) }}
      </div>
    </template>
    <template #difference-amount-label>
      <div v-if="hasBill">
        Solde :
      </div>
    </template>
    <template #difference-amount-value>
      <div
        v-if="hasBill"
        data-test="difference-amount-value"
      >
        {{ formatAmount(difference, { showPositiveSign: true }) }}
      </div>
    </template>
    <template #billing-status>
      <app-tag
        :color="billingState.color"
        data-test="billing-status"
      >
        {{ billingState.shortLabel }}
      </app-tag>
    </template>
    <template #place>
      <div data-test="place">
        {{ consultation.activityPlacePractitionerLink.activityPlace.name }}
      </div>
    </template>
    <template #patient-name>
      <div data-test="patient-name">
        <span class="event-name">
          <span v-if="isCurrentUserFinancialSecretary">{{ patient.publicId }}</span>
          <a
            v-else
            v-test="'link-to-patient'"
            class="event-name__link"
            @mouseup.stop="goToPatientFile"
          >
            <span data-sentry-mask>{{ patientFullName }} </span>
            <span class="event-name__link__info">({{ patient.publicId }})</span>
          </a>
        </span>
      </div>
    </template>
    <template #payment-list>
      <payment-list
        :disabled="!hasBill"
        disabled-message="Impossible d'ajouter un règlement à une consultation sans montant d'honoraire"
        :consultation="consultation"
        @refresh="refreshConsultation"
      />
    </template>
  </accounting-list-item-layout>
</template>

<script>


import { mapGetters } from 'vuex';

import { BILLING_STATUSES, BILLING_STATUS_NAMES } from '@/modules/accounting/constants';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import Consultation from '@/modules/patient/models/Consultation';
import DetailedConsultation from '@/modules/patient/models/DetailedConsultation';
import NovaTools from '@/nova-tools/NovaTools';
import { formatAmount } from '@/utils/functions/currencies';
import { format } from '@/utils/functions/dates';
import { pluralize } from '@/utils/functions/words';

import AppTag from '@/components/ui/tag/AppTag.vue';
import ConsultationFeeForm from '@/modules/accounting/components/ConsultationFeeForm.vue';
import AccountingListItemLayout from '@/modules/accounting/layouts/AccountingListItemLayout.vue';
import PaymentList from '@/modules/patient/components/consultation/accounting/PaymentList.vue';

export default {
  name: 'AccountingListItem',
  components: {
    PaymentList,
    AccountingListItemLayout,
    AppTag,
  },
  props: {
    item: {
      type: DetailedConsultation,
      required: true,
    },
  },
  data () {
    return {
      totalAmount: null,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserFinancialSecretary']),
    consultation () {
      return new Consultation(this.item);
    },
    payments () {
      return this.item.payments;
    },
    patient () {
      return this.item.patient;
    },
    consultationDate () {
      return format(new Date(this.consultation.createdAt), 'dd/MM/yyyy');
    },
    totalPaid () {
      return this.payments.reduce((totalPaid, payment) => {
        totalPaid += payment.amount;
        return totalPaid;
      }, 0);
    },
    difference () {
      return this.totalPaid - this.consultation.fees;
    },
    patientFullName () {
      return this.consultation.patient.getCivilState();
    },
    paymentTitle () {
      const paymentsCount = this.payments.length;
      return `${paymentsCount} ${pluralize('règlement', paymentsCount)}`;
    },
    hasBill () {
      return this.consultation.billingState !== BILLING_STATUS_NAMES.NONE;
    },
    billingState () {
      return BILLING_STATUSES[this.consultation.billingState];
    },
  },
  watch: {
    'consultation.fees': {
      immediate: true,
      async handler (newValue) {
        this.totalAmount = newValue;
      },
    },
  },
  methods: {
    formatAmount,
    goToPatientFile () {
      this.$router.push({
        name: ROUTE_NAMES.PATIENT_FILE,
        params: { uuid: this.consultation.patient.getUuid() },
      });
    },
    async refreshConsultation () {
      this.$emit('refresh');
    },
    async updateTotalAmount () {
      NovaTools.modal.appendModalForm({
        value: { fees: this.consultation.fees },
        consultationIri: this.consultation.getIri(),
        title: 'Modifier le montant des honoraires',
        form: ConsultationFeeForm,
      }, this.refreshConsultation);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-skeleton-loader {
    &__text {
      margin-bottom: 0;
    }

    &__chip {
      height: 20px;
      width: auto;
    }
  }
}

.accounting-list-item-summary {
  display: flex;
  justify-content: space-between;
}

.event-name {
  text-overflow: ellipsis;
  overflow: hidden;

  &__link {
    color: var(--v-color-text);

    &:hover {
      text-decoration: underline;
    }

    &__info {
      margin-left: 5px;
      color: var(--v-secondary-lighten4);
      font-size: 12px;
    }
  }
}
</style>