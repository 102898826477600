<template>
  <validation-provider ref="validationProvider" v-slot="{ errors }">
    <div v-if="localDataset" class="indication-fieldset">
      <legend class="indication-fieldset__label">
        {{ label }}
        <app-icon
          v-if="infoIconText"
          icon="info"
          class="ml-2"
          color="secondary lighten-1"
          :tooltip="infoIconText"
        />
      </legend>
      <div v-for="(item, index) in items" :key="index" class="indication-fieldset__item">
        <n-tooltip v-slot="{ on }" :text="item.disabledTooltip || defaultDisabledTooltip" :disabled="!item.disabled">
          <span v-on="on">
            <app-checkbox
              v-model="localDataset[item.name]"
              v-test="'indication-checkbox'"
              :label="item.label"
              :disabled="item.disabled"
              :ripple="false"
              class="indication-fieldset__item__checkbox"
              @change="handleSelectionChange"
            />
          </span>
        </n-tooltip>
      </div>
      <v-slide-y-transition v-if="errors.length > 0">
        <p class="error--text">
          {{ errors[0] }}
        </p>
      </v-slide-y-transition>
    </div>
  </validation-provider>
</template>

<script>

import { ValidationProvider } from 'vee-validate';

import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';


/**
 * Composant groupe de checkbox à sélection unique pour l'indication de l'arrêt de travail
 */
export default {
  name: 'IndicationFieldset',
  components: {
    AppCheckbox,
    ValidationProvider,
  },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      localDataset: null,
      defaultDisabledTooltip: 'Indisponible dans cette configuration',
      currentSelectedOption: null,
    };
  },
  computed: {
    infoIconText () {
      return this.items.find(item => item.name === this.currentSelectedOption)?.text;
    },
  },
  watch: {
    dataset: {
      immediate: true,
      deep: true,
      handler () {
        this.localDataset = this.dataset;
        this.setCurrentSelectedOption();
      },
    },
    required: {
      handler () {
        this.validateSelection();
      },
    },
  },
  mounted () {
    this.validateSelection();
  },
  methods: {
    handleSelectionChange () {
      if (this.currentSelectedOption && this.localDataset[this.currentSelectedOption]) {
        this.localDataset[this.currentSelectedOption] = false;
      }
      this.$emit('input', this.localDataset);
    },
    setCurrentSelectedOption () {
      this.currentSelectedOption = Object.keys(this.localDataset).find(key => this.localDataset[key] === true) ?? null;

      this.validateSelection();
    },
    validateSelection () {
      this.$refs.validationProvider?.setErrors([]);
      if (this.required && ! this.currentSelectedOption) {
        this.$refs.validationProvider?.setErrors(['Une indication doit être sélectionnée']);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.indication-fieldset {
    &__label {
        font-size: 14px;
        color: var(--v-secondary-base);
        padding-bottom: 8px;
    }
    &__item {
      max-width: fit-content;
      &__checkbox {
        padding-bottom: 4px;
      }
    }
}
</style>