<template>
  <app-form :submit-method="submit">
    <app-row>
      <app-col cols="12" :class="colMdClass">
        <app-row>
          <app-col cols="12">
            <identity-fieldset
              v-model="localValue"
              v-test="'civil-state-fieldset'"
              :is-logging-ins-call="isLoggingInsCall"
              :auto-select-civility="! isEditing"
              :is-partner-access="isPartnerAccess"
              @fetch-ins="payload => saveInsiLog(payload)"
              @partner-success="onPartnerSuccess"
              @partner-error="onPartnerError"
            />
          </app-col>
          <app-col v-show="!isPartnerAccess" cols="12">
            <contact-info-fieldset :contact-points.sync="localValue.contactPoints" :address.sync="localValue.address" />
          </app-col>
        </app-row>
      </app-col>
      <app-col v-show="!isPartnerAccess" cols="12" md="4">
        <app-row>
          <app-col cols="12">
            <consents-fieldset
              :mss-document-consent.sync="localValue.mssDocumentConsent"
            />
          </app-col>
          <app-col cols="12">
            <other-informations-fieldset
              :note.sync="localValue.note"
              :profession.sync="localValue.profession"
              :family-situation.sync="localValue.familySituation"
            />
          </app-col>
          <app-col cols="12">
            <death-fieldset
              :is-deceased.sync="localValue.isDeceased"
              :death-date.sync="localValue.deathDate"
            />
          </app-col>
          <app-col cols="12">
            <bill-dataset-fieldset v-model="localValue.billDataset" />
          </app-col>
        </app-row>
      </app-col>
    </app-row>
  </app-form>
</template>

<script>

import { mapActions, mapMutations } from 'vuex';

import BillDatasetFieldset from './fieldsets/BillDatasetFieldset.vue';
import ConsentsFieldset from './fieldsets/ConsentsFieldset.vue';
import ContactInfoFieldset from './fieldsets/contactInfo/ContactInfoFieldset.vue';
import DeathFieldset from './fieldsets/DeathFieldset.vue';
import IdentityFieldset from './fieldsets/IdentityFieldset.vue';
import OtherInformationsFieldset from './fieldsets/OtherInformationsFieldset.vue';
import violationsMixin from '@/mixins/violations';
import Patient from '@/modules/patient/models/Patient';
import { isPartnerAccess, postPatientToPartner, postErrorToPartner } from '@/services/partnerService';

import AppForm from '@/components/ui/form/AppForm.vue';

export default {
  name: 'PatientForm',
  components: {
    AppForm,
    IdentityFieldset,
    ConsentsFieldset,
    BillDatasetFieldset,
    ContactInfoFieldset,
    OtherInformationsFieldset,
    DeathFieldset,
  },
  mixins: [violationsMixin],
  props: {
    /**
     * Modèle du patient
     * @model
     */
    value: {
      type: Patient,
      default: () => new Patient(),
    },
  },
  data () {
    return {
      isLoggingInsCall: false,
      pendingInsiLogs: [],
    };
  },
  computed: {
    localValue: {
      get () {
        return this.value;
      },
      set (newVal) {
        this.$emit('input', newVal);
      },
    },
    isEditing () {
      return !! this.value?.['@id'];
    },
    isPartnerAccess () {
      return isPartnerAccess();
    },
    colMdClass () {
      return `col-md-${  this.isPartnerAccess ? '12' : '8'}`;
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('patient', ['insertPatient', 'updatePatient']),
    async submit () {
      const action = this.isEditing ? 'updatePatient' : 'insertPatient';
      const patient = await this[action](new Patient(this.localValue));

      // Une fois le patient créé, on met à jour les logs en fournissant l'@id du patient
      await Promise.all(
        this.pendingInsiLogs.map(pendingLog => this.localValue.insIdentity.associatePatientIriToLog(pendingLog['@id'], patient['@id'])),
      );
      this.pendingInsiLogs = [];

      this.SET_SNACK({
        message: this.isEditing
          ? 'Le patient a été modifié avec succès'
          : 'Le patient a été ajouté avec succès',
      });
      postPatientToPartner(patient);
      return patient;
    },
    async saveInsiLog ({ data, callType }) {
      try {
        this.isLoggingInsCall = true;
        const callLogsData = await this.localValue.insIdentity.logCall(callType, {
          patient: this.localValue['@id'],
          trace: data,
        });

        // On met de côté le log généré tant que le patient n'a pas d'@id
        if (! this.isEditing) {
          this.pendingInsiLogs.push(callLogsData);
        }
      } finally {

        this.isLoggingInsCall = false;
      }
    },
    onPartnerSuccess (patientData) {
      postPatientToPartner(patientData);
    },
    onPartnerError (errorMsg) {
      postErrorToPartner(errorMsg);
    },
  },
};
</script>