<template>
  <app-button
    fab
    color="white"
    x-small
    tooltip="Tableau de bord Intellio next"
    @click="goToIntellioNextApp"
  >
    <svg
      height="26px"
      width="26px"
      class="icon-intellio-next"
    >
      <use xlink:href="#icon-intellio-next" />
    </svg>
  </app-button>
</template>

<script>
import { getFromAPI } from '@/services/api';

export default {
  methods: {
    async goToIntellioNextApp () {
      const { data, status } = await getFromAPI('/api/sephira/token');
      if (status === 200) {
        const {token} = data;
        const urlDashboard = `${import.meta.env.VUE_APP_INTELLIONEXT_BASE_URL}?Token=${token}`;
        window.open(urlDashboard);
      }
    },
  },
};
</script>