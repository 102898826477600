<template>
  <div>
    <patient-sidebar-panel-listing
      title="Facteurs de risque professionnels"
      create-label="Ajouter un facteur de risque professionnel"
      edit-label="Modifier un facteur de risque professionnel"
      risk-factor-name-key="professional"
      :items="patientProfessionalRiskFactors"
      :form="ProfessionalRiskFactorForm"
      :patient="patient"
      :loading="isLoading"
      no-data-text="Aucune donnée"
      :item-model-class="PatientProfessionalRiskFactor"
      @submit-success="fetchProfessionalRiskFactors"
    >
      <template #item="{ item, edit }">
        <patient-sidebar-risk-factor
          :risk-factor="item"
          :actions="getActions(item, edit)"
        />
      </template>
    </patient-sidebar-panel-listing>
  </div>
</template>

<script>


import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem';
import PatientProfessionalRiskFactor from '@/modules/patient/models/healthEntity/PatientProfessionalRiskFactor';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue';
import ProfessionalRiskFactorForm from '@/modules/patient/components/patientFile/riskFactors/professional/ProfessionalRiskFactorForm.vue';
import PatientSidebarRiskFactor from '@/modules/patient/components/PatientSidebarRiskFactor.vue';

export default {
  name: 'ProfessionalRiskFactorsListing',
  components: {
    PatientSidebarPanelListing,
    PatientSidebarRiskFactor,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      ProfessionalRiskFactorForm,
      PatientProfessionalRiskFactor,
      patientProfessionalRiskFactors: [],
      isLoading: false,
    };
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id']) {
          this.fetchProfessionalRiskFactors();
        }
      },
    },
  },
  methods: {
    async fetchProfessionalRiskFactors () {
      this.isLoading = true;
      try {
        this.patientProfessionalRiskFactors = await PatientProfessionalRiskFactor.fetchAll(this.patient['@id']);
      } finally {
        this.isLoading = false;
      }
    },
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({ callback: () => editMethod(item) }),
        new DeleteActionMenuItem({
          callback: async () => {
            NovaTools.dialog.confirm(
              'Supprimer le facteur de risque professionnel',
              `Êtes-vous sûr(e) de vouloir supprimer le facteur de risque professionnel ${item?.label} ?`,
              async () => {
                await PatientProfessionalRiskFactor.delete(item);
                await this.fetchProfessionalRiskFactors();
              },
            );
          },
        }),
      ];
    },
  },
};
</script>