<template>
  <div class="pathology-autocomplete-widget-v2">
    <section ref="widget" />
  </div>
</template>

<script>
import autocompleteMixin from '@/components/lap/synapse-widgets/mixins/autocomplete';
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget';
import { asPatientPathology } from '@/components/lap/synapse-widgets/utils/synapsePathologyDataMapper';

export default {
  name: 'PathologyAutocompleteWidgetV2',
  mixins: [widgetMixin, autocompleteMixin],
  async mounted () {
    await this.initWidget({
      terminologies: [
        {
          terminology: 'icd10',
          active: true,
          available: true,
        },
        {
          terminology: 'cisp',
          active: false,
          available: false,
        },
        {
          terminology: 'drc',
          active: false,
          available: false,
        },
      ],
      disableSelectedPathologies: true,
      enableFavorite: false,
    }, { zIndex: 204 });

    this.$nextTick(() => this.focus());

    this.widget.setCallback('onSelectPathology', (pathology) => {
      this.$emit('input', asPatientPathology(pathology));
    });
  },
};
</script>
<style lang="scss" scoped>
.pathology-autocomplete-widget-v2 {
  ::v-deep {
    .pathology-autocomplete {
      fieldset label {
        outline: none !important;
        border-bottom: 2px solid var(--v-primary-base);
      }
    }
  }
}
</style>