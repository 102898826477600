import { getNirObject } from '@/services/vendors/icanopee/utils/dataNormalizer';

/**
 * Permet de formatter les données d'un patient de façon à pouvoir l'utiliser dans le service ICannopée
 * @param {Patient} patient
 * @returns {Object}
 */
export const getVitaleDataFromPatient = patient => {
  const getFormattedBirthDate = (birthDate) => {
    const [year, month, day] = birthDate.split('-');
    return `${day}${month}${year}`;
  };
  return {
    s_name: patient.familyName || '',
    s_birthName: patient.birthName || '',
    s_givenName: patient.firstName || '',
    s_birthday: patient.birthDate ? getFormattedBirthDate(patient.birthDate) : '',
    i_rank: patient.billDataset?.birthRank ? parseInt(patient.billDataset.birthRank, 10) : 1,
    i_qualBenef: patient.billDataset?.quality ? parseInt(patient.billDataset.quality, 10) : 0,
    NirData: {
      Nir: getNirObject(patient.nir),
      CertifiedNir: getNirObject(patient.certifiedNir),
      s_certificationDate: '',
    },
    HealthCoverage: {
      s_regime: patient.billDataset?.grandRegime?.code || '',
      s_desk: patient.billDataset?.fund || '',
      s_center: patient.billDataset?.center || '',
    },
  };
};