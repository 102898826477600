import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { ROUTE_NAMES } from '@/modules/authentication/constants';
import router from '@/router/instance';
import { globalErrorHandler, displayError } from '@/services/errorManager';
import { isPartnerAccess, setPartnerAccessLoggedOut} from '@/services/partnerService';

const ONE_MINUTE_AS_MILLISECONDS = 60000;

/**
 * Plugin permettant de préparer axios à l'utilisation de l'api easy-care
 */
export default {
  isInitialized: false,
  axiosInstance: null,
  unprotectedUrl: [
    '/api/authentication_token',
    '/api/token/refresh',
    '/users/password/reset-link-email',
    '/users/password/reset',
    '/users/password/check-token',
    '/api/v1/partner/auth',
  ],
  intervalDuration: ONE_MINUTE_AS_MILLISECONDS,

  install () {
    this.axiosInstance = axios;
    this.setDefaultContentType('application/json');
    this.setDefaultBaseUrl(import.meta.env.VUE_APP_ROOT_API);

    this.getAxiosApiInstance().interceptors.request.use(
      (request) => this.onResolveRequestInterceptorProcess(request),
      (err) => this.onRejectRequestInterceptorProcess(err),
    );
    this.getAxiosApiInstance().interceptors.response.use(
      (response) => this.onResolveResponseInterceptorProcess(response),
      (err) => this.onRejectResponseInterceptorProcess(err),
    );

    setInterval(this.checkToken.bind(this), this.intervalDuration);
  },

  async onResolveRequestInterceptorProcess (request) {
    const controller = new AbortController();
    if (!this.unprotectedUrl.includes(request.url)) {
      if (this.getToken()) {
        request.headers.Authorization = `Bearer ${this.getToken()}`;
        if (this.isInitialized) {
          return request;
        }
        await this.refreshTokens(request);
        return request;
      }

      const requestAborted = {
        ...request,
        signal: controller.signal,
      };
      controller.abort();
      return requestAborted;
    }
    return request;
  },

  async onRejectRequestInterceptorProcess (err) {
    return err;
  },

  onResolveResponseInterceptorProcess (response) {
    // eslint-disable-next-line default-case
    switch (response.config.url) {
    case '/api/authentication_token':
    case '/api/token/refresh':
    case '/api/v1/partner/auth':
      this.setTokens(response.data.token, response.data.refresh_token, response.data.refresh_token_expiration);
      this.setAuthPayload(response.data);
      break;
    case '/api/logout':
      this.resetTokens();
            break;
    }
    return response;
  },

  async onRejectResponseInterceptorProcess (err) {
    if (err?.code !== 'ERR_CANCELED') {
      const currentPath = err.response.config.url;

      switch (currentPath) {
      case '/api/authentication_token':
        this.showNotification(err.response.data.message);
        await this.redirectToAuthentication();
        break;
      case '/api/logout':
        await this.redirectToAuthentication();
        break;
      case '/api/token/refresh':
        this.showNotification('Votre session a expiré');
        await this.redirectToAuthentication();
        break;
      case '/api/v1/partner/auth':
        this.showNotification(err.response.data.message);
        break;
      default:
        switch (err.response.status) {
        case 401:
          if (! this.unprotectedUrl.includes(currentPath)) {
            return this.refreshTokens(err.config);
          }
          break;
        default:
          await globalErrorHandler(err);
        }
      }
      return err;
    }
    return Promise.reject(err);
  },

  async refreshTokens (config) {
    const resultRefresh = await this.renewToken();
    if (resultRefresh?.status === 200) {
      if (config?.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      return this.getAxiosApiInstance()(config);
    }
    return Promise.reject(new Error('Token refresh failed'));
  },

  setDefaultBaseUrl (baseUrl) {
    this.getAxiosApiInstance().defaults.baseURL = baseUrl;
  },

  setDefaultContentType (contentType) {
    this.getAxiosApiInstance().defaults.headers['Content-Type'] = contentType;
  },

  setTokens (token, refreshToken, refreshTokenExpiration) {
    this.isInitialized = true;
    this.setToken(token);
    this.setRefreshToken(refreshToken);
    this.setRefreshTokenExpiration(refreshTokenExpiration);
  },

  resetTokens () {
    this.isInitialized = false;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiration');
    localStorage.removeItem('authPayload');
    if (isPartnerAccess()) {
      setPartnerAccessLoggedOut(true);
    }
  },

  setToken (token) {
    localStorage.setItem('token', token);
  },

  getToken () {
    return localStorage.getItem('token') ?? null;
  },

  setRefreshToken (refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  },

  getRefreshToken () {
    return localStorage.getItem('refresh_token');
  },

  setRefreshTokenExpiration (refreshTokenExpiration) {
    localStorage.setItem('refresh_token_expiration', refreshTokenExpiration);
  },

  getRefreshTokenExpiration () {
    return localStorage.getItem('refresh_token_expiration');
  },

  setAuthPayload (authPayload) {
    localStorage.setItem('authPayload', JSON.stringify(authPayload));
  },

  getAuthPayload () {
    return JSON.parse(localStorage.getItem('authPayload'));
  },
  getCurrentUser () {
    return this.getAuthPayload().user;
  },
  getAxiosApiInstance () {
    return this.axiosInstance;
  },

  showNotification (message) {
    displayError(message);
  },

  async checkToken () {
    if (this.getToken()) {
      if (!this.isRefreshTokenValid()) {
        this.showNotification('Votre session a expiré');
        await this.redirectToAuthentication();
      }
    } else {
      await this.redirectToAuthentication();
    }
  },

  isRefreshTokenValid () {
    let isExpired = true;
    const refreshTokenExpiration = this.getRefreshTokenExpiration();
    if (refreshTokenExpiration) {
      const expiration = refreshTokenExpiration * 1000;
      const currentTime = new Date().getTime();
      isExpired = (expiration < currentTime);
    }
    return !isExpired;
  },

  async redirectToAuthentication () {
    if (router.currentRoute.name !== ROUTE_NAMES.CONNECTION) {
      await router.push({ name: ROUTE_NAMES.CONNECTION });
    }
    this.resetTokens();
  },

  renewToken () {
    return this.getAxiosApiInstance().get('/api/token/refresh', { params: { refresh_token: this.getRefreshToken() } });
  },

  getTokenExpire () {
    const token = this.getToken();
    return token ? jwtDecode(token).exp * 1000 : null;
  },
};
