const DEFAULT_SIDEBARS_SIZE = 350;

export default {
  props: {
    sidebarTitle: {
      type: String,
      default: null,
    },
    sidebarWidth: {
      type: Number,
      default: DEFAULT_SIDEBARS_SIZE,
    },
    mobileFabIcon: {
      type: String,
      default: 'menu-alt',
    },
    sidebarOnRight: {
      type: Boolean,
      default: false,
    },
    hideMobileFab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pageLayoutMixin_props () {
      return {
        sidebarTitle: this.sidebarTitle,
        sidebarWidth: this.sidebarWidth,
        hideMobileFab: this.hideMobileFab,
        sidebarOnRight: this.sidebarOnRight,
      };
    },
  },
};