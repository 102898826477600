<template>
  <app-form :submit-method="saveForm">
    <drug-autocomplete
      ref="drugSearch"
      v-model="medication.product"
      append-icon="pills"
      rules="required"
    />
    <app-date-picker
      v-model="medication.endDate"
      label="Date de fin"
      data-test="end-date-input"
      name="date"
      :min="new Date()"
      icon="fa-calendar"
      :disabled="longTermTreatment"
    />
    <app-switch
      v-model="longTermTreatment"
      dense
      label="Traitement au long cours"
      class="mt-4"
    />
  </app-form>
</template>

<script>

import { mapActions } from 'vuex';

import Medication from '@/modules/patient/models/Medication';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import DrugAutocomplete from '@/components/lap/DrugAutocomplete.vue';
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue';

export default {
  name: 'OngoingMedicationForm',
  components: {
    AppDatePicker,
    AppForm,
    DrugAutocomplete,
    AppSwitch,
  },
  props: {
    value: {
      type: Medication,
      default: null,
    },
    /**
     * Patient associée
     */
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      medication: new Medication(),
      longTermTreatment: this.value?.longTerm,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.medication = new Medication(value || {});
        this.longTermTreatment = value?.longTerm;
      },
    },
    longTermTreatment (longTermTreatment) {
      if (longTermTreatment) {
        this.medication.endDate = null;
      }
    },
  },
  methods: {
    ...mapActions('patient', ['insertOngoingMedication', 'updateOngoingMedication']),
    async saveForm () {
      const action = this.medication?.['@id'] ? 'updateOngoingMedication' : 'insertOngoingMedication';
      const snackMessage = `Le médicament a été ${this.medication?.['@id'] ? 'modifié' : 'ajouté'} avec succès`;
      await this[action]({
        patientUuid: this.patient.getUuid(),
        medication: new Medication({
          ...this.medication,
          longTerm: this.longTermTreatment,
        }),
      });
      NovaTools.notify.success(snackMessage);
    },
  },
};
</script>
<style scoped lang="scss">
  .ongoing-medication-display-selected-product {
    display: flex;
    align-items: center;
    gap: map-get($spacers, 4);
  }
</style>