/**
 * Permet de fabriquer un bundle Synapse fictif
 * @param {Function} implementationProvider Une méthode retournant l'implémentation désirée pour les méthodes
 * @returns {Object} Le bundle Synapse fictif
 */
export const synapseBundleFactory = (implementationProvider) => {
  if (! implementationProvider) {
    throw new Error('Vous devez fournir une implémentation pour les méthodes mockées');
  }
  const callbacks = {};

  const widgetMock = function widgetMock () {
    this.setToken = implementationProvider('setToken');
    this.init = implementationProvider('init');
    this.update = implementationProvider('update');
    this.destroy = implementationProvider('destroy');
    this.setCallback = implementationProvider('setCallback', { callbacks });
    this.triggerCallback = implementationProvider('triggerCallback', { callbacks });
  };

  return {
    callbacks,
    setLang: implementationProvider(),
    PathologyAutocompleteWidgetV2: widgetMock,
    AutocompleteWidget: widgetMock,
    PosologyCheckerWidget: widgetMock,
    PosologyFormWidget: widgetMock,
    AdverseEffectWidget: widgetMock,
    StartStoppWidget: widgetMock,
    InteractionWidget: widgetMock,
    GroundsWidget: widgetMock,
    MedicalResourceAutocompleteWidget: widgetMock,
    PrescriptionSummaryWidget: widgetMock,
    AllergyAutocompleteWidget: widgetMock,
    NextPosologyFormWidget: widgetMock,
  };
};
