import { uniqBy as _uniqBy, isNil } from 'lodash';

/**
 * Permet de retirer des éléments non définis dans un tableau.
 * Conserve le nombre 0 s'il est présent
 * @param {Array} arr Le tableau source
 * @returns {Array} Le tableau sans ses éléments non définis
 */
export const compact = (arr) => arr.filter(item => (!(typeof item === 'number' && Number.isNaN(item))) && (!isNil(item)) && (item !== ''));

/**
 * Indique si des éléments définis sont présents dans un tableau
 * @param {Array} arr Le tableau source
 * @returns {boolean} Si le tableau est vide ou pas
 */
export const isArrayEmpty = (arr) => compact(arr).length === 0;

/**
 * Permet de retirer les doublons dans un tableau.
 * @param {Array} arr Le tableau source
 * @param {String} key La clé sur laquelle tester l'unicité
 * @returns {Array} Le tableau sans les doublons
 */
export const uniqBy = (arr, key) => _uniqBy(arr, key);

/**
 * Permet de filtrer de manière récursive un tableau
 * @param {Array} array
 * @param {Function} callback
 * @returns {Array}
 */
export const filterDeep = (array, callback) => compact(array.map(item => {
  if (Array.isArray(item)) {
    const result = filterDeep(item, callback);
    return result.length ? result : null;
  }
  return callback(item) ? item : null;
}));
