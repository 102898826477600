<template>
  <div style="display: flex">
    <app-dropdown-menu-list
      icon="magnifying-glass-bolt"
      title="Rechercher"
    >
      <app-dropdown-menu-list-item
        icon="prescription"
        title="Une e-Prescription"
        :subtitle="!isValidCps ? 'Nécessite une carte CPS' : null"
        @click="openSearchPrescriptionModal"
      />
    </app-dropdown-menu-list>
    <search-prescription-modal
      :is-open="showSearchPrescriptionModal"
      @cancel="showSearchPrescriptionModal=false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';

import AppDropdownMenuList from '@/components/ui/dropdownMenuList/AppDropdownMenuList.vue';
import AppDropdownMenuListItem from '@/components/ui/dropdownMenuList/AppDropdownMenuListItem.vue';
import SearchPrescriptionModal from '@/layout/default/components/header/searchMenu/searchPrescription/SearchPrescriptionModal.vue';

export default {
  name: 'SearchMenuButton',
  components: {
    AppDropdownMenuListItem,
    AppDropdownMenuList,
    SearchPrescriptionModal,
  },
  data () {
    return { showSearchPrescriptionModal: false };
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasCard', 'getCardContent']),
    isValidCps () {
      return this.getHasCard(CARD_TYPES.CPX) && this.getCardContent(CARD_TYPES.CPX);
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    openSearchPrescriptionModal () {
      if (this.isValidCps) {
        this.showSearchPrescriptionModal = true;
      } else {
        this.SET_SNACK({
          color: 'warning',
          message: 'Cette action nécessite une carte CPS',
        });
      }
    },
  },
};
</script>