import EfficienceModal from '@/nova-tools/icanopee/efficience/efficience_common';
import NovaTools from '@/nova-tools/NovaTools';

import EfficienceModalDmp from '@/nova-tools/icanopee/efficience/dmp/EfficienceModalDmp.vue';

export class Efficience {
  #modalId = null;

  /**
   * Permet d'ouvrir le dmp d'un patient dans une modale
   * @returns {int} l'id de la modale
   */
  openModalIframe (patient) {
    if (! patient.insIdentity.isValidated()) {
      NovaTools.notify.warning('Accès au DMP non autorisé en l\'absence du matricule INS du patient');
      return null;
    }
    const { id } = NovaTools.modal.append(EfficienceModalDmp, {
      showModal: true,
      url: EfficienceModal.getEfficienceIframeSearchUrl(patient),
      isRemoteMode: false,
    });
    this.#modalId = id;
    return id;
  }
}
export default new Efficience();