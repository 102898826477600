<template>
  <div class="appointments-panel-item__appointment">
    <div class="appointments-panel-item__state">
      <v-icon
        v-test="'appointment-state'"
        v-tooltip="stateLabel"
        small
      >
        {{ stateIcon }}
      </v-icon>
    </div>
    <div>
      <span
        v-test="'appointment-link'"
        class="appointments-panel-item__date"
        @click="goToAppointment"
      >
        <span v-test="'appointment-date'">{{ appointment.getStartDateWithDayOfWeek() }}</span>
        <span
          v-test="'appointment-time'"
          class="ml-2 appointments-panel-item__time"
        >
          {{ appointment.getFormattedTimePeriod() }}
        </span>
      </span>
      <div
        v-if="loading"
        class="mb-last-0"
      >
        <div
          v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
          :key="index"
          v-test="'motive-skeleton'"
          class="mb-4"
        >
          <app-skeleton-loader
            type="text"
            width="80%"
          />
        </div>
      </div>
      <p
        v-else-if="appointment.motive"
        class="mb-0 appointments-panel-item__motive"
      >
        <v-badge
          v-test="'appointment-motive'"
          dot
          inline
          left
          :color="motive.color"
        >
          {{ motiveName }}
        </v-badge>
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import { APPOINTMENT_STATES } from '@/modules/agenda/constants';
import Appointment from '@/modules/agenda/models/events/Appointment';
import Motive from '@/modules/agenda/models/Motive';
import { getFromAPI } from '@/services/api';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';

const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 1;

export default {
  name: 'AppointmentsPanelItem',
  components: { AppSkeletonLoader },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return {
      motive: null,
      loading: false,
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
    };
  },
  computed: {
    appointmentId () {
      return this.appointment['@id'].split('/')[3];
    },
    motiveName () {
      return this.motive?.name ? this.motive.name : '';
    },
    stateIcon () {
      return `fas fa-${APPOINTMENT_STATES[this.appointment.state.toUpperCase()].icon}`;
    },
    stateLabel () {
      return APPOINTMENT_STATES[this.appointment.state.toUpperCase()].name;
    },
  },
  async created () {
    await this.getOneMotive();
  },
  methods: {
    ...mapMutations('agenda', ['SET_CURRENT_DATE']),
    async getOneMotive () {
      if (this.appointment.motive) {
        this.loading = true;
        const { data } = await getFromAPI(this.appointment.motive);
        this.motive = data ? new Motive({ ...data }) : null;
      }
      this.loading = false;
    },
    goToAppointment () {
      this.SET_CURRENT_DATE(this.appointment.startDateTime);
      this.$router.push({
        name: 'agenda.edit.appointment',
        params: { eventUuid: this.appointmentId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointments-panel-item {
  &__appointment {
    display: flex;
  }
  &__date {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
  }
  &__time {
    font-size: 12px;
    color: var(--v-secondary-base);
    font-weight: lighter;
  }
  &__motive {
    font-size: 13px;
    font-weight: 400;
  }
  &__state {
    align-self: center;
    margin-right: 15px;
  }
}
</style>