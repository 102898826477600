<template>
  <div class="moved-appointment-snack">
    <div class="flex-grow-1">
      <h3 class="moved-appointment-snack__title">
        Vous déplacez un rendez-vous
      </h3>
      <p
        class="moved-appointment-snack__patient mb-0"
        data-test="moved-appointment-patient"
      >
        {{ appointmentPatientCivilState }}
      </p>
    </div>
    <app-button
      color="secondary"
      icon="close"
      data-test="cancel-appointment-move"
      @click="cancelAppointmentMove"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'MovedAppointmentSnack',
  computed: {
    ...mapGetters('agenda', ['getMovedAppointment']),
    appointmentPatientCivilState () {
      return this.getMovedAppointment?.patient.getCivilState();
    },
  },
  methods: {
    ...mapMutations('agenda', ['SET_MOVED_APPOINTMENT']),
    cancelAppointmentMove () {
      this.SET_MOVED_APPOINTMENT(null);
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 32px;

.moved-appointment-snack {
  display: flex;
  align-items: center;
  padding: map-get($spacers, 3);
  background-color: var(--v-primary-lighten5);
  border: 1px solid var(--v-secondary-lighten4);
  border-radius: 6px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $icon-size;
    height: $icon-size;
    width: 100%;
    border-radius: 50%;
    background-color: var(--v-secondary-base);
  }

  &__title {
    color: var(--v-secondary-base);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__patient {
    font-weight: 600;
    font-size: 13px;
  }
}
</style>