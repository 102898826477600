import { normalizeRules as VeeValidateNormalizeRules } from 'vee-validate';

/**
 * Une définition de règles de validation
 * (@see {@link https://vee-validate.logaretm.com/v3/guide/rules.html#rules})
 * @typedef {(String|Object)} Rules
 */

/**
 * Retourne un ensemble de règles de validation en les convertissant sous la forme d'un objet { [règle]: valeur }
 * (@see {@link https://vee-validate.logaretm.com/v3/advanced/rules-object-expression.html#rules-object-expression})
 * @param {Rules} rules
 * @returns {Object}
 */
export const normalizeRules = (rules) => VeeValidateNormalizeRules(rules);

/**
 * Retourne la valeur d'une règle de validation
 * @param {Rules} rules
 * @param {String} ruleName
 * @returns
 */
export const getRuleValue = (rules, ruleName) => normalizeRules(rules)[ruleName];

/**
 * Permet de savoir si les règles de validations possède un caractère requis
 * @param {Rules} rules
 * @returns {Boolean}
 */
export const isRequired = (rules) => getRuleValue(rules, 'required');

/**
 * Retourne un label de champ en fonction de ses règles de validation
 * @param {String} label
 * @param {Rules} rules
 * @returns {String}
 */
export const getFormattedFieldLabel = (label, rules) => {
  if (isRequired(rules) && !getRuleValue(rules, 'hide')) {
    return `${label} *`;
  }
  return label;
};
