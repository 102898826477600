export const SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES = Object.freeze({
  VSM: {
    text: 'Volets de synthèse médicale',
    value: 'vsm',
  },
  E_PRESCRIPTION: {
    text: 'E-Prescriptions',
    value: 'eprescription',
  },
  CONSULTATION_DOCUMENT: {
    text: 'Documents de consultation',
    value: 'consultation_document',
  },
  PRESCRIPTION_DOCUMENT: {
    text: 'Ordonnances',
    value: 'prescription_document',
  },
  DISCHARGE_LETTER_DOCUMENT: {
    text: 'Lettres de liaison à la sortie d\'un établissement',
    value: 'consultation_discharge_letter_document',
  },
  CERTIFICATE_DOCUMENT: {
    text: 'Certificats, déclarations',
    value: 'consultation_certificate_document',
  },
  TELEMEDICINE_ACT_DOCUMENT: {
    text: 'Demande d\'acte de télémédecine',
    value: 'consultation_telemedicine_act_document',
  },
  PATIENT_IMPORTED_DOCUMENT: {
    text: 'Fichiers importés dans les dossiers patients',
    value: 'patient_imported_document',
  },
});