<template>
  <app-autocomplete
    ref="autocompleteInput"
    v-model="localSelected"
    :autofocus="autofocus"
    :items="items"
    :placeholder="placeholder"
    :label="label"
    :disabled="disabled"
    :item-value="itemValueField"
    :item-text="itemTextField"
    :clearable="clearable"
    :multiple="multiple"
    :prepend-icon="prependIcon"
    :return-object="returnObject"
    :loading="isFetchingItems"
    :search-input.sync="localSearch"
    :min-search-length="minSearchLength"
    :no-filter="noFilter"
    :chips="chips"
    :deletable-chips="deletableChips"
    :small-chips="smallChips"
    :rules="rules"
    :close-on-content-click="closeOnContentClick"
    :hide-no-data="isFetchingItems || ! hasMinLength"
    :name="name"
    @change="selectListItem"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template
      v-if="$scopedSlots.selection"
      #selection="data"
    >
      <slot
        name="selection"
        :data="data"
      >
        {{ data.item[itemTextField] }}
      </slot>
    </template>
    <template
      v-if="$scopedSlots.item"
      #item="data"
    >
      <slot
        name="item"
        :data="data"
      >
        <component
          :is="itemRenderer"
          v-if="itemRenderer"
          :item="data.item"
        />
        <v-list-item-content v-else>
          <v-list-item-title>
            {{ data.item[itemTextField] }}
          </v-list-item-title>
        </v-list-item-content>
      </slot>
    </template>
    <template #no-data>
      <div ref="no-data-slot">
        <slot name="no-data">
          <v-list-item>
            <v-list-item-title>Aucun résultat</v-list-item-title>
          </v-list-item>
        </slot>
      </div>
    </template>
    <template
      v-if="fetchedItems && fetchedItems.length"
      #append-item
    >
      <v-lazy
        :key="currentPage"
        v-model="hasBeenIntercepted"
        :style="{ marginBottom: '1px' }"
      />
    </template>
  </app-autocomplete>
</template>

<script>

import { debounce } from 'lodash';

import focusableFieldMixin from '@/mixins/focusableFieldMixin';
import { getFromAPI } from '@/services/api';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';

/**
 * Composant de champ autocomplete permettant d'effectuer une recherche sur l'api de l'application
 */
export default {
  name: 'AppApiAutocomplete',
  components: { AppAutocomplete },
  mixins: [
    focusableFieldMixin(),
    localCopyMixin({
      propertyName: 'search',
      copyPropertyName: 'localSearch',
    }),
    localCopyMixin({
      propertyName: 'selected',
      copyPropertyName: 'localSelected',
    }),
  ],
  props: {
    url: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemTextField: {
      type: [String, Function, Array],
      default: null,
    },
    itemValueField: {
      type: [String, Function],
      default: 'value',
    },
    itemRenderer: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'Rechercher',
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Object, String, Number, Array],
      default: null,
    },
    minSearchLength: {
      type: Number,
      default: 3,
    },
    prependIcon: {
      type: String,
      default: 'search',
    },
    additionalParameters: {
      type: Object,
      default: null,
    },
    /**
     * Des règles de validation séparées par | (pipe)
     * @see Voir les règles disponibles : https://logaretm.github.io/vee-validate/guide/rules.html#rules
     */
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: null,
    },
    itemModelClass: {
      type: Function,
      default: null,
    },
    /**
     * Liste d'éléments en cache
     * Permet un affichage instantané du résultat
     * L'original prends toujours le pas sur un résultat en cache
     */
    cachedItems: {
      type: Array,
      default: () => ([]),
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de vider un champ
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de désactiver le filtrage automatique
     */
    noFilter: {
      type: Boolean,
      default: true,
    },
    /**
     * Permet de désactiver le champ
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    /**
     * Vide la recherche lors du blur du champ
     * Si le contenu de la recherche doit être utilisé par la suite
     * il faut passer cette props à false
     */
    clearOnBlur: {
      type: Boolean,
      default: true,
    },
    /**
     * Permet de fermer la liste lorsque l'on clique dans le contenu
     */
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      fetchedItems: [],
      currentPage: 0,
      totalItemsCount: 0,
      isFocused: false,
      isFetchingItems: false,
      hasBeenIntercepted: false,
      axiosCanceller: null,
    };
  },
  computed: {
    hasMinLength () {
      return (this.localSearch && this.localSearch.length >= this.minSearchLength);
    },
    canFetchResults () {
      return this.hasMinLength || this.localSelected;
    },
    items () {
      const hasApiResults = this.fetchedItems && this.fetchedItems.length > 0;
      if (this.multiple) {
        // Permet de pouvoir rechercher d'autres éléments sans perdre la sélection
        return hasApiResults
          ? [...this.selected, ...this.fetchedItems]
          : [...this.selected, ...this.cachedItems];
      }
      return hasApiResults ? this.fetchedItems : this.cachedItems;
    },
  },
  watch: {
    url: {
      immediate: true,
      handler () {
        this.initAndFetchResults();
      },
    },
    localSearch (localSearch) {
      this.currentPage = 0;
      if (localSearch) {
        this.initAndFetchResults();
        return;
      }
      this.fetchedItems = [];
    },
    hasBeenIntercepted: {
      immediate: true,
      async handler (hasBeenIntercepted) {
        if (hasBeenIntercepted && this.isFocused) {
          await this.$nextTick();
          await this.nextPageLoad();
        }
      },
    },
  },
  methods: {
    async initAndFetchResults () {
      this.currentPage = 0;
      this.isFetchingItems = this.localSearch && this.localSearch.length >= this.minSearchLength;
      await this.fetchNextPageResultsDebounced();
    },
    async nextPageLoad () {
      // Vérifie l'existence de la réf liée à l'absence de résultats de recherche
      // Afin d'éviter le lancement de requête inutile
      const hasResults = ! this.$refs?.['no-data-slot'];
      if (! hasResults || ! this.canFetchResults) {
        return;
      }
      if (this.fetchedItems.length < this.totalItemsCount && ! this.isFetchingItems) {
        this.isFetchingItems = true;
        const results = await this.fetchNextPageResults();
        if (results) {
          this.fetchedItems.push(...results);
        }
      }
    },
    fetchNextPageResultsDebounced: debounce(async function fetchNextPageResultsDebounced () {
      if (this.canFetchResults) {
        try {
          this.isFetchingItems = true;
          this.fetchedItems = await this.fetchNextPageResults();
        } catch {
          this.fetchedItems = [];
          this.isFetchingItems = false;
        }
      } else {
        if (this.axiosCanceller) {
          this.axiosCanceller.abort();
          this.axiosCanceller = null;
        }
        this.fetchedItems = [];
        this.isFetchingItems = false;
      }
    }, 400),
    async fetchNextPageResults () {
      let results = [];
      try {
        if (this.axiosCanceller) {
          this.axiosCanceller.abort();
          this.axiosCanceller = null;
        }
        this.axiosCanceller = new AbortController();

        const response = await getFromAPI(this.url, {
          pagination: true,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage + 1,
          global: this.localSearch,
          ...this.additionalParameters,
        }, { signal: this.axiosCanceller.signal });

        if (response) {
          this.hasBeenIntercepted = false;
          this.currentPage += 1;
          this.totalItemsCount = response.data['hydra:totalItems'];
          results = response.data['hydra:member'];
          this.isFetchingItems = false;
        }
        const ModelClass = this.itemModelClass;
        return ModelClass ? results.map(item => new ModelClass(item)) : results;
      } catch {
        this.isFetchingItems = false;
        if (this.axiosCanceller) {
          this.axiosCanceller.abort();
          this.axiosCanceller = null;
        }
        return results;
      }
    },
    selectListItem (selection) {
      this.$emit('selected', selection);
      if (! selection) {
        this.fetchedItems = [];
      }
      if (this.clearOnSelect) {
        this.clear();
      }
    },
    clear () {
      this.localSearch = null;
      this.$nextTick(() => {
        this.fetchedItems = [];
        this.localSelected = null;
      });
    },
    onFocus () {
      this.isFocused = true;
    },
    onBlur () {
      this.isFocused = false;
      if (! this.localSelected) {
        if (this.axiosCanceller) {
          this.axiosCanceller.abort();
          this.axiosCanceller = null;
        }
        this.fetchedItems = [];
        if (this.clearOnBlur) {
          this.localSearch = null;
        }
      }
      this.$emit('blur');
    },
  },
};
</script>