<template>
  <app-panel
    title="Fichiers importés"
    header-divided
    :actions="getPanelActions()"
    :max-actions-visible="2"
  >
    <section>
      <app-circular-loader
        v-if="isLoading && Object.keys(documentsTree).length === 0"
        label="Récupération des documents"
      />
      <template v-else>
        <app-row dense>
          <app-col cols="6">
            <app-text-field
              v-model="filters.title"
              v-test="'text-filter'"
              prepend-icon="search"
              label="Nom du document"
              :loading="isLoading"
              clearable
            />
          </app-col>
          <app-col cols="3">
            <app-select
              v-model="filters.type"
              v-test="'type-filter'"
              :items="filters.availableTypes"
              label="Type"
              :loading="isLoading"
              clearable
              item-text="label"
            />
          </app-col>
          <app-col cols="3">
            <app-select
              v-model="filters.date"
              v-test="'date-filter'"
              :items="filters.availableDates"
              label="Date"
              :loading="isLoading"
              clearable
              item-text="label"
            />
          </app-col>
        </app-row>
        <drop
          class="mt-2"
          mode="cut"
          @drop="onDropOnRoot"
        >
          <div class="root-drop-zone" />
        </drop>
        <v-treeview
          :open="treeOpened"
          :items="documentsTree"
          activatable
          item-key="@id"
          open-on-click
          dense
        >
          <template #prepend="{ item, open }">
            <app-icon
              v-if="item.children"
              color="#F8C64A"
              :icon="open ? 'folder-open' : 'folder'"
              :size="24"
              style="width: 32px"
            />
            <template v-else>
              <app-file-preview
                v-if="['image/jpeg', 'image/png'].includes(item.data.mimeType)"
                v-test="'document-preview'"
                class="app-file-preview-item"
                :type="item.data.mimeType"
                :src="item.data.getFullContentURL()"
              />
              <app-icon
                v-else-if="item.data.mimeType === 'application/pdf'"
                icon="file-pdf"
                :size="24"
                style="width: 32px"
              />
              <app-icon
                v-else
                icon="file"
                :size="24"
                style="width: 32px"
              />
            </template>
          </template>
          <template #label="{ item }">
            <drag
              :key="item.data['@id']"
              :data="item.data"
              @dragstart="isDragInProgress=true"
              @dragend="isDragInProgress=false"
              @cut="() => {}"
            >
              <drop
                v-if="isFolder(item.data)"
                class="item-drop-zone"
                mode="cut"
                @drop="onDropOnFolder($event,item.data)"
              >
                <folder-item
                  :folder.sync="item.data"
                  :patient-iri="patient['@id']"
                  :level="item.level"
                  @updated="fetchDocumentsTree"
                />
              </drop>
              <drop
                v-else
                class="item-drop-zone"
                mode="cut"
                @drop="onDropOnFile($event,item)"
              >
                <file-item
                  :file.sync="item.data"
                  :patient-iri="patient['@id']"
                  @updated="fetchDocumentsTree"
                />
              </drop>
            </drag>
          </template>
          <template #append="{ item }">
            <app-actions-menu
              :key="item.data['@id']"
              :max-actions-visible="item.children ? 1 : 2"
              :actions="getFileActions(item.data)"
            />
          </template>
        </v-treeview>
        <drop
          class="mt-2"
          mode="cut"
          @drop="onDropOnRoot"
        >
          <div class="root-drop-zone" />
        </drop>
      </template>
    </section>
  </app-panel>
</template>

<script>

import { debounce } from 'lodash';
import { Drag, Drop } from 'vue-easy-dnd';

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import Patient from '@/modules/patient/models/Patient';
import PatientDocument from '@/modules/patient/models/PatientDocument';
import PatientDocumentFolder from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/models/PatientDocumentFolder';
import NovaTools from '@/nova-tools/NovaTools';

import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue';
import AppFilePreview from '@/components/ui/filePreview/AppFilePreview.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import FileItem from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/FileItem.vue';
import FolderItem
  from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/FolderItem.vue';
import DocumentFolderFormVue from '@/modules/patient/modules/patientFile/modules/documents/forms/DocumentFolderForm.vue';
import DocumentForm from '@/modules/patient/modules/patientFile/modules/documents/forms/DocumentForm.vue';

export default {
  name: 'PatientDocumentsExplorer',
  components: {
    FolderItem,
    FileItem,
    AppFilePreview,
    AppPanel,
    AppActionsMenu,
    AppCircularLoader,
    AppSelect,
    AppTextField,
    Drag,
    Drop,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
      DocumentForm,
      filters: {
        title: null,
        type: null,
        availableTypes: [
          {
            value: 'pdf',
            label: 'Fichiers PDF',
          },
          {
            value: 'image',
            label: 'Images',
          },
          {
            value: 'text',
            label: 'Documents texte',
          },
          {
            value: 'archive',
            label: 'Archives (ZIP)',
          },
        ],
        date: 'new',
        availableDates: [
          {
            value: 'new',
            label: 'À partir du plus récent',
          },
          {
            value: 'old',
            label: 'À partir du plus ancien',
          },
        ],
      },
      documentsTree: {},
      folderContent: [],
      folderPath: ['Général'],
      treeOpened: [],
      isDragInProgress: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.fetchDocumentsTreeDebounced();
      },
    },
  },
  async created () {
    await this.fetchDocumentsTree();
  },
  methods: {
    fetchDocumentsTreeDebounced: debounce(function fetchDocumentsTreeDebounced () {
      this.fetchDocumentsTree();
    }, 1000),
    async fetchDocumentsTree () {
      this.isLoading = true;
      this.documentsTree = await PatientDocument.fetchAsTree({
        patient: this.patient.getUuid(),
        title: this.filters.title,
        type: this.filters.type,
        date: this.filters.date,
      });
      this.isLoading = false;
    },
    isFolder (item) {
      return item['@type'] === 'PatientImportedDocumentFolder';
    },
    getPanelActions () {
      return {
        addFile: new ActionMenuItem('upload', 'Importer des fichiers à la racine', this.openImportFilesModal),
        addFolder: new ActionMenuItem('folder-add', 'Créer un dossier à la racine', this.openNewDirectoryModal),
      };
    },
    getFileActions (item) {
      return item.getMenuActions({
        patient: this.patient,
        onOpenImportFilesModal: this.openImportFilesModal,
        onOpenNewDirectoryModal: this.openNewDirectoryModal,
        onDeleted: this.fetchDocumentsTree,
      });
    },
    openImportFilesModal (currentFolder = null) {
      NovaTools.modal.appendModalForm({
        patient: this.patient['@id'],
        folderParent: currentFolder,
        title: 'Importer des fichiers',
        form: DocumentForm,
        isOpened: true,
        width: '70%',
        scrollable: true,
        mustTriggerFunction: true,
      }, this.fetchDocumentsTree);
    },
    openNewDirectoryModal (currentFolder = null) {
      NovaTools.modal.appendModalForm({
        patientIri: this.patient.getIri(),
        folderParent: currentFolder,
        title: 'Créer un dossier',
        form: DocumentFolderFormVue,
        isOpened: true,
      }, this.fetchDocumentsTree);
    },
    async onDropOnFolder (event, folder = null) {
      await this.onDrop(event, folder ? folder['@id'] : null);
    },
    async onDropOnFile (event, file) {
      await this.onDrop(event, file.parent);
    },
    async onDropOnRoot (event) {
      await this.onDrop(event);
    },
    async onDrop (event, targetFolderIri = null) {
      const objectToMoveIri = event.data['@id'];
      if (objectToMoveIri !== targetFolderIri) {
        event.data instanceof PatientDocument
          ? await PatientDocument.moveToFolder(objectToMoveIri, targetFolderIri)
          : await PatientDocumentFolder.moveToFolder(objectToMoveIri, targetFolderIri);

        await this.fetchDocumentsTree();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$file-preview-size: 32px;
.app-file-preview-item {
  max-width: $file-preview-size;
  height: $file-preview-size;
}

.root-drop-zone {
  height: 16px;
}

.item-drop-zone {
  margin: 2px;
}

.drop-in {
    background-color: #FFFFFFAA;
    outline: 2px dashed var(--v-secondary-lighten4) !important;
}
</style>