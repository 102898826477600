import Vue from 'vue';

import Medication from '@/modules/patient/models/Medication';
import { getFromAPI, postToAPI, putToAPI } from '@/services/api';

export const state = () => ({
  ongoingMedications: [],
  hasLoadedPatientOngoingMedications: {},
  isLoadingOngoingMedications: false,
});

export const getters = {
  getPatientOngoingMedications: (state) => patientUuid => (state.ongoingMedications[patientUuid] ? state.ongoingMedications[patientUuid] : []),
  getHasLoadedOngoingMedicationsByPatientUuid: state => patientUuid => state.hasLoadedPatientOngoingMedications[patientUuid],
  isLoadingOngoingMedications: state => state.isLoadingOngoingMedications,
};

export const mutations = {
  SET_ONGOING_MEDICATION (state, { patientUuid, medication }) {
    if (state.ongoingMedications[patientUuid] === undefined) {
      Vue.set(state.ongoingMedications, patientUuid, []);
    }
    state.ongoingMedications[patientUuid].push(medication);
  },
  UPDATE_ONGOING_MEDICATION (state, { patientUuid, medication }) {
    state.ongoingMedications[patientUuid].forEach(item => {
      if (item['@id'] === medication['@id']) {
        item.product = medication.product;
        item.endDate = medication.endDate;
        item.longTerm = medication.longTerm;
      }
    });
  },
  RESET_PATIENT_ONGOING_MEDICATIONS (state, { patientUuid }) {
    if (state.ongoingMedications[patientUuid] !== undefined) {
      state.ongoingMedications[patientUuid] = [];
    }
  },
  SET_IS_LOADING (state, visibility) {
    state.isLoadingOngoingMedications = visibility;
  },
  SET_HAS_LOADED_PATIENT_ONGOING_MEDICATIONS (state, { patientUuid, hasLoadedPatientOngoingMedications }) {
    Vue.set(state.hasLoadedPatientOngoingMedications, patientUuid, hasLoadedPatientOngoingMedications);
  },
};

export const actions = {
  async fetchOngoingMedications ({ state, commit }, patientUuid) {
    commit('SET_IS_LOADING', true);
    try {
      commit('RESET_PATIENT_ONGOING_MEDICATIONS', { patientUuid });
      const { data } = await getFromAPI(`/api/patients/${patientUuid}/ongoing_medications`);
      const ongoingMedications = data['hydra:member'];
      ongoingMedications.forEach((medication) => {
        commit('SET_ONGOING_MEDICATION', {
          patientUuid,
          medication: new Medication(medication),
        });
      });
    } catch (error) { /* empty */ }
    commit('SET_IS_LOADING', false);
    if (! state.hasLoadedPatientOngoingMedications[patientUuid]) {
      commit('SET_HAS_LOADED_PATIENT_ONGOING_MEDICATIONS', {
        patientUuid,
        hasLoadedPatientOngoingMedications: true,
      });
    }
  },
  async insertOngoingMedication ({ commit }, { patientUuid, medication }) {
    const { data } = await postToAPI('/api/external_medications', {
      patient: `/api/patients/${patientUuid}`,
      longTerm: medication.longTerm,
      product: new Medication(medication).product,
      endDate: new Medication(medication).endDate,
    });
    const newMedication = new Medication(data);
    newMedication.healthProductLine = data?.healthProductLine || data?.['@id'];
    newMedication.healthProductLine = newMedication.getIri();
    commit('SET_ONGOING_MEDICATION', {
      patientUuid,
      medication: newMedication,
    });
  },
  async updateOngoingMedication ({ commit }, { patientUuid, medication }) {
    await putToAPI(medication.healthProductLine, {
      data: {
        patient: `/api/patients/${patientUuid}`,
        longTerm: medication.longTerm,
        product: new Medication(medication).product,
        endDate: new Medication(medication).endDate,
      },
    });
    commit('UPDATE_ONGOING_MEDICATION', {
      patientUuid,
      medication: new Medication(medication),
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};