export const FORM_HEADINGS = Object.freeze({
  CONNECTION: {
    title: 'Connectez-vous',
    text: 'À votre compte',
  },
  FORGET_PASSWORD: {
    title: 'Mot de passe oublié',
    text: 'Veuillez saisir votre adresse email, un message vous sera envoyé afin que vous puissiez définir un nouveau mot de passe',
  },
  RESET_PASSWORD: {
    title: 'Réinitialisation de votre mot de passe',
    text: 'Respectez les critères suivants pour votre nouveau mot de passe',
  },
});