import EfficienceBackground from './efficience/background/efficience_background';
import EfficienceDmp from './efficience/dmp/efficience_dmp';
import efficience from './efficience/efficience_common';
import EfficienceManual from './efficience/manual/efficience_manual';

export default {
  efficience,
  EfficienceDmp,
  EfficienceManual,
  EfficienceBackground,
};