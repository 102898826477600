<template>
  <authentication-form-wrapper
    :title="FORM_HEADINGS.RESET_PASSWORD.title"
    :text="FORM_HEADINGS.RESET_PASSWORD.text"
    :form="ResetPasswordForm"
    submit-text="Réinitialiser le mot de passe"
  />
</template>

<script>
import { FORM_HEADINGS } from '@/modules/authentication/constants';

import AuthenticationFormWrapper from '@/modules/authentication/components/AuthenticationFormWrapper.vue';
import ResetPasswordForm from '@/modules/authentication/forms/ResetPasswordForm.vue';


export default {
  name: 'ResetPassword',
  components: { AuthenticationFormWrapper },
  data () {
    return {
      FORM_HEADINGS,
      ResetPasswordForm,
    };
  },
};
</script>