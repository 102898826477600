<template>
  <app-autocomplete
    v-model="selection"
    :label="label"
    :loading="isFetching"
    :items="keywords"
    item-text="label"
    :item-value="itemValue"
    :return-object="! itemValue"
    clearable
    :multiple="multiple"
  >
    <template #selection="{ item }">
      <app-tag
        v-test="'selected-keyword'"
        :color="item.color"
        small
        :closable="multiple"
        @click:close="removeKeyword(item)"
      >
        {{ item.label }}
      </app-tag>
    </template>
    <template #item="{ item }">
      <app-tag
        :color="item.color"
        small
      >
        {{ item.label }}
      </app-tag>
    </template>
  </app-autocomplete>
</template>

<script>
import PatientKeyword from '@/modules/patient/models/PatientKeyword';
import { getFromAPI } from '@/services/api';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';

/**
 * Permet de sélectionner un ou plusieurs mots clés
 * associés aux patients de l'organisation.
 */
export default {
  name: 'PatientKeywordSelector',
  components: {
    AppAutocomplete,
    AppTag,
  },
  props: {
    value: {
      type: [PatientKeyword, Array],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      keywords: [],
      isFetching: false,
    };
  },
  computed: {
    selection: {
      get () {
        return this.value;
      },
      set (selection) {
        this.$emit('input', selection);
      },
    },
    label () {
      return `Mot${this.multiple ? '(s)' : ''}-clef${this.multiple ? '(s)' : ''}`;
    },
  },
  created () {
    this.fetch();
  },
  methods: {
    removeKeyword (keywordToDelete) {
      if (this.itemValue) {
        this.selection = this.selection.filter(keywordValue => keywordValue !== keywordToDelete[this.itemValue]);
      } else {
        this.selection = this.selection.filter(keyword => keyword['@id'] !== keywordToDelete['@id']);
      }
    },
    async fetch () {
      this.isFetching = true;
      try {
        const { data } = await getFromAPI('/api/patient_keywords');
        this.keywords = data['hydra:member'].map(keyword => new PatientKeyword(keyword));
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>