import Vue from 'vue';


import Schedule from '@/modules/agenda/models/Schedule';
import FreeAppointmentSlot from '@/modules/patient/models/medicalHistory/FreeAppointmentSlot';
import { getFromAPI } from '@/services/api';

const state = () => ({
  schedules: {},
  visibleScheduleIDs: [],
});

const getters = {
  getSchedules: state => Object.values(state.schedules),
  getVisibleSchedules: state => Object.values(state.schedules).filter(schedule => state.visibleScheduleIDs.includes(schedule['@id'])),
  getScheduleNameFromId: state => scheduleId => state.schedules?.[scheduleId]?.name,
  getScheduleFromIri: state => scheduleIri => state.schedules?.[scheduleIri],
};

const mutations = {
  SET_SCHEDULE (state, schedule) {
    Vue.set(state.schedules, schedule['@id'], new Schedule(schedule));
  },
  SET_SCHEDULE_VISIBILITY (state, { schedule, visible }) {
    if (visible) {
      if (! state.visibleScheduleIDs.includes(schedule['@id'])) {
        state.visibleScheduleIDs.push(schedule['@id']);
      }
    } else {
      const scheduleIDIndex = state.visibleScheduleIDs.indexOf(schedule['@id']);

      // Évite d'éffectuer un splice avec un index négatif
      // Provoquant le masquage d'agendas précédemment cochés
      // et l'affichage d'agendas précédemment décochés
      if (scheduleIDIndex >= 0) {
        state.visibleScheduleIDs.splice(scheduleIDIndex, 1);
      }
    }
  },
};

const actions = {
  async fetchSchedules ({ commit, state }) {
    const response = await getFromAPI('/api/practitioner_schedules');
    const isSchedulesAlreadyFetched = state.visibleScheduleIDs.length > 0;
    response.data['hydra:member'].forEach(schedule => {
      commit('SET_SCHEDULE', schedule);
      commit('SET_SCHEDULE_VISIBILITY', {
        schedule,
        visible: isSchedulesAlreadyFetched ? state.visibleScheduleIDs.includes(schedule['@id']) : true,
      });
    });
  },
  async fetchOneSchedule ({ commit }, iri) {
    const { data } = await getFromAPI(iri);
    commit('SET_SCHEDULE', data);
  },
  async fetchEvents ({ dispatch }, schedule) {
    dispatch('fetchAppointments', { schedule });
    dispatch('fetchAvailabilities', { schedule });
    dispatch('fetchAbsences', { schedule });
  },
  async fetchScheduleFreeAppointmentSlots (store, { schedule, motive, startDate }) {
    const { data } = await getFromAPI('/api/free_appointment_slots', {
      schedule,
      motive,
      startDate,
    });
    return data['hydra:member'].map(freeAppointmentSlot => new FreeAppointmentSlot(freeAppointmentSlot));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};