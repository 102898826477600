import { uniqBy } from 'lodash';

import ApiEntity from '@/models/ApiEntity';
import { EMPTY_MESSAGE_FIELD_TEXTS } from '@/modules/secureMessaging/modules/messaging/constants';
import MssAddress from '@/modules/secureMessaging/modules/messaging/models/MssAddress';
import MssMessageAttachment from '@/modules/secureMessaging/modules/messaging/models/MssMessageAttachment';
import { format } from '@/utils/functions/dates';

export default class MssMessage extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.attachments = options.attachments?.map(attachment => new MssMessageAttachment(attachment)) || [];
    this.bodyFragment = options.bodyFragment || null;
    this.transferredMessage = options.transferredMessage || null;
    this.body = options.body || null;
    this.receptionDateTime = options.receptionDateTime || null;
    this.senderName = options.senderName || null;
    this.senderEmail = options.senderEmail || null;
    this.recipients = options.recipients ? options.recipients.map(address => new MssAddress(address)) : [];
    this.cc = options.cc ? options.cc.map(address => new MssAddress(address)) : [];
    this.bcc = options.bcc ? options.bcc.map(address => new MssAddress(address)) : [];
    this.subject = options.subject || null;
    this.unread = options.unread || false;
  }

  /**
   * Retourne la date de réception en fonction de la date courante
   * @returns {String}
   */
  getFormattedReceptionDate () {
    const sameDay = new Date(this.receptionDateTime).getDate() === new Date().getDate();
    const sameMonth = new Date(this.receptionDateTime).getMonth() === new Date().getMonth();
    const sameYear = new Date(this.receptionDateTime).getFullYear() === new Date().getFullYear();

    if (! sameYear || ! sameMonth) {
      return format(this.receptionDateTime, 'dd/MM/yyyy');
    }
    if (sameMonth && ! sameDay) {
      return format(this.receptionDateTime, 'd MMM');
    }
    return format(this.receptionDateTime, 'HH:mm');
  }

  /**
   * Retourne l'objet du message avec une valeur spécifique si celui-ci est null
   * @returns {String}
   */
  getFormattedSubject () {
    return this.subject || EMPTY_MESSAGE_FIELD_TEXTS.SUBJECT;
  }

  /**
   * Retourne un texte formatée de la liste des destinataires séparés par des virgules avec leurs adresse mail minifiée
   * @returns {String}
   */
  getFormattedRecipients () {
    const allRecipients = uniqBy([
      ...this.recipients,
      ...this.cc,
      ...this.bcc,
    ], 'email').map(address => address.email.split('@').at(0));
    return `À: ${allRecipients.join(', ')}`;
  }
}