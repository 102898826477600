<template>
  <fieldset class="app-period-field">
    <div class="app-period-field__icon">
      <app-icon icon="schedule-day" />
    </div>
    <div class="app-period-field__fields">
      <app-date-picker
        v-test="'from-date'"
        :value="from"
        :icon="null"
        :max="maxFromDate"
        label="À partir du"
        clearable
        @input="date => $emit('update:from', date)"
      />
      <template v-if="showToField">
        <app-date-picker
          v-test="'to-date'"
          :value="to"
          :icon="null"
          :min="new Date(from)"
          :max="new Date()"
          label="Jusqu'au"
          clearable
          @input="date => $emit('update:to', date)"
        />
      </template>
    </div>
  </fieldset>
</template>

<script>
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';

export default {
  name: 'AppPeriodField',
  components: { AppDatePicker },
  props: {
    from: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    /**
     * Masque le champ "jusqu'au" tant qu'une date de début n'a pas été sélectionnée
     */
    needsStartDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maxFromDate () {
      return this.to
        ? new Date(this.to)
        : new Date();
    },
    showToField () {
      if (this.needsStartDate) {
        return !! this.from;
      }
      return true;
    },
  },
  watch: {
    showToField (showToField) {
      if (! showToField && !! this.to) {
        this.$emit('update:to', null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-period-field {
  display: flex;
  gap: map-get($spacers, 3);
  margin: 0;

  &__icon {
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: map-get($spacers, 3);
  }

  &__fields {
    display: grid;
    gap: map-get($spacers, 2);
    flex-grow: 1;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
</style>