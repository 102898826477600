<template>
  <div
    class="drag-event"
    :style="{ height, top: posY + 'px' }"
  >
    <span
      class="drag-event__time"
      data-test="drag-event-times"
    >{{ startTime }} - {{ endTime }}</span>
    <v-icon
      size="12"
      class="drag-event__grip"
    >
      fas fa-grip-lines
    </v-icon>
  </div>
</template>

<script>
import { add, format } from '@/utils/functions/dates';

export default {
  name: 'CalendarDragEvent',
  props: {
    date: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    endTime () {
      return format(add(new Date(`${this.date}T${this.startTime}`), { minutes: this.duration }), 'HH:mm');
    },
    posY () {
      const startTimeArray = this.startTime.split(':');
      return this.$parent.timeToY({
        hour: parseInt(startTimeArray[0], 10),
        minute: parseInt(startTimeArray[1], 10),
      });
    },
    height () {
      return `${this.$parent.minutesToPixels(this.duration)}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-event {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--v-blue-grey-lighten1);
  border-radius: 3px;
  padding: 4px 8px 12px 8px;
  overflow: hidden;
  z-index: 50;
  min-height: 35px;
  cursor: row-resize;

  &__time {
    font-size: 12px;
    user-select: none;
  }

  &__grip {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>