<template>
  <fieldset class="patient-antecedent-fieldset">
    <app-panel
      :title="localAntecedent.label || 'Nouvel antécédent'"
      is-outlined
      is-flat
      header-divided
      dense
      :actions="antecedentActions"
      :max-actions-visible="2"
    >
      <app-row class="patient-antecedent-fieldset__common">
        <app-col v-if="!localAntecedent.label" cols="12">
          <pathology-autocomplete-wrapper
            ref="pathology-autocomplete-wrapper"
            v-model="localAntecedent"
            v-test="'antecedent-content-field'"
          />
        </app-col>
      </app-row>
      <app-row class="patient-antecedent-fieldset__common" :class="!localAntecedent.label ? 'mt-0': ''">
        <app-col cols="12" md="6">
          <app-select
            v-model="type"
            v-test="'antecedent-type-field'"
            :items="availableAntecedentTypes"
            label="Type"
            hide-errors="auto"
          />
        </app-col>
        <app-col v-if="isFamilyAntecedent" cols="12" md="6">
          <patient-antecedent-family-relation
            v-model="familyRelationship"
            :rules="!!localAntecedent ? 'required' : null"
            :index="index"
          />
        </app-col>
      </app-row>
      <app-row class="patient-antecedent-fieldset__common mt-0">
        <app-col cols="12">
          <app-textarea-field
            v-model="note"
            v-test="'pathology-note'"
            label="Commentaire (optionnel)"
            class="mt-0"
            hide-errors="auto"
            rows="1"
            auto-grow
          />
        </app-col>
      </app-row>
    </app-panel>
  </fieldset>
</template>

<script>
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import { ANTECEDENT_TYPES } from '@/modules/patient/constants';
import PatientAntecedent from '@/modules/patient/models/healthEntity/PatientAntecedent';
import PatientFamilyAntecedent from '@/modules/patient/models/healthEntity/PatientFamilyAntecedent';

import PathologyAutocompleteWrapper from '@/components/lap/PathologyAutocompleteWrapper.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import PatientAntecedentFamilyRelation from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentFamilyRelation.vue';

export default {
  name: 'PatientAntecedentFieldset',
  components: {
    AppTextareaField,
    AppSelect,
    AppPanel,
    PatientAntecedentFamilyRelation,
    PathologyAutocompleteWrapper,
  },
  props: {
    antecedent: {
      type: PatientAntecedent,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      type: null,
      familyRelationship: null,
      localAntecedent: new PatientAntecedent(),
      note: null,
    };
  },
  computed: {
    availableAntecedentTypes () {
      return Object.values(ANTECEDENT_TYPES).filter(type => type.value !== ANTECEDENT_TYPES.NOT_CODIFIED.value);
    },
    isFamilyAntecedent () {
      return this.type === ANTECEDENT_TYPES.FAMILY.value;
    },
    antecedentActions () {
      const actions = [];
      if (this.localAntecedent.label) {
        actions.push(new ActionMenuItem('edit', 'Modifier', () => {
          this.localAntecedent = null;
        }));
      }
      if (this.length > 1) {
        actions.push(new ActionMenuItem('delete', 'Supprimer', () => this.$emit('remove-antecedent', this.index)));
      }
      return actions;
    },
  },
  watch: {
    antecedent: {
      immediate: true,
      handler (antecedent) {
        this.type = antecedent.type;
        this.familyRelationship = antecedent.familyRelationship;
        this.localAntecedent = this.localAntecedent ?? new PatientAntecedent();
        this.localAntecedent.icd10Code = antecedent.icd10Code;
        this.localAntecedent.label = antecedent.label;
        this.localAntecedent.theriaqueLink = antecedent.theriaqueLink;
        this.note = antecedent.note;
      },
    },
    type () {
      this.onFieldChange();
    },
    familyRelationship () {
      this.onFieldChange();
    },
    'localAntecedent.icd10Code': function handleIcd10CodeChange () {
      this.onFieldChange();
    },
    note () {
      this.onFieldChange();
    },
  },
  methods: {
    onFieldChange () {
      this.$emit('update:antecedent', this.getSerializedAntecedent());
    },
    getSerializedAntecedent () {
      const Model = this.isFamilyAntecedent ? PatientFamilyAntecedent : PatientAntecedent;
      return new Model({
        ...this.antecedent,
        icd10Code: this.localAntecedent?.icd10Code,
        label: this.localAntecedent?.label,
        theriaqueLink: this.localAntecedent?.theriaqueLink,
        type: this.type,
        familyRelationship: this.familyRelationship,
        patient: this.patientIri,
        note: this.note,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-antecedent-fieldset {
  &:not(:last-child) {
    padding-bottom: map-get($spacers, 4);
  }

  &__common {
    align-items: end;
  }
}

::v-deep {
  .panel__title {
    white-space: normal !important;
  }
  .synapse-autocomplete-wrapper__field__input {
    .options {
      width: 281px !important;
    }
  }
  .synapse-autocomplete-wrapper__field__input--mobile {
    .options {
      width: 80% !important;
    }
  }
}
</style>