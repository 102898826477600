<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validator"
    slim
    :rules="normalizedRules"
    :name="label.toLowerCase()"
    :vid="name"
    mode="lazy"
  >
    <v-text-field
      ref="input"
      v-model="localDate"
      v-mask="'##/##/####'"
      v-test="'date-field'"
      :label="fieldLabel"
      :hint="hideHint ? null : 'jj/mm/aaaa'"
      :placeholder="hideDetails || hideHint ? 'jj/mm/aaaa' : null"
      :error-messages="errors"
      :hide-details="hideDetails"
      :disabled="disabled"
      :clearable="clearable"
      @input="handleInput"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider, validate, normalizeRules } from 'vee-validate';

import fieldMixin from '@/mixins/fields';

const DATE_STRING_LENGTH = 10;

/**
 * Un champ de texte permettant de saisir une date
 */
export default {
  name: 'AppDateField',
  components: { ValidationProvider },
  mixins: [fieldMixin],
  props: {
    allowLunarDate: {
      type: Boolean,
      default: false,
    },
    hideHint: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { localDate: this.value };
  },
  computed: {
    normalizedRules () {
      return {
        ...normalizeRules({ date_format: { allowLunarDate: this.allowLunarDate } }),
        ...normalizeRules(this.rules),
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (date) {
        if (date) {
          const [years, months, days] = date.split('-');
          this.localDate = `${days}/${months}/${years}`;
        } else {
          this.localDate = '';
        }
      },
    },
    localDate (localDate) {
      if (localDate && localDate.length === DATE_STRING_LENGTH) {
        this.validateField(localDate);
        return;
      }
      // Désactive l'observer en validant les règles du champ sans place le champ en erreur
      this.$refs.validator?.setFlags({ valid: false });
    },
  },
  methods: {
    async handleInput (date) {
      await this.$nextTick();
      if (! date || ! date?.length) {
        this.$emit('input', null);
        return;
      }
      if (date.length === DATE_STRING_LENGTH) {
        this.onCompleteDateInput(date);
      }
    },
    async onCompleteDateInput (date) {
      const { valid } = await this.validateField(date);
      if (valid) {
        const [days, months, years] = date.split('/');
        this.$emit('input', `${years}-${months}-${days}`);
      }
    },
    /**
     * Retourne le résultats de l'ensemble des règles de validations
     * Y compris celles nécessaires à la validation de la date
     * @param { String } date la date au format jj/mm/aaaa
     * @returns { { errors: Array, valid: Boolean } } la liste des messages d'erreur et l'état de validité du champ
     */
    async getRulesValidation (date) {
      const validationResult = await validate(date, this.normalizedRules);
      return {
        errors: validationResult.errors,
        valid: validationResult.valid,
      };
    },
    async validateField (date) {
      const result = await this.getRulesValidation(date);
      this.$refs.validator?.applyResult(result);
      return result;
    },
  },
};
</script>