<template>
  <div class="app-replacement-content-item">
    <div class="subtitle-3 mb-1">
      <slot
        name="title"
        :label="item.label"
      >
        {{ item.label }}
      </slot>
    </div>
    <div class="app-replacement-content-item__transformation">
      <span class="secondary--text">{{ item.getValueLabel().before }}</span>
      <app-icon
        icon="right"
        color="secondary lighten-4"
        :size="14"
        class="mx-2"
      />
      <span>{{ item.getValueLabel().after }}</span>
    </div>
  </div>
</template>

<script>
import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem';

import AppIcon from '@/components/ui/icon/AppIcon.vue';


export default {
  name: 'AppRemplacementContentItem',
  components: { AppIcon },
  props: {
    item: {
      type: ReplacementItem,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-replacement-content-item {
  &__transformation {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--v-content-base)
  }
}
</style>