/*  eslint max-len:0 */
/**
 * Conversion JSON de https://mos.esante.gouv.fr/NOS/TRE_A05-TypeDocComplementaire/TRE_A05-TypeDocComplementaire.xml
 * Version normalisée, simplifiée et indexée par code
 */

/* eslint-disable quote-props */
export default {
  'ATTEST-ASS-COMPL': {
    code: 'ATTEST-ASS-COMPL',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Attestation assurance complémentaire',
  },
  'ATTEST-HEBGT': {
    code: 'ATTEST-HEBGT',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Attestation d\'hébergement',
  },
  'ATTEST-RESID': {
    code: 'ATTEST-RESID',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Attestation de résidence',
  },
  'ATTEST-VITALE': {
    code: 'ATTEST-VITALE',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Attestation de carte vitale',
  },
  'BIL_AUTO': {
    code: 'BIL_AUTO',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'CR de bilan d\'évaluation de la perte d\'autonomie',
    descriptionMetier: 'Document réalisé par un médecin ou par certains auxiliaires médicaux décrivant la perte d\'autonomie dans la vie quotidienne d\'un patient, suivant différents axes.',
  },
  'CERT_DECL': {
    code: 'CERT_DECL',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Certificat, déclaration',
    descriptionMetier: 'Certificat : Document médical justificatif attestant de J48 de santé d\'un patient et remis à celui-ci, à sa demande, par un médecin dans son domaine de compétence. Déclaration : Document à visée épidémiologique ou de prévention, établi à l\'intention de l\'autorité sanitaire et rédigé par tout médecin ayant fait le diagnostic d\'une maladie à déclaration obligatoire listée au Code de la Santé Publique.',
  },
  'CONTACTS-URGENCE': {
    code: 'CONTACTS-URGENCE',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Fiche contacts d\'urgence',
  },
  'DISP_AUT': {
    code: 'DISP_AUT',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Dispensation (autre)',
    descriptionMetier: 'Document listant les produits, autres que des médicaments, ou les dispositifs dispensés au patient.',
  },
  'EXPPAT_1': {
    code: 'EXPPAT_1',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Volontés et droits du patient',
    descriptionMetier: 'Personnes à prévenir en cas d\'urgence, personne de confiance, directives anticipées, don d\'organe, don du corps, accès au dossier du patient par ses ayants droit.',
  },
  'EXPPAT_2': {
    code: 'EXPPAT_2',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Autre document du patient',
    descriptionMetier: 'Informations personnelles saisies dans un éditeur de texte',
  },
  'EXPPAT_3': {
    code: 'EXPPAT_3',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Directives anticipées',
  },
  'IMG-KOS': {
    code: 'IMG-KOS',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Reference d\'objets d\'un examen d\'imagerie',
  },
  'INSCR_RCP': {
    code: 'INSCR_RCP',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '20171013120000',
    displayName: 'Données d\'inscription en RCP',
  },
  'PROT_ALD': {
    code: 'PROT_ALD',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Protocole de soins ALD',
    descriptionMetier: 'Document établi par le médecin traitant pour une demande de prise en charge à 100 % concernant les soins et les traitements d\'un patient, liés à une affection de longue durée (A.L.D.) exonérante. Le caractère exonérant nécessite un suivi de la maladie et des soins prolongés de plus de six mois et des traitements coûteux.',
  },
  'REMB': {
    code: 'REMB',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Données de remboursement',
  },
  'SYNTH': {
    code: 'SYNTH',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Synthèse',
    descriptionMetier: 'Document réalisé par un professionnel de santé, résumant et mettant en évidence les éléments essentiels de l\'état de santé et de la prise en charge médicale d\'un patient, à un instant donné. Il peut s\'agir d\'une synthèse globale ou d\'une synthèse centrée sur une pathologie.',
  },
  'DOCPAT01': {
    code: 'DOCPAT01',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Synthèse déposée par le patient',
  },
  'DOCPAT02': {
    code: 'DOCPAT02',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Traitement ou document de soins déposé par le patient',
  },
  'DOCPAT03': {
    code: 'DOCPAT03',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'CR déposé par le patient',
  },
  'DOCPAT04': {
    code: 'DOCPAT04',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Imagerie déposée par le patient',
  },
  'DOCPAT05': {
    code: 'DOCPAT05',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'CR de biologie déposé par le patient',
  },
  'DOCPAT06': {
    code: 'DOCPAT06',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'CR de prévention déposé par le patient',
  },
  'DOCPAT07': {
    code: 'DOCPAT07',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Certificat déposé par le patient',
  },
  'DOCPAT08': {
    code: 'DOCPAT08',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Profil médical Mon espace santé',
  },
  'DOCPAT09': {
    code: 'DOCPAT09',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Document médico-administratif',
  },
  'DOCPAT10': {
    code: 'DOCPAT10',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Autorisation de soins et actes non usuels sanitaires',
  },
  'PRESC-BIO': {
    code: 'PRESC-BIO',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes de biologie médicale',
    descriptionMetier: 'Prescription d\'actes de biologie médicale',
  },
  'PRESC-KINE': {
    code: 'PRESC-KINE',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes de kinésithérapie',
    descriptionMetier: 'Prescription d\'actes de kinésithérapie',
  },
  'PRESC-INF': {
    code: 'PRESC-INF',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes infirmiers',
    descriptionMetier: 'Prescription d\'actes infirmiers',
  },
  'PRESC-PEDI': {
    code: 'PRESC-PEDI',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes de pédicurie',
    descriptionMetier: 'Prescription d\'actes de pédicurie',
  },
  'PRESC-ORTHOPHO': {
    code: 'PRESC-ORTHOPHO',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes d\'orthophonie',
    descriptionMetier: 'Prescription d\'actes d\'orthophonie',
  },
  'PRESC-ORTHOPTIE': {
    code: 'PRESC-ORTHOPTIE',
    codeSystem: '1.2.250.1.213.1.1.4.12',
    dateFin: '',
    displayName: 'Prescription d\'actes d\'orthoptie',
    descriptionMetier: 'Prescription d\'actes d\'orthoptie',
  },
};