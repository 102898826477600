import Vue from 'vue';

import Absence from '@/modules/agenda/models/events/Absence';
import AbsenceSettings from '@/modules/agenda/models/events/AbsenceSettings';
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '@/services/api';
import { format, startOfWeek, endOfWeek } from '@/utils/functions/dates';


const state = () => ({
  absencesSettings: {},
  absences: {},
});

const getters = {
  getAbsences: state => Object.values(state.absences),
  getVisibleAbsences: state => Object.values(state.absences).filter(absence => state.visibleScheduleIDs.includes(absence.setting.schedule['@id'])),
  getAbsenceSettingByUuid: state => uuid => state.absencesSettings[`/api/absence_settings/${uuid}`],
};

const mutations = {
  SET_ABSENCE (state, absence) {
    Vue.set(state.absences, absence['@id'], new Absence(absence));
  },
  SET_ABSENCE_SETTINGS (state, absenceSettings) {
    Vue.set(state.absencesSettings, absenceSettings['@id'], new AbsenceSettings(absenceSettings));
  },
  DELETE_ABSENCE (state, absence) {
    Vue.delete(state.absences, absence['@id']);
  },
};

const actions = {
  async fetchAbsences ({ commit, state }, { fromDate = startOfWeek(state.currentDate),
    toDate = endOfWeek(state.currentDate),
    schedule = state.visibleScheduleIDs } = {}) {
    const { data } = await getFromAPI('/api/absences', {
      'date[after]': format(fromDate, 'yyyy-MM-dd'),
      'date[before]': format(toDate, 'yyyy-MM-dd'),
      schedule,
    });
    data['hydra:member'].forEach(absence => {
      commit('SET_ABSENCE', absence);
      commit('SET_ABSENCE_SETTINGS', absence.setting);
    });
  },
  async fetchOneAbsenceSetting ({ commit }, iri) {
    const { data } = await getFromAPI(`/api/absence_settings/${iri}`);
    commit('SET_ABSENCE_SETTINGS', data);
  },
  async insertAbsenceSetting ({ dispatch, commit }, absenceSetting) {
    const { data } = await postToAPI('/api/absence_settings', {
      ...absenceSetting,
      schedule: absenceSetting.schedule['@id'],
    });
    commit('SET_ABSENCE_SETTINGS', data);
    await dispatch('fetchAbsences', { schedule: absenceSetting.schedule['@id'] });
  },
  async updateAbsenceSetting ({ getters, commit, dispatch }, absenceSetting) {
    const scheduleIRI = absenceSetting.schedule['@id'];
    const { data } = await putToAPI(`${absenceSetting['@id']}`, {
      data: {
        ...absenceSetting,
        schedule: scheduleIRI,
      },
    });
    commit('SET_ABSENCE_SETTINGS', data);
    await dispatch('clearEventsFromSettingsIri', {
      events: getters.getAbsences,
      settingIri: absenceSetting['@id'],
      commitName: 'DELETE_ABSENCE',
    });
    await dispatch('fetchAbsences', { schedule: [scheduleIRI] });
  },
  async deleteAbsence ({ dispatch, getters }, absenceSettingIri) {
    await deleteFromAPI(absenceSettingIri);
    dispatch('clearEventsFromSettingsIri', {
      events: getters.getAbsences,
      settingIri: absenceSettingIri,
      commitName: 'DELETE_ABSENCE',
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};