<template>
  <app-form
    class="forget-password-form"
    :submit-method="sendResetEmail"
  >
    <app-text-field
      v-model="username"
      v-test="'email-field'"
      type="email"
      label="Email"
      rules="email|required"
      class="mb-4"
    />
    <app-link
      v-test="'login-form-link'"
      class="forget-password-form__link"
      :to="{ name: ROUTE_NAMES.CONNECTION }"
    >
      Retour au formulaire de connexion
    </app-link>
  </app-form>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/authentication/constants';
import { postToAPI } from '@/services/api';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';


export default {
  name: 'ForgetPasswordForm',
  components: {
    AppTextField,
    AppForm,
  },
  data () {
    return {
      ROUTE_NAMES,
      username: null,
    };
  },
  methods: {
    sendResetEmail () {
      return postToAPI('/users/password/reset-link-email', { username: this.username });
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-password-form {
  &__link {
    display: flex;
    justify-content: center;
  }
}
</style>