<template>
  <document-analytic-widget-layout class="document-analytics-widget-skeleton">
    <template #title>
      <app-skeleton-loader
        type="text"
        :width="120"
      />
    </template>
    <template #total-count>
      <app-skeleton-loader
        type="text"
        :width="40"
      />
      <app-skeleton-loader
        type="image"
        class="document-analytics-widget-skeleton__number-item"
      />
    </template>
    <template #qualified-ins-count>
      <app-skeleton-loader
        type="text"
        :width="120"
      />
    </template>
    <template #unqualified-ins-count>
      <app-skeleton-loader
        type="text"
        :width="80"
      />
    </template>
    <template #failed-count>
      <app-skeleton-loader
        type="text"
        :width="40"
      />
      <app-skeleton-loader
        type="image"
        class="document-analytics-widget-skeleton__number-item"
      />
    </template>
  </document-analytic-widget-layout>
</template>

<script>
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import DocumentAnalyticWidgetLayout from '@/modules/users/components/dashboard/analytics/widgets/DocumentAnalyticWidgetLayout.vue';

export default {
  name: 'DocumentAnalyticWidgetSkeleton',
  components: {
    DocumentAnalyticWidgetLayout,
    AppSkeletonLoader,
  },
};
</script>

<style lang="scss" scoped>
.document-analytics-widget-skeleton {
  &__number-item {
    height: 45px;
    width: 40px !important;
  }
}
</style>