<template>
  <app-page-layout
    sidebar-title="Filtres"
    mobile-fab-icon="filters"
    class="list-layout"
  >
    <template #sidebar>
      <component
        :is="listFilterComponent"
        v-if="listFilterComponent"
        ref="filteringComponent"
        v-test="'list-layout-filters'"
        v-bind="filterProps"
        v-on="getComponentEvents('filters')"
        @change:filtering="updateGlobalFilters"
      />
    </template>
    <infinite-scrolling-wrapper
      ref="listComponent"
      v-test="'list-layout-listing'"
      :item-per-page="itemPerPage"
      :api-resource-url="apiResourceUrl"
      :filters="sortedActiveFilters"
      :result-converter="resultConverter"
      :item-model-class="itemModelClass"
    >
      <template #default="{ items, hasInitializedContent, count }">
        <component
          :is="listHeaderComponent"
          v-if="listHeaderComponent"
          ref="headerComponent"
          v-test="'list-layout-header'"
          class="mb-6 list-layout__header"
          :is-loading="!hasInitializedContent"
          :count="count"
          :filters="sortedActiveFilters"
          v-bind="headerProps"
          v-on="getComponentEvents('header')"
          @clear-filter="clearFilter"
          @update:filters="updateListHeaderFilters"
        />
        <n-list
          :items="items"
          :loading="!hasInitializedContent"
        >
          <template #item="{ item }">
            <app-card-list-item
              v-test="'list-layout-items'"
              v-on="getComponentEvents('item', item)"
            >
              <component
                :is="listItemComponent"
                v-bind="{ [itemName]: item }"
                @refresh="refresh"
              />
            </app-card-list-item>
          </template>
          <template #skeleton>
            <app-card-list-item>
              <component :is="listSkeletonComponent" />
            </app-card-list-item>
          </template>
        </n-list>
      </template>
      <template #loading-component>
        <app-card-list-item class="mt-2">
          <component
            :is="listSkeletonComponent"
            v-if="listSkeletonComponent"
          />
          <app-skeleton-loader
            v-else
            type="card"
            :height="defaultSkeletonHeight"
          />
        </app-card-list-item>
      </template>
    </infinite-scrolling-wrapper>
  </app-page-layout>
</template>

<script>
import { isNull } from 'lodash';

import InfiniteScrollingWrapper from '@/components/ui/infiniteScrollingWrapper/InfiniteScrollingWrapper.vue';
import AppCardListItem from '@/components/ui/listing/cardList/AppCardListItem.vue';
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import AppPageLayout from '@/layout/AppPageLayout.vue';


const DEFAULT_ITEM_PER_PAGE = 15;
const DEFAULT_SKELETON_HEIGHT = 80;

/**
 * Composant de mise en page permettant le listing d'items paginés
 * automatisant le chargement de la ressource associée et son lazy-loading
 * au travers d'une url, de l'usage de squelettes, et d'un filtrage.
 */
export default {
  name: 'ListLayout',
  components: {
    InfiniteScrollingWrapper,
    AppSkeletonLoader,
    AppPageLayout,
    AppCardListItem,
  },
  props: {
    /**
     * Composant utilisé pour filtrer le contenu de la liste
     * Présenté dans la colonne de gauche du Grid Layout
     */
    listFilterComponent: {
      type: Object,
      default: null,
    },
    /**
     * Composant utilisé pour l'entête de la liste
     * Présenté en haut de la colonne principale, au dessus du contenu
     */
    listHeaderComponent: {
      type: Object,
      default: null,
    },
    /**
     * Composant à utiliser pour présenter un élément du listing
     */
    listItemComponent: {
      type: Object,
      required: true,
    },
    /**
     * Composant à utiliser pour la pagination infinie
     * Et pour le listing des squelettes au chargement initial
     */
    listSkeletonComponent: {
      type: Object,
      default: null,
    },
    /**
     * Nombre d'élément par pagination
     */
    itemPerPage: {
      type: Number,
      default: DEFAULT_ITEM_PER_PAGE,
    },
    /**
     * Url de l'API easy-care à récupérer et paginer
     */
    apiResourceUrl: {
      type: String,
      required: true,
    },
    /**
     * Adaptateur permetant de transformer un résultat récupéré depuis l'API easy-care
     */
    resultConverter: {
      type: Function,
      default: null,
    },
    /**
     * Classe du modèle servant à instancier le résultat
     */
    itemModelClass: {
      type: Function,
      default: null,
    },
    /**
     * Nom de la props à utiliser pour transmettre l'item
     */
    itemName: {
      type: String,
      default: 'item',
    },
    /**
     * Props à transmettre au composant "listHeaderComponent"
     */
    headerProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Props à transmettre au composant "listFilterComponent"
     */
    filterProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      defaultSkeletonHeight: DEFAULT_SKELETON_HEIGHT,
      filters: [],
      listHeaderFilters: [],
    };
  },
  computed: {
    /**
     * Ne garde que les tags des filtres actifs
     * Les met dans l'ordre d'utilisation (le dernier utilisé sera toujours en dernier)
     * Et place les filtres liés via l'utilisation de chainWith, à droite du filtre associé
     */
    sortedActiveFilters () {
      // On les organisent par ordre d'usage
      const sortedFilters = [...this.filters, ...this.listHeaderFilters]
        .filter(filter => ! isNull(filter.value))
        .sort((filterA, filterB) => filterA.getLastUsageDateTime() - filterB.getLastUsageDateTime());

      // On chaine les filtres liés
      const linkedFilters = sortedFilters.filter(filter => filter.chainWith);
      linkedFilters.forEach(filter => {
        const filterIndex = sortedFilters.indexOf(filter);
        let targetIndex = sortedFilters.findIndex(_filter => _filter.name === filter.chainWith);

        // On compense la position selon la direction du splice à venir
        if (targetIndex <= filterIndex) {
          targetIndex += 1;
        }

        // On déplace l'élément
        if (targetIndex > - 1) {
          sortedFilters.splice(filterIndex, 1);
          sortedFilters.splice(targetIndex, 0, filter);
        }
      });

      return sortedFilters;
    },
  },
  methods: {
    /*
     * Permet de récupérer les évènements associés aux 3 namespaces du ListLayout: 'header', 'filters' et 'item'.
     * @param {String} namespace
     * @param {String} payload Le jeu de donnée à fournir systématiquement au namespace
     * @example
     * <list-layout
     *   @header:event-name="onEventName" // Sera lancée lorsque listHeaderComponent aura émit un event 'event-name'
     * />
     */
    getComponentEvents (namespace, payload) {
      return Object.keys(this.$listeners)
        .reduce((events, listenerName) => {
          const namespacePrefix = `${namespace}:`;
          if (listenerName.startsWith(namespacePrefix)) {
            const originalEventName = listenerName.substring(namespacePrefix.length, listenerName.length);
            events[originalEventName] = () => this.$listeners[listenerName](payload);
          }
          return events;
        }, {});
    },
    clearFilter (filter) {
      this.$refs.filteringComponent.clearFilter(filter);
    },
    updateListHeaderFilters (filters) {
      this.listHeaderFilters = filters;
    },
    updateGlobalFilters (filters) {
      this.filters = filters;
    },
    clearFilters () {
      this.$nextTick(() => {
        this.$refs.filteringComponent?.clearFilters?.();
      });
    },
    refresh () {
      this.$refs.headerComponent?.refresh?.();
      this.$refs.listComponent?.refresh?.();
    },
  },
};
</script>

<style lang="scss">
.list-layout {
  &__header {
    width: 100%;
  }
}
</style>