<template>
  <div class="patient-identity-qualification-button">
    <v-menu
      v-model="insDropdownActive"
      content-class="patient-identity-qualification-button__menu"
      min-width="200"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <app-button
          color="primary"
          small
          :loading="loading"
          :outlined="outlined"
          v-bind="attrs"
          v-on="on"
        >
          Obtenir l'INS
          <app-icon
            icon="chevron-down"
            class="ml-2"
            size="13"
          />
        </app-button>
      </template>
      <v-list
        tag="main"
        class="py-1"
      >
        <v-list-item
          v-test="'query-ins-button-cv'"
          :disabled="! getHasVitaleCard"
          class="my-1"
          @click="openIcanopeeModal(INS_FETCH_METHOD.CV)"
        >
          <app-icon
            icon="ins"
            :color="getHasVitaleCard ? 'content' : 'secondary lighten-4'"
          />
          <div>
            <v-list-item-title>Carte vitale</v-list-item-title>
            <v-list-item-subtitle v-if="! getHasVitaleCard">
              Aucune carte insérée
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-list-item
          v-test="'query-ins-button-trait'"
          :disabled="! getHasCpxCard"
          class="my-1"
          @click="openIcanopeeModal(INS_FETCH_METHOD.TRAIT)"
        >
          <app-icon
            icon="user"
            :color="getHasCpxCard ? 'content' : 'secondary lighten-4'"
          />
          <div class="ml-1">
            <v-list-item-title>Par traits d'identité</v-list-item-title>
            <v-list-item-subtitle v-if="! getHasCpxCard">
              Aucune carte CPx insérée
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
    <i-canopee-modal
      :patient="patient"
      :call-type="insCallType"
      :is-open.sync="iCanopeeModalVisible"
      :is-partner-access="isPartnerAccess"
      @error="onInsFetchError"
      @success="onInsFetchSuccess"
      @fetch-result="onInsFetchResult"
    />
    <n-dialog
      v-test="'ins-validation-modal'"
      title="Appliquer les modifications"
      :actions="[new ButtonAction('Annuler', handleCancel), new ButtonAction('Appliquer', applyInsiDifferences, 'primary')]"
      :is-open="!! insiResultDifferences"
      persistent
      divided
    >
      <app-replacement-content-list
        v-test="'ins-replacement-content'"
        :replacements="insiResult.replacements"
        readonly
      >
        <template #prepend>
          <p>Certaines données déjà renseignées vont être mises à jour.</p>
          <div class="d-flex">
            <span class="secondary--text">Donnée actuelle</span>
            <app-icon
              icon="right"
              color="secondary lighten-4"
              :size="14"
              class="mx-2"
            />
            <span>Donnée provenant de l'identité récupérée</span>
          </div>
        </template>
      </app-replacement-content-list>
    </n-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import { INS_FETCH_METHOD } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import { isInsPatientTraitsValid, getReplacementItemFromInsCallResult } from '@/modules/patient/utils/ins';
import NovaTools from '@/nova-tools/NovaTools';
import ButtonAction from '@novalys/src/models/ButtonAction';

import AppReplacementContentList from '@/components/ui/replacementContent/AppReplacementContentList.vue';
import ICanopeeModal from '@/modules/patient/components/patientForm/ICanopeeModal.vue';

export default {
  name: 'PatientIdentityQualificationButton',
  components: {
    AppReplacementContentList,
    ICanopeeModal,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    isPartnerAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      INS_FETCH_METHOD,
      ButtonAction,
      insDropdownActive: false,
      iCanopeeModalVisible: false,
      insCallType: INS_FETCH_METHOD.TRAIT,
      insiResult: {
        replacements: null,
        insIdentity: null,
      },
    };
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasCpxCard', 'getHasVitaleCard']),
    insiResultDifferences () {
      return this.insiResult.replacements?.filter(({ before, after }) => before !== after) ?? null;
    },
  },
  methods: {
    openIcanopeeModal (insCallType) {
      this.iCanopeeModalVisible = true;
      this.insCallType = insCallType;
    },
    closeIcanopeeModal () {
      this.iCanopeeModalVisible = false;
    },
    onInsFetchSuccess ({ CR, Ins, ...patientTraits }) {
      const { codeCR } = CR;
      if (codeCR === '00') {
        if (! isInsPatientTraitsValid(patientTraits)) {
          NovaTools.notify.error('Impossible de récupérer l\'identité du patient', {
            title: 'Le téléservice INSi renvoie une identité partielle',
            timeout: 6000,
          });
          this.closeIcanopeeModal();
          return;
        }
        this.closeIcanopeeModal();
        this.insiResult.replacements = getReplacementItemFromInsCallResult(this.patient, patientTraits);
        const { oid, nir, key } = Ins;
        this.insiResult.insIdentity = {
          ...this.patient.insIdentity,
          oid,
          ins: nir + key,
        };
        if (! this.isPartnerAccess) {
          this.$emit('partner-success', new Patient({
            ...this.patient,
            insIdentity: this.insiResult.insIdentity,
          }));
        }
        if (! this.insiResultDifferences.length) {
          this.applyInsiResultFromPatient(this.patient);
          return;
        }
        return;
      }
      if (codeCR === '01') {
        NovaTools.notify.error('Impossible de récupérer l\'INS à partir des informations renseignées');
      }
      if (codeCR === '02') {
        NovaTools.notify.error('Veuillez affiner les traits d\'identification du patient', {
          title: 'Plusieurs identités trouvées',
          timeout: 8000,
        });
      }
      this.closeIcanopeeModal();
    },
    onInsFetchError () {
      this.closeIcanopeeModal();
      NovaTools.notify.error('Impossible de récupérer l\'INS', { title: 'Une erreur est survenue' });
      this.$emit('error');
    },
    onInsFetchResult (event) {
      this.$emit('fetch-ins', event);
    },
    applyInsiResultFromPatient (patient) {
      this.$emit('success', new Patient({
        ...patient,
        insIdentity: this.insiResult.insIdentity,
      }));
      NovaTools.notify.success('La récupération de l\'INS a été effectuée avec succès');
    },
    applyInsiDifferences () {
      const updatedPatient = new Patient(this.patient);
      this.insiResultDifferences.forEach(({ key, after }) => {
        updatedPatient[key] = after;
      });
      this.applyInsiResultFromPatient(updatedPatient);
    },
    handleCancel () {
      NovaTools.notify.info('La récupération de l\'INS a été annulée');
      this.insiResult.replacements = null;
      this.insiResult.insIdentity = null;
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="scss">
.patient-identity-qualification-button {
  &__menu {
    border-radius: 6px;
    background-color: white;
    margin-top: map-get($spacers, 2);

    ::v-deep {
      .v-list-item {
        display: flex;
        align-items: center;
        min-height: 34px;
        gap: map-get($spacers, 4);
        font-size: 13px;
        font-weight: 600;

        &:after {
          display: none;
        }
      }
    }
  }
}
</style>