import PrescriptionHealthProductLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionHealthProductLine';

export default class PrescriptionLppLine extends PrescriptionHealthProductLine {
  constructor (options = {}) {
    super(options);

    this.locationDayCount = options.locationDayCount || null;
    this.locationDurationCount = options.locationDurationCount || this.locationDayCount;
    this.locationDurationUnit = options.locationDurationUnit || 'jour(s)';
    this.packageUnit = options.packageUnit || null;
    this.purchase = options.purchase || false;
  }

  getPackageDetails () {
    const packageUnitStr = this.packageUnit ?? '';
    return `Quantité : ${this.packageCount} ${packageUnitStr}`;
  }

  getDurationDetails () {
    if (this.purchase) {
      return 'En achat';
    }
    const locationDurationPrefix = 'En location pendant';
    if (this.locationDurationCount) {
      return `${locationDurationPrefix} ${this.locationDurationCount} ${this.locationDurationUnit}`;
    }
    if (this.locationDayCount) {
      return `${locationDurationPrefix} ${this.locationDayCount} jour(s)`;
    }
    return '';
  }
}