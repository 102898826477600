<template>
  <div
    v-test="`measure-${measure.name}`"
    class="consultation-measures-item"
  >
    <div
      class="consultation-measures-item__row"
    >
      <span
        v-if="measure.label"
        v-test="`measure-label-${measure.name}`"
        class="blue-grey--text mr-1"
      >{{ measure.label }} :</span>
      <span
        v-test="`measure-value-${measure.name}`"
        class="font-weight-semi-bold"
      >
        {{ getValueWithUnit(measure) }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConsultationMeasuresItem',
  components: { },
  props: {
    measure: {
      type: Object, // ce n'est ni un Measure ni un Indicator > créer une classe composite ?
      required: true,
    },
  },
  methods: {
    getValueWithUnit (measure) {
      return measure.value ? measure.value + (measure.unit ? ` ${measure.unit}` : '') : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.consultation-measures-item {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    &__row {
      justify-content: space-between;
      max-height: 20px;
    }
}
</style>