/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation';

const correspondentEntity = new DocumentEntity(
  'Correspondent',
  'correspondents.xml',
  'Contient les données des professionnels de santé rattachés aux patients',
);

correspondentEntity.addField(new DocumentEntityField('contact', 'Les informations de contact', new DocumentEntityRelation('Contact', 'id')));
correspondentEntity.addField(new DocumentEntityField('category', 'La catégorie'));
correspondentEntity.addField(new DocumentEntityField('note', 'La note associée'));

export default correspondentEntity;