<template>
  <v-date-picker
    ref="datePicker"
    v-model="selectedDate"
    class="mini-calendar"
    no-title
    scrollable
    full-width
    show-week
    locale-first-day-of-year="4"
    first-day-of-week="1"
    :weekday-format="formatWeekDay"
    show-adjacent-months
    @input="$emit('date-updated')"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import NovaTools from '@/nova-tools/NovaTools';
import { leftPad } from '@/utils/functions/number';

export default {
  name: 'AgendaMiniCalendar',
  data () {
    return {
      // Est vrai lorsque le composant est monté permettant d'utiliser la ref du datePicker dans une computed
      isMounted: false,
    };
  },
  computed: {
    ...mapState('agenda', ['currentDate']),
    /**
     * Retourne le mois sélectionné dans la vue jour du datePicker
     * Contrairement à selectedDate, cette date sera mise à jour au clic sur les boutons de navigation de l'entête du date picker
     * @returns { String }
     */
    tableMonth () {
      if (this.isMounted) {
        return this.$refs.datePicker.$data.tableDate.split('-')[1];
      }
      return leftPad(new Date(this.selectedDate).getMonth() + 1, 2);
    },
    selectedDate: {
      get () {
        return this.formatDate(this.currentDate);
      },
      set (newVal) {
        this.SET_CURRENT_DATE(NovaTools.dates.dateToDatetime(newVal));
      },
    },
  },
  watch: {
    tableMonth () {
      this.$nextTick(this.enableAdjacentMonthsDays);
    },
  },
  mounted () {
    this.isMounted = true;
    this.enableAdjacentMonthsDays();
  },
  methods: {
    ...mapMutations('agenda', ['SET_CURRENT_DATE']),
    formatWeekDay (day) {
      return NovaTools.dates.format(day, 'EEEEEE');
    },
    formatDate (date) {
      return NovaTools.dates.format(date, 'yyyy-MM-dd');
    },
    enableAdjacentMonthsDays () {
      const dateButtons = this.$refs.datePicker.$el.querySelectorAll('tbody .v-btn');
      dateButtons.forEach(button => {
        if (button.hasAttribute('disabled')) {
          // Supprimer l'attribut "disabled" rétabli le comportement par défaut du bouton
          button.removeAttribute('disabled');
          button.style.pointerEvents = 'all';
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mini-calendar::v-deep {
  .v-date-picker-table {
    height: auto !important;
  }
  table {
    border-collapse: collapse;
    th {
      text-transform: capitalize;
    }
    .v-date-picker-header__value {
      .accent--text {
        button {
          text-transform: uppercase;
        }
      }
    }
  }
  .v-date-picker-table--date {
    tr {
      td {
        &:first-child {
          background-color: #f1f3f4;
          small {
            font-weight: 600;
          }
        }
      }
    }
    tbody .v-btn.v-btn--disabled.v-btn--active {
      color: #fff !important
    }
  }
}
</style>