/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientNonDrugAllergyEntity = new DocumentEntity(
  'PatientFemaleMedicalDataset',
  'patientFemaleMedicalDatasets.xml',
  'Contient les données spécifiques aux femmes',
);

patientNonDrugAllergyEntity.addField(new DocumentEntityField('pregnant', 'Est enceinte'));
patientNonDrugAllergyEntity.addField(new DocumentEntityField('breastfeeding', 'Allaite'));
patientNonDrugAllergyEntity.addField(new DocumentEntityField('lastPeriodDate', 'Date de dernières règles'));

export default patientNonDrugAllergyEntity;