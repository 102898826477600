import { DOCUMENT_TYPES } from '@/modules/documents/constants';
import ConsultationLettersDocument from '@/modules/patient/models/ConsultationLettersDocument';


export default class ConsultationDischargeLetterDocument extends ConsultationLettersDocument {
  constructor (options = {}) {
    super(options);
    this.content = options.content || null;
  }

  getLabel () {
    return DOCUMENT_TYPES.DISCHARGE_LETTER.title;
  }

  getIcon () {
    return DOCUMENT_TYPES.DISCHARGE_LETTER.icon;
  }
}