import ToggleableFilter from './ToggleableFilter';

/**
 * Callback pour la résolution du label
 * @callback LabelGetterCallback
 * @param {*} componentValue La valeur du v-model
 * @returns {String} Le nouveau label
 */

/**
 * Structure définissant les options complémentaires à ToggleableFilter
 * @typedef {Object} SelectFilterExtendedOptions
 * @property {LabelGetterCallback} labelGetter Permet de fournir un nouveau label en fonction du v-model
 */

/**
 * Structure définissant les options de configuration d'un filtre toggleable
 * @typedef {import('@/utils/classes/httpRequestParameters/filters/ToggleableFilter').
 *    ToggleableFilterOptions & SelectFilterExtendedOptions} SelectFilterOptions
 */

/**
 * Classe représentant un filtre que l'on peut sélectionner.
 * Elle se différencie du filtre toggleable par la nécessité d'avoir un label dynamique
 */
export default class SelectFilter extends ToggleableFilter {
  /**
   * Callback permettant d'affecter un nouveau label toggleable est truthy
   * @type {LabelGetterCallback}
   */
  labelGetter;

  /**
   * Permet de créer un nouveau filtre sélectionnable
   * @param {SelectFilterOptions} options les options de filtre sélectionnable
   */
  constructor (options = {}) {
    super(options);
    this.labelGetter = options.labelGetter;
    if (! this.labelGetter) {
      throw new Error('Les filtres sélectionnables doivent fournir la méthode "labelGetter"');
    }

    this.onComponentValueChanged = (vm) => {
      if (! this.componentValue) {
        this.label = null;
        this.updateValue(null);

        return;
      }

      if (Array.isArray(this.componentValue)) {
        this.label = this.componentValue.map(value => this.labelGetter(value, vm));
        this.updateValue(this.componentValue.map(value => this.valueGetter(value, vm)));
      } else {
        this.label = this.labelGetter(this.componentValue, vm);
        this.updateValue(this.valueGetter(this.componentValue, vm));
      }
    };
  }
}
