<template>
  <app-select
    :value="value"
    :rules="rules"
    :items="insureds"
    label="Sélectionnez un bénéficiaire"
    :item-text="insured => insured.patient.getCivilState()"
    :item-value="insured => insured"
    single-line
    class="mt-0 pt-0"
    return-object
    :readonly="readonly"
    :hint="hint"
    :persistent-hint="persistentHint"
    @input="insured => $emit('input', insured)"
  />
</template>

<script>
import AppSelect from '@/components/ui/form/AppSelect.vue';

export default {
  name: 'VitaleCardInsuredPicker',
  components: { AppSelect },
  props: {
    insureds: {
      type: Array,
      default () {
        return [];
      },
    },
    value: {
      type: Object,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
};
</script>