import { state as referringPhysiciansState, getters as referringPhysiciansGetters, mutations as referringPhysiciansMutations, actions as referringPhysiciansActions } from './referringPhysicians';

export const state = () => ({ ...referringPhysiciansState() });

export const getters = { ...referringPhysiciansGetters };

export const mutations = { ...referringPhysiciansMutations };

export const actions = { ...referringPhysiciansActions };

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};