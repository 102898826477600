<template>
  <app-panel title="Décès">
    <app-buttons-group
      v-test="'patient-is-deceased-buttons-group'"
      label="Le patient est décédé"
      item-text="label"
      :items="[{ label: 'Oui', value: true }, { label: 'Non', value: false }]"
      :value="isDeceased"
      mandatory
      @input="updateIsDeceasedField($event)"
    />
    <app-date-field
      v-if="isDeceased"
      v-test="'death-date-input'"
      class="ml-2"
      :value="deathDate"
      label="Date de décès"
      name="deathDate"
      :rules="'before_now'"
      clearable
      @input="$emit('update:death-date', $event)"
    />
  </app-panel>
</template>

<script>
import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue';
import AppDateField from '@/components/ui/form/AppDateField.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';

export default {
  name: 'DeathFieldset',
  components: {
    AppPanel,
    AppDateField,
    AppButtonsGroup,
  },
  props: {
    isDeceased: {
      type: Boolean,
      default: false,
    },
    deathDate: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateIsDeceasedField ($event) {
      this.$emit('update:is-deceased', $event);
      if (! $event) {
        this.$emit('update:death-date', null);
      }
    },
  },
};
</script>