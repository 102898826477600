<template>
  <n-list
    :items="sortedActs"
    :spacing="1"
    inline
  >
    <template #item="{ item: act }">
      <v-menu
        offset-y
        bottom
        open-on-hover
        open-delay="200"
        rounded="lg"
        transition="slide-y-transition"
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <app-tag
              x-small
              :color="act.main ? 'content': 'secondary'"
              class="pending-mss-documents-panel__tag"
            >
              {{ act.name }}
            </app-tag>
          </div>
        </template>
        <v-sheet class="pa-3">
          <h3
            v-test="'act-category'"
            class="subtitle-3"
          >
            {{ act.main ? 'Acte principal' : 'Expression personnelle du patient' }}
          </h3>
          <p
            v-test="'act-name'"
            class="secondary--text"
          >
            {{ act.name }}
          </p>
          <n-divider :spacing="2" />
          <app-dataset-item
            v-test="'act-performer'"
            :label="act.main ? 'Praticien' : 'Patient'"
            :value="act.performer && act.performer.name"
          />
          <app-dataset-item
            v-if="act.main"
            v-test="'act-organisation'"
            label="Organisation"
            :value="act.performer.organisation"
          />
          <app-dataset-item
            v-test="'act-setting'"
            :label="act.main ? 'Cadre d\'exercice' : 'Démarche'"
            :value="act.performer && act.performer.practiceSetting"
          />
        </v-sheet>
      </v-menu>
    </template>
  </n-list>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';

export default {
  name: 'DocumentActList',
  components: {
    AppTag,
    AppDatasetItem,
  },
  props: {
    acts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedActs () {
      return [...this.acts].sort((actLeft, actRight) => {
        if (actLeft.main === actRight.main) return 0;
        if (actLeft.main) return -1;
        return 1;
      });
    },
  },
};
</script>