<template>
  <app-hoverable-actions-wrapper
    tag="article"
    :actions="actions"
    align-top
  >
    <correspondents-item-body
      v-test="'correspondents-item-body'"
      :correspondent="correspondent"
    />
  </app-hoverable-actions-wrapper>
</template>

<script>
import CorrespondentsItemBody from './CorrespondentsItemBody.vue';
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent';

import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue';

export default {
  name: 'CorrespondentsPanelItem',
  components: {
    AppHoverableActionsWrapper,
    CorrespondentsItemBody,
  },
  props: {
    correspondent: {
      type: PatientCorrespondent,
      required: true,
    },
  },
  data () {
    return {
      actions: [
        new ActionMenuItem(
          'edit',
          'Modifier',
          () => {
            this.$emit('edit-correspondent', this.correspondent);
          },
        ),
        new ActionMenuItem(
          'delete',
          this.correspondent.isReferringPhysician
            ? 'La suppression du médecin traitant se fait dans la vue d\'ensemble du dossier patient'
            : 'Supprimer',
          this.correspondent.isReferringPhysician ? () => {} : () => {
            this.$emit('delete-correspondent', this.correspondent);
          },
        ),
      ],
    };
  },
};
</script>