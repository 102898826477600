<template>
  <section>
    <object
      v-test="'consultation-letter-preview'"
      class="consultation-letter-preview"
      :data="pdfData"
      type="application/pdf"
    >
      Votre navigateur ne supporte la prévisualisation de fichiers PDF
    </object>
  </section>
</template>

<script>
export default {
  name: 'ConsultationLetterPreview',
  props: {
    base64Data: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    pdfData () {
      return this.base64Data
        ? ['data:application/pdf;base64', this.base64Data].join(',')
        : null;
    },
  },
};
</script>

<style lang="scss">
object {
  width: 100%;
  min-height: 420px;
}
</style>