<template>
  <app-autocomplete
    :items="organisationPractitioners"
    :item-value="itemValue"
    :value="value"
    :item-text="getItemText"
    label="Praticien"
    :return-object="returnObject"
    :rules="rules"
    prepend-icon="practitioner"
    :clearable="clearable"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import User from '@/models/user/User';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';


/**
 * Permet de sélectionner un praticien parmi ceux de l'organisation
 */
export default {
  name: 'OrganisationPractitionerSelector',
  components: { AppAutocomplete },
  props: {
    value: {
      type: [String, User],
      default: '@id',
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: [String, Function],
      default: null,
    },
  },
  computed: { ...mapGetters('users', ['organisationPractitioners']) },
  methods: {
    getItemText (user) {
      return `${user.contact.getCivilState()} (${user.contact.practitionerProfile.rpps})`;
    },
  },
};
</script>