import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';

export default class EditActionMenuItem extends ActionMenuItem {
  /**
   * Représente une action de menu
   * @param {String} [icon = edit] L'icône de l'action
   * @param {String} [text = Modifier] Le texte de l'action
   * @param {Function} callback La méthode exécutée par l'action
   * @param {Object} [options] Options de l'ActionMenuItem
   */

  constructor ({ icon = 'edit', text = 'Modifier', callback, options }) {
    super(icon, text, async (...args) => {
      await callback(...args);
    }, options);
  }
}