<template>
  <button
    v-ripple
    v-tooltip="'Accéder à un dossier par carte vitale'"
    class="vitale-card-search-button"
    @click="openModal"
  >
    <svg
      height="24px"
      width="20px"
      :class="searchIconColor"
    >
      <use :xlink:href="`#${searchIconColor}`" />
    </svg>
    <vitale-card-search-modal :is-open.sync="isModalOpen" />
  </button>
</template>

<script>
import VitaleCardSearchModal from '@/layout/default/components/header/vitaleCardSearch/VitaleCardSearchModal.vue';

export default {
  name: 'VitaleCardSearchButton',
  components: { VitaleCardSearchModal },
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { isModalOpen: false };
  },
  computed: {
    searchIconColor () {
      return this.inverted ? 'icon-vital-card-search-blue' : 'icon-vital-card-search';
    },
  },
  methods: {
    openModal () {
      this.isModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.vitale-card-search-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: white;
  border-radius: 50%;

  &:hover:before {
    opacity: .08;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: currentColor;
    opacity: 0;
    transition: opacity .2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  .icon-vital-card-search {
    fill: currentColor;
  }
}

</style>