import Prescription from '@/modules/patient/models/Prescription';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api';

export default class PrescriptionTemplate extends Prescription {

  constructor (options = {}) {
    super(options);
  }

  /**
   * Permet de récupérer l'ensemble des ordonnances type depuis l'API
   * @param {Object} params
   * @returns {PrescriptionTemplate[]}
   */
  static async fetchAll (params) {
    const { data } = await getFromAPI('/api/prescription_templates', params);
    return data['hydra:member'].map(prescriptionTemplate => new PrescriptionTemplate(prescriptionTemplate));
  }

  /**
   * Permet de récupérer les ordonnances type filtrées depuis l'API
   * @param {String} filter
   * @returns {PrescriptionTemplate[]}
   */
  static async fetchAllFilterByText (filter = '') {
    return this.fetchAll({ global: filter });
  }

  /**
   * Insère une ordonnance type vers l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   * @returns {PrescriptionTemplate}
   */
  static async insert (prescriptionTemplate, showNotification = true) {
    const { data } = await postToAPI('/api/prescription_templates', prescriptionTemplate);
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été créée avec succès');
    }
    return new PrescriptionTemplate(data);
  }

  /**
   * Mettre à jour une ordonnance type à partir de l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   * @returns {PrescriptionTemplate}
   */
  static async update (prescriptionTemplate, showNotification = true) {
    const { data } = await putToAPI(prescriptionTemplate['@id'], { data: prescriptionTemplate });
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été remplacée avec succès');
    }
    return new PrescriptionTemplate(data);
  }

  /**
   * Supprime une ordonnance type à partir de l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   */
  static async delete (prescriptionTemplate, showNotification = true) {
    await deleteFromAPI(prescriptionTemplate['@id']);
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été supprimée avec succès');
    }
  }

}