<template>
  <settings-layout :menu-items="displayedMenuItems">
    <router-view />
  </settings-layout>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

import SettingsLayout from './layout/SettingsLayout.vue';
import SETTINGS_MENU_ITEMS from '@/constants/settingsMenuItems';

/**
 * Représente le module de la partie paramètres
 */
export default {
  name: 'SettingsIndex',
  components: { SettingsLayout },
  /**
   * Stocke la route précédent l'arrivée dans les paramètres dans le sessionStorage du navigateur
   * cette donnée sera donc supprimé à chaque fin de session de l'onglet courant
   */
  beforeRouteEnter (to, from, next) {
    sessionStorage.setItem('path-origin', from.path);
    next();
  },
  beforeRouteUpdate (to, from, next) {
    // Si on tente de revenir en arrière sur desktop, la route sera toujours /parametres
    if (! this.isMobile && to.name === 'settings') {
      next(sessionStorage.getItem('path-origin'));
    } else {
      next();
    }
    this.updateRouteMetadata(to);
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapGetters('auth', ['getCurrentUserProfile']),
    displayedMenuItems () {
      return SETTINGS_MENU_ITEMS.reduce((acc, menuItem) => {
        const availableMenuLinks = menuItem.links.filter(link => link.have_access.includes(this.getCurrentUserProfile));
        // Si aucun lien n'est disponible dans le panel
        // Alors le panel n'est pas affiché
        return availableMenuLinks.length === 0
          ? [...acc]
          : [
            ...acc,
            {
              ...menuItem,
              links: availableMenuLinks,
            },
          ];
      }, []);
    },
  },
  watch: {
    isMobile: {
      immediate: true,
      /**
       * Empêche d'accéder à la route /parametres sur desktop lors du passage de mobile à desktop
       */
      handler () {
        if (! this.isMobile && this.$route.name === 'settings') {
          this.$router.push({ name: 'settings.account' });
        }
      },
    },
  },
  created () {
    this.updateRouteMetadata();
  },
  methods: {
    /**
     * Stocke en métadonnées les informations de la route à charger
     */
    updateRouteMetadata (to) {
      const nextRoute = to || this.$route;
      const route = nextRoute.name;

      for (let i = 0; i < this.displayedMenuItems.length; i += 1) {
        const item = this.displayedMenuItems[i];
        const sectionTitle = item.links.find(link => link.to.name === route);

        if (sectionTitle !== undefined) {
          nextRoute.meta.sectionTitle = sectionTitle.text;
          nextRoute.meta.categoryTitle = item.title;
          break;
        }
      }
    },
  },
};
</script>