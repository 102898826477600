import Vue from 'vue';

import { state as footersState, getters as footersGetters, mutations as footersMutations, actions as footersActions } from './footers';
import { state as headersState, getters as headersGetters, mutations as headersMutations, actions as headersActions } from './headers';
import { state as templatesState, getters as templatesGetters, mutations as templatesMutations, actions as templatesActions } from './templates';
import DocumentVariable from '@/modules/documents/models/DocumentVariable';
import { getFromAPI } from '@/services/api';
import { mapStoreResetter } from '@/utils/functions/store';


export const state = () => ({
  ...headersState(),
  ...footersState(),
  ...templatesState(),
  isDocumentEditorInitialized: false,
  documentVariables: {},
});

export const getters = {
  ...headersGetters,
  ...footersGetters,
  ...templatesGetters,
  getDocumentVariables: state => Object.values(state.documentVariables),
};

export const mutations = {
  ...mapStoreResetter(state),
  ...headersMutations,
  ...footersMutations,
  ...templatesMutations,
  SET_DOCUMENT_EDITOR_INITIALIZED (state, value) {
    state.isDocumentEditorInitialized = value;
  },
  SET_DOCUMENT_VARIABLE (state, variable) {
    Vue.set(state.documentVariables, variable['@id'], new DocumentVariable(variable));
  },
};

export const actions = {
  ...headersActions,
  ...footersActions,
  ...templatesActions,
  async fetchDocumentVariables ({ commit }) {
    const { data } = await getFromAPI('/api/document_variables');
    data['hydra:member'].forEach(variable => commit('SET_DOCUMENT_VARIABLE', variable));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};