<template>
  <v-menu
    v-model="isOpen"
    class="dropdown-menu"
    open-on-hover
    offset-y
    bottom
    left
  >
    <template #activator="{ on, attrs }">
      <div
        class="dropdown-menu__button"
        v-bind="attrs"
        v-on="on"
      >
        <app-icon
          color="#FFFFFF"
          :icon="icon"
        />
        <app-icon
          color="#FFFFFF"
          :icon="isOpen ? 'chevron-up' : 'chevron-down'"
          size="9"
        />
      </div>
    </template>
    <v-list class="dropdown-menu__list">
      <v-subheader class="dropdown-menu__list__sub-header">
        {{ title }}
      </v-subheader>
      <slot />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownMenuList',
  props: {
    icon: {
      type: String,
      default: 'settings',
    },
    title: {
      type: String,
      default: 'titre',
    },
  },
  data () {
    return { isOpen: false };
  },
};
</script>

<style scoped lang="scss">
  .dropdown-menu {
    &__button {
      height: 36px;
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &__list {
      width: 250px;

      &__sub-header {
        font-size: 13px;
        height: 36px;
      }
    }
  }
</style>