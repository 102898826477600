<template>
  <app-form
    :submit-method="sendAldiRequest"
    v-on="{ ...$listeners }"
  >
    <vitale-card-switch-view-validator
      v-if="!showApCv && !apCvData"
      v-test="'vitale-card-view'"
      unfounded-card-message="Ce téléservice nécessite une carte vitale"
      :can-use-apcv="true"
      @use-apcv="showApCvModal"
      @remove-card="clearForm"
    >
      <beneficiary-selector
        :patient="value"
        :insureds="insureds"
        :selected-beneficiary.sync="selectedBeneficiary"
      />
    </vitale-card-switch-view-validator>
    <div
      v-else
      v-test="'ap-cv-view'"
    >
      <ap-cv-form
        v-if="!insureds.length"
        v-test="'ap-cv-form'"
        :patient="value"
        @submit-success="getDataFromApCv"
      />
      <beneficiary-selector
        v-else
        :patient="value"
        :insureds="insureds"
        :selected-beneficiary.sync="selectedBeneficiary"
      />
    </div>
  </app-form>
</template>

<script>

import { mapGetters } from 'vuex';

import Aldi from '@/modules/patient/components/sidebar/tlsi/aldi/models/Aldi';
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import { getCivilState } from '@/utils/functions/persons';

import AppForm from '@/components/ui/form/AppForm.vue';
import VitaleCardSwitchViewValidator from '@/components/ui/form/VitaleCardSwitchViewValidator.vue';
import ApCvForm from '@/modules/patient/components/sidebar/tlsi/apcv/ApCvForm.vue';
import BeneficiarySelector from '@/modules/patient/components/sidebar/tlsi/BeneficiarySelector.vue';

export default {
  name: 'AldiForm',
  components: {
    AppForm,
    VitaleCardSwitchViewValidator,
    ApCvForm,
    BeneficiarySelector,
  },
  props: {
    value: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      selectedBeneficiary: null,
      cardType: null,
      showApCv: false,
      apCvData: null,
    };
  },
  computed: {
    ...mapGetters('cardReaders', ['getCardContent', 'getHasVitaleCard']),
    vitaleCardInsureds () {
      return this.getCardContent(CARD_TYPES.VITALE);
    },
    insureds () {
      return this.vitaleCardInsureds || this.apCvData?.insureds || [];
    },
    beneficiaryVitaleCardIndex () {
      return this.insureds.indexOf(this.selectedBeneficiary);
    },
    beneficiaryFullName () {
      return getCivilState({
        firstNames: this.selectedBeneficiary?.patient.firstName,
        familyName: this.selectedBeneficiary?.patient.familyName,
      });
    },
  },
  watch: {
    vitaleCardInsureds () {
      this.showApCv = false;
    },
  },
  mounted () {
    this.$listeners.cancel = () => {
      this.clearForm();
    };
  },
  methods: {
    clearForm () {
      if (this.showApCv) {
        this.showApCv = false;
        this.apCvData = null;
        this.cardType = null;
      }
      this.selectedBeneficiary = null;
    },
    showApCvModal () {
      this.showApCv = true;
    },
    getDataFromApCv (apCvData) {
      this.apCvData = apCvData;
      this.cardType = apCvData.data.s_profileType;
    },
    async sendAldiRequest () {
      try {
        const aldiInstance = new Aldi({ patient: this.value });

        const requestParams = {
          patientData: null,
          template: null,
        };
        requestParams.template = await aldiInstance.getAldiTemplate(this.beneficiaryVitaleCardIndex, ! this.getHasVitaleCard);

        if (this.getHasVitaleCard) {
          requestParams.patientData = await Tlsi.getBeneficiaryData(this.beneficiaryVitaleCardIndex, 'PatientData');
        } else {
          requestParams.patientData = this.beneficiaryVitaleCardIndex === 0
            ? this.apCvData.data.User.VitaleData
            : this.apCvData.data.Wallet[this.beneficiaryVitaleCardIndex - 1].VitaleData;
        }
        requestParams.patientData.s_birthday = this.selectedBeneficiary.patient.birthDate;

        const result = await aldiInstance.sendAldiRequest(requestParams);
        return aldiInstance.formatAldiResult(result, this.beneficiaryFullName);
      } catch (error) {
        if (typeof error === 'object' && error.response && error.response.data) {
          const contentError = error.response.data['hydra:description'];
          if (error.response.data['hydra:detail']) {
            contentError.concat(`(${error.response.data['hydra:detail']})`);
          }
          NovaTools.notify.error(contentError, {
            title: error.response.data['hydra:title'],
            timeout: 15000,
          });
        } else {
          NovaTools.notify.error(error.message ?? error, { timeout: 15000 });
        }
        throw error;
      } finally {
        this.clearForm();
      }
    },
  },
};
</script>