<template>
  <app-modal-form
    :title="tlsiModalMixin_modalFormTitle"
    submit-text="Transmettre"
    :form="DmtiForm"
    :value="patient"
    width="720"
    :is-opened.sync="tlsiModalMixin_isOpen"
    @cancel="tlsiModalMixin_closeModalForm"
    @submitError="tlsiModalMixin_closeModalForm"
    @submitSuccess="handleDmtiRequestSuccess"
  />
</template>

<script>

import tlsiModalMixin from '@/modules/patient/components/sidebar/tlsi/tlsiModalMixin';
import { TLSI_TYPES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { getFileFromAPI } from '@/services/api';
import { format } from '@/utils/functions/dates';
import { downloadBlob } from '@/utils/functions/files';
import ButtonAction from '@novalys/src/models/ButtonAction';

import AppModalForm from '@/components/ui/modal/AppModalForm.vue';
import DmtiForm from '@/modules/patient/components/sidebar/tlsi/dmti/DmtiForm.vue';

export default {
  name: 'DmtiModalForm',
  components: { AppModalForm },
  mixins: [tlsiModalMixin(TLSI_TYPES.DMTI)],
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return { DmtiForm };
  },
  methods: {
    handleDmtiRequestSuccess ({ message, time, returnReceiptId }) {
      this.tlsiModalMixin_closeModalForm();
      NovaTools.dialog.confirm(
        'Accusé de réception',
        message,
        new ButtonAction('Fermer', () => {}),
        new ButtonAction('Télécharger', async () => {
          const { data } = await getFileFromAPI(`api/tlsi/${returnReceiptId}/download_receipt`);
          const fileName = `teledeclaration-${format(time, 'ddMMyyyy')}`;
          downloadBlob(data, { fileName });
        }, 'primary'),
      );
    },
  },
};
</script>