<template>
  <n-expandable :icon-rotation-angle="[-90, 0]">
    <template #header="{ toggle, expandIconStyle }">
      <h3
        class="subtitle-3 consultation-vaccines-list__vaccines-display"
        @click="toggle"
      >
        <app-icon
          icon="caret"
          size="12"
          color="secondary"
          :style="expandIconStyle"
        />
        <span class="ml-2">Vaccinations</span>
        <app-tag
          x-small
          color="#c0d0fe"
          class="px-2 ml-2"
        >
          {{ vaccines.length }}
        </app-tag>
      </h3>
    </template>
    <app-row>
      <app-col>
        <consultation-vaccine-item
          v-for="vaccine in vaccines"
          :key="vaccine['@id']"
          :vaccine="vaccine"
        />
      </app-col>
    </app-row>
  </n-expandable>
</template>

<script>
import AppTag from '@/components/ui/tag/AppTag.vue';
import ConsultationVaccineItem
  from '@/modules/patient/components/consultation/vaccine/ConsultationVaccineItem.vue';

export default {
  name: 'MedicalHistoryVaccinesList',
  components: {
    ConsultationVaccineItem,
    AppTag,
  },
  props: {
    vaccines: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.medical-history-vaccines-list {
  &__vaccine {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 1);
    align-items: flex-start;
  }
  &__vaccine-element {
    display: flex;
    flex-direction: row;
    gap: map-get($spacers, 3);
    align-items: flex-start;
  }
  &__vaccines-display {
    user-select: none;
    cursor: pointer;
  }
}
</style>