import ApiEntity from '@/models/ApiEntity';
import User from '@/models/user/User';

export default class MedicalHistoryNote extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.patient = options.patient || null;
    this.createdBy = new User(options.createdBy) || null;
    this.updatedBy = new User(options.updatedBy);
    this.content = options.content || null;
  }
}