<template>
  <app-form
    :submit-method="submitMethod"
    class="login-form"
  >
    <app-alert
      v-if="errorMessage"
      v-test="'error-toast'"
      type="error"
      class="mb-4"
    >
      {{ errorMessage }}
    </app-alert>
    <app-text-field
      v-model="user.username"
      v-test="'email-field'"
      label="Email"
      class="mb-2"
      rules="required|email"
    />
    <app-text-field
      v-model="user.password"
      v-test="'password-field'"
      label="Mot de passe"
      :type="showClearPassword ? 'text' : 'password'"
      rules="required"
      :append-icon="showClearPassword ? 'hide' : 'visualize'"
      @click:append="showClearPassword = !showClearPassword"
    />
    <div class="login-form__actions mt-4">
      <app-checkbox
        v-model="rememberMe"
        v-test="'remember-me-checkbox'"
        label="Se souvenir de moi"
      />
      <app-link
        v-test="'reset-password-link'"
        :to="{ name: AUTHENTICATION_ROUTE_NAMES.FORGET_PASSWORD }"
      >
        Mot de passe oublié
      </app-link>
    </div>
  </app-form>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import { BASE_ROUTE_NAMES } from '@/constants';
import UserLogin from '@/models/UserLogin';
import { ROUTE_NAMES as AUTHENTICATION_ROUTE_NAMES } from '@/modules/authentication/constants';
import NovaTools from '@/nova-tools/NovaTools';
import gcuService from '@/services/gcuService';

import AppAlert from '@/components/ui/alert/AppAlert.vue';
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';

export default {
  name: 'LoginForm',
  components: {
    AppTextField,
    AppCheckbox,
    AppAlert,
    AppForm,
  },
  data () {
    return {
      AUTHENTICATION_ROUTE_NAMES,
      BASE_ROUTE_NAMES,
      user: new UserLogin(),
      rememberMe: true,
      errorMessage: null,
      // Nom d'utilisateur premettant au champ email de ne pas être vidé après une connexion sans l'option "se souvenir de moi"
      savedUsername: null,
      showClearPassword: false,
    };
  },
  computed: { ...mapState('auth', ['lastAuthUsername', 'lastRememberMe']) },
  watch: {
    lastAuthUsername (lastAuthUsername) {
      this.user.username = this.savedUsername || lastAuthUsername;
    },
    lastRememberMe (lastRememberMe) {
      this.rememberMe = lastRememberMe;
    },
  },
  mounted () {
    this.user.username = this.lastAuthUsername;
    this.rememberMe = this.lastRememberMe;
  },
  methods: {
    ...mapActions('auth', ['authenticate', 'logout_nocgu']),
    async submitMethod () {
      try {
        this.savedUsername = this.user.username;

        const { user, gcu } = await this.authenticate({
          user: this.user,
          rememberMe: this.rememberMe,
        });

        this.user.username = this.savedUsername;

        if (! user.isPersonalizedPwd) {
          NovaTools.notify.warning('Votre mot de passe est temporaire, veuillez le réinitialiser.');
          this.$router.push({ name: AUTHENTICATION_ROUTE_NAMES.RESET_PASSWORD });
          return;
        }
        if (gcuService.handleGcuValidation(gcu, () => this.logout_nocgu())) {
          return;
        }
        await this.$router.push('/');

      } catch (error) {
        this.errorMessage = error.response?.data?.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>