import store from '@/store/instance';

export const displayError = message => {
  store.commit('app/SET_SNACK', {
    message,
    color: 'error',
  });
};

export const globalErrorHandler = error => {
  /**
   * Pour ne pas gérer l'erreur par cet intercepteur,
   * ajoutez errorHandle: false dans le paramètre de la requete :
   * ex: axios.get('/patients', {errorHandle: false})
   */
  if (error?.config?.errorHandle === false) {
    return Promise.reject(error);
  }

  // Serveur indisponible
  if (! error.response) {
    displayError('Service indisponible, merci de revenir dans quelques minutes');
    return Promise.reject(error);
  }

  if (error.response.status !== 500) {
    error.response.status !== 422 ? displayError(
      error.response.data['hydra:description']
      || error.response.data.message
      || 'Une erreur s\'est produite',
    ) : Promise.reject(error);
  }

  if (error.response) {
    displayError(
      error.response.data.message
      || 'Une erreur s\'est produite',
    );
    return Promise.reject(error);
  }
  return Promise.reject(error);
};
