<template>
  <article
    v-test="'vaccine'"
    class="consultation-vaccines-list__vaccine mb-3"
  >
    <header class="consultation-vaccines-list__vaccine-element">
      <app-icon
        color="secondary"
        icon="syringe"
        tooltip="Vaccin"
      />
      <span
        v-test="'vaccine-name'"
        class="subtitle-3"
      >
        {{ vaccine.name }}
      </span>
      <span
        v-test="'vaccine-date'"
        class="secondary--text"
      >
        {{ getVaccineFormattedDate(vaccine) }}
      </span>
      <span
        v-if="vaccine.boosterDate"
        class="secondary--text"
      >
        -
      </span>
      <span
        v-if="vaccine.boosterDate"
        v-test="'vaccine-booster-date'"
        class="secondary--text font-weight-bold"
      >
        {{ getVaccineFormattedBoosterDate(vaccine.boosterDate) }}
      </span>
    </header>
    <section class="consultation-vaccines-list__vaccine-element">
      <span
        v-test="'vaccine-cip-code'"
        class="secondary--text"
      >
        Code CIP : {{ vaccine.cipCode }}
      </span>
      <span class="secondary--text">
        -
      </span>
      <span
        v-test="'vaccine-lot-number'"
        class="secondary--text"
      >
        Numéro de lot : {{ vaccine.lotNumber }}
      </span>
      <span class="secondary--text">
        -
      </span>
      <span
        v-test="'vaccine-expiry-date'"
        class="secondary--text"
      >
        {{ getVaccineFormattedExpiryDate(vaccine.expiryDate) }}
      </span>
    </section>
  </article>
</template>

<script>
import ConsultationVaccine from '@/modules/patient/models/ConsultationVaccine';
import NovaTools from '@/nova-tools/NovaTools';

export default {
  name: 'ConsultationVaccineItem',
  props: {
    vaccine: {
      type: ConsultationVaccine,
      required: true,
    },
  },
  methods: {
    getVaccineFormattedDate (vaccine) {
      if (vaccine.vaccinationMonth) {
        const vaccinationDateString = `${vaccine.vaccinationYear}-${vaccine.vaccinationMonth}-${vaccine.vaccinationDay}`;
        const vaccinationDate = `Administré le ${NovaTools.dates.format(vaccinationDateString)}`;
        if (vaccine.vaccinationTime) {
          return `${vaccinationDate} à ${vaccine.vaccinationTime}`;
        }
        return vaccinationDate;
      }
      return `Vaccin administré en ${vaccine.vaccinationYear}`;
    },
    getVaccineFormattedBoosterDate (vaccineBoosterDate) {
      return `Rappel en ${NovaTools.dates.format(vaccineBoosterDate, 'MMMM yyyy')}`;
    },
    getVaccineFormattedExpiryDate (vaccineExpiryDate) {
      return `Date d'expiration : ${NovaTools.dates.format(vaccineExpiryDate)}`;
    },
  },
};
</script>

<style scoped lang="scss">
.consultation-vaccines-list {
  &__vaccine {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 1);
    align-items: flex-start;
  }
  &__vaccine-element {
    display: flex;
    flex-direction: row;
    gap: map-get($spacers, 3);
    align-items: flex-start;
  }
  &__vaccines-display {
    user-select: none;
    cursor: pointer;
  }
}
</style>