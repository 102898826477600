/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientNonDrugAllergyEntity = new DocumentEntity(
  'PatientNonDrugAllergy',
  'patientNonDrugAllergies.xml',
  'Contient les allergies non médicamenteuses des patients',
);

patientNonDrugAllergyEntity.addField(new DocumentEntityField('label', 'Le label'));
patientNonDrugAllergyEntity.addField(new DocumentEntityField('note', 'La note'));

export default patientNonDrugAllergyEntity;