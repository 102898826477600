const TIME_UNITS = {
  MONTH: {
    label: 'mois',
    yearCoefficient: 1 / 12,
  },
  YEAR: {
    label: 'années',
    yearCoefficient: 1,
  },
};

/**
 * Représente les données concernant chacunes des tranches d'ages du carnet de santé.
 * un coefficient est appliqué de façon à connaitre l'unité de la variation min/max
 * par rapport à une année
 */
export const HEALTH_BOOK_AGE_RANGES = {
  YOUNG_CHILD: {
    categoryName: 'youngChild',
    categoryLabel: '1 mois à 3 ans',
    unit: TIME_UNITS.MONTH,
    value: [1, 36],
    axisValue: [0, 36],
    tickStepSize: 2,
  },
  YOUNG_CHILD_EXTENDED: {
    categoryName: 'youngChildExtended',
    categoryLabel: '1 mois à 5 ans',
    unit: TIME_UNITS.MONTH,
    value: [1, 60],
    axisValue: [0, 60],
    tickStepSize: 2,
  },
  MINOR_CHILD: {
    categoryName: 'minorChild',
    categoryLabel: '1 an à 18 ans',
    unit: TIME_UNITS.YEAR,
    value: [1, 18],
    axisValue: [0, 18],
    tickStepSize: 1,
  },
};