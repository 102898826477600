import { BASE_ROUTE_NAMES } from '@/constants/baseRouteNames';
import { PARTNER_PAGE_ROUTES } from '@/constants/partnerPageRoutes';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, postToAPI } from '@/services/api';
import store from '@/store/instance';
import { getEnv } from '@/utils/functions/env';

/* Obligation to keep "this" for tests https://stackoverflow.com/questions/45111198/how-to-mock-functions-in-the-same-module-using-jest */

export function isPartnerAccess () {
  return getEnv('VUE_APP_PARTNER_ENV') === 'true';
}

export function isPartnerAccessLoggedOut () {
  if (! isPartnerAccess()) {
    return false;
  }
  const isPartnerAccessLoggedOut = localStorage.getItem('isPartnerAccessLoggedOut');

  return (isPartnerAccessLoggedOut !== null) ? JSON.parse(isPartnerAccessLoggedOut) : false;
}

export function setPartnerAccessLoggedOut (isPartnerAccessLoggedOut) {
  localStorage.setItem('isPartnerAccessLoggedOut', JSON.stringify(isPartnerAccessLoggedOut));
}

export function removeLocalPartnerAccessLoggedOut () {
  localStorage.removeItem('isPartnerAccessLoggedOut');
}

export function isPartnerAuthentication (to) {
  if (! isPartnerAccess()) {
    return false;
  }

  return (to.path === '/' && !!to.query?.partnerKey);
}

export async function searchPatientUuid (patientEncryptedCriterias) {
  try {
    const { data } = await getFromAPI(
      '/api/v1/partner/search_patient', { data: patientEncryptedCriterias }, { errorHandle: false });
    return data;
  } catch(e) {
    return null;
  }
}

export async function searchPatient (patientEncryptedCriterias) {
  try {
    const { patientId: patientUuid } = await searchPatientUuid(patientEncryptedCriterias);
    await store.dispatch('patient/fetchOnePatient', patientUuid);
    return store.getters['patient/getPatientByUUID'](patientUuid);
  } catch(e) {
    return null;
  }
}

export async function routePartnerPatientLap (patientData) {
  try {
    const { data } = await postToAPI(
      '/api/v1/partner/create_consultation_lap',
      { payload: decodeURIComponent(patientData) },
      { errorHandle: false },
    );
    return {
      name: ROUTE_NAMES.CREATE_PRESCRIPTION,
      params: {
        uuid: data.patientId,
        consultationUUID: data.consultationId,
      },
    };
  } catch(e) {
    return {
      name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
      params: { message: 'Patient ou lieu d\'activité non connu' },
    };
  }
}

export async function routePartnerPatientInsAccess (patientIns, page) {
  try {
    if (page === PARTNER_PAGE_ROUTES.LAP) {
      const routeLap = await routePartnerPatientLap(patientIns);
      if (Object.keys(routeLap).length > 0) {
        return routeLap;
      }
    }
    const data = await searchPatientUuid(patientIns);
    if (data.patientId && data.patientId !== null) {
      return {
        name: page === PARTNER_PAGE_ROUTES.PATIENT_FILE ? ROUTE_NAMES.PATIENT_FILE : ROUTE_NAMES.PATIENT_EDIT,
        params: { uuid: data.patientId },
      };
    }
  } catch(e) { /* empty */ }

  return { name: ROUTE_NAMES.PATIENT_CREATE };
}

export async function routePartnerPatientIdentityAccess (patientIdentity, page) {
  let patientIdentityDecrypted = {};
  try {
    if (page === PARTNER_PAGE_ROUTES.LAP) {
      const routeLap = await routePartnerPatientLap(patientIdentity);
      if (Object.keys(routeLap).length > 0) {
        return routeLap;
      }
    }
    const data = await searchPatientUuid(patientIdentity);
    if (data.transactionId) {
      store.commit('patient/SET_PARTNER_TRANSACTION_ID', data.transactionId);
    }
    if (data.patientId) {
      return {
        name: page === PARTNER_PAGE_ROUTES.PATIENT_FILE ? ROUTE_NAMES.PATIENT_FILE : ROUTE_NAMES.PATIENT_EDIT,
        params: { uuid: data.patientId },
      };
    }
    if (data.birthName && data.firstName && data.birthDate && data.birthPlaceCode && data.gender) {
      const dataDecrypted = new URLSearchParams({
        ...data,
        familyName: data.birthName,
        firstNames: data.firstName,
      });
      patientIdentityDecrypted = btoa(dataDecrypted.toString());
    }
  } catch(e) {
    NovaTools.notify.error('Données d\'accès incorrectes, redirection sur le formulaire de création patient');
  }

  return {
    name: ROUTE_NAMES.PATIENT_CREATE,
    params: { patientIdentity: patientIdentityDecrypted },
  };
}

export async function routePartnerDmpAccess (patientData) {
  const patient = await searchPatient(patientData);
  if (patient === null) {
    return {
      name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
      params: { message: 'Données patient inconnues, merci de créer la fiche patient avant accès au DMP' },
    };
  }
  if (patient.insIdentity?.isValidated()) {
    NovaTools.icanopee.efficience.openModalIframe(patient);
    return null;
  }

  return {
    name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
    params: { message: 'Le status INS actuel du patient ne permet pas l\'accès au DMP' },
  };
}

export function getRoutePartnerAccess (to) {
  const { query } = to;

  if (! Object.values(PARTNER_PAGE_ROUTES).includes(query.page)) {
    return { name: 'homePage' };
  }

  if (query.page === PARTNER_PAGE_ROUTES.DMP) {
    return routePartnerDmpAccess(query.patientIns ?? query.patientIdentity);
  }
  if (query.patientIns) {
    return routePartnerPatientInsAccess(query.patientIns, query.page);
  }
  if (query.patientIdentity) {
    return routePartnerPatientIdentityAccess(query.patientIdentity, query.page);
  }

  return { name: 'homePage' };
}

export function postMessageToPartner (message) {
  if(isPartnerAccess()){
    window.parent.postMessage({ message }, '*');
  }
}

export function postPatientToPartner (patient) {
  if (patient) {
    const message = {
      ins: patient.insIdentity?.ins,
      oid: patient.insIdentity?.oid,
      status: patient.insIdentity?.status,
      gender: patient.gender,
      civility: patient.civility,
      birthName: patient.birthName,
      familyName: patient.familyName,
      firstName: patient.firstName,
      firstNames: patient.firstNames,
      usedFirstName: patient.usedFirstName,
      birthDate: patient.birthDate,
      birthPlaceCode: patient.birthPlaceCode,
    };
    postMessageToPartner(message);
  }
}

export function postErrorToPartner (error) {
  if(isPartnerAccess()){
    window.parent.postMessage({ error }, '*');
  }
}
