<template>
  <patient-details
    :patient="metaDataset.getPatient()"
    x-small
    :show-anonymized-identifier="false"
    :show-pictograms="false"
  >
    <template #sub-content>
      <app-dataset-item
        v-test="'ins'"
        class="mt-n1"
        label="INS"
        :value="patientIns"
      />
    </template>
  </patient-details>
</template>

<script>
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';


export default {
  name: 'DocumentMetaDatasetRelatedPatientDetails',
  components: {
    AppDatasetItem,
    PatientDetails,
  },
  props: {
    metaDataset: {
      type: MssDocumentMetaDataset,
      required: true,
    },
  },
  computed: {
    patientIns () {
      return this.metaDataset.getPatient().insIdentity?.ins;
    },
  },
};
</script>