<template>
  <p
    class="app-dataset-item mb-0"
    :class="{ 'app-dataset-item--vertical': vertical }"
    data-test="dataset-item"
  >
    <span
      v-if="loading"
      data-test="dataset-item-skeleton"
    >
      <app-skeleton-loader
        type="text"
        class="d-inline-block pr-2"
        width="20%"
      />
      <app-skeleton-loader
        type="text"
        class="d-inline-block"
        width="80%"
      />
    </span>
    <span
      v-else
      class="app-dataset-item__content"
    >
      <span
        class="app-dataset-item__label"
        data-test="dataset-item-label"
      >
        {{ label }} {{ separatorChar }}
      </span>
      <span data-test="dataset-item-value">
        <slot :value="displayedValue">{{ displayedValue }}</slot>
      </span>
    </span>
  </p>
</template>

<script>
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';

/**
 * Représente un couple label/valeur
 */
export default {
  name: 'AppDatasetItem',
  components: { AppSkeletonLoader },
  props: {
    /**
     * Le label à afficher
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * La valeur du jeu de donnée
     */
    value: {
      type: [String, Number],
      default: null,
    },
    /**
     * Affiche le label et sa valeur de manière verticale
     */
    vertical: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le composant dans son état de chargement
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Phrase à afficher en cas de valeur null
     * @default 'Non renseigné(e)'
     */
    emptySentence: {
      type: String,
      default: '-',
    },
    /**
     * Caractère à afficher entre le label et la valeur
     * @default ':'
     */
    separatorChar: {
      type: String,
      default: ':',
    },
  },
  computed: {
    displayedValue () {
      if (this.value === null) {
        return this.emptySentence;
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .app-dataset-item {
    font-size: 13px;

    &__content {
      display: block;
    }

    &--vertical &__content &__label {
      display: block;
      margin-right: 0;
      font-size: 12px;
    }

    &__label {
      margin-right: map-get($spacers, 1);
      color: var(--v-secondary-base);
    }
  }
</style>