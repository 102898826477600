/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientMeasureEntity = new DocumentEntity('PatientMeasure', 'patientMeasures.xml', 'Contient les mesures biométriques des patients');

patientMeasureEntity.addField(new DocumentEntityField('name', 'Le nom'));
patientMeasureEntity.addField(new DocumentEntityField('value', 'La valeur'));

export default patientMeasureEntity;