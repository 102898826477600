const DEFAULT_CELL_HEIGHT = 48;

export default {
  data () {
    return {
      cellHeight: DEFAULT_CELL_HEIGHT,
      observer: null,
    };
  },
  mounted () {
    this.initObserver();
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    /**
     * Observe le changement de taille de l'agenda et met à jours
     * la propriété cellHeight en conséquence.
     */
    initObserver () {
      const observer = new ResizeObserver(this.setCellHeight);
      this.$nextTick(() => {
        const dayBody = this.$refs.calendarWrapper.querySelector('.v-calendar-daily__body');
        observer.observe(dayBody);
        this.observer = observer;
      });
    },
    setCellHeight () {
      if (! this.$refs.calendarWrapper) {
        return;
      }

      this.$nextTick(() => {
        const cell = this.$refs.calendarWrapper.querySelector(
          '.v-calendar-daily__day-interval',
        );
        if (cell) {
          this.cellHeight = cell.getBoundingClientRect().height;
        }
      });

    },
    resetObserver () {
      if (this.observer) {
        this.observer.disconnect();
      }
      this.initObserver();
    },
  },
};