<template>
  <div>
    <patient-sidebar-panel-listing
      title="Allergies non médicamenteuses"
      create-label="Ajouter une allergie non médicamenteuse"
      edit-label="Modifier une allergie non médicamenteuse"
      risk-factor-name-key="allergy"
      :items="nonDrugAllergies"
      :form="NonDrugAllergyForm"
      :loading="isLoadingNonDrugAllergies && !hasLoadedNonDrugAllergies"
      :patient="patient"
      no-data-text="Aucune donnée"
      :item-model-class="PatientNonDrugAllergy"
    >
      <template #item="{ item, edit }">
        <patient-sidebar-risk-factor
          :risk-factor="item"
          :actions="getActions(item, edit)"
        />
      </template>
    </patient-sidebar-panel-listing>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem';
import PatientNonDrugAllergy from '@/modules/patient/models/healthEntity/PatientNonDrugAllergy';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue';
import NonDrugAllergyForm from '@/modules/patient/components/patientFile/riskFactors/nonDrugAllergies/NonDrugAllergyForm.vue';
import PatientSidebarRiskFactor from '@/modules/patient/components/PatientSidebarRiskFactor.vue';

export default {
  name: 'NonDrugAllergiesListing',
  components: {
    PatientSidebarPanelListing,
    PatientSidebarRiskFactor,
  },
  props: {
    patient: {
      type: Patient,
      default: () => new Patient(),
    },
  },
  data () {
    return {
      NonDrugAllergyForm,
      PatientNonDrugAllergy,
      deletingNonDrugAllergy: null,
    };
  },
  computed: {
    ...mapGetters('patient', ['getPatientNonDrugAllergies', 'isLoadingNonDrugAllergies', 'getHasLoadedNonDrugAllergiesByPatientUuid']),
    nonDrugAllergies () {
      return this.getPatientNonDrugAllergies(this.patient.getUuid());
    },
    hasLoadedNonDrugAllergies () {
      return this.getHasLoadedNonDrugAllergiesByPatientUuid(this.patient.getUuid());
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id']) {
          this.fetchPatientNonDrugAllergies(this.patient.getUuid());
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchPatientNonDrugAllergies', 'deletePatientNonDrugAllergy']),
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({ callback: () => editMethod(item) }),
        new DeleteActionMenuItem({
          callback: async () => {
            await this.deletePatientNonDrugAllergy(item);
            NovaTools.notify.success('L\'allergie a été supprimée avec succès');
          },
          options: {
            confirmTitle: 'Supprimer l\'allergie',
            confirmText: `Êtes-vous sûr(e) de vouloir supprimer l'allergie ${item?.label} ?`,
          },
        })];
    },
  },
};
</script>