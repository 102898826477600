<template>
  <app-navigation-drawer
    v-model="showNavigationDrawer"
    v-bind="{...$props, ...$attrs}"
    class="drawer-modal-form"
    temporary
    overlay-color="text"
    :title="title"
    scrollable
    data-test="drawer-modal-form"
    @cancel="cancelForm"
    @transitionend="onTransitionend"
  >
    <app-form-wrapper
      ref="formWrapper"
      :value="value"
      class="drawer-modal-form__wrapper d-flex flex-column flex-grow-1"
      :form="form"
    >
      <template #default="{ localValue, formValidation, isSubmitting, formId }">
        <component
          :is="form"
          :id="formId"
          ref="form"
          data-test="form"
          :value="localValue"
          v-bind="$attrs"
          @submitForm="(submitMethod) => submitForm($refs.formWrapper, submitMethod)"
        />
        <v-card-actions class="pa-4 px-md-8 py-md-5">
          <slot name="actions-left" />
          <v-spacer />
          <slot
            name="actions"
            v-bind="{ localValue, formValidation, isSubmitting, formId, cancelColor, cancelText, submitColor, submitText }"
          >
            <app-button
              class="mr-2"
              text
              :color="cancelColor"
              @click="$emit('cancel')"
            >
              {{ cancelText }}
            </app-button>
            <app-button
              :color="submitColor"
              :loading="isSubmitting"
              :disabled="!formValidation.valid"
              type="submit"
              :form="formId"
              data-test="app-modal-form-submit-button"
              @click="$emit('submit')"
            >
              {{ submitText }}
            </app-button>
          </slot>
        </v-card-actions>
      </template>
    </app-form-wrapper>
  </app-navigation-drawer>
</template>

<script>
import ModalFormMixin from './mixins/ModalFormMixin';
import AppNavigationDrawer from '../drawer/AppNavigationDrawer.vue';

import AppFormWrapper from '@/components/ui/form/AppFormWrapper.vue';

export default {
  name: 'DrawerModalForm',
  components: {
    AppFormWrapper,
    AppNavigationDrawer,
  },
  mixins: [ModalFormMixin],
  props: {
    /**
     * Constructeur du composant (fichier .vue, ou créé programatiquement)
     */
    form: {
      type: [String, Object],
      required: true,
    },
    /**
     * Modèle utilisé pour remplir le formulaire
     * Si le modèle est non null, la modale devient alors visible
     * @model
     */
    value: {
      type: Object,
      default: null,
    },
    /**
     * Intitulé de la modale
     */
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    showNavigationDrawer: {
      get () {
        return this.value !== null;
      },
      set (isOpen) {
        if (! isOpen) {
          this.cancelForm();
        }
      },
    },
  },
  methods: {
    onTransitionend () {
      if (this.autoFocus && this.showNavigationDrawer) {
        this.formMixin_focusForm();
      }
    },
    cancelForm () {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-navigation-drawer {
    &__content {
      flex-direction: column;
      display: flex;
      overflow: hidden;
    }
  }

  .drawer-modal-form {
    &__back-button {
      background-color: #f5f8fa !important;
    }

    &__content {
      flex-direction: column;
      display: flex;
    }

    &__headline {
      font-size: 20px;
      font-weight: 300;
    }

    &__header {
      display: flex;
    }

    &__body {
      flex-direction: column;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-basis: 0;
    }

    &__footer {
      display: flex;
    }
  }
}
</style>