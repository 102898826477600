<template>
  <div
    v-if="birthPlaceCode"
    class="birth-place-field"
  >
    <div class="birth-place-field__title">
      Code lieu de naissance {{ isRequired }}
    </div>
    <div class="birth-place-field__value">
      <birth-place-field-item
        :disabled="disabled"
        :birth-place="getBirthPlaceListItemObject(birthPlaceCode,birthPlaceLabel)"
      />
      <app-icon
        v-if="! disabled"
        icon="close"
        @click="reset"
      />
    </div>
  </div>
  <div v-else>
    <app-api-autocomplete
      url="/api/insee_geographic_code_refs"
      label="Code lieu de naissance"
      :placeholder="fieldPlaceholder"
      :prepend-icon="null"
      return-object
      :rules="rules"
      :selected.sync="selectedBirthPlace"
      :item-model-class="InseeGeographicCodeRef"
      item-value-field="value"
      :item-text-field="getBirthPlaceItemText"
      :no-filter="false"
      :disabled="disabled"
      clearable
      name="birthPlaceCode"
    >
      <template #item="{ data }">
        <v-list-item-title>
          <birth-place-field-item :birth-place="data.item" />
        </v-list-item-title>
      </template>
      <template #selection="{ data }">
        <birth-place-field-item :birth-place="data.item" />
      </template>
    </app-api-autocomplete>
  </div>
</template>

<script>

import { INSEE_UNKNOWN_REF } from '@/modules/patient/constants';
import InseeGeographicCodeRef from '@/modules/patient/models/InseeGeographicCodeRef';
import { getBirthPlaceFullLabel } from '@/modules/patient/utils/getBirthPlaceFullLabel';

import AppApiAutocomplete from '@/components/ui/form/apiAutocomplete/AppApiAutocomplete.vue';
import BirthPlaceFieldItem from '@/modules/patient/components/patientForm/BirthPlaceFieldItem.vue';

export default {
  name: 'BirthPlaceField',
  components: {
    AppApiAutocomplete,
    BirthPlaceFieldItem,
  },
  props: {
    birthPlaceCode: {
      type: String,
      default: null,
    },
    birthPlaceLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      InseeGeographicCodeRef,
      searchText: null,
    };
  },
  computed: {
    selectedBirthPlace: {
      get () {
        if (this.birthPlaceCode) {
          return this.getBirthPlaceListItemObject(this.birthPlaceCode, this.birthPlaceLabel);
        }
        return null;
      },
      set (birthPlace) {
        const birthPlaceCode = birthPlace?.value || null;
        const birthPlaceLabel = birthPlace?.label || null;
        this.updateBirthPlace(birthPlaceCode, birthPlaceLabel);
      },
    },
    isRequired () {
      return this.rules?.includes('required') ? ' *' : '';
    },
    cachedBirthPlaces () {
      const hasNotFoundBirthPlace = !! this.birthPlaceCode && ! this.birthPlaceLabel;
      if (hasNotFoundBirthPlace || this.searchText?.length === 5) {
        return [this.getBirthPlaceListItemObject(hasNotFoundBirthPlace ? this.birthPlaceCode : this.searchText, null)];
      }
      return [this.getBirthPlaceListItemObject(INSEE_UNKNOWN_REF.value, INSEE_UNKNOWN_REF.label)];
    },
    fieldPlaceholder () {
      return `${INSEE_UNKNOWN_REF.value} si ${INSEE_UNKNOWN_REF.label.toLowerCase()}`;
    },
  },
  watch: {
    birthPlaceCode (birthPlaceCode) {
      this.searchText = birthPlaceCode;
    },
  },
  methods: {
    /**
     * Permet de rechercher un lieu par code ainsi que par son label
     * Cette méthode est déclarée dans la partie "methods" plutôt qu'en usage direct (:item-text="item => fonction de comparaison")
     * Car cela provoque le clear instantané de la recherche si un .sync lui est associée.
     * Bug Vuetify @see https://github.com/vuetifyjs/vuetify/issues/6635
     */
    getBirthPlaceItemText (birthPlace) {
      return `${birthPlace.value} ${birthPlace.label}`;
    },
    getBirthPlaceListItemObject (code, label) {
      return new InseeGeographicCodeRef({
        value: code,
        label: code ? getBirthPlaceFullLabel(code, label) : null,
      });
    },
    updateBirthPlace (code, label) {
      this.$emit('update:birthPlaceCode', code);
      this.$emit('update:birthPlaceLabel', label);
    },
    reset () {
      this.updateBirthPlace(null, null);
    },
  },
};
</script>
<style lang="scss" scoped>
  .birth-place-field {
    height: 100%;
    border-bottom: 1px solid var(--v-secondary-lighten4);
    box-sizing: content-box;

    &__title {
      font-size: 9px;
      color: #37467a99 ;
    }

    &__value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 4px;
    }
  }
</style>