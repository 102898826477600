<template>
  <v-list-item
    class="app-list-item"
    active-class="app-list-item--active"
    :to="normalizedRoute"
  >
    <app-icon
      v-if="icon"
      class="app-list-item__icon test"
      :icon="icon"
      data-test="list-item-icon"
    />
    <p
      class="app-list-item__title"
      data-test="list-item-title"
    >
      {{ title }}
    </p>
    <p
      v-if="hint"
      class="app-list-item__hint"
      data-test="list-item-hint"
    >
      {{ hint }}
    </p>
  </v-list-item>
</template>

<script>
/**
 * L'élément de menu d'une liste de l'application
 * Wrapper du composant vuetify @see https://vuetifyjs.com/en/api/v-list-item/}
 */
export default {
  name: 'AppListItem',
  props: {
    /**
     * Le titre de l'élément de la liste
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Affiche un icône pour l'élément de la liste
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Affiche une anotation pour l'élément de la liste
     */
    hint: {
      type: [String, Number],
      default: null,
    },
    /**
     * le nom de la route ou la route complète vers laquelle l'élément de la liste redirigera
     */
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    normalizedRoute () {
      if (this.to) {
        return typeof this.to === 'string' ? { name: this.to } : this.to;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
$list-item-default-height: 36px;

.app-list-item {
  display: flex;
  align-items: center;
  min-height: $list-item-default-height;
  background-color: var(--v-secondary-lighten5);

  &--active {
    color: var(--v-primary-base);
  }

  &__icon {
    margin-right: map-get($spacers, 3);
  }

  &__title {
    font-size: 13px;
    font-weight: 600;
    flex-grow: 1;
  }

  &__hint {
    font-size: 13px;
    font-weight: 500;
  }
}
</style>