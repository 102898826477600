<template>
  <n-tooltip
    v-slot="{ on }"
    :text="disabledTooltip"
    :disabled="! disabled"
  >
    <span class="n-radio-button__wrapper" v-on="on">
      <v-radio
        :value="value"
        :color="color"
        :label="label"
        :disabled="disabled"
        :ripple="false"
      />
    </span>
  </n-tooltip>
</template>

<script>
/**
 * Composant bouton radio
 */
export default {
  name: 'NRadioButton',
  props: {
    value: {
      type: [String, Boolean],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.n-radio-button__wrapper {
  max-width: fit-content;
}
</style>