import ApiEntity from '@/models/ApiEntity';
import { getFromAPI } from '@/services/api';

/**
 * @typedef {Object} GrandRegimeType
 * @property {String} code
 * @property {String} libelle
 */
export default class GrandRegime extends ApiEntity {

  /**
   * @param {GrandRegimeType} options
   */
  constructor (options = {}) {
    super(options);
    this.code = options.code || null;
    this.libelle = options.libelle || null;
  }

  /**
   * Permet de récupérer l'ensemble des régimes de la CV depuis l'API
   * @returns {GrandRegime[]}
   */
  static async fetchAll () {
    const { data } = await getFromAPI('/api/grand_regime_refs');
    return data['hydra:member'].map(grandRegime => new GrandRegime(grandRegime));
  }
}