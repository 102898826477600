<template>
  <form @submit.prevent="submitMethod ? submit() : null">
    <slot v-bind="{ submit }" />
  </form>
</template>

<script>
/**
* Composant wrapper de formulaire de l'application. Il permet la capture automatique
* de l'évènement de soumission et transmet l'information au wrapper parent.
* Il faut pour cela utiliser app-form :submitMethod="submit" au lieu de form v-on:submit="submit"
* */
export default {
  name: 'AppForm',
  props: {
    /**
     * Méthode qui sera appelée en cas de soumission du formulaire
     */
    submitMethod: {
      type: Function,
      default: null,
    },
  },
  methods: {
    submit () {
      this.$parent.$emit('submitForm', this.submitMethod);
    },
  },
};
</script>