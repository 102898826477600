<template>
  <article class="terms-of-use-view">
    <app-container class="terms-of-use-view__content">
      <main class="terms-of-use-view__content__text">
        <h1 class="title-1">
          Conditions générales d'utilisation
        </h1>
        <n-divider :spacing="6" />
        <section v-sanitize="gcuContent" />
      </main>
      <footer
        v-if="! isCurrentUserAcceptedGcu"
        v-test="'gcu-actions'"
        class="terms-of-use-view__content__footer"
      >
        <app-button
          v-test="'refuse-gcu-button'"
          color="content"
          text
          @click="refuseGcu"
        >
          Refuser
        </app-button>
        <app-button
          v-test="'accept-gcu-button'"
          color="primary"
          @click="acceptGcu"
        >
          Accepter et continuer
        </app-button>
      </footer>
    </app-container>
  </article>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';

import { patchToAPI } from '@/services/api';
import gcuService from '@/services/gcuService';
import { getRoutePartnerAccess } from '@/services/partnerService';

import AppContainer from '@/components/ui/container/AppContainer.vue';

export default {
  name: 'TermsOfUse',
  components: { AppContainer },
  computed: {
    ...mapGetters('auth', ['getCurrentUser', 'getGcu', 'getAuthPayload', 'isPartnerAccess']),
    isCurrentUserAcceptedGcu () {
      return this.getGcu?.accepted && this.getGcu?.accepted === true;
    },
    gcuContent () {
      return this.getGcu?.content.replace(/\\/g, '');
    },
  },
  methods: {
    ...mapActions('auth', ['logout_nocgu']),
    ...mapMutations('auth', ['TOKEN_REFRESH']),
    ...mapActions('users', ['updateContact']),
    ...mapActions('agenda', ['fetchAgendaDatas']),
    ...mapActions('app', ['getAppDatas']),
    async acceptGcu () {
      await patchToAPI(`${this.getCurrentUser['@id']}/gcu`, { data: { acceptedGcu: this.getGcu.id } });
      this.TOKEN_REFRESH({
        ...this.getAuthPayload,
        gcu: {
          ...this.getAuthPayload.gcu,
          accepted: true,
        },
      });
      localStorage.removeItem('gcuValidationNeeded');
      await Promise.all([
        this.getAppDatas(),
        this.fetchAgendaDatas(),
      ]);
      if (this.isPartnerAccess) {
        this.$router.push(await getRoutePartnerAccess(gcuService.getGcuPartnerPayload()) ?? '/');
        gcuService.removeGcuPartnerPayload();
        return;
      }
      this.$router.push('/');
    },
    refuseGcu () {
      this.logout_nocgu();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-of-use-view {
  height: 100%;
  overflow: auto;

  &__content {
    padding: map-get($spacers, 6) map-get($spacers, 6) 0 map-get($spacers, 6) !important;
    position: relative;
    height: 100%;

    &__footer {
      position: sticky;
      bottom: 0;
      padding: map-get($spacers, 6) 0;
      display: flex;
      justify-content: flex-end;
      gap: map-get($spacers, 4);
      background-color: var(--v-secondary-lighten5);
    }
  }
}
</style>