<template>
  <span
    v-tooltip="tooltipConfig"
    class="d-inline-block"
  >
    <app-checkbox
      v-test="'mss-sending-checkbox'"
      :value="value"
      label="Envoyer par messagerie sécurisée de santé au patient"
      :disabled="isDisabled"
      class="flex-grow-1"
      @change="$emit('input', $event)"
    />
  </span>
</template>

<script>
import Patient from '@/modules/patient/models/Patient';
import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService';

import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';

/**
 * Case à cocher qui permet d'activer l'envoi via MSS d'un document
 * Activera la case à cocher selon les prérequis
 * Affichera le bon message selon les prérequis
 */
export default {
  name: 'SendByMssOption',
  components: { AppCheckbox },
  props: {
    /**
     * La valeur du choix de l'utilisateur
     * True si envoi via MSS activé
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Le patient concerné par l'envoi MSS
     * Requis pour vérifier le consentement
     */
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: {
    tooltipConfig () {
      return {
        disabled: ! this.isDisabled,
        text: this.disabledMessage,
      };
    },
    isPatientIdentityQualified () {
      return this.patient.insIdentity.isQualified();
    },
    isDisabled () {
      return ! AuthenticationServiceInstance.isAuthenticated || ! this.isPatientIdentityQualified || ! this.patient.mssDocumentConsent;
    },
    disabledMessage () {
      if (! this.isDisabled) {
        return null;
      }
      if (! AuthenticationServiceInstance.isAuthenticated) {
        return 'Non connecté à la messagerie';
      }
      if (! this.isPatientIdentityQualified) {
        return 'Ce patient n\'a pas le statut INS qualifié';
      }
      if (! this.patient.mssDocumentConsent) {
        return 'Le patient ne consent pas à l\'envoi de document sur sa MSS';
      }
      return null;
    },
  },
};
</script>