import Address from '@/models/Address';
import ContactPoint from '@/models/ContactPoint';
import Person from '@/models/Person';
import { PATIENT_RELATIONS } from '@/modules/patient/constants';

export default class PatientRelative extends Person {
  constructor (options = {}) {
    super(options);

    this.link = options.link || null;
    this.note = options.note || null;
    this.patient = options.patient || null;
    this.contactPoints = options.contactPoints ? options.contactPoints?.map(contactPoint => new ContactPoint(contactPoint)) : [];
    this.address = options.address ? new Address(options.address) : new Address();
  }

  getLinkLabel () {
    return this.link ? PATIENT_RELATIONS[this.link.toUpperCase()].label : null;
  }
}