export const WEEKLY_DAYS = Object.freeze([{
  name: 'Lu',
  value: 'MO',
  index: 1,
}, {
  name: 'Ma',
  value: 'TU',
  index: 2,
}, {
  name: 'Me',
  value: 'WE',
  index: 3,
}, {
  name: 'Je',
  value: 'TH',
  index: 4,
}, {
  name: 'Ve',
  value: 'FR',
  index: 5,
}, {
  name: 'Sa',
  value: 'SA',
  index: 6,
}, {
  name: 'Di',
  value: 'SU',
  index: 0,
}]);