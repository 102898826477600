<template>
  <app-form :submit-method="saveDrugAllergy">
    <drug-allergy-autocomplete
      v-model="selectedDrugAllergy"
      v-test="'drug-allergy-form-label-field'"
      class="mb-2"
      :rules="{ required: true, excluded: forbiddenDrugAllergiesLabel }"
    />
    <app-textarea-field
      v-model="localDrugAllergy.note"
      v-test="'drug-allergy-form-note-field'"
      label="Commentaire (optionnel)"
      hide-errors="auto"
      rows="1"
      auto-grow
    />
  </app-form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import PatientDrugAllergy from '@/modules/patient/models/healthEntity/PatientDrugAllergy';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import DrugAllergyAutocomplete from '@/components/lap/DrugAllergyAutocomplete.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';

export default {
  name: 'DrugAllergyForm',
  components: {
    AppForm,
    DrugAllergyAutocomplete,
    AppTextareaField,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      localDrugAllergy: null,
      selectedDrugAllergy: null,
    };
  },
  computed: {
    ...mapGetters('patient', ['getPatientDrugAllergies']),
    isEditing () {
      return !! this.localDrugAllergy?.['@id'];
    },
    forbiddenDrugAllergiesLabel () {
      const patientDrugAllergies = this.getPatientDrugAllergies(this.patient.getUuid());
      if (this.isEditing) {
        return patientDrugAllergies.filter(allergy => allergy['@id'] !== this.localDrugAllergy['@id']).map(drugAllergy => drugAllergy.label);
      }
      return patientDrugAllergies.map(drugAllergy => drugAllergy.label);
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        this.localDrugAllergy = new PatientDrugAllergy(value ? {
          ...value,
          patient: this.patient['@id'],
        } : { patient: this.patient['@id'] });
        this.selectedDrugAllergy = { label: value?.label };
      },
    },
    'selectedDrugAllergy.label': function updateDrugAllergyLabel (newAllergyLabel) {
      this.localDrugAllergy.label = newAllergyLabel;
    },
  },
  methods: {
    ...mapActions('patient', ['insertPatientDrugAllergy', 'updatePatientDrugAllergy']),
    async saveDrugAllergy () {
      try {
        const message = `L'allergie médicamenteuse a été ${this.isEditing ? 'modifiée' : 'ajoutée'} avec succès`;
        const action = this.isEditing ? 'updatePatientDrugAllergy' : 'insertPatientDrugAllergy';

        await this[action](this.localDrugAllergy);
        NovaTools.notify.success(message);
      } catch (error) {
        this.handleSaveError(error);
      }
    },
    handleSaveError (error) {
      let message = 'Une erreur est survenue';
      let options = {};

      if (this.isHydraError(error)) {
        message = error.response.data['hydra:description'];
        options = { title: message };
      }
      if (typeof error === 'string') {
        message = error;
      }

      NovaTools.notify.error(message, options);
    },
    isHydraError (error) {
      return typeof error === 'object' && error.response?.data?.['hydra:description'];
    },
  },
};
</script>