<template>
  <n-dialog
    :is-open.sync="localIsOpen"
    title="Accusé de réception"
    width="720"
    divided
    persistent
    :actions="modalActions"
    class="aati-result-modal"
  >
    <n-alert
      transparent
      type="success"
      title="Transmission réussie"
      :message="successAlertText"
    />
    <app-panel
      v-if="employerSheetExists"
      is-outlined
      is-flat
      class="mt-4"
    >
      <div class="aati-result-modal__panel-content">
        <p class="font-weight-semi-bold">
          Veuillez imprimer et transmettre au patient l'exemplaire destiné à son employeur (obligatoire)
        </p>
        <n-button
          v-test="'aati-employer-sheet-download-button'"
          label="Télécharger"
          bg-color="primary"
          :loading="employerSheetDownloadLoading"
          @click="downloadEmployerSheet"
        />
      </div>
    </app-panel>
    <app-panel :is-outlined="employerSheetExists" is-flat class="mt-4">
      <div class="aati-result-modal__panel-content">
        <p v-if="employerSheetExists">
          Vous pouvez également :
        </p>
        <div class="flex-list-2 flex-column align-end">
          <n-button
            v-test="'aati-receipt-download-button'"
            class="aati-result-modal__button content--text"
            label="Télécharger l'avis de dépôt"
            bg-color="secondary lighten-5"
            icon="download"
            size="small"
            :loading="returnReceiptDownloadLoading"
            @click="downloadReturnReceipt"
          />
          <n-button
            v-test="'aati-summary-save-button'"
            class="aati-result-modal__button content--text"
            label="Enregistrer le récapitulatif dans le dossier patient"
            bg-color="secondary lighten-5"
            icon="attach"
            size="small"
            :loading="isDocumentSaving"
            @click="handleUploadAatiDocuments"
          />
        </div>
      </div>
    </app-panel>
  </n-dialog>
</template>
<script>


import Aati from '@/modules/patient/components/sidebar/tlsi/aati/models/Aati';
import NovaTools from '@/nova-tools/NovaTools';
import { getFileFromAPI } from '@/services/api';
import { downloadBlob } from '@/utils/functions/files';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';
import ButtonAction from '@novalys/src/models/ButtonAction';

import AppPanel from '@/components/ui/panel/AppPanel.vue';

export default {
  name: 'AatiResultModal',
  components: { AppPanel },
  mixins: [
    localCopyMixin({
      propertyName: 'isOpen',
      copyPropertyName: 'localIsOpen',
    }),
  ],
  props: {
    responsePayload: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      employerSheetDownloaded: false,
      employerSheetDownloadLoading: false,
      returnReceiptDownloadLoading: false,
      isDocumentSaving: false,
    };
  },
  computed: {
    employerSheetExists () {
      return !! this.responsePayload.aatiEmployerId;
    },
    modalActions () {
      return [
        new ButtonAction(
          'Fermer', 
          () => this.$emit('update:is-open', false), 
          'primary', 
          { disabled: this.employerSheetExists && ! this.employerSheetDownloaded }
        ),
      ];
    },
    successAlertText () {
      const date = NovaTools.dates.format(this.responsePayload.time, 'dd MMMM yyyy à HH:mm');
      return [
        `L'arrêt de travail n°${this.responsePayload.number}`,
        `concernant ${this.responsePayload.patientName}`,
        `a bien été transmis et déposé le ${date}.`,
      ].join(' ');
    },
  },
  methods: {
    async downloadEmployerSheet () {
      this.employerSheetDownloadLoading = true;
      await this.downloadAatiDocument(this.responsePayload.aatiEmployerId, 'Volet_employeur');
      this.employerSheetDownloaded = true;
      this.employerSheetDownloadLoading = false;
    },
    async downloadReturnReceipt () {
      this.returnReceiptDownloadLoading = true;
      await this.downloadAatiDocument(this.responsePayload.returnReceiptId, 'Accuse_de_reception');
      this.returnReceiptDownloadLoading = false;
    },
    async downloadAatiDocument (documentId, documentTypeFilename) {
      const { data } = await getFileFromAPI(`api/tlsi/${documentId}/download_receipt`);
      const date = NovaTools.dates.format(this.responsePayload.time, 'yyyyMMdd');
      const fileName = `${date}_AAT_${documentTypeFilename}_${this.responsePayload.patientName.split(' ').join('_')}`;
      downloadBlob(data, { fileName });
    },
    async handleUploadAatiDocuments () {
      try {
        this.isDocumentSaving = true;
        await Aati.saveAatiFullSummaryToPatientFile({ tlsiReturnReceipt: this.responsePayload.returnReceiptId });
        NovaTools.notify.success('Le récapitulatif de l\'arrêt de travail a été enregistré dans le dossier patient');
      } catch (e) {
        let errorMessage = '';
        if (e.response?.data?.detail) {
          errorMessage = e.response.data.detail;
        } else if (e.response?.data?.['hydra:description']) {
          errorMessage = e.response.data['hydra:description'];
        } else {
          errorMessage = 'Une erreur est survenue';
          throw e;
        }
        NovaTools.notify.error(errorMessage);
      } finally {
        this.isDocumentSaving = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.aati-result-modal {
  &__panel-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: map-get($spacers, 2);
  }

  &__button {
    max-width: fit-content;
  }
}
</style>