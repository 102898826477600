<template>
  <validation-provider
    v-slot="{ valid, errors }"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
    :mode="fieldValidationMode"
  >
    <v-textarea
      ref="input"
      v-model="localValue"
      :auto-grow="autoGrow"
      :rows="rows"
      v-bind="{ ...$props, ...$attrs }"
      :rules="[]"
      :label="fieldLabel"
      :error="errors.length > 0"
      :class="{
        'is-valid': valid,
        ...$attrs.class
      }"
      :error-messages="errors"
      :hide-details="hideErrors"
      :counter="counter"
      :maxlength="maxlength"
      @blur="$emit('blur')"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import fieldMixin from '@/mixins/fields';

/**
 * Champ de textarea de l'application
 */
export default {
  name: 'AppTextareaField',
  components: { ValidationProvider },
  mixins: [fieldMixin],
  props: {
    /**
     * Permets de rendre le composant redimensionnable automatiquement en fonction de la saisie
     * @default false
     */
    autoGrow: {
      type: Boolean,
      default: true,
    },
    /**
     * Défini le nombre de ligne à afficher quand le champ est vide
     * @default 5
     */
    rows: {
      type: [Number, String],
      default: 5,
    },
    /**
     * Nombre de caractères maximum
     * Affiche un compteur sur le champ
     */
    maxlength: {
      type: Number,
      default: null,
    },
  },
  computed: {
    counter () {
      if (this.maxlength) {
        return this.maxlength;
      }
      return null;
    },
  },
};
</script>