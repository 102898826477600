import SettingsRoutes from './settingsRoutes';
import { BASE_ROUTE_NAMES } from '@/constants';
import AccountingRoutes from '@/modules/accounting/routes';
import AgendaRoutes from '@/modules/agenda/routes';
import { ROUTE_NAMES as AUTHENTICATION_ROUTE_NAMES } from '@/modules/authentication/constants';
import AuthenticationRoutes from '@/modules/authentication/routes';
import ContactRoutes from '@/modules/contact/routes';
import DataPortabilityRoutes from '@/modules/dataPortability/routes';
import PatientRoutes from '@/modules/patient/routes';
import SecureMessagingRoutes from '@/modules/secureMessaging/routes';
import apiAuth from '@/services/apiAuth';
import { isPartnerAccess, isPartnerAuthentication, removeLocalPartnerAccessLoggedOut, getRoutePartnerAccess } from '@/services/partnerService';
import storeInstance from '@/store/instance';

import PartnerEmptyPage from '@/views/PartnerEmptyPage.vue';
import PartnerErrorPage from '@/views/PartnerErrorPage.vue';
import TermsOfUseView from '@/views/TermsOfUse.vue';
import TheHomePage from '@/views/TheHomePage.vue';

export const routes = [
  {
    path: '/',
    name: 'homePage',
    component: TheHomePage,
  },
  {
    path: '/condition-generales-d-utilisation',
    name: BASE_ROUTE_NAMES.TERMS_OF_USE,
    component: TermsOfUseView,
    meta: {
      layoutResolver: () => {
        const isLogged = storeInstance.getters['auth/isLogged'];
        const gcuAcceptedStatus = storeInstance.getters['auth/getGcu'].accepted;
        const hasAcceptedGcu = gcuAcceptedStatus && gcuAcceptedStatus !== 'new';
        return (isLogged && hasAcceptedGcu) ? 'default' : 'empty';
      },
    },
  },
  {
    path: '/partenaire-page-vide',
    name: BASE_ROUTE_NAMES.PARTNER_EMPTY_PAGE,
    component: PartnerEmptyPage,
  },
  {
    path: '/partenaire-page-erreur',
    name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
    component: PartnerErrorPage,
    props: route => ({ message: route.params.message }),
  },
  AuthenticationRoutes,
  AgendaRoutes,
  SettingsRoutes,
  PatientRoutes,
  ContactRoutes,
  AccountingRoutes,
  SecureMessagingRoutes,
  DataPortabilityRoutes,
  {
    path: '*',
    redirect: '/',
  },
];

/**
 * Protection des routes
 */

export const checkUserLoggedIn = async (to, from, next) => {
  const publicRouteNames = Object.values(AUTHENTICATION_ROUTE_NAMES);
  const isNavigatingToRestrictedRoute = !publicRouteNames.includes(to.name);

  if (isNavigatingToRestrictedRoute && apiAuth.isRefreshTokenValid() && !apiAuth.getAuthPayload()?.user.isPersonalizedPwd) {
    next({ name: AUTHENTICATION_ROUTE_NAMES.RESET_PASSWORD });
    return;
  }

  if (isNavigatingToRestrictedRoute && !apiAuth.isRefreshTokenValid()) {
    next({
      name: AUTHENTICATION_ROUTE_NAMES.CONNECTION,
      query: to.query,
    });
    return;
  }

  if(isNavigatingToRestrictedRoute && isPartnerAccess() && isPartnerAuthentication(to)) {
    removeLocalPartnerAccessLoggedOut();
    const routePartner = await getRoutePartnerAccess(to);
    if (routePartner !== null) {
      next(routePartner);
    }
    return;
  }
  next();
};

export default {
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
};