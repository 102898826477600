import Vue from 'vue';

import Appointment from '@/modules/agenda/models/events/Appointment';
import { getFromAPI, postToAPI, patchToAPI, deleteFromAPI } from '@/services/api';
import { startOfWeek, endOfWeek, format, isSameDay } from '@/utils/functions/dates';


const state = () => ({ appointments: {} });

const getters = {
  getVisiblesSchedulesAppointments: state => Object.values(state.appointments)
    .filter(appointment => state.visibleScheduleIDs.includes(appointment.schedule)),
  getAppointments: state => Object.values(state.appointments),
  getAppointmentByUuid: state => uuid => state.appointments[`/api/appointments/${uuid}`],
  getAppointmentsByDay: (state, getters) => (date = new Date()) => getters.getAppointments
    .filter(appointment => isSameDay(date, new Date(appointment.startDateTime))),
  getPatientAppointmentsByDay: (state, getters) => (patientUuid, date) => getters.getAppointmentsByDay(date)
    .filter(appointment => appointment.patient.getUuid() === patientUuid),
};

const mutations = {
  SET_APPOINTMENT (state, appointment) {
    Vue.set(state.appointments, appointment['@id'], new Appointment(appointment));
  },
  DELETE_APPOINTMENT (state, appointmentIri) {
    Vue.delete(state.appointments, appointmentIri);
  },
};

const actions = {
  async fetchAgendaDatas ({ dispatch, state, rootGetters }) {
    if (! rootGetters['auth/isLogged'] || state.appDatasStatus === 'success') {
      return;
    }

    await Promise.all([
      dispatch('fetchSchedules'),
      dispatch('fetchAppointmentMotives'),
    ]);
  },
  async fetchAppointments ({ state, commit }, {
    fromDate = startOfWeek(state.currentDate),
    toDate = endOfWeek(state.currentDate),
    schedule = state.visibleScheduleIDs,
    patient = null,
  } = {}) {
    const { data } = await getFromAPI('/api/appointments', {
      'date[after]': format(fromDate, 'yyyy-MM-dd'),
      'date[before]': format(toDate, 'yyyy-MM-dd'),
      schedule,
      patient,
    });
    data['hydra:member'].forEach(appointment => commit('SET_APPOINTMENT', appointment));
  },
  async fetchOneAppointment ({ state, commit, dispatch }, iri) {
    const { data } = await getFromAPI(`/api/appointments/${iri}`);
    const scheduleIRI = data.schedule;

    if (! state.schedules[scheduleIRI]) {
      await dispatch('fetchOneSchedule', scheduleIRI);
    }

    commit('SET_APPOINTMENT', data);
  },
  async insertAppointment ({ commit }, appointment) {
    const { patient, schedule, motive } = appointment;
    const { data } = await postToAPI('/api/appointments', {
      ...appointment,
      patient: patient['@id'],
      schedule,
      motive,
    });
    commit('SET_APPOINTMENT', data);
  },
  async updateAppointment (store, appointment) {
    const { patient, schedule, motive, state } = appointment;
    const oldAppointment = store.state.appointments[appointment['@id']];
    store.commit('SET_APPOINTMENT', appointment);
    try {
      await patchToAPI(`${appointment['@id']}`, {
        data: {
          ...appointment,
          motive,
          schedule,
          patient: patient['@id'],
          state,
        },
      });
    } catch (e) {
      store.commit('SET_APPOINTMENT', oldAppointment);
    }
  },
  async deleteAppointment ({ commit }, appointmentIri) {
    await deleteFromAPI(appointmentIri);
    commit('DELETE_APPOINTMENT', appointmentIri);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};