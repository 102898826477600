<template>
  <app-form
    :submit-method="submit"
    class="document-form"
  >
    <app-text-field
      v-model="folderTitle"
      v-test="'folder-name-input'"
      label="Nom du dossier"
      hide-errors="auto"
      rules="required|max:100"
      name="title"
    />
  </app-form>
</template>

<script>
import PatientDocumentFolder from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/models/PatientDocumentFolder';
import NovaTools from '@/nova-tools/NovaTools';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';


export default {
  name: 'DocumentFolderForm',
  components: {
    AppForm,
    AppTextField,
  },
  props: {
    patientIri: {
      type: String,
      required: true,
    },
    folderParent: {
      type: PatientDocumentFolder,
      default: null,
    },
  },
  data () {
    return { folderTitle: null };
  },
  methods: {
    async submit () {
      const folder = await PatientDocumentFolder.insert(new PatientDocumentFolder({
        patient: this.patientIri,
        title: this.folderTitle,
        parent: this.folderParent?.getIri(),
      }));
      NovaTools.notify.success('Le dossier a été créé avec succès');
      return folder;
    },
  },
};
</script>