<template>
  <app-panel
    is-outlined
    is-flat
    class="patient-resume my-2"
  >
    <patient-details
      :patient="localPatient"
      hide-avatar
      show-birth-informations
      data-test="patient-details"
    >
      <template #sub-content>
        <div
          v-if="patientMobile"
          class="patient-resume__contact pb-2"
          data-test="patient-mobile"
        >
          <div class="patient-resume__mobile">
            <v-icon color="text lighten-3">
              fa-mobile-alt
            </v-icon>
            <a :href="`tel:${patientMobile}`">{{ patientMobile }}</a>
          </div>
        </div>
        <app-link
          icon="edit"
          data-test="edit-patient-link"
          @click="openPatientFormModal"
        >
          Modifier le dossier administratif
        </app-link>
      </template>
    </patient-details>

    <app-modal-patient-form
      data-test="modal-patient-form"
      :is-open.sync="isPatientFormModalOpen"
      :patient="localPatient"
      @patient-set="selectPatient"
    />
  </app-panel>
</template>

<script>
import { CONTACT_POINT_LABELS, MEDIA_TYPES } from '@/constants';
import Patient from '@/modules/patient/models/Patient';

import AppModalPatientForm from '@/components/ui/modal/AppModalPatientForm.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';


export default {
  name: 'AppointmentPatientResume',
  components: {
    AppPanel,
    PatientDetails,
    AppModalPatientForm,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isPatientFormModalOpen: false,
      localPatient: new Patient({ ...this.patient }),
    };
  },
  computed: {
    patientPhones () {
      return this.localPatient.contactPoints.filter(
        el => el.media === MEDIA_TYPES.TELEPHONE.value,
      );
    },
    patientMobile () {
      return this.patientPhones.find(
        el => el.label === CONTACT_POINT_LABELS.MOBILE.value,
      )?.formattedValue;
    },
  },
  watch: {
    patient (newPatient) {
      this.localPatient = new Patient({ ...newPatient });
    },
  },
  methods: {
    openPatientFormModal () {
      this.isPatientFormModalOpen = true;
    },
    selectPatient (patient) {
      this.localPatient = new Patient({ ...patient });
      this.isPatientFormModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-resume {
  &__mobile {
    display: flex;
    .v-icon {
      margin-right: 0.5em;
    }
  }
}
</style>