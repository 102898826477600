export const DEFAULT_FOLDERS = Object.freeze({
  INBOX: {
    value: 'Inbox',
    icon: 'email',
    text: 'Boîte de réception',
  },
  OUTBOX: {
    value: 'Sent',
    icon: 'outbox',
    text: 'Boîte d\'envoi',
  },
});