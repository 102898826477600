<template>
  <v-expansion-panel
    class="app-menu-panel rounded-lg"
    v-bind="$attrs"
    active-class="app-menu-panel--expanded"
    :readonly="readonly"
    :class="{ 'app-menu-panel--readonly': readonly }"
  >
    <v-expansion-panel-header
      class="app-menu-panel__header px-4 py-0"
      :ripple="!readonly"
      v-bind="$attrs"
    >
      <span class="app-menu-panel__title">
        <app-icon
          :icon="icon"
          color="primary"
        />
        <span class="app-menu-panel__title__text">{{ title }}</span>
      </span>
      <template #actions>
        <slot name="actions" />
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-bind="$attrs">
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
/**
 * Représente un panneau de navigation de l'application
 */
export default {
  name: 'AppMenuPanel',
  props: {
    /**
     * Titre du panneau
     */
    title: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Icône du peanneau
     */
    icon: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Lecture seule
     */
    readonly: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-menu-panel {
  overflow: hidden;

  &:before {
    box-shadow: none;
  }

  &:not(:first-child):after {
    border-top: none;
  }

  &--readonly &__header {
    cursor: default;
  }

  &--expanded &__header {
    background-color: #E4ECFB;
    font-weight: 600;
  }

  &--expanded &__title,
  &--expanded &__icon {
    color: var(--v-primary-base);
  }

  &__header {
    min-height: 35px;
    background-color: var(--v-text-lighten4);
  }

  &__title {
    color: var(--v-text-base);
    display: flex;
    align-items: center;
    gap: map-get($spacers, 2);

    &__text {
      line-height: 0;
    }
    &__text:last-child {
      line-height: normal;
      margin: 6px 0px;
    }
  }
}
</style>