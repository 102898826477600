import Vue from 'vue';

import { reportMessageError } from '@/nova-tools/traces/traces';

const DEFAULT_NOTIFICATION_TIMEOUT = 3000;

/**
 * Le service permettant de gérer l'ensemble des portails de l'application
 */
export default Vue.observable({
  index: 0,
  notifications: {},
  /**
   * Retourne la liste des notifications sous la forme d'un tableau
   * @returns {Array}
   */
  getAll () {
    return Object.values(this.notifications);
  },
  /**
   * Supprime une notification
   * @param {String} notificationId L'identifiant de la notification
   */
  remove (notificationId) {
    Vue.delete(this.notifications, notificationId);
  },
  /**
   * Permet d'enregistrer une notification dans le plugin
   * @param {Object} options Les options disponibles
   * @param {String} options.title Le titre de la notification
   * @param {String} options.type Le type de notification ("info", "error", "success", "warning")
   * @param {String} options.message Le message de la notification
   * @param {Number|String} options.timeout La durée de vie de la notification, infinie si la propriété vaut -1
   * @returns {String} L'identifiant de la notification
   */
  show (options) {
    if (! options.message || typeof options.message !== 'string') {
      throw new Error('Un message doit être renseigné sous la forme d\'une chaine de caractère');
    }
    const notificationId = `notification-${this.index}`;
    const notificationTimeout = options.timeout || DEFAULT_NOTIFICATION_TIMEOUT;

    const sameNotification = this.getAll()
      .find(({ type, title, message }) => type === options.type && title === options.title && message === options.message);

    if (sameNotification) {
      Vue.set(this.notifications, sameNotification.id, {
        ...sameNotification,
        occurrenceCount: sameNotification.occurrenceCount + 1,
      });
      return null;
    }

    Vue.set(this.notifications, notificationId, {
      id: notificationId,
      type: options.type,
      title: options.title,
      message: options.message,
      actions: options.actions,
      occurrenceCount: 1,
      timeout: notificationTimeout,
    });
    this.index += 1;
    return notificationId;
  },
  success (message, options) {
    return this.show({
      type: 'success',
      message,
      ...options,
    });
  },
  error (message, options) {
    if (options === undefined || options.trace === undefined || options.trace === true) {
      reportMessageError(message);
    }
    return this.show({
      type: 'error',
      message,
      ...options,
    });
  },
  info (message, options) {
    return this.show({
      type: 'info',
      message,
      ...options,
    });
  },
  warning (message, options) {
    return this.show({
      type: 'warning',
      message,
      ...options,
    });
  },
});