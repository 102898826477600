<template>
  <section class="flex-list-4 flex-column">
    <h3 class="subtitle-3">
      Général
    </h3>
    <app-dataset-item
      v-test="'category'"
      vertical
      label="Type"
      :value="documentMetaDataset.getCodeDisplayName()"
    />
    <div class="d-flex">
      <app-dataset-item
        v-test="'creation-date'"
        class="flex-grow-1"
        vertical
        label="Date de création"
        :value="formattedCreationDate"
      />
      <app-dataset-item
        v-test="'version'"
        class="flex-grow-1"
        vertical
        label="Version"
        :value="documentMetaDataset.versionNumber || 'Non spécifiée'"
      />
    </div>
    <app-dataset-item
      vertical
      label="Acte(s)"
    >
      <document-act-list
        v-test="'acts'"
        :acts="documentMetaDataset.acts"
      />
    </app-dataset-item>
    <app-dataset-item
      vertical
      label="Auteur(s)"
    >
      <article
        v-for="(author, index) in documentMetaDataset.authors"
        :key="index"
        v-test="'authors'"
      >
        <p class="subtitle-3">
          {{ author.name }}
        </p>
        <p :class="{ 'text--secondary': ! author.organisation }">
          {{ author.organisation || 'Aucun lieu associé' }}
        </p>
      </article>
    </app-dataset-item>
  </section>
</template>

<script>
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset';
import { format } from '@/utils/functions/dates';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import DocumentActList from '@/modules/secureMessaging/modules/documents/components/DocumentActList.vue';



export default {
  name: 'DocumentMetaDatasetSection',
  components: {
    AppDatasetItem,
    DocumentActList,
  },
  props: {
    documentMetaDataset: {
      type: MssDocumentMetaDataset,
      required: true,
    },
  },
  computed: {
    relatedPatient () {
      return this.documentMetaDataset.getPatient();
    },
    formattedCreationDate () {
      return format(this.documentMetaDataset.createdAt, 'dd MMM yyyy à HH:mm');
    },
  },
};
</script>