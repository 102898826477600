<template>
  <div class="medical-history-item">
    <div class="medical-history-item__informations d-flex align-center">
      <span
        v-if="date"
        v-test="'creation-date'"
        class="mr-6"
        :title="NovaTools.dates.format(date, 'PPPPppp')"
      >{{
        NovaTools.dates.format(date) }}</span>
      <div v-if="author" v-test="'author'" class="d-flex align-center">
        <app-icon :icon="authorIcon" color="blue-grey" class="mr-2" />
        <span class="blue-grey--text">{{ author.getCivilState({ shortenFirstNames: true }) }}</span>
      </div>
      <div class="medical-history-item__informations__title">
        <slot
          v-if="$slots['title-append']"
          name="title-append"
        />
      </div>
    </div>
    <app-panel
      class="medical-history-item__panel"
      :color="color"
      :actions="actions"
      dense
      :icon="icon"
      :title="title"
    >
      <slot />
    </app-panel>
    <p
      v-if="updateDate !== date"
      v-test="'updated-info'"
      class="mt-2 mb-0 medical-history-item__updatedAt"
    >
      <span>{{ updatedFormattedDate }}</span>
      <span
        v-if="updatedBy"
        class="ml-1"
      >
        <span>par</span>
        <strong> {{ updatedFormattedAuthor }}</strong>
      </span>
    </p>
  </div>
</template>

<script>

import Contact from '@/models/user/Contact';
import NovaTools from '@/nova-tools/NovaTools';
import { formatDistanceToNow } from '@/utils/functions/dates';

import AppPanel from '@/components/ui/panel/AppPanel.vue';

export default {
  name: 'MedicalHistoryItem',
  components: { AppPanel },
  props: {
    date: {
      type: String,
      default: null,
    },
    updateDate: {
      type: String,
      default: null,
    },
    updatedBy: {
      type: Contact,
      default: null,
    },
    author: {
      type: Contact,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    actions: {
      type: [Object, Array],
      default: null,
    },
  },
  data () {
    return { NovaTools };
  },
  computed: {
    authorIcon () {
      return this.author.practitioner ? 'practitioner' : 'user';
    },
    updatedFormattedDate () {
      return `Modifiée ${formatDistanceToNow(this.updateDate)}`;
    },
    updatedFormattedAuthor () {
      return this.updatedBy?.getCivilState({ shortenFirstNames: true });
    },
  },
};
</script>

<style lang="scss" scoped>

.medical-history-item {
  &__informations {
    margin-top: 1px;
    margin-bottom: 8px;
    font-size: 13px;

    &__title {
      display: flex;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &__updatedAt {
    color: var(--v-blue-grey-lighten1);
    font-size: 12px;
  }

  &__panel {
    ::v-deep {
      .panel__header {
        display: grid;
        grid-template-columns: 1fr 16px;
      }
    }
  }
}
</style>