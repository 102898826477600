import { getFromAPI } from '@/services/api';
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader';


/**
 * @typedef {Object} TlsiType
 */
class Tlsi {

  static async fetchSoftwareInformations () {
    const { data: softwareInformations } = await getFromAPI('/api/software_information');
    return softwareInformations;
  }

  static async getBeneficiaryData (selectedBeneficiaryIndex, returnedKey = null) {
    const result = await iCanopeeCardReaderInstance.getVitaleInfos(selectedBeneficiaryIndex);
    if (returnedKey) {
      return result[returnedKey];
    }
    return result;
  }

}

export default Tlsi;