import { initialise, addMessageError } from '@/nova-tools/traces/sentry';


export function initialiseTraces() {
  initialise();
}

export function reportMessageError(message) {
  addMessageError(message);
}
