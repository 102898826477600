/* eslint-disable no-console */

/**
 * Permet de logger une erreur dans la console
 * @param {String} message - le message à logger
 */
function logError (message) {
  console.error(message);
}

function logWarn (message) {
  console.warn(message);
}

export const logger = {
  error: logError,
  warn: logWarn,
};