<template>
  <div
    class="app-button"
    :class="{ 'app-button--loading': loading }"
    @click="disabled ? null : $emit('click', $event)"
  >
    <n-tooltip
      v-slot="{ on }"
      :disabled="! tooltip && (! icon || ! label)"
      data-test="button-tooltip"
      :text="tooltip || label"
    >
      <div
        class="app-button__wrapper"
        v-on="on"
      >
        <v-btn
          :type="type"
          :form="form"
          :color="color"
          :icon="!! icon"
          :loading="loading"
          :disabled="disabled"
          :small="small"
          :x-small="xSmall"
          :large="large"
          :text="text"
          :outlined="outlined"
          :fab="fab"
          rounded
          data-test="button"
          class="app-button__button"
        >
          <app-icon
            v-if="icon"
            :icon="icon"
            class="app-button__button__icon"
            data-test="button-icon"
          />
          <slot v-else>
            {{ label }}
          </slot>
        </v-btn>
      </div>
    </n-tooltip>
  </div>
</template>

<script>
/**
 * Le wrapper d'un bouton de l'application
 * @deprecated - @see NButton
 */
export default {
  name: 'AppButton',
  props: {
    /**
     * La couleur du bouton
     * @default content
     */
    color: {
      type: String,
      default: 'content',
    },
    /**
     * Affiche le bouton sous la forme d'une icône précis
     * @default
     */
    icon: {
      type: [String, Boolean],
      default: null,
    },
    /**
     * Le label du bouton
     * @default null
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Le type du bouton
     * @default 'button'
     */
    type: {
      type: String,
      default: 'button',
    },
    /**
     * L'id du formulaire que cible le bouton @see {@link https://developer.mozilla.org/fr/docs/Web/HTML/Element/Button#attributes}
     * @default null
     */
    form: {
      type: String,
      default: null,
    },
    /**
     * Affiche une tooltip avec un message lors du survol du bouton
     * @default null
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * Place le bouton dans un état de chargement
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Désactive le bouton
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le bouton sous la forme d'un bouton flottant
     * @default false
     */
    fab: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique une petite taille au bouton
     * @default false
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique une très petite taille au bouton
     * @default false
     */
    xSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique une grane taille au bouton
     * @default false
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche
     * @default false
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique une transparence au bouton
     * @default false
     */
    text: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.app-button {
  display: inline-flex;
  height: min-content;

  &--loading {
    pointer-events: none;
  }

  &__wrapper {
    width: inherit;
  }

  &__button {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 100%;
    user-select: none;
    width: 100%;
  }
}

.v-btn {
  // Selecteur CSS plus précis pour éviter l'utilisation de !important
  &:not(.v-btn--round).v-size--default {
    min-width: 0;
  }

  &.v-size--x-small &__loader .v-progress-circular {
    height: 16px !important;
    width: 16px !important;
  }
}
</style>