<template>
  <div class="allergy-autocomplete-widget">
    <section ref="widget" />
  </div>
</template>

<script>
import autocompleteMixin from '@/components/lap/synapse-widgets/mixins/autocomplete';
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget';

export default {
  name: 'AllergyAutocompleteWidget',
  mixins: [widgetMixin, autocompleteMixin],
  async mounted () {
    await this.initWidget();

    this.$nextTick(() => this.focus());

    this.widget.setCallback('onSelectAllergy', (entity) => {
      this.$emit('input', entity);
    });
  },
};
</script>