import PatientHealthEntity from '@/modules/patient/models/healthEntity/PatientHealthEntity';

export default class PatientCodifiedHealthEntity extends PatientHealthEntity {
  constructor (options = {}) {
    super(options);

    this.icd10Code = options.icd10Code || null; // code CIM-10
    this.theriaqueLink = options.theriaqueLink || null;
  }

  /**
   * Permet de récupérer le label de la pathologie (avec ou sans son code CIM-10)
   * @param {Object} [hideCode=false] Demande à renvoyer le code avec le label ou non
   * @returns {String} Le label de la pathologie
   */
  getLabel ({ hideCode } = { hideCode: false }) {
    if (! hideCode && this.icd10Code) {
      return `${this.label} (${this.icd10Code})`;
    }
    return this.label;
  }

  /**
   * Renvoi une pathologie telle que demandée par Synapse pour la sécurisation
   * @returns Object SynapsePathology
   */
  asSynapseObject () {
    return {
      label: this.getLabel(),
      code: this.icd10Code,
      theriaque_link: this.theriaqueLink,
    };
  }
}