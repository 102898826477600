<template>
  <n-hover v-slot="{ hover }">
    <section class="app-hoverable-actions-wrapper">
      <component
        :is="tag"
        v-test="'actions-component'"
        class="app-hoverable-actions-wrapper__component"
      >
        <slot />
      </component>
      <v-spacer
        v-if="! compact"
        v-test="'spacer'"
      />
      <v-slide-x-transition>
        <app-actions-menu
          v-show="hover"
          v-test="'actions-menu'"
          class="app-hoverable-actions-wrapper__actions ml-2"
          :class="{ 'mb-auto mt-2': alignTop }"
          small
          :actions="actions"
          :max-actions-visible="maxActionsVisible"
        />
      </v-slide-x-transition>
    </section>
  </n-hover>
</template>

<script>
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';

import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue';

/**
 * Composant wrapper, permettant d'afficher un menu d'action au survol de son contenu
 */
export default {
  name: 'AppHoverableActionsWrapper',
  components: { AppActionsMenu },
  props: {
    /**
     * Liste des actions
     * si l'argument callback de ActionMenuItem est null le bouton correspondant sera disabled
     */
    actions: {
      type: [Object, Array],
      required: true,
      validator: actions => actions instanceof Object || actions.every(action => action instanceof ActionMenuItem),
    },
    /**
     * Aigne les actions sur le haut de l'élément
     */
    alignTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Colle le menu au contenu
     */
    compact: {
      type: Boolean,
      default: false,
    },
    /**
     * Indique le nom de la balise servant à wrapper le contenu
     */
    tag: {
      type: String,
      default: 'div',
    },
    maxActionsVisible: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-hoverable-actions-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;

  &__component {
    overflow: hidden;
    flex-grow: 1;
  }

  &__actions {
    height: 0;
  }
}
</style>