<template>
  <div>
    <v-slide-y-transition
      class="forget-password-view"
      mode="out-in"
    >
      <div
        v-if="emailSent"
        v-test="'email-confirmation'"
        class="forget-password-view__email-sent"
      >
        <app-icon
          icon="check-circle"
          size="60"
          color="primary"
          class="mb-4"
        />
        <div class="forget-password-view__email-sent__title">
          Un email vous a été envoyé à cette adresse
        </div>
        <p class="forget-password-view__email-sent__text">
          Si vous ne le recevez pas, merci de contacter les administrateurs
        </p>
      </div>
      <authentication-form-wrapper
        v-else
        v-test="'forget-password-form'"
        :title="FORM_HEADINGS.FORGET_PASSWORD.title"
        :text="FORM_HEADINGS.FORGET_PASSWORD.text"
        :form="ForgetPasswordForm"
        submit-text="Réinitialiser le mot de passe"
        @submit-success="handleEmailSent"
      />
    </v-slide-y-transition>
  </div>
</template>

<script>
import { FORM_HEADINGS } from '@/modules/authentication/constants';

import AuthenticationFormWrapper from '@/modules/authentication/components/AuthenticationFormWrapper.vue';
import ForgetPasswordForm from '@/modules/authentication/forms/ForgetPasswordForm.vue';


export default {
  name: 'ForgetPassword',
  components: { AuthenticationFormWrapper },
  data () {
    return {
      FORM_HEADINGS,
      ForgetPasswordForm,
      emailSent: false,
    };
  },
  methods: {
    handleEmailSent () {
      this.emailSent = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-password-view {
  &__email-sent {
    text-align: center;

    &__title {
      font-size: 20px;
      font-weight: 700;
    }

    &__text {
      font-size: 13px;
      color: var(--v-secondary-base);
    }
  }
}
</style>