<template>
  <div class="mb-2">
    <p>
      Vous pouvez saisir des points de vigilance qui apparaîtront dans le VSM :
    </p>
    <app-textarea-field
      v-model="localVigilancePoints"
      v-test="'vigilance-points-field'"
      class="mb-3"
      name="vigilance-points"
      label="Points de vigilance"
      rows="3"
      :auto-grow="false"
    />
    <n-button
      label="Ajouter au VSM"
      bg-color="primary"
      size="small"
      @click="handleClick"
    />
  </div>
</template>

<script>

import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';

export default {
  name: 'HealthSummaryVigilancePoints',
  components: { AppTextareaField },
  props: {
    vigilancePoints: {
      type: String,
      default: null,
    },
  },
  data () {
    return { localVigilancePoints: null };
  },
  watch: {
    vigilancePoints: {
      immediate: true,
      handler () {
        this.localVigilancePoints = this.vigilancePoints;
      },
    },
  },
  methods: {
    handleClick () {
      this.$emit('update:vigilancePoints', this.localVigilancePoints);
    },
  },
};
</script>