import { CONTACT_POINT_LABELS, MEDIA_TYPES } from '@/constants';
import ApiEntity from '@/models/ApiEntity';
import ContactPoint from '@/models/ContactPoint';
import Person from '@/models/Person';
import Contact from '@/models/user/Contact';
import { getCivilState } from '@/utils/functions/persons';

export default class NewHealthProfessional extends ApiEntity {
  constructor (options = {}) {
    super(options);

    this.id = options.id || null;
    this.contact = options.contact ? new Contact(options.contact) : null;
  }

  getCivilState () {
    return getCivilState({
      firstNames: this.contact.firstNames,
      familyName: this.contact.familyName,
    });
  }

  getKnowHow () {
    return this.contact.practitionerProfile ? this.contact.practitionerProfile.qualifications : null;
  }

  toPerson () {
    const defaultPerson = {
      firstNames: this.contact.firstNames,
      familyName: this.contact.familyName,
    };

    const address = this.contact?.address;
    const contactActivityPlace = (this.contact?.contactActivityPlaces?.length !== 0)
      ? this.contact.contactActivityPlaces[0] : null;
    const defaultAddress = address ?? contactActivityPlace;

    // On ne retourne que le premier lieu d'activité, en attendant une refacto du module des contacts
    if (defaultAddress) {
      defaultPerson.address = {
        name: defaultAddress.name,
        city: defaultAddress.city,
        postalCode: defaultAddress.postalCode,
      };
    }
    defaultPerson.contactPoints = [];
    if (contactActivityPlace) {
      defaultPerson.contactPoints.push(
        new ContactPoint({
          value: contactActivityPlace.contactPoints?.filter(item => item.media === 'email')[0]?.value,
          label: CONTACT_POINT_LABELS.BUSINESS.value,
          media: MEDIA_TYPES.EMAIL.value,
        }),
      );
      defaultPerson.contactPoints.push(
        new ContactPoint({
          value: contactActivityPlace.contactPoints?.filter(item => item.media === 'fax')[0]?.value,
          label: CONTACT_POINT_LABELS.BUSINESS.value,
          media: MEDIA_TYPES.FAX.value,
        }),
      );
      defaultPerson.contactPoints.push(
        new ContactPoint({
          value: contactActivityPlace.contactPoints?.filter(item => item.media === 'telephone')[0]?.value,
          label: CONTACT_POINT_LABELS.BUSINESS.value,
          media: MEDIA_TYPES.TELEPHONE.value,
        }),
      );
    }

    // On ne retourne que la première adresse mss, en attendant une refacto du module des contacts
    defaultPerson.contactPoints.push(
      new ContactPoint({
        value: (this.contact?.getMssContactPoints()?.length !== 0) ? this.contact?.getMssContactPoints()[0] : null,
        label: CONTACT_POINT_LABELS.MSS.value,
        media: MEDIA_TYPES.EMAIL.value,
      }),
    );

    return new Person(defaultPerson);
  }
}