<template>
  <app-hoverable-actions-wrapper
    v-test="'contact-point'"
    :actions="actions"
    :compact="compact"
  >
    <div class="app-contact-point">
      <div>
        <label
          v-if="contactPoint.label && showLabel"
          class="app-contact-point__label"
          data-test="contact-point-label">
          {{ contactPointLabel }}
        </label>
        <a class="app-contact-point__link mr-2 sentry-mask" :href="contactPointLink" data-test="contact-point-value">
          {{ contactPointFormattedValue }}
        </a>
      </div>
    </div>
  </app-hoverable-actions-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import { MEDIA_TYPES, CONTACT_POINT_LABELS } from '@/constants';
import ContactPoint from '@/models/ContactPoint';

import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue';

export default {
  name: 'AppContactPoint',
  components: { AppHoverableActionsWrapper },
  props: {
    contactPoint: {
      type: ContactPoint,
      required: true,
    },
    formattedContactPoint: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contactPointLink () {
      const linkType = this.contactPoint.media === MEDIA_TYPES.EMAIL.value ? 'mailto' : 'tel';
      return `${linkType}:${this.contactPoint.value}`;
    },
    contactPointLabel () {
      return CONTACT_POINT_LABELS[this.contactPoint.label.toUpperCase()]?.label;
    },
    actions () {
      return [new ActionMenuItem(
        'copy',
        'Copier dans le presse papier',
        this.copyContactPointToClipboard,
      )];
    },
    contactPointFormattedValue () {
      return this.formattedContactPoint ? this.contactPoint.formattedValue : this.contactPoint.value;
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    copyContactPointToClipboard () {
      navigator.clipboard.writeText(this.contactPoint.value);
      this.SET_SNACK({
        color: 'info',
        message: 'Le point de contact a été copié dans le presse papier',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-contact-point {
  position: relative;
  display: flex;
  align-items: center;

  &__label {
    display: block;
    font-size: 12px;
    color: var(--v-secondary-lighten4)
  }

  &__clipboard-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__link {
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>