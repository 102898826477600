<template>
  <router-view
    v-if="canDisplayModule"
    data-test="patientModule"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PatientModule',
  data () {
    return { canDisplayModule: false };
  },
  watch: {
    $route: {
      immediate: true,
      /**
       * Éxécute l'ensemble des requêtes relatives aux entités globales au module
       */
      async handler (to) {
        const patientUuid = to.params.uuid;
        const {consultationUUID} = to.params;

        const globalQueries = [
          patientUuid ? this.fetchOnePatient(patientUuid) : null,
          consultationUUID ? this.fetchOnePatientConsultation(consultationUUID) : null,
        ].filter(item => item);

        if (globalQueries.length) {
          await Promise.all(globalQueries);
        }
        this.canDisplayModule = true;
      },
    },
  },
  methods: { ...mapActions('patient', ['fetchOnePatient', 'fetchOnePatientConsultation']) },
};
</script>