<template>
  <div>
    <app-confirm-modal
      :is-open="value !== null"
      :is-submitting="isDeleting"
      title="Supprimer la pathologie"
      confirm-color="error"
      confirm-text="Supprimer"
      data-test="delete-confirm-modal"
      @cancel="$emit('input', null)"
      @confirm="deletePathology"
    >
      <div data-test="msg">
        Êtes-vous sûr(e) de vouloir supprimer la pathologie <span class="font-weight-bold">{{ pathologyName }}</span> ?
      </div>
    </app-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';

import AppConfirmModal from '@/components/ui/modal/AppConfirmModal.vue';

export default {
  name: 'PathologyDeleteConfirmModal',
  components: { AppConfirmModal },
  props: {
    /**
     * La pathologie à supprimer
     * @model
     */
    value: {
      type: PatientPathology,
      default: null,
    },
  },
  data () {
    return { isDeleting: false };
  },
  computed: {
    pathologyName () {
      return this.value?.label;
    },
  },
  methods: {
    ...mapActions('patient', ['deletePatientPathology', 'setIsHealthSummaryRemindersUpdated']),
    ...mapMutations('app', ['SET_SNACK']),
    async deletePathology () {
      this.isDeleting = true;
      try {
        await this.deletePatientPathology(this.value);
        this.SET_SNACK({ message: 'La pathologie a été supprimée avec succès' });
        this.$emit('input', null);
        this.setIsHealthSummaryRemindersUpdated(true);
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>