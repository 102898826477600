import { PRACTITIONER, MEDICAL_SECRETARY, FINANCIAL_SECRETARY } from '@/constants/userProfiles';
import { ROUTE_NAMES as ACCOUNTING_ROUTE_NAMES } from '@/modules/accounting/constants';
import { ROUTE_NAMES as CONTACT_ROUTE_NAMES } from '@/modules/contact/constants';
import { ROUTE_NAMES as SECURE_MESSAGING_ROUTE_NAMES } from '@/modules/secureMessaging/constants';

export const MODULES_MENU_ITEMS = Object.freeze({
  AGENDA: {
    text: 'Agenda',
    route: { name: 'agenda' },
    icon: 'agenda',
    have_access: [PRACTITIONER, MEDICAL_SECRETARY],
  },
  PATIENT: {
    text: 'Patients',
    route: { name: 'patients.listing' },
    icon: 'patients',
    have_access: [PRACTITIONER, MEDICAL_SECRETARY],
  },
  CONTACTS: {
    text: 'Contacts',
    route: { name: CONTACT_ROUTE_NAMES.CONTACT_LISTING },
    icon: 'contacts',
    have_access: [PRACTITIONER, MEDICAL_SECRETARY],
  },
  SECURE_MESSAGING: {
    text: 'Messagerie',
    route: { name: SECURE_MESSAGING_ROUTE_NAMES.ROOT },
    icon: 'secure-messaging-module',
    have_access: [PRACTITIONER],
  },
  ACOUNTING: {
    text: 'Gestion',
    route: { name: ACCOUNTING_ROUTE_NAMES.ACCOUNTING_DASHBOARD },
    icon: 'gestion-comptable',
    have_access: [PRACTITIONER, FINANCIAL_SECRETARY],
  },
});