/* eslint-disable object-property-newline */
import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation';

const patientEntity = new DocumentEntity('Patient', 'patients.xml', 'Contient les données de santé de chaque patient');

patientEntity.addField(new DocumentEntityField(
  'externalMedication',
  'Prescriptions effectuées par un médecin externe',
  new DocumentEntityRelation('ExternalMedication', 'id'),
));
patientEntity.addField(new DocumentEntityField(
  'importedDocuments',
  'Les documents importés',
  new DocumentEntityRelation('PatientImportedDocument', 'id'),
));
patientEntity.addField(new DocumentEntityField('correspondents', 'Les correspondants médicaux', new DocumentEntityRelation('Correspondent', 'id')));
patientEntity.addField(new DocumentEntityField(
  'patientRelatives',
  'Les personnes de l\'entourage',
  new DocumentEntityRelation('PatientRelative', 'id'),
));
patientEntity.addField(new DocumentEntityField('contactPoints', 'Les points de contact', new DocumentEntityRelation('ContactPoint', 'id')));
patientEntity.addField(new DocumentEntityField('address', 'l\'adresse', new DocumentEntityRelation('Address', 'id')));
patientEntity.addField(new DocumentEntityField('gender', 'Le sexe'));
patientEntity.addField(new DocumentEntityField('civility', 'La civilité'));
patientEntity.addField(new DocumentEntityField('firstName', 'Le prénom de naissance'));
patientEntity.addField(new DocumentEntityField('usedFirstName', 'Le prénom utilisé'));
patientEntity.addField(new DocumentEntityField('firstNames', 'L\'ensemble des prénoms'));
patientEntity.addField(new DocumentEntityField('familyName', 'Le nom de famille d\'usage'));
patientEntity.addField(new DocumentEntityField('birthName', 'Le nom de famille de naissance'));
patientEntity.addField(new DocumentEntityField('birthDate', 'La date de naissance'));
patientEntity.addField(new DocumentEntityField('birthPlaceCode', 'Le code INSEE du lieu de naissance'));
patientEntity.addField(new DocumentEntityField('insIdentity', 'L\'identité INS', new DocumentEntityRelation('PatientInsIdentity', 'id')));
patientEntity.addField(new DocumentEntityField('nir', 'Le NIR'));
patientEntity.addField(new DocumentEntityField('keywords', 'Les mots-clés associés', new DocumentEntityRelation('Keyword', 'id')));
patientEntity.addField(new DocumentEntityField('familySituation', 'Le statut marital (single | married | pacs | concubinage | widowed | divorced)'));
patientEntity.addField(new DocumentEntityField('profession', 'La profession'));
patientEntity.addField(new DocumentEntityField('note', 'La note'));
patientEntity.addField(new DocumentEntityField('measures', 'Les mesures biométriques', new DocumentEntityRelation('PatientMeasure', 'id')));
patientEntity.addField(new DocumentEntityField('consultations', 'Les consultations du patient', new DocumentEntityRelation('Consultation', 'id')));
patientEntity.addField(new DocumentEntityField(
  'medicalHistoryNotes',
  'Les notes de l\'historique médical',
  new DocumentEntityRelation('MedicalHistoryNote', 'id'),
));
patientEntity.addField(new DocumentEntityField(
  'medicalDataset', 'Informations médicales', new DocumentEntityRelation('PatientMedicalDataset', 'id')
));
patientEntity.addField(new DocumentEntityField(
  'nonDrugAllergies',
  'Allergies non médicamenteuses',
  new DocumentEntityRelation('PatientNonDrugAllergy', 'id'),
));
patientEntity.addField(new DocumentEntityField('pathologies', 'Pathologies', new DocumentEntityRelation('PatientPathology', 'id')));
patientEntity.addField(new DocumentEntityField('drugAllergies', 'Allergies médicamenteuses', new DocumentEntityRelation('PatientDrugAllergy', 'id')));
patientEntity.addField(new DocumentEntityField(
  'femaleMedicalDataset',
  'Informations médicales spécifiques aux femmes',
  new DocumentEntityRelation('PatientFemaleMedicalDataset', 'id'),
));
patientEntity.addField(new DocumentEntityField(
  'otherRiskFactors',
  'Autres facteurs de risques',
  new DocumentEntityRelation('PatientOtherRiskFactor', 'id'),
));
patientEntity.addField(new DocumentEntityField('publicId', 'Identifiant unique au format court'));
patientEntity.addField(new DocumentEntityField('isDeceased', 'Le patient est décédé'));
patientEntity.addField(new DocumentEntityField('deathDate', 'La date de décès'));

export default patientEntity;