<template>
  <app-panel :title="title">
    <div class="accounting-widget">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="accounting-widget__item"
      >
        <div class="accounting-widget__item__title">
          <div
            v-if="item.dot"
            class="accounting-widget__item__title__badge"
            :class="item.dot"
          />
          <span>{{ item.label }}</span>
        </div>
        <div class="accounting-widget__item__value">
          {{ item.value }}
        </div>
      </div>
    </div>
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue';

export default {
  name: 'AccountingListWidget',
  components: { AppPanel },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">

.accounting-widget {
  display: flex;
  gap: map-get($spacers, 5);

  &__item {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 1);

    &__title {
      display: flex;
      align-items: center;
      gap: map-get($spacers, 1);
      color: var(--v-secondary-base);
      font-size: 12px;
      font-weight: 400;

      &__badge {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    &__value {
      display: flex;
      gap: map-get($spacers, 3);
      color: var(--v-content-base);
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>