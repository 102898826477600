import 'vuex'

export default class StorePlugin {
  /**
   * Store VueX associé
   */
  #store

  /**
   * Indique si le plugin est en cours d’exécution
   */
  #isStarted = false

  /**
   * Permet la création d'un plugin de store
   * @param {Store} store Store VueX associé
   */
  constructor (store) {
    this.#store = store
  }

  start () {
    throw new Error('Le plugin de store doit implémenter la méthode "start"')
  }

  stop () {
    throw new Error('Le plugin de store doit implémenter la méthode "stop"')
  }

  /**
   * Réservé au manager de service
   * Permet de lancer le service
   */
  async run () {
    this.#isStarted = true
    await this.start()
  }

  /**
   * Réservé au manager de service
   * Permet de stopper le service
   */
  async kill () {
    await this.stop()
    this.#isStarted = false
  }

  isStarted () {
    return this.#isStarted
  }

  getStore () {
    return this.#store
  }
}