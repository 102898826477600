<template>
  <app-panel title="Consentements">
    <app-row>
      <app-col cols="12">
        <h3 class="subtitle-3 secondary--text text--lighten-4 mb-3">
          Documents
        </h3>
        <app-switch
          v-model="localmssDocumentConsent"
          label="Le patient autorise l'envoi de documents par MSS"
          dense
        />
      </app-col>
    </app-row>
  </app-panel>
</template>

<script>
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';


export default {
  name: 'ConsentsFieldset',
  components: {
    AppPanel,
    AppSwitch,
  },
  mixins: [
    localCopyMixin({
      propertyName: 'mssDocumentConsent',
      copyPropertyName: 'localmssDocumentConsent',
    }),
  ],
  props: {
    mssDocumentConsent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>