<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <h5 v-if="showLabel" class="schedules-list-wrapper__title mb-3">
      {{ label }}
    </h5>
    <app-selectable-list
      :value="checkedSchedules"
      label="agendas"
      :items="getSchedules"
      :group-by="groupBy"
      :min-count="1"
      :disabled="disabled"
      data-test="selectable-list"
      @changed="toggle"
    >
      <template #parent="{ parent }">
        {{ parent.firstNames }} {{ parent.familyName }}
      </template>
      <template #item=" { item }">
        {{ item.name }}
      </template>
    </app-selectable-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppSelectableList from '@/components/ui/selectableList/AppSelectableList.vue';


export default {
  name: 'AgendaSchedulesList',
  components: { AppSelectableList },
  props: {
    /**
     * Titre à afficher au dessus des checkboxes
     * @default "Mes Agendas"
     */
    label: {
      type: String,
      default: 'Mes Agendas',
    },
    /**
     * Tableau contenant les IDs des agendas cochés
     */
    checkedSchedules: {
      type: Array,
      required: true,
    },
    /**
     * Permet d'empêcher la modification
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet d'afficher le libellé
     * @default false
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('agenda', ['getSchedules']),
    ...mapGetters('auth', ['isCurrentUserSecretary']),
    groupBy () {
      return this.isCurrentUserSecretary ? 'activityPlacePractitionerLink.practitioner' : null;
    },
  },
  methods: {
    toggle ({ item, isChecked }) {
      this.$emit('scheduleChanged', {
        schedule: item,
        isChecked,
      });
    },
  },
};
</script>

<style lang="scss">
.schedules-list-wrapper {
  &__title {
    font-size: 13px;
    font-weight: 600;
  }
  .schedules-list {
    &__checkbox-label {
      font-size: 13px;
      color: var(--v-text-base);

      &--disabled {
        opacity: .6;
        pointer-events: none;
      }

      &--clickable {
        user-select: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__practitioner {
      height: 20px;
      font-size: 12px;
      color: var(--v-blue-grey-base);
      margin-bottom: 8px;
    }
  }
}
</style>