import axios from 'axios';
import { Buffer } from 'buffer';

import CardReader from '@/services/card-reader/cardReader';
import { CARD_TYPE_DEFINITIONS, CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import mockCps from '@/services/vendors/icanopee/__fixtures__/cps';
import { ICANOPEE_CARD_READER_EVENTS } from '@/services/vendors/icanopee/constants/cardReaderEvents';
import { ICANOPEE_COMMANDS } from '@/services/vendors/icanopee/constants/commands';
// Mock d'une CPS de test
import { getEnv } from '@/utils/functions/env';

const hardCodedPracticeLocation = mockCps.PracticeLocations[0];

// Timeout d'expiration d'une session (en secondes)
const DEFAULT_SESSION_TIMEOUT_S = 3600;

// Serveur websocket pour le connecteur ICanopée
const DEFAULT_WEBSOCKET_SERVER_URL = 'wss://localhost.icanopee.net:9982';
const DEFAULT_REMOTE_CMD_URL = 'https://localhost.icanopee.net:9982/remotecommand';

const DEFAULT_KEEPALIVE_INTERVAL_S = DEFAULT_SESSION_TIMEOUT_S / 2;

const EPRESCRIPTION_TLSI_URL = getEnv('VUE_APP_EPRESCRIPTION_TLSI_URL');
const DEPLOY_ENV_TYPE = getEnv('VUE_APP_DEPLOY_ENV_TYPE');
const APCV_AUTH_URL = getEnv('VUE_APP_APCV_AUTH_URL');

// Messages d'erreur
const ERROR_MESSAGES = {
  INVALID_CODE: 'Le code porteur saisi est incorrect',
  // eslint-disable-next-line max-len
  LOCKED_CARD: 'La carte CPS est verrouillée suite à un nombre trop important de connexions échouées. Vous pouvez débloquer votre carte CPS avec l\'application CPS-Gestion',
  DEFAULT: 'Une erreur inconnue est survenue',
};

class ICanopeeCardReader extends CardReader {
  /**
   * Instance du Websocket
   * @type {WebSocket}
   */
  websocket = null;

  /**
   * Instance d'Axios
   */
  axiosInstance = axios.create();

  /**
   * Instance du Websocket de rafraîchissement
   * @type {WebSocket}
   */
  keepAliveWebsocket = null;

  /**
   * Descriptif des lecteurs de carte
   */
  cardReaders = {
    [CARD_TYPES.CPX]: {
      sessionId: null,
      websocket: null,
      name: null,
      index: null,
      type: CARD_TYPES.CPX,
    },
    [CARD_TYPES.VITALE]: {
      sessionId: null,
      websocket: null,
      name: null,
      index: null,
      type: CARD_TYPES.VITALE,
    },
  };

  /**
   * Identifiant unique de la session à lier aux requêtes
   */
  sessionId = null;

  codePinToValidateAfterInit = null;

  constructor () {
    super();

    // On force le contexte pour éviter que "this" référence websocket dans le listener
    this.onGlobalMessage = this.onGlobalMessage.bind(this);

    this.createOrchestratorWebsocket();
  }

  /**
   * Créé une instance de WebSocket pour les requêtes synchrones
   * @param {Function} onOpen Fonction appelée lors du succès de la création du websocket
   * @param {Function} onClose Function appelée lors de la cloture de la connection du websocket
   * @param {String} name Le nom du service (facilite de debugging)
   * @returns {WebSocket} Le websocket
   */
  async createSynchronousWebsocket (options = {}) {
    return new Promise((resolve, reject) => {
      const { onClose } = options;

      const websocket = new WebSocket(`${DEFAULT_WEBSOCKET_SERVER_URL}/IcanopeeSynchronousWebsocket`);
      websocket.onopen = () => {
        resolve(websocket);
      };
      websocket.onerror = (error) => {
        reject(error);
      };
      websocket.onclose = onClose ? onClose.bind(this) : null;
    });
  }

  /**
   * Permet de se reconnecter en cas de perte de connectivité au service
   */
  retryCreateOrchestratorWebsocket = () => {
    setTimeout(async () => {
      await this.createOrchestratorWebsocket();
    }, 5000);
  };

  async createOrchestratorWebsocket () {
    try {
      await this.registerConfig();
      this.websocket = await this.createSynchronousWebsocket({ onClose: this.retryCreateOrchestratorWebsocket });
      await this.init();
    } catch (error) {
      /**
       * Fallback géré dans le onClose.
       * Le try/catch permet de masquer l'erreur en console.
       */
    }
  }

  /**
   * Initialise le service de lecture de carte pour DMPConnect JS2
   */
  async init () {
    this.sessionId = await this.startSession(this.websocket);
    const cardReaders = await this.getCardReaders() || [];
    await this.autoSelectCardReader(cardReaders, CARD_TYPES.CPX);
    await this.autoSelectCardReader(cardReaders, CARD_TYPES.VITALE);

    if (this.codePinToValidateAfterInit) {
      await this.validatePincode(this.codePinToValidateAfterInit);
      this.codePinToValidateAfterInit = null;
    }
    // await this.closeGhostSessions(); // when working remove slave/efficience/insiconsult sessions unknown from this app

    // Keep alive de la connection
    this.keepAliveWebsocket = this.websocket;
    setInterval(async () => {
      await this.keepAlive(this.sessionId); // Principale
      await this.keepAlive(this.cardReaders[CARD_TYPES.CPX].sessionId); // Connection CPx
      await this.keepAlive(this.cardReaders[CARD_TYPES.VITALE].sessionId); // Connection Carte Vitale
    }, DEFAULT_KEEPALIVE_INTERVAL_S * 1000);
  }

  hasSession () {
    return this.sessionId !== null;
  }

  async createCardReaderWebsocket (cardReaderIndex, cardReaderName, cardType) {
    if (this.cardReaders[cardType]?.websocket) {
      const { websocket } = this.cardReaders[cardType];

      if (websocket.readyState > WebSocket.OPEN) {
        websocket.removeEventListener('message', this.onGlobalMessage);
        websocket.close();
      }

      this.setCardReaderHasCard(cardType, false);
      this.setCardReaderContent(cardType, null);
    }

    return new Promise((resolve) => {
      const cardReaderWebsocket = new WebSocket(`${DEFAULT_WEBSOCKET_SERVER_URL}/IcanopeeCardReaderWebsocket`);
      cardReaderWebsocket.addEventListener('message', this.onGlobalMessage);
      cardReaderWebsocket.onclose = () => {
        this.setCardReaderHasCard(cardType, false);
        this.setCardReaderContent(cardType, null);
        cardReaderWebsocket.removeEventListener('message', this.onGlobalMessage);
      };
      cardReaderWebsocket.onopen = async () => {
        const sessionId = await this.startSession(cardReaderWebsocket);

        // On met en cache les informations
        this.cardReaders[cardType].index = cardReaderIndex;
        this.cardReaders[cardType].name = cardReaderName;
        this.cardReaders[cardType].websocket = cardReaderWebsocket;
        this.cardReaders[cardType].sessionId = sessionId;
        cardReaderWebsocket.userData = this.cardReaders[cardType];

        // On active le monitoring
        if (cardType === CARD_TYPES.CPX) {
          await this.watchCpxCardReader(cardReaderName, cardReaderIndex, cardReaderWebsocket, sessionId);
        } else {
          await this.watchVitaleCardReader(cardReaderName, cardReaderIndex, cardReaderWebsocket, sessionId);
        }

        resolve();
      };
    });
  }

  processVitalePatients(message) {
    if (message.i_cardStatus === 1 || message.Patients.length === 0) {
      this.setCardReaderHasCard(CARD_TYPES.VITALE, false);
      this.setCardReaderContent(CARD_TYPES.VITALE, null);
    } else if ([3, 4, 5].includes(message.i_cardStatus)) {
      this.setCardReaderHasCard(CARD_TYPES.VITALE, true);
      this.setCardReaderContent(CARD_TYPES.VITALE, message.Patients);
    }
  }

  async handleVitaleCardStatus(cardStatus) {
    switch (cardStatus) {
      case 2:
        this.setCardReaderContentLoading(CARD_TYPES.VITALE, true);
        await this.fetchVitaleCardContent();
        this.setCardReaderContentLoading(CARD_TYPES.VITALE, false);
        break;
      case 1:
      case 4:
      case 5:
        this.setCardReaderHasCard(CARD_TYPES.VITALE, cardStatus === 1);
        this.setCardReaderContent(CARD_TYPES.VITALE, null);
        break;
      default:
        break;
    }
  }

  handleCpxCardMessage(cardStatus) {
    switch (cardStatus) {
      case 1:
      case 4:
        this.setCardReaderHasCard(CARD_TYPES.CPX, true);
        break;
      case 2:
      case 5:
      case 6:
        this.setCardReaderHasCard(CARD_TYPES.CPX, cardStatus !== 2);
        this.setCardReaderContent(CARD_TYPES.CPX, null);
        break;
      default:
        break;
    }
  }

  async handleVitaleCardMessage(message) {
    if (message.Patients) {
      this.processVitalePatients(message);
    } else {
      await this.handleVitaleCardStatus(message.i_cardStatus);
    }
  }

  /**
   * Listener appelé en cas d'évènement "global" (Évènement poussé par le server)
   * @param {MessageEvent} event L'évènement global
   */
  async onGlobalMessage(event) {
    const { target, data } = event;
    const message = JSON.parse(data);

    if (target.userData) {
      const { type } = target.userData;

      if (type === CARD_TYPES.VITALE) {
        await this.handleVitaleCardMessage(message);
      } else if (type === CARD_TYPES.CPX) {
        this.handleCpxCardMessage(message.i_cardStatus);
      }
    }
  }

  /**
   * Permet d'envoyer une commande websocket en attendant la réponse
   * @param {WebSocket} websocket L'instance du websocket à utiliser
   * @param {String} name Le nom de la commande
   * @param {Object} params Objet définissant les paramètres à fournir à la commande
   * @returns {Promise.<Object>} Le résultat désérialisé de la commande
   */
  async sendCommand (name, options = {}) {
    let websocket = options.websocket || this.websocket;
    const needed = options.needed || null;
    if (websocket === null) {
      websocket = await this.createSynchronousWebsocket();
    }
    return new Promise((resolve, reject) => {
      websocket.onerror = (error) => {
        reject(error);
      };

      websocket.onmessage = async ({ data }) => {
        const message = JSON.parse(data);

        if (! needed || message[needed]) {
          resolve(message);
        }
      };
      websocket.send(JSON.stringify({
        s_commandName: name,
        ...options.params || {},
      }));
    });
  }

  /**
   * Enregistre une configuration à partir des informations fournies dans les dcParameters.
   * @returns {null|boolean} état de l'enregistrement de la configuration
   */
  async registerConfig () {
    try {
      const isConfigAlreadyRegistered = await this.isConfigRegistered();
      if (isConfigAlreadyRegistered === null) {
        throw new Error('error getting DcParameter registered state');
      }
      if (isConfigAlreadyRegistered) {
        return true;
      }
      const { data } = await this.axiosInstance.post(
        `${DEFAULT_REMOTE_CMD_URL}/registerDcParameter`,
        { s_dcparameters64: import.meta.env.VUE_APP_ICANOPEE_DC_PARAMETERS },
        { headers: { 'Content-Type': 'text/plain;charset=UTF-8' } },
      );
      return (data.s_status === 'OK');
    } catch (error) {
      return null;
    }
  }

  /**
   * Retourne si le connecteur a bien la configuration d'enregistrée, null si erreur
   * @returns {null|boolean}
   */
  async isConfigRegistered () {
    try {
      const { data } = await this.axiosInstance.post(
        `${DEFAULT_REMOTE_CMD_URL}/isDcParameterRegistered`,
        { s_dcparameters64: import.meta.env.VUE_APP_ICANOPEE_DC_PARAMETERS },
        { headers: { 'Content-Type': 'text/plain;charset=UTF-8' } },
      );
      return data.s_status === 'OK' && data.i_registered;
    } catch (error) {
      return null;
    }
  }

  /**
   * Démarre une session sur le serveur WS ICanopée
   * @param {WebSocket} websocket instance du websocket
   * @returns {String} id de la session
   */
  async startSession (websocket) {
    const { s_sessionId: sessionId } = await this.sendCommand(ICANOPEE_COMMANDS.OPEN_SESSION, {
      websocket,
      params: {
        i_timeoutInSeconds: DEFAULT_SESSION_TIMEOUT_S,
        s_dcparameters64: import.meta.env.VUE_APP_ICANOPEE_DC_PARAMETERS,
      },
    });

    return sessionId;
  }

  /**
   * Permet d'obtenir des informations globales telles que le nombre de sessions actives
   * @returns {Object}
   */
  getSystemInformation () {
    return this.sendCommand(ICANOPEE_COMMANDS.GET_SYSTEM_INFORMATION);
  }

  /**
   * Clôt une session active
   * @param {String} sessionId
   */
  async closeSession (sessionId) {
    await this.sendCommand(ICANOPEE_COMMANDS.CLOSE_SESSION, { params: { s_sessionId: sessionId } });
  }

  /**
   * Clôt l'ensemble des sessions fantômes non utilisé par l'instance actuelle
   */
  /* eslint-disable camelcase */
  async closeGhostSessions () {
    const { Sessions } = await this.getSystemInformation();
    await Promise.all(
      Sessions
        .filter(({ s_sessionId }) => s_sessionId !== this.sessionId)
        .map(({ s_sessionId }) => this.closeSession(s_sessionId)),
    );
  }
  /* eslint-disable camelcase */

  async keepAlive (sessionId) {
    if (sessionId) {
      await this.sendCommand(ICANOPEE_COMMANDS.GET_SESSION_STATE, {
        websocket: this.keepAliveWebsocket,
        params: { s_sessionId: sessionId },
      });
    }
  }

  /**
   * Charge les lecteurs de carte
   */
  async getCardReaders () {
    this.emitEvent(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATING, { isUpdating: true });
    const { Readers: cardReaders } = await this.sendCommand(ICANOPEE_COMMANDS.GET_PCSC_READERS, { params: { s_sessionId: this.sessionId } })
      || { Readers: [] };
    this.emitEvent(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATING, { isUpdating: false });

    if (cardReaders && cardReaders.length > 0) {
      this.emitEvent(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATED, { cardReaders });
    }

    return cardReaders;
  }

  /**
   * Permet d'automatiquement définir le lecteur de carte qui est associé au type de carte désiré
   * @param {Array.<Object>} readers Les lecteurs de carte
   * @param {String} cardType Le type de carte désiré
   */
  async autoSelectCardReader (readers, cardType) {
    // On récupère l'index du lecteur si une carte y est insérée
    let cardSlotIndex = readers?.findIndex(reader => reader.i_slotType === CARD_TYPE_DEFINITIONS[cardType].i_slotType);

    // Si on ne trouve pas, on regarde si on a une sauvegarde du lecteur utilisé, dans le localStorage
    const { localStorageKey } = CARD_TYPE_DEFINITIONS[cardType];
    const savedCardReaderName = this.getSavedCardReaders()?.[localStorageKey];

    if (cardSlotIndex === - 1 && savedCardReaderName) {
      /**
       * Si le nom du lecteur dans le localStorage correspond
       * à un lecteur déjà sélectionné, on vide la valeur du localStorage
       * et on n'essaye plus de le sélectionner
       */
      const otherCardReaderName = cardType === CARD_TYPES.CPX
        ? this.cardReaders[CARD_TYPES.VITALE].name
        : this.cardReaders[CARD_TYPES.CPX].name;

      if (otherCardReaderName === savedCardReaderName) {
        this.saveCardReader(cardType, { s_name: '' });
        return;
      }

      cardSlotIndex = readers.findIndex(reader => reader.s_name === savedCardReaderName);
    }

    if (cardSlotIndex > - 1) {
      await this.selectCardReader(cardType, readers[cardSlotIndex], cardSlotIndex);
    }
  }

  async selectCardReader (cardType, cardReader, cardReaderIndex) {
    const cardReaderWebsocket = this.cardReaders[cardType]?.websocket;
    const createConnexion = (
      ! cardReaderWebsocket
      || cardReaderWebsocket.readyState > WebSocket.OPEN
    );

    if (createConnexion) {
      await this.createCardReaderWebsocket(
        cardReaderIndex,
        cardReader.s_name,
        cardType,
      );
    }
    this.saveCardReader(cardType, cardReader);
  }

  /**
   * Permet de définir le lecteur de carte CPx
   * @param {String} cardReaderName le nom du lecteur de carte
   * @param {Number} readerIndex l'index du lecteur
   */
  async watchCpxCardReader (cardReaderName, readerIndex, websocket = null, sessionId = null) {
    if (! sessionId) {
      sessionId = this.sessionId;
    }

    await this.sendCommand(ICANOPEE_COMMANDS.GET_CPX_CARD, {
      websocket,
      params: {
        i_readerNumber: readerIndex,
        s_readerName: cardReaderName,
        s_sessionId: sessionId,
      },
    });

    await this.sendCommand(ICANOPEE_COMMANDS.START_CPX_CARD_MONITORING, {
      websocket,
      params: {
        s_sessionId: sessionId,
        i_checkingInterval: 0,
      },
    });

    // Fix : Commande qui permet de déclencher le monitoring de la CPx.
    // Le monitoring ne trigger pas l'état initial. On le force avec cette requête
    await this.sendCommand(ICANOPEE_COMMANDS.GET_CPX_STATUS, {
      websocket,
      params: { s_sessionId: sessionId },
    });
  }

  /**
   * Permet de définir le lecteur de carte vitale
   * @param {String} cardReaderName le nom du lecteur de carte
   * @param {Number} readerIndex l'index du lecteur
   */
  async watchVitaleCardReader (cardReaderName, readerIndex, websocket = null, sessionId = null) {
    if (! sessionId) {
      sessionId = this.sessionId;
    }

    await this.sendCommand(ICANOPEE_COMMANDS.GET_VITALE_CARD, {
      websocket,
      params: {
        i_readerNumber: readerIndex,
        s_readerName: cardReaderName,
        s_sessionId: sessionId,
      },
    });

    await this.sendCommand(ICANOPEE_COMMANDS.START_VITALE_CARD_MONITORING, {
      websocket,
      params: {
        s_sessionId: sessionId,
        i_checkingInterval: 0,
      },
    });
  }

  async fetchVitaleCardContent () {
    this.setCardReaderHasCard(CARD_TYPES.VITALE, true);
    this.setCardReaderContentLoading(CARD_TYPES.VITALE, true);
    // La lecture d'une CV en parallèle d'une action telle que la validation du code PIN peut provoquer
    // un chargement infini de la commande READ_VITALE_CARD, il est donc nécessaire de l'effectuer sur
    // une différente instance de websocket.
    const websocket = await this.createSynchronousWebsocket();
    if (this.cardReaders[CARD_TYPES.VITALE] === null || this.cardReaders[CARD_TYPES.VITALE].sessionId === null) {
      const cardReaders = await this.getCardReaders();
      this.autoSelectCardReader(cardReaders, CARD_TYPES.CPX);
      this.autoSelectCardReader(cardReaders, CARD_TYPES.VITALE);
    }
    let content = null;
    try {
      const { Patients } = await this.sendCommand(ICANOPEE_COMMANDS.READ_VITALE_CARD, {
        websocket,
        params: { s_sessionId: this.cardReaders[CARD_TYPES.VITALE].sessionId },
      });
      this.setCardReaderContent(CARD_TYPES.VITALE, Patients || null);
      content = Patients;
    } finally {
      this.setCardReaderContentLoading(CARD_TYPES.VITALE, false);
      websocket.close();
    }
    return content;
  }

  /**
   * Récupère la configuration des lecteurs de carte
   * @returns {Object} Le contenu persisté
   */
  getSavedCardReaders () {
    try {
      return JSON.parse(localStorage.getItem('cardReaders'));
    } catch (error) {
      return null;
    }
  }

  /**
   * Permet de sauvegarder le lecteur associé à un lecteur de carte
   * @param {CardType} cardType Le type de carte
   * @param {String} cardReaderName Le nom du lecteur de carte
   */
  saveCardReader (cardType, reader) {
    localStorage.setItem('cardReaders', JSON.stringify({
      ...this.getSavedCardReaders(),
      [CARD_TYPE_DEFINITIONS[cardType].localStorageKey]: reader.s_name || '',
    }));

    this.emitEvent(ICANOPEE_CARD_READER_EVENTS.CARD_READER_DEFINED, {
      cardReaderType: cardType,
      cardReader: reader,
    });
  }

  async reloadAndValidatePincode(pincode) {
    if ( !this.hasSession()) {
      /** needed to deal with "bad" refresh of page (pressing enter on url navigator instead of refresh key) */
      this.codePinToValidateAfterInit = pincode;
      return;
    }

    await this.validatePincode(pincode);
  }

  /**
   * @inheritdoc
   */
  async validatePincode (pincode) {
    const { index, name } = this.cardReaders[CARD_TYPES.CPX];

    this.setCardReaderContentLoading(CARD_TYPES.CPX, true);
    let result = {};

    try {
      await this.sendCommand(ICANOPEE_COMMANDS.GET_CPX_CARD, {
        params: {
          i_readerNumber: index,
          s_readerName: name,
          s_sessionId: this.sessionId,
        },
      });
      result = await this.sendCommand(ICANOPEE_COMMANDS.READ_CPX_CARD, {
        params: {
          s_sessionId: this.sessionId,
          i_returnCertificates: 1,
          s_pinCode: pincode.toString(),
        },
      });
    } finally {
      this.setCardReaderContentLoading(CARD_TYPES.CPX, false);
    }

    // Lors d'une récupération avec succès des informations (Code PIN OK)
    if (result.s_authenticationCertificatePEM) {
      this.setCardReaderContent(CARD_TYPES.CPX, result);
    }

    // Lors d'une récupération avec échec des informations (Code PIN KO)
    if (result.i_apiErrorCode === 7) {
      this.setCardReaderContent(CARD_TYPES.CPX, null);
      throw ERROR_MESSAGES.INVALID_CODE;
    }

    // Lors d'une récupération avec carte bloquée (Trop d'essais ratés)
    if (result.i_apiErrorCode === 8) {
      this.setCardReaderContent(CARD_TYPES.CPX, null);
      throw ERROR_MESSAGES.LOCKED_CARD;
    }

    const tlsiConnectorPayload = {
      s_pinCode: pincode,
      s_sessionId: this.sessionId,
      s_tlsiUrl: EPRESCRIPTION_TLSI_URL,
      i_transactionsTimeout: 30,
      s_practiceSetting: 'AMBULATOIRE',
      i_cpsPracticeLocationIndice: 0,
    };

    // Utilisation en dur avec une seule CPS compatible - Mode preuve
    const cpxContent = this.cards[CARD_TYPES.CPX].content;
    if (cpxContent?.PracticeLocations?.[0]?.i_practiceLocationExerciceMode === 3) {
      tlsiConnectorPayload.PracticeLocation = {
        s_practiceLocationName: hardCodedPracticeLocation.s_practiceLocationName,
        s_practiceLocationActivitySector: hardCodedPracticeLocation.s_practiceLocationActivity,
        s_practiceLocationStructureId: hardCodedPracticeLocation.s_practiceLocationStructureId,
        s_practiceLocationPracticeSettings: 'AMBULATOIRE',
      };
    }

    await this.sendCommand(ICANOPEE_COMMANDS.CREATE_TLSI_CONNECTOR, { params: tlsiConnectorPayload });
  }

  /**
   * @inheritdoc
   */
  getCardReaderEvents () {
    return ICANOPEE_CARD_READER_EVENTS;
  }

  /**
 * Permet de récupérer les informations de la carte vitale à partir de l'index du patient concerné
 * @param {Number} patientIndex
 * @returns {Object} PatientData - VitaleData
 */
  async getVitaleInfos (patientIndex) {
    const websocket = await this.createSynchronousWebsocket();
    const { sessionId, index, name } = this.cardReaders[CARD_TYPES.VITALE];
    const vitaleInfos = await this.sendCommand(ICANOPEE_COMMANDS.GET_VITALE_INFOS, {
      websocket,
      params: {
        i_vitalePatientIndex: patientIndex,
        i_readerNumber: index,
        s_readerName: name,
        s_sessionId: sessionId,
      },
    });
    websocket.close();
    return vitaleInfos;
  }

  /**
   * Permet de générer un template XML pour un téléservice
   * @param {Object} softwareInformations
   * @param {Object} options
   * @returns {String} le template au format XML
   */
  async getTeleserviceXmlTemplate (softwareInformations, options = {}) {
    const { tlsiCommand, service, operation, version, payload } = options;
    const teleserviceXmlTemplatePayload = {
      s_sessionId: this.sessionId,
      LpsInfos: {
        s_idam: softwareInformations.idam,
        s_numAM: softwareInformations.idam_type,
        s_instance: softwareInformations.instance,
        s_name: `${softwareInformations.software_name}:${softwareInformations.software_version}`,
        s_version: softwareInformations.properVersion,
      },
      s_version: version || '1.2.1',
      s_service: service,
      s_operation: operation,
      i_isDevMode: DEPLOY_ENV_TYPE === 'development' ? 1 : 0,
      ...payload,
    };

    // Utilisation en dur avec une seule CPS compatible - Mode preuve. Pour le remplaçant ?
    const cpxContent = this.cards[CARD_TYPES.CPX].content;
    if (cpxContent?.PracticeLocations?.[0]?.i_practiceLocationExerciceMode === 3) {
      teleserviceXmlTemplatePayload.LpsInfos.s_billingNumber = hardCodedPracticeLocation.s_practiceLocationBillingNumber.slice(1);
    }

    const result = await this.sendCommand(tlsiCommand, {
      params: teleserviceXmlTemplatePayload,
      needed: 's_answerBodyBuffer',
    });
    if (result.s_status !== 'OK') {
      const messageCardType = result.s_apiErrorTypeDescription === 'VitaleError'
                            && result.s_apiErrorDescription === 'Card not found.' ? 'vitale' : 'CPS';
      throw new Error(
        `Impossible de créer une connexion au TLSi.\nVeuillez vérifier que votre carte ${messageCardType} est bien présente dans le lecteur`
      );
    }
    return Buffer.from(result.s_answerBodyBuffer, 'base64').toString();
  }

  /**
   * Permet de générer le template (XML) qui servira à contacter le téléservice e-Prescription
   * @param {Object} softwareInformations Les informations du LPS
   * @param {String} operation
   * @returns {String}
   */
  getEPrescriptionTemplate(softwareInformations, operation = 'creerEPrescription') {
    softwareInformations.properVersion = softwareInformations.eprescription_version;

    return this.getTeleserviceXmlTemplate(softwareInformations, {
      tlsiCommand: ICANOPEE_COMMANDS.GENERATE_EMPTY_TLSI_REQUEST_WITHOUT_PATIENT_DATA,
      service: 'serviceaccueileprescription',
      operation,
    });
  }

  /**
   * Permet de générer le template (XML) d'un téléservice à partir de l'index d'un assuré
   * @param {Object} softwareInformations Les informations du LPS
   * @param {*} patientIndex  l'index de l'assuré sur la carte vitale
   * @param {{ service, operation, version }} options Le nom du service  de l'opération et de numéro de version du téléservice
   * @param {Boolean} isApCv indique si l'identification est faite par l'appli CV
   * @returns
   */
  async getEmptyTlsiRequestTemplate (softwareInformations, patientIndex, { service, operation, version }, isApCV) {
    const payload = { i_vitalePatientIndex: patientIndex };
    if (isApCV) {
      payload.i_useApCvContext = 1;
    } else {
      await this.sendCommand(ICANOPEE_COMMANDS.GET_VITALE_CARD, {
        params: {
          i_readerNumber: this.cardReaders[CARD_TYPES.VITALE].index,
          s_readerName: this.cardReaders[CARD_TYPES.VITALE].name,
          s_sessionId: this.sessionId,
        },
      });
      await this.sendCommand(ICANOPEE_COMMANDS.READ_VITALE_CARD, { params: { s_sessionId: this.sessionId } });
    }

    return this.getTeleserviceXmlTemplate(softwareInformations, {
      tlsiCommand: ICANOPEE_COMMANDS.GENERATE_EMPTY_TLSI_REQUEST,
      service,
      operation,
      version,
      payload,
    });
  }

  /**
   * Permet de générer le template (XML) qui servira à contacter le téléservice DMTi
   * @param { Object } softwareInformations
   * @param { Number | { RightsHolderNir, BeneficiaryVitaleData }} vitaleData l'index du patient
   * ou un objet constitué des données du bénéficiaire ainsi que du nir de l'ayant droit
   * @param {Boolean} isApCv indique si l'identification est faite par l'appli CV
   * @returns
   */
  getDmtiTemplate (softwareInformations, vitaleData, isApCv) {
    const xmlTemplateRequestParams = {
      service: 'MT',
      operation: 'TeledeclarerMT',
      version: '2.1.0',
    };
    softwareInformations.properVersion = softwareInformations.tlsi_version;

    return this.getEmptyTlsiRequestTemplate(softwareInformations, vitaleData, xmlTemplateRequestParams, isApCv);
  }

  async getApCvContext (softwareInformations, apCvProfile, pincode) {
    const response = await this.createApCvConnector(pincode);

    if (response.s_status !== 'OK') {
      return response;
    }

    const apCvContextPayload = {
      LpsInfos: {
        s_idam: softwareInformations.idam,
        s_numAM: softwareInformations.idam_type,
        s_instance: softwareInformations.instance,
        s_name: `${softwareInformations.software_name}:${softwareInformations.software_version}`,
        s_version: softwareInformations.apcv_version,
      },
      s_dataInBase64: apCvProfile,
      s_commandName: ICANOPEE_COMMANDS.GET_APCV_CONTEXT,
      s_sessionId: this.sessionId,
    };
    return this.sendCommand(ICANOPEE_COMMANDS.GET_APCV_CONTEXT, { params: apCvContextPayload });
  }

  async createApCvConnector (pincode) {
    return this.sendCommand(ICANOPEE_COMMANDS.CREATE_APCV_CONNECTOR, {
      params: {
        s_apcvUrl: APCV_AUTH_URL,
        i_transactionsTimeout: 30,
        s_practiceSetting: 'AMBULATOIRE',
        i_cpsPracticeLocationIndice: 0,
        s_sessionId: this.sessionId,
        s_pinCode: pincode,
      },
    });
  }

  async releaseApCvContext () {
    await this.sendCommand(ICANOPEE_COMMANDS.RELEASE_APCV_CONTEXT);
  }

  /**
   * Permet de générer le template (XML) qui servira à contacter le téléservice ALDI
   * @param {Object} softwareInformations
   * @param {Number} patientIndex
   * @param {Boolean} isApCv indique si l'identification est faite par l'appli CV
   * @returns
   */
  async getAldiTemplate(softwareInformations, patientIndex, isApCv) {
    softwareInformations.properVersion = softwareInformations.tlsi_version;

    return this.getEmptyTlsiRequestTemplate(softwareInformations, patientIndex, {
      service: 'ald',
      operation: 'lister',
      version: '1.0.0',
    }, isApCv);
  }

  /**
   * Permet de générer le template (XML) qui servira à contacter le téléservice Imti
   * @param {Object} softwareInformations
   * @param {Number|{ RightsHolderNir, BeneficiaryVitaleData }} vitaleData l'index du patient
   * ou un objet constitué des données du bénéficiaire ainsi que du nir de l'ayant droit
   * @param {Boolean} isApCv indique si l'identification est faite par l'appli CV
   * @returns
   */
  // eslint-disable-next-line consistent-return
  getImtiTemplate(softwareInformations, vitaleData, isApCv) {
    const xmlTemplateRequestParams = {
      service: 'MT',
      operation: 'LireMT',
      version: '3.0.0',
    };
    softwareInformations.properVersion = softwareInformations.tlsi_version;

    if (vitaleData instanceof Object) {
      return this.getTeleserviceXmlTemplate(softwareInformations, {
        ...xmlTemplateRequestParams,
        tlsiCommand: ICANOPEE_COMMANDS.GENERATE_EMPTY_TLSI_REQUEST_WITH_RAW_VITALE_DATA,
        payload: {
          RightsHolderNir: vitaleData.RightsHolderNir,
          BeneficiaryVitaleData: vitaleData.BeneficiaryVitaleData,
        },
      });
    }
    if (! Number.isNaN(vitaleData) && vitaleData >= 0) {
      return this.getEmptyTlsiRequestTemplate(softwareInformations, vitaleData, xmlTemplateRequestParams, isApCv);
    }
  }


  /**
   * Permet de générer le template (XML) sans données patient qui servira à contacter le téléservice Aati
   * @param {Object} softwareInformations
   * @param {String} operation nom de la requête
   * @returns
   */
  getAatiResourceTemplate(softwareInformations, operation) {
    softwareInformations.properVersion = softwareInformations.aati_version;

    return this.getTeleserviceXmlTemplate(softwareInformations, {
    tlsiCommand: ICANOPEE_COMMANDS.GENERATE_EMPTY_TLSI_REQUEST_WITHOUT_PATIENT_DATA,
      service: 'aat',
      operation,
      version: '4.1.0',
    });
  }

  /**
   * Permet de générer le template (XML) avec données patient qui servira à contacter le téléservice Aati
   * @param {Object} softwareInformations
   * @param {Number|{ RightsHolderNir, BeneficiaryVitaleData }} vitaleData l'index du patient
   * ou un objet constitué des données du bénéficiaire ainsi que du nir de l'ayant droit
   * @param {Boolean} isApCv indique si l'identification est faite par l'appli CV
   * @returns {Promise<String|null>} Le template XML ou null si les données ne sont pas valides
   */
  async getAatiTemplate (softwareInformations, vitaleData, isApCv) {
    const xmlTemplateRequestParams = {
      service: 'aat',
      operation: 'CreerFluxAAT',
      version: '4.4.0',
    };
    softwareInformations.properVersion = softwareInformations.aati_version;

    if (vitaleData instanceof Object) {
      return this.getTeleserviceXmlTemplate(softwareInformations, {
        ...xmlTemplateRequestParams,
        tlsiCommand: ICANOPEE_COMMANDS.GENERATE_EMPTY_TLSI_REQUEST_WITH_RAW_VITALE_DATA,
        payload: {
          RightsHolderNir: vitaleData.RightsHolderNir,
          BeneficiaryVitaleData: vitaleData.BeneficiaryVitaleData,
        },
      });
    }

    if (! Number.isNaN(vitaleData) && vitaleData >= 0) {
      return this.getEmptyTlsiRequestTemplate(
        softwareInformations,
        vitaleData,
        xmlTemplateRequestParams,
        isApCv
      );
    }

    return null;
  }
}

export default new ICanopeeCardReader();