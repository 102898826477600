import ApiEntity from '@/models/ApiEntity';
import { getFromAPI, postToAPI, deleteFromAPI } from '@/services/api';

export default class WidgetFavoriteEntity extends ApiEntity {

  constructor (options = {}) {
    super(options);
    this.widgetName = options.widgetName || null;
    this.entity = options.entity || null;
  }

  /**
   * Permet de récupérer les entités favorites associées à un widget
   * @param { String } widgetName - nom du widget Synapse
   * @returns { Array }
   */
  static async fetchAll (widgetName) {
    const response = await getFromAPI('/api/widget_favorite_entities', { widgetName });
    return response.data['hydra:member'].map(item => new WidgetFavoriteEntity(item));
  }

  /**
   * Insère un favori associé à un widget
   * @param { String } widgetName - nom du widget Synapse
   * @param { Object } entity - Entité Synapse à enregistrer comme favori
   * @returns { WidgetFavoriteEntity }
   */
  static async insert (widgetName, entity) {
    const { data } = await postToAPI('/api/widget_favorite_entities', {
      widgetName,
      entity,
    });
    return new WidgetFavoriteEntity(data);
  }

  /**
   * Supprime un favori associé à un widget
   * @param { WidgetFavoriteEntity } widgetFavoriteEntity - Entité à supprimer des favoris
   */
  static async delete (widgetFavoriteEntity) {
    await deleteFromAPI(widgetFavoriteEntity['@id']);
  }
}