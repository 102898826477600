import BaseFile from './BaseFile';
import { getFileFromAPI } from '@/services/api';

export default class PdfFile extends BaseFile {

  async fileGetter () {
    const response = await getFileFromAPI(this.fetchUrl);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const fileName = response.headers['content-disposition']?.split('filename=')[1];
    return {
      blob,
      name: fileName,
    };
  }

  async open () {
    const file = await this.getFile();
    window.open(file.blobUrl);
  }

}