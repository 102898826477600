<template>
  <n-tooltip
    v-slot="{ on, attrs }"
    :text="cardButtonTooltip"
  >
    <div
      v-bind="attrs"
      class="card-button d-flex align-center justify-center mr-1"
      type="button"
      :class="{'disabled': currentCardStatus === CARD_STATUS.LOADING }"
      v-on="{ ...on, click: (e) => onClick(e) }"
    >
      <v-progress-circular
        v-if="isLoadingCard && type === CARD_TYPES.VITALE"
        indeterminate
        color="primary"
        size="24"
        width="4"
        class="ml-2 mr-4"
      />
      <v-badge
        v-else
        filled
        overlap
        :color="currentCardStatus.color"
        dot
        :offset-x="type === CARD_TYPES.VITALE ? 20 : 10"
        offset-y="10"
      >
        <app-button
          v-if="type === CARD_TYPES.VITALE"
          text
        >
          <svg
            height="22"
            width="32"
            class="icon-vital-card"
          >
            <use xlink:href="#icon-vital-card" />
          </svg>
        </app-button>
        <app-button
          v-else
          icon="cpx"
          :color="color"
        />
      </v-badge>
      <app-modal-form
        ref="cardReaderSelectForm"
        :is-opened="isCardReaderModalOpened"
        :form="CardReaderSelectForm"
        title="Sélection des lecteurs de carte"
        @cancel="isCardReaderModalOpened = false"
        @submitSuccess="isCardReaderModalOpened = false"
      />
    </div>
  </n-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

import { CARD_STATUS } from '@/services/card-reader/constants/cardStatus';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';

import AppModalForm from '@/components/ui/modal/AppModalForm.vue';
import CardReaderSelectForm from '@/forms/card-reader/CardReaderSelectForm.vue';



export default {
  name: 'CardReaderStatusIcon',
  components: { AppModalForm },
  props: {
    type: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data () {
    return { isCardReaderModalOpened: false };
  },
  computed: {
    ...mapGetters('cardReaders', ['getIsLoadingCard', 'getCardReader', 'getCardContent', 'getHasCard']),
    CARD_TYPES: () => CARD_TYPES,
    CARD_STATUS: () => CARD_STATUS,
    CardReaderSelectForm: () => CardReaderSelectForm,
    isLoadingCard () {
      return this.getIsLoadingCard(this.type);
    },
    cardReader () {
      return this.getCardReader(this.type);
    },
    hasCard () {
      return this.getHasCard(this.type);
    },
    hasContent () {
      return this.getCardContent(this.type);
    },
    currentCardStatus () {
      if (! this.cardReader) {
        return CARD_STATUS.NO_READER;
      }

      if (this.isLoadingCard) {
        return CARD_STATUS.LOADING;
      }

      if (this.hasCard) {
        if (! this.hasContent) {
          return CARD_STATUS.UNKNOWN_CONTENT;
        }

        return CARD_STATUS.CONTENT_READY;
      }

      return CARD_STATUS.NO_CARD;
    },
    cardButtonTooltip () {
      if (this.currentCardStatus === CARD_STATUS.NO_READER) {
        return 'Aucun lecteur de carte assigné';
      }

      if (this.currentCardStatus === CARD_STATUS.LOADING) {
        return 'Lecture en cours';
      }

      if (this.currentCardStatus === CARD_STATUS.UNKNOWN_CONTENT) {
        return 'Carte non synchronisée';
      }

      if (this.currentCardStatus === CARD_STATUS.CONTENT_READY) {
        return this.type === CARD_TYPES.VITALE
          ? 'Importer depuis la carte vitale'
          : 'Carte CPS synchronisée';
      }

      return 'Aucune carte détectée';
    },
  },
  watch: {
    currentCardStatus (currentCardStatus) {
      this.$emit('statusChange', currentCardStatus);
    },
    isCardReaderModalOpened (isCardReaderModalOpened) {
      if (isCardReaderModalOpened) {
        this.$refs.cardReaderSelectForm.getForm()?.refreshCardReaders();
      }
    },
  },
  methods: {
    onClick () {
      if ([CARD_STATUS.NO_READER, CARD_STATUS.NO_CARD]
        .indexOf(this.currentCardStatus) > - 1) {
        this.isCardReaderModalOpened = true;
      } else if (this.currentCardStatus !== CARD_STATUS.LOADING) {
        this.$emit('click', this.currentCardStatus);
      }
    },
  },
};
</script>