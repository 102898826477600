import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import NovaTools from '@/nova-tools/NovaTools';

import DocumentMetaDatasetModal from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/DocumentMetaDatasetModal.vue';

export default class MessagingBioResultsViewerAction extends ActionMenuItem {
  constructor (document) {
    if (! document) {
      throw new Error('Un document est requis pour cette action');
    }
    super('magnifying-glass-plus', 'Détails', () => {
      const { id } = NovaTools.modal.append(DocumentMetaDatasetModal, {
        value: true,
        title: document.title,
        documentMetaDataset: document.metaDataset,
      }, { cancel: () => NovaTools.modal.destroy(id) });
    }, { disabled: ! document.metaDataset });
  }
}