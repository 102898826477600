import { ICONS } from '@/constants';

export default {
  props: {
    /**
     * Valeur du champ
     */
    value: {
      type: [String, Number, Boolean, Array, Object],
      required: false,
      default: '',
    },
    /**
     * Nom du champ
     */
    label: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * L'identifiant du champ pour l'API
     * Utilisé dans le VID par exemple
     */
    name: {
      type: String,
      required: false,
      default: '',
    },
    hint: {
      type: String,
      default: null,
    },
    /**
     * Des règles de validation séparées par | (pipe)
     * @see Voir les règles disponibles : https://logaretm.github.io/vee-validate/guide/rules.html#rules
     */
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    /**
     * Le mode de validation du champ
     * @see {@link https://vee-validate.logaretm.com/v2/guide/interaction.html#interaction-modes}
     * @default null
     * @values aggressive, passive, lazy, eager
     */
    validationMode: {
      type: String,
      default: null,
    },
    /**
     * Permet de ne pas afficher le label
     * Utile dans le cas où son affichage est déporté
     */
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Permet de ne pas afficher les erreurs
     * Utile dans le cas où il n'y a pas la place
     */
    hideErrors: {
      type: [Boolean, String],
      required: false,
      default: 'auto',
    },
    /**
     * Permet de ne pas afficher les détails
     * Utile dans le cas où il n'y a pas la place
     */
    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: 'auto',
    },
    /**
     * Icône à afficher à gauche du champ
     * @default null
     */
    prependIcon: {
      type: String,
      default: null,
    },
    /**
     * Icône à afficher à l'intérieur gauche du champ
     */
    prependInnerIcon: {
      type: String,
      default: null,
    },
    /**
     * Icône à afficher à droite du champ
     * @default null
     */
    appendIcon: {
      type: String,
      default: null,
    },
    /**
     * Affiche le champ dans une taille réduite
     * @default false
     */
    dense: {
      type: Boolean,
      default: false,
    },
    /**
     * Désactive le champ
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le champ en mode lecture seule
     * @default false
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche une croix pour réinitialiser le contenu
     */
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { localValue: '' };
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value;
      },
    },
    localValue () {
      /**
       * Emet un evenement input à chaque changement de valeur
       * @param {String} value - La nouvelle valeur du champ
       */
      this.$emit('input', this.localValue);
    },
  },
  computed: {
    /**
     * On ne transmet pas @input aveuglément au composant vuetify,
     * car c'est déjà géré par la mixin.
     * Sans cela, @input sera émit 2 fois (par la mixin et le composant vuetify).
     */
    listeners () {
      return {
        ...this.$listeners,
        input: () => {},
      };
    },
    isRequired () {
      if (typeof this.rules === 'string') {
        return this.rules.includes('required');
      }
      return this.rules?.required || false;
    },
    fieldLabel () {
      if (this.showLabel) {
        if (this.isRequired && ! (typeof this.rules === 'string' && this.rules.includes('hide'))) {
          return `${this.label} *`;
        }
        return this.label;
      }
      return '';
    },
    fieldIcon () {
      // En attendant la migration complète, des icônes personnalisés peuvent être renseignés pour le moment
      return {
        prepend: ICONS[this.prependIcon] || this.prependIcon,
        prependInner: ICONS[this.prependInnerIcon] || this.prependInnerIcon,
        append: ICONS[this.appendIcon] || this.appendIcon,
      };
    },
    fieldValidationMode () {
      if (this.isRequired && ! this.validationMode) {
        return 'aggressive';
      }
      return this.validationMode || 'eager';
    },
  },
  methods: {
    validate () {
      if (this.$children[0].validate) {
        this.$nextTick(() => this.$children[0].validate());
      } else {
        throw new Error('Aucune méthode de validation n\'est disponible');
      }
    },
    removeErrors () {
      this.$refs.validator?.reset();
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur();
      }
    },
  },
};