<template>
  <app-icon
    :icon="icon"
    :size="size"
  />
</template>

<script>
/**
 * Une icône permettant d'identifier un fichier de part son type
 */
export default {
  name: 'AppFileIcon',
  props: {
    /**
     * Le type du fichier
     */
    type: {
      type: String,
      default: null,
    },
    /**
     * La taille de l'icône à afficher
     */
    size: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    icon () {
      if (this.type?.startsWith('image')) {
        return 'image';
      }
      if (this.type === 'application/pdf') {
        return 'file-pdf';
      }
      if (this.type === 'application/zip') {
        return 'file-zip';
      }
      return 'file';
    },
  },
};
</script>