import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

export default class DocumentEntity {
  /**
   * Nom de l'entité
   */
  name;

  /**
   * Description de l'entité
   */
  description;

  /**
   * Le nom du fichier sérialisé
   */
  filename;

  /**
   * Ensemble des champs de type "DocumentEntityFields"
   */
  fields = [];

  /**
   * Permet de construire un DocumentEntity
   * @param {String} name Nom de l'entité
   */
  constructor (name, filename, description) {
    if (! name) {
      throw new Error('Le nom de l\'entité doit être fourni');

    }
    if (! filename) {
      throw new Error('Le nom de fichier de la sérialisation de l\'entité doit être fourni');
    }
    if (! description) {
      throw new Error('La description de l\'entité doit être fournie');
    }

    this.name = name;
    this.filename = filename;
    this.description = description;

    this.addField(new DocumentEntityField('id', 'Identifiant unique (UUID)'));
  }

  /**
   * Permet d'ajouter un champ pour une entité
   * @param {DocumentEntityField} field Le champ d'entité à ajouter
   */
  addField (field) {
    this.fields.push(field);
  }

  /**
   *
   * @param {String} fieldName Nom du champ à récupérer
   * @returns {DocumentEntityField} Instance du champ associé
   */
  getFieldByName (fieldName) {
    return this.fields.find(field => field.name === fieldName);
  }
}