<template>
  <div
    v-tooltip="{ right: true, text: tooltipText, disabled: isTooltipDisabled }"
    v-ripple="! action.disabled || action.loading"
    v-test="'action'"
    class="app-actions-menu-item"
    :class="actionClassList"
    @click="handleClick"
  >
    <app-circular-loader
      v-if="action.loading"
      v-test="'action-loading-spinner'"
      :color="textColor"
      :size="16"
      :width="2"
    />
    <div
      v-else
      class="app-actions-menu-item__icon"
    >
      <app-icon
        v-if="action.icon"
        v-test="'action-icon'"
        :color="textColor"
        :icon="action.icon"
      />
    </div>
    <div
      :class="`${textColor}--text`"
      class="app-actions-menu-item__text"
    >
      <p
        v-test="'action-title'"
        class="app-actions-menu-item__text__title"
      >
        {{ action.text }}
      </p>
      <p
        v-if="action.subtitle"
        class="app-actions-menu-item__text__subtitle"
      >
        {{ action.subtitle }}
      </p>
    </div>
    <slot name="append" />
  </div>
</template>

<script>
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';

import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';

export default {
  name: 'AppActionsMenuItem',
  components: { AppCircularLoader },
  props: {
    action: {
      type: ActionMenuItem,
      required: true,
    },
  },
  computed: {
    textColor () {
      return this.action.important ? 'error' : 'content';
    },
    tooltipText () {
      return (this.action.disabled && this.action.disabledTooltip) ? this.action.disabledTooltip : null;
    },
    isTooltipDisabled () {
      return ! this.tooltipText;
    },
    actionClassList () {
      const itemBaseClassName = 'app-actions-menu-item';
      return {
        [`${itemBaseClassName}--disabled`]: this.action.disabled,
        [`${itemBaseClassName}--loading`]: this.action.loading,
        [`${itemBaseClassName}--important`]: this.action.important,
      };
    },
  },
  methods: {
    handleClick () {
      if (this.action.disabled || this.action.loading) {
        return;
      }
      this.action.callback?.();
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
$list-white-space: map-get($spacers, 4);

.app-actions-menu-item {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 $list-white-space;
  gap: $list-white-space;

  &:not(&--disabled):hover:before {
    opacity: .05;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &--disabled,
  &--loading {
    opacity: .5;
    cursor: default;
  }

  &--important {
    color: var(--v-error-base) !important;
  }

  &__icon {
    display: flex;
    align-items: center;
    width: $list-white-space;
  }

  &__text {
    line-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__title {
      line-height: 1.2em;
      font-size: 13px;
      font-weight: 600;
    }

    &__subtitle {
      font-size: 12px;
      opacity: .6;
    }
  }
}
</style>