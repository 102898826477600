<template>
  <div>
    <h3 class="subtitle-3 mb-1">
      Prescripteur
    </h3>
    <app-row>
      <app-col cols="12" md="6">
        <div class="flex-column flex-list-1">
          <app-dataset-item v-test="'practitioner-full-name'" label="Nom complet" :value="practitionerFullName" />
          <app-dataset-item v-test="'practitioner-rpps'" label="Numéro RPPS" :value="prescriber.rpps" />
        </div>
      </app-col>
    </app-row>
    <app-row>
      <app-col cols="12" md="6">
        <app-select
          v-model="currentLocation"
          v-test="'practitioner-structure'"
          :items="practiceLocations"
          label="Lieu d'exercice"
          :item-text="getPracticeLocationText"
          :item-value="location => location"
          return-object
          rules="required"
          @input="handlePracticeLocationSelection"
        />
      </app-col>
    </app-row>
    <app-row v-if="currentLocation" v-test="'structure-group-inputs'">
      <app-col cols="12" md="4">
        <app-text-field
          v-test="'am-structure-input'"
          :value="prescriber.amStructure"
          rules="required|length:9"
          label="Numéro AM"
          :readonly="!!prescriber.amStructure"
        />
      </app-col>
      <app-col cols="12" md="4">
        <app-text-field
          v-test="'finess-structure-input'"
          :value="prescriber.finessStructure"
          rules="length:9"
          label="FINESS"
          validation-mode="aggressive"
          @input="handlePrescriberFinessStructureInput"
        />
      </app-col>
      <app-col cols="12" md="4">
        <app-text-field
          v-test="'siret-structure-input'"
          :value="prescriber.siretStructure"
          rules="length:14"
          label="SIRET"
          validation-mode="aggressive"
          @input="handlePrescriberSiretStructureInput"
        />
      </app-col>
      <app-col cols="12">
        <app-text-field
          v-test="'address-structure-input'"
          :value="prescriber.address"
          :rules="{ max: '300', required: isStructureRequired }"
          validation-mode="aggressive"
          label="Adresse"
          class="pt-0"
          @input="handlePrescriberAddressInput"
        />
      </app-col>
    </app-row>
  </div>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';

export default {
  name: 'AatiPrescriberForm',
  components: {
    AppTextField,
    AppSelect,
    AppDatasetItem,
  },
  props: {
    prescriber: {
      type: Object,
      required: true,
    },
    practiceLocations: {
      type: Array,
      required: true,
    },
  },
  data () {
    return { currentLocation: null };
  },
  computed: {
    isStructureRequired () {
      return ! this.prescriber.isLiberal;
    },
    practitionerFullName () {
      return `${this.prescriber.firstName} ${this.prescriber.familyName}`;
    },
  },
  watch: {
    practiceLocations: {
      immediate: true,
      handler () {
        this.currentLocation = this.prescriber.am
          ? this.practiceLocations.find(loc => loc.s_practiceLocationBillingNumber.slice(1) === this.prescriber.am)
          : this.practiceLocations[0];
        this.handlePracticeLocationSelection(this.currentLocation);
      },
    },
  },
  methods: {
    getPracticeLocationText (location) {
      return location.s_practiceLocationName;
    },
    handlePrescriberAddressInput (prescriberAddress) {
      this.$emit('update:prescriber', {
        ...this.prescriber,
        address: prescriberAddress,
      });
    },
    handlePrescriberFinessStructureInput (prescriberFinessStructure) {
      this.$emit('update:prescriber', {
        ...this.prescriber,
        finessStructure: prescriberFinessStructure,
      });
    },
    handlePrescriberSiretStructureInput (prescriberSiretStructure) {
      this.$emit('update:prescriber', {
        ...this.prescriber,
        siretStructure: prescriberSiretStructure,
      });
    },
    handlePracticeLocationSelection (location) {
      if (location) {
        this.$emit('update:prescriber', {
          ...this.prescriber,
          isLiberal: location.i_practiceLocationExerciceMode === 1,
          am: location.s_practiceLocationBillingNumber.slice(1),
          company: location.s_practiceLocationName,
          amStructure: location.s_practiceLocationBillingNumber.slice(1),
        });
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.row+.row {
  margin-top: 0 !important;
}
</style>