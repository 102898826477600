<template>
  <app-form
    :submit-method="submit"
    class="start-consultation-form"
  >
    <n-alert message="Une consultation a déjà eu lieu aujourd'hui pour ce patient. Souhaitez-vous la continuer ou en créer une nouvelle ?" />
    <div class="start-consultation-form__list">
      <medical-history-consultation
        v-for="(item) in consultations"
        :key="item['@id']"
        :consultation="item"
        @edition="$emit('close')"
      />
    </div>
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue';
import MedicalHistoryConsultation
  from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryConsultation.vue';
import NAlert from '@/nova-ui/NAlert/NAlert.vue';

export default {
  name: 'StartConsultationForm',
  components: {
    NAlert,
    MedicalHistoryConsultation,
    AppForm,
  },
  props: {
    consultations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    submit () {
      this.$emit('submitSuccess');
    },
  },
};
</script>
<style scoped lang="scss">
.start-consultation-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 40vh;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include media-md {
      overflow-y: auto;
      padding: 0 4px;
    }
  }
}
</style>