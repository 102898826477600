<template>
  <div class="app-list-header">
    <header class="app-list-header__row">
      <h1
        class="app-list-header__title mb-2"
        data-test="app-list-header-label"
      >
        {{ formattedCount }}
      </h1>
      <slot name="list-header-right" />
    </header>
  </div>
</template>

<script>
import { pluralize } from '@/utils/functions/words';

export default {
  name: 'AppListHeader',
  props: {
    /**
     * Nom des entités listées - i.e: "patient / facture"
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Texte à ajouter en fin de label
     */
    nameSuffix: {
      type: String,
      default: '',
    },
    /**
     * Si le nom des entités est féminin
     * @default false
     */
    feminize: {
      type: Boolean,
      default: false,
    },
    /**
     * Indique si le chargement est en cours
     * @default false
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Nombre d'élément dans la liste
     */
    count: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    noneWord () {
      return `Aucun${this.feminize ? 'e' : ''}`;
    },
    formattedCount () {
      const spacedSuffix = this.nameSuffix ? ` ${this.nameSuffix}` : '';
      const casedName = this.name.toLowerCase();
      if (this.isLoading) {
        return `Récupération des ${pluralize(casedName, 2)}${spacedSuffix} ...`;
      }
      return `${this.count || this.noneWord} ${pluralize(casedName, this.count)}${spacedSuffix}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-list-header {
  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
  }
}
</style>