import axios from 'axios';

import modal from '@/nova-tools/modal/modal';

import DocumentViewerModal from '@/components/ui/viewer/DocumentViewerModal.vue';

export class Files {
  openDocumentViewer (document) {
    modal.appendDestroyOnClose(DocumentViewerModal, {
      document,
      title: document.title,
      isOpened: true,
    });
  }

  async getBase64Content (contentUrl) {
    const { data } = await axios.create().get(contentUrl, { responseType: 'blob' });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }

}

export default new Files();