<template>
  <div>
    <n-tooltip
      v-slot="{ on }"
      :disabled="!needTooltip"
      :text="tooltipText"
    >
      <p
        ref="text"
        class="ellipsis-text"
        v-on="on"
      >
        <!-- @slot le texte à afficher -->
        <slot />
      </p>
    </n-tooltip>
  </div>
</template>

<script>
/**
 * Permet d'afficher un texte dans l'application en le coupant si il dépasse de son conteneur
 * Si il est coupé, une tooltip affichera son contenu total
 */
export default {
  name: 'NTruncableText',
  data () {
    return {
      needTooltip: false,
      tooltipText: '',
      resObs: new ResizeObserver(this.handleResize),
    };
  },
  mounted () {
    this.resObs.observe(this.$refs.text);
    this.tooltipText = this.$refs.text.innerText;
  },
  beforeDestroy () {
    this.resObs.unobserve(this.$refs.text);
  },
  methods: {
    handleResize () {
      const { scrollWidth, clientWidth } = this.$refs.text;
      this.needTooltip = scrollWidth > clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>