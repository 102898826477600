import EventSetting from './EventSetting';

export default class AbsenceSettings extends EventSetting {

  /**
   * Intervalles historisés
   * (Début et fin)
   */
  #oldIntervals = null;

  constructor (options = {}) {
    super(options);
  }

  isWholeDay () {
    return this.intervals[0].start === '00:00' && this.intervals[0].end === '23:59';
  }

  setIsWholeDay (isWholeDay) {
    if (isWholeDay) {
      if (! this.isWholeDay()) {
        // On stocke les intervalles en cours pour pouvoir les restaurer plus tard
        this.#oldIntervals = { ...this.intervals[0] };
        this.intervals[0].start = '00:00';
        this.intervals[0].end = '23:59';
      }
    } else if (this.#oldIntervals) {
      // Si on a stocké les intervalles précédents, on les restaure
      this.intervals[0].start = this.#oldIntervals.start;
      this.intervals[0].end = this.#oldIntervals.end;
      this.#oldIntervals = null;
    } else {
      // Si on n'a pas d'intervalles (édition), on les vide
      this.intervals[0].start = null;
      this.intervals[0].end = null;
    }
  }
}