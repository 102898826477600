<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Adresse de visite"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <n-alert transparent type="info" :message="visitingAddressAlertText" />
      <n-radio-button-group
        v-model="localValue.source"
        :items="addressItems"
        required
      />
      <div v-if="localValue.source === '2'" v-test="'visiting-address-form'" class="px-4 pb-2">
        <app-row>
          <app-col cols="2">
            <app-text-field v-model="localValue.stair" rules="max:3" label="Escalier" />
          </app-col>
          <app-col cols="2">
            <app-text-field v-model="localValue.floor" rules="max:3" label="Étage" />
          </app-col>
          <app-col cols="3">
            <app-text-field v-model="localValue.apartment" rules="max:5" label="Appartement" />
          </app-col>
          <app-col cols="2">
            <app-text-field v-model="localValue.building" rules="max:3" label="Bâtiment" />
          </app-col>
          <app-col cols="3">
            <app-text-field v-model="localValue.code" rules="max:8" label="Code d'accès" />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="12">
            <app-text-field
              v-model="localValue.street"
              v-test="'street-input'"
              rules="required|max:38"
              label="N° et nom de la voie"
            />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="3">
            <app-text-field
              v-model="localValue.zipcode"
              v-test="'zipcode-input'"
              rules="required|numeric|max:5"
              label="Code postal"
            />
          </app-col>
          <app-col cols="9">
            <app-text-field
              v-model="localValue.city"
              v-test="'city-input'"
              rules="required|max:26"
              label="Ville"
            />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="3">
            <app-text-field
              v-model="localValue.phone"
              v-mask="'## ## ## ## ##'"
              rules="regex:^(?:\s*\d){10}$"
              label="Téléphone"
            />
          </app-col>
        </app-row>
      </div>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import AppTextField from '@/components/ui/form/AppTextField.vue';
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue';


export default {
  name: 'MedicalLeavePatientVisitingAddressFields',
  components: {
    AatiFormStep,
    AppTextField,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    patientAddress: {
      type: Object,
      default: null,
    },
    vitaleCardAddress: {
      type: Object,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 7,
      localValue: null,
      visitingAddressAlertText: [
        'Veuillez vérifier que le patient n\'a pas déménagé récemment et que l\'adresse sélectionnée',
        '(carte vitale ou dossier patient) correspond à l\'adresse connue de l\'Assurance Maladie.',
        '',
        'Dans le cas où une autre adresse est saisie et qu\'elle se situe hors du département de résidence du patient,',
        'veuillez l\'informer que l\'accord préalable de sa caisse de rattachement est obligatoire.',
      ].join('\n'),
    };
  },
  computed: {
    patientAddressText () {
      return this.buildAddressText(this.patientAddress);
    },
    patientAddressLabel () {
      return ['Adresse du dossier patient', this.patientAddressText].filter(el => !! el).join(' : ');
    },
    vitaleCardAddressText () {
      return this.buildAddressText(this.vitaleCardAddress);
    },
    vitaleCardAddressLabel () {
      return ['Adresse de la carte vitale', this.vitaleCardAddressText].filter(el => !! el).join(' : ');
    },
    addressItems () {
      return [
        {
          label: this.vitaleCardAddressLabel,
          value: '0',
          disabled: ! this.vitaleCardAddressText,
          disabledTooltip: 'Aucune adresse enregistrée en carte vitale',
        },
        {
          label: this.patientAddressLabel,
          value: '1',
          disabled: ! this.patientAddressText,
          disabledTooltip: 'Aucune adresse enregistrée dans le dossier patient',
        },
        {
          label: 'Autre adresse',
          value: '2',
        },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value;
      },
    },
    localValue: {
      deep: true,
      handler () {
        this.$emit('update:value', this.localValue);
      },
    },
    'localValue.source': {
      handler (newValue) {
        if (newValue !== '2') {
          this.localValue = {
            source: newValue,
            stair: null,
            floor: null,
            apartment: null,
            building: null,
            code: null,
            street: null,
            zipcode: null,
            city: null,
            phone: null,
          };
        }
      },
    },
  },
  methods: {
    buildAddressText (address) {
      if (! address) {
        return '';
      }
      const fullCity = [address.postalCode, address.city].filter(el => !! el).join(' ') || null;
      return [
        address.line1,
        address.line2,
        fullCity,
      ].filter(el => !! el).join(' - ');
    },
    buildAddressLabel (addressText) {
      return ['Adresse de visite habituelle', addressText].filter(el => !! el).join(' : ');
    },
  },
};
</script>
<style lang="scss" scoped>
.row+.row {
  margin-top: 0 !important;
}
</style>