import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/lib/locale/fr'
Vue.use(Vuetify)

/**
 * Permet de récupérer une instance de Vuetify avec la configuration passée en paramètre
 */
export default ({ theme = {} } = {}) => new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  iconfont: 'fa',
  theme: {
    options: { customProperties: true },
    themes: theme,
  },
})