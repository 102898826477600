import { state as messagingState, getters as messagingGetters, mutations as messagingMutations, actions as messagingActions } from '@/modules/secureMessaging/modules/messaging/store';
import { mapStoreResetter } from '@/utils/functions/store';

export const state = () => ({ ...messagingState() });

export const getters = { ...messagingGetters };

export const mutations = {
  ...mapStoreResetter(state),
  ...messagingMutations,
};

export const actions = { ...messagingActions };

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};