<template>
  <app-row>
    <app-col
      cols="12"
      md="6"
    >
      <correspondents-panel
        v-test="'patient-correspondents-panel'"
        :patient="patient"
      />
    </app-col>
    <app-col
      cols="12"
      md="6"
    >
      <relatives-panel
        v-test="'patient-relatives-panel'"
        :patient="patient"
      />
    </app-col>
  </app-row>
</template>

<script>

import Patient from '@/modules/patient/models/Patient';

import CorrespondentsPanel from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/CorrespondentsPanel.vue';
import RelativesPanel from '@/modules/patient/modules/patientFile/modules/correspondents/components/relativesPanel/PatientRelativesPanel.vue';

export default {
  name: 'PatientCorrespondentsModule',
  components: {
    RelativesPanel,
    CorrespondentsPanel,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
};
</script>