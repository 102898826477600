import { defaultEntityColor } from '@/constants/entityColors';
import ApiEntity from '@/models/ApiEntity';

export default class PatientKeyword extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.label = options.label || null;
    this.description = options.description || null;
    this.color = options.color || defaultEntityColor;
  }
}