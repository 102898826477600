<template>
  <app-navigation-drawer
    v-model="isEditing"
    :title="currentSlotStep.title"
    :on-previous="goToPreviousStep"
    class="free-appointment-slot-drawer"
    scrollable
    :loading="isFetchingNewAppointmentSlots"
    from-right
    @close="currentStepIndex = 0"
  >
    <v-carousel
      v-model="currentStepIndex"
      light
      hide-delimiters
      :show-arrows="false"
      :continuous="false"
      height="auto"
      class="free-appointment-slot-drawer__carrousel"
    >
      <v-carousel-item
        v-for="(step, _currentStepIndex) in steps"
        :key="'step' + _currentStepIndex"
      >
        <component
          :is="step.component"
          v-if="step.props.value"
          :show-divider="false"
          v-bind="step.props"
          v-on="step.events"
        />
      </v-carousel-item>
    </v-carousel>

    <section
      v-if="hasPermission('use_doctolib')"
      class="doctolib-choice"
    >
      <choice-divider />
      <app-button
        color="doctolib"
        text
        class="doctolib-button"
        @click="bookDoctolibAppointment"
      >
        <svg
          v-test="'doctolib-logo'"
          width="26px"
          height="26px"
          class="mr-4 icon-brand-doctolib"
        >
          <use xlink:href="#icon-brand-doctolib" />
        </svg>
        Prendre un rendez-vous sur Doctolib
      </app-button>
    </section>
  </app-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { DOCTOLIB } from '@/constants';
import Appointment from '@/modules/agenda/models/events/Appointment';
import FreeAppointmentSlot from '@/modules/patient/models/medicalHistory/FreeAppointmentSlot';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import ChoiceDivider from '@/components/ui/divider/ChoiceDivider.vue';
import AppNavigationDrawer from '@/components/ui/drawer/AppNavigationDrawer.vue';
import AppSimpleForm from '@/components/ui/form/AppSimpleForm.vue';
import FreeAppointmentSlotConfirm from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/drawerSteps/FreeAppointmentSlotConfirm.vue';
import FreeAppointmentSlotForm from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/drawerSteps/FreeAppointmentSlotForm.vue';
import FreeAppointmentSlotList from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/drawerSteps/FreeAppointmentSlotList.vue';

export default {
  name: 'FreeAppointmentSlotDrawer',
  components: {
    AppNavigationDrawer,
    ChoiceDivider,
  },
  props: {
    /**
     * Patient pour lequel le rendez-vous doit être pris
     */
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Instance de FreeAppointmentSlot
     * @model
     */
    value: {
      type: FreeAppointmentSlot,
      default: () => new FreeAppointmentSlot(),
    },
  },
  data () {
    return {
      AppSimpleForm,
      FreeAppointmentSlotForm,
      FreeAppointmentSlotList,
      FreeAppointmentSlotConfirm,
      currentStepIndex: 0,
      currentStartDate: new Date(),
      slots: [],
      isFetchingNewAppointmentSlots: false,
      newAppointment: null,
      firstFreeSlotDate: null,
      noFuturSlot: false,
    };
  },
  computed: {
    ...mapGetters('agenda', ['getScheduleFromIri', 'getAppointmentMotiveFromIri']),
    ...mapGetters('auth', ['hasPermission']),
    steps () {
      return [{
        title: 'Prendre un RDV',
        component: AppSimpleForm,
        props: {
          form: FreeAppointmentSlotForm,
          value: this.value,
          patient: this.patient,
          submitText: 'Rechercher un créneau',
        },
        events: {
          submitSuccess: this.showSlots,
          setSelectedDate: this.setSelectedDate,
        },
      }, {
        title: 'Prochains créneaux',
        component: FreeAppointmentSlotList,
        props: {
          value: this.value,
          patient: this.patient,
          freeAppointmentSlots: this.slots,
          startDate: new Date(this.currentStartDate),
          firstFreeSlotDate: new Date(this.firstFreeSlotDate),
          isLoading: this.isFetchingNewAppointmentSlots,
          noFuturSlot: this.noFuturSlot,
        },
        events: {
          'go-to-next-free-appointment-slots': this.goToNextFreeAppointmentSlots,
          'go-to-page-start-date-appointment-slots': this.goToPageStartDateAppointmentSlots,
          'time-click': this.bookAppointment,
        },
      }, {
        title: 'Confirmer le rendez-vous',
        component: FreeAppointmentSlotConfirm,
        props: { value: this.newAppointment },
        events: { 'appointment-booked': this.appointmentBooked },
      }];
    },
    currentSlotStep () {
      return this.steps[this.currentStepIndex];
    },
    isEditing: {
      get () {
        return !! this.value;
      },
      set (isEditing) {
        if (! isEditing) {
          this.$emit('input', null);
        }
      },
    },
  },
  watch: {
    value (value) {
      if (! value) {
        this.resetDrawer();
      }
    },
  },
  methods: {
    ...mapActions('agenda', ['fetchScheduleFreeAppointmentSlots']),
    resetDrawer () {
      this.slots = [];
      this.currentStepIndex = 0;
      this.$emit('cancel');
    },
    goToPreviousStep (closeDrawer) {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex -= 1;
      } else {
        closeDrawer();
      }
    },
    async goToNextFreeAppointmentSlots () {
      this.isFetchingNewAppointmentSlots = true;
      const firstFreeAppointmentSlot = this.slots[0];
      const firstFreeAppointmentSlotDate = new Date(firstFreeAppointmentSlot.startDateTime);
      const appointmentSlots = await this.fetchScheduleFreeAppointmentSlots({
        schedule: firstFreeAppointmentSlot.schedule,
        motive: firstFreeAppointmentSlot.motive,
        startDate: NovaTools.dates.format(firstFreeAppointmentSlotDate, 'yyyy-MM-dd'),
      });
      this.isFetchingNewAppointmentSlots = false;
      this.slots = appointmentSlots;
      this.currentStartDate = firstFreeAppointmentSlotDate;
    },
    async goToPageStartDateAppointmentSlots (firstDate) {
      this.isFetchingNewAppointmentSlots = true;
      const firstFreeAppointmentSlot = this.slots[0];
      const appointmentSlots = await this.fetchScheduleFreeAppointmentSlots({
        schedule: firstFreeAppointmentSlot.schedule,
        motive: firstFreeAppointmentSlot.motive,
        startDate: NovaTools.dates.format(firstDate, 'yyyy-MM-dd'),
      });
      this.isFetchingNewAppointmentSlots = false;
      if (appointmentSlots.length > 0) {
        this.slots = appointmentSlots;
        this.noFuturSlot = false;
      } else {
        this.noFuturSlot = true;
      }
    },
    setSelectedDate (selectedDate) {
      this.currentStartDate = selectedDate;
    },
    showSlots (slots) {
      if (slots.length > 0) {
        this.slots = slots;
        this.firstFreeSlotDate = slots[0].startDateTime;
        this.currentStepIndex += 1;

        const tmpDate = this.currentStartDate;
        this.currentStartDate = null;
        this.$nextTick(() => {
          this.currentStartDate = tmpDate;
        });
      } else {
        NovaTools.notify.info('Aucun créneau disponible pour ce motif et cette date');
      }
    },
    bookAppointment ({ startDateTime, endDateTime, schedule, motive }) {
      this.newAppointment = new Appointment({
        startDateTime,
        endDateTime,
        schedule,
        motive,
        patient: this.patient,
      });
      this.currentStepIndex += 1;
    },
    appointmentBooked () {
      this.resetDrawer();
      this.$emit('appointment-booked');
    },
    bookDoctolibAppointment () {
      window.zipper('book', {
        pms_id: this.patient.getUuid() + DOCTOLIB.PATIENT_ID_SUFFIX,
        first_name: this.patient.firstName,
        last_name: this.patient.birthName,
        birthdate: this.patient.dob,
        gender: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .free-appointment-slot-drawer {
    &__carrousel {
      overflow: visible;
    }
  }
  .doctolib-choice {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>