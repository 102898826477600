/**
 * Types officiels Synapse
 */
const SYNAPSE_TYPES = {
  BRANDED_DRUG: 'branded-drug',
  CLINICAL_DRUG: 'clinical-drug', // Non documenté chez eux, mais bien un type Synapse
  PHARMACEUTICAL_INGREDIENT: 'pharmaceutical-ingredient', // Non documenté chez eux, mais bien un type Synapse
};

/**
 * Types personnalisés pour usage interne - basé sur la sémantique de Synapse
 */
const INTERNAL_TYPES = { CUSTOM_DRUG: 'custom-drug' };

export const HEALTH_PRODUCT_TYPES = Object.freeze({
  ...SYNAPSE_TYPES,
  ...INTERNAL_TYPES,
});