<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      v-test="'step'"
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Nature de l'arrêt de travail"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <n-radio-button-group
        v-test="'nature-radio-button-group'"
        :value="localValue"
        :items="MEDICAL_LEAVE_NATURE_DATA"
        required
        rules="required"
        direction="row"
        class="ml-2"
        @input="$emit('update:value', $event)"
      />
    </aati-form-step>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { MEDICAL_LEAVE_NATURE_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveNatureData';

import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue';

export default {
  name: 'MedicalLeaveNatureFields',
  components: {
    AatiFormStep,
    ValidationObserver,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 2,
      MEDICAL_LEAVE_NATURE_DATA,
      localValue: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (! this.value) {
          this.$emit('update:value', MEDICAL_LEAVE_NATURE_DATA[0].value);
        }
        this.localValue = this.value;
      },
    },
  },
};
</script>