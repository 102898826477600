<template>
  <v-list
    class="app-list"
    :class="{ 'app-list--rounded': rounded }"
    :rounded="rounded"
  >
    <slot />
  </v-list>
</template>

<script>
/**
 * Le composant permettant de lister des éléments dans l'application (liste d'items, menu, etc...)
 * Wrapper du composant vuetify @see https://vuetifyjs.com/en/components/lists/
 */
export default {
  name: 'AppList',
  props: {
    /**
     * Applique un style arrondi à la liste
     */
    rounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-list {
  &--rounded {
    padding: 0;
  }
}
</style>