<template>
  <app-button
    v-test="'consultation-payment-submit'"
    icon="add"
    x-small
    :disabled="disabled"
    :tooltip="tooltipMessage"
    @click="openDialogAddPayment()"
  />
</template>

<script>
import Payment from '@/modules/accounting/models/Payment';
import Consultation from '@/modules/patient/models/Consultation';
import NovaTools from '@/nova-tools/NovaTools';

import PaymentForm from '@/modules/patient/components/consultation/accounting/PaymentForm.vue';

export default {
  name: 'PaymentAddButton',
  props: {
    /**
     * Consultation à laquelle les réglements sont rattachés
     */
    consultation: {
      type: Consultation,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    tooltipMessage () {
      return this.disabled ? this.disabledMessage : 'Ajouter un règlement';
    },
  },
  methods: {
    openDialogAddPayment () {
      this.editingPayment = new Payment({
        amount: this.remainToPay,
        consultation: this.consultation.getIri(),
      });
      NovaTools.modal.appendModalForm({
        value: new Payment({
          amount: 0,
          consultation: this.consultation.getIri(),
        }),
        title: 'Ajouter un règlement',
        form: PaymentForm,
        isOpened: true,
        scrollable: false,
        width: '600px',
      }, (payment) => {
        this.$emit('updated', payment);
      });
    },
  },
};
</script>