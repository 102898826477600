/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientOtherRiskFactorEntity = new DocumentEntity(
  'PatientOtherRiskFactor',
  'patientOtherRiskFactors.xml',
  'Contient les facteurs de risque (autres) des patients',
);

patientOtherRiskFactorEntity.addField(new DocumentEntityField('content', 'Contenu libre'));

export default patientOtherRiskFactorEntity;