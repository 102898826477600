import { BASE_ROUTE_NAMES } from '@/constants/baseRouteNames';
import NovaTools from '@/nova-tools/NovaTools';
import router from '@/router/instance';
import ButtonAction from '@novalys/src/models/ButtonAction';


const GCU_PARTNER_PAYLOAD_LS = 'gcuPartnerPayload';

export default {
  handleGcuValidation (gcu, logoutRefusalFn, routeTo) {
    if (! gcu.accepted || gcu.accepted === 'new') { // bool or string ... bad design
      localStorage.setItem('gcuValidationNeeded', true);

      NovaTools.dialog.confirm(
        'Conditions générales d\'utilisation',
        gcu?.accepted === 'new'
          ? 'Une nouvelle version des conditions générales d\'utilisation est disponible'
          : 'Une validation des conditions générales d\'utilisation est nécessaire pour pouvoir accéder à easy-care.',
        new ButtonAction('Consulter les CGU', () => router.push({ name: BASE_ROUTE_NAMES.TERMS_OF_USE }), 'primary'),
        new ButtonAction('Refuser', logoutRefusalFn),
      );
      this.setGcuPartnerPayload(routeTo);

      return true;
    }

    return false;
  },
  isRoute (routeTo) {
    return (typeof routeTo === 'object' && routeTo !== null && 'path' in routeTo && 'query' in routeTo);
  },
  setGcuPartnerPayload (routeTo) {
    if (! this.isRoute(routeTo)) {
      return;
    }
    const { path, query } = routeTo;
    localStorage.setItem(GCU_PARTNER_PAYLOAD_LS, JSON.stringify({
      path,
      query,
    }));
  },
  getGcuPartnerPayload () {
    return JSON.parse(localStorage.getItem(GCU_PARTNER_PAYLOAD_LS)) ?? {
      path: '/',
      query: '',
    };
  },
  removeGcuPartnerPayload () {
    localStorage.removeItem(GCU_PARTNER_PAYLOAD_LS);
  },
};