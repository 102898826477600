<template>
  <app-form-wrapper
    ref="formWrapper"
    v-bind="{...$props, ...$attrs}"
    class="app-simple-form"
    :form="form"
  >
    <template #default="{ localValue, formValidation, validationObserver, isSubmitting, formId }">
      <component
        :is="form"
        :id="formId"
        ref="form"
        :value="localValue"
        :validation-observer="validationObserver"
        v-bind="$attrs"
        @submitForm="(submitMethod) => submitForm($refs.formWrapper, submitMethod)"
        v-on="{...$listeners}"
      />
      <n-divider
        v-if="showDivider"
        :class="`mx-n${dividerSpacing}`"
        :spacing="dividerSpacing"
      />
      <div
        class="d-flex"
        :class="{ [`mt-${dividerSpacing}`]: ! showDivider }"
      >
        <v-spacer v-if="actionsToRight" />
        <slot
          name="actions"
          :is-valid="formValidation.valid"
          :is-submitting="isSubmitting"
          :form-id="formId"
        >
          <app-button
            :loading="isSubmitting"
            color="primary"
            type="submit"
            :form="formId"
            data-test="submit"
            :disabled="!formValidation.valid"
          >
            {{ submitText }}
          </app-button>
        </slot>
      </div>
    </template>
  </app-form-wrapper>
</template>

<script>
import AppFormWrapper from './AppFormWrapper.vue';
import FormMixin from './mixins/FormMixin';

export default {
  name: 'AppSimpleForm',
  components: { AppFormWrapper },
  mixins: [FormMixin],
  inheritAttrs: false,
  props: {
    /**
     * Constructeur du composant (fichier .vue, ou créé programatiquement)
     */
    form: {
      type: [String, Object],
      required: true,
    },
    /**
     * Modèle utilisé pour remplir le formulaire
     */
    value: {
      type: Object,
      default: null,
    },
    /**
     * Label du bouton de soumission
     */
    submitText: {
      type: String,
      default: 'Enregistrer',
    },
    /**
     * Affiche ou non un divider entre le formulaire et ses actions
     */
    showDivider: {
      type: Boolean,
      default: true,
    },
    /**
     * Permet de spécifier un espacement pour le divider du formulaire
     */
    dividerSpacing: {
      type: Number,
      default: 5,
    },
    /**
     * Positionne les actions sur la droite ou la gauche du formulaire
     */
    actionsToRight: {
      type: Boolean,
      default: true,
    },
  },
  async mounted () {
    if (this.autoFocus) {
      await this.$nextTick();
      this.formMixin_focusForm();
    }
  },
};
</script>