<template>
  <n-expandable title="Résumé administratif">
    <div class="flex-list-1 flex-column">
      <app-dataset-item
        v-test="'birth-name'"
        :value="patient.birthName"
        data-sentry-mask
        label="Nom de naissance"
      />
      <app-dataset-item
        v-test="'used-birth-name'"
        :value="patient.familyName"
        data-sentry-mask
        label="Nom utilisé"
      />
      <app-dataset-item
        v-test="'first-name'"
        :value="patient.firstName"
        data-sentry-mask
        label="1er prénom"
      />
      <app-dataset-item
        v-test="'first-names'"
        :value="patient.firstNames"
        data-sentry-mask
        label="Prénom(s)"
      />
      <app-dataset-item
        v-test="'used-first-name'"
        :value="patient.usedFirstName"
        data-sentry-mask
        label="Prénom utilisé"
      />
      <app-dataset-item
        v-test="'gender'"
        :value="patient.getGenderLabel()"
        label="Sexe"
      />
      <app-dataset-item
        v-test="'birth-place'"
        :value="patientBirthPlaceLabel"
        label="Lieu de naissance (code)"
      />
      <app-dataset-item
        v-test="'nir'"
        :value="patient.nir"
        data-sentry-mask
        label="N° de sécurité sociale"
      >
        <slot name="displayedValue">
          {{ patient.nir }}
          <app-icon
            v-if="patient.nirAllowUnchecked"
            v-test="'nir-allow-unchecked-icon'"
            icon="error"
            color="warning"
            tooltip="Le numéro de sécurité sociale semble incorrect"
          />
        </slot>
      </app-dataset-item>
      <app-dataset-item
        v-test="'ins'"
        :value="patientIns"
        data-sentry-mask
        label="INS"
      />
      <n-expandable :content-spacing-left="0">
        <template #header="{ toggle, isExpanded }">
          <app-link @click="toggle">
            {{ `Afficher ${isExpanded ? 'moins' : 'plus'}` }}
          </app-link>
        </template>
        <div class="flex-list-2 flex-column">
          <section>
            <h3 class="administrative-listing__title">
              Informations complémentaires
            </h3>
            <div class="flex-list-1 flex-column">
              <app-dataset-item
                v-test="'family-situation'"
                :value="patientFamilySituationLabel"
                label="Situation familiale"
              />
              <app-dataset-item
                v-test="'profession'"
                :value="patient.profession"
                label="Profession"
              />
              <app-dataset-item
                v-test="'note'"
                :value="patient.note"
                label="Notes"
              />
            </div>
          </section>
          <section>
            <h3 class="administrative-listing__title">
              Organismes de rattachement
            </h3>
            <div class="flex-list-1 flex-column">
              <app-dataset-item
                v-test="'birth-rank'"
                label="Rang de naissance"
                :value="patientBillDataset.birthRank"
              />
              <app-dataset-item
                v-test="'grand-regime'"
                label="Grand régime"
                :value="patientGrandRegimeLabel"
              />
              <app-dataset-item
                v-test="'quality'"
                label="Qualité du bénéficiaire"
                :value="qualityLabel"
              />
              <app-dataset-item
                v-test="'fund'"
                label="Caisse"
                :value="patientBillDataset.fund"
              />
              <app-dataset-item
                v-test="'center'"
                label="Centre"
                :value="patientBillDataset.center"
              />
            </div>
          </section>
          <section>
            <h3 class="administrative-listing__title">
              Autre
            </h3>
            <div class="flex-list-1 flex-column">
              <app-dataset-item
                v-test="'public-id'"
                :value="patient.publicId"
                label="Identifiant anonymisé"
              />
            </div>
          </section>
        </div>
      </n-expandable>
    </div>
  </n-expandable>
</template>

<script>

import { FAMILY_SITUATIONS } from '@/constants';
import { BENEFICIARIES_QUALITY_CODES } from '@/modules/patient/constants';
import PatientBillDataset from '@/modules/patient/models/datasets/PatientBillDataset';
import Patient from '@/modules/patient/models/Patient';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';

export default {
  name: 'AdministrativeListing',
  components: { AppDatasetItem },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: {
    patientIns () {
      return this.patient.insIdentity?.ins ? `${this.patient.insIdentity.ins} (NIR)` : null;
    },
    patientBirthPlaceLabel () {
      return this.patient.getBirthPlaceLabel();
    },
    patientFamilySituationLabel () {
      return FAMILY_SITUATIONS[this.patient.familySituation?.toUpperCase()]?.label;
    },
    patientBillDataset () {
      return this.patient.billDataset || new PatientBillDataset();
    },
    patientGrandRegimeLabel () {
      return this.patientBillDataset.grandRegime?.libelle;
    },
    qualityLabel () {
      if (this.patientBillDataset.quality) {
        return Object.values(BENEFICIARIES_QUALITY_CODES).find(quality => quality.value === this.patientBillDataset.quality)?.label;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.administrative-listing {
  &__title {
    font-size: 13px;
    font-weight: 400;
    color: var(--v-secondary-lighten4);
    margin-bottom: map-get($spacers, 1);
  }
}
</style>