<template>
  <v-hover v-slot="{ hover }">
    <!--
      @slot Le slot avec le flag de survol
      @binding { Boolean } hover Indique si l'élément est survolé ou non
    -->
    <slot v-bind="{ hover }" />
  </v-hover>
</template>

<script>
/**
 * Wrapper pour un élément qui lui indique s'il est survolé ou non
 */
export default { name: 'NHover' };
</script>