<template>
  <line-chart
    :chart-data="localChartData"
    :chart-options="options"
  />
</template>

<script>
import { Chart as ChartJS, Tooltip, CategoryScale, LinearScale, PointElement, LineElement, Filler, Title } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { cloneDeep } from 'lodash';
import { Line as LineChart } from 'vue-chartjs/legacy';

import { COLORS } from '@/constants';

ChartJS.register(Tooltip, CategoryScale, LinearScale, PointElement, LineElement, Filler, annotationPlugin, Title);

const BASE_FONT_SIZE = 13;
const BASE_PADDING = 4;

const FONT_COLOR = COLORS.content.base;
const FONT_FAMILY = 'Poppins';

const POINT_RADIUS = 5;
const POINT_BORDER_COLOR = '#FFFFFF';

const BACKGROUND_COLOR = COLORS.primary.base;

const BORDER_WIDTH = 2;
const BORDER_COLOR = COLORS.primary.lighten4;
const ZERO_BORDER_COLOR = COLORS.secondary.lighten4;

const TOOLTIP_PADDING = BASE_PADDING * 3;

const TICK_ANGLE = 0;

export default {
  /**
   * https://vue-chartjs.org/guide/
   */
  name: 'AppLineChart',
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      default: () => ({ datasets: [] }),
    },
    xLabel: {
      type: String,
      default: null,
    },
    xUnit: {
      type: String,
      default: null,
    },
    yUnit: {
      type: String,
      default: null,
    },
    yLabel: {
      type: String,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    maintainAspectRatio: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { options: this.getOptions() };
  },
  computed: {
    /**
     * Injecte les données
     */
    localChartData () {
      return {
        ...this.chartData,
        datasets: [
          ...this.chartData.datasets.map(dataset => ({
            backgroundColor: BACKGROUND_COLOR,
            borderColor: BORDER_COLOR,
            borderWidth: BORDER_WIDTH,
            pointRadius: POINT_RADIUS,
            pointHoverRadius: POINT_RADIUS + 1,
            pointBorderColor: POINT_BORDER_COLOR,
            fill: false,
            ...dataset,
          })),
        ],
      };
    },
  },
  watch: {
    chartOptions: {
      immediate: true,
      handler () {
        this.options = this.getOptions();
      },
    },
  },
  methods: {
    getOptions () {
      const sharedTickOptions = {
        fontColor: FONT_COLOR,
        fontSize: BASE_FONT_SIZE - 2,
        fontFamily: FONT_FAMILY,
        minRotation: TICK_ANGLE,
        maxRotation: TICK_ANGLE,
        padding: BASE_PADDING * 2,
        maxTicksLimit: 5,
      };

      const sharedGridLinesOptions = {
        color: BORDER_COLOR,
        borderDash: [BORDER_WIDTH],
        borderDashOffset: BORDER_WIDTH,
        zeroLineColor: ZERO_BORDER_COLOR,
        zeroLineWidth: BORDER_WIDTH,
        drawBorder: false,
      };

      const chartOptions = cloneDeep(this.chartOptions || {});
      const chartOptionsPlugins = cloneDeep(this.chartOptions.plugins || {});
      const chartOptionsPluginsTooltip = cloneDeep(chartOptionsPlugins?.tooltip || {});

      delete chartOptions.plugins;
      delete chartOptionsPlugins.tooltip;

      return {
        maintainAspectRatio: this.maintainAspectRatio,
        plugins: {
          /**
           * Afin d'éviter une erreur du type "Error in mounted hook: "RangeError: Maximum call stack size exceeded"
           * au montage d'un graphique sans annotations, il est nécessaire de lui en fournir par défaut.
           * @see https://github.com/apertureless/vue-chartjs/issues/846#issuecomment-1149575966
           */
          annotation: chartOptionsPlugins.annotation || {},
          tooltip: {
            displayColors: false,
            backgroundColor: COLORS.content.darken4,
            titleFont: {
              family: FONT_FAMILY,
              size: BASE_FONT_SIZE,
            },
            bodyFont: {
              family: FONT_FAMILY,
              size: BASE_FONT_SIZE,
            },
            padding: TOOLTIP_PADDING,
            callbacks: {
              title: chartOptionsPluginsTooltip.callbacks?.title ? chartOptionsPluginsTooltip.callbacks.title : this.getTooltipTitle,
              label: this.getTooltipLabel,
            },
          },
          ...chartOptionsPlugins,
        },
        scales: {
          yAxes: {
            ticks: {
              callback: this.getYTickLabel,
              ...sharedTickOptions,
            },
            grid: { ...sharedGridLinesOptions },
            title: {
              text: this.yLabel,
              color: FONT_COLOR,
              font: FONT_FAMILY,
            },
          },
          xAxes: {
            ticks: { ...sharedTickOptions },
            grid: { ...sharedGridLinesOptions },
            title: {
              text: this.xLabel,
              color: FONT_COLOR,
              font: FONT_FAMILY,
            },
          },
        },
        animation: false,
        ...chartOptions,
      };
    },
    getTooltipText (value, options = {}) {
      return (options.label ? `${options.label}: ` : '') + value + (options.unit ? ` ${options.unit}` : '');
    },
    getTooltipTitle ([item]) {
      return this.getTooltipText(item.label, {
        label: this.xLabel,
        unit: this.xUnit,
      });
    },
    getTooltipLabel (item) {
      return `• ${this.getTooltipText(item.formattedValue, {
        label: this.yLabel,
        unit: this.yUnit,
      })}`;
    },
    getYTickLabel (value) {
      return this.getTooltipText(value, { unit: this.yUnit });
    },
  },
};
</script>