import ApiEntity from '@/models/ApiEntity';
import { getFromAPI } from '@/services/api';
import { getIsoDate } from '@/utils/functions/dates';

export default class ConsultationVaccine extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.name = options.name || null;
    this.description = options.description || null;
    this.boosterDate = options.boosterDate || null;
    this.cipCode = options.cipCode || null;
    this.consultation = options.consultation || null;
    this.expiryDate = options?.expiryDate?.substring(0, 10) || null;
    this.lotNumber = options.lotNumber || null;
    this.metadata = options.metadata || {
      name: null,
      description: null,
      atcCodes: [],
      cisCodes: [],
    };
    this.vaccinationYear = options.vaccinationYear || null;
    this.vaccinationMonth = options.vaccinationMonth || null;
    this.vaccinationDay = options.vaccinationDay || null;
    this.vaccinationTime = this.getFormattedVaccinationTime(options.vaccinationTime);
  }

  static PARSING_MODE = {
    BASIC: 0,
    BASIC_WITH_FNC1: 1,
    SERIAL_FIRST: 2,
    SERIAL_LAST: 3,
  };

  getFormattedVaccinationTime (vaccinationTime) {
    if (! vaccinationTime) {
      return null;
    }

    if (/^\d{2}:\d{2}$/.test(vaccinationTime)) {
      return vaccinationTime;
    }

    const vaccinationDateObject = new Date(vaccinationTime);
    const vaccinationHours = vaccinationDateObject.getUTCHours();
    const vaccinationMinutes = vaccinationDateObject.getUTCMinutes();
    return `${String(vaccinationHours).padStart(2, '0')}:${String(vaccinationMinutes).padStart(2, '0')}`;
  }

  getVaccinationDate (withDay = false) {
    if (! this.vaccinationYear) {
      return null;
    }

    if (withDay) {
      return (this.vaccinationMonth && this.vaccinationDay)
        ? `${this.vaccinationYear}-${this.vaccinationMonth}-${this.vaccinationDay}`
        : `${this.vaccinationYear}-01-01`;
    }

    return this.vaccinationMonth
      ? `${this.vaccinationYear}-${this.vaccinationMonth}`
      : `${this.vaccinationYear}-01`;
  }

  /**
   * Permet de récupérer les métadonnées complète d'un ConsultationVaccine depuis le référentiel Vaccine
   * à l'aide de son code CIP
   * @returns {ConsultationVaccine|null}
   */
  async fetchMetadata () {
    if (this.cipCode === null) {
      return null;
    }
    const { data } = await getFromAPI('/api/vaccines', {
      'vaccineCodes.nomenclature': 'CIP',
      'vaccineCodes.value': this.cipCode,
    });
    const nbVaccine = data['hydra:totalItems'];
    if (nbVaccine === 1) {
      const vaccine = data['hydra:member'][0];
      const {vaccineCodes} = vaccine;
      this.metadata = {
        name: vaccine.name,
        description: vaccine.description,
        atcCodes: vaccineCodes.filter(code => code.nomenclature === 'ATC').map(code => code.value),
        cisCodes: vaccineCodes.filter(code => code.nomenclature === 'CIS').map(code => code.value),
      };
      return this;
    }
    if (nbVaccine > 1) {
      throw Error('Erreur, plusieurs vaccins semblent correspondre à ce code cip');
    }
    return null;
  }

  /**
   * Permet de récupérer les métadonnées complète d'un ConsultationVaccine
   * @param {String} datamatrix
   * @param {Boolean} fetchMetadata
   * @returns {ConsultationVaccine|null}
   */
  static async getConsultationVaccineFromDatamatrix (datamatrix, fetchMetadata = false) {
    try {
      let cipCode;
      let expiryDate;
      let lotNumber;
      // resultText = '01034009268630361725123110ABXC83AC2156E4EK67PK'
      // On filtre le retour du datamatrix pour ne garder que ce pattern de données :
      // [010][Code CIP de 13 chiffres][17][Date d'expiration][10][Numéro de lot]
      // https://www.cipmedicament.org/wp-content/uploads/2017/10/CIP-ACL-Les-Cahiers-08-MEO-Tra%C3%A7abilit%C3%A9-QR2-201010-1.pdf (Page 3)
      const splitted = datamatrix.split(/[^a-z0-9 ,.?!]/ig); // Split sur les FNC1/GS = balise d'un char ASCII non "classique" ?

      // On extrait les informations du datamatrix
      // La doc ci-dessus est vieille et un nouveau format existe en 3 parties et pas juste 1 ou 2 (charactère ascii non std FNC1)
      if (splitted.length === 3) {
        // Certains mettent CIP+S/N suivi de Expiration+Lot#
        if (splitted[2].substring(0, 2) === '17') {
          // [010][Code CIP de 13 chiffres][21][serial number]
          [cipCode] = splitted[1].match(/^010([0-9]{13})21[0-9]+/)
            .filter((result, i) => i > 0);
          // [17][Date d'expiration][10][Numéro de lot]
          [expiryDate, lotNumber] = splitted[2].match(/^17([0-9]{6})10([A-Za-z0-9]+)$/)
            .filter((result, i) => i > 0);
        // D'autres CIP+Expiration+Lot# suivi de S/N
        } else if (splitted[2].substring(0, 2) === '21') {
          // [010][Code CIP de 13 chiffres][17][Date d'expiration][10][Numéro de lot]
          [cipCode, expiryDate, lotNumber] = splitted[1].match(/^010([0-9]{13})17([0-9]{6})10([A-Za-z0-9]+)$/)
            .filter((result, i) => i > 0);
          // [21][serial number] sera dans splitted[2]
        }
      } else {
        const datamatrixCode = splitted[0].length ? splitted[0] : splitted[1];
        // Format "classique" ancien correspondant à la doc
        // [010][Code CIP de 13 chiffres][17][Date d'expiration][10][Numéro de lot]
        [cipCode, expiryDate, lotNumber] = datamatrixCode
          .match(/^010([0-9]{13})17([0-9]{6})10([A-Za-z0-9]+)$/)
          .filter((result, i) => i > 0);
      }
      const [year, month, day] = expiryDate.match(/.{1,2}/g);

      const consultationVaccine = new ConsultationVaccine({
        cipCode,
        expiryDate: getIsoDate(new Date(`20${year}`, month - 1, day)),
        lotNumber,
      });
      if (fetchMetadata) {
        try {
          await consultationVaccine.fetchMetadata();
          consultationVaccine.name = consultationVaccine.metadata.name;
          consultationVaccine.description = consultationVaccine.metadata.description;
        } catch {
          // Si on ne parvient pas à récupérer les metadata on ne génère pas une erreur, on a bien lu le datamatrix
          // on a juste pas trouvé ou plusiers correspondances (indétermination) pour le CIP du ConsultationVaccine
        }
      }
      return consultationVaccine;
    } catch {
      throw new Error('DataMatrix illisible ou non conforme');
    }
  }
}