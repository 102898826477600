<template>
  <n-list
    :items="sectionNames"
    :spacing="4"
  >
    <template #item="sectionProps">
      <section class="bio-result-list__section">
        <h2 class="subtitle-3 bio-result-list__section__title">
          {{ sectionProps.item }}
        </h2>
        <app-data-table
          :headers="tableHeaders"
          :items="getTableFormattedSectionItems(sectionProps.item)"
          :group-by="getTableGroupKey(getTableFormattedSectionItems(sectionProps.item))"
        >
          <template #item="{ item, index }">
            <template v-if="item.previousResults.length">
              <tr>
                <td colspan="2">
                  <div class="bio-result-list__table__sub-group-title">
                    <p>{{ item.name }}</p>
                  </div>
                </td>
                <td>
                  <bio-result-list-unit-selector
                    :bio-result="item"
                    :selected-unit-index.sync="item.selectedUnitIndex"
                  />
                </td>
                <td>
                  {{ item.getDisplayableTableItemValueRange() }}
                </td>
              </tr>
              <bio-result-list-sub-group-row
                v-for="(result, resultIndex) in [item, ...item.previousResults]"
                :key="`${resultIndex}-${index}`"
                :bio-result="result"
                :selected-unit-index="item.selectedUnitIndex"
              />
            </template>
            <bio-result-list-table-row
              v-else
              :bio-result="item"
              :selected-unit-index.sync="item.selectedUnitIndex"
            />
          </template>
        </app-data-table>
      </section>
    </template>
  </n-list>
</template>

<script>
import BioResultTableItem from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/classes/BioResultTableItem';

import AppDataTable from '@/components/ui/tables/AppDataTable.vue';
import BioResultListSubGroupRow from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListSubGroupRow.vue';
import BioResultListTableRow from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListTableRow.vue';
import BioResultListUnitSelector from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListUnitSelector.vue';


export default {
  name: 'BioResultList',
  components: {
    AppDataTable,
    BioResultListTableRow,
    BioResultListSubGroupRow,
    BioResultListUnitSelector,
  },
  props: {
    bioResults: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'Nom',
          value: 'name',
          width: '40%',
        },
        {
          text: 'Valeur',
          value: 'value',
          sortable: false,
        },
        {
          text: 'Unité',
          value: 'unit',
          sortable: false,
        },
        {
          text: 'Référence',
          value: 'range',
          sortable: false,
          width: '75',
        },
      ],
    };
  },
  computed: {
    sectionNames () {
      return this.bioResults.map(result => result.displayName);
    },
  },
  methods: {
    getTableFormattedSectionItems (sectionName) {
      const bioResultSections = this.bioResults.find(structuredBody => structuredBody.displayName === sectionName).sections;
      if (bioResultSections.every(section => section.sections)) {
        const tableItems = [];
        bioResultSections.forEach(section => {
          tableItems.push(...section.sections
            .map(bioResult => new BioResultTableItem(bioResult, { category: section.displayName }))
            .filter(bioResult => this.isBioResultHasValues(bioResult)));
        });
        return tableItems;
      }
      return bioResultSections
        .map(bioResult => new BioResultTableItem(bioResult))
        .filter(bioResult => this.isBioResultHasValues(bioResult));
    },
    isBioResultHasValues (bioResult) {
      return bioResult.values.filter(value => value.value).length >= 1;
    },
    getTableGroupKey (tableItems) {
      return tableItems.every(item => item.category) ? 'category' : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bio-result-list {
  &__section {
    border: 1px solid var(--v-divider-base);
    border-radius: 6px;
    overflow: hidden;

    &__title {
      border-bottom: 1px solid var(--v-divider-base);
      color: var(--v-content-base);
      padding: map-get($spacers, 3);
    }
  }

  &__table {
    &__sub-group-title {
      display: flex;
      align-items: center;
      gap: map-get($spacers, 2);
      font-weight: 500;
      font-size: 13px;
    }
  }
}
</style>