import { computed, ref } from 'vue'
import { isEqual } from 'lodash'

const DEFAULT_VALUE_GETTER = item => item
/**
 * @typedef {Object} selectableOptions
 * @property {Boolean} multiple Indique si la sélection peut être multiple.
 * @property {Function} valueGetter Fonction permettant de définir la valeur à sélectionner (retourne l'élément complet par défaut).
 */

/**
 * Permet de contrôler une liste d'éléments sélectionnable
 * @param {Array} _items Les éléments de la liste
 * @param {selectableOptions} options
 * @returns {Object}
 */
export const useSelectable = (_items, options = {}) => {
  const isMultiple = options.multiple
  const valueGetter = options.valueGetter || DEFAULT_VALUE_GETTER
  const items = ref(_items)
  const selection = ref([])

  const isAllSelected = computed(() => isMultiple ? (selection.value.length === items.value?.length) : false)
  const isSelectionIndeterminate = computed(() => isMultiple ? ((selection.value.length > 0) && ! isAllSelected.value) : false)

  const getItemSelectionIndex = (item) => selection.value.findIndex(_item => isEqual(_item, valueGetter(item)))

  const isSelected = (item) => isMultiple ? getItemSelectionIndex(item) >= 0 : (selection.value === valueGetter(item))

  const toggle = (item) => {
    if (isMultiple) {
      isSelected(item) ? selection.value.splice(getItemSelectionIndex(item), 1) : selection.value.push(valueGetter(item))
      return
    }
    selection.value = isSelected(item) ? null : valueGetter(item)
  }

  const toggleAll = () => {
    if (isMultiple) {
      selection.value = isAllSelected.value ? [] : items.value.map(valueGetter)
      return
    }
    toggle(selection.value)
  }

  return {
    toggle,
    toggleAll,
    isSelected,
    isAllSelected,
    isSelectionIndeterminate,
    valueGetter,
    selection,
  }
}