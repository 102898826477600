<template>
  <div class="health-summary-reminder-panel-filters">
    <patient-keyword-selector
      v-model="selectedKeywords"
      multiple
      class="health-summary-reminder-panel-filters__keywords"
    />
    <pathology-autocomplete-wrapper 
      v-model="selectedPathologies"
      multiple
    />
  </div>
</template>

<script>
import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';
import PatientKeyword from '@/modules/patient/models/PatientKeyword';

import PathologyAutocompleteWrapper from '@/components/lap/PathologyAutocompleteWrapper.vue';
import PatientKeywordSelector from '@/modules/patient/components/keywords/components/PatientKeywordSelector.vue';

export default {
  name: 'HealthSummaryRemindersPanelFilters',
  components: {
    PatientKeywordSelector,
    PathologyAutocompleteWrapper,
  },
  props: {
    keywords: {
      type: Array,
      default: null,
      validator: keywords => keywords.every(keyword => keyword instanceof PatientKeyword),
    },
    pathologies: {
      type: Array,
      default: null,
      validator: pathologies => pathologies.every(pathology => pathology instanceof PatientPathology),
    },
  },
  computed: {
    selectedKeywords: {
      get() {
        return this.keywords;
      },
      set(selectedKeywords) {
        this.$emit('update:keywords', selectedKeywords);
      },
    },
    selectedPathologies: {
      get() {
        return this.pathologies;
      },
      set(selectedPathologies) {
        this.$emit('update:pathologies', selectedPathologies);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.health-summary-reminder-panel-filters {
  display: flex;
  gap: map-get($spacers, 8);
  align-items: flex-end;

  &__keywords {
    max-width: fit-content;
  }
}
</style>
