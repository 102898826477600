<template>
  <div class="app-sidebar-panel mx-n5">
    <div
      v-ripple
      class="app-sidebar-panel__header"
      data-test="panel-header"
      @click="toggleContentVisible"
    >
      <h2 class="app-sidebar-panel__title">
        {{ title }}
      </h2>
      <app-button
        v-if="hasAddListener"
        icon="add"
        x-small
        class="app-sidebar-panel__button-add"
        data-test="app-button-add"
        @click.native.stop="add"
      />
      <v-spacer />
      <app-icon
        icon="chevron-up"
        color="secondary lighten-4"
        :style="chevronStyle"
        class="app-sidebar-panel__chevron"
        data-test="panel-chevron"
      />
    </div>
    <v-expand-transition>
      <div
        v-show="contentVisible"
        class="app-sidebar-panel__content"
        data-test="panel-content"
      >
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'AppSidebarPanel',
  props: {
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return { contentVisible: this.isOpen };
  },
  computed: {
    chevronStyle () {
      const rotationAngle = this.contentVisible ? 0 : 180;
      return { transform: `rotate(${rotationAngle}deg)` };
    },
    hasAddListener () {
      return this.$listeners?.add;
    },
  },
  watch: {
    contentVisible (isContentVisible) {
      this.$emit('update:isOpen', isContentVisible);
    },
    isOpen (isOpen) {
      this.contentVisible = isOpen;
    },
  },
  methods: {
    toggleContentVisible () {
      this.contentVisible = ! this.contentVisible;
    },
    add () {
      this.$emit('add');
    },
  },
};
</script>

<style lang="scss" scoped>
  .app-sidebar-panel {
    &__header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: var(--v-primary-lighten5);
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
      gap: map-get($spacers, 1);
      cursor: pointer;
      user-select: none;
    }

    &__title {
      font-size: 13px;
      font-weight: 700;
    }

    &__chevron {
      transition: transform .3s ease;
    }

    &__button-add {
      margin-left: map-get($spacers, 2);

      ::v-deep {
        .app-button__button {
          background-color: var(--v-primary-lighten4) !important;
          &__icon {
            color: var(--v-content-base);
          }
        }
      }
    }
  }
</style>