<template>
  <app-form
    class="send-message-form"
    :submit-method="submit"
  >
    <section class="send-message-form__row">
      <messaging-emails-field
        v-model="recipients"
        :hint="hintText"
        label="Destinataires"
        rules="required"
        :patient="patient"
        class="send-message-form__recipients-field mt-0"
      />
      <app-link
        v-test="'toggle-copy-recipient-fields-link'"
        :tooltip="`${showCopyRecipientFields ? 'Masquer' : 'Afficher'} les CC/CCI`"
        class="send-message-form__toggle-copy-recipient-fields-link"
        @click="handleClick "
      >
        CC/CCI
      </app-link>
    </section>
    <v-expand-transition>
      <section
        v-if="showCopyRecipientFields"
        v-test="'copy-recipient-fields'"
        class="send-message-form__row"
      >
        <messaging-emails-field
          v-model="cc"
          label="CC"
          class="send-message-form__row__field"
          :patient="patient"
        />
        <messaging-emails-field
          v-model="bcc"
          label="CCI"
          class="send-message-form__row__field"
          :patient="patient"
        />
      </section>
    </v-expand-transition>
    <app-switch
      v-model="returnReceipt"
      label="Accusé de réception"
      small
      dense
    />
    <n-divider :spacing="0" />
    <app-text-field
      v-model="subject"
      label="Objet"
    />
    <app-wysiwyg-field
      v-model="body"
      :auto-focus="autoFocusBody"
      label="Message"
    />
    <app-file-input
      ref="sendMessageFormAppFileInputComponent"
      v-test="'file-input'"
      :value="sendingAttachments"
      :max-mo-size="10"
      :uploaded-files-max="uploadedFilesMax"
      :has-uploaded-files-errors="hasUploadedFilesErrors"
      :uploaded-files-count="uploadedFilesCount"
      button-label="Sélectionner des fichiers"
      button-clear-label="Retirer tous les fichiers"
      upload-icon="attach"
      hide-errors
      rows="1"
      auto-grow
      class="document-form__file-input mb-5"
      name="file"
      multiple
      @file-selected="uploadAttachments"
      @invalid-file="uploadAttachments"
      @files-cleared="clearUploadedAttachments"
    >
      <template #default>
        <n-list
          v-if="sendingAttachments.length"
          inline
          :items="sendingAttachments"
        >
          <template #item="{ item, index }">
            <article class="send-message-form__attachment">
              <div class="send-message-form__attachment__content">
                <app-file-icon :type="item.mimeType" />
                <p>{{ item.fileName }}</p>
              </div>
              <app-icon
                color="error"
                icon="delete"
                tooltip="Retirer ce fichier"
                class="ml-0"
                @click="removeUploadedAttachments(index)"
              />
              <app-icon
                v-if="item.file.shortError"
                color="error"
                icon="error"
                :tooltip="item.file.shortError"
                class="ml-0"
              />
            </article>
          </template>
        </n-list>
        <template v-for="(item, index) in sendingAttachments">
          <div
            v-if="item.file.longError"
            :key="index"
            class="error--text mt-2"
          >
            {{ item.file.longError }}
          </div>
        </template>
      </template>
    </app-file-input>
  </app-form>
</template>

<script>
import Patient from '@/modules/patient/models/Patient';
import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService';
import MssMessage from '@/modules/secureMessaging/modules/messaging/models/MssMessage';
import NovaTools from '@/nova-tools/NovaTools';
import { getFileFromAPI, postToAPI } from '@/services/api';
import { getFileContent } from '@/utils/functions/files';
import { titleCase } from '@/utils/functions/words';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppFileInput from '@/components/ui/form/AppFileInput.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppWysiwygField from '@/components/ui/form/AppWysiwygField.vue';
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue';
import AppFileIcon from '@/components/ui/icon/AppFileIcon.vue';
import MessagingEmailsField from '@/modules/secureMessaging/modules/messaging/components/MessagingEmailsField.vue';


export default {
  name: 'SendMessageForm',
  components: {
    AppForm,
    AppFileInput,
    AppTextField,
    MessagingEmailsField,
    AppSwitch,
    AppFileIcon,
    AppWysiwygField,
  },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: MssMessage,
      default: () => new MssMessage(),
    },
    sourceMessage: {
      type: MssMessage,
      default: null,
    },
    autoFocusBody: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient || null,
      default: null,
    },
  },
  data () {
    return {
      recipients: [],
      cc: [],
      bcc: [],
      subject: this.value.subject,
      body: this.value.body,
      showCopyRecipientFields: false,
      returnReceipt: false,
      sendingAttachments: [],
      uploadedFilesMax: 12,
      hasUploadedFilesErrors: false,
      uploadedFilesCount: 0,
    };
  },
  computed: {
    hintText () {
      return `Recherchez un destinataire ${this.patient ? '' : '(3 caractères min.)'} ou entrez une adresse mssante`;
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler (newMessage) {
        this.recipients = newMessage.senderEmail ? [newMessage.senderEmail] : [];
        this.cc = [];
        this.bcc = [];
        this.subject = newMessage.subject;
        this.body = this.sourceMessage ? this.getFormattedMessageThread(this.sourceMessage) : newMessage.body;
        this.returnReceipt = false;

        if (newMessage.attachments.length) {
          this.sendingAttachments = await Promise.all(newMessage.attachments.map(async attachment => {
            const { contentUrl, fileName, mimeType, documentSource } = attachment;
            const { data } = await getFileFromAPI(contentUrl);
            const fileContent = await this.getFileContent(data);
            return {
              mimeType,
              fileName,
              fileContent,
              documentSource,
              file: {
                shortError: null,
                longError: null,
              },
            };
          }));
          return;
        }
        this.sendingAttachments = [];
      },
    },
  },
  methods: {
    async getFileContent (file) {
      return getFileContent(file);
    },
    uploadAttachments (attachmentFiles) {
      const availableSlots = this.uploadedFilesMax - this.uploadedFilesCount;
      if (availableSlots <= 0) {
        return;
      }
      if (attachmentFiles.length > availableSlots) {
        attachmentFiles.splice(availableSlots);
      }
      attachmentFiles.forEach(async file => {
        const fileContent = await this.getFileContent(file);
        this.sendingAttachments.push({
          file,
          mimeType: file.type,
          fileName: file.name,
          fileContent,
        });
        this.checkUploadedFilesErrors();
      });
      this.uploadedFilesCount += attachmentFiles.length;
    },
    removeUploadedAttachments (index) {
      this.sendingAttachments.splice(index, 1);
      this.uploadedFilesCount -= 1;
      this.checkUploadedFilesErrors();
    },
    checkUploadedFilesErrors () {
      this.hasUploadedFilesErrors = !! this.sendingAttachments.filter(file => file.file.longError).length;
    },
    clearUploadedAttachments () {
      this.sendingAttachments = [];
      this.uploadedFilesErrors = 0;
      this.uploadedFilesCount = 0;
    },
    handleClick () {
      this.showCopyRecipientFields = ! this.showCopyRecipientFields;
    },
    getFormattedMessageThread (message) {
      const { senderName, senderEmail } = message;
      return '<br><div /> <hr> <br>'
        + `De: <strong>${senderName || ''} (${senderEmail})</strong>`
        + '<br>'
        + `Envoyé: <strong>${titleCase(NovaTools.dates.format(new Date(message.receptionDateTime), 'iiii M MMMM yyyy à HH:MM'))}</strong>`
        + '<br>'
        + `Objet: <strong>${message.getFormattedSubject()}</strong>`
        + `<br> <br>${message.body}`;
    },
    async submit () {
      let hasError = 0;
      let errorMessage = '';
      try {
        await postToAPI('/api/mss_messages', {
          subject: this.subject,
          body: this.body?.replace(/(\r\n|\n|\r)/gm, ''),
          transferredMessage: this.sourceMessage?.getIri(),
          recipients: this.getSerializedEmailList(this.recipients),
          returnReceipt: this.returnReceipt,
          cc: this.getSerializedEmailList(this.cc),
          bcc: this.getSerializedEmailList(this.bcc),
          attachments: this.sendingAttachments,
        }, {
          headers: { jSessionId: AuthenticationServiceInstance.authenticationPayload.jSessionId },
          params: { mssEmail: AuthenticationServiceInstance.authenticationPayload.mssEmail },
        });
      } catch (error) {
        errorMessage = 'Une erreur s\'est produite lors de l\'envoi';
        if (error && error.response && error.response.data && error.response.data['hydra:description']) {
          errorMessage = error.response.data['hydra:description'];
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        hasError = 1;
      }
      if (hasError) {
        if (errorMessage === 'Erreur technique') {
          errorMessage = `${errorMessage} : Un des fichiers attachés n'est pas accepté par la MSSanté`;
          this.$refs.sendMessageFormAppFileInputComponent.setFileInputError(errorMessage);
          this.$refs.sendMessageFormAppFileInputComponent.setShowErrors(true);
        }
        throw new Error('Arrêt intentionnel de submit, gestion des erreurs');
      }
      NovaTools.notify.success('Message envoyé');
    },
    getSerializedEmailList (emailList) {
      return emailList.map(email => ({
        name: null,
        email,
      }));
    },
  },
};

</script>

<style lang="scss" scoped>
$form-white-space: map-get($spacers, 4);

.send-message-form {
  display: flex;
  flex-direction: column;
  gap: $form-white-space;

  &__toggle-copy-recipient-fields-link {
    width: 50px;
  }

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    gap: $form-white-space;

    &__field {
      width: 100%;
    }
  }

  &__attachment {
    display: flex;
    align-items: center;
    background-color: var(--v-secondary-lighten5);
    color: var(--v-content-base);
    font-size: 13px;
    min-width: 180px;
    border-radius: 6px;
    padding: map-get($spacers, 2) map-get($spacers, 3);
    gap: map-get($spacers, 2);

    &__content {
      display: flex;
      align-items: center;
      gap: map-get($spacers, 3);
      flex-grow: 1;
    }
  }
}
</style>