import PatientCodifiedHealthEntity from '@/modules/patient/models/healthEntity/PatientCodifiedHealthEntity';

export default class PatientPathology extends PatientCodifiedHealthEntity {
  constructor (options = {}) {
    super(options);

    this.important = options.important || false;
    this.startDate = options.startDate || null;
    this.endDate = options.endDate || null;
  }
}