import { CONTACT_POINT_LABELS } from '@/constants';
import { CONTACT_CHANNELS } from '@/modules/secureMessaging/constants';
import { LOCAL_STORAGE_AUTHENTICATION_PAYLOAD_KEY } from '@/modules/secureMessaging/modules/authentication/constants';
import { postToAPI } from '@/services/api';

const REFRESH_CONNECTION_DELAY_MINUTES = 15;

/**
 * Le service d'authentification à la MSS
 */
class AuthenticationService {
  #otpValidationPayload;

  #currentUser;

  #keepAliveConnectionInterval;

  constructor () {
    this.onAuthenticationSuccess = () => {};
  }

  get authenticationPayload () {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_PAYLOAD_KEY));
  }

  get authenticationToken () {
    return this.authenticationPayload.jSessionId;
  }

  get isAuthenticated () {
    return this.authenticationPayload !== null;
  }

  sendOtp ({ user, targetChannel }) {
    return new Promise((resolve, reject) => {
      postToAPI('/api/mss/send_otp', { mssCanalType: targetChannel === CONTACT_CHANNELS.SMS.value ? 'SMS' : 'Mail' })
        .then(result => {
          this.#otpValidationPayload = result.data;
          this.#currentUser = user;
          resolve(result.data);
        }).catch(error => reject(error));
    });
  }

  validateOtp (otp) {
    return new Promise((resolve, reject) => {
      postToAPI('/api/mss/check_otp_validity', {
        otp,
        ...this.#otpValidationPayload,
      }).then(result => {
        const authenticationPayload = JSON.stringify({
          jSessionId: result.data.jSessionId,
          mssEmail: this.#currentUser.contact.getContactPointByLabel(CONTACT_POINT_LABELS.MSS.value)?.value,
        });
        localStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_PAYLOAD_KEY, authenticationPayload);
        this.onAuthenticationSuccess?.(authenticationPayload);
        resolve(result.data);
      }).catch(error => reject(error));
    });
  }

  async refreshConnection () {
    await postToAPI('/api/mss/refresh_connection', { jSessionId: this.authenticationToken });
    this.onAuthenticationSuccess?.(this.authenticationPayload);
  }

  keepAliveConnection () {
    this.#keepAliveConnectionInterval = setInterval(this.refreshConnection.bind(this), (60_000 * REFRESH_CONNECTION_DELAY_MINUTES));
  }

  killConnection () {
    clearInterval(this.#keepAliveConnectionInterval);
    localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_PAYLOAD_KEY);
  }
}

const instance = new AuthenticationService();

export default instance;