export default class DocumentVariable {
  constructor (options = {}) {
    this['@id'] = options['@id'] || null;
    this.scope = options.scope || null;
    this.name = options.name || null;
    this.description = options.description || null;
    this.formats = options.formats || [];
  }

  /**
   * Permet d'afficher le contexte sous la forme d'un titre commencant par une majuscule
   * @returns {String}
   */
  get titleFormattedScope () {
    return this.scope[0].toUpperCase() + this.scope.slice(1, this.scope.length);
  }
}