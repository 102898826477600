<template>
  <div class="consultation-payments">
    <header>
      <h2
        class="subtitle"
        data-test="payments-title"
      >
        {{ paymentTitle }}
      </h2>
      <payment-add-button
        :disabled="disabled"
        :disabled-message="disabledMessage"
        :consultation="consultation"
        @updated="refreshPayments"
      />
    </header>
    <table class="payments-table">
      <tr
        v-for="payment in orderedConsultationPayments"
        :key="payment['@id']"
        v-test="'payment-line'"
        class="payment-line mb-0 mt-0"
      >
        <td>
          <span class="payments-table__payment-amount mr-2 ">
            {{ formatAmount(payment.amount) }}
          </span>
        </td>
        <td>
          <span class="payments-table__payment-type mr-2"> Par {{ PAYMENTS_TYPES[payment.type.toUpperCase()].label.toLowerCase() }} </span>
        </td>
        <td class="payments-table__payment-date">
          <app-icon
            class="payments-table__payment-date-icon mr-2"
            icon="schedule"
            small
          />
          {{ format(payment.date,'dd/MM/yyyy') }}
        </td>
        <td>
          <app-button
            v-test="'edit-button'"
            icon="edit"
            tooltip="Modifier le réglement"
            small
            @click="openPaymentEdition(payment)"
          />
        </td>
        <td>
          <app-button
            v-test="'delete-button'"
            icon="delete"
            tooltip="Supprimer le réglement"
            small
            @click="openPaymentDeletion(payment)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { PAYMENTS_TYPES } from '@/constants';
import Consultation from '@/modules/patient/models/Consultation';
import NovaTools from '@/nova-tools/NovaTools';
import { deleteFromAPI } from '@/services/api';
import { formatAmount } from '@/utils/functions/currencies';
import { format } from '@/utils/functions/dates';
import { pluralize } from '@/utils/functions/words';
import DeleteButtonAction from '@novalys/src/models/DeleteButtonAction';

import PaymentAddButton from '@/modules/patient/components/consultation/accounting/PaymentAddButton.vue';
import PaymentForm from '@/modules/patient/components/consultation/accounting/PaymentForm.vue';

export default {
  name: 'PaymentList',
  components: { PaymentAddButton },
  props: {
    /**
     * Consultation à laquelle les réglements sont rattachés
     */
    consultation: {
      type: Consultation,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      consultationPayments: [],
      PAYMENTS_TYPES,
    };
  },
  computed: {
    orderedConsultationPayments () {
      return this.consultationPayments.slice(0)
        .sort((paymentA, paymentB) => new Date(paymentB.createdAt).getTime() - new Date(paymentA.createdAt).getTime());
    },
    paymentTitle () {
      const paymentsCount = this.consultationPayments.length;
      return `${paymentsCount} ${pluralize('règlement', paymentsCount)}`;
    },
  },
  watch: {
    'consultation.fees': {
      deep: true,
      immediate: true,
      handler () {
        this.fetchConsultationPayments();
      },
    },
  },
  methods: {
    format,
    formatAmount,
    async fetchConsultationPayments () {
      if (this.consultation.getUuid()) {
        this.consultationPayments = await Consultation.fetchPayments(this.consultation.getUuid());
        this.$emit('update:total', this.getCalculatedTotal(this.consultationPayments));
      }
    },
    openPaymentEdition (paymentToEdit) {
      NovaTools.modal.appendModalForm({
        value: paymentToEdit,
        title: 'Editer un règlement',
        form: PaymentForm,
        isInvoicePaid: (this.getCalculatedTotal(this.consultationPayments) >= (this.consultation?.fees ?? 0)),
        isOpened: true,
        scrollable: false,
        width: '600px',
      }, () => {
        this.refreshPayments();
      });
    },
    openPaymentDeletion (paymentToDelete) {
      NovaTools.dialog.confirm(
        'Supprimer le règlement',
        `Êtes-vous sûr(e) de vouloir supprimer ce règlement par ${PAYMENTS_TYPES[paymentToDelete.type
          .toUpperCase()].label.toLowerCase()} d'un montant de ${paymentToDelete.amount} € ?`,
        new DeleteButtonAction(async () => {
          await deleteFromAPI(paymentToDelete['@id']);
          await this.refreshPayments();
          NovaTools.notify.success('Le paiement a été supprimé avec succès');
        }),
      );
    },
    getCalculatedTotal (payments) {
      return payments?.reduce((acc, payment) => {
        acc += payment.amount;
        return acc;
      }, 0);
    },
    async refreshPayments () {
      await this.fetchConsultationPayments();
      this.$emit('refresh', this.consultationPayments);
    },
  },
};
</script>

<style scoped lang="scss">
  .subtitle {
    font-size: 13px;
    font-weight: 700;
    margin-right: 10px;
  }

  .consultation-payments {
    flex-grow: 1;

    header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .payments-table {
    width: 100%;
    border-collapse: collapse;

    &__payment-amount {
      font-size: 16px;
      font-weight: 600;
    }

    &__payment-type {
      color: var(--v-secondary-lighten4);
      font-size: 12px;
    }

    &__payment-date-icon {
      opacity: .5;
      line-height: 100%;
    }
  }
</style>