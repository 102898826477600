import { ROUTE_NAMES } from '@/modules/authentication/constants';

import AuthenticationModule from '@/modules/authentication/AuthenticationModule.vue';
import ForgetPasswordView from '@/modules/authentication/views/ForgetPassword.vue';
import ResetPasswordView from '@/modules/authentication/views/ResetPassword.vue';
import LoginView from '@/modules/authentication/views/UserLogin.vue';


const routeCommons = { meta: { layout: 'empty' } };

export default {
  path: '/',
  component: AuthenticationModule,
  children: [
    {
      ...routeCommons,
      path: '/',
      name: ROUTE_NAMES.CONNECTION,
      component: LoginView,
    },
    {
      ...routeCommons,
      name: ROUTE_NAMES.FORGET_PASSWORD,
      path: '/mot-de-passe-oublie',
      component: ForgetPasswordView,
    },
    {
      ...routeCommons,
      name: ROUTE_NAMES.RESET_PASSWORD,
      path: '/reinitialiser-mot-de-passe',
      component: ResetPasswordView,
    },
  ],
};