<template>
  <n-dialog
    :title="title"
    persistent
    divided
    :actions="modalActions"
    :is-open.sync="localIsOpen"
  >
    <app-replacement-content-list
      v-model="selectedFields"
      :replacements="replacements"
      @update="updateSelection"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>
    </app-replacement-content-list>
  </n-dialog>
</template>

<script>
import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';
import ButtonAction from '@novalys/src/models/ButtonAction';

import AppReplacementContentList from '@/components/ui/replacementContent/AppReplacementContentList.vue';



export default {
  name: 'AppReplacementContentListModal',
  components: { AppReplacementContentList },
  mixins: [
    localCopyMixin({
      propertyName: 'isOpen',
      copyPropertyName: 'localIsOpen',
    }),
  ],
  props: {
    title: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      default: 'Remplacer la sélection',
    },
    replacements: {
      type: Array,
      required: true,
      validator: replacements => replacements.every(replacement => replacement instanceof ReplacementItem),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedFields: [],
      modalActions: [
        new ButtonAction('Annuler', this.closeModal),
        new ButtonAction(this.confirmText, this.onSubmit, 'primary'),
      ],
    };
  },
  methods: {
    closeModal () {
      this.localIsOpen = false;
      this.$emit('close');
    },
    onSubmit () {
      this.$emit('confirm', this.selectedFields);
      this.closeModal();
    },
    updateSelection (selection) {
      this.selectedFields = selection;
    },
  },
};
</script>

<style lang="scss" scoped>
.vital-card-insured-merge-selector-modal {
  &__item {
    padding: map-get($spacers, 3);
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px var(--v-divider-base);
    transition: box-shadow .2s ease;

    &--selected {
      box-shadow: inset 0 0 0 1.5px var(--v-primary-base);
    }
  }
}
</style>