/**
 * Plugin permettant de lier les abonnements mercure au store
 * @param {Store} store
 */

import Mercure from '@/utils/classes/Mercure';

const mercurePlugin = async store => {
  Mercure.setUserData({ store });
};

export default mercurePlugin;