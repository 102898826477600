export const ANTECEDENT_TYPES = Object.freeze({
  MEDICAL: {
    value: 'medical',
    text: 'Médical',
  },
  SURGICAL: {
    value: 'surgical',
    text: 'Chirurgical',
  },
  FAMILY: {
    value: 'family',
    text: 'Familial',
  },
  GYNECOLOGICAL: {
    value: 'gynecological',
    text: 'Gynécologique',
  },
  OBSTETRICAL: {
    value: 'obstetrical',
    text: 'Obstétrical',
  },
  PSYCHIATRIC: {
    value: 'psychiatric',
    text: 'Psychiatrique',
  },
  EMPTY: {
    value: null,
    text: 'Non renseigné',
  },
  NOT_CODIFIED: {
    value: 'not_codified',
    text: 'Non codifié CIM10',
  },
});