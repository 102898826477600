<template>
  <app-panel
    title="Personnes de l'entourage"
    header-divided
    class="patient-relatives-panel mb-last-0"
    :quantity="patientRelatives.length"
    :actions="actions"
  >
    <n-list
      :items="patientRelatives"
      :loading="loading"
      :skeleton-count="DEFAULT_SKELETONS_AMOUNT"
      empty-text="Aucune personne de l'entourage"
      hoverable
      :inset-x="4"
    >
      <template #item="{item}">
        <patient-relatives-panel-item
          v-test="'patient-relatives-panel-item'"
          :item="item"
          @update-patient-relative="item => openPatientRelativeUpdateForm(item)"
          @delete-patient-relative="item => deletePatientRelative(item)"
        />
      </template>
      <template #skeleton>
        <div v-test="'patient-relatives-skeleton'">
          <app-skeleton-loader
            type="text"
            width="40%"
          />
          <app-skeleton-loader
            type="text"
            width="30%"
          />
        </div>
      </template>
    </n-list>
    <drawer-modal-form
      :title="patientRelativesFormTitle"
      :form="PatientRelativesForm"
      :value="editingPatientRelative"
      :is-editing="isEditing"
      submit-text="Enregistrer"
      :patient-iri="patient.getIri()"
      @cancel="editingPatientRelative = null"
      @submitSuccess="submitSuccess"
    />
  </app-panel>
</template>

<script>
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import { PATIENT_RELATIONS } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import PatientRelative from '@/modules/patient/models/PatientRelative';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, deleteFromAPI } from '@/services/api';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import DrawerModalForm from '@/components/ui/modal/DrawerModalForm.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import PatientRelativesForm from '@/modules/patient/modules/patientFile/modules/correspondents/components/relativesPanel/forms/PatientRelativesForm.vue';
import PatientRelativesPanelItem from '@/modules/patient/modules/patientFile/modules/correspondents/components/relativesPanel/PatientRelativesPanelItem.vue';



const DEFAULT_SKELETONS_AMOUNT = 4;
const DEFAULT_DISPLAY_LIMITATION = 3;

export default {
  name: 'PatientRelativesPanel',
  components: {
    DrawerModalForm,
    PatientRelativesPanelItem,
    AppPanel,
    AppSkeletonLoader,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      DEFAULT_SKELETONS_AMOUNT,
      DEFAULT_DISPLAY_LIMITATION,
      patientRelatives: [],
      loading: false,
      isEditing: false,
      editingPatientRelative: null,
      actions: { add: new ActionMenuItem('add', 'Ajouter une personne de l\'entourage', this.openPatientRelativeAdditionForm) },
      PatientRelativesForm,
      isAllDisplayed: false,
    };
  },
  computed: {
    patientRelativesDisplayed () {
      return this.isAllDisplayed ? this.patientRelatives : this.patientRelatives.slice(0, DEFAULT_DISPLAY_LIMITATION);
    },
    patientRelativesFormTitle () {
      if (this.isEditing) {
        return 'Modifier une personne';
      }
      return 'Ajouter une personne';
    },
    patientUuid () {
      return this.patient.getUuid();
    },
  },
  async created () {
    await this.fetchPatientRelatives();
  },
  methods: {
    async fetchPatientRelatives () {
      this.loading = true;
      try {
        const { data } = await getFromAPI(`/api/patients/${this.patientUuid}/patient_relatives`);
        this.patientRelatives = data['hydra:member'].map(item => new PatientRelative(item));

        // On les présente dans le même ordre que celui du listing des relations
        const orderedRelations = Object.keys(PATIENT_RELATIONS).map(relationName => relationName.toLowerCase());
        this.patientRelatives.sort((relativeA, relativeB) => {
          const relativeAPos = orderedRelations.indexOf(relativeA.link);
          const relativeBPos = orderedRelations.indexOf(relativeB.link);
          return relativeBPos > relativeAPos ? - 1 : 0;
        });
      } finally {
        this.loading = false;
      }
    },
    openPatientRelativeAdditionForm () {
      this.isEditing = false;
      this.editingPatientRelative = new PatientRelative();
    },
    openPatientRelativeUpdateForm (patientRelative) {
      this.isEditing = true;
      this.editingPatientRelative = patientRelative;
    },
    submitSuccess () {
      this.isEditing = false;
      this.editingPatientRelative = null;
      this.fetchPatientRelatives();
    },
    deletePatientRelative (patientRelative) {
      NovaTools.dialog.confirm(
        'Supprimer une personne de l\'entourage',
        `Êtes-vous sûr(e) de vouloir supprimer ${patientRelative.getCivilState()} des personnes de l'entourage ?`,
        () => {
          this.confirmDeletePatientRelative(patientRelative);
        },
      );
    },
    async confirmDeletePatientRelative (patientRelative) {
      const currentPatientRelativeIndex = this.patientRelatives.indexOf(patientRelative);
      await deleteFromAPI(patientRelative['@id']);
      this.patientRelatives.splice(currentPatientRelativeIndex, 1);
      NovaTools.notify.success('La personne de l\'entourage a été supprimée avec succès');
    },
  },
};
</script>