<template>
  <v-dialog
    v-model="localShowModal"
    fullscreen
    persistent
  >
    <fullscreen-layout
      title="Revenir sur easy-care"
      @goBack="closeModal"
    >
      <iframe
        v-if="localShowModal"
        id="modal-efficience-manual"
        class="iframe-style"
        width="100%"
        loading="lazy"
        :src="url"
      />
    </fullscreen-layout>
  </v-dialog>
</template>

<script>
import NovaTools from '@/nova-tools/NovaTools';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import FullscreenLayout from '@/layout/Fullscreen.vue';


export default {
  name: 'EfficienceModalManual',
  components: { FullscreenLayout },
  mixins: [
    localCopyMixin({
      propertyName: 'showModal',
      copyPropertyName: 'localShowModal',
    }),
  ],
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  mounted () {
    window.addEventListener('message', this.onMessage);
  },
  destroyed () {
    window.removeEventListener('message', this.onMessage);
  },
  methods: {
    closeModal () {
      this.localShowModal = false;
    },
    onMessage ({ origin, data }) {
      NovaTools.icanopee.EfficienceManual.receiveMessageFromEfficienceManual(origin, data);
    },
  },
};
</script>

<style scoped lang="scss">
  .fullscreen-layout {
    background-color: #FFFFFF;
    ::v-deep {
      .page-layout {
        .page-layout__content {
          padding: 0;
          display: flex;
        }
      }
    }
  }

  .iframe-style {
    border: none;
    flex: 1;
  }
</style>