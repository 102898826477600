export const MEDICAL_LEAVE_PRESCRIBER_DATA = Object.freeze([
  {
    label: 'traitant ou le médecin ayant prescrit l\'arrêt de travail initial',
    value: 'MT',
  },
  {
    label: 'remplaçant',
    value: 'MR',
  },
  {
    label: 'spécialiste',
    value: 'MS',
  },
  {
    label: 'hospitalier',
    value: 'MH',
  },
  {
    label: 'participant au cabinet de groupe',
    value: '5',
  },
  {
    label: 'intervenant pour une urgence médicalement justifiée',
    value: '6',
  },
  {
    label: 'intervenant dans le lieu d\'éloignement de l\'assuré hors de sa résidence habituelle',
    value: '7',
  },
  {
    label: 'autre (préciser) : ',
    value: '8',
  },
]);