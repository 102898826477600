<template>
  <app-form :submit-method="save">
    <app-select
      v-model="category"
      v-test="'habitus-risk-factor-category-field'"
      label="Catégorie"
      :items="habitusCategories"
      return-object
      item-value="code"
      item-text="label"
      class="mt-2"
    />
    <div
      v-if="selectedCategoryHasUnit"
      class="d-flex align-center"
    >
      <app-number-field
        v-model="unitValue"
        v-test="'habitus-risk-factor-unit-value-field'"
        rules="required"
        :label="'Valeur'"
        class="mt-2"
      />
      <div class="mt-6 ml-3 mr-3">
        {{ category.parameters.target }} /
      </div>
      <app-select
        v-model="unit"
        v-test="'habitus-risk-factor-unit-type-field'"
        rules="required"
        label="Unité"
        :items="category.parameters.units"
        item-value="code"
        item-text="label"
        class="mt-2"
      />
    </div>
    <app-textarea-field
      v-model="label"
      label="Description"
      hide-errors="auto"
      rules="required"
      data-test="habitus-risk-factor-description-field"
      rows="1"
      auto-grow
    />
  </app-form>
</template>

<script>

import PatientHabitus from '@/modules/patient/models/healthEntity/PatientHabitus';
import Patient from '@/modules/patient/models/Patient';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppNumberField from '@/components/ui/form/AppNumberField.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';

export default {
  name: 'HabitusRiskFactorForm',
  components: {
    AppForm,
    AppSelect,
    AppTextareaField,
    AppNumberField,
  },
  props: {
    value: {
      type: PatientHabitus,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      label: '',
      category: null,
      unitValue: null,
      habitusCategories: [],
      unit: '',
    };
  },
  computed: {
    selectedCategoryHasUnit () {
      return this.category?.valueType === 'PQ';
    },
    isEditing () {
      return !! this.value?.['@id'];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        // Pré-remplissage des champs en cas d'édition
        if (value) {
          this.label = value.label;
          this.category = value.habitus;
          this.unitValue = value.value;
          this.unit = value.habitusParameter;
        }
      },
    },
  },
  mounted () {
    this.loadHabitusCategories();
  },
  methods: {
    async loadHabitusCategories () {
      this.habitusCategories = await PatientHabitus.fetchHabitusCategories();
    },
    async save () {
      const payload = {
        type: 'habitus',
        label: this.label,
        familyRelationship: null,
        habitus: this.category['@id'],
        value: this.selectedCategoryHasUnit ? this.unitValue : null,
        habitusParameter: this.selectedCategoryHasUnit ? this.unit : null,
      };
      if (this.isEditing) {
        await PatientHabitus.update(new PatientHabitus({
          ...this.value,
          ...payload,
        }));
      } else {
        await PatientHabitus.insert(this.patient.getIri(), payload);
      }
      this.resetFields();
    },
    resetFields () {
      this.label = '';
      this.category = null;
      this.unitValue = null;
      this.unit = '';
    },
  },
};
</script>