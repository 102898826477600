import { intervalToDuration as intervalToDurationFns,
  formatDistanceToNow as formatDistanceToNowFns,
  startOfWeek as startOfWeekFns,
  startOfMonth as startOfMonthFns,
  endOfWeek as endOfWeekFns,
  add as addFn,
  areIntervalsOverlapping as areIntervalsOverlappingFn,
  max as maxFn,
  min as minFn,
  isSameDay as isSameDayFns,
  isWithinInterval as isWithinIntervalFns,
  isValid as isValidFns,
  isBefore as isBeforeFns,
  subDays as subDaysFns,
  isFuture as isFutureFns } from 'date-fns';
import { fr } from 'date-fns/locale';

import { pluralize } from './words';
import NovaTools from '@/nova-tools/NovaTools';

const dateFnsOptions = { weekStartsOn: 1 };

/**
 * Renvoi une durée entre 2 dates
 * @param { Date } start date de début
 * @param { Date } end date de fin
 * @returns { Duration }
 */
export const intervalToDuration = ({ start, end }) => intervalToDurationFns({
  start,
  end,
});
/**
 * Renvoie l'âge d'une personne depuis une date de naissance, sous la forme d'une chaine de caractère.
 * @example
 * // Si la date d'aujourd'hui est le 20 novembre 2020
 * getAge(new Date(2006, 5, 10)) // => "14 ans et 5 mois"
 * getAge(new Date(1981, 4, 15)) // => "39 ans"
 * getAge(new Date(2019, 6, 15)) // => "16 mois"
 *
 * @param { (Date | String) } birthDate Date de naissance de la personne
 * @param options
 * @returns  { String } l'âge de la personne
 */
export const getAge = (birthDate, options = {}) => {
  birthDate = birthDate instanceof Date ? birthDate : new Date(birthDate);

  let stopDate = options.deathDate ?? new Date();
  stopDate = stopDate instanceof Date ? stopDate : new Date(stopDate);

  const { years, months, days } = intervalToDuration({
    start: birthDate,
    end: stopDate,
  });

  if (options.returnAsRawInterval) {
    return {
      years,
      months,
      days,
    };
  }

  if ((years < 18 && years >= 2) && months !== 0) {
    return `${years} ans et ${months} mois`;
  }
  if (years === 0 && months === 0) {
    return `${days} ${pluralize('jour', days)}`;
  }
  const totalMonths = 12 * years + months;
  if (totalMonths < 24) {
    return `${totalMonths} mois`;
  }
  return `${years} ans`;
};

/**
 * @deprecated Utiliser le plugin dates de novalys "NovaTools.dates.format"
 * Formatte une date en français
 * @param {Date|String} date
 * @param {String} [formatStr='dd MMM yyyy'] le format voulu de la date ex dd/MM/yyyy
 * @see https://date-fns.org/docs/format#description pour les formats acceptés
 */
export const format = (date, formatStr = 'dd MMM yyyy') => NovaTools.dates.format(date, formatStr);

/**
 * Formatte le temps écoulé à partir de la date actuelle
 * @see https://date-fns.org/v2.22.1/docs/formatDistanceToNow
 * @param {Date|String} date
 * @returns {String} Le temps écoulé à partir de la date actuelle
 */
export const formatDistanceToNow = (date) => formatDistanceToNowFns(new Date(date), {
  addSuffix: true,
  locale: fr,
});

/**
 * Renvoi la date du 1er jour à 00h de la semaine contenant le jour donné
 * @param {Date} date
 * @param options
 * @param {Object} [options.weekStartsOn=1] Le jour considéré comme le 1er de la semaine (0 pour dimanche, 1 pour lundi)
 * @return {Date} 1er jour de la semaine
 */
export const startOfWeek = (date, options = dateFnsOptions) => startOfWeekFns(date, options);

/**
 * Renvoi la date du 1er jour à 00h du mois contenant le jour donné
 * @param {Date} date
 * @return {Date} 1er jour du mois
 */
export const startOfMonth = (date) => startOfMonthFns(date);

/**
 * Renvoi la date du dernier jour à 23h59 de la semaine contenant le jour donné
 * @param {Date} date
 * @param options
 * @param {Object} [options.weekStartsOn=1] Le jour considéré comme le 1er de la semaine (0 pour dimanche, 1 pour lundi)
 * @return {Date} dernier jour de la semaine
 */
export const endOfWeek = (date, options = dateFnsOptions) => endOfWeekFns(date, options);

/**
 * Renvoi le numéro de la semaine de la date donnée
 * @param {Date} date
 * @return {Number} Le numéro de la semaine
 */
export const getWeek = date => {
  const UTCDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  const dayNum = UTCDate.getUTCDay() || 7;
  UTCDate.setUTCDate(UTCDate.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(UTCDate.getUTCFullYear(), 0, 1));
  return Math.ceil(((UTCDate - yearStart) / 86400000 + 1) / 7);
};

/**
 * Ajoute une durée à une date
 * @param {Date} date
 * @param {Object} addition
 * @param {Integer} addition.weeks
 * @param {Integer} addition.days
 * @param {Integer} addition.month
 * @param {Integer} addition.years
 * @param {Integer} addition.hours
 * @param {Integer} addition.minutes
 * @param {Integer} addition.seconds
 * @return {Date} la nouvelle date
 */
export const add = (date, addition) => addFn(date, addition);

/**
 * Retire un nombre de jours à une date
 * @param {Date} date
 * @param {Integer} duration nombre de jours à retirer
 * @return {Date} la nouvelle date
 */
export const subDays = (date, duration) => subDaysFns(date, duration);

/**
 * Est-ce que les intervales de temps se chevauchent ?
 * @param {Interval} intervalLeft
 * @param {Interval} intervalRight
 * @param {Boolean} inclusive
 * @return {Boolean} Si les intervales se chevauchent
 */
export const areIntervalsOverlapping = (
  intervalLeft,
  intervalRight,
  inclusive = true,
) => areIntervalsOverlappingFn(intervalLeft, intervalRight, { inclusive });
/**
 * Récupère la date la plus récente parmis un ensemble
 * @param {Array.<Date> | Array.<Number>} datesArray
 * @return {Date} La date la plus récente
 */
export const max = (datesArray) => maxFn(datesArray);

/**
 * Récupère la date la plus ancienne parmis un ensemble
 * @param {Array.<Date> | Array.<Number>} datesArray
 * @return {Date} La date la plus ancienne
 */
export const min = (datesArray) => minFn(datesArray);

/**
 * Vérifie qu'une date est dans le futur
 * @param dates La date à tester
 * @return {Boolean}
 */
export const isFuture = (dates) => isFutureFns(dates);

/**
 * Calcule la différence en minutes entre deux dates
 * @param {Date} dateLeft - la date de référence
 * @param {Date} dateRight - la date avec laquelle la différence doit être calculée
 * @return {Number} Le nombre de minutes entre les deux dates
 */
export const differenceInMinutes = (dateLeft, dateRight) => (dateLeft.getTime() - dateRight.getTime()) / 60 / 1000;

/* Détermine si deux dates sont dans la même journée
 * @param {Date} dateLeft
 * @param {Date} dateRight
 * @returns {Boolean} Si les deux dates sont dans la même journée
 */
export const isSameDay = (dateLeft, dateRight) => isSameDayFns(dateLeft, dateRight);

/**
 * Détermine si une date est aujourd'hui
 * @param {Date} date
 * @returns {Boolean} Si la date est aujourd'hui
 */
export const isToday = (date) => isSameDay(date, new Date());

/**
 * Détermine si une date est situé dans un intervalle
 * @param {Date} date
 * @param {Object} interval
 * @param {Date} interval.start
 * @param {Date} interval.end
 * @returns {Boolean} Si la date est situé dans un intervalle
 */
export const isWithinInterval = (date, interval) => isWithinIntervalFns(date, interval);

/**
 * Vérifie si une date est valide
 * @param {Date} date
 * @returns {Boolean} Si la date est valide
 */
export const isValid = (date) => isValidFns(date);

/**
 * Vérifie si la 1ere date se situe avant la 2eme
 * @param {Date|String} dateLeft - 1ere date
 * @param {Date|String} dateRight - 2eme date
 * @returns {Boolean}
 */
export const isBefore = (dateLeft, dateRight) => isBeforeFns(new Date(dateLeft), new Date(dateRight));

/**
 * Vérifie si deux dates sont exactement égales
 * @param {Date|String} date1 - 1ere date
 * @param {Date|String} date2 - 2eme date
 * @returns {Boolean}
 */
export const isEqual = (date1, date2) => new Date(date1).getTime() === new Date(date2).getTime();

/**
 * Retourne la date au format Iso (YYYY-DD-MM) à partir d'une date
 * @param {Date|String} date
 * @returns {String} la date au format Iso (YYYY-DD-MM)
 */
export const getIsoDate = (date) => new Date(date).toISOString().split('T')[0];