<template>
  <section
    class="mobile-sub-header elevation-5"
    :class="{ 'mobile-sub-header--centered-title': centeredTitle }"
  >
    <app-icon
      v-if="backRouteName || customBackMethod"
      icon="left"
      data-test="go-back-button"
      @click="handleBackArrowClick"
    />
    <span
      class="mobile-sub-header__title flex d-flex"
      data-test="navigation-title"
    >
      <slot>{{ title }}</slot>
    </span>
    <app-icon
      v-if="$listeners['click:menu']"
      icon="menu-alt"
      data-test="navigation-menu-button"
      @click="$emit('click:menu')"
    />
  </section>
</template>

<script>
/**
 * Représente le sub header sur mobile
 */
export default {
  name: 'AppMobileSubheader',
  props: {
    /**
     * Nom de la route
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Route vers laquelle rediriger
     */
    backRouteName: {
      type: String,
      default: null,
    },
    /**
     * Affiche le titre ou son contenu au centre du composant
     */
    centeredTitle: {
      type: Boolean,
      default: false,
    },
    /**
     * Dans certain cas, une redirection ne sera pas necessaire pour passer à la vue précédente
     * Cette prop permet de fournir une méthode personnalisée éxéutée lors du clic sur la flêche de retour arrière
     * permettant d'implémenter un fonctionnement pour un contexte spécifique.
     *
     * Si la prop backRouteName est également renseignée, la redirection par défaut sera ignorée et uniquement
     * customBackMethod sera éxécutée
     */
    customBackMethod: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleBackArrowClick () {
      if (this.customBackMethod) {
        this.customBackMethod();
        return;
      }
      this.$router.push({ name: this.backRouteName });
    },
  },
};
</script>

<style lang="scss" scoped>
$bar-height: 40px;

.mobile-sub-header {
  position: relative;
  height: $bar-height;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 map-get($spacers, 4);
  gap: map-get($spacers, 4);

  &--centered-title {
    justify-content: space-between;
  }

  &--centered-title &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__title {
    font-size: 13px;
    font-weight: 600;
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>