import EventSetting from './EventSetting';
import ApiEntity from '@/models/ApiEntity';
import { differenceInMinutes } from '@/utils/functions/dates';

export default class Event extends ApiEntity {
  constructor (options = {}) {
    super(options);

    this.date = options.date || null;
    this.startTime = options.startTime || null;
    this.endTime = options.endTime || null;
    this.setting = new EventSetting(options.setting) || null;
  }

  getStartDateTime () {
    return new Date(`${this.date}T${this.startTime}`);
  }

  getEndDateTime () {
    return new Date(`${this.date}T${this.endTime}`);
  }

  getDuration () {
    return differenceInMinutes(this.getEndDateTime(), this.getStartDateTime());
  }

  getLabelPrefix (inVisibleTimeRange) {
    return inVisibleTimeRange
      ? ''
      : this.getStartDateTime().toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      });
  }

  getLabel (inVisibleTimeRange) {
    return [this.getLabelPrefix(inVisibleTimeRange), this.setting.title].join(' ');
  }

  getScheduleName () {
    return this.setting.schedule.name;
  }

  isInTimeRange (timeRange) {
    // Récupère l'heure de début et de fin de la journée
    const scheduleStartHour = Number(timeRange.startTime.split(':')[0]);
    const scheduleEndHour = Number(timeRange.endTime.split(':')[0]);

    // On défini les horaires de la journée où l'event a eu lieu
    const scheduleDayStart = new Date(this.getStartDateTime());
    scheduleDayStart.setHours(scheduleStartHour);
    scheduleDayStart.setMinutes(0);

    const scheduleDayEnd = new Date(this.getStartDateTime());
    scheduleDayEnd.setHours(scheduleEndHour);
    scheduleDayEnd.setMinutes(0);

    // Conversion en timestamp pour faciliter la comparaison
    const eventStartTime = this.getStartDateTime().getTime();
    const eventEndTime = this.getEndDateTime().getTime();

    const scheduleStartTime = scheduleDayStart.getTime();
    const scheduleEndTime = scheduleDayEnd.getTime();

    return eventStartTime < scheduleEndTime && eventEndTime > scheduleStartTime;
  }

  getColor () {
    return '#4d7cff';
  }

  isLabelTimed () {
    return true;
  }

  toAgendaEvent (timeRange, scheduleName = '') {
    const isInTimeRange = this.isInTimeRange(timeRange);
    return {
      ...this,
      timed: isInTimeRange,
      name: this.getLabel(isInTimeRange),
      start: this.getStartDateTime(),
      end: this.getEndDateTime(),
      category: { categoryName: scheduleName },
      getColor: this.getColor,
      isLabelTimed: this.isLabelTimed,
      eventInstance: this,
    };
  }
}