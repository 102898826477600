<template>
  <section>
    <n-alert
      v-if="!patientIns.isQualified()"
      v-test="'ins-alert'"
      type="warning"
      :title="patientInsLabel"
      transparent
      message="Le document ne pourra pas être transféré sur le DMP du patient"
    />
    <p v-else>
      <app-switch
        v-if="isDesynchronizable"
        v-model="localValue"
        dense
        label="Synchronisation automatique avec le dmp"
      />
      <template v-else>
        Ce document sera envoyé dans le DMP du patient à sa validation.
      </template>
    </p>
  </section>
</template>

<script>
import { computed } from 'vue';

import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue';

/**
 * Permet d'afficher un message qui précise que le document sera envoyé au DMP à sa validation
 * Si le patient n'a pas le statut INS qualifié, un message explique que l'envoi au DMP ne sera pas possible
*/
export default {
  name: 'DmpSendingActionMessage',
  components: { AppSwitch },
  mixins: [localCopyMixin()],
  props: {
    patientIns: {
      type: PatientInsIdentity,
      required: true,
    },
    value: {
      type: Boolean,
      default: true,
    },
    isDesynchronizable: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const patientInsLabel = computed(() => `Patient avec ${props.patientIns.getLabel().toLowerCase()}`);
    return { patientInsLabel };
  },
};
</script>