<template>
  <section>
    <div class="flex-list-2 flex-column">
      <app-dataset-item
        v-test="'request-date'"
        label="Requête envoyée le"
        :value="requestFormattedDate"
      />
      <app-dataset-item
        v-test="'code'"
        label="Code de retour"
        :value="message.code"
      />
    </div>
    <n-divider :spacing="4" />
    <app-row>
      <app-col cols="12">
        <div class="flex-list-2 flex-column">
          <h3 class="subtitle-3">
            Bénéficiaire
          </h3>
          <app-row>
            <app-col
              cols="12"
              md="6"
            >
              <div class="flex-column flex-list-1">
                <app-dataset-item
                  v-test="'beneficiary-fullname'"
                  label="Nom complet"
                  :value="beneficiary.fullName"
                />
                <app-dataset-item
                  v-test="'beneficiary-birth-date'"
                  label="Date de naissance"
                  :value="beneficiary.getFormattedBirthDate()"
                />
                <app-dataset-item
                  v-test="'beneficiary-nir'"
                  label="NIR"
                  :value="beneficiary.nir ? beneficiary.nir.getFullNir() : null"
                />
                <app-dataset-item
                  v-test="'right-opening-nir'"
                  label="NIR de l'ouvrant droit"
                  :value="beneficiary.nirOd ? beneficiary.nirOd.getFullNir() : null"
                />
              </div>
            </app-col>
            <app-col
              cols="12"
              md="6"
            >
              <div class="flex-column flex-list-1">
                <app-dataset-item
                  v-test="'beneficiary-birth-rank'"
                  label="Rang de naissance"
                  :value="beneficiary.birthrank"
                />
                <app-dataset-item
                  v-test="'beneficiary-grand-regime'"
                  label="Grand régime"
                  :value="beneficiary.grandRegime"
                />
                <app-dataset-item
                  v-test="'beneficiary-desk'"
                  label="Caisse"
                  :value="beneficiary.desk"
                />
                <app-dataset-item
                  v-test="'beneficiary-center'"
                  label="Centre"
                  :value="beneficiary.center"
                />
              </div>
            </app-col>
          </app-row>
        </div>
      </app-col>
    </app-row>
    <n-divider :spacing="4" />
    <app-row>
      <app-col cols="12">
        <div class="flex-list-2 flex-column">
          <h3 class="subtitle-3">
            Médecin traitant
          </h3>
          <app-row>
            <app-col
              cols="12"
              md="6"
            >
              <div class="flex-column flex-list-1">
                <app-dataset-item
                  v-test="'practitioner-full-name'"
                  label="Nom complet"
                  :value="practitionerFullName"
                />
                <app-dataset-item
                  v-test="'practitioner-rpps'"
                  label="Numéro RPPS"
                  :value="practitioner.rpps || null"
                />
                <app-dataset-item
                  v-test="'psam'"
                  label="Numéro PSAM"
                  :value="practitioner.psam || null"
                />
                <app-dataset-item
                  v-test="'adeli'"
                  label="Numéro ADELI"
                  :value="practitioner.adeli || null"
                />
              </div>
            </app-col>
            <app-col
              cols="12"
              md="6"
            >
              <div class="flex-column flex-list-1">
                <app-dataset-item
                  v-test="'practitioner-address'"
                  label="Adresse"
                  :value="address"
                />
                <app-dataset-item
                  v-test="'practitioner-email'"
                  label="E-mail"
                  :value="practitioner.email || null"
                />
              </div>
            </app-col>
          </app-row>
        </div>
      </app-col>
    </app-row>
  </section>
</template>

<script>

import TlsiBeneficiary from '@/modules/patient/utils/classes/TlsiBeneficiary';
import NovaTools from '@/nova-tools/NovaTools';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';

export default {
  name: 'ImtiResultSummary',
  components: { AppDatasetItem },
  props: {
    message: {
      type: Object,
      required: true,
    },
    practitioner: {
      type: Object,
      required: true,
    },
    beneficiary: {
      type: TlsiBeneficiary,
      required: true,
    },
  },
  computed: {
    requestFormattedDate () {
      return NovaTools.dates.format(this.message.time, 'dd MMM yyyy à HH:mm');
    },
    practitionerFullName () {
      return `${this.practitioner.firstName} ${this.practitioner.lastName}`;
    },
    address () {
      if (this.practitioner.address) {
        const { line1, line2, postalCode, city, country } = this.practitioner.address;
        const lastPartAddress = [postalCode, city, country ? `(${country})` : null].filter(item => !! item).join(' ');
        return [line1, line2, lastPartAddress].filter(item => !! item).join(' - ');
      }
      return null;
    },
  },
};
</script>