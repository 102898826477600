
import { ROUTE_NAMES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';

import PatientFileModule from '@/modules/patient/modules/patientFile/PatientFileModule.vue';
import PatientModule from '@/modules/patient/PatientModule.vue';

export default {
  path: '/patients',
  component: PatientModule,
  children: [
    {
      path: '/',
      name: ROUTE_NAMES.LISTING,
      component: () => import('@/modules/patient/views/PatientsListing.vue'),
    },
    {
      path: 'nouveau-patient/:patientIdentity?',
      name: ROUTE_NAMES.PATIENT_CREATE,
      component: () => import('@/modules/patient/views/PatientFormView.vue'),
      props: { prefilled_patient: new Patient() },
      meta: { layout: 'empty' },
    },
    {
      path: ':uuid/dossier',
      name: 'patients.editPatient',
      component: () => import('@/modules/patient/views/PatientFormView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: '/patients/:uuid',
      name: ROUTE_NAMES.PATIENT_FILE,
      component: PatientFileModule,
      meta: {
        hideHeader: { mobileOnly: true },
        hideMobileBottomNavigation: true,
      },
    },
    {
      path: '/patients/:uuid/consultation/:consultationUUID?',
      name: 'patients.consultation',
      component: () => import('@/modules/patient/views/Consultation.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: '/patients/:uuid/consultation/:consultationUUID/template/:templateUUID/document',
      name: ROUTE_NAMES.CONSULTATION_DOCUMENT_PREVIEW,
      component: () => import('@/modules/documents/views/DocumentPreview.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: '/patients/:uuid/consultation/:consultationUUID/document/:documentUuid',
      name: ROUTE_NAMES.CONSULTATION_DOCUMENT_EDITION,
      component: () => import('@/modules/documents/views/DocumentPreview.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: '/patients/:uuid/consultation/:consultationUUID/ordonnance/:prescriptionUuid?',
      name: ROUTE_NAMES.CREATE_PRESCRIPTION,
      component: () => import('@/modules/patient/views/PatientPrescription.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: '/patients/:uuid/consultation/:consultationUUID/ordonnance/:prescriptionUuid/edit',
      name: ROUTE_NAMES.EDIT_PRESCRIPTION,
      component: () => import('@/modules/patient/views/PatientPrescription.vue'),
      meta: { layout: 'empty' },
      props: true,
    },
  ],
};