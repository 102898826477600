<template>
  <n-dialog
    :is-open.sync="localValue"
    :title="title"
    hide-footer
    divided
    :width="isMobile ? '90%' : '75%'"
    content-class="document-meta-dataset-modal"
    @cancel="handleCancel"
  >
    <app-tabs
      grow
      class="ma-n6 document-meta-dataset-modal__tabs"
      outlined
    >
      <app-tabs-item
        v-test="'informations-tab'"
        label="Informations"
      >
        <div :class="{'document-meta-dataset-modal__panels-container': ! isMobile}">
          <app-panel
            is-outlined
            is-flat
            :class="{ 'mb-2': isMobile }"
          >
            <document-meta-dataset-section :document-meta-dataset="documentMetaDataset" />
          </app-panel>
          <app-panel
            is-outlined
            is-flat
          >
            <document-supported-section :document-meta-dataset="documentMetaDataset" />
          </app-panel>
        </div>
      </app-tabs-item>
      <app-tabs-item
        v-if="documentMetaDataset.isReportOfBiologicalExaminations()"
        v-test="'bio-result-tab'"
        label="Résultats d'analyses"
      >
        <bio-result-list :bio-results="documentMetaDataset.bioResults" />
      </app-tabs-item>
    </app-tabs>
  </n-dialog>
</template>

<script>
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppPanel from '@/components/ui/panel/AppPanel.vue';
import BioResultList from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultList.vue';
import DocumentMetaDatasetSection from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/DocumentMetaDatasetSection.vue';
import DocumentSupportedSection from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/DocumentSupportedSection.vue';




export default {
  name: 'MessagingDocumentMetaDatasetModal',
  components: {
    BioResultList,
    DocumentMetaDatasetSection,
    DocumentSupportedSection,
    AppPanel,
  },
  mixins: [localCopyMixin()],
  props: {
    title: {
      type: String,
      required: true,
    },
    documentMetaDataset: {
      type: MssDocumentMetaDataset,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCancel () {
      this.localValue = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style>
.document-meta-dataset-modal {
  min-height: 80%;
  max-height: 80% !important;
}
</style>

<style scoped lang="scss">
.document-meta-dataset-modal {
  &__panels-container {
    display: grid;
    gap: map-get($spacers, 6);
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  &__tabs {
    ::v-deep {
      .app-tabs__headers-container {
        position: sticky;
        top: -25px;
        z-index: 10;

        .app-tabs__headers {
          border-top: none;
        }
      }
    }
  }
}
</style>