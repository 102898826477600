<template>
  <app-panel class="document-analytic-widget-layout">
    <template #title>
      <slot name="title">
        {{ title }}
      </slot>
    </template>
    <app-row>
      <app-col cols="8">
        <div class="mb-2">
          <slot name="total-count" />
        </div>
        <slot name="qualified-ins-count" />
        <slot name="unqualified-ins-count" />
      </app-col>
      <app-col cols="4">
        <slot name="failed-count" />
      </app-col>
    </app-row>
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue';

export default {
  name: 'DocumentAnalyticWidget',
  components: { AppPanel },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.document-analytic-widget-layout {
  &__count-item {
    display: flex;
    align-items: center;
    gap: map-get($spacers, 4);

    &__count {
      font-weight: 600;
      width: 24px;
    }
  }

  &__number-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
</style>