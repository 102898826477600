import { mapGetters } from 'vuex';

import { MODULES_MENU_ITEMS } from '@/constants';


/**
 * La mixin permettant de déterminer les modules de l'application à afficher
 */
export default {
  name: 'moduleItemsMixin',
  computed: {
    ...mapGetters('auth', { moduleItemsMixin_getCurrentUserProfile: 'getCurrentUserProfile' }),
    moduleItemsMixin_menuItems () {
      return Object.values(MODULES_MENU_ITEMS)
        .filter(moduleMenuItem => moduleMenuItem.have_access.includes(this.moduleItemsMixin_getCurrentUserProfile));
    },
  },
};