<template>
  <div>
    <app-data-table
      :headers="tableHeaders"
      :items="value"
      :loading="isFetching"
      class="app-data-table-consultation"
      @click:row-clicked="openCosultation"
    />
    <consult-prescription-modal
      v-if="selectedPrescriptionAmId"
      :am-id="selectedPrescriptionAmId"
      :is-open="isOpen"
      @cancel="closeConsultation"
    />
  </div>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue';
import ConsultPrescriptionModal
  from '@/layout/default/components/header/searchMenu/searchPrescription/ConsultPrescriptionModal.vue';

export default {
  name: 'SearchPrescriptionResultList',
  components: {
    ConsultPrescriptionModal,
    AppDataTable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpen: false,
      selectedPrescriptionAmId: null,
      tableHeaders: [
        {
          text: 'Identifiant e-prescription',
          value: 'prescription.amId',
          width: '40%',
        },
        {
          text: 'Type de prescription',
          value: 'prescription.type',
          width: '30%',
        },
        {
          text: 'Patient associé',
          value: 'patient.nirOD',
          width: '30%',
        },
      ],
    };
  },
  methods: {
    openCosultation (item) {
      this.selectedPrescriptionAmId = item.prescription.amId;
      this.isOpen = true;
    },
    closeConsultation () {
      this.selectedPrescriptionAmId = null;
      this.isOpen = false;
    },
  },
};
</script>
<style scoped lang="scss">
.app-data-table-consultation {
  height: 250px;
  margin-top: map-get($spacers, 4);

  ::v-deep {
    tr {
      cursor: pointer;
    }
  }
}
</style>