import Vue from 'vue';

import { CALENDAR_VIEWS } from '@/modules/agenda/constants';
import Availability from '@/modules/agenda/models/events/Availability';
import AvailabilitySettings from '@/modules/agenda/models/events/AvailabilitySettings';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api';
import { format, startOfWeek, endOfWeek } from '@/utils/functions/dates';


const state = () => ({
  availabilitiesSettings: {},
  availabilities: {},
  isLoadingAvailabilitiesSettings: false,
});

const getters = {
  getAvailabilitiesSettings: state => Object.values(state.availabilitiesSettings),
  getAvailabilities: state => Object.values(state.availabilities),
  getVisibleAvailabilities: state => Object.values(state.availabilities)
    .filter(availability => state.visibleScheduleIDs.includes(availability.setting.schedule['@id'])),
  getAvailabilitySettingByUuid: state => uuid => state.availabilitiesSettings[`/api/availability_settings/${uuid}`],
  getAvailabilitiesSettingsByScheduleIri: (state, getters) => (scheduleIri) => getters.getAvailabilitiesSettings
    .filter((availability) => availability.schedule['@id'] === scheduleIri),
};

const mutations = {
  SET_AVAILABILITY_SETTINGS (state, availabilitySettings) {
    Vue.set(state.availabilitiesSettings, availabilitySettings['@id'], new AvailabilitySettings(availabilitySettings));
  },
  SET_AVAILABILITIES_SETTINGS_LOADING (state, isLoadingAvailabilitiesSettings) {
    state.isLoadingAvailabilitiesSettings = isLoadingAvailabilitiesSettings;
  },
  DELETE_AVAILABILITY_SETTINGS (state, availabilitySettings) {
    Vue.delete(state.availabilitiesSettings, availabilitySettings['@id']);
  },
  SET_AVAILABILITY (state, availability) {
    Vue.set(state.availabilities, availability['@id'], new Availability(availability));
  },
  DELETE_AVAILABILITY (state, availability) {
    Vue.delete(state.availabilities, availability['@id']);
  },
};

const serializeAvailabilitySettings = (availabilitySettings) => ({
  ...availabilitySettings,
  schedule: availabilitySettings.schedule['@id'],
  appointmentMotives: availabilitySettings.appointmentMotives.map(
    motive => motive['@id'],
  ),
});

const actions = {
  async fetchAvailabilitiesSettings ({ commit }) {
    try {
      commit('SET_AVAILABILITIES_SETTINGS_LOADING', true);
      const { data } = await getFromAPI('/api/availability_settings');
      data['hydra:member'].forEach(availabilitySettings => commit('SET_AVAILABILITY_SETTINGS', availabilitySettings));
      return true;
    } catch (error) {
      return false;
    } finally {
      commit('SET_AVAILABILITIES_SETTINGS_LOADING', false);
    }
  },
  async fetchOneAvailabilitySetting ({ commit }, uuid) {
    try {
      const { data } = await getFromAPI(`/api/availability_settings/${uuid}`);
      return commit('SET_AVAILABILITY_SETTINGS', data);
    } catch (error) {
      return false;
    }
  },
  async saveAvailabilitySettings ({ commit, dispatch }, availabilitySettings) {
    const { data } = await postToAPI('/api/availability_settings', serializeAvailabilitySettings(availabilitySettings));
    commit('SET_AVAILABILITY_SETTINGS', data);
    await dispatch('fetchAvailabilities', { schedule: [availabilitySettings.schedule['@id']] });
  },

  async updateAvailabilitySettings ({ commit, dispatch }, availabilitySettings) {
    const { data } = await putToAPI(`${availabilitySettings['@id']}`, { data: serializeAvailabilitySettings(availabilitySettings) });
    await dispatch('clearAvailabilitiesFromSettingsIri', availabilitySettings['@id']);

    commit('SET_AVAILABILITY_SETTINGS', data);
    await dispatch('fetchAvailabilities', { schedule: [availabilitySettings.schedule['@id']] });
  },

  async fetchAvailabilities ({ commit, getters, state }, { schedule = state.visibleScheduleIDs } = {}) {
    let fromDate;
    let toDate;

    if (getters.getView === CALENDAR_VIEWS.WEEK.value) {
      fromDate = startOfWeek(state.currentDate);
      toDate = endOfWeek(state.currentDate);
    } else {
      fromDate = state.currentDate;
      toDate = state.currentDate;
    }

    const { data } = await getFromAPI('/api/availabilities', {
      'date[after]': format(fromDate, 'yyyy-MM-dd'),
      'date[before]': format(toDate, 'yyyy-MM-dd'),
      schedule,
    });
    data['hydra:member'].forEach(availability => {
      commit('SET_AVAILABILITY', availability);
      commit('SET_AVAILABILITY_SETTINGS', availability.setting);
    });
  },

  async deleteAvailabilitySettings ({ commit, dispatch }, availabilitySettings) {
    await deleteFromAPI(availabilitySettings['@id']);
    commit('DELETE_AVAILABILITY_SETTINGS', availabilitySettings);
    dispatch('clearAvailabilitiesFromSettingsIri', availabilitySettings['@id']);
  },

  async clearAvailabilitiesFromSettingsIri ({ getters, dispatch }, settingIri) {
    await dispatch('clearEventsFromSettingsIri', {
      events: getters.getAvailabilities,
      settingIri,
      commitName: 'DELETE_AVAILABILITY',
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};