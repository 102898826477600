<template>
  <div>
    <div
      v-if="loading"
      class="mb-last-0"
    >
      <div
        v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
        :key="index"
        v-test="'appointment-skeleton'"
        class="mb-4"
      >
        <app-skeleton-loader
          type="text"
          width="40%"
        />
        <app-skeleton-loader
          type="text"
          width="60%"
        />
      </div>
    </div>
    <div
      v-else-if="firstAppointments.length > 0"
      class="mb-last-0"
    >
      <appointments-panel-item
        v-for="appointment in firstAppointments"
        :key="appointment['@id']"
        v-test="'patient-appointment'"
        :appointment="appointment"
        class="mb-3"
      />
      <n-expandable
        v-if="otherAppointments.length > 0"
        v-test="'more-section'"
        :content-spacing-left="0"
      >
        <template #header="{ toggle, isExpanded }">
          <app-link @click="toggle">
            {{ `Afficher ${isExpanded ? 'moins' : 'plus'}` }}
          </app-link>
        </template>
        <appointments-panel-item
          v-for="appointment in otherAppointments"
          :key="appointment['@id']"
          v-test="'patient-appointment'"
          :appointment="appointment"
          class="mb-3"
        />
      </n-expandable>
    </div>
    <p
      v-else
      v-test="'appointments-panel-no-data'"
      class="mb-0 secondary--text"
    >
      Aucun rendez-vous {{ noAppointmentText }}
    </p>
  </div>
</template>

<script>
import AppointmentsPanelItem from './AppointmentsPanelItem.vue';
import Appointment from '@/modules/agenda/models/events/Appointment';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI } from '@/services/api';
import { isBefore, subDays } from '@/utils/functions/dates';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';


const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 4;

export default {
  name: 'AppointmentsTab',
  components: {
    AppSkeletonLoader,
    AppointmentsPanelItem,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    appointmentCategory: {
      type: String,
      required: true,
      validator: cat => ['past', 'future'].includes(cat),
    },
  },
  data () {
    return {
      firstAppointments: [],
      otherAppointments: [],
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
      loading: false,
    };
  },
  computed: {
    isFuture () {
      return this.appointmentCategory === 'future';
    },
    noAppointmentText () {
      return this.isFuture ? 'à venir' : 'passé';
    },
    apiFilter () {
      return this.isFuture ? 'after' : 'before';
    },
    limitFilterDate () {
      return NovaTools.dates.format(this.isFuture ? new Date() : subDays(new Date(), 1), 'yyyy-MM-dd');
    },
  },
  async mounted () {
    await this.loadAppointments();
  },
  methods: {
    async loadAppointments () {
      this.loading = true;

      const { data } = await getFromAPI(`/api/patients/${this.patient.getUuid()}/appointments?date[${this.apiFilter}]=${this.limitFilterDate}`);

      const appointments = data['hydra:member'] ? data['hydra:member'].map(appointment => new Appointment({
        ...appointment,
        patient: this.patient.getIri(),
      })) : [];

      this.$emit('update-count', appointments.length);

      const chronologicalOrder = this.isFuture;
      appointments.sort((appointment, nextAppointment) => {
        const comparisonDatesArray = [new Date(appointment.startDateTime), new Date(nextAppointment.startDateTime)];

        if (chronologicalOrder) {
          comparisonDatesArray.reverse();
        }

        return isBefore(comparisonDatesArray[0], comparisonDatesArray[1]) ? 1 : - 1;
      });
      this.firstAppointments = appointments.slice(0, 5);
      this.otherAppointments = appointments.slice(5);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .n-expandable main {
    max-height: 250px;
    overflow-y: auto;
  }
}
</style>