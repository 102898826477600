<template>
  <app-col
    cols="12"
    md="12"
    lg="4"
    class="mb-n5"
  >
    <app-autocomplete
      v-model="localValue"
      v-test="'ins-identity-device-field'"
      class="ins-identity-device-field"
      item-value="@id"
      item-text="value"
      :items="insIdentityDevices"
      clearable
      label="Dispositif"
      hide-errors="auto"
      return-object
      :disabled="autoSelectStatus && !canChangeDevice"
    >
      <template #selection="{ item }">
        <app-badge :color="item.strongTrustLevel ? 'success' : 'warning'">
          <p class="subtitle-3 ins-identity-device-field__selected-value">
            {{ item.value }}
          </p>
        </app-badge>
      </template>
      <template #item="{ item }">
        <article class="py-2">
          <p class="subtitle-3 mb-1">
            {{ item.value }}
          </p>
          <app-badge :color="item.strongTrustLevel ? 'success' : 'warning'">
            Niveau de confiance {{ item.strongTrustLevel ? 'fort' : 'faible' }}
          </app-badge>
        </article>
      </template>
    </app-autocomplete>
  </app-col>
</template>

<script>
import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppBadge from '@/components/ui/badge/AppBadge.vue';
import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';


export default {
  name: 'IdentityDeviceField',
  components: {
    AppBadge,
    AppAutocomplete,
  },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: InsIdentityDevice,
      default: null,
    },
    autoSelectStatus: {
      type: Boolean,
      default: false,
    },
    canChangeDevice: {
      type: Boolean,
      default: false,
    },
    insIdentityDevices: {
      type: Array,
      required: true,
    },
    currentInsStatus: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
  .ins-identity-device-field {
    ::v-deep {
      .v-select__selections {
        flex-wrap: nowrap;
      }
    }

    &__selected-value {
      max-width: 168px;

      @media (min-width: 1517px), (min-width: 390px) and (max-width: 1264px) {
        max-width: 228px;
      }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>