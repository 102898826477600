<template>
  <n-expandable
    v-if="motiveCode"
    :icon-rotation-angle="[-90, 0]"
    :expanded="true"
    class="mb-4"
  >
    <template #header="{ toggle, expandIconStyle }">
      <div class="motive-duration-refs-table__header">
        <app-icon
          icon="caret"
          color="secondary"
          :style="expandIconStyle"
          @click="toggle"
        />
        <h3 class="motive-duration-refs-table__header__label">
          Durées indicatives pour le motif :
          <span class="mx-2">{{ motiveLabel }}</span>
          <a
            v-if="detailedContentUrl"
            v-test="'motive-duration-link'"
            :href="detailedContentUrl"
            target="_blank"
          >
            <app-icon icon="file-pdf" />
          </a>
        </h3>
      </div>
    </template>
    <table v-if="rows.length > 0" v-test="'motive-duration-table'" class="motive-duration-refs-table__table">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.value"
            :colspan="header.colspan"
            :rowspan="header.rowspan"
            class="motive-duration-refs-table__table__headers"
          >
            {{ header.text }}
          </th>
        </tr>
        <tr
          v-for="(subHeaderGroup, subHeaderLevel) in displayedSubHeaders"
          :key="subHeaderLevel"
          class="motive-duration-refs-table__table__subheaders"
        >
          <th
            v-for="(subHeader, subHeaderIndex) in subHeaderGroup"
            :key="subHeaderIndex"
            :colspan="subHeader.colspan"
            :rowspan="subHeader.rowspan"
          >
            {{ subHeader.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex" class="motive-duration-refs-table__table__row">
          <td
            v-for="(col, colIndex) in row"
            :key="colIndex"
            :rowspan="col.rowspan"
            :colspan="col.colspan"
            :class="col.class"
            @click="col.class && handleClickOnValue($event)"
          >
            {{ col.text }}
          </td>
        </tr>
      </tbody>
    </table>
    <n-alert
      v-else
      v-test="'error-message'"
      class="mb-4"
      transparent
      type="warning"
      :message="errorMessage"
    />
  </n-expandable>
</template>

<script>

import { getFromAPI } from '@/services/api';

export default {
  name: 'MotiveDurationRefsTable',
  props: {
    motiveCode: {
      type: String,
      default: null,
    },
    motiveLabel: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      AATI_DURATION_REFS_URL: '/api/aati_duration_refs',
      headers: [{
        text: 'Type d\'emploi',
        value: 'job',
        colspan: 1,
        rowspan: 1,
      },
      {
        text: 'Durée de référence (jours)',
        value: 'duration',
        colspan: 1,
        rowspan: 1,
      }],
      subHeaders: [[], []],
      rows: [],
      initializedSubHeaders: false,
      detailedContentUrl: null,
      noDurationRefs: false,
    };
  },
  computed: {
    displayedSubHeaders () {
      return this.subHeaders.filter(subHeaderGroup => subHeaderGroup.length > 0);
    },
    errorMessage () {
      return this.noDurationRefs
        ? 'Aucune durée indicative n\'est disponible pour ce motif.'
        : 'Une erreur est survenue lors du chargement du tableau des durées indicatives : veuillez consulter la fiche PDF détaillée.';
    },
  },
  watch: {
    motiveCode: {
      immediate: true,
      async handler () {
        this.resetTable();
        this.detailedContentUrl = null;
        this.noDurationRefs = false;

        if (this.motiveCode) {
          await this.fetchAatiDurationRefs();
        }
      },
    },
  },
  mounted () {
  },
  methods: {
    async fetchAatiDurationRefs () {
      try {
        const response = await getFromAPI(`${this.AATI_DURATION_REFS_URL}/${this.motiveCode}`, {}, { errorHandle: false });
        this.detailedContentUrl = response.data.url;
        this.buildRows(response.data.criteria.Critere);
      } catch (e) {
        this.resetTable();

        if (e.response?.status === 404) {
          this.noDurationRefs = true;
        }

        throw e;
      }
    },
    buildRows (rawContent) {
      this.resetTable();
      if (Array.isArray(rawContent)) {
        rawContent.forEach(item => {
          this.buildCols(item, []);
        });
        this.smoothColspanRows();
      } else if (this.hasDureeIndicativeProperty(rawContent)) {
        this.addDureeIndicativeCols([], rawContent);
      }
    },
    buildCols (data, currentRow) {
      if (this.hasDureeIndicativeProperty(data)) {
        this.handleMissingSubHeader(data, currentRow);
        return;
      }
      const checkingCriteria = Array.isArray(data.Critere) ? data.Critere[0] : data.Critere;
      if (this.hasDureeIndicativeProperty(checkingCriteria)) {
        this.updateTypeEmploiHeaderColspan(currentRow);
        // Build "Duree" data cols
        if (Array.isArray(data.Critere)) {
          data.Critere.forEach(item => {
            currentRow.push(this.buildDureeIndicativeCell(item));
          });
        } else {
          currentRow.push(this.buildDureeIndicativeCell(data.Critere));
        }
        // Fill subHeaders object if not yet initialised
        if (! this.initializedSubHeaders) {
          this.buildSubHeaders(data);
        }
        this.rows.push(currentRow);
        return;
      }
      if (Array.isArray(data.Critere)) {
        if (! this.initializedSubHeaders) {
          this.buildSubHeaders(data);
        }
        if (Object.keys(data).includes('Text')) {
          currentRow.push(this.buildTextCell(data));
        }
        data.Critere.forEach(item => {
          if (this.hasDureeIndicativeProperty(item)) {
            currentRow.push(this.buildDureeIndicativeCell(item));
          } else {
            item.Critere.Critere.forEach(subItem => {
              currentRow.push(this.buildDureeIndicativeCell(subItem));
            });
          }
          this.updateTypeEmploiHeaderColspan(currentRow);
        });
        this.rows.push(currentRow);
        this.smoothColspanRows();
      } else {
        if (Object.keys(data).includes('Text')) {
          currentRow.push(this.buildTextCell(data));
        }
        this.buildCols(data.Critere, currentRow);
      }
    },
    handleMissingSubHeader (data, currentRow) {
      this.initializedSubHeaders = true;
      this.updateTypeEmploiHeaderColspan(currentRow);
      this.addDureeIndicativeCols(currentRow, data);
    },
    buildSubHeaders (data) {
      // Build subheaders "Duree"
      data.Critere.forEach((item, index) => {
        this.subHeaders[0].push(this.buildTextCell(item));

        // Check for sublevel of subheaders
        if (Array.isArray(item.Critere.Critere)) {
          item.Critere.Critere.forEach(subItem => {
            this.subHeaders[1].push(this.buildTextCell(subItem));
          });
          this.subHeaders[0][index].colspan = item.Critere.Critere.length;
        }
      });

      if (this.subHeaders[1].length > 0) {
        this.subHeaders[0].forEach(subHeader => {
          // Update subheader rowspan which doesnt have sublevel to align with subheader with sublevel
          if (subHeader.colspan === 1) {
            subHeader.rowspan = 2;
          }
        });
        // Update "Type d'emploi" header rowspan
        this.headers[0].rowspan = 3;
        // Update "Duree" header colspan
        this.headers[1].colspan = this.subHeaders[0].reduce((acc, val) => acc + val.colspan, 0);
      } else if (this.subHeaders[0].length > 0) {
        // Update "Type d'emploi" header rowspan
        this.headers[0].rowspan = 2;
        // Update "Duree" header colspan
        this.headers[1].colspan = this.subHeaders[0].length;
      }
      this.initializedSubHeaders = true;
    },
    addDureeIndicativeCols (row, data) {
      row.push(
        this.buildTextCell(data),
        this.buildDureeIndicativeCell(data),
      );
      this.rows.push(row);
    },
    buildDureeIndicativeCell (data) {
      return {
        rowspan: 1,
        colspan: 1,
        text: data.DureeIndicative.Duree,
        class: 'motive-duration-refs-table__table__row__duration',
      };
    },
    buildTextCell (data) {
      return {
        rowspan: 1,
        colspan: 1,
        text: data.Text,
      };
    },
    hasTextProperty (data) {
      return data && typeof data === 'object' && 'Text' in data;
    },
    hasDureeIndicativeProperty (data) {
      return data && typeof data === 'object' && 'DureeIndicative' in data;
    },
    // Get max col number from rows for "Type d'emploi" row colspan
    smoothColspanRows () {
      this.rows.forEach(row => {
        const firstHeaderColspanRow = row.length - this.headers[1].colspan;
        if (firstHeaderColspanRow < this.headers[0].colspan) {
          row[0].colspan = this.headers[0].colspan - firstHeaderColspanRow + 1;
        }
      });
    },
    updateTypeEmploiHeaderColspan (currentRow) {
      if (currentRow.length > this.headers[0].colspan) {
        this.headers[0].colspan = currentRow.length;
      }
    },
    resetTable () {
      this.rows = [];
      this.headers.forEach(header => {
        header.colspan = 1;
        header.rowspan = 1;
      });
      this.subHeaders = [[], []];
      this.initializedSubHeaders = false;
    },
    handleClickOnValue (event) {
      const clickedCaseValue = parseInt(event.target.innerText, 10);
      if (! Number.isNaN(clickedCaseValue)) {
        this.$emit('click', clickedCaseValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.motive-duration-refs-table {
  &__header {
    display: flex;
    gap: map-get($spacers, 4);

    &__label {
      font-size: 14px;
      color: var(--v-secondary-base);
    }
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
  }

  &__table {
    display: block;
    overflow-x: auto;

    td,
    th {
      padding: 10px;
      text-align: center;
    }

    &__headers,
    &__subheaders,
    td:not(.motive-duration-refs-table__table__row__duration) {
      background-color: var(--v-secondary-lighten5);
    }

    &__subheaders {
      color: var(--v-accent-base);
    }

    &__row {
      font-weight: bold;

      td:not(:first-child) {
        color: var(--v-accent-base);
      }

      td.motive-duration-refs-table__table__row__duration {
        background-color: var(--v-primary-lighten5);
        cursor: pointer;
      }
    }
  }
}
</style>