<template>
  <div>
    <v-stepper-step
      v-test="'step'"
      :step="step"
      editable
      :edit-icon="icon"
      :color="color"
      :complete="isCompleted"
      @click="handleClick"
    >
      {{ title }}
    </v-stepper-step>
    <v-stepper-content :step="step">
      <slot name="default" />
    </v-stepper-content>
  </div>
</template>

<script>

export default {
  name: 'AatiFormStep',
  props: {
    step: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      required: true,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return { visited: false };
  },
  computed: {
    icon () {
      if (! this.visited || this.currentStep === this.step) {
        return null;
      }
      return this.isFormValid ? 'fas fa-check' : 'fas fa-times';
    },
    color () {
      if (! this.visited || this.currentStep === this.step) {
        return 'primary';
      }
      return this.isFormValid ? 'success' : 'error';
    },
    isCompleted () {
      return this.step > 1 ? this.visited && this.currentStep !== this.step : this.currentStep > this.step;
    },
  },
  mounted () {
    // Initialisation de l'état visité pour la première étape
    if (this.step === 1 && this.currentStep === this.step && ! this.visited) {
      this.visited = true;
    }
  },
  methods: {
    handleClick () {
      this.visited = true;
      this.$emit('update-current-step', this.step);
    },
  },
};
</script>