/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const prescriptionLineEntity = new DocumentEntity('PrescriptionLine', 'prescriptionLines.xml', 'Contient les lignes des prescriptions');

prescriptionLineEntity.addField(new DocumentEntityField('product', 'La représentation (de type JSON) du produit prescrit'));
prescriptionLineEntity.addField(new DocumentEntityField('comment', 'Le commentaire'));
prescriptionLineEntity.addField(new DocumentEntityField('posologies', 'Les posologies'));
prescriptionLineEntity.addField(new DocumentEntityField('summaries', 'Les résumés textuels'));
prescriptionLineEntity.addField(new DocumentEntityField('startDate', 'La date de début du traitement'));
prescriptionLineEntity.addField(new DocumentEntityField('durationDays', 'La durée du traitement'));

export default prescriptionLineEntity;