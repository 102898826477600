<template>
  <validation-observer
    ref="addressObserver"
    slim
  >
    <div class="app-address">
      <div class="app-address__prepend v-text-field">
        <v-icon>fa-map-marker-alt</v-icon>
      </div>
      <div class="app-address__inputs">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <app-text-field
              v-model="localAddress.line1"
              class="app-address__line1"
              label="Adresse"
              name="address.line1"
              data-test="address-line-1-input"
              hide-errors="auto"
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <app-text-field
              v-model="localAddress.line2"
              class="app-address__line2"
              label="Adresse ligne 2"
              name="address.line2"
              data-test="address-line-2-input"
              hide-errors="auto"
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="4"
          >
            <app-text-field
              v-model="localAddress.postalCode"
              class="app-address__cp"
              label="Code postal"
              name="address.postalCode"
              data-test="address-postal-code-input"
              hide-errors="auto"
              autocomplete="off"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="4"
          >
            <app-text-field
              v-model="localAddress.city"
              class="app-address__city"
              label="Ville"
              name="address.city"
              data-test="address-city-input"
              hide-errors="auto"
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <app-autocomplete
              v-model="localAddress.country"
              item-value="value"
              item-text="label"
              :items="countries"
              clearable
              label="Pays"
              data-test="address-country-input"
              hide-errors="auto"
              autocomplete="off"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { COUNTRIES } from '@/constants';
import Address from '@/models/Address';
import { getRefAsArray } from '@/utils/functions/refs';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';



export default {
  name: 'PatientAddressFieldset',
  components: {
    AppTextField,
    ValidationObserver,
    AppAutocomplete,
  },
  props: {
    value: {
      type: Address,
      required: true,
    },
  },
  data () {
    return { localAddress: this.value };
  },
  computed: {
    countries () {
      return getRefAsArray(COUNTRIES);
    },
  },
  watch: {
    value (newAddress) {
      this.localAddress = newAddress;
    },
    localAddress: {
      deep: true,
      async handler (newAddress) {
        this.$emit('input', newAddress);
        await this.$nextTick();
        await this.$refs.addressObserver.validate();
      },
    },
  },
};
</script>

<style lang="scss">
$icon-size: 24px;
$icon-margin: 9px;
$row-margin: 12px;

  .app-address {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;

    &__prepend {
      width: $icon-size;
      margin-right: $icon-margin;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__inputs {
      flex: 1;

      & > .row {
        padding-left: $icon-size + $icon-margin;

        @include media-md {
           width: calc(100% - #{$row-margin});
        }

        @include media-lg {
          padding-left: 0;
          width: auto;
        }

        &  > div[class^="col"]:first-child {
          @include media-lg {
            padding-left: $icon-size + $icon-margin + 12px;
          }
        }
      }
    }
  }
</style>