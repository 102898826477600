<template>
  <v-bottom-navigation
    class="app-mobile-bottom-navigation"
    grow
  >
    <v-btn
      v-for="(item, index) in visibleMenuItems"
      :key="index"
      :to="item.route"
      active-class="primary"
      class="app-bottom-navigation__item"
      data-test="mobile-bottom-navigation-item"
    >
      <span
        class="white--text"
        data-test="mobile-bottom-navigation-item-text"
      >{{ item.text }}</span>
      <svg
        width="20"
        height="20"
        data-test="mobile-bottom-navigation-item-icon"
        :data-name="item.icon"
        :class="`app-bottom-navigation__icon v-icon mb-1 icon-${item.icon}`"
      >
        <use :xlink:href="`#icon-${item.icon}`" />
      </svg>
    </v-btn>
    <the-mobile-bottom-navigation-more-links
      v-if="remainingMenuItems.length > 0"
      class="app-mobile-bottom-navigation__more-button"
      :items="remainingMenuItems"
      data-test="mobile-bottom-navigation-more-link"
    />
  </v-bottom-navigation>
</template>

<script>
import TheMobileBottomNavigationMoreLinks from './TheMobileBottomNavigationMoreLinks.vue';
import moduleItemsMixin from '@/layout/mixins/moduleItemsMixin';

const MAX_VISIBLE_ITEMS = 4;

/**
 * Représente le menu du bas sur mobile
 */
export default {
  name: 'TheMobileBottomNavigation',
  components: { TheMobileBottomNavigationMoreLinks },
  mixins: [moduleItemsMixin],
  computed: {
    /**
     * Retourne les éléments à afficher en fonction du maximum d'éléments précisé
     * @returns {Array}
     */
    visibleMenuItems () {
      return this.moduleItemsMixin_menuItems.slice(0, MAX_VISIBLE_ITEMS);
    },
    /**
     * Retourne le reste des éléments en fonction de la valeur maximale précisée dans les datas
     * @returns {Array}
     */
    remainingMenuItems () {
      return this.moduleItemsMixin_menuItems.slice(MAX_VISIBLE_ITEMS);
    },
  },
};
</script>
<style lang="scss" scoped>
.app-bottom-navigation {
  &__icon {
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
  }
}
</style>