import { downloadBlob } from '@/utils/functions/files';

export default class BaseFile {

  /**
   * Cache des fichiers déjà téléchargés.
   *
   * Evite de requêter la même URL plusieurs fois,
   * si un blob a déjà été généré.
   */
  downloadedFiles = {};

  fetchUrl;

  constructor (fetchUrl) {
    this.fetchUrl = fetchUrl;
  }

  async getFile () {
    if (! this.downloadedFiles[this.fetchUrl]) {
      const { name, blob } = await this.fileGetter();
      this.downloadedFiles[this.fetchUrl] = {
        blobUrl: URL.createObjectURL(blob),
        blob,
        name,
      };
    }
    return this.downloadedFiles[this.fetchUrl];
  }

  /**
   * Méthode de récupération asynchrone du fichier via l'API
   * @return Object { blob, name}
   */
  async fileGetter () {
    throw new Error('La méthode fileGetter doit être réimplémenté dans les classes filles');
  }

  /**
   * Permet de télécharger le fichier
   */
  async download ({ fileName } = {}) {
    const file = await this.getFile();
    if (! fileName) {
      downloadBlob(file.blob, { fileName: file.name });
    } else {
      downloadBlob(file.blob, { fileName });
    }
  }

  /**
   * Reset le cache.
   * Principalement utile pour les tests
   */
  clearCache () {
    this.downloadedFiles = {};
  }
}