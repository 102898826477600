<template>
  <n-dialog
    divided
    content-class="patient-logs-modal"
    :is-open="isOpen"
    title="Journal des traces"
    width="980"
    @update:is-open="isOpen => $emit('update:is-open', isOpen)"
  >
    <p class="mb-6">
      {{ logsHeading }}
    </p>
    <app-data-table
      :items="patientLogs"
      :loading="isFetchingPatientLogs"
      :headers="headers"
      expandable-rows
      pageable
    >
      <template #expanded-row="{ item }">
        <div class="pa-3 secondary lighten-5 flex-list-2 flex-column">
          <template v-if="item.getTrace()">
            <h3 class="subtitle-3">
              Trace complète
            </h3>
            <pre
              v-test="'log-detail-trace'"
              class="patient-logs-modal__trace-field"
            >{{ item.getTrace() }}</pre>
          </template>
          <p
            v-else
            v-test="'undefined-trace'"
          >
            Aucune trace à afficher
          </p>
        </div>
      </template>
    </app-data-table>
  </n-dialog>
</template>

<script>


import ApiLog from '@/models/ApiLog';
import Patient from '@/modules/patient/models/Patient';
import { getFromAPI } from '@/services/api';
import { format } from '@/utils/functions/dates';

import AppDataTable from '@/components/ui/tables/AppDataTable.vue';

export default {
  name: 'PatientLogsModal',
  components: { AppDataTable },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isFetchingPatientLogs: false,
      patientLogs: [],
      headers: [
        {
          value: 'createdAt',
          text: 'Date d\'enregistrement',
          valueGetter: log => format(log.createdAt, 'dd MMM yyyy à HH:mm'),
        },
        {
          value: 'value',
          text: 'Description',
          valueGetter: log => log.getDescription(),
        },
      ],
    };
  },
  computed: {
    logsHeading () {
      return `Vous retrouverez ici l'ensemble de l'activité liée à l'identité du patient ${this.patient.getCivilState()} depuis sa création.`;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (isOpen) {
        if (isOpen) {
          this.fetchPatientLogs();
        }
      },
    },
  },
  methods: {
    async fetchPatientLogs () {
      this.isFetchingPatientLogs = true;
      try {
        const { data } = await getFromAPI(`/api/logs_consulting/patient/${this.patient.getUuid()}`);
        this.patientLogs = data['hydra:member'].map(patientLog => new ApiLog(patientLog));
      } finally {
        this.isFetchingPatientLogs = false;
      }
    },
  },
};
</script>

<style lang="scss">
.patient-logs-modal {
  min-height: 600px;

  &__trace-field {
    white-space: pre-wrap;
  }
}
</style>