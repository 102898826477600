import Vue from 'vue';

import ContactPoint from '@/models/ContactPoint';
import Contact from '@/models/user/Contact';
import PractitionerSignature from '@/models/user/PractitionerSignature';
import User from '@/models/user/User';
import { getFromAPI, postFileToAPI, putToAPI } from '@/services/api';
import { mapStoreResetter } from '@/utils/functions/store';


export const state = () => ({ users: {} });

export const getters = {
  organisationPractitioners: state => Object.values(state.users)
    .filter(user => user.contact.practitionerProfile)
    .slice()
    .sort((a, b) => a.contact.familyName.localeCompare(b.contact.familyName)),

  getPractitionerSignature: state => (user) => {
    const userIri = user['@id'];
    return state.users?.[userIri]?.contact?.practitionerProfile?.signature;
  },
};

export const mutations = {
  ...mapStoreResetter(state),

  SET_USER (state, user) {
    Vue.set(state.users, user['@id'], new User(user));
  },
  SET_PRACTITIONER_SIGNATURE (state, { user, practitionerSignature }) {
    const userIri = user['@id'];
    Vue.set(state.users[userIri].contact.practitionerProfile, 'signature', new PractitionerSignature(practitionerSignature));
  },
  SET_CONTACT (state, { user, contact }) {
    const userIri = user['@id'];
    Vue.set(state.users[userIri], 'contact', new Contact(contact));
  },
};

export const actions = {
  async fetchUsers ({ commit }) {
    const { data } = await getFromAPI('/api/users');
    data['hydra:member'].forEach(user => commit('SET_USER', user));
  },

  async uploadPractitionerSignature ({ commit }, { user, practitionerSignatureFile }) {
    const formData = new FormData();
    formData.append('file', practitionerSignatureFile, practitionerSignatureFile.name);

    const { data } = await postFileToAPI('/api/practitioner_signatures', formData);
    commit('SET_PRACTITIONER_SIGNATURE', {
      user,
      practitionerSignature: data,
    });
  },
  async updateContact ({ commit }, { user, contact }) {
    const serializedContact = {
      ...contact,
      practitionerProfile: contact.practitionerProfile
        ? {
          ...contact.practitionerProfile,
          medicalSpecialtyRef: contact.practitionerProfile?.medicalSpecialtyRef?.['@id'] || null,
        }
        : null,
      contactPoints: ContactPoint.removeEmptyContactPoints(contact.contactPoints),
    };
    const { data } = await putToAPI(contact['@id'], { data: serializedContact });
    delete data.practitionerProfile?.mssAccountDataset?.password;

    commit('SET_CONTACT', {
      user,
      contact: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};