<template>
  <div class="d-flex align-center">
    <app-color-dot
      :size="small ? 12 : 24"
      data-test="motive-label-color"
      :color="motive.color"
      class="d-block"
    />
    <div
      class="ml-2"
      data-test="motive-label-text"
    >
      {{ motive.name }}
    </div>
  </div>
</template>

<script>
import Motive from '@/modules/agenda/models/Motive';

import AppColorDot from '@/components/ui/form/color/AppColorDot.vue';


export default {
  name: 'MotiveLabel',
  components: { AppColorDot },
  props: {
    motive: {
      type: Motive,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>