<template>
  <patient-sidebar-panel-listing
    title="Habitus, mode de vie"
    create-label="Ajouter un habitus, mode de vie"
    edit-label="Modifier un habitus, mode de vie"
    :items="patientHabitusRiskFactors"
    :form="HabitusRiskFactorForm"
    :loading="isLoading"
    :item-model-class="PatientHabitus"
    :patient="patient"
    no-data-text="Aucune donnée"
    @submit-success="fetchHabitusRiskFactors"
  >
    <template #item="{ item, edit }">
      <app-hoverable-actions-wrapper
        tag="article"
        :inset-x="4"
        :actions="getActions(item, edit)"
      >
        <div
          v-test="'risk-factor-title'"
          class="subtitle-3"
        >
          {{ item.getDetailedHabitusLabel() }}
        </div>
        <div v-test="'risk-factor-label'">
          {{ item.label }}
        </div>
      </app-hoverable-actions-wrapper>
    </template>
  </patient-sidebar-panel-listing>
</template>

<script>

import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem';
import PatientHabitus from '@/modules/patient/models/healthEntity/PatientHabitus';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue';
import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue';
import HabitusRiskFactorForm from '@/modules/patient/components/patientFile/riskFactors/habitus/HabitusRiskFactorForm.vue';

export default {
  name: 'HabitusRiskFactorsListing',
  components: {
    AppHoverableActionsWrapper,
    PatientSidebarPanelListing,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      HabitusRiskFactorForm,
      PatientHabitus,
      patientHabitusRiskFactors: [],
      isLoading: false,
    };
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id']) {
          this.fetchHabitusRiskFactors();
        }
      },
    },
  },
  methods: {
    async fetchHabitusRiskFactors () {
      this.isLoading = true;
      try {
        this.patientHabitusRiskFactors = await PatientHabitus.fetchAll(this.patient['@id']);
      } finally {
        this.isLoading = false;
      }
    },
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({
          text: 'Modifier',
          callback: () => editMethod(item),
        }),
        new DeleteActionMenuItem({
          callback: () => {
            NovaTools.dialog.confirm(
              'Supprimer l\'habitus, mode de vie',
              'Êtes-vous sûr(e) de vouloir supprimer l\'habitus, mode de vie ?',
              async () => {
                await PatientHabitus.delete(item['@id']);
                await this.fetchHabitusRiskFactors();
              },
            );
          },
        }),
      ];
    },
  },
};
</script>