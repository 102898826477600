export const FAMILY_SITUATIONS = Object.freeze({
  SINGLE: {
    value: 'single',
    label: 'Célibataire',
  },
  MARRIED: {
    value: 'married',
    label: 'Marié(e)',
  },
  PACS: {
    value: 'pacs',
    label: 'Pacsé(e)',
  },
  CONCUBINAGE: {
    value: 'concubinage',
    label: 'En concubinage',
  },
  WIDOWED: {
    value: 'widowed',
    label: 'Veuf(ve)',
  },
  DIVORCED: {
    value: 'divorced',
    label: 'Divorcé(e)',
  },

});