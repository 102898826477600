import ApiEntity from '@/models/ApiEntity';

export default class PrescriptionLine extends ApiEntity {
  constructor (options = {}) {
    super(options);

    this.refundable = typeof (options.refundable) !== 'undefined' ? options.refundable : true;
    this.preventive = options.preventive || false;
    this.workAccident = options.workAccident || false;
    this.renewalCount = options.renewalCount || 0;
    this.ald = options.ald || false;
  }

  /**
   * Retourne le titre de la ligne de prescription
   */
  getLabel () {
    throw new Error('getLabel doit être défini');
  }

  getRenewalDetails () {
    if (this.renewalCount > 0) {
      return `À renouveler ${this.renewalCount} fois`;
    }
    return '';
  }

  /**
   * Retourne une version en une ligne de la ligne de prescription
   */
  toString () {
    return this.getLabel();
  }
}