/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation';

const contactEntity = new DocumentEntity('Contact', 'contacts.xml', 'Contient les données des contacts');

contactEntity.addField(new DocumentEntityField('firstNames', 'Les prénoms'));
contactEntity.addField(new DocumentEntityField('familyName', 'Le nom de famille'));
contactEntity.addField(new DocumentEntityField('civility', 'La civilité'));
contactEntity.addField(new DocumentEntityField('address', 'L\'adresse', new DocumentEntityRelation('Address', 'id')));
contactEntity.addField(new DocumentEntityField('contactPoints', 'Les informations de contact', new DocumentEntityRelation('ContactPoint', 'id')));
contactEntity.addField(new DocumentEntityField('profession', 'La profession'));
contactEntity.addField(new DocumentEntityField('birthName', 'Le nom de naissance'));

export default contactEntity;