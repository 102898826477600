<template>
  <section
    v-test="'check-user-token'"
    class="layout-default"
    @click="checkUserToken"
    @keydown="checkUserToken"
  >
    <the-header
      v-if="!hideHeader"
      data-test="header"
    />
    <main class="layout-default__main">
      <slot />
    </main>
    <the-mobile-bottom-navigation
      v-if="isMobile && ! hideMobileBottomNavigation"
      data-test="mobile-bottom-navigation"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import TheHeader from './components/header/TheHeader.vue';
import TheMobileBottomNavigation from './components/mobileBottomNavigation/TheMobileBottomNavigation.vue';
import apiAuth from '@/services/apiAuth';

/**
 * Représente le layout par défaut de l'application
 */
export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheMobileBottomNavigation,
  },
  data () {
    return { expireTokenTime: null };
  },
  computed: {
    ...mapState('app', ['isMobile']),
    /**
     * Définie si le header de l'application doit être affiché
     * @returns {Boolean}
     */
    hideHeader () {
      return this.getIsMetaHidden('hideHeader');
    },
    /**
     * Définie si la navigation du bas sur mobile doit être affiché
     * @returns {Boolean}
     */
    hideMobileBottomNavigation () {
      return this.getIsMetaHidden('hideMobileBottomNavigation');
    },
    /**
     * Détermine si une route est nestée
     */
    isRouteNested () {
      return this.$route.fullPath.split('/').length > 2;
    },
  },
  methods: {
    /**
     * Retourne la valeur d'une meta
     * @param {String} meta Meta à rechercher
     * @returns {Any} Valeur de la meta
     */
    getMeta (meta) {
      const route = this.$route.matched.find(route => route.meta[meta]);

      return route !== undefined ? route.meta[meta] : false;
    },
    /**
     * Retourne l'état visible d'une meta en fonction de son nom, de l'état du mobile ainsi que de la route
     * @param {String} metaValue Le nom de la meta à traiter
     * @returns {Boolean}
     */
    getIsMetaHidden  (metaValue) {
      const meta = this.getMeta(metaValue);
      let isHidden = !! meta;
      if (meta.childrenOnly) {
        isHidden = this.isRouteNested ? isHidden : false;
      }
      if (meta.mobileOnly) {
        isHidden = this.isMobile ? isHidden : false;
      }
      return isHidden;
    },
    async checkUserToken () {
      try {
        if (! this.expireTokenTime) {
          this.expireTokenTime = apiAuth.getTokenExpire();
        }
        if (new Date().getTime() > this.expireTokenTime) {
          await apiAuth.renewToken();
          this.expireTokenTime = apiAuth.getTokenExpire();
        }
      } catch (e) {
        this.expireTokenTime = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-default {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__main {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
  }
}
</style>