import ConsultationDocument from './ConsultationDocument';
import Observation from './Observation';
import Prescription from './Prescription';
import ActivityPlacePractitionerLink from '@/models/ActivityPlacePractitionerLink';
import ApiEntity from '@/models/ApiEntity';
import { BILLING_STATUS_NAMES } from '@/modules/accounting/constants';
import Payment from '@/modules/accounting/models/Payment';
import SephiraInvoice from '@/modules/accounting/models/SephiraInvoice';
import ConsultationCertificate from '@/modules/patient/components/consultation/certificates/models/ConsultationCertificate';
import ConsultationTelemedicineAct from '@/modules/patient/components/consultation/telemedicineActs/models/ConsultationTelemedicineAct';
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument';
import ConsultationFollowUpLetterDocument from '@/modules/patient/models/ConsultationFollowUpLetterDocument';
import ConsultationVaccine from '@/modules/patient/models/ConsultationVaccine';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, putToAPI } from '@/services/api';

export default class Consultation extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.patient = options.patient
      ? new Patient(options.patient)
      : null;

    this.observation = new Observation(options.observation || {});
    this.activityPlacePractitionerLink = options.activityPlacePractitionerLink
      ? new ActivityPlacePractitionerLink(options.activityPlacePractitionerLink)
      : null;

    this.patientMeasures = options.patientMeasures || [];

    this.prescriptions = options.prescriptions
      ? options.prescriptions.map(p => new Prescription(p))
      : [];

    this.documents = options.documents
      ? options.documents.map(d => new ConsultationDocument(d))
      : [];

    this.followUpLetterDocument = options.followUpLetterDocument
      ? new ConsultationFollowUpLetterDocument(options.followUpLetterDocument)
      : null;

    this.dischargeLetterDocument = options.dischargeLetterDocument
      ? new ConsultationDischargeLetterDocument(options.dischargeLetterDocument)
      : null;

    this.certificateDocuments = options.certificateDocuments
      ? options.certificateDocuments.map(certif => new ConsultationCertificate(certif))
      : [];

    this.telemedicineActDocument = options.telemedicineActDocument
      ? new ConsultationTelemedicineAct(options.telemedicineActDocument)
      : null;

    this.vaccines = options.vaccines
      ? options.vaccines.map(vaccine => new ConsultationVaccine(vaccine))
      : [];

    this.invoice = options.invoice
      ? new SephiraInvoice(options.invoice)
      : null;

    this.fees = options.fees || null;

    this.billingState = options.billingState || BILLING_STATUS_NAMES.NONE;
    this.partnerBillable = options.partnerBillable || false;
    this.appointment = options.appointment || null;
    this.date = options.date || null;
  }

  get practitioner () {
    if (this.activityPlacePractitionerLink?.practitioner?.['@id']) {
      return this.activityPlacePractitionerLink.practitioner;
    }
    return null;
  }

  /**
   * Permet de récupérer les consultations de la journée en cours d'un patient depuis l'API
   * @param {String} patientUuid
   * @returns {Consultation[]}
   */
  static async fetchTodayPatientConsultations (patientUuid) {
    const currentDate = NovaTools.dates.format(new Date(), 'yyyy-MM-dd');
    const { data } = await getFromAPI(`/api/patients/${patientUuid}/consultations?date[after]=${currentDate}&date[before]=${currentDate}`);
    return data['hydra:member'].map(consultation => new Consultation(consultation));
  }

  /**
   * Permet de récupérer une consultation
   * @param {String} consultationUuid
   * @returns {Consultation}
   */
  static async fetch (consultationUuid) {
    const { data } = await getFromAPI(`/api/consultations/${consultationUuid}`);
    return new Consultation(data);
  }

  /**
   * Permet de récupérer les paiements d'une consultation
   * @param {String} consultationUuid
   * @returns {Payment[]}
   */
  static async fetchPayments (consultationUuid) {
    const { data } = await getFromAPI(`/api/consultations/${consultationUuid}/payments`);
    return data['hydra:member']
      .map(paymentData => new Payment(paymentData));
  }

  async updateActivityPlacePractitionerLink (activityPlacePractitionerLink) {
    const { data } = await putToAPI(this.getIri(), { data: { activityPlacePractitionerLink: activityPlacePractitionerLink['@id'] } });
    return data;
  }

  async updateAppointment (appointment) {
    const { data } = await putToAPI(this.getIri(), { data: { appointment: appointment?.['@id'] || null } });
    return data;
  }

  async updateObservation (observation) {
    const { data } = await putToAPI(this.getIri(), { data: { observation } });
    return data;
  }

  async updatePatientMeasures (patientMeasures) {
    const { data } = await putToAPI(this.getIri(), { data: { patientMeasures } });
    return data.patientMeasures;
  }

  async updateFees (fees) {
    const { data } = await putToAPI(this.getIri(), { data: { fees } });
    return data;
  }

  async updateVaccines (vaccines) {
    const { data } = await putToAPI(this.getIri(), { data: { vaccines } });
    return data.vaccines;
  }

}