import { initialiseTraces } from '@/nova-tools/traces/traces';

import Vue from 'vue';


/**
 * Global components
 */
import AppTabsItem from '@/components/ui/tabs/AppTabsItem.vue';

// Ajoute les composants N dans le projet easy-care
import App from './App.vue';
import './assets/styles/main.scss';
import './plugins';
import './registerServiceWorker';
import router from './router/instance';
import './services/vendors/doctolib';
import store from './store/instance';
import './utils/directives';
import novalysOptions from '../config/novalys';
import * as Components from '@/nova-ui';
import apiAuth from '@/services/apiAuth';
import Novalys from '@novalys';

import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppCol from '@/components/ui/grid/AppCol.vue';
import AppRow from '@/components/ui/grid/AppRow.vue';
import AppIcon from '@/components/ui/icon/AppIcon.vue';
import AppList from '@/components/ui/list/AppList.vue';
import AppListItem from '@/components/ui/list/AppListItem.vue';
import AppTabs from '@/components/ui/tabs/AppTabs.vue';
import AppLink from '@/components/ui/typography/link/AppLink.vue';
import Default from '@/layout/default/Default.vue';

Vue.use(Novalys, novalysOptions);

Vue.use(apiAuth);

Vue.config.productionTip = false;

Vue.component('DefaultLayout', Default);
Vue.component('AppButton', AppButton);
Vue.component('AppLink', AppLink);
Vue.component('AppIcon', AppIcon);
Vue.component('AppRow', AppRow);
Vue.component('AppCol', AppCol);
Vue.component('AppTabs', AppTabs);
Vue.component('AppTabsItem', AppTabsItem);
Vue.component('AppList', AppList);
Vue.component('AppListItem', AppListItem);

Object.values(Components).forEach(Component => Vue.component(Component.name, Component));

window.store = store;

initialiseTraces();

const app = new Vue({
  router,
  store,
  ...Novalys.mapDependenciesInstances(),
  render: h => h(App),
});

app.$mount('#app');

const loadSVGSprite = async () => {
  try {
    const response = await fetch('/img/sprite.svg');
    const responseText = await response.text();
    window.trustedTypes && window.trustedTypes.createPolicy
      ? document.querySelector('.sprite-svg-container').innerHTML = app.$trustedPolicy.createHTML(responseText)
      : document.querySelector('.sprite-svg-container').innerHTML = responseText;
  } catch (error) {
    throw new Error('Failed to load SVG sprite:', error);
  }
};

loadSVGSprite();