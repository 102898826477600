<template>
  <div class="accounting-listing-filters">
    <h3 class="accounting-listing-filters__title mb-1">
      Sélectionnez une date
    </h3>
    <app-select
      v-model="selectedFilterId"
      v-test="'type-select'"
      item-value="id"
      item-text="label"
      :items="availableFilters"
      @input="applyFilter"
    />
    <app-row
      v-if="selectedFilterId === 4"
      dense
    >
      <app-col>
        <app-date-picker
          v-model="startDate"
          :icon="null"
          :max="maxFromDate"
          label="À partir du"
          clearable
          data-test="from-date"
          @input="applyFilter"
        />
      </app-col>
      <app-col>
        <app-date-picker
          v-model="endDate"
          :icon="null"
          :min="new Date(startDate)"
          :max="new Date()"
          label="Jusqu'au"
          clearable
          data-test="to-date"
          @input="applyFilter"
        />
      </app-col>
    </app-row>
  </div>
</template>

<script>

import FilterComponentMixin from '@/mixins/filterComponent';
import { DateFilter } from '@/utils/classes/httpRequestParameters/filters';
import { format, startOfMonth, startOfWeek } from '@/utils/functions/dates';

import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';

export default {
  name: 'AccountingListFilters',
  components: {
    AppSelect,
    AppDatePicker,
  },
  mixins: [FilterComponentMixin([
    new DateFilter({
      name: 'from',
      label: 'À partir du',
      queryParamName: 'date[after]',
    }),
    new DateFilter({
      name: 'to',
      label: 'Jusqu\'au',
      chainWith: 'from',
      queryParamName: 'date[before]',
    }),
  ])],
  data () {
    return {
      selectedFilterId: 1,
      availableFilters: [
        {
          id: 1,
          label: 'Aujourd\'hui',
          startDate: new Date(),
          endDate: new Date(),
        },
        {
          id: 2,
          label: 'Semaine courante',
          startDate: startOfWeek(new Date()),
          endDate: new Date(),
        },
        {
          id: 3,
          label: 'Mois courant',
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
        },
        {
          id: 4,
          label: 'Période',
        },
      ],
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    };
  },
  computed: {
    maxFromDate () {
      return this.endDate
        ? new Date(this.endDate)
        : new Date();
    },
  },
  async mounted () {
    this.applyFilter();
  },
  methods: {
    applyFilter () {
      if (this.selectedFilterId === 4) { // custom
        this.updateDates(this.startDate, this.endDate);
      } else {
        const selectedFilter = this.availableFilters.find(filter => filter.id === this.selectedFilterId);
        this.updateDates(format(selectedFilter.startDate, 'yyyy-MM-dd'), format(selectedFilter.endDate, 'yyyy-MM-dd'));
      }
    },
    updateDates (startDate, endDate) {
      if (this.fromFilter.componentValue !== startDate) {
        this.fromFilter.componentValue = startDate;
      }
      if (this.toFilter.componentValue !== endDate) {
        this.toFilter.componentValue = endDate;
      }
    },
  },
};
</script>