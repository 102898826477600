export default {
  props: {
    /**
     * Applique un label sur l'avatar
     */
    avatarLabel: {
      type: String,
      default: null,
    },
    /**
     * Affiche uniquement l'avatar
     */
    avatarOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Masque l'avatar
     */
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le composant avec une grande taille
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le composant avec une petite taille
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le composant avec une très petite taille
     */
    xSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Aligne le contenu de la personne sur le haut plutôt qu'au centre
     */
    alignContentTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche les initiales de la personne plutôt qu'un avatar
     */
    showInitials: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    personDetailsMixin_props () {
      return {
        avatarLabel: this.avatarLabel,
        avatarOnly: this.avatarOnly,
        hideAvatar: this.hideAvatar,
        large: this.large,
        small: this.small,
        xSmall: this.xSmall,
        alignContentTop: this.alignContentTop,
        showInitials: this.showInitials,
      };
    },
  },
};