import { COLORS } from '@/constants';
import { leftPad } from '@/utils/functions/number';

/**
 * Convertit une valeur héxadécimale en rgba
 * @param {String} hex Couleur en héxadécimal
 * @param {String} [alpha=1] Opacité à appliquer
 * @returns {String}
 */
export const hexToRgba = (hex, alpha = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return null;
};

/**
 * Convertit une couleur au format hexadécimal
 * @param { String } color
 * @returns { String } La couleur au format hexadécimal
 */
export const colorToHex = (color) => {
  if (color.startsWith('#')) {
    const hex = color.replace('#', '');
    if (hex.length !== 3 && hex.length !== 6) {
      throw new Error('Le code couleur hexadécimal est invalide');
    }
    if (hex.length === 3) {
      return `#${hex}${hex}`;
    }
    return color;
  }

  if (color.startsWith('rgb')) {
    const colorChannels = color.match(/\d+/g);
    return `#${colorChannels.reduce((acc, colorChannel) => acc + leftPad(Number(colorChannel).toString(16), 2), '')}`;
  }

  const [colorName, colorVariant] = color.split(' ');
  if (Object.keys(COLORS).includes(colorName)) {
    const variant = colorVariant ? colorVariant.replace('-', '') : 'base';
    return COLORS[colorName][variant];
  }

  throw new Error('La couleur est invalide');
};

/**
 * Retourne la couleur de contraste d'une couleur
 * @param { String } color
 * @returns { String }
 */
export const getColorContrast = (color) => {
  // Seuil de passage à une couleur de contraste
  const threshold = 175;
  const [r, g, b] = hexToRgba(colorToHex(color)).match(/\d+/g);
  const colorBrightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return COLORS[colorBrightness > threshold ? 'content' : 'white'].base;
};
