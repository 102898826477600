<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      v-test="'step'"
      class="medical-leave-medical-infos-fields"
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Informations médicales"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <div v-if="localValue.nature">
        <indication-fieldset
          v-test="'indications-group'"
          :dataset="indicationFieldsetData"
          :label="indicationFieldsetLabel"
          :items="indicationItems"
          @input="subData => localValue = { ...localValue, ...subData }"
        />
        <!-- Champ dates complémentaires -->
        <div
          v-if="localValue[atmpDateFieldProps.vIf]"
          v-test="atmpDateFieldProps.vTest"
          class="medical-leave-medical-infos-fields__indication-date"
        >
          <app-icon icon="schedule" />
          <span>Accident de travail / maladie professionnelle déclaré(e) le :</span>
          <app-date-picker
            v-model="localValue[atmpDateFieldProps.vModel]"
            class="medical-leave-medical-infos-fields__indication-date__date-field"
            :max="new Date()"
            rules="required|before_now"
          />
        </div>
        <div
          v-if="localValue.childDeath"
          v-test="'death-date-field'"
          class="medical-leave-medical-infos-fields__indication-date"
        >
          <app-icon icon="schedule" />
          <span>Décès survenu le :</span>
          <app-date-picker
            v-model="localValue.childDeathDate"
            class="medical-leave-medical-infos-fields__indication-date__date-field"
            :max="new Date() "
            rules="required|before_now"
          />
        </div>
      </div>
      <div class="mb-2">
        <h3 class="medical-leave-medical-infos-fields__motive-title mt-4">
          Motif
        </h3>
        <app-autocomplete
          v-model="localMotive"
          v-test="'motive-search-field'"
          :show-label="false"
          :placeholder="motivePlaceholder"
          :items="aatiMotives"
          :item-text="getMotiveText"
          :text-transform="sanitizeSearchString"
          return-object
          clearable
          hide-errors="auto"
          :min-search-length="0"
          :disabled="disabledMotiveField"
          :rules="{ 'required': isMotiveRequired }"
          @input="$event => localMotive = $event"
        >
          <template #selection="{ item }">
            <div class="medical-leave-medical-infos-fields__motive-selection">
              <app-tag small color="secondary">
                {{ item.code }}
              </app-tag>
              <span>{{ item.label }} ({{ item.codification }})</span>
            </div>
          </template>
          <template #item="{ item }">
            <div class="medical-leave-medical-infos-fields__motive-item">
              <app-tag
                v-if="item.parent"
                small
                color="secondary"
                class="ml-4 mr-2"
              >
                {{ item.code }}
              </app-tag>
              <span>{{ item.label }}</span>
              <span v-if="item.parent"> ({{ item.codification }})</span>
            </div>
          </template>
        </app-autocomplete>
        <!-- Complément d'infos si le motif le requiert -->
        <div v-test="'motive-complement-field'" class="medical-leave-medical-infos-fields__motive-infos">
          <span class="medical-leave-medical-infos-fields__motive-infos__label">{{ motiveInformationLabel }}</span>
          <app-text-field
            v-model="localValue.motiveInformation"
            label="Informations complémentaires"
            :show-label="false"
            :rules="{ 'max': 100, 'required': !! localMotive && localMotive.toSpecify }"
          />
        </div>
      </div>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import { deburr } from 'lodash';
import { ValidationObserver } from 'vee-validate';

import { MEDICAL_LEAVE_INDICATION_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveIndicationData';
import { MEDICAL_LEAVE_NATURE_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveNatureData';
import { getFromAPI } from '@/services/api';

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue';
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue';
import IndicationFieldset from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/elements/IndicationFieldset.vue';



export default {
  name: 'MedicalLeaveMedicalInfosFields',
  components: {
    AatiFormStep,
    AppAutocomplete,
    AppDatePicker,
    AppTextField,
    AppTag,
    IndicationFieldset,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    beneficiaryNir: {
      type: String,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 3,
      MEDICAL_LEAVE_INDICATION_DATA,
      MEDICAL_LEAVE_NATURE_DATA,
      AATI_MOTIVE_REFS_URL: '/api/aati_motive_refs',
      aatiMotives: [],
      localValue: null,
      localMotive: null,
      disabledMotiveField: false,
    };
  },
  computed: {
    pregnancyEnabled () {
      return ['2', '8'].includes(this.beneficiaryNir?.[0]);
    },
    natureLabel () {
      return MEDICAL_LEAVE_NATURE_DATA.find(nature => nature.value === this.localValue.nature)?.label || null;
    },
    isTP () {
      return this.localValue.nature === 'TP';
    },
    isTCP () {
      return this.localValue.nature === 'TCP';
    },
    fullTimeFieldsetData () {
      return (({ ald, atmp, pregnancy, childDeath }) => ({
        ald,
        atmp,
        pregnancy,
        childDeath,
      }))(this.localValue);
    },
    partialTimeFieldsetData () {
      return (({ aldPartial, atmpPartial }) => ({
        aldPartial,
        atmpPartial,
      }))(this.localValue);
    },
    indicationFieldsetData () {
      return this.isTP ? this.partialTimeFieldsetData : this.fullTimeFieldsetData;
    },
    indicationItems () {
      const items = [];
      const availabilityProp = (this.isTP) ? 'partialTime' : 'fullTime';
      MEDICAL_LEAVE_INDICATION_DATA.forEach(ind => {
        const disabledTooltipMessage = this.getDisabledTooltipMessage(ind, availabilityProp);
        items.push({
          label: ind.label,
          name: ind[availabilityProp].name,
          text: ind.regulatoryText,
          disabled: !! disabledTooltipMessage,
          disabledTooltip: disabledTooltipMessage,
        });
      });
      return items;
    },
    indication () {
      return Object.keys(this.indicationFieldsetData).find(key => this.indicationFieldsetData[key] === true) ?? null;
    },
    indicationFieldsetLabel () {
      return `Arrêt à ${this.natureLabel} en rapport avec :`;
    },
    atmpDateFieldProps () {
      return this.isTP ? {
        vModel: 'atmpPartialDate',
        vTest: 'atmp-partial-date-field',
        vIf: 'atmpPartial',
      } : {
        vModel: 'atmpDate',
        vTest: 'atmp-date-field',
        vIf: 'atmp',
      };
    },
    isMotiveRequired () {
      return ! this.localValue.pregnancy;
    },
    motivePlaceholder () {
      return `Rechercher parmi les motifs de référence${this.isMotiveRequired ? '*' : ''}`;
    },
    motiveInformationLabel () {
      return `Préciser${!! this.localMotive && this.localMotive.toSpecify ? '*' : ''} :`;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler () {
        this.localValue = this.value;
      },
    },
    localValue: {
      deep: true,
      handler () {
        this.$emit('update:value', this.localValue);
      },
    },
    'localValue.pregnancy': {
      immediate: true,
      handler () {
        this.handleMotiveFieldState();
      },
    },
    'localValue.childDeath': function handleChildDeathChange(newVal) {
      if (!newVal) {
        this.localValue.childDeathDate = null;
      }
    },
    'localValue.ald': function handleAldChange(newVal) {
      if (this.isTCP) {
        this.localValue.aldPartial = newVal;
      }
    },
    'localValue.atmp': function handleAtmpChange(newVal) {
      if (!newVal) {
        this.localValue.atmpDate = null;
      }
      if (this.isTCP) {
        this.localValue.atmpPartial = newVal;
      }
    },
    'localValue.atmpDate': function handleAtmpDateChange(newVal) {
      if (this.isTCP) {
        this.localValue.atmpPartialDate = newVal;
      }
    },
    'localValue.atmpPartial': function handleAtmpPartialChange(newVal) {
      if (!newVal) {
        this.localValue.atmpPartialDate = null;
      }
    },
    'localValue.nature': function handleNatureChange(newVal, oldVal) {
      this.handleIndicationReset(newVal, oldVal);
    },
    localMotive: {
      deep: true,
      handler () {
        this.localValue.motiveCode = this.localMotive?.code ?? null;
        this.localValue.motiveLabel = this.localMotive?.label ?? null;
      },
    },
  },
  async mounted () {
    await this.fetchAatiMotiveRefs();
    this.localMotive = this.aatiMotives.find(motive => motive.code === this.value.motiveCode);
  },
  methods: {
    async fetchAatiMotiveRefs () {
      const response = await getFromAPI(this.AATI_MOTIVE_REFS_URL);
      response.data['hydra:member'].filter(motive => ! motive.parent)
        .sort((a, b) => a.label.localeCompare(b.label))
        .forEach(motive => {
          this.aatiMotives.push({
            ...motive,
            disabled: motive.children.length > 0, // Non sélectionnable si possède des motifs enfants
          });
          const children = motive.children.map(child => ({
              ...child,
              parent: motive, // On ajoute l'objet entier pour pouvoir ensuite rechercher sur le libellé du parent
            }));
          this.aatiMotives = this.aatiMotives.concat(children.sort((a, b) => a.code.localeCompare(b.code))); // Tri par code croissant
        });
    },
    handleIndicationReset (newNature, oldNature) {
      if ((newNature === 'TP' && (oldNature === 'TC' || oldNature === 'TCP'))
        || (newNature === 'TCP' && oldNature === 'TC')) {
        this.copyValuesBetweenDatasets(false);
      }
      if ((newNature === 'TC' && (oldNature === 'TP' || oldNature === 'TCP'))
        || (newNature === 'TCP' && oldNature === 'TP')) {
        this.copyValuesBetweenDatasets();
      }
      this.resetValuesFromDataset(newNature);
    },
    getMotiveText (motive) {
      return deburr(`${motive.label} ${motive.codification} ${motive.code} ${motive.parent?.label || ''}`);
    },
    handleMotiveFieldState () {
      this.disabledMotiveField = false;
      if (this.localValue.pregnancy) {
        this.localMotive = null;
        this.disabledMotiveField = true;
      }
    },
    sanitizeSearchString (value) {
      return value ? deburr(value) : value;
    },
    getDisabledTooltipMessage (indication, availabilityProp) {
      if (indication[availabilityProp].name === 'pregnancy' && ! this.pregnancyEnabled) {
        return 'Option disponible uniquement pour les bénéficiaires de sexe féminin';
      }
      if (indication[availabilityProp].natureAvailability.indexOf(this.localValue.nature) === - 1) {
        return `Option indisponible pour un arrêt à ${this.natureLabel}`;
      }
      return null;
    },
    copyValuesBetweenDatasets (fromPartialToFull = true) {
      if (fromPartialToFull) {
        this.localValue.ald = this.localValue.aldPartial;
        this.localValue.atmp = this.localValue.atmpPartial;
        this.localValue.atmpDate = this.localValue.atmpPartialDate;
      } else {
        this.localValue.aldPartial = this.localValue.ald;
        this.localValue.atmpPartial = this.localValue.atmp;
        this.localValue.atmpPartialDate = this.localValue.atmpDate;
      }
    },
    resetValuesFromDataset (newNature) {
      switch (newNature) {
        case 'TC':
          Object.keys(this.partialTimeFieldsetData).forEach(key => {
            this.localValue[key] = false;
          });
          break;
        case 'TP':
          Object.keys(this.fullTimeFieldsetData).forEach(key => {
            this.localValue[key] = false;
          });
          break;
        case 'TCP':
          this.localValue.pregnancy = false;
          this.localValue.childDeath = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.medical-leave-medical-infos-fields {
  &__indication-date,
  &__motive-selection,
  &__motive-infos {
    display: flex;
    gap: map-get($spacers, 2);
    align-items: baseline;
  }

  &__indication-date {
    padding: 10px;
    font-size: 12px;
    background-color: var(--v-secondary-lighten5);
    margin: 10px 0;
    max-width: max-content;

    ::v-deep {
      .app-date-picker__field {
        padding-top: 0;
      }
    }

    &__date-field {
      max-width: 100px;
    }
  }

  &__motive-infos {
    &__label {
      font-size: 12px;
    }
  }

  &__motive-title {
    font-size: 14px;
    color: var(--v-secondary-base);
  }

  ::v-deep {
    span:has(> .v-radio) {
      max-width: fit-content;
    }

    .app-autocomplete {
      display: unset !important;
    }
    .app-autocomplete .v-autocomplete__content {
      position: static !important;
      margin-top: 0 !important;
      margin-left: 1px;
    }
  }
}
</style>