<template>
  <div class="free-appointment-slot-confirm">
    <patient-details
      small
      :patient="patient"
    />
    <div class="free-appointment-slot-confirm__block mt-5">
      <div>Rendez-vous le <b>{{ startDate }}</b></div>
      <div>
        Pour le motif <b>{{ motiveText }}</b>
      </div>
    </div>
    <div class="free-appointment-slot-confirm__block mt-5">
      <app-badge icon="practitioner" color="secondary lighten-4" class="d-flex mb-2">
        {{ practitionerContactText }}
      </app-badge>
      <app-badge icon="address" color="secondary lighten-4" class="d-flex">
        {{ activityPlaceText }}
      </app-badge>
    </div>
    <app-button
      data-test="submit-button"
      color="primary"
      class="mt-5"
      :loading="isSubmiting"
      @click="submitAppointment"
    >
      Je confirme
    </app-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Appointment from '@/modules/agenda/models/events/Appointment';
import NovaTools from '@/nova-tools/NovaTools';

import AppBadge from '@/components/ui/badge/AppBadge.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';




export default {
  name: 'FreeAppointmentSlotConfirm',
  components: {
    PatientDetails,
    AppBadge,
  },
  props: {
    value: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return { isSubmiting: false };
  },
  computed: {
    ...mapGetters('agenda', ['getScheduleFromIri', 'getAppointmentMotiveFromIri']),
    patient () {
      return this.value.patient;
    },
    motive () {
      return this.value.motive ? this.getAppointmentMotiveFromIri(this.value.motive) : null;
    },
    motiveText () {
      return this.motive?.name || 'Inconnu';
    },
    schedule () {
      return this.value.schedule ? this.getScheduleFromIri(this.value.schedule) : null;
    },
    activityPlace () {
      return this.schedule?.getActivityPlace() || null;
    },
    activityPlaceText () {
      return this.activityPlace?.name || 'Lieu non reconnu';
    },
    practitionerContact () {
      return this.schedule?.getPractitioner() || null;
    },
    practitionerContactText () {
      return this.practitionerContact?.getCivilState() || 'Praticien non reconnu';
    },
    startDate () {
      return NovaTools.dates.format(new Date(this.value.startDateTime), 'PPPP à kk\'h\'mm');
    },
  },
  methods: {
    ...mapActions('agenda', ['insertAppointment']),
    async submitAppointment () {
      this.isSubmiting = true;
      try {
        await this.insertAppointment(this.value);
        NovaTools.notify.success('Le rendez-vous a été ajouté avec succès');
        this.$emit('appointment-booked');
      } catch (error) { /* empty */ }

      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.free-appointment-slot-confirm {
  font-size: 13px;
}
</style>