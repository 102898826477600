<template>
  <div>
    <app-row>
      <span class="civil-state-fieldset__subtitle col-12">Informations générales</span>
    </app-row>
    <app-row>
      <app-col
        cols="12"
        md="4"
      >
        <app-gender-picker
          v-test="'gender-input'"
          :disabled="insTraitsDisabled"
          name="gender"
          rules="required"
          :value="gender"
          @input="$emit('update:gender', $event)"
        />
      </app-col>
      <app-col
        cols="12"
        md="4"
      >
        <app-civility-picker
          v-test="'civ-input'"
          :civilities="PATIENT_CIVILITIES"
          label="Civilité"
          item-text="shortLabel"
          item-value="value"
          name="civility"
          :value="civility"
          @input="$emit('update:civility', $event)"
        />
      </app-col>
    </app-row>
    <app-row>
      <app-col
        cols="12"
        md="4"
      >
        <app-text-field
          ref="birthNameInput"
          v-test="'birth-name-input'"
          name="birthName"
          label="Nom de naissance"
          data-sentry-mask
          rules="required"
          hide-details
          autocomplete="off"
          :text-transform="sanitizeNameString"
          :disabled="insTraitsDisabled"
          :value="birthName"
          @input="$emit('update:birth-name', $event)"
        />
      </app-col>
      <app-col
        cols="12"
        md="4"
      >
        <app-text-field
          v-test="'family-name-input'"
          name="familyName"
          label="Nom utilisé"
          data-sentry-mask
          autocomplete="off"
          clearable
          :text-transform="sanitizeNameString"
          :value="familyName"
          @input="$emit('update:family-name', $event)"
        />
      </app-col>
    </app-row>
    <app-row>
      <app-col
        cols="12"
        md="4"
      >
        <app-select
          v-test="'first-name-input'"
          name="firstName"
          rules="required"
          :items="firstNamesAsArray"
          label="1er prénom de naissance"
          :text-transform="sanitizeNameString"
          :disabled="! firstNames"
          autocomplete="off"
          :value="firstName"
          disabled-required-auto-select
          @input="$emit('update:first-name', $event)"
          @mouseenter.native="isFirstNamesTooltipActive = ! Boolean(firstNames)"
          @mouseleave.native="isFirstNamesTooltipActive = false"
        />
      </app-col>
      <app-col
        cols="12"
        md="4"
      >
        <n-tooltip
          v-model="isFirstNamesTooltipActive"
          text="Veuillez saisir au moins un prénom"
          :disabled="Boolean(firstNames)"
        >
          <app-text-field
            v-test="'first-names-input'"
            :value="firstNames"
            name="firstNames"
            label="Prénom(s)"
            :text-transform="sanitizeNameString"
            :disabled="insTraitsDisabled"
            @input="$emit('update:first-names', $event)"
            @change="handleFirstnameAutomatism"
          />
        </n-tooltip>
      </app-col>
      <app-col
        cols="12"
        md="4"
      >
        <app-text-field
          v-test="'used-first-name-input'"
          :value="usedFirstName"
          name="usedFirstName"
          label="Prénom utilisé"
          autocomplete="off"
          :text-transform="sanitizeNameString"
          @input="$emit('update:used-first-name', $event)"
        />
      </app-col>
    </app-row>
    <app-row>
      <app-col
        cols="12"
        md="4"
      >
        <patient-birth-date-field
          v-test="'birth-date-input'"
          data-sentry-mask
          rules="required"
          :disabled="insTraitsDisabled"
          :value="birthDate"
          @input="$emit('update:birthDate', $event)"
        />
      </app-col>
      <app-col
        cols="12"
        md="4"
      >
        <birth-place-field
          :disabled="insTraitsDisabled"
          rules="required"
          :birth-place-code="birthPlaceCode"
          :birth-place-label="birthPlaceLabel"
          @update:birthPlaceCode="$emit('update:birthPlaceCode', $event)"
          @update:birthPlaceLabel="$emit('update:birthPlaceLabel', $event)"
        />
      </app-col>
    </app-row>
    <app-row v-if="! isPartnerAccess">
      <app-col cols="12" md="4">
        <app-text-field
          ref="nirInput"
          v-test="'nir-input'"
          :value="nir"
          label="Numéro de sécurité sociale"
          data-sentry-mask
          name="nir"
          :hide-errors="hideValidationErrors"
          @change="handleNirChange($event)"
        >
          <template
            v-if="isNirInvalid"
            #append
          >
            <app-icon
              icon="error"
              color="warning"
              :tooltip="nirInvalidMessage"
            />
          </template>
        </app-text-field>
        <app-checkbox
          v-show="isNirInvalid"
          v-test="'nir-allow-unchecked-checkbox'"
          class="mt-1"
          :value="nirAllowUnchecked"
          name="nirAllowUnchecked"
          label="Ce numéro de sécurité sociale est correct"
          :ripple="false"
          @change="handleNirAllowUncheckedChange($event)"
        />
      </app-col>
    </app-row>
  </div>
</template>

<script>
import { deburr } from 'lodash';
import { validate } from 'vee-validate';

import { GENDERS, CIVILITIES } from '@/constants';
import { PATIENT_CIVILITIES } from '@/modules/patient/constants';

import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';
import AppCivilityPicker from '@/components/ui/form/AppCivilityPicker.vue';
import AppGenderPicker from '@/components/ui/form/AppGenderPicker.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppIcon from '@/components/ui/icon/AppIcon.vue';
import BirthPlaceField from '@/modules/patient/components/patientForm/BirthPlaceField.vue';
import PatientBirthDateField from '@/modules/patient/components/patientForm/PatientBirthDateField.vue';



export default {
  name: 'IdentityCivilStateFieldset',
  components: {
    AppSelect,
    AppIcon,
    AppCivilityPicker,
    AppTextField,
    AppGenderPicker,
    AppCheckbox,
    PatientBirthDateField,
    BirthPlaceField,
  },
  props: {
    gender: {
      type: String,
      default: null,
    },
    civility: {
      type: String,
      default: null,
    },
    birthName: {
      type: String,
      default: null,
    },
    familyName: {
      type: String,
      default: null,
    },
    firstNames: {
      type: String,
      default: null,
    },
    firstName: {
      type: String,
      default: null,
    },
    usedFirstName: {
      type: String,
      default: null,
    },
    birthDate: {
      type: String,
      default: null,
    },
    birthPlaceCode: {
      type: String,
      default: null,
    },
    birthPlaceLabel: {
      type: String,
      default: null,
    },
    nir: {
      type: String,
      default: null,
    },
    insTraitsDisabled: {
      type: Boolean,
      default: false,
    },
    autoSelectCivility: {
      type: Boolean,
      default: false,
    },
    autoSelectFirstName: {
      type: Boolean,
      default: false,
    },
    nirAllowUnchecked: {
      type: Boolean,
      default: false,
    },
    isPartnerAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      PATIENT_CIVILITIES,
      isFirstNamesTooltipActive: false,
      nirInvalidMessage: 'Le numéro de sécurité sociale semble incorrect',
      hasValidationErrors: false,
    };
  },
  computed: {
    firstNamesAsArray () {
      if (! this.firstNames) {
        return [];
      }
      return this.getAllPossibleFirstNames(this.firstNames);
    },
    nirValidationRules () {
      if (this.nir?.length === 15) {
        return {
          valid_nir: {
            gender: this.gender,
            invalidMessage: this.nirInvalidMessage,
          },
        };
      }
      return 'length:15';
    },
    isNirInvalid () {
      return this.hasValidationErrors && typeof this.nirValidationRules !== 'string';
    },
    hideValidationErrors () {
      return this.isNirInvalid && this.nirAllowUnchecked;
    },
  },
  watch: {
    // Permet de ne pas définir la civilité lors de la première hydratation du formulaire
    // Évitant ainsi de définir systématiquement la civilité en fonction du genre dans le cas d'une édition
    // Par exemple si un patient possède le genre "Homme" et la civilité "Madame", celle-ci ne sera pas écrasée
    // et remplacée par "Monsieur" lors de l'ouverture du formulaire en mode édition
    gender (gender) {
      if (this.autoSelectCivility) {
        this.setCivility(gender);
      }
    },
    nir () {
      this.validateNir();
    },
  },
  methods: {
    async handleFirstnameAutomatism () {
      if (this.autoSelectFirstName) {
        this.$emit('update:first-name', this.firstNamesAsArray[0]);
      }
    },
    setCivility (gender) {
      if (gender === GENDERS.MALE.value) {
        this.$emit('update:civility', CIVILITIES.MR.value);
        this.focusName();
      }
      if (gender === GENDERS.FEMALE.value) {
        this.$emit('update:civility', CIVILITIES.MRS.value);
        this.focusName();
      }
    },
    /**
     * Auto focus sur le champ nom de naissance
     */
    focusName () {
      if (! this.birthName) {
        this.$refs.birthNameInput.focus();
      }
    },
    /**
     * Retire le caractère diacritique et ce qui n'est pas une lettre, un tiret, une apostrophe, un tiret ou un espace
     * Met la première lettre en majuscule
     * @param {String} value texte à formatter
     */
    sanitizeNameString (value) {
      return deburr(value)
        .replace(/[^a-zA-Z' -]/g, '')
        .replace('  ', ' ')
        .toUpperCase();
    },
    /**
     * Génère tous les 1ers prénoms possibles
     * Avec les prénoms JEAN LUC et PIERRE sont générés :
     * 'JEAN', 'JEAN LUC', 'JEAN-LUC', 'JEAN LUC PIERRE', 'JEAN LUC-PIERRE', 'JEAN-LUC PIERRE', 'JEAN-LUC-PIERRE'
     */
    getAllPossibleFirstNames (firstnames) {
      const firstnamesAsArray = firstnames.trim().split(' ');
      const allFirstnames = [firstnamesAsArray.at(0)];

      let baseFirstname = firstnamesAsArray.slice(0, 1);
      firstnamesAsArray.slice(1).forEach((remainingFirstname) => {
        const newFirstnames = [];
        baseFirstname.forEach((base) => {
          const baseWithSpace = `${base} ${remainingFirstname}`;
          const baseWithHyphen = `${base}-${remainingFirstname}`;
          allFirstnames.push(baseWithSpace, baseWithHyphen);
          newFirstnames.push(baseWithSpace, baseWithHyphen);
        });
        baseFirstname = newFirstnames;
      });

      return allFirstnames;
    },
    handleNirAllowUncheckedChange (event) {
      this.$emit('update:nirAllowUnchecked', !! event);
      this.$nextTick(() => this.validateNir());
    },
    handleNirChange (event) {
      this.$emit('update:nirAllowUnchecked', false);
      this.$emit('update:nir', event);
    },
    async validateNir () {
      const validationResult = await validate(this.nir, this.nirValidationRules, { name: 'numéro de sécurité sociale' });
      this.$refs.nirInput.$refs.validator.applyResult(validationResult);
      this.hasValidationErrors = validationResult.errors.length > 0;

      if (this.nirAllowUnchecked) {
        this.$refs.nirInput.$refs.validator.reset();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.civil-state-fieldset {
  &__subtitle {
    font-weight: 600;
    font-size: 13px;
    color: var(--v-secondary-lighten4);
  }
}
</style>