<template>
  <div class="agenda-sidebar">
    <agenda-mini-calendar
      v-if="!isMobile"
      data-test="sidebar-calendar"
    />

    <agenda-sidebar-item
      v-if="isMobile"
      title="Type de vue"
      data-test="sidebar-view-select"
    >
      <mobile-views-select />
    </agenda-sidebar-item>

    <agenda-sidebar-item
      title="Mes agendas"
      data-test="sidebar-shedules-list"
    >
      <agenda-schedules-list
        :checked-schedules="getVisibleSchedules"
        @scheduleChanged="toggleVisibility"
      />
    </agenda-sidebar-item>

    <agenda-sidebar-item
      v-if="isMobile"
      title="Libellé des rendez-vous"
      data-test="sidebar-appointments-display-selector"
    >
      <appointments-display-selector
        data-test="appointments-display-selector"
        :disabled="getView === CALENDAR_VIEWS.LIST.value"
      />
    </agenda-sidebar-item>
  </div>
</template>

<script>

import { mapGetters, mapMutations, mapState } from 'vuex';

import AgendaSidebarItem from './AgendaSidebarItem.vue';
import { CALENDAR_VIEWS } from '../../constants';

import AgendaMiniCalendar from '@/modules/agenda/components/AgendaMiniCalendar.vue';
import MobileViewsSelect from '@/modules/agenda/components/AgendaMobileNavigation/MobileViewsSelect.vue';
import AgendaSchedulesList from '@/modules/agenda/components/AgendaSchedulesList.vue';
import AppointmentsDisplaySelector from '@/modules/agenda/components/AppointmentsDisplaySelector.vue';

export default {
  name: 'AgendaSidebar',
  components: {
    AgendaSidebarItem,
    AgendaSchedulesList,
    AgendaMiniCalendar,
    MobileViewsSelect,
    AppointmentsDisplaySelector,
  },
  data () {
    return { CALENDAR_VIEWS };
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapGetters('agenda', ['getView', 'getVisibleSchedules']),
  },
  methods: {
    ...mapMutations('agenda', ['SET_SCHEDULE_VISIBILITY']),
    toggleVisibility ({ schedule, isChecked }) {
      this.SET_SCHEDULE_VISIBILITY({
        schedule,
        visible: isChecked,
      });
    },
  },
};
</script>

<style scoped>
.agenda-sidebar {
  overflow: hidden;
}
</style>