export const GENDERS = Object.freeze({
  MALE: {
    value: 'male',
    label: 'Homme',
    icon: 'fa-mars',
  },
  FEMALE: {
    value: 'female',
    label: 'Femme',
    icon: 'fa-venus',
  },
  UNKNOWN: {
    value: 'unknown',
    label: 'Indéterminé',
    icon: 'fa-question',
  },
});