<template>
  <n-button
    v-test="'create-patient-button'"
    v-tooltip="{ text: displayedLabel, disabled: !hideLabel }"
    :bg-color="bgColor"
    :label="!hideLabel ? displayedLabel : null"
    icon="userPlus"
    :keep-color-style-when-no-label="keepColorStyleWhenNoLabel"
    @click="goToCreatePatientForm"
  />
</template>

<script>

import { ROUTE_NAMES as PATIENT_ROUTE_NAMES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';

/**
 * Bouton de création de patient
 */
export default {
  name: 'CreatePatientButton',
  props: {
    bgColor: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    avoidRedirection: {
      type: Boolean,
      default: false,
    },
    preFilledPatient: {
      type: Patient,
      default: null,
    },
    keepColorStyleWhenNoLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayedLabel () {
      if (this.preFilledPatient) {
        return 'Créer le patient';
      }
      return 'Ajouter un patient';
    },
  },
  methods: {
    goToCreatePatientForm () {
      if (this.avoidRedirection) {
        this.$emit('click');
      } else {
        this.$router.push({
          name: PATIENT_ROUTE_NAMES.PATIENT_CREATE,
          params: { prefilled_patient: this.preFilledPatient },
        });
      }
    },
  },
};
</script>