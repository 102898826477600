<template>
  <section class="n-expandable">
    <!--
      @slot L'entête du composant
      @binding {Function} toggle méthode permettant de plier/déplier la zone de contenu
      @binding {Object} expandIconStyle Le style de l'icône en fonction de l'état plié/déplié de la zone de contenu
      @binding {Boolean} isExpanded L'état plié/déplié de la zone de contenu
    -->
    <slot
      name="header"
      v-bind="{ toggle, expandIconStyle, isExpanded }"
    >
      <header
        v-test="'expand-header'"
        class="n-expandable__header"
        @click="toggle"
      >
        <v-icon
          v-test="'expand-icon'"
          class="n-expandable__header__expand-icon"
          :style="expandIconStyle"
        >
          {{ expandIcon }}
        </v-icon>
        <!--
          @slot Le texte affiché dans l'entête du composant
         -->
        <slot name="title">
          <p
            v-test="'expand-title'"
            class="n-expandable__header__text"
            :style="titleStyle"
          >
            {{ title }}
          </p>
        </slot>
      </header>
    </slot>
    <!--
      @slot La zone de contenu complète de la zone de contenu, utile pour des implémentations très spécifiques du composant
      @binding {Boolean} isExpanded L'état plié/déplié de la zone de contenu
    -->
    <slot
      name="content-outer"
      v-bind="{ isExpanded }"
    >
      <v-expand-transition>
        <!-- Div intermédiaire permettant d'éviter un flash de l'animation du v-expand-transition,
              ne tenant pas compte de la hauteur rajoutée par les marges -->
        <div
          v-show="localExpanded"
          v-test="'expand-content'"
        >
          <main :class="contentClasslist">
            <!-- @slot La zone de contenu -->
            <slot />
          </main>
        </div>
      </v-expand-transition>
    </slot>
  </section>
</template>

<script>
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

const ICON_START_ROTATION_ANGLE = 0;
const ICON_END_ROTATION_ANGLE = 90;

/**
 * Ce composant a pour but de faciliter l'intégration de composant pouvant être plié/déplié.
 */
export default {
  name: 'NExpandable',
  mixins: [
    localCopyMixin({
      propertyName: 'expanded',
      copyPropertyName: 'localExpanded',
    }),
  ],
  props: {
    /**
     * Titre du composant affiché par défaut dans son entête cliquable
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Icône permettant d'indiquer l'état plié/déplié de la zone de contenu
     */
    expandIcon: {
      type: String,
      default: 'fas fa-caret-right',
    },
    /**
     * L'état plié/déplié de la zone de contenu
     */
    expanded: {
      type: Boolean,
      default: false,
    },
    /**
     * Espacement gauche de la zone de contenu
     */
    contentSpacingLeft: {
      type: [String, Number],
      default: 4,
    },
    /**
     * Espacement supérieur de la zone de contenu
     */
    contentSpacingTop: {
      type: [String, Number],
      default: 2,
    },
    /**
     * Angle de rotation de l\'icône permettant d'indiquer l'état plié/déplié de la zone de contenu
     */
    iconRotationAngle: {
      type: [Number, Array],
      default: () => [ICON_START_ROTATION_ANGLE, ICON_END_ROTATION_ANGLE],
      validator: (iconRotationAngle) => {
        if (Array.isArray(iconRotationAngle)) {
          return iconRotationAngle.length <= 2;
        }
        return true;
      },
    },
    /**
     * Personnaliser le titre
     */
    titleStyle: {
      type: Object,
      default: null,
    },
  },
  computed: {
    contentClasslist () {
      return {
        [`pl-${this.contentSpacingLeft}`]: !! this.contentSpacingLeft,
        [`pt-${this.contentSpacingTop}`]: !! this.contentSpacingTop,
      };
    },
    normalizedIconRotationAngle () {
      if (Array.isArray(this.iconRotationAngle)) {
        return [this.iconRotationAngle[0], Number.isFinite(this.iconRotationAngle[1]) ? this.iconRotationAngle[1] : ICON_END_ROTATION_ANGLE];
      }
      return [ICON_START_ROTATION_ANGLE, this.iconRotationAngle];
    },
    expandIconStyle () {
      const [angleStart, angleEnd] = this.normalizedIconRotationAngle;
      return {
        transform: `rotate(${this.localExpanded ? angleEnd : angleStart}deg)`,
        transition: 'transform .3s ease',
      };
    },
    isExpanded () {
      return this.localExpanded;
    },
  },
  methods: {
    toggle () {
      this.localExpanded = ! this.localExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.n-expandable {
  &__header {
    display: flex;
    align-items: center;
    gap: map-get($spacers, 2);
    cursor: pointer;
    user-select: none;

    &__expand-icon {
      color: var(--v-secondary-lighten4);
    }

    &__text {
      font-weight: 500;
      font-size: 13px;
    }
  }
}
</style>