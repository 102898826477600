<template>
  <section
    v-test="'availability-range'"
    class="availability-range"
    :style="{ top: posY + 'px' }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <v-slide-y-reverse-transition>
      <div
        v-show="canShowOptions"
        v-test="'availability-range-options'"
        class="availability-range__options"
        @mouseenter="event => $emit('mouseenter:options', event)"
        @mouseleave="event => $emit('mouseleave:options', event)"
      >
        <app-button
          v-test="'delete-button'"
          icon="delete"
          label="Supprimer la plage de disponibilité"
          x-small
          @click.native.stop="deletingAvailabilitySettings = range.availability.setting"
        />
        <app-button
          v-test="'edit-button'"
          icon="edit"
          label="Modifier la plage de disponibilité"
          x-small
          @click.native.stop="openAvailabilitySettingModal(range.availability.setting)"
        />
      </div>
    </v-slide-y-reverse-transition>
    <section class="availability-range__motives">
      <div
        v-for="(color, index) in motiveColors"
        :key="index"
        class="availability-range__motives__motive"
        :style="{ backgroundColor: color }"
      />
    </section>
    <div
      v-test="'availability-range'"
      class="availability-range__range"
      :style="{ height: height + 'px' }"
    />
    <availability-delete-confirm-modal
      v-model="deletingAvailabilitySettings"
      v-test="'delete-confirm-modal'"
    />
  </section>
</template>

<script>
import { differenceInMinutes } from 'date-fns';

import AvailabilityDeleteConfirmModal from '@/components/settings/views/agenda/availabilities/AvailabilityDeleteConfirmModal.vue';


export default {
  name: 'CalendarAvailabilityRange',
  components: { AvailabilityDeleteConfirmModal },
  props: {
    range: {
      type: Object,
      required: true,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      deletingAvailabilitySettings: null,
      editingAvailabilitySettings: null,
      canShowOptions: false,
    };
  },
  computed: {
    posY () {
      return this.$parent.timeToY({
        hour: this.range.start.getHours(),
        minute: this.range.start.getMinutes(),
      });
    },
    height () {
      return this.$parent.minutesToPixels(
        differenceInMinutes(this.range.end, this.range.start) - 1,
      );
    },
    motiveColors () {
      return this.range.availability.setting.appointmentMotives.map(motive => motive.color);
    },
  },
  methods: {
    /**
     * Pour cet évènement, on s'assure qu'il n'est déclanché que si
     * on sort réèlement de sa zone (ignore le survol d'éléments plus hauts
     * qui pourraient déclancher l'évènement alors qu'on y est toujours)
     */
    onMouseleave (event) {
      const rect = event.target.getBoundingClientRect();

      const isInsideX = event.clientX > rect.left && event.clientX < rect.right;
      const isInsideY = event.clientY > rect.top && event.clientY < rect.bottom;
      const isInside = isInsideX && isInsideY;

      if (! isInside) {
        this.$emit('mouseleave', event);
      }
    },
    openAvailabilitySettingModal (setting) {
      this.$router.push({
        name: 'agenda.edit.availability',
        params: { eventUuid: setting.getUuid() },
      });
    },
    handleMouseEnter () {
      this.canShowOptions = true;
      this.$emit('mouseenter');
    },
    handleMouseLeave () {
      this.canShowOptions = false;
      this.$emit('mouseleave');
    },
  },
};
</script>

<style lang="scss" scoped>
  .availability-range {
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;

    &__options {
      position: absolute;
      z-index: 999;
      top: -35px;
      height: 35px;
      right: -10px;
      display: block;
      padding: 10px;
      padding-bottom: 0;

      ::v-deep {
        .v-btn {
          margin-left: 4px;
          background-color: var(--v-content-base) !important;
          color: white !important;
        }
      }
    }

    &__motives {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;

      &__motive {
        height: 100%;
        width: 4px;
        z-index: 1;
      }
    }

    &__range {
      background-color: white;
      position: relative;
      width: 100%;
    }
  }
</style>