<template>
  <validation-provider
    v-slot="{ errors }"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-autocomplete
      ref="input"
      v-model="localValue"
      v-test="'app-autocomplete'"
      class="app-autocomplete"
      :label="fieldLabel"
      :hint="hintLabel"
      :disabled="disabled"
      :loading="loading"
      :return-object="returnObject"
      :item-value="itemValue"
      :item-text="itemText"
      :autofocus="false"
      :no-filter="noFilter"
      :placeholder="placeholder"
      :search-input.sync="localSearchInput"
      :error="errors.length > 0"
      :error-messages="errors"
      :hide-details="hideErrors"
      :rules="[]"
      :clearable="clearable"
      :chips="chips"
      :deletable-chips="deletableChips"
      :small-chips="smallChips"
      :prepend-icon="fieldIcon.prepend"
      :prepend-inner-icon="fieldIcon.prependInner"
      :items="items"
      :multiple="multiple"
      :value-comparator="valueComparator"
      :attach="attach"
      :hide-no-data="loading || hideNoData || ((localSearchInput || '').length < minSearchLength)"
      :menu-props="{closeOnContentClick}"
      @keydown.enter.prevent
      @change="selectedItem => $emit('change', selectedItem)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    >
      <template
        v-if="$scopedSlots['item']"
        #item="{ item }"
      >
        <slot
          name="item"
          :item="item"
          :max-width="getItemWidth"
        >
          {{ item[itemText] }}
        </slot>
      </template>
      <template
        v-if="$scopedSlots['no-data']"
        #no-data
      >
        <slot name="no-data" />
      </template>
      <template
        v-if="$scopedSlots['selection']"
        #selection="data"
      >
        <slot
          name="selection"
          v-bind="data"
        />
      </template>
      <template #append-item>
        <slot name="append-item" />
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
import { deburr } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import transformableMixin from '@/components/ui/form/mixins/TransformableMixin';
import fieldMixin from '@/mixins/fields';
import focusableFieldMixin from '@/mixins/focusableFieldMixin';

const LIST_ITEM_PADDING = 32;
/**
 * Champ autocomplete de l'application
 */
export default {
  name: 'AppAutocomplete',
  components: { ValidationProvider },
  mixins: [
    fieldMixin,
    focusableFieldMixin('input'),
    transformableMixin('localSearchInput'),
  ],
  props: {
    /**
     * Valeur sélectionnée
     * @model
     */
    value: {
      type: [Object, String, Array, Number],
      default: null,
    },
    searchInput: {
      type: String,
      default: '',
    },
    itemText: {
      type: [String, Function, Array],
      default: 'value',
    },
    items: {
      type: Array,
      default: null,
    },
    filter: {
      type: Function,
      default: undefined,
    },
    /**
     * Permet de rendre le menu de l'autocomplete au niveau du composant ou alors à la racine de l'application
     */
    attach: {
      type: Boolean,
      default: true,
    },
    /**
     * Permet d'indiquer le nombre minimum de caractères avant d'afficher un résultat
     */
    minSearchLength: {
      type: Number,
      default: 3,
    },
    /**
     * Permet de fermer la liste lorsque l'on clique dans le contenu
     */
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    hideNoData: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: [String, Function, Array],
      default: 'value',
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data () {
    return { localSearchInput: '' };
  },
  computed: {
    getItemWidth () {
      return (this.$refs.input.$refs['input-slot'].clientWidth - LIST_ITEM_PADDING);
    },
    hintLabel () {
      return this.minSearchLength !== 0 && this.localSearchInput?.length < this.minSearchLength
        ? `Veuillez saisir ${this.minSearchLength} caractères afin de lancer la recherche` : null;
    },
  },
  watch: {
    localSearchInput (localSearchInput) {
      this.$emit('update:search-input', localSearchInput);
    },
    /**
     * On retire le focus d'un champ qui devient désactivé
     */
    disabled (disabled) {
      if (disabled) {
        const inputEl = this.$refs.input;
        inputEl.blur();
      }
    },
  },
  methods: {
    valueComparator (a, b) {
      if (typeof a === 'string' && typeof b === 'string') {
        return deburr(a).toLowerCase() === deburr(b).toLowerCase();
      }

      return a === b;
    },
    clear () {
      this.$nextTick(() => {
        this.localValue = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-autocomplete {
  ::v-deep {
    &:not(.v-text-field--single-line) {
      input {
        max-height: inherit;
        padding: map-get($spacers, 2) 0;
        line-height: 16px;
      }
    }
    .v-chip {
      background-color: var(--v-secondary-base);
      color: #fff;

      &__close {
        margin-left: map-get($spacers, 2);
        color: #fff;
      }
    }
  }
}
</style>