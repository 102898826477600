import axios from 'axios';

import JwtTokenService from './jwtTokenService';
/* global Synapse */
import { getSynapseBundleMock } from './vendors/synapseBundle';
import { HEALTH_PRODUCT_TYPES } from '@/modules/patient/components/consultation/prescription/constants/healthProductTypes';

window.global ||= window;

/**
 * Permet de toujours fournir une instance du bundle de Synapse valide.
 * Pour les contextes App / Test et Styleguidist
 */
try {
  global.Synapse = Synapse;
} catch (error) {
  global.Synapse = getSynapseBundleMock();
}

global.Synapse.setLang('fr-FR');

/**
 * Contient l'ensemble des widgets instanciés
 */
const synapseWidgets = [];

/**
 * Token gardé en local pour les requêtes vers Synapse
 */
let synapseToken = null;

const GET_TOKEN_URL = `${import.meta.env.VUE_APP_ROOT_API}/api/lap/synapse/get-token`;
const synapseAxios = axios.create({ baseURL: 'https://api.synapse-medicine.com/' });
const tokenService = new JwtTokenService(synapseAxios, GET_TOKEN_URL, {
  getToken: () => synapseToken,
  getRefreshToken: () => null,
  onTokenRefreshed ({ token }) {
    synapseToken = token;
    synapseWidgets.forEach(widget => widget.setToken(synapseToken));
  },
  isTokenExpiredError: ({ status, data }) => (status === 403 && data.message.includes('service')),
  refreshToken: (axiosInstance, getTokenEndpoint) => axios.get(getTokenEndpoint),
});

/**
 * Permet de récupérer le service de token actif
 * @returns {JwtTokenService} Le service
 */
export const getTokenService = () => tokenService;

/**
 * Permet de récupérer la liste des widgets actifs
 * @returns {[SynapseWidget]} L'ensemble des widgets
 */
export const getWidgets = () => synapseWidgets;

/**
 * Factory qui permet de créer une instance de widget Synapse
 * Liste des widgets https://v3-widget.synapse-medicine.com/fr_FR#setup
 * @returns { SynapseWidget }
 */
export const buildWidget = widgetName => {
  const widget = new global.Synapse[widgetName]();
  widget.setToken(tokenService.getToken());

  // Évènement custom déclenché par la mixin des composants Synapse
  widget.onDestroy = () => {
    synapseWidgets.splice(synapseWidgets.indexOf(widget), 1);
  };
  synapseWidgets.push(widget);
  return widget;
};

/**
 * Permet de récupérer une liste de médicaments depuis Synapse
 * @param {String} params.query - Une partie du nom du médicament à rechercher
 * @param {String} [params.type='branded-drug'] - le type de médicament à chercher @see https://v3-widget.synapse-medicine.com/#entity-object
 * @param {String} [params.size=20] - Le nombre de résultats à renvoyer
 * @returns {Array} Une liste de médicament qui correspondent plus ou moins à la recherche
 */
export const getDrugs = async ({ query }) => {
  const [brandedDrugs, clinicalDrugs] = await Promise.all([
    synapseAxios.post('prescription-autocomplete/v1/autocomplete', {
      text: query,
      type: HEALTH_PRODUCT_TYPES.BRANDED_DRUG,
    }),
    synapseAxios.post('prescription-autocomplete/v1/autocomplete', {
      text: query,
      type: HEALTH_PRODUCT_TYPES.CLINICAL_DRUG,
    }),
  ]);

  return [
    ...brandedDrugs.data,
    ...clinicalDrugs.data,
  ];
};

/**
 * Permet de récupérer une liste d'allergies médicamenteuses depuis Synapse
 * @param { String } params.query - Une partie du nom de l'allergie médicamenteuse à rechercher
 * @returns {Promise<axios.AxiosResponse<any>>} Une liste d'allergies médicamenteuses
 */
export const getDrugAllergies = ({ query }) => synapseAxios.post('prescription-autocomplete/v1/allergy-autocomplete', { query });

/**
 * Permet de récupérer un objet entity d'une allergie médicamenteuse
 * @param { String } label - label de l'allergie médicamenteuse à rechercher
 * @returns { Object } objet entity d'une allergie médicamenteuse
 */
export async function getDrugAllergiesEntityFromLabel (label) {
  async function sendSynapseAllergyAutocompleteRequest () {
    const response = await synapseAxios.post('prescription-autocomplete/v1/allergy-autocomplete', { query: label });
    const drugAllergyObject = response.data
      .find(item => item.entity.label.toLowerCase() === label.toLowerCase());
    return drugAllergyObject ? drugAllergyObject.entity : {};
  }
  if (! tokenService.getToken()) {
    if (! tokenService.isRefreshing) {
      tokenService.refreshTokenAsync();
    }
    return new Promise((res) => {
      tokenService.onRefresh(() => res());
    }).then(async () => sendSynapseAllergyAutocompleteRequest());
  }
  
  return sendSynapseAllergyAutocompleteRequest();
}

export { tokenService };