<template>
  <v-autocomplete
    ref="input"
    v-model="localSelected"
    class="n-autocomplete"
    :label="label"
    :search-input.sync="localSearch"
    :items="fetchedItems"
    :item-value="itemValue"
    :item-text="itemText"
    :return-object="returnObject"
    :disabled="disabled"
    :loading="isFetchingItems"
    :hide-no-data="!hasMinLength || isFetchingItems"
    :prepend-icon="prependIcon"
    :hint="hint"
    :menu-props="{ 'max-width': 'min-content' }"
    @keydown.enter.prevent
    @input="onSelectItem"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template
      v-if="$scopedSlots['item']"
      #item="{ item }"
    >
      <slot
        name="item"
        :item="item"
      >
        {{ item[itemText] }}
      </slot>
    </template>
    <template #no-data>
      <v-list-item>
        <v-list-item-title>Aucun résultat</v-list-item-title>
      </v-list-item>
    </template>
    <template
      v-if="$scopedSlots['selection']"
      #selection="data"
    >
      <slot
        name="selection"
        v-bind="data"
      />
    </template>
    <template
      v-if="fetchedItems && fetchedItems.length"
      #append-item
    >
      <v-lazy
        :key="currentPage"
        v-model="hasBeenIntercepted"
        :style="{ marginBottom: '1px' }"
      />
    </template>
  </v-autocomplete>
</template>

<script>

import { debounce } from 'lodash';

import { ICONS } from '@/constants';
import { getFromAPI } from '@/services/api';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

const LIST_ITEM_PADDING = 32;
/**
 * Composant de champ autocomplete permettant d'effectuer une recherche sur l'api de l'application
 */
export default {
  name: 'NAutocomplete',
  mixins: [
    localCopyMixin({
      propertyName: 'search',
      copyPropertyName: 'localSearch',
    }),
    localCopyMixin({
      propertyName: 'selected',
      copyPropertyName: 'localSelected',
    }),
  ],
  props: {
    url: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemText: {
      type: [String, Function, Array],
      default: null,
    },
    itemValue: {
      type: [String, Function],
      default: 'value',
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: null,
    },
    minSearchLength: {
      type: Number,
      default: 3,
    },
    label: {
      type: String,
      default: 'Rechercher',
    },
    name: {
      type: String,
      default: null,
    },
    selected: {
      type: [Object, String, Number, Array],
      default: null,
    },
    /**
     * Permet de désactiver le champ
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Vide la recherche lors du blur du champ
     * Si le contenu de la recherche doit être utilisé par la suite
     * il faut passer cette props à false
     */
    clearOnBlur: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      prependIcon: ICONS.search,
      fetchedItems: [],
      currentPage: 0,
      totalItemsCount: 0,
      isFocused: false,
      isFetchingItems: false,
      hasBeenIntercepted: false,
      axiosCanceller: null,
    };
  },
  computed: {
    hint () {
      return this.minSearchLength !== 0 && this.localSearch?.length < this.minSearchLength
        ? `Veuillez saisir ${this.minSearchLength} caractères afin de lancer la recherche` : null;
    },
    getItemWidth () {
      return (this.$refs.input.$refs['input-slot'].clientWidth - LIST_ITEM_PADDING);
    },
    hasMinLength () {
      return (this.localSearch && this.localSearch.length >= this.minSearchLength);
    },
    canFetchResults () {
      return this.hasMinLength || !! this.localSelected;
    },
  },
  watch: {
    url: {
      immediate: true,
      handler () {
        this.initAndFetchResults();
      },
    },
    localSearch (localSearch) {
      this.currentPage = 0;
      if (localSearch) {
        this.initAndFetchResults();
        return;
      }
      this.fetchedItems = [];
    },
    hasBeenIntercepted: {
      immediate: true,
      async handler (hasBeenIntercepted) {
        if (hasBeenIntercepted && this.isFocused) {
          await this.$nextTick();
          await this.nextPageLoad();
        }
      },
    },
  },
  methods: {
    async initAndFetchResults () {
      this.currentPage = 0;
      this.isFetchingItems = this.canFetchResults;
      await this.fetchNextPageResultsDebounced();
    },
    fetchNextPageResultsDebounced: debounce(async function fetchNextPageResultsDebounced() {
      this.fetchedItems = await this.fetchNextPageResults();
    }, 400),
    async nextPageLoad () {
      // Vérifie l'existence de la réf liée à l'absence de résultats de recherche
      // Afin d'éviter le lancement de requête inutile
      const hasResults = ! this.$refs?.['no-data'];
      if (! hasResults || ! this.canFetchResults) {
        return;
      }
      if (this.fetchedItems.length < this.totalItemsCount && ! this.isFetchingItems) {
        const results = await this.fetchNextPageResults();
        if (results) {
          this.fetchedItems.push(...results);
        }
      }
    },
    async fetchNextPageResults () {
      let results = [];
      if (this.canFetchResults) {
        try {
          if (this.axiosCanceller) {
            this.cancelFetchingResults();
          }
          this.axiosCanceller = new AbortController();
          this.isFetchingItems = true;
          const response = await getFromAPI(this.url, {
            pagination: true,
            itemsPerPage: this.itemsPerPage,
            page: this.currentPage + 1,
            global: this.localSearch,
          }, { signal: this.axiosCanceller.signal });
          if (response) {
            this.hasBeenIntercepted = false;
            this.currentPage += 1;
            this.totalItemsCount = response.data['hydra:totalItems'];
            results = response.data['hydra:member'];
            this.isFetchingItems = false;
          }
        } catch {
          if (this.axiosCanceller) {
            this.cancelFetchingResults();
          }
        }
      } else if (this.axiosCanceller) {
        this.cancelFetchingResults();
      }
      return results;
    },
    onSelectItem (selection) {
      this.$emit('input', selection);
      if (! selection) {
        this.fetchedItems = [];
      }
      if (this.clearOnSelect) {
        this.clear();
      }
    },
    onFocus () {
      this.isFocused = true;
      this.$emit('focus');
    },
    onBlur () {
      this.isFocused = false;
      if (! this.localSelected) {
        if (this.axiosCanceller) {
          this.cancelFetchingResults();
          this.isFetchingItems = false;
        }

        if (this.clearOnBlur) {
          this.clear();
        }
      }
      this.$emit('blur');
    },
    clear () {
      this.localSearch = null;
      this.$nextTick(() => {
        this.fetchedItems = [];
        this.localSelected = null;
      });
    },
    cancelFetchingResults () {
      this.axiosCanceller.abort();
      this.axiosCanceller = null;
      this.isFetchingItems = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.n-autocomplete {
  ::v-deep {
    &:not(.v-text-field--single-line) {
      input {
        max-height: inherit;
        padding: map-get($spacers, 2) 0;
        line-height: 16px;
      }
    }
  }
}
</style>