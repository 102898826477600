<template>
  <section>
    <div class="sidebar-item pa-4">
      <h5
        class="sidebar-item__title mb-3"
        data-test="title"
      >
        {{ title }}
      </h5>
      <div class="sidebar-item__content">
        <slot />
      </div>
    </div>
    <app-divider class="mx-n4" />
  </section>
</template>

<script>
import AppDivider from '@/components/ui/divider/AppDivider.vue';

export default {
  name: 'AgendaSidebarItem',
  components: { AppDivider },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-item {
  &__title {
    font-size: 13px;
    font-weight: 600;
  }
}
</style>