import ApiEntity from '@/models/ApiEntity';
import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';
import Patient from '@/modules/patient/models/Patient';

export default class HealthSummaryReminder extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.patient = options.patient ? new Patient(options.patient) : null;
    this.patientPathologies = options.pathologies ? options.pathologies.map(pathology => new PatientPathology(pathology)) : [];
    this.patientAld = options.ald;
    this.reminderDate = options.reminderDate || null;
    this.display = options.display || null;
  }
}