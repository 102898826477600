import Vue from 'vue';

import { state as consultationsState, getters as consultationsGetters, mutations as consultationsMutations, actions as consultationsActions } from './consultations';
import { state as documentDmpSyncsState, getters as documentDmpSyncsGetters, mutations as documentDmpSyncsMutations, actions as documentDmpSyncsActions } from './documentDmpSyncs';
import { state as femaleMedicalDatasetState, getters as femaleMedicalDatasetGetters, mutations as femaleMedicalDatasetMutations, actions as femaleMedicalDatasetActions } from './femaleMedicalDataset';
import { state as patientHealthSummaryRemindersState, getters as patientHealthSummaryRemindersGetters, mutations as patientHealthSummaryRemindersMutations, actions as patientHealthSummaryRemindersActions } from './healthSummaryReminders';
import { state as measuresState, getters as measuresGetters, mutations as measuresMutations, actions as measuresActions } from './measures';
import { state as medicalDatasetState, getters as medicalDatasetGetters, mutations as medicalDatasetMutations, actions as medicalDatasetActions } from './medicalDataset';
import { state as notesState, getters as notesGetters, mutations as notesMutations, actions as notesActions } from './notes';
import { state as riskFactorsState, getters as riskFactorsGetters, mutations as riskFactorsMutations, actions as riskFactorsActions } from './riskFactors';
import Patient from '@/modules/patient/models/Patient';
import { state as ongoingMedicationsState, getters as ongoingMedicationsGetters, mutations as ongoingMedicationsMutations, actions as ongoingMedicationsActions } from '@/modules/patient/store/ongoingMedications';
import { getFromAPI, patchToAPI } from '@/services/api';
import { mapStoreResetter } from '@/utils/functions/store';

export const state = () => ({
  settings: {},
  settingsPath: null,
  patients: {},
  isFetchingPatients: false,
  filteredPatients: {},
  patientCount: 0,
  filteredPatientCount: 0,
  partnerTransactionId: null,
  ...measuresState(),
  ...riskFactorsState(),
  ...medicalDatasetState(),
  ...femaleMedicalDatasetState(),
  ...consultationsState(),
  ...notesState(),
  ...documentDmpSyncsState(),
  ...ongoingMedicationsState(),
  ...patientHealthSummaryRemindersState(),
});

export const getters = {
  getSettings: state => state.settings,
  getPatients: state => Object.values(state.patients).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  isFetchingPatients: state => state.isFetchingPatients,
  getFilteredPatients: state => Object.values(state.filteredPatients),
  getPatientByUUID: state => uuid => state.patients[`/api/patients/${uuid}`],
  getPatientByIRI: state => iri => state.patients[iri],
  ...measuresGetters,
  ...riskFactorsGetters,
  ...medicalDatasetGetters,
  ...femaleMedicalDatasetGetters,
  ...consultationsGetters,
  ...notesGetters,
  ...documentDmpSyncsGetters,
  ...ongoingMedicationsGetters,
  ...patientHealthSummaryRemindersGetters,
};

export const mutations = {
  ...mapStoreResetter(state),
  SET_SETTINGS (state, settings) {
    state.settings = settings;
  },
  SET_SETTINGS_PATH (state, settingsPath) {
    state.settingsPath = settingsPath;
  },
  SET_PATIENT (state, patient) {
    Vue.set(state.patients, patient['@id'], new Patient(patient));
  },
  SET_IS_FETCHING_PATIENTS (state, isFetchingPatients) {
    state.isFetchingPatients = isFetchingPatients;
  },
  SET_CURRENT_PATIENT (state, patient) {
    Vue.set(state, 'currentPatient', new Patient(patient));
  },
  SET_FILTERED_PATIENT (state, patient) {
    Vue.set(state.filteredPatients, patient['@id'], new Patient(patient));
  },
  SET_ALL_FILTERED_PATIENTS (state, patients) {
    const uuidsObject = patients.reduce((acc, patient) => ({
      ...acc,
      [patient['@id']]: new Patient(patient),
    }), {});
    Vue.set(state, 'filteredPatients', uuidsObject);
  },
  SET_PATIENT_COUNT (state, patientCount) {
    state.patientCount = patientCount;
  },
  SET_FILTERED_PATIENT_COUNT (state, filteredPatientCount) {
    state.filteredPatientCount = filteredPatientCount;
  },
  SET_PARTNER_TRANSACTION_ID (state, partnerTransactionId) {
    state.partnerTransactionId = partnerTransactionId;
  },
  ...measuresMutations,
  ...riskFactorsMutations,
  ...medicalDatasetMutations,
  ...femaleMedicalDatasetMutations,
  ...consultationsMutations,
  ...notesMutations,
  ...ongoingMedicationsMutations,
  ...documentDmpSyncsMutations,
  ...patientHealthSummaryRemindersMutations,
};

export const actions = {
  async fetchSettings (context) {
    const { data } = await getFromAPI('/api/user_settings', { name: 'patient_ins_identity' });
    context.commit('SET_SETTINGS_PATH', data['hydra:member'][0]['@id']);
    context.commit('SET_SETTINGS', data['hydra:member'][0].value);
  },
  async updateSettings ({ state, commit }, settings) {
    const { data } = await patchToAPI(state.settingsPath, { data: { value: settings } });
    commit('SET_SETTINGS', data.value);
  },
  async fetchOnePatient ({ commit }, patientUUID) {
    const { data } = await getFromAPI(`/api/patients/${patientUUID}`);
    commit('SET_PATIENT', data);
  },
  async fetchPatients ({ commit }) {
    commit('SET_IS_FETCHING_PATIENTS', true);
    try {
      const { data } = await getFromAPI('/api/patients', {
        pagination: true,
        itemsPerPage: 30,
      });
      data['hydra:member'].forEach(patient => commit('SET_PATIENT', patient));
      commit('SET_PATIENT_COUNT', data['hydra:totalItems']);
    } catch (e) { /* empty */ }
    commit('SET_IS_FETCHING_PATIENTS', false);
  },
  async searchPatient ({ commit, getters }) {
    commit('SET_IS_FETCHING_PATIENTS', true);
    try {
      const { data } = await getFromAPI('/api/patients', getters.getFiltersValue);
      commit('SET_ALL_FILTERED_PATIENTS', data['hydra:member']);
      commit('SET_FILTERED_PATIENT_COUNT', data['hydra:totalItems']);
    } catch (e) { /* empty */ }
    commit('SET_IS_FETCHING_PATIENTS', false);
  },
  async insertPatient ({ state, commit }, patient) {
    patient.transactionId = state.partnerTransactionId;
    const createdPatient = await Patient.insert(patient);
    commit('SET_PATIENT', createdPatient);
    return createdPatient;
  },
  async updatePatient ({ state, commit }, patient) {
    patient.transactionId = state.partnerTransactionId;
    const updatedPatient = await Patient.update(patient);
    commit('SET_PATIENT', updatedPatient);
    return updatedPatient;
  },
  setPartnerTransactionId ({ commit }, transactionId) {
    commit('SET_PARTNER_TRANSACTION_ID', transactionId);
  },

  ...measuresActions,
  ...riskFactorsActions,
  ...medicalDatasetActions,
  ...femaleMedicalDatasetActions,
  ...consultationsActions,
  ...notesActions,
  ...ongoingMedicationsActions,
  ...documentDmpSyncsActions,
  ...patientHealthSummaryRemindersActions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};