export const PAYMENTS_TYPES = Object.freeze({
  CARD: {
    value: 'card',
    label: 'Carte Bancaire',
  },
  TRANSFER: {
    value: 'transfer',
    label: 'Virement',
  },
  CASH: {
    value: 'cash',
    label: 'Espèce',
  },
  CHECK: {
    value: 'check',
    label: 'Chèque',
  },
  OTHER: {
    value: 'other',
    label: 'Autre',
  },
});