<template>
  <n-tooltip
    v-slot="{ on }"
    :disabled="!keyword.description"
    :text="keyword.description"
  >
    <span v-on="on"><!-- span pour faire marcher la tooltip -->
      <app-tag
        v-test="'keyword-tag'"
        :color="keyword.color"
        small
        class="keyword-tag"
      >
        {{ keyword.label }}
      </app-tag>
    </span>
  </n-tooltip>
</template>

<script>
import PatientKeyword from '@/modules/patient/models/PatientKeyword';

import AppTag from '@/components/ui/tag/AppTag.vue';

export default {
  name: 'DisplayPatientKeyword',
  components: { AppTag },
  props: {
    keyword: {
      type: PatientKeyword,
      required: true,
    },
  },
};
</script>