<template>
  <span class="app-icon">
    <n-tooltip
      v-slot="{ on }"
      :disabled="! tooltip"
      :text="tooltip"
    >
      <v-icon
        :color="color"
        :size="size"
        :left="left"
        data-test="icon"
        v-on="getIconEvents(on)"
      >
        {{ displayedIcon }}
      </v-icon>
    </n-tooltip>
  </span>
</template>

<script>
import { ICONS } from '@/constants';

/**
 * Composant d\icône de l'application
 */
export default {
  name: 'AppIcon',
  props: {
    /**
     * l'\icône à afficher
     * @see ICONS
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * La couleur de l'icône
     * @default null
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * Permet l'affichage d'un tooltip
     * @default null
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * La taille de l'icône
     */
    size: {
      type: [Number, String],
      default: null,
    },
    /**
     * Si l'icone est à gauche de son label
     */
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayedIcon () {
      return ICONS[this.icon];
    },
  },
  methods: {
    getIconEvents (baseEvents) {
      if (this.$listeners.click) {
        return {
          ...baseEvents,
          click: () => this.$emit('click'),
        };
      }
      return baseEvents;
    },
  },
};
</script>

<style lang="scss">
.app-icon {
  display: inline-block;

  .v-icon {
    display: flex;
    &:focus::after {
      opacity: 0 !important;
    }
  }
}
</style>