import BaseFilter from './BaseFilter';

/**
 * Structure définissant les options complémentaires à BaseFilter
 * @typedef {Object} TextFilterExtendedOptions
 * @property {Number} minLength taille minimale requise pour déclencher la prise en compte du contenu
 */

/**
 * Structure définissant les options de configuration d'un filtre de texte
 * @typedef {import('@/utils/classes/httpRequestParameters/filters/BaseFilter').BaseFilterOptions & TextFilterExtendedOptions} TextFilterOptions
 */
export default class TextFilter extends BaseFilter {
  /**
   * Taille minimale du contenu avant d'activer le filtre
   */
  minLength;

  /**
   * Permet de créer un nouveau filtre de texte
   * @param {TextFilterOptions} options Les options de création de filtre de texte
   */
  constructor (options = {}) {
    super({
      ...options,
      debounce: true,
      onComponentValueChanged: () => {
        if (! this.isValid()) {
          this.updateValue(null);
          return;
        }

        this.updateValue(this.componentValue);
      },
      validator: (componentValue) => (componentValue && componentValue.length >= this.minLength),
    });

    this.minLength = options.minLength || 0;
  }
}