/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity';
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField';

const patientImportedDocumentEntity = new DocumentEntity(
  'PatientImportedDocument',
  'patientImportedDocuments.xml',
  'Contient les données des documents importés de chaque patient',
);

patientImportedDocumentEntity.addField(new DocumentEntityField('title', 'Le titre'));
patientImportedDocumentEntity.addField(new DocumentEntityField('description', 'La description'));
patientImportedDocumentEntity.addField(new DocumentEntityField('path', 'Le chemin d\'accès au document'));

export default patientImportedDocumentEntity;