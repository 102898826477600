import { INSEE_UNKNOWN_REF, INSEE_NOT_FOUND_PLACE_LABEL } from '@/modules/patient/constants';

/**
 * Retourne le label d'un lieu de naissance en fonction d'un code ou d'un couple code/label donné
 * @param {String} code Le code du lieu de naissance
 * @param {String} label Le label du lieu de naissance
 * @returns {String}
 */
export const getBirthPlaceFullLabel = (code, label) => {
  if (! code) {
    throw new Error('Veuillez renseigner une valeur pour le code du lieu de naissance');
  }
  if (code === INSEE_UNKNOWN_REF.value) {
    return INSEE_UNKNOWN_REF.label;
  }
  if (! label) {
    return INSEE_NOT_FOUND_PLACE_LABEL;
  }
  return label;
};