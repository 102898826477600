<template>
  <div class="d-flex align-center">
    <p>{{ bioResult.values[selectedUnitIndex].unit }}</p>
    <v-menu
      v-if="bioResult.values.length > 1 && getTableItemValueUnits(bioResult).length > 1"
      open-on-click
    >
      <template #activator="{ on }">
        <span v-on="on">
          <app-button
            x-small
            icon="edit"
            class="ml-1"
            label="Changer l'unité"
          />
        </span>
      </template>
      <v-list>
        <v-list-item-group
          v-model="localSelectedUnitIndex"
          color="primary"
          mandatory
        >
          <v-list-item
            v-for="(unit, unitIndex) in getTableItemValueUnits(bioResult)"
            :key="unitIndex"
            :value="unitIndex"
            @click="localSelectedUnitIndex = unitIndex"
          >
            {{ unit }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import BioResultTableItem from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/classes/BioResultTableItem';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';


export default {
  name: 'BioResultListUnitSelector',
  mixins: [
    localCopyMixin({
      propertyName: 'selectedUnitIndex',
      copyPropertyName: 'localSelectedUnitIndex',
    }),
  ],
  props: {
    bioResult: {
      type: BioResultTableItem,
      required: true,
    },
    selectedUnitIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getTableItemValueUnits (item) {
      return item.values.map(value => value.unit).filter(unit => unit);
    },
  },
};
</script>