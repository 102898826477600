<template>
  <app-form-wrapper
    ref="formWrapper"
    class="app-fullscreen-layout"
    v-bind="{...$props, ...$attrs }"
    :title="null"
    :value="value"
    :form="form"
  >
    <template #default="{ localValue, formValidation, isSubmitting, formId, validationObserver }">
      <fullscreen-layout
        :title="title"
        :back-route-name="backRouteName"
        :hide-mobile-fab="hideMobileFab"
        :show-back-button="showBackButton"
        v-bind="pageLayoutMixin_props"
        @goBack="$emit('cancel')"
      >
        <template #title>
          <section class="d-flex flex-column align-start">
            <slot name="title">
              <div
                v-if="editableTitle"
                class="ml-n2"
              >
                <section
                  class="app-fullscreen-layout__title-input-container mr-2"
                  :title="localTitle || titleLabel"
                >
                  <app-text-field
                    ref="title-input"
                    v-model="localTitle"
                    v-test="'title-input'"
                    class="app-fullscreen-layout__title-input-container__input"
                    rules="required"
                    name="title"
                    filled
                    solo
                    dense
                    hide-errors
                    :label="titleInputLabel"
                    :placeholder="titleInputLabel"
                    @keypress.enter="handleTitleInputEnterKeypress"
                    @change="changeTitle"
                  />
                  <p
                    v-test="'title-input-placeholder'"
                    class="app-fullscreen-layout__title-input-container__placeholder"
                  >
                    {{ localTitle || titleInputLabel }}
                  </p>
                </section>
              </div>
              <template v-else>
                {{ title }}
              </template>
            </slot>
            <slot name="title-sub" />
          </section>
        </template>
        <template #actions>
          <div class="d-flex">
            <slot
              name="actions"
              :is-valid="formValidation.valid"
              :is-submitting="isSubmitting"
              :form-id="formId"
              v-bind="{ formValidation, isSubmitting, formId }"
            >
              <app-button
                v-test="'submit'"
                :loading="isSubmitting"
                type="submit"
                :form="formId"
                color="primary"
                :disabled="!formValidation.valid"
              >
                {{ submitText }}
              </app-button>
            </slot>
          </div>
        </template>

        <template
          v-if="$scopedSlots['mobile-sub-header']"
          #mobile-sub-header="{ toggleSidebar }"
        >
          <slot
            name="mobile-sub-header"
            :toggle-sidebar="toggleSidebar"
          />
        </template>

        <template #sidebar>
          <slot name="sidebar" />
        </template>

        <template #sub-header>
          <slot name="sub-header" />
        </template>
        <component
          :is="form"
          :id="formId"
          ref="form"
          v-test="'form'"
          :value="localValue"
          v-bind="$attrs"
          :validation-observer="validationObserver"
          v-on="$listeners"
          @submitForm="(submitMethod) => submitForm($refs.formWrapper, submitMethod)"
        />
      </fullscreen-layout>
    </template>
  </app-form-wrapper>
</template>

<script>
import AppFormWrapper from './AppFormWrapper.vue';
import FormMixin from './mixins/FormMixin';
import pageLayoutMixin from '@/layout/mixins/pageLayoutMixin';
import { LocalCopyMixin } from '@novalys/mixins';

import AppTextField from '@/components/ui/form/AppTextField.vue';
import FullscreenLayout from '@/layout/Fullscreen.vue';



export default {
  name: 'AppFullscreenForm',
  components: {
    AppFormWrapper,
    FullscreenLayout,
    AppTextField,
  },
  mixins: [
    FormMixin,
    pageLayoutMixin,
    LocalCopyMixin({
      propertyName: 'title',
      copyPropertyName: 'localTitle',
    }),
  ],
  inheritAttrs: false,
  props: {
    /**
     * Constructeur du composant (fichier .vue, ou créé programatiquement)
     */
    form: {
      type: [String, Object],
      required: true,
    },
    /**
     * Modèle utilisé pour remplir le formulaire
     */
    value: {
      type: Object,
      default: null,
    },
    /**
     * Intitulé de la fenêtre
     */
    title: {
      type: String,
      default: null,
    },
    titleLabel: {
      type: String,
      default: null,
    },
    /**
     * Label du bouton de soumission
     * @default "Enregistrer"
     */
    submitText: {
      type: String,
      default: 'Enregistrer',
    },
    /**
     * Nom de la route vers laquelle rediriger quand on appuie sur le bouton de retour
     */
    backRouteName: {
      type: String,
      default: null,
    },

    hideMobileFab: {
      type: Boolean,
      default: false,
    },
    editableTitle: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return { titleInputLabel: this.titleLabel };
  },
  watch: {
    titleLabel () {
      this.setTitleInputLabel();
    },
  },
  mounted () {
    this.setTitleInputLabel();
  },
  methods: {
    getTitleInputRef () {
      return this.$refs['title-input'];
    },
    handleTitleInputEnterKeypress () {
      this.getTitleInputRef().blur();
    },
    setTitleInputLabel () {
      this.titleInputLabel = this.getTitleInputRef()?.fieldLabel;
    },
    changeTitle (title) {
      this.$emit('change:title', title);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-fullscreen-layout {
  &__title-input-container {
    position: relative;
    max-width: 400px;
    font-size: 18px;

    &__input,
    &__placeholder {
      font-size: inherit;
      font-weight: inherit;
    }

    &__input {
      border: 2px solid transparent;
      border-radius: 6px;
      position: absolute;
      transition: border-color .3s ease;
      width: 100%;

      &.v-input--is-focused {
        border-color: var(--v-primary-base);
      }

      &.error--text {
        border-color: var(--v-error-base);
      }

      ::v-deep {
        .v-label {
          font-size: inherit;
          font-weight: inherit;
          max-width: 100%;
          top: 50% !important;
          transform: translateY(-50%);
        }

        .v-input {
          &__slot,
          &__control {
            min-height: 26px !important;
          }

          &__slot {
            padding: 0 map-get($spacers, 1) !important;
            box-shadow: none !important;
          }
        }

        input {
          padding: 0 !important;
          text-overflow: ellipsis;
        }
      }
    }

    &__placeholder {
      padding: 0 map-get($spacers, 1);
      opacity: 0;
      pointer-events: none;
      height: 30px;
      border: 2px solid;
    }
  }
}
</style>