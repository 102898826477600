<!-- eslint-disable max-len -->
<template>
  <div>
    <div class="flex-list-2 flex-column">
      <app-dataset-item
        v-test="'report-label'"
        label="Type de traitement"
        :value="reportTitle"
      />
      <p
        v-if="report.code === '1'"
        class="secondary--text"
      >
        Les informations relatives à l'ALD proviennent d'un organisme différent de celui issu de la carte vitale.
      </p>
    </div>
    <n-divider :spacing="4" />
    <div class="flex-list-2 flex-column">
      <h3 class="subtitle-3">
        Bénéficiaire
      </h3>
      <app-row>
        <app-col
          cols="12"
          md="6"
        >
          <div class="flex-column flex-list-1">
            <app-dataset-item
              v-test="'beneficiary-fullname'"
              label="Nom complet"
              :value="beneficiary.fullName"
            />
            <app-dataset-item
              v-test="'beneficiary-nir'"
              label="NIR"
              :value="beneficiary.nir ? beneficiary.nir.getFullNir() : null"
            />
            <app-dataset-item
              v-test="'beneficiary-birth-date'"
              label="Date de naissance"
              :value="beneficiary.getFormattedBirthDate()"
            />
            <app-dataset-item
              v-test="'beneficiary-birth-rank'"
              label="Rang de naissance"
              :value="beneficiary.birthrank"
            />
          </div>
        </app-col>
        <app-col
          cols="12"
          md="6"
        >
          <app-dataset-item
            v-test="'right-opening-nir'"
            label="NIR de l'ouvrant droit"
            :value="beneficiary.nirOd ? beneficiary.nirOd.getFullNir() : null"
          />
        </app-col>
      </app-row>
    </div>
    <n-divider :spacing="4" />
    <div class="flex-list-3 flex-column">
      <h3 class="subtitle-3">
        Liste des ALD
      </h3>
      <p class="secondary--text">
        La liste des ALD peut ne pas être exhaustive.
        Certaines ALD peuvent ne pas être communiquées (par exemple pour des dossiers confidentiels ou filtres spécifiques).
      </p>
      <p class="secondary--text">
        Le libellé de l'ALD fourni par le service peut ne pas être celui qui a été utilisé par le médecin traitant pour remplir le protocole de soins.
      </p>
    </div>
    <div class="ald-list-grid mt-1">
      <v-container>
        <v-row align="end">
          <v-col cols="1">
            <span v-test="'app-list-grid-header'">Code</span>
          </v-col>
          <v-col cols="4">
            <span v-test="'app-list-grid-header'">Nom</span>
          </v-col>
          <v-col cols="2">
            <span v-test="'app-list-grid-header'">Prise en charge</span>
          </v-col>
          <v-col cols="2">
            <span v-test="'app-list-grid-header'">Début</span>
          </v-col>
          <v-col cols="2">
            <span v-test="'app-list-grid-header'">Fin</span>
          </v-col>
          <v-col cols="1">
            <span v-test="'app-list-grid-header'">Code CIM10</span>
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in aldList"
          :key="index"
          v-test="'app-list-grid-row'"
        >
          <v-col cols="1">
            <span>{{ item.code }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ getLabel(item.label) }}</span>
          </v-col>
          <v-col cols="2">
            <span>{{ item.state }}</span>
          </v-col>
          <v-col cols="2">
            <span>{{ getFormattedDate(item.startDate, 'male') }}</span>
          </v-col>
          <v-col cols="2">
            <span>{{ getFormattedDate(item.endDate, 'female') }}</span>
          </v-col>
          <v-col cols="1">
            <span>{{ item.cim.code }}</span>
          </v-col>
          <v-col
            v-if="!item.endDate"
            cols="12"
            class="pt-0"
          >
            <span class="secondary--text">
              Les informations relatives à l'ALD du patient sont anciennes et peuvent justifier l'actualisation de son protocole de soins.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

import TlsiBeneficiary from '@/modules/patient/utils/classes/TlsiBeneficiary';
import NovaTools from '@/nova-tools/NovaTools';
import { capitalize, genderize } from '@/utils/functions/words';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';

export default {
  name: 'AldiResultSummary',
  components: { AppDatasetItem },
  props: {
    report: {
      type: Object,
      required: true,
    },
    beneficiary: {
      type: TlsiBeneficiary,
      required: true,
    },
    aldList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    reportTitle () {
      return `${this.report.label} (${this.report.code})`;
    },
  },
  methods: {
    getLabel (label) {
      return capitalize(label);
    },
    getFormattedDate (date, gender) {
      return date ? NovaTools.dates.format(date.split('+')[0], 'dd/MM/yyyy') : genderize('Non renseigné', gender);
    },
  },
};
</script>
<style lang="scss" scoped>
.ald-list-grid {
  .row {
    &:not(:last-of-type){
      border-bottom: thin solid rgba(55, 70, 122, 0.12);
    }

    &:first-of-type {
      color: rgba(55, 70, 122, 0.6);
    }
  }
}
</style>