<template xmlns:scoped="http://www.w3.org/1999/xhtml">
  <medical-history-item
    v-test="'note-panel'"
    :date="note.createdAt"
    :update-date="note.updatedAt"
    :updated-by="note.updatedBy.contact"
    :author="note.createdBy.contact"
    title="Note"
    icon="note"
    :color="NOTE_COLOR"
    :actions="noteActions"
  >
    <app-simple-form
      v-if="isEditing"
      v-test="'note-form'"
      :value="note"
      :form="NoteForm"
      :show-divider="false"
      auto-focus
      class="note"
      @submitSuccess="isEditing = false"
    />
    <span v-else class="note" data-test="note">{{ note.content }}</span>
  </medical-history-item>
</template>

<script>
import { mapActions } from 'vuex';

import MedicalHistoryItem from './MedicalHistoryItem.vue';
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem';
import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote';
import NovaTools from '@/nova-tools/NovaTools';
import DeleteButtonAction from '@novalys/src/models/DeleteButtonAction';

import AppSimpleForm from '@/components/ui/form/AppSimpleForm.vue';
import NoteForm from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/forms/NoteForm.vue';

const NOTE_COLOR = '#FFFEF0';

export default {
  name: 'MedicalHistoryNote',
  components: {
    MedicalHistoryItem,
    AppSimpleForm,
  },
  props: {
    note: {
      type: MedicalHistoryNote,
      default: () => new MedicalHistoryNote(),
    },
  },
  data () {
    return {
      NOTE_COLOR,
      NoteForm,
      isEditing: false,
      noteActions: [
        new EditActionMenuItem({ callback: () => {
            this.isEditing = true;
        }}),
        new DeleteActionMenuItem({
          callback: () => {
            NovaTools.dialog.confirm(
              'Supprimer la note',
              'Êtes-vous sûr(e) de vouloir supprimer la note ?',
              new DeleteButtonAction(() => this.deleteNote()),
            );
          },
        }),
      ],
    };
  },
  watch: {
    isEditing (newVal) {
      const action = newVal ? 'addEventListener' : 'removeEventListener';
      window[action]('keydown', this.handleKeydown);
    },
  },
  methods: {
    ...mapActions('patient', ['deletePatientMedicalHistoryNotes']),
    handleKeydown ({ keyCode }) {
      // Touche échap
      if (keyCode === 27) {
        this.isEditing = false;
      }
    },
    async deleteNote () {
      await this.deletePatientMedicalHistoryNotes(this.note);
      NovaTools.notify.success('La note a été supprimée avec succès');
    },
  },
};
</script>

<style lang="scss" scoped::v-deep>
  $note-font-size: 13px;

  .note {
    font-style: italic;
    white-space: break-spaces;
    font-size: $note-font-size;

    .v-input {
      margin-top: 0;
      padding-top: 0;
      background-color: #fff;

      .v-input__slot:before,
      .v-input__slot:after,
      .v-label {
        display: none;
      }
    }

    textarea {
      font-size: $note-font-size;
      border: 1px solid var(--v-blue-grey-base);
      padding: 4px 8px;
      border-radius: 4px;

      &:focus {
        border-color: var(--v-primary-base);
      }
    }
  }
</style>