<template>
  <div class="easy-care-logo">
    <svg
      data-test="logo"
      width="36px"
      height="36px"
      class="icon-logo"
    >
      <use xlink:href="#icon-logo" />
    </svg>
    <h1
      v-if="showLabel"
      class="easy-care-logo__label ml-3"
      data-test="logo-label"
    >
      easy-care
    </h1>
  </div>
</template>

<script>
export default {
  name: 'EasyCareLogo',
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.easy-care-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__label {
    font-size: 16px;
    font-weight: 600;
    color: var(--v-primary-base);
  }
}
</style>