<template>
  <app-form
    :submit-method="submit"
    class="patient-relative-form"
  >
    <span class="patient-relative-form__group-title mt-0">État civil</span>
    <div class="patient-relative-form__row">
      <app-select
        v-model="localPatientRelative.civility"
        class="app-select-civility"
        label="Civilité"
        :items="PATIENT_CIVILITIES"
        item-text="shortLabel"
        item-value="value"
        rules="required"
      />
      <app-text-field
        v-model="localPatientRelative.familyName"
        label="Nom"
        rules="required"
      />
      <app-text-field
        v-model="localPatientRelative.firstNames"
        label="Prénom"
        rules="required"
      />
    </div>
    <div class="patient-relative-form__row">
      <app-select
        v-model="localPatientRelative.link"
        label="Lien avec le patient"
        :items="Object.values(PATIENT_RELATIONS)"
        item-text="label"
        item-value="name"
        rules="required"
      />
    </div>
    <span class="patient-relative-form__group-title">Contact</span>
    <span class="patient-relative-form__group-subtitle"><app-icon
      icon="phone"
      color="secondary"
    />Téléphones</span>
    <div class="patient-relative-form__row">
      <app-text-field
        v-model="contactPoints[0].value"
        v-mask="'## ## ## ## ##'"
        rules="regex:^(?:\s*\d){10}$"
        label="Principal"
      />
      <app-text-field
        v-model="contactPoints[1].value"
        v-mask="'## ## ## ## ##'"
        rules="regex:^(?:\s*\d){10}$"
        label="Autre"
      />
    </div>
    <span class="patient-relative-form__group-subtitle"><app-icon
      icon="email"
      color="#8c94b2"
    />E-mails</span>
    <div class="patient-relative-form__row">
      <app-text-field
        v-model="contactPoints[2].value"
        rules="email"
        label="Principal"
      />
      <app-text-field
        v-model="contactPoints[3].value"
        rules="email"
        label="Autre"
      />
    </div>
    <span class="patient-relative-form__group-subtitle"><app-icon
      icon="address"
      color="#8c94b2"
    />Adresse</span>
    <div class="patient-relative-form__row">
      <app-text-field
        v-model="localPatientRelative.address.line1"
        label="Ligne 1"
      />
    </div>
    <div class="patient-relative-form__row">
      <app-text-field
        v-model="localPatientRelative.address.line2"
        label="Ligne 2"
      />
    </div>
    <div class="patient-relative-form__row">
      <app-text-field
        v-model="localPatientRelative.address.postalCode"
        label="Code postal"
      />
      <app-text-field
        v-model="localPatientRelative.address.city"
        label="Ville"
      />
    </div>
    <span class="patient-relative-form__group-title">Autre</span>
    <div class="patient-relative-form__row">
      <app-textarea-field
        v-model="localPatientRelative.note"
        label="Commentaire"
        :rows="4"
      />
    </div>
  </app-form>
</template>

<script>
import { MEDIA_TYPES } from '@/constants';
import ContactPoint from '@/models/ContactPoint';
import { PATIENT_CIVILITIES, PATIENT_RELATIONS } from '@/modules/patient/constants';
import PatientRelative from '@/modules/patient/models/PatientRelative';
import NovaTools from '@/nova-tools/NovaTools';
import { postToAPI, putToAPI } from '@/services/api';
import { LocalCopyMixin } from '@novalys/mixins';


import AppForm from '@/components/ui/form/AppForm.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';

export default {
  name: 'PatientRelativesForm',
  components: {
    AppTextareaField,
    AppSelect,
    AppTextField,
    AppForm,
  },
  mixins: [LocalCopyMixin({
    propertyName: 'value',
    copyPropertyName: 'localPatientRelative',
  })],
  props: {
    /**
     * La personne de l'entourage à créer ou modifier
     */
    value: {
      type: PatientRelative,
      default: () => new PatientRelative(),
    },
    /**
     * Patient avec lequel la personne de l'entourage sera liée
     */
    patientIri: {
      type: String,
      required: true,
    },
    /**
     * Le mode d'insertion de la personne
     * false pour une création, true pour une édition
     */
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      PATIENT_CIVILITIES,
      PATIENT_RELATIONS,
      contactPoints: null,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (patientRelative) {
        const personalPhoneContactPoint = patientRelative.contactPoints
          .find((contact) => contact.media === MEDIA_TYPES.TELEPHONE.value && contact.label === 'personal') || new ContactPoint({
          media: MEDIA_TYPES.TELEPHONE.value,
          label: 'personal',
        });
        const otherPhoneContactPoint = patientRelative.contactPoints
          .find((contact) => contact.media === MEDIA_TYPES.TELEPHONE.value && contact.label === 'other') || new ContactPoint({
          media: MEDIA_TYPES.TELEPHONE.value,
          label: 'other',
        });
        const personalMailContactPoint = patientRelative.contactPoints
          .find((contact) => contact.media === MEDIA_TYPES.EMAIL.value && contact.label === 'personal') || new ContactPoint({
          media: MEDIA_TYPES.EMAIL.value,
          label: 'personal',
        });
        const otherMailContactPoint = patientRelative.contactPoints
          .find((contact) => contact.media === MEDIA_TYPES.EMAIL.value && contact.label === 'other') || new ContactPoint({
          media: MEDIA_TYPES.EMAIL.value,
          label: 'other',
        });

        this.contactPoints = [
          personalPhoneContactPoint,
          otherPhoneContactPoint,
          personalMailContactPoint,
          otherMailContactPoint,
        ];
      },
    },
  },
  methods: {
    async submit () {
      const params = new PatientRelative({
        ...this.localPatientRelative,
        patient: this.patientIri,
        contactPoints: ContactPoint.removeEmptyContactPoints(this.contactPoints),
      });

      let successMessage = '';
      if (this.isEditing) {
        await putToAPI(this.localPatientRelative['@id'], { data: params });
        successMessage = 'La personne de l\'entourage a été modifiée avec succès';
      } else {
        await postToAPI('/api/patient_relatives', params);
        successMessage = 'La personne de l\'entourage a été ajoutée avec succès';
      }
      NovaTools.notify.success(successMessage);
    },
  },
};
</script>
<style lang="scss" scoped>
  .patient-relative-form {
    display: flex;
    flex-direction: column;

    .app-select-civility {
      ::v-deep {
        .v-input__control {
          width: 120px ;
        }
      }
    }

    &__group-title {
      font-weight: 600;
      font-size: 16px;
      color: var(--v-content-base) !important;
      margin-top: map-get($spacers, 6);
    }

    &__group-subtitle {
      font-weight: 600;
      font-size: 13px;
      display: flex;
      gap: 10px;
      color: var(--v-content-base) !important;
      margin-top: map-get($spacers, 4);
    }

    &__row {
      display: flex;
      gap: map-get($spacers, 4);
    }
  }
</style>