import { deburr, capitalize as lodashCapitalize } from 'lodash';

/**
 * Renvoie une chaine de caractères au pluriel
 * @param {String} word Le mot à mettre au pluriel
 * @param {Number|String} count Le nombre permettant de déterminer la pluralité du mot
 * @returns {String} Mot au pluriel
 */
export const pluralize = (word, count) => {
  if (count === 0 || (word.endsWith('s') || word.endsWith('aux'))) {
    return word;
  }
  if (!count || (Number(count.toString().replace(',', '.')) >= 2)) {
    const pluralSuffix = word.endsWith('al') ? 'ux' : 's';
    return (word.endsWith('al') ? word.slice(0, word.length - 1) : word) + pluralSuffix;
  }
  return word;
};

/**
 * Ajoute un "e" à la fin d'un mot si le genre renseigné est féminin.
 * Dans le cas d'un genre inconnu alors "(e)" sera rajouté à la fin du mot
 * @param {String} word Le mot à genrer (Doit être au masculin)
 * @param {String} gender Le genre du mot
 * @returns {String} Mot genré
 */
export const genderize = (word, gender) => {
  const isFeminine = word[word.length - 1] === 'e';
  if (gender === 'unknown') {
    return `${word}(e)`;
  }
  return gender === 'female' && ! isFeminine ? `${word}e` : word;
};

/**
 * Permet de déterminer si une chaine de caractère est contenue dans une autre
 * En ignorant les caractères spéciaux ainsi que leurs casses
 * @param {String} str La chaine de caractère originale
 * @param {String} search La chaine de caractère à rechercher
 * @returns {Boolean} Si la chaine de caractères contient la phrase recherchée
 */
export const softInclude = (str, search) => {
  const deburredStr = deburr(str).toLowerCase();
  const deburredSearch = deburr(search).toLowerCase();
  return deburredStr.includes(deburredSearch);
};

/**
 * Convertit la première lettre des mots d'une chaîne de caractères en majuscule en tenant compte des mots composés
 * et éventuellement des espaces
 * @param {String} string La chaîne de caractères à traiter
 * @param {Boolean} applyOnEveryWord Spécifie si on doit capitaliser sur chaque mot de la chaîne séparé par un espace
 * @returns {String} La chaîne de caractères convertie
 */
export const capitalize = (string, applyOnEveryWord = false) => {
  if (applyOnEveryWord) {
    return string.split(' ').map(word => word.split('-').map(word => lodashCapitalize(word)).join('-')).join(' ');
  }
  return string.split('-').map(word => lodashCapitalize(word)).join('-');
};

/**
 * Retourne un mot ou une chaine de caractères avec la première lettre en majuscule et le reste en minuscule
 * @param {String} str Le mot/chaine de caractères
 * @returns {String} str Le mot/chaine de caractères avec la première lettre en majuscule et le reste en minuscule
 */
export const titleCase = (str) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);