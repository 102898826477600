<template>
  <app-panel
    class="civil-state-fieldset"
    title="Identité"
  >
    <identity-civil-state-fieldset
      v-test="'identity-civil-state-fieldset'"
      :gender.sync="localValue.gender"
      :civility.sync="localValue.civility"
      :birth-name.sync="localValue.birthName"
      :family-name.sync="localValue.familyName"
      :first-names.sync="localValue.firstNames"
      :first-name.sync="localValue.firstName"
      :used-first-name.sync="localValue.usedFirstName"
      :birth-date.sync="localValue.birthDate"
      :birth-place-code.sync="localValue.birthPlaceCode"
      :birth-place-label.sync="localValue.birthPlaceLabel"
      :nir.sync="localValue.nir"
      :ins-traits-disabled="isInsStrictFeaturesLocked"
      :auto-select-civility="autoSelectCivility"
      :auto-select-first-name="! isFirstNameSelectAutomationDisabled"
      :is-deceased.sync="localValue.isDeceased"
      :death-date.sync="localValue.deathDate"
      :nir-allow-unchecked.sync="localValue.nirAllowUnchecked"
      :is-partner-access="isPartnerAccess"
    />
    <identity-security-fieldset
      v-model="localValue.insIdentity"
      :patient="localValue"
      :is-logging-ins-call="isLoggingInsCall"
      :is-partner-access="isPartnerAccess"
      @ins-fetch-success="handleInsFetchSuccess"
      @fetch-ins="$emit('fetch-ins', $event)"
      @partner-success="$emit('partner-success', $event)"
      @partner-error="$emit('partner-error', $event)"
    />
  </app-panel>
</template>
<script>
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppPanel from '@/components/ui/panel/AppPanel.vue';
import IdentityCivilStateFieldset from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/IdentityCivilStateFieldset.vue';
import IdentitySecurityFieldset from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/IdentitySecurityFieldset.vue';



export default {
  name: 'IdentityFieldset',
  components: {
    IdentityCivilStateFieldset,
    IdentitySecurityFieldset,
    AppPanel,
  },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: Patient,
      default: null,
    },
    isLoggingInsCall: {
      type: Boolean,
      default: false,
    },
    autoSelectCivility: {
      type: Boolean,
      default: false,
    },
    isPartnerAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isFirstNameSelectAutomationDisabled: false,
      insResetDisabled: false,
    };
  },
  computed: {
    isUpdateInsGranted () {
      return NovaTools.security.permissions.hasPermission('update_ins_strict_features');
    },
    isInsStrictFeaturesLocked () {
      return Boolean(this.localValue.insIdentity.ins) && ! this.isUpdateInsGranted;
    },
    insStrictTraits () {
      const values = ['gender', 'firstNames', 'birthDate', 'birthPlaceCode', 'birthName'];
      return values.reduce((acc, trait) => ({
        ...acc,
        [trait]: this.value[trait]?.toUpperCase(),
      }), {});
    },
  },
  watch: {
    insStrictTraits (insStrictTraits, oldInsStrictTraits) {
      if (Object.values(oldInsStrictTraits).filter(item => item).length === 0) {
        return;
      }
      if (!! this.value.insIdentity.ins && this.isUpdateInsGranted) {
        this.onInsStrictFeaturesChange(insStrictTraits, oldInsStrictTraits);
      }
    },
  },
  methods: {
    handleInsFetchSuccess (newPatient) {
      this.isFirstNameSelectAutomationDisabled = true;
      this.insResetDisabled = true;
      if (this.localValue.firstNames !== newPatient.firstNames) {
        newPatient.firstName = null; // on reset le champ 1er prénom
      }
      this.$emit('input', newPatient);
    },
    onInsStrictFeaturesChange (insStrictTraits, oldInsStrictTraits) {
      if (this.insResetDisabled) {
        this.insResetDisabled = false;
        return;
      }
      if (Object.keys(insStrictTraits).every(trait => insStrictTraits[trait] === oldInsStrictTraits[trait])) {
        return;
      }
      this.value.insIdentity.reset();
    },
  },
};
</script>