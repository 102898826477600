export const INS_IDENTITY_STATUS = Object.freeze({
  TEMPORARY: {
    value: 'temporary',
    label: 'Identité Provisoire',
    color: 'error',
  },
  VALIDATED: {
    value: 'validated',
    label: 'Identité Validée',
    color: 'warning',
  },
  FETCHED: {
    value: 'fetched',
    label: 'Identité Récupérée',
    color: 'primary',
  },
  QUALIFIED: {
    value: 'qualified',
    label: 'Identité Qualifiée',
    color: 'success',
  },
});