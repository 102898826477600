import TemplateFolder from './TemplateFolder';
import ApiEntity from '@/models/ApiEntity';

export default class Template extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.title = options.title || null;
    this.content = options.content || null;
    this.folder = options.folder
      ? new TemplateFolder(options.folder)
      : null;
    this.createdBy = options.createdBy || {};
    this.updatedBy = options.updatedBy || {};
    this.header = options.header || null;
    this.footer = options.footer || null;
    this.dmpSync = options.dmpSync || false;
    this.enabled = typeof options.enabled !== 'undefined' ? options.enabled : true;
  }
}