<template>
  <div class="search-prescription-filter">
    <validation-observer ref="observer">
      <div class="search-prescription-filter__parameters">
        <app-select
          v-model="params.prescriptionType"
          class="search-prescription-filter__parameters__item"
          :items="formattedPrescriptionTypes"
          label="Type de prescription"
          item-text="text"
          item-value="value"
        />
        <app-select
          v-model="params.prescriptionSubType"
          class="search-prescription-filter__parameters__item"
          :items="PRESCRIPTIONS_SOUS_TYPES"
          label="Sous type de prescription"
          item-text="label"
          item-value="value"
          clearable
        />
        <app-text-field
          v-model="params.amId"
          class="search-prescription-filter__parameters__item"
          label="Identifiant"
          append-icon="qrcode"
          clearable
          @click:append="openBarcodeReaderModal"
        />
        <v-menu
          v-model="showMenu"
          offset-y
          offset-x
          left
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <app-button
              icon="filters"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <div class="search-prescription-filter__parameters__menu">
            <prescription-period-filter v-model="params.period" />
            <div style="height: 68px">
              <div style="font-size: 13px;font-weight: 600;color: #37467A;height: 20px">
                Bénéficiaire
              </div>
              <div style="display: flex;gap: 16px">
                <app-text-field
                  v-model="params.nir"
                  label="NIR"
                />
                <app-select
                  v-model="params.rank"
                  :items="getRankItems()"
                  label="Rang"
                  item-text="label"
                  item-value="value"
                />
              </div>
            </div>
            <div style="height: 68px">
              <div style="font-size: 13px;font-weight: 600;color: #37467A;height: 20px">
                Ouvrant droit et patient
              </div>
              <div style="display: flex;gap: 16px">
                <app-text-field
                  v-model="params.nirOD"
                  name="nirOd"
                  label="NIR de l'ouvrant droit"
                  class="search-prescription-filter__parameters__item"
                />
                <app-date-field
                  v-model="params.birthDate"
                  class="search-prescription-filter__parameters__item"
                  name="birthDate"
                  label="Date de naissance du patient"
                  hide-details="auto"
                />
              </div>
            </div>
          </div>
        </v-menu>
      </div>
    </validation-observer>
    <div class="search-prescription-filter__tags">
      <app-tag
        v-if="params.period.typeDate"
        color="#F5F8FA"
        small
        closable
        @click:close="resetPeriod"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="params.period.labelTag" />
        <!--eslint-enable-->
      </app-tag>
      <app-tag
        v-if="params.nir"
        color="#F5F8FA"
        small
        closable
        @click:close="params.nir=null"
      >
        <div>Nir Bénéficiaire  <b> {{ params.nir }}</b></div>
      </app-tag>
      <app-tag
        v-if="params.rank"
        color="#F5F8FA"
        small
        closable
        @click:close="params.rank=null"
      >
        <div>Rang <b>{{ params.rank }}</b></div>
      </app-tag>
      <app-tag
        v-if="params.nirOD"
        color="#F5F8FA"
        small
        closable
        @click:close="params.nirOD=null"
      >
        <div>Nir de l'ouvrant droit <b>{{ params.nirOD }}</b></div>
      </app-tag>
      <app-tag
        v-if="params.birthDate"
        color="#F5F8FA"
        small
        closable
        @click:close="params.birthDate=null"
      >
        <div>Patient né le <b>{{ NovaTools.dates.format(params.birthDate,'dd/MM/yyyy') }}</b></div>
      </app-tag>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <app-button
        color="primary"
        small
        class="mt-2"
        @click="onChange"
      >
        <app-icon icon="search" />
        <div class="pl-2">
          Lancer la recherche
        </div>
      </app-button>
    </div>
  </div>
</template>
<script>
import { BarcodeFormat } from '@zxing/library';
import { ValidationObserver } from 'vee-validate';

import { PRESCRIPTIONS_SOUS_TYPES } from '@/constants';
import violationsMixin from '@/mixins/violations';
import { PRESCRIPTION_TYPES } from '@/modules/patient/constants';
import NovaTools from '@/nova-tools/NovaTools';
import { getRefAsArray } from '@/utils/functions/refs';

import BarcodeReaderModal from '@/components/ui/barcodeReaderModal/BarcodeReaderModal.vue';
import AppDateField from '@/components/ui/form/AppDateField.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';
import PrescriptionPeriodFilter
  from '@/layout/default/components/header/searchMenu/searchPrescription/filter/PrescriptionPeriodFilter.vue';



export default {
  name: 'SearchPrescriptionFilter',
  components: {
    PrescriptionPeriodFilter,
    AppTag,
    AppDateField,
    AppTextField,
    AppSelect,
    ValidationObserver,
  },
  mixins: [violationsMixin],
  data () {
    return {
      PRESCRIPTION_TYPES,
      PRESCRIPTIONS_SOUS_TYPES: getRefAsArray(PRESCRIPTIONS_SOUS_TYPES),
      showBarCodeReader: false,
      barCodeFormat: BarcodeFormat.QR_CODE,
      showMenu: false,
      params: {
        amId: null,
        prescriptionType: 'health_product',
        prescriptionSubType: null,
        period: {
          typeDate: null,
          startDate: null,
          endDate: null,
          labelTag: null,
        },
        nir: null,
        rank: null,
        nirOD: null,
        birthDate: null,
      },
    };
  },
  computed: {
    actsWithEPrescription () {
      return Object.values(PRESCRIPTION_TYPES)
        .filter(type => type.value !== PRESCRIPTION_TYPES.HEALTH_PRODUCT.value && ! type.preventEprescription)
        .sort((pTypeA, pTypeB) => pTypeA.text.localeCompare(pTypeB.text));
    },
    formattedPrescriptionTypes () {
      return [
        PRESCRIPTION_TYPES.HEALTH_PRODUCT,
        { divider: true },
        {
          text: 'Tous',
          value: 'all',
        },
        { divider: true },
        { header: 'Acte(s)' },
        ...this.actsWithEPrescription,
      ];
    },
  },
  methods: {
    onChange () {
      this.$emit('change', this.params);
    },
    onDecode (result) {
      this.params.scan = result;
      this.params.amId = JSON.parse(result)?.D?.ID;
    },
    getRankItems () {
      const result = [];
      result.push({
        value: null,
        label: 'Non renseigné',
      });
      for (let i = 1; i <= 9; i += 1) {
        result.push({
          value: i,
          label: `Rang ${i}`,
        });
      }
      return result;
    },
    resetPeriod () {
      this.params.period = {
        typeDate: null,
        startDate: null,
        endDate: null,
        labelTag: null,
      };
    },
    showError (e) {
      this.setViolations(e, this.$refs.observer);
      this.showMenu = true;
    },
    openBarcodeReaderModal () {
      const { id } = NovaTools.modal.append(BarcodeReaderModal, {
        isOpen: true,
        barcodeFormat: this.barCodeFormat,
      }, {
        onSuccessDecode: (result) => {
          this.onDecode(result);
        },
        cancel: () => NovaTools.modal.destroy(id),
      });
    },
  },
};
</script>
<style scoped lang="scss">
.search-prescription-filter {
  &__parameters {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 8px;

    &__item {
      width: 200px;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      width: 624px;
      height: 280px;
      max-height: 600px;
      background-color: white;
      padding: 12px 16px;
      gap: 12px
    }
  }

  &__tags {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>