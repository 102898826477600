import prepareVuetify from './src/plugins/vendors/vuetify'

import * as services from './src/services'
import * as directives from './src/directives'
import Vuetify from 'vuetify'

const moduleDependencies = []

/**
 * Permet de récupérer le dictionnaire avec l'ensemble des modules utilisés par le framework
 * @returns {Object} les modules classés par nom
 */
const mapDependenciesInstances = () => moduleDependencies

/**
 * Installe le framework
 * @param {Object} Vue l'instance de VueJs sur laquelle installer le framework
 * @param {Object} options les options d'installation du framework
 * @see {@link https://fr.vuejs.org/v2/guide/plugins.html#Ecrire-un-plugin}
 */
const install = (Vue, options = {}) => {
  moduleDependencies.vuetify = prepareVuetify(options)
  Object.values(services).forEach(service => Vue.use(service))
  Object.values(directives).forEach(directive => Vue.use(directive))
  Vuetify.install(Vue)
}

// Exportation du module installable
export default {
  install,
  mapDependenciesInstances,
}