import { PRACTITIONER, MEDICAL_SECRETARY, FINANCIAL_SECRETARY } from '@/constants/userProfiles';
import { ROUTE_NAMES as SCHEDULE_ROUTE_NAMES } from '@/modules/agenda/constants';
import { ROUTE_NAMES as DOCUMENT_ROUTE_NAMES } from '@/modules/documents/constants';
import { ROUTE_NAMES as PATIENT_ROUTE_NAMES } from '@/modules/patient/constants';
import { ROUTE_NAMES as SECURE_MESSAGING_ROUTE_NAMES } from '@/modules/secureMessaging/constants';
import { getEnv } from '@/utils/functions/env';

export default Object.freeze([{
  title: 'Mon compte',
  name: 'account',
  icon: 'user',
  links: [
    {
      to: { name: 'settings.account' },
      text: 'Général',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY, FINANCIAL_SECRETARY],
    },
    {
      to: { name: 'settings.psc' },
      text: 'Pro Santé Connect',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY, FINANCIAL_SECRETARY],
    },
  ],
},
{
  title: 'Espace Patient',
  name: 'patientSpace',
  icon: 'users',
  links: [
    {
      to: { name: 'settings.patientspace' },
      text: 'Général',
      have_access: [PRACTITIONER],
      disabled: getEnv('VUE_APP_PATIENT_SPACE_ACTIVATION') !== 'true',
    },
  ],
},
{
  title: 'Agenda',
  name: 'agenda',
  icon: 'schedule',
  links: [
    {
      to: { name: SCHEDULE_ROUTE_NAMES.AGENDA_LIST },
      text: 'Agendas',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: SCHEDULE_ROUTE_NAMES.ADENDA_MOTIVES_LIST },
      text: 'Motifs',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: SCHEDULE_ROUTE_NAMES.ADENDA_AVAILABILITIES_LIST },
      text: 'Disponibilités',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
  ],
},
{
  title: 'Patient',
  name: 'patient',
  icon: 'ins',
  links: [
    {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_IDENTITY_GENERAL },
      text: 'Général',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_RGPD },
      text: 'RGPD',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    }, {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_VSM_ALERT_SETTINGS },
      text: 'Alertes VSM',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_IDENTITY_DEVICE_SETTINGS },
      text: 'Dispositifs d\'identité',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_KEYWORDS_SETTINGS },
      text: 'Mots-clefs',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: PATIENT_ROUTE_NAMES.PATIENT_DOCUMENTS_EXPORT },
      text: 'Portabilité des documents',
      have_access: [PRACTITIONER],
    },
  ],
},
{
  title: 'Consultation',
  name: 'consultation',
  icon: 'consultation',
  links: [
    {
      to: { name: PATIENT_ROUTE_NAMES.CONSULTATION_METRIC_SETTINGS },
      text: 'Métriques',
      have_access: [PRACTITIONER],
    },
  ],
},
{
  title: 'Messagerie sécurisée',
  name: 'secureMessaging',
  icon: 'email',
  links: [
    {
      to: { name: SECURE_MESSAGING_ROUTE_NAMES.CONFIGURATION_SETTINGS },
      text: 'Général',
      have_access: [PRACTITIONER],
    },
    {
      to: { name: SECURE_MESSAGING_ROUTE_NAMES.TRUSTED_SENDERS },
      text: 'Contrats de confiance',
      have_access: [PRACTITIONER],
    },
  ],
},
{
  title: 'Documents',
  name: 'documents',
  icon: 'folder',
  links: [
    {
      to: { name: DOCUMENT_ROUTE_NAMES.TEMPLATE_SETTINGS },
      text: 'Modèles',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: DOCUMENT_ROUTE_NAMES.HEADER_SETTINGS },
      text: 'Entêtes',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
    {
      to: { name: DOCUMENT_ROUTE_NAMES.FOOTER_SETTINGS },
      text: 'Pieds de page',
      have_access: [PRACTITIONER, MEDICAL_SECRETARY],
    },
  ],
},
{
  title: 'Ordonnances LAP/Documents structurés',
  name: 'prescription',
  icon: 'prescription',
  links: [
    {
      to: { name: DOCUMENT_ROUTE_NAMES.PRESCRIPTION_SETTINGS },
      text: 'Paramétrage',
      have_access: [PRACTITIONER],
    },
    {
      to: { name: DOCUMENT_ROUTE_NAMES.PRESCRIPTION_HEADER_SETTINGS },
      text: 'Entêtes',
      have_access: [PRACTITIONER],
    },
  ],
},
]);