import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';

/**
 * Permet de récupérer un objet PatientPathology à partir d'un objet Synapse PathologyV2
 * @param {Object} pathology
 * @returns {Object}
 */
export const asPatientPathology = pathology => new PatientPathology({
  label: pathology.identifier.display,
  icd10Code: pathology.identifier.code,
});

/**
 * Permet de récupérer un objet Synapse PathologyV2 à partir d'un objet PatientPathology
 * @param {Object} pathology
 * @returns {Object}
 */
export const asSynapsePathologyObject = pathology => ({
  identifier: {
    code: pathology.icd10Code,
    display: pathology.label,
    system: 'cim-10',
  },
});

export const getSynapsePathologies = pathologies => pathologies.map(pathology => asSynapsePathologyObject(pathology));