


import GrandRegime from '@/models/GrandRegime';
import Patient from '@/modules/patient/models/Patient';
import { getFromAPI } from '@/services/api';
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader';
import { getGenderFromSexCode, getInsuredBirthDate } from '@/services/vendors/icanopee/utils/dataNormalizer';
import { getCivilityFromGender } from '@/utils/functions/refs';
import { capitalize } from '@/utils/functions/words';

/**
 * @typedef {Object} ApCvType
 * @property {Patient} patient
 * @property {Object} practitioner
 */
class ApCv {

  grandRegimes = [];

  /**
   * @param {ApCvType} options
   */
  constructor (options = {}) {
    if (! options.patient || ! options.practitioner) {
      throw new Error('Un patient et un praticien sont nécessaires', options.patient, options.practitioner);
    }
    this.patient = options.patient;
    this.practitioner = options.practitioner;
  }

  /**
   * Permet de s'authentifier avec un profil ApCV (QRCode)
   * @param {Objet} apCvProfile
   * @returns {Objet} les données récoltées de l'ApCV
   */
  async authenticate (apCvProfile, pincode) {
    const { data: softwareInformations } = await getFromAPI('/api/software_information');

    const data = await iCanopeeCardReaderInstance.getApCvContext(softwareInformations, apCvProfile, pincode);
    if (! data || data.s_status !== 'OK') {
      throw new Error(data?.s_apiErrorExtendedInformations || '');
    }

    if (data.User) {
      if (this.grandRegimes.length === 0) {
        this.grandRegimes = await GrandRegime.fetchAll();
      }

      const insureds = [];
      insureds.push(this.formatApCvPatient(data.User, true));
      data.Wallet.forEach((otherInsureds) => {
        insureds.push(this.formatApCvPatient(otherInsureds));
      });
      return {
        insureds,
        data,
      };
    }
    return null;
  }

  /**
   * Permet de détruire le profil ApCV
   */
  async destroy () {
    await iCanopeeCardReaderInstance.releaseApCvContext();
  }

  formatApCvPatient (patient, isMainInsured = false) {
    const gender = getGenderFromSexCode(patient.Identity.i_sex);
    const civility = getCivilityFromGender(gender);
    const insuredOrganism = patient.VitaleData.HealthCoverage;
    const nirInfos = patient.VitaleData.NirData.CertifiedNir;
    const birthDate = getInsuredBirthDate(patient.VitaleData.s_birthday);

    return {
      isMainInsured,
      patient: new Patient({
        firstName: capitalize(patient.VitaleData.s_givenName, true),
        firstNames: capitalize(patient.Identity.s_birthGiven, true),
        birthName: capitalize(patient.VitaleData.s_birthName, true),
        familyName: capitalize(patient.VitaleData.s_name, true),
        birthDate,
        gender,
        civility,
        nir: nirInfos.s_nir.concat('', nirInfos.s_key),
        billDataset: {
          birthRank: patient.VitaleData.i_rank,
          fund: insuredOrganism.s_desk,
          center: insuredOrganism.s_center,
          grandRegime: this.grandRegimes.find(grandRegime => grandRegime.code === insuredOrganism.s_regime),
          birthDate,
          quality: patient.VitaleData.i_qualBenef,
        },
      }),
    };
  }

}

export default ApCv;