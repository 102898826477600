<template>
  <validation-provider
    v-slot="{ valid, validated }"
    :ref="validatorName"
    slim
    :rules="rules"
  >
    <section
      class="app-wysiwyg"
      :class="{ 'app-wysiwyg--invalid': ! valid && validated }"
    >
      <section class="app-wysiwyg__editor">
        <app-wysiwyg
          :ref="editorName"
          v-model="localValue"
          :config="config"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <section :class="toolbarClass" />
      </section>
      <app-textarea-field
        :ref="textareaName"
        v-model="localValue"
        :label="label"
        :rules="rules"
        :rows="8"
        auto-grow
        class="app-wysiwyg__textarea mt-0 pt-0"
      />
    </section>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';
import AppWysiwyg from '@/components/ui/wysiwyg/AppWysiwyg.vue';



export default {
  name: 'AppWysiwygField',
  components: {
    AppTextareaField,
    AppWysiwyg,
    ValidationProvider,
  },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: [Object, String],
      default: null,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Contenu',
    },
    editorName: {
      type: String,
      default: 'editor',
    },
  },
  data () {
    return {
      config: {
        menubar: false,
        inline: true,
        auto_focus: this.autoFocus,
        plugins: 'advlist autolink lists link charmap visualblocks code fullscreen textcolor',
        toolbar: 'fontsizeselect | bold italic underline forecolor alignleft aligncenter alignright alignjustify',
        toolbar_persist: true,
        fontsize_formats: '36px 28px 24px 20px 18px 16px 14px 12px 10px 8px',
        fixed_toolbar_container: `.app-wysiwyg__editor__toolbar__${this.editorName}`,
      },
    };
  },
  computed: {
    toolbarClass () {
      return `app-wysiwyg__editor__toolbar app-wysiwyg__editor__toolbar__${this.editorName}`;
    },
    textareaName () {
      return `textarea${this.editorName === 'editor' ? '' : `-${this.editorName}`}`;
    },
    validatorName () {
      return `validator${this.editorName === 'editor' ? '' : `-${this.editorName}`}`;
    },
  },
  watch: {
    localValue (localValue) {
      /**
       * Force le vidage du body en cas de reset du formulaire
       */
      if (! localValue) {
        this.$refs[this.editorName]?.editor?.resetContent();
      }
    },
  },
  methods: {
    handleFocus () {
      this.$refs[this.textareaName].$refs.input.isFocused = true;
    },
    handleBlur () {
      if (this.editorName === 'editor') {
        this.$refs.textarea.focus();
      }
      this.$nextTick(() => {
        this.$refs[this.textareaName].blur();
        this.$refs[this.textareaName].$refs.input.isFocused = false;
        this.$refs[this.validatorName].validate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$field-details-height: map-get($spacers, 4);
$field-details-padding-top: map-get($spacers, 2);

::v-deep {
  .v-input {
    &__slot {
      pointer-events: none;
      margin-bottom: 0;
    }

    &__control {
      flex-wrap: nowrap;
    }

    &__control,
    &__slot {
      height: 100%;
    }
  }

  .v-text-field {
    &__slot {
      height: 100%;
    }
    &__details {
      padding-top: $field-details-padding-top;
    }
  }

  .v-messages {
    &__message {
      line-height: $field-details-height;
    }
  }

  textarea {
    opacity: 0;
  }
}

.app-wysiwyg {
  display: flex;
  font-size: 13px;
  color: initial;
  position: relative;
  margin-bottom: 0;
  flex-grow: 1;
  transition: margin-bottom .3s ease;

  &--invalid &__editor {
    margin-bottom: $field-details-height + $field-details-padding-top;
  }

  &__editor {
    position: relative;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 3);
    padding: map-get($spacers, 4) 0;
    z-index: 10;

    &__toolbar {
      position: sticky;
      bottom: 0;
      margin-top: auto;
    }

    ::v-deep {
      .mce-content-body {
        flex: 1 0 auto;
        outline: none;
        height: 100%;
      }
    }
  }

  &__textarea {
    position: absolute;
    top: map-get($spacers, 3);
    left: 0;
    width: 100%;
    height: calc(100% - #{map-get($spacers, 3)});
    pointer-events: none;
  }
}
</style>