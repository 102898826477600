import ApiEntity from '@/models/ApiEntity';

export default class PatientHealthEntity extends ApiEntity {
  constructor (options = {}) {
    super(options);

    this.patient = options.patient || null;
    this.label = options.label || null;
    this.note = options.note || null;
  }
}