import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument';
import EfficienceCommon from '@/nova-tools/icanopee/efficience/efficience_common';
import NovaTools from '@/nova-tools/NovaTools';
import { getEnv } from '@/utils/functions/env';

import EfficienceModalBackground from '@/nova-tools/icanopee/efficience/background/EfficienceModalBackground.vue';

const REFRESH_MESSAGES_DELAY_S = 3600; // Toutes les heures
class EfficienceBackground {
  #modalId = null;

  #query = null;

  openModalBackground (patientIns) {
    if (patientIns) {
      const { id } = NovaTools.modal.append(EfficienceModalBackground, {
        showModal: true,
        url: EfficienceCommon.getEfficienceIframePatientUrl(patientIns),
      });
      this.#modalId = id;
      return id;
    }
    return false;
  }

  closeModalBackground () {
    if (this.#modalId) {
      NovaTools.modal.destroy(this.#modalId);
    }
    this.#modalId = null;
    this.#query = null;
  }

  async postMessageToEfficienceInBackground (query) {
    if (query) {
      this.#query = query;
      if (this.getEfficienceIframeBackground()) {
        await this.getEfficienceIframeBackground().postMessage(
          JSON.stringify({ remoteCommand: NovaTools.base64.encode(JSON.stringify(query)) }),
          '*',
        );
      }
    }
  }

  getEfficienceIframeBackground () {
    const iframe = document.getElementById('modal-efficience-background');
    if (iframe) {
      return iframe.contentWindow;
    }
    // this.closeModalBackground()
    return false;

  }

  async sendDocument (icanopeeDocument, relatesTo) {
    let contentBase64;
    if (! icanopeeDocument.fileContentBase64) {
      contentBase64 = await NovaTools.icanopee.efficience.getBase64Content(icanopeeDocument.contentUrl);
    } else {
      contentBase64 = icanopeeDocument.fileContentBase64;
    }
    const params = {
      request: {
        action: 'submitDocument',
        id: relatesTo,
        patient: {
          ins: {
            root: import.meta.env.VUE_APP_ICANOPEE_DMP_CONNECT_JS2_ENVIRONMENT,
            extension: icanopeeDocument.patientIns,
          },
        },
        document: {
          title: icanopeeDocument.title,
          description: icanopeeDocument.title,
          content: contentBase64,
          format: icanopeeDocument.format,
          typeCode: icanopeeDocument.typeCode,
          practice: icanopeeDocument.practice,
        },
        sendInBackground: true,
      },
    };
    if (icanopeeDocument.dmpUniqueId) {
      params.request.document.replacedDocumentUniqueId = icanopeeDocument.dmpUniqueId;
    }
    await this.postMessageToEfficienceInBackground(params);
  }

  async receiveMessageFromEfficienceBackground (origin, data) {
    if (origin === getEnv('VUE_APP_ICANOPEE_EFFICIENCE_BASE_URL')) {
      const responseType = data.type;
      if (responseType === 'waiting_for_pincode_entry') {
        await this.onWaitingPinCode();
      } else if (responseType === 'remoteNotification') {
        const resultat = JSON.parse(NovaTools.base64.decode(data.data));
        const relatesTo = JSON.parse(resultat.answer.RelatesTo);
        const { status } = resultat.answer;
        if (status === 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Failure') {
          await this.onRemoteNotificationFailure(resultat, relatesTo);
        } else if (status === 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success') {
          await this.onRemoteNotificationSuccess(resultat, relatesTo);
        } else {
          this.closeModalBackground();
        }
      } else if (! ['ready_to_receive_commands', 'login'].includes(responseType)) {
        this.closeModalBackground();
      }
    }
  }

  async onWaitingPinCode () {
    if (NovaTools.security.codePin.getCodePin() !== null) {
      await this.getEfficienceIframeBackground().postMessage(
        JSON.stringify({ pinCode: NovaTools.security.codePin.getCodePin() }),
        '*',
      );
    } else {
      this.closeModalBackground();
    }
  }

  async onRemoteNotificationFailure (resultat, relatesTo) {
    await NovaTools.dmp.traceDmpSendFailed(relatesTo.iri, relatesTo.documentType);
    this.closeModalBackground();
    await this.synchroniseNextItemOfQueue();
  }

  async onRemoteNotificationSuccess (result, relatesTo) {
    const { document } = result.answer;
    if (document) {
      const { data } = await NovaTools.dmp.traceDmpSend(relatesTo.iri, relatesTo.documentType, {
        dmpUniqueId: document.uniqueId,
        dmpSyncDate: (new Date()).toISOString(),
      });
      await NovaTools.icanopee.efficience.updateDocumentInStore(data);
      this.closeModalBackground();
      await this.synchroniseNextItemOfQueue();
    }
  }

  /// ////////////////////////////////////////////////////////////////////////////////
  // automate
  /// ////////////////////////////////////////////////////////////////////////////////
  #dmpSyncInterval = null;

  #documentsQueue = [];

  async startSynchro () {
    await this.synchroniseDocumentsInBackground();
    this.#dmpSyncInterval = setInterval(async () => {
      await this.synchroniseDocumentsInBackground();
    }, REFRESH_MESSAGES_DELAY_S * 1000);
  }

  stopSynchro () {
    clearInterval(this.#dmpSyncInterval);
  }

  async synchroniseDocumentsInBackground () {
    try {
      const { data } = await NovaTools.requests.get('/api/dmp_sync_documents');
      this.#documentsQueue = data['hydra:member'];
      await this.synchroniseNextItemOfQueue();
    } catch (error) { /* empty */ }
  }

  async synchroniseNextItemOfQueue () {
    if (this.#documentsQueue.length > 0) {
      const firstQueueItem = this.getFirstItemAndPopsOutQueue();
      const icanopeeDocument = await this.getIcanopeeDocumentFromDocument(firstQueueItem);
      await this.sendDocumentInBackground(icanopeeDocument);
    }
  }

  async sendDocumentInBackground (icanopeeDocument) {
    if (NovaTools.security.codePin.getCodePin() !== null) {
      const modalId = this.openModalBackground(icanopeeDocument.patientIns);
      if (modalId) {
        const relatesTo = JSON.stringify({
          command: 'sendDocumentInBackground',
          iri: icanopeeDocument.iri,
          documentType: icanopeeDocument.documentType,
        });
        await this.sendDocument(icanopeeDocument, relatesTo);
      }
    }
  }

  async getIcanopeeDocumentFromDocument (document) {

    if (! document.patientIns) {
      const { data } = await NovaTools.requests.get(document.consultation);
      document.patientIns = data.patient.insIdentity.ins;
    }
    if (! document.documentType) {
      document.documentType = document['@type'];
    }
    if (! document.documentTitle) {
      document.documentTitle = document.name ? document.name : 'titre non renseigné';
    }

    const { patientIns } = document;
    const contentBase64 = document.fileContentBase64 ?? await NovaTools.icanopee.efficience.getBase64Content(document.contentUrl);
    return new IcanopeeDocument(patientIns, {
      title: document.documentTitle,
      fileContentBase64: contentBase64,
      format: 'pdf',
      typeCode: document.category ?? '11488-4',
      practice: '07',
      documentType: document.documentType,
      iri: document['@id'],
    });
  }

  getFirstItemAndPopsOutQueue () {
    const firstItem = this.#documentsQueue[0];
    this.#documentsQueue.shift();
    return firstItem;
  }

}
export default new EfficienceBackground();