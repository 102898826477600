<template>
  <v-radio-group
    v-model="localValue"
    :label="label"
    :mandatory="required"
    :row="rowDirection"
    :column="columnDirection"
  >
    <template v-if="$scopedSlots['label']" #label>
      <slot name="label" />
    </template>
    <n-radio-button
      v-for="(item, index) in items"
      :key="index"
      :value="item.value"
      :disabled="item.disabled"
      :disabled-tooltip="item.disabledTooltip"
      :label="capitalize(item.label)"
      :color="item.color"
    />
    <slot name="append" />
  </v-radio-group>
</template>

<script>

/**
 * Composant groupe de boutons radio
 */
import { capitalize } from '@/utils/functions/words';

export default {
  name: 'NRadioButtonGroup',
  props: {
    value: {
      type: [String, Boolean],
      required: false,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    direction: {
      type: String,
      default: 'column',
      validator: direction => ['row', 'column'].includes(direction),
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  data () {
    return { localValue: null };
  },
  computed: {
    rowDirection () {
      return this.direction === 'row';
    },
    columnDirection () {
      return this.direction === 'column';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value;
      },
    },
    localValue () {
      this.$emit('input', this.localValue);
    },
  },
  methods: { capitalize },
};
</script>