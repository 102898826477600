<template>
  <app-modal
    :is-open="isOpen"
    title="Scanner un code-barres"
  >
    <barcode-reader
      v-test="'barcode-reader'"
      :barcode-format="barcodeFormat"
      @scan-success="onDecode"
    />
    <template #actions>
      <n-button
        label="Annuler"
        @click="handleCancel"
      />
    </template>
  </app-modal>
</template>

<script>
import { BarcodeFormat } from '@zxing/library';

import ModalMixin from '@/components/ui/modal/mixins/ModalMixin';

import BarcodeReader from '@/components/ui/barcodeReader/BarcodeReader.vue';
import AppModal from '@/components/ui/modal/AppModal.vue';

export default {
  name: 'BarcodeReaderModal',
  components: {
    AppModal,
    BarcodeReader,
  },
  mixins: [ModalMixin],
  props: {
    /**
     * Type du barcode à scanner
     * @see https://zxing.github.io/zxing/apidocs/com/google/zxing/BarcodeFormat.html#:~:text=EAN%2D8%201D%20format.
     */
    barcodeFormat: {
      type: Number,
      default: BarcodeFormat.DATA_MATRIX,
      validator: format => Object.keys(BarcodeFormat).includes(`${format}`),
    },
  },
  methods: {
    onDecode (result) {
      this.$emit('onSuccessDecode', result);
      this.handleCancel();
    },
  },
};
</script>