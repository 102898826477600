<template>
  <div class="iframe-style">
    <iframe
      id="modal-efficience-background"
      class="iframe-style"
      width="100%"
      loading="lazy"
      :src="url"
    />
  </div>
</template>
<script>
import NovaTools from '@/nova-tools/NovaTools';

export default {
  name: 'EfficienceModalBackground',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  mounted () {
    window.addEventListener('message', this.onMessage);
  },
  destroyed () {
    window.removeEventListener('message', this.onMessage);
  },
  methods: {
    onMessage ({ origin, data }) {
      NovaTools.icanopee.EfficienceBackground.receiveMessageFromEfficienceBackground(origin, data);
    },
  },
};
</script>
<style scoped lang="scss">
  .iframe-style {
    border: none;
    width: 0;
    height: 0;
  }
</style>