/* eslint max-len: 0 */
import { HEALTH_BOOK_AGE_RANGES } from '@/modules/patient/constants/healthBookAgeRanges';

/**
 * Valeurs moyennes de l'ensemble des courbes du carnet de santé en fonction d'une tranche d'age
 * l'écart type en x étant inconnu, normalDistributionRates représente une estimation des courbes
 * adjacentes à la moyenne permettant ainsi leurs générations.
 */
export const HEALTH_BOOK_AVERAGE_DATA = {
  FEMALE: {
    SIZE: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        label: 'Taille des filles de 1 mois à 3 ans',
        distributionRates: [0.039, 0.075, 0.11],
        distributionUnit: '',
        data: [[50, [53, 56.3, 59.3, 62, 64.1, 66, 67.5, 69, 70.5, 71.7, 73, 74.1, 75.3, 76.5, 77.6, 78.7, 79.8, 80.8, 82, 83, 84, 85, 85.9, 86.8, 87.6, 88.4, 89.2, 90, 90.7, 91.4, 92.1, 92.9, 93.5, 94.3, 94.9, 95.5]]],
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        label: 'Taille des filles de 1 an à 18 ans',
        distributionRates: [],
        distributionUnit: 'σ',
        data: [
          [- 3, [66.756, 77.471, 85.124, 90.991, 96.348, 101.705, 106.552, 111.144, 115.481, 119.307, 123.389, 129.256, 136.399, 141.246, 143.287, 144.307, 144.817, 145.073]],
          [- 2, [69.052, 80.532, 88.440, 94.817, 100.940, 106.552, 111.654, 116.756, 121.603, 126.195, 131.042, 136.909, 143.287, 147.624, 149.409, 150.685, 151.195, 151.450]],
          [- 1, [71.858, 83.593, 91.756, 98.899, 105.277, 111.399, 117.011, 122.368, 127.726, 132.828, 137.930, 144.307, 150.430, 154.256, 156.042, 157.062, 157.573, 157.828]],
          [0, [74.286, 86.735, 95.510, 102.857, 109.796, 116.327, 122.449, 128.367, 133.878, 139.592, 145.510, 152.041, 157.347, 160.816, 162.449, 163.469, 164.082, 164.490]],
          [1, [76.705, 89.715, 98.899, 107.062, 113.950, 121.093, 127.726, 133.848, 139.970, 146.348, 152.726, 159.613, 164.460, 167.521, 168.797, 170.073, 170.583, 170.838]],
          [2, [79.256, 92.777, 102.726, 110.889, 118.797, 126.450, 132.828, 139.460, 146.093, 152.981, 159.868, 167.011, 171.603, 173.899, 175.175, 176.195, 176.960, 177.215]],
          [3, [81.807, 95.838, 106.297, 114.970, 123.389, 131.042, 138.185, 145.073, 152.470, 159.358, 167.266, 174.664, 178.491, 180.532, 181.552, 182.828, 183.338, 183.593]],
        ],
      },
    },
    WEIGHT: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        label: 'Poids des filles de 1 mois à 3 ans',
        distributionRates: [],
        distributionUnit: '%',
        data: [
          [1, [3, 3.75, 4.25, 4.75, 5.25, 5.6, 5.95, 6.25, 6.5, 6.75, 7, 7.2, 7.4, 7.6, 7.75, 7.95, 8.1, 8.25, 8.45, 8.6, 8.75, 8.9, 9.05, 9.2, 9.3, 9.45, 9.6, 9.75, 9.85, 10, 10.1, 10.2, 10.35, 10.5, 10.6, 10.75]],
          [3, [3.25, 3.95, 4.55, 5.05, 5.5, 5.95, 6.25, 6.55, 6.85, 7.1, 7.35, 7.55, 7.8, 8, 8.2, 8.35, 8.5, 8.75, 8.9, 9, 9.15, 9.3, 9.5, 9.65, 9.8, 9.95, 10.1, 10.25, 10.4, 10.5, 10.6, 10.75, 10.9, 11.05, 11.2, 11.4]],
          [10, [3.5, 4.2, 4.95, 5.5, 6, 6.4, 6.75, 7.05, 7.3, 7.55, 7.8, 8.05, 8.25, 8.5, 8.75, 8.95, 9.05, 9.25, 9.45, 9.6, 9.8, 10, 10.1, 10.25, 10.5, 10.6, 10.8, 10.95, 11.1, 11.25, 11.4, 11.5, 11.7, 11.85, 12, 12.1]],
          [25, [3.8, 4.6, 5.3, 5.8, 6.4, 6.8, 7.2, 7.5, 7.8, 8.1, 8.4, 8.6, 8.9, 9.05, 9.25, 9.5, 9.7, 9.9, 10.05, 10.25, 10.45, 10.6, 10.8, 11, 11.2, 11.4, 11.55, 11.75, 11.9, 12, 12.2, 12.4, 12.5, 12.7, 12.85, 13]],
          [50, [4.1, 5, 5.7, 6.3, 6.8, 7.3, 7.75, 8.1, 8.45, 8.75, 9, 9.25, 9.5, 9.75, 10, 10.2, 10.4, 10.6, 10.8, 11, 11.25, 11.45, 11.6, 11.8, 12, 12.25, 12.45, 12.6, 12.8, 13, 13.2, 13.35, 13.5, 13.65, 13.8, 14]],
          [75, [4.4, 5.25, 6.1, 6.75, 7.35, 7.85, 8.3, 8.75, 9.05, 9.4, 9.7, 10, 10.2, 10.45, 10.7, 10.9, 11.2, 11.4, 11.6, 11.8, 12.05, 12.25, 12.5, 12.75, 13, 13.2, 13.45, 13.6, 13.8, 14, 14.2, 14.4, 14.6, 14.8, 15, 15.2]],
          [90, [4.75, 5.6, 6.5, 7.2, 7.8, 8.4, 8.9, 9.25, 9.6, 10, 10.35, 10.65, 10.95, 11.25, 11.5, 11.7, 11.9, 12.2, 12.45, 12.7, 13, 13.2, 13.5, 13.7, 14, 14.2, 14.4, 14.6, 14.9, 15.1, 15.35, 15.55, 15.8, 16, 16.2, 16.45]],
          [97, [5, 6, 6.9, 7.7, 8.4, 9, 9.5, 9.95, 10.35, 10.7, 11, 11.3, 11.6, 11.9, 12.2, 12.5, 12.8, 13.1, 13.4, 13.65, 13.9, 14.2, 14.5, 14.75, 15.05, 15.3, 15.6, 15.85, 16.15, 16.45, 16.65, 16.9, 17.2, 17.4, 17.6, 17.9]],
          [99, [5.2, 6.25, 7.2, 8, 8.75, 9.4, 10, 10.45, 10.85, 11.25, 11.6, 11.95, 12.25, 12.55, 12.85, 13.2, 13.5, 13.8, 14.1, 14.4, 14.7, 15, 15.3, 15.6, 15.9, 16.2, 16.5, 16.8, 17.15, 17.45, 17.75, 18, 18.3, 18.6, 18.85, 19.1]],
        ],
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        label: 'Poids des filles de 1 an à 18 ans',
        distributionRates: [],
        distributionUnit: '%',
        data: [
          [1, [7.179, 9.231, 10.769, 12.308, 13.846, 15.128, 16.923, 18.205, 20.256, 21.795, 23.590, 26.154, 29.231, 32.821, 35.641, 37.692, 39.231, 40.256]],
          [3, [7.436, 9.487, 11.282, 12.821, 14.615, 16.154, 17.949, 19.487, 21.538, 23.333, 25.641, 28.718, 32.308, 35.897, 38.974, 40.769, 42.051, 42.821]],
          [10, [8.205, 10.256, 12.051, 14.103, 15.641, 17.436, 19.231, 21.282, 23.590, 25.897, 28.462, 31.795, 35.897, 40.256, 43.077, 44.872, 46.154, 46.667]],
          [25, [8.718, 11.026, 12.821, 15.128, 16.923, 18.718, 21.026, 23.333, 25.897, 28.718, 31.795, 35.897, 40.769, 44.872, 47.692, 49.487, 50.513, 51.282]],
          [50, [9.231, 12.051, 14.103, 16.154, 18.205, 20.769, 23.077, 25.897, 29.231, 32.564, 36.154, 41.026, 45.897, 49.744, 52.564, 54.359, 55.641, 56.410]],
          [75, [10, 12.564, 15.128, 17.692, 20, 22.821, 25.641, 29.231, 33.333, 37.179, 41.795, 46.667, 51.795, 55.897, 58.205, 60, 61.538, 62.821]],
          [90, [10.769, 13.590, 16.410, 18.974, 22.051, 25.128, 28.718, 32.821, 37.692, 42.308, 47.692, 53.590, 59.231, 63.077, 65.385, 67.179, 68.718, 70.513]],
          [97, [11.538, 14.872, 17.949, 21.026, 24.359, 28.205, 32.564, 37.436, 43.077, 48.462, 54.615, 62.051, 68.205, 72.308, 74.615, 76.667, 78.718, 80.769]],
          [99, [12.051, 15.641, 18.974, 22.821, 26.923, 30.769, 35.897, 42.051, 48.205, 54.359, 61.538, 68.974, 76.667, 81.538, 83.846, 85.897, 88.462, 90.769]],
        ],
      },
    },
    HEADCIRC: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD_EXTENDED.categoryName]: {
        label: 'Périmètre crânien des filles de 1 mois à 5 ans',
        distributionRates: [0.028, 0.056, 0.084],
        distributionUnit: '',
        data: [[50, [36.9, 38.5, 40, 41, 42, 42.9, 43.6, 44.2, 44.7, 45.1, 45.5, 45.8, 46.1, 46.4, 46.6, 46.85, 47.05, 47.3, 47.5, 47.65, 47.85, 48, 48.15, 48.25, 48.4, 48.5, 48.6, 48.7, 48.8, 48.9, 49, 49.05, 49.15, 49.25, 49.35, 49.45, 49.5, 49.55, 49.6, 49.65, 49.7, 49.75, 49.8, 49.85, 49.9, 49.95, 49.99, 50.05, 50.1, 50.15, 50.2, 50.25, 50.3, 50.35, 50.4, 50.45, 50.48, 50.5, 50.55, 50.6]]],
      },
    },
  },
  MALE: {
    SIZE: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        label: 'Taille des garçons de 1 mois à 3 ans',
        distributionRates: [0.0365, 0.072, 0.114],
        distributionUnit: '',
        data: [[50, [54, 57.5, 60.9, 63.5, 65.8, 67.7, 69.2, 70.6, 72, 73.2, 74.5, 75.8, 76.9, 78, 79, 80.1, 81.1, 82.1, 83.2, 84.2, 85.2, 86.1, 87, 88, 88.9, 89.6, 90.3, 91.1, 91.9, 92.6, 93.2, 94, 94.7, 95.3, 96, 96.7]]],
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        label: 'Taille des garçons de 1 an à 18 ans',
        distributionRates: [],
        distributionUnit: 'σ',
        data: [
          [- 3, [68.462, 78.718, 86.154, 91.795, 97.436, 102.821, 107.692, 112.564, 116.923, 121.282, 125.128, 128.205, 131.795, 137.179, 143.846, 149.231, 153.077, 155.128]],
          [- 2, [70.769, 81.795, 89.487, 96.154, 102.051, 107.692, 112.821, 117.949, 123.077, 127.436, 131.795, 135.641, 140.256, 145.897, 152.564, 157.436, 160.769, 162.051]],
          [- 1, [73.333, 84.615, 93.077, 100, 106.410, 112.308, 118.205, 123.590, 128.974, 133.846, 138.462, 143.333, 148.718, 155.128, 161.282, 165.385, 167.692, 169.487]],
          [0, [75.641, 87.949, 96.667, 103.846, 110.769, 117.436, 123.333, 129.231, 134.872, 140.513, 145.385, 151.026, 157.179, 164.103, 169.487, 173.077, 175.385, 176.410]],
          [1, [78.462, 91.026, 100.513, 107.692, 115.128, 122.308, 128.718, 134.872, 141.026, 146.410, 152.051, 158.205, 165.897, 173.077, 178.462, 181.282, 182.564, 183.590]],
          [2, [80.769, 93.846, 103.846, 112.308, 119.744, 127.179, 134.103, 140.769, 147.179, 153.077, 158.718, 165.641, 174.359, 182.564, 187.179, 189.231, 190, 191.026]],
          [3, [83.333, 97.179, 107.179, 116.154, 124.615, 132.051, 139.231, 146.154, 152.821, 158.974, 165.385, 173.333, 182.821, 191.538, 195.897, 197.179, 197.436, 197.949]],
        ],
      },
    },
    WEIGHT: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        label: 'Poids des garçons de 1 mois à 3 ans',
        distributionRates: [],
        distributionUnit: '%',
        data: [
          [1, [3, 3.85, 4.55, 5.15, 5.6, 6, 6.4, 6.65, 6.9, 7.2, 7.45, 7.65, 7.8, 8, 8.2, 8.4, 8.55, 8.75, 8.9, 9.05, 9.2, 9.33, 9.49, 9.6, 9.75, 9.85, 10, 10.17, 10.28, 10.45, 10.55, 10.65, 10.8, 10.95, 11.1, 11.2]],
          [3, [3.4, 4.2, 4.9, 5.5, 6, 6.4, 6.75, 7, 7.3, 7.55, 7.8, 8.05, 8.25, 8.5, 8.65, 8.8, 9, 9.2, 9.35, 9.5, 9.65, 9.8, 10, 10.15, 10.25, 10.4, 10.52, 10.65, 10.8, 11, 11.15, 11.25, 11.4, 11.5, 11.6, 11.75]],
          [10, [3.7, 4.6, 5.3, 5.9, 6.4, 6.8, 7.2, 7.55, 7.85, 8.17, 8.4, 8.65, 8.85, 9.1, 9.25, 9.45, 9.6, 9.8, 10, 10.15, 10.3, 10.5, 10.7, 10.8, 11, 11.2, 11.3, 11.45, 11.6, 11.75, 11.9, 12, 12.2, 12.3, 12.5, 12.6]],
          [25, [4, 4.9, 5.75, 6.4, 6.9, 7.3, 7.75, 8.1, 8.45, 8.7, 9, 9.25, 9.45, 9.65, 9.85, 10.1, 10.25, 10.45, 10.65, 10.8, 11, 11.2, 11.4, 11.55, 11.75, 11.9, 12.1, 12.25, 12.45, 12.55, 12.75, 12.9, 13.05, 13.2, 13.35, 13.5]],
          [50, [4.3, 5.3, 6.2, 6.8, 7.4, 7.8, 8.3, 8.6, 9, 9.3, 9.55, 9.85, 10.15, 10.35, 10.55, 10.8, 11, 11.25, 11.45, 11.6, 11.8, 12, 12.2, 12.4, 12.6, 12.8, 12.95, 13.15, 13.3, 13.5, 13.65, 13.8, 14, 14.2, 14.35, 14.5]],
          [75, [4.7, 5.75, 6.55, 7.25, 7.9, 8.45, 8.9, 9.3, 9.7, 10, 10.3, 10.55, 10.8, 11.1, 11.3, 11.55, 11.8, 12, 12.25, 12.5, 12.7, 12.9, 13.15, 13.35, 13.55, 13.75, 14, 14.2, 14.4, 14.55, 14.7, 14.9, 15.1, 15.3, 15.5, 15.7]],
          [90, [5, 6.2, 7.1, 7.8, 8.45, 9, 9.5, 9.9, 10.3, 10.65, 10.95, 11.25, 11.55, 11.8, 12.1, 12.3, 12.6, 12.8, 13.1, 13.3, 13.55, 13.8, 14, 14.25, 14.5, 14.75, 15, 15.2, 15.4, 15.6, 15.8, 16, 16.25, 16.45, 16.6, 16.8]],
          [97, [5.3, 6.5, 7.5, 8.3, 9, 9.6, 10.1, 10.55, 11, 11.4, 11.75, 12.1, 12.4, 12.65, 12.9, 13.2, 13.5, 13.7, 14, 14.2, 14.5, 14.75, 15, 15.3, 15.5, 15.8, 16, 16.3, 16.55, 16.8, 17, 17.25, 17.5, 17.75, 18, 18.2]],
          [99, [5.5, 6.8, 7.8, 8.75, 9.5, 10.05, 10.6, 11.1, 11.55, 12, 12.35, 12.7, 13, 13.3, 13.55, 13.85, 14.15, 14.45, 14.7, 15, 15.225, 15.55, 15.8, 16.15, 16.45, 16.7, 17, 17.25, 17.5, 17.8, 18.1, 18.3, 18.55, 18.8, 19.1, 19.3]],
        ],
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        label: 'Poids des garçons de 1 an à 18 ans',
        distributionRates: [],
        distributionUnit: '%',
        data: [
          [1, [7.5, 9.444, 11.111, 12.5, 14.167, 15.833, 17.222, 19.167, 21.111, 22.778, 24.722, 26.667, 28.889, 32.222, 36.389, 40.556, 43.333, 45]],
          [3, [7.949, 10.256, 11.795, 13.333, 14.872, 16.410, 18.205, 20.000, 22.051, 24.103, 26.154, 28.462, 31.282, 34.872, 39.231, 43.590, 46.667, 48.718]],
          [10, [8.462, 10.769, 12.564, 14.103, 15.897, 17.692, 19.744, 21.795, 23.846, 26.410, 28.718, 31.538, 34.872, 39.231, 44.103, 48.718, 51.795, 53.846]],
          [25, [9.231, 11.538, 13.590, 15.385, 17.179, 19.231, 21.282, 23.590, 26.154, 28.974, 31.795, 35.128, 39.231, 44.359, 49.231, 53.846, 57.179, 59.487]],
          [50, [10.000, 12.500, 14.444, 16.667, 18.889, 21.111, 23.333, 26.389, 29.444, 33.056, 36.389, 40.833, 45.556, 51.389, 56.389, 60.833, 63.889, 66.111]],
          [75, [10.513, 13.333, 15.641, 17.949, 20.256, 22.821, 25.641, 29.231, 33.077, 36.923, 41.282, 46.410, 52.308, 58.462, 63.077, 67.179, 70.256, 72.564]],
          [90, [11.282, 14.359, 16.667, 19.487, 22.051, 25.128, 28.718, 32.821, 37.179, 42.308, 47.179, 52.821, 60.256, 67.179, 71.795, 75.641, 78.718, 82.308]],
          [97, [12.051, 15.385, 18.205, 21.026, 24.359, 28.205, 32.564, 37.436, 43.077, 48.974, 54.615, 62.051, 70.256, 77.436, 82.308, 86.410, 90.513, 96.410]],
          [99, [12.821, 16.154, 19.231, 22.564, 26.154, 31.026, 36.154, 42.051, 48.718, 55.128, 61.795, 68.974, 78.718, 86.923, 92.051, 96.154, 101.795, 111.538]],
        ],
      },
    },
    HEADCIRC: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD_EXTENDED.categoryName]: {
        label: 'Périmètre crânien des garçons de 1 mois à 5 ans',
        distributionRates: [0.028, 0.056, 0.084],
        distributionUnit: '',
        data: [[50, [37.6, 39.4, 40.85, 42, 43, 44, 44.7, 45.3, 45.8, 46.2, 46.6, 47, 47.3, 47.6, 47.8, 48, 48.2, 48.4, 48.6, 48.8, 48.95, 49.1, 49.25, 49.4, 49.5, 49.575, 49.65, 49.75, 49.85, 49.925, 50, 50.1, 50.2, 50.275, 50.35, 50.4, 50.45, 50.5, 50.55, 50.6, 50.65, 50.7, 50.75, 50.8, 50.85, 50.9, 50.95, 51, 51.025, 51.05, 51.1, 51.15, 51.2, 51.25, 51.3, 51.35, 51.4, 51.425, 51.45, 51.5]]],
      },
    },
  },
};