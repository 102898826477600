<template>
  <validation-observer v-slot="{ valid, errors }" slim>
    <aati-form-step
      v-test="'step'"
      class="medical-leave-duration-fields"
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Durée de l'arrêt de travail"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <div class="mb-2">
        <!-- Tableau des durées indicatives -->
        <motive-duration-refs-table
          v-if="showMotiveDurationRefsTable"
          :motive-code="medicalData.motiveCode"
          :motive-label="medicalData.motiveLabel"
          @click="fillDurationField"
        />

        <!-- Erreurs -->
        <app-row v-if="hasErrors(errors)">
          <app-col cols="12" class="pb-0">
            <n-alert transparent type="error" :message="buildAlertMessage(errors)" />
          </app-col>
        </app-row>

        <!-- Dates / durée temps complet TC/TCP ou temps partiel TP -->
        <app-row
          v-test="'main-period-section'"
          class="medical-leave-duration-fields__main-period"
          :class="{ 'mb-1' : !showPartialDates}"
        >
          <app-col v-if="showPartialDates" cols="12" class="pb-0">
            <h3 class="medical-leave-duration-fields__main-period__title">
              Période d'arrêt à temps complet
            </h3>
          </app-col>
          <app-col v-if="showPregnancyDurationAlert" cols="12">
            <n-alert transparent type="info" :message="pregnancyDurationAlertText" />
          </app-col>
          <app-col cols="12" lg="9">
            <app-row class="medical-leave-duration-fields__main-period__dates">
              <app-col cols="6" xl="3">
                <span>À partir du</span>
              </app-col>
              <app-col cols="6" xl="3">
                <app-date-picker
                  v-model="localValue.startDate"
                  v-test="'start-date-picker'"
                  label="Date de début"
                  :min="validStartDateInterval.min"
                  :max="validStartDateInterval.max"
                  :show-label="false"
                  hide-errors
                  :rules="{
                    required: true,
                    afterOrEqualAtmpDate: { atmpDate: medicalData.atmpDate },
                    afterOrEqualThirdPartyAccidentDate: { thirdPartyAccidentDate: backgroundData.thirdPartyAccidentDate }
                  }"
                  @input="calculateDurations"
                />
              </app-col>
              <app-col cols="6" xl="3">
                <span>jusqu'au (inclus)</span>
              </app-col>
              <app-col cols="6" xl="3">
                <app-date-picker
                  v-model="localValue.endDate"
                  label="Date de fin"
                  :min="new Date(localValue.startDate)"
                  :show-label="false"
                  hide-errors
                  rules="required"
                  @input="calculateDurations"
                />
              </app-col>
            </app-row>
          </app-col>
          <app-col cols="12" lg="3">
            <div class="medical-leave-duration-fields__main-period__duration">
              <app-text-field
                ref="duration-input"
                v-model="duration"
                class="medical-leave-duration-fields__main-period__duration__number"
                label="Durée"
                :show-label="false"
                hide-errors
                validation-mode="aggressive"
                :rules="{ numeric: true, max: 3, maxDuration: { maxDuration, maxDurationStr: 'complet' } }"
                @input="calculateEndDate"
              />
              <span>{{ pluralize('jour', duration) }}</span>
            </div>
          </app-col>
        </app-row>

        <!-- Dates / durée temps partiel TCP -->
        <app-row
          v-if="showPartialDates"
          v-test="'second-period-section'"
          class="medical-leave-duration-fields__second-period"
          :class="{ 'mb-1': showPartialDates }"
        >
          <app-col cols="12" class="pb-0">
            <h3 class="medical-leave-duration-fields__second-period__title">
              Période de reprise à temps partiel
              <app-icon
                icon="info"
                class="ml-2"
                color="secondary lighten-1"
                :tooltip="partialDatesTooltipText"
              />
            </h3>
          </app-col>
          <app-col cols="12" lg="9">
            <app-row class="medical-leave-duration-fields__second-period__dates">
              <app-col cols="6" xl="3">
                <span>À partir du</span>
              </app-col>
              <app-col cols="6" xl="3">
                <app-date-picker v-model="startPartialDate" disabled />
              </app-col>
              <app-col cols="6" xl="3">
                <span>jusqu'au (inclus)</span>
              </app-col>
              <app-col cols="6" xl="3">
                <app-date-picker
                  v-model="localValue.endPartialDate"
                  label="Date de fin de reprise à temps partiel"
                  :disabled="!enablePartialDates"
                  :min="new Date(startPartialDate)"
                  :show-label="false"
                  hide-errors
                  rules="required"
                  @input="calculateDurations"
                />
              </app-col>
            </app-row>
          </app-col>
          <app-col cols="12" lg="3">
            <div class="medical-leave-duration-fields__second-period__duration">
              <app-text-field
                ref="partial-duration-input"
                v-model="partialDuration"
                class="medical-leave-duration-fields__second-period__duration__number"
                :integer-only="true"
                :disabled="!enablePartialDates"
                label="Durée de reprise à temps partiel"
                :show-label="false"
                hide-errors
                validation-mode="aggressive"
                :rules="{ numeric: true, max: 3, maxDuration: { maxDuration, maxDurationStr: 'partiel' } }"
                @input="calculateEndPartialDate"
              />
              <span>{{ pluralize('jour', partialDuration) }}</span>
            </div>
          </app-col>
        </app-row>

        <app-row v-if="showStartDateAlert" class="mt-0">
          <app-col cols="12">
            <n-alert transparent type="info" :message="startDateAlertText" />
            <app-text-field
              :value="medicalData.motiveInformation"
              label="Informations complémentaires"
              hint="Attention ! Ce champ est partagé avec le complément d'informations lié au motif."
              :persistent-hint="true"
              :rules="{ 'max': 100, required: showStartDateAlert }"
              hide-errors
              @input="handleMotiveInformationInput"
            />
          </app-col>
        </app-row>
      </div>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import { debounce } from 'lodash';
import { ValidationObserver, extend } from 'vee-validate';

import NovaTools from '@/nova-tools/NovaTools';
import { add, subDays, differenceInMinutes } from '@/utils/functions/dates';
import { pluralize } from '@/utils/functions/words';

import AppDatePicker from '@/components/ui/form/AppDatePicker.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue';
import MotiveDurationRefsTable from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/elements/MotiveDurationRefsTable.vue';




export default {
  name: 'MedicalLeaveDurationFields',
  components: {
    ValidationObserver,
    AatiFormStep,
    AppDatePicker,
    AppTextField,
    MotiveDurationRefsTable,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    medicalData: {
      type: Object,
      default: null,
    },
    backgroundData: {
      type: Object,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 5,
      localValue: null,
      duration: 1,
      partialDuration: 1,
      partialDatesTooltipText: [
        'L\'état de santé de votre patient s\'améliore,',
        'le temps partiel thérapeutique est de nature à favoriser la reprise de travail à temps complet.',
        'Prévenez votre patient que la reprise à temps partiel thérapeutique est soumise à l\'accord de son employeur.',
      ].join(' '),

      pregnancyDurationAlertText: [
        'Le congé pathologique ne peut pas excéder 14 jours au cours de la période prénatale.',
        'Au-delà de cette durée, si l\'état de votre patiente le nécessite,',
        'l\'arrêt sera pris en charge au titre du risque maladie.',
      ].join(' '),

      startDateAlertText: [
        'Veuillez justifier une date de début d\'arrêt différente de la date du jour.\n\n',
        'En application de l\'article L. 321-1 5° du CSS, la constatation médicale de l\'incapacité',
        'physique de continuer ou reprendre le travail intervient le jour de la consultation.',
        'Toutefois, lors de circonstances exceptionnelles démontrées à partir d\'éléments probants',
        '(ex: documents médicolégaux, comme une radiographie datée avec une fracture)',
        'cette date peut être différente.',
        'Indiquez les éléments justifiant votre demande.',
      ].join('\n'),
      maxDurations: {
        initial: 365 - 1, // 1 an - 1 jour
        prolongation: 180 - 1, // 6 mois - 1 jour
      },
      validStartDateInterval: {
        min: subDays(new Date(), 4), // J-4
        max: add(new Date(), { days: 1 }), // J+1
      },
    };
  },
  computed: {
    showPartialDates () {
      return this.medicalData.nature === 'TCP';
    },
    enablePartialDates () {
      return !! this.localValue.endDate;
    },
    startPartialDate () {
      return (this.showPartialDates && this.enablePartialDates)
        ? NovaTools.dates.format(add(NovaTools.dates.newDate(this.localValue.endDate), { days: 1 }), 'yyyy-MM-dd')
        : null;
    },
    showMotiveDurationRefsTable () {
      return this.medicalData.type === 'initial'
        && ['TC', 'TCP'].includes(this.medicalData.nature)
        && ! this.medicalData.pregnancy;
    },
    showPregnancyDurationAlert () {
      return this.medicalData.pregnancy && this.duration > 14;
    },
    showStartDateAlert () {
      return this.localValue.startDate !== NovaTools.dates.format(new Date(), 'yyyy-MM-dd');
    },
    atmpRelatedInvalidStartDate () {
      return !! this.medicalData.atmpDate && this.medicalData.atmpDate > this.localValue.startDate;
    },
    thirdPartyAccidentRelatedInvalidStartDate () {
      return !! this.backgroundData.thirdPartyAccidentDate && this.backgroundData.thirdPartyAccidentDate > this.localValue.startDate;
    },
    maxDuration () {
      return this.maxDurations[this.medicalData.type];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value;
        this.localValue.startDate = this.localValue.startDate ?? NovaTools.dates.format(new Date(), 'yyyy-MM-dd');
        this.calculateDurations();
      },
    },
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', this.localValue);
      },
    },
  },
  methods: {
    pluralize,
    calculateEndDate: debounce(function handleEndDateCalculation(duration) {
      if (! duration) {
        this.localValue.endDate = null;
        return;
      }
      const start = NovaTools.dates.newDate(this.localValue.startDate);
      const end = subDays(add(start, { days: duration }), 1); // last day included
      this.localValue.endDate = NovaTools.dates.format(end, 'yyyy-MM-dd');
    }, 800),
    calculateEndPartialDate: debounce(function handleEndPartialDateCalculation(duration) {
      if (! duration) {
        this.localValue.endPartialDate = null;
        return;
      }
      const start = NovaTools.dates.newDate(this.startPartialDate);
      const end = subDays(add(start, { days: duration }), 1); // last day included
      this.localValue.endPartialDate = NovaTools.dates.format(end, 'yyyy-MM-dd');
    }, 800),
    calculateDurations () {
      this.calculateDuration();
      this.calculatePartialDuration();
    },
    calculateDuration () {
      if (! (this.localValue.startDate && this.localValue.endDate && this.localValue.startDate <= this.localValue.endDate)) {
        this.duration = null;
        return;
      }
      const start = NovaTools.dates.newDate(this.localValue.startDate);
      const end = add(NovaTools.dates.newDate(this.localValue.endDate), { days: 1 }); // last day included
      this.duration = Math.ceil(differenceInMinutes(end, start) / (60 * 24));
      this.$refs['duration-input']?.validate();
    },
    calculatePartialDuration () {
      if (! (this.startPartialDate && this.localValue.endPartialDate && this.startPartialDate <= this.localValue.endPartialDate)) {
        this.partialDuration = null;
        return;
      }
      const start = NovaTools.dates.newDate(this.startPartialDate);
      const end = add(NovaTools.dates.newDate(this.localValue.endPartialDate), { days: 1 }); // last day included
      this.partialDuration = Math.ceil(differenceInMinutes(end, start) / (60 * 24));
      this.$refs['partial-duration-input']?.validate();
    },
    hasErrors (errors) {
      return !! Object.keys(errors).find(name => errors[name].length > 0);
    },
    buildAlertMessage (errors) {
      const message = [];
      Object.keys(errors).forEach(name => {
        errors[name].forEach(error => {
          message.push(error);
        });
      });
      return message.join('\r\n\n');
    },
    handleMotiveInformationInput (information) {
      this.$emit('update:medical-data', {
        ...this.medicalData,
        motiveInformation: information,
      });
    },
    fillDurationField (durationTableValue) {
      this.duration = durationTableValue;
    },
  },
};
extend('afterOrEqualAtmpDate', {
  validate (value, { atmpDate }) {
    if (! atmpDate) {
      return true;
    }
    const valueDateString = value.split('/').reverse().join('-');
    const formattedValue = new Date(valueDateString);
    const formattedAtmpDate = new Date(atmpDate);
    return formattedValue < formattedAtmpDate ? 'La date de début d\'arrêt doit être postérieure ou égale à la date de l\'ATMP' : true;
  },
  params: ['atmpDate'],
});
extend('afterOrEqualThirdPartyAccidentDate', {
  validate (value, { thirdPartyAccidentDate }) {
    if (! thirdPartyAccidentDate) {
      return true;
    }
    const valueDateString = value.split('/').reverse().join('-');
    const formattedValue = new Date(valueDateString);
    const formattedAccidentDate = new Date(thirdPartyAccidentDate);
    return formattedValue < formattedAccidentDate
      ? 'La date de début de l\'arrêt doit être égale ou postérieure à la date de l\'accident causé par un tiers'
      : true;
  },
  params: ['thirdPartyAccidentDate'],
});
extend('maxDuration', {
  validate (value, { maxDuration, maxDurationStr }) {
    return value > maxDuration
      ? `La durée de l'arrêt de travail à temps ${maxDurationStr} ne peut pas excéder 1 an pour un avis initial, et 6 mois pour une prolongation.`
      : true;
  },
  params: ['maxDuration', 'maxDurationStr'],
});
</script>
<style lang="scss" scoped>
.medical-leave-duration-fields {
  &__main-period,
  &__second-period {

    &__title {
      font-size: 14px;
      color: var(--v-secondary-base);
    }

    &__duration {
      display: flex;
      gap: map-get($spacers, 4);
      align-items: center;
      padding: 10px;
      height: 100%;
    }

    &__dates {
      margin: 0;
      align-items: center;

      ::v-deep {
        .app-date-picker__field {
          padding-top: 0;
          max-width: 150px;
        }
      }
    }

    &__duration__number {
      padding-top: 0;
      width: 50px;
    }
  }

  &__main-period {
    &__dates,
    &__duration {
      background-color: var(--v-primary-lighten5);
    }
  }

  &__second-period {
    &__dates,
    &__duration {
      background-color: var(--v-secondary-lighten5);
    }
  }
}
</style>