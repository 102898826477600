import { defaultEntityColor } from '@/constants/entityColors';

class Motive {
  constructor (options = {}) {
    this['@id'] = options['@id'] || null;
    this.name = options.name || null;
    this.duration = options.duration || 15;
    this.color = options.color || defaultEntityColor;
    this.practitionerSchedules = options.practitionerSchedules || [];
  }
}

export default Motive;