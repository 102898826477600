<template>
  <section>
    <div v-if="freeAppointmentSlots.length" key="with-slot" class="mb-6">
      <p v-test="'first-appointment-slot-date'" class="free-appointment-slot-list__intro mb-0">
        Premier créneau libre le <b>{{ firstFreeAppointmentSlot.date }}</b>
      </p>
      <p v-test="'first-appointment-slot-motive'" class="free-appointment-slot-list__intro mb-2">
        Pour le motif <b>{{ firstFreeAppointmentSlot.motive }}</b>
      </p>
      <app-link
        v-if="displayedSlots.length === 0"
        v-test="'go-to-next-free-appointment-slot'"
        icon="clock"
        @click="goToNextFreeAppointmentSlots"
      >
        {{ noFuturSlot ? 'Revenir au dernier créneau disponible' : 'Aller au prochain créneau libre' }}
      </app-link>
      <p v-if="displayedSlots.length === 0 && noFuturSlot" class="error--text">
        Plus aucun créneau disponible pour ce motif à partir de cette date
      </p>
    </div>
    <p
      v-else
      key="no-slot"
    >
      Aucun créneau disponible
    </p>
    <div
      v-if="freeAppointmentSlots.length"
      class="d-flex"
    >
      <app-button
        v-if="displayedPrevious"
        v-test="'back-button-navigation'"
        icon="left"
        @click="goToPreviousPage()"
      />
      <div v-else class="free-appointment-slot-list__skeleton-button" />
      <v-row class="mt-0">
        <v-col
          v-for="([dayName, daySlots], dayIndex) in Array.from(allSlotsByDate)"
          :key="'day' + dayIndex"
          v-test="'appointment-slots-day-col'"
          class="pt-0"
          cols="3"
        >
          <span
            v-test="'appointment-slots-day'"
            class="free-appointment-slot-list__slots-day mb-4"
            :class="{ 'free-appointment-slot-list__slots-day--empty': isDaySlotsEmpty(daySlots) }"
          >
            <p class="mb-0">{{ dayName.split(' ')[0] }}&nbsp;</p>
            <p class="mb-0">{{ dayName.split(' ').slice(1).join(' ') }}</p>
          </span>
          <div class="free-appointment-slot-list__slots">
            <app-tag
              v-for="(slot, slotIndex) in daySlots.slice(0, slotsAmountByCol)"
              :key="'slot' + slotIndex"
              v-test="'appointment-slot'"
              class="free-appointment-slot-list__slot mb-2"
              small
              label
              color="primary"
              text-color="content"
              :class="{ 'free-appointment-slot-list__slot--empty': (!slot || isLoading) }"
              @click="$emit('time-click', slot)"
            >
              {{ (slot && !isLoading) ? slot.getTime() : '-' }}
            </app-tag>
          </div>
        </v-col>
        <v-col class="d-flex justify-center">
          <app-link
            v-if="totalDisplayedSlots < displayedSlots.length"
            v-test="'display-all-slots-link'"
            @click="displayAllSlots"
          >
            Afficher l'ensemble des créneaux
          </app-link>
          <p v-else v-test="'appointment-slots-message'" class="secondary--text text--lighten-4">
            {{ isLoading ? 'Récupération des créneaux...' : (displayedSlots.length === 0 && freeAppointmentSlots.length
              === 1 ? 'Aucun créneau disponible pour les 4 prochains jours' : 'Aucun créneau supplémentaire à afficher')
            }}
          </p>
        </v-col>
      </v-row>
      <app-button
        v-test="'next-button-navigation'"
        :disabled="noFuturSlot"
        icon="right"
        @click="goToNextPage()"
      />
    </div>
  </section>
</template>

<script>

import { max } from 'lodash';
import { mapGetters } from 'vuex';

import FreeAppointmentSlot from '@/modules/patient/models/medicalHistory/FreeAppointmentSlot';
import NovaTools from '@/nova-tools/NovaTools';
import { isSameDay } from '@/utils/functions/dates';

import AppTag from '@/components/ui/tag/AppTag.vue';

const MIN_SLOTS_AMOUNT = 4;
/**
 * Planning des créneaux disponibles
 */
export default {
  name: 'FreeAppointmentSlotList',
  components: { AppTag },
  props: {
    /**
     * Liste des créneaux disponible fournit par l'API
     */
    freeAppointmentSlots: {
      type: Array,
      default: () => [],
    },
    /**
     * Permet d'éffectuer la recherche à partir d'une date spécifique demandé par le client
     * @default date en cours ( new Date() )
     */
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    /**
     * Date du premier créneau disponible à partir de "startDate"
     * @default null
     */
    firstFreeSlotDate: {
      type: Date,
      default: null,
    },
    /**
     * Permet de mettre le composant en état d'attente de récupération des données
     * @default null
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de renseigner le composant que plus aucun créneau n'est disponible pour les dates à venir
     * @default false
     */
    noFuturSlot: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      slotsAmountByCol: MIN_SLOTS_AMOUNT,
      firstDate: this.startDate,
    };
  },
  computed: {
    ...mapGetters('agenda', ['getAppointmentMotiveFromIri']),
    firstFreeAppointmentSlot () {
      return {
        date: NovaTools.dates.format(this.firstFreeSlotDate),
        motive: this.getAppointmentMotiveFromIri(this.freeAppointmentSlots[0].motive)?.name,
      };
    },
    displayedDays () {
      const dateTraitement = this.firstDate;
      return [...Array(MIN_SLOTS_AMOUNT).keys()].map((item, index) => new Date(
        dateTraitement.getFullYear(),
        dateTraitement.getMonth(),
        dateTraitement.getDate() + index,
      ));
    },
    allSlotsByDate () {
      return this.displayedDays
        .reduce((map, date) => {
          const startDateTime = new Date(date);
          const day = NovaTools.dates.format(startDateTime, 'E dd MMM');

          // Permet d'afficher les créneaux indisponibles
          map.set(day, [...Array(this.slotsAmountByCol).keys()].map(() => undefined));

          const freeSlots = this.freeAppointmentSlots.filter(slot => isSameDay(new Date(slot.startDateTime), new Date(date)));
          if (freeSlots) {
            freeSlots.forEach((slot, index) => map.get(day).splice(index, 1, new FreeAppointmentSlot(slot)));
          }
          return map;
        }, new Map());
    },
    totalDisplayedSlots () {
      let activeVisibleSlots = 0;
      Array.from(this.allSlotsByDate).forEach(iterator => {
        const numberSlotsOfDay = iterator[1].filter(slot => slot).length;
        activeVisibleSlots += (numberSlotsOfDay <= this.slotsAmountByCol) ? numberSlotsOfDay : this.slotsAmountByCol;
      });
      return activeVisibleSlots;
    },
    displayedSlots () {
      return Array.from(this.allSlotsByDate).map((iterator) => iterator[1]).flat().filter(slot => slot);
    },
    displayedPrevious () {
      return ! (NovaTools.dates.format(this.firstDate, 'yyyy-MM-dd') === NovaTools.dates.format(new Date(), 'yyyy-MM-dd'));
    },
  },
  watch: {
    freeAppointmentSlots () {
      // Réinitialise le nombre de créneaux affiché dans la liste à chaque mise à jour de la liste
      // Évitant l'affichage de créneaux vides inutiles
      this.slotsAmountByCol = MIN_SLOTS_AMOUNT;
    },
    startDate (newVal, oldVal) {
      if (! isSameDay(newVal, oldVal)) {
        this.firstDate = newVal;
      }
    },
  },
  methods: {
    isDaySlotsEmpty (daySlots) {
      return daySlots.every(slot => ! (slot instanceof FreeAppointmentSlot));
    },
    /**
     * Affiche autant de slot que la colonne en possède
     */
    displayAllSlots () {
      this.slotsAmountByCol = max(Array.from(this.allSlotsByDate).map((iterator) => iterator[1].length));
    },
    goToNextFreeAppointmentSlots () {
      this.$emit('go-to-next-free-appointment-slots');
      this.firstDate = this.firstFreeSlotDate;
    },
    goToPreviousPage () {
      let newDate = new Date(this.firstDate.toString());
      newDate.setDate(newDate.getDate() - 4);
      if (newDate < new Date()) {
        newDate = new Date();
      }
      this.firstDate = newDate;
      this.$emit('go-to-page-start-date-appointment-slots', newDate);
    },
    goToNextPage () {
      const newDate = new Date(this.firstDate.toString());
      newDate.setDate(newDate.getDate() + 4);
      this.firstDate = newDate;
      this.$emit('go-to-page-start-date-appointment-slots', newDate);
    },
  },
};
</script>

<style lang="scss" scoped>

.free-appointment-slot-list {
  &__intro {
    font-size: 13px;
  }

  &__slots-day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 13px;

    &--empty {
      opacity: .5;
      user-select: none;
      pointer-events: none;
    }
  }

  &__slots {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__slot {
    display: flex;
    justify-content: center;
    max-width: 58px;
    width: 100%;

    &--empty {
      opacity: .5;
      user-select: none;
      pointer-events: none;
    }
  }

  &__skeleton-button {
    width: 47px;
  }
}
</style>