<template>
  <n-dialog
    divided
    width="720"
    :is-open="isOpen"
    title="Accéder à un dossier par carte vitale"
    content-class="vitale-card-search-modal"
    @update:is-open="isOpen => $emit('update:is-open', isOpen)"
  >
    <vitale-card-switch-view-validator
      class="vitale-card-search-modal__switch-view"
      @remove-card="handleRemoveVitaleCard"
      @card-read="handleVitaleCardRead"
    >
      <vitale-card-search-modal-form-steps
        class="vitale-card-search-modal__content"
        :selected-insured.sync="selectedInsured"
        :is-mobile="isMobile"
        @patient-selected="handleSelectPatient"
        @reload-card="handleRemoveVitaleCard"
      />
    </vitale-card-switch-view-validator>
    <template
      v-if="!! selectedInsured"
      #actions
    >
      <div class="vitale-card-search-modal__selected-insured-actions">
        <div>
          <p class="vitale-card-search-modal__selected-insured-subtitle">
            Bénéficiaire sélectionné
          </p>
          <patient-details
            v-test="'selected-insured'"
            :patient="selectedInsured"
            x-small
            :show-anonymized-identifier="false"
            :show-pictograms="false"
            show-birth-informations
            show-initials
          />
        </div>
        <div>
          <create-patient-button
            bg-color="primary"
            :pre-filled-patient="selectedInsured"
            :hide-label="isMobile"
            keep-color-style-when-no-label
          />
        </div>
      </div>
    </template>
  </n-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import { ROUTE_NAMES as PATIENT_ROUTE_NAMES } from '@/modules/patient/constants';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

import VitaleCardSwitchViewValidator from '@/components/ui/form/VitaleCardSwitchViewValidator.vue';
import VitaleCardSearchModalFormSteps from '@/layout/default/components/header/vitaleCardSearch/VitaleCardSearchModalFormSteps.vue';
import CreatePatientButton from '@/modules/patient/components/CreatePatientButton.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';

export default {
  name: 'VitaleCardSearchModal',
  components: {
    PatientDetails,
    CreatePatientButton,
    VitaleCardSwitchViewValidator,
    VitaleCardSearchModalFormSteps,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { selectedInsured: null };
  },
  computed: {
    ...mapGetters('cardReaders', ['getVitaleCardInsureds']),
    ...mapGetters('app', ['isMobile']),
  },
  watch: {
    isOpen (isOpen) {
      if (isOpen && this.getVitaleCardInsureds?.length === 1) {
        this.selectedInsured = this.getVitaleCardInsureds.at(0).patient;
        return;
      }
      if (! isOpen) {
        this.clearSelection();
      }
    },
  },
  methods: {
    clearSelection () {
      this.selectedInsured = null;
    },
    handleSelectPatient (patientIri) {
      const patientUuid = getUUIDFromIRI(patientIri);
      this.$emit('update:is-open', false);
      if (this.$route.name === PATIENT_ROUTE_NAMES.PATIENT_FILE && this.$route.params.uuid === patientUuid) {
        return;
      }
      this.$router.push({
        name: PATIENT_ROUTE_NAMES.PATIENT_FILE,
        params: { uuid: patientUuid },
      });
      this.clearSelection();
    },
    handleRemoveVitaleCard () {
      this.clearSelection();
    },
    handleVitaleCardRead () {
      if (this.getVitaleCardInsureds?.length === 1) {
        this.selectedInsured = this.getVitaleCardInsureds.at(0).patient;
      }
    },
  },
};
</script>

<style>
.vitale-card-search-modal {
  min-height: 80%;
  max-height: 80% !important;
}
</style>

<style lang="scss" scoped>
.vitale-card-search-modal {
  &__switch-view {
    min-height: 500px;
  }
  &__switch-view,
  &__content {
    height: 100%;
  }
  &__selected-insured-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__selected-insured-subtitle {
    font-size: 12px;
    color: var(--v-secondary-base);
  }
}
</style>