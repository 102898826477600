<!-- eslint-disable vue/no-mutating-props -->
<template>
  <app-form :submit-method="submit">
    <app-textarea-field
      v-model="localValue.content"
      v-test="'note-content-textarea'"
      label="Saisissez le contenu de votre note ici"
      hide-errors
      rows="1"
      auto-grow
      :rules="{ required: true, equalToOriginal: { original: originalNoteContent } }"
    />
  </app-form>
</template>

<script>
import { extend } from 'vee-validate';
import { mapActions } from 'vuex';

import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote';
import NovaTools from '@/nova-tools/NovaTools';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue';




export default {
  name: 'NoteForm',
  components: {
    AppForm,
    AppTextareaField,
  },
  props: {
    value: {
      type: MedicalHistoryNote,
      default: () => new MedicalHistoryNote(),
    },
  },
  data () {
    return {
      localValue: null,
      originalNoteContent: null,
    };
  },
  computed: {
    isEditing () {
      return !! this.localValue?.['@id'];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value;
        this.originalNoteContent = this.value?.content || null;
      },
    },

  },
  methods: {
    ...mapActions('patient', ['insertPatientMedicalHistoryNotes', 'savePatientMedicalHistoryNotes']),
    async submit () {
      const action = this.isEditing ? 'savePatientMedicalHistoryNotes' : 'insertPatientMedicalHistoryNotes';
      await this[action](this.localValue);
      NovaTools.notify.success(`La note a été ${this.isEditing ? 'modifiée' : 'ajoutée'} avec succès`);
    },
  },
};

extend('equalToOriginal', {
  validate (value, { original }) {
    return value?.trim() !== original?.trim();
  },
  params: ['original'],
});
</script>