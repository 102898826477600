<template>
  <v-dialog
    v-model="opened"
    v-bind="$attrs"
    :scrollable="scrollable"
    :fullscreen="isMobile"
    content-class="app-modal"
    overlay-color="text"
    :width="$attrs && $attrs.width ? $attrs.width : '600px'"
    :transition="modalTransition"
    :eager="eager"
    :persistent="persistent"
    :retain-focus="retainFocus"
    @input="val => $emit('update:isOpen', val)"
  >
    <template #activator="{ on, attrs }">
      <!-- @slot Slot to insert element that enables modal to display -->
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card color="app-modal__content">
      <v-card-title
        class="pt-6 px-6 pb-0"
        :class="{ 'pb-6': scrollable }"
      >
        <h2 class="app-modal__headline title-3">
          {{ title }}
        </h2>
        <app-button
          v-if="hideFooter"
          icon="close"
          class="ml-auto"
          data-test="close-button"
          label="Fermer"
          @click="handleCancel"
        />
      </v-card-title>
      <n-divider
        v-if="scrollable"
        :spacing="0"
      />
      <v-card-text
        class="pa-6 app-modal__body"
        :class="{'py-4 py-md-5': scrollable }"
      >
        <slot />
      </v-card-text>
      <v-divider v-if="scrollable" />
      <v-card-actions
        v-if="!hideFooter"
        class="pa-4 px-md-8 py-md-5"
        data-test="modalFooter"
      >
        <v-spacer />
        <!-- @slot Slot to insert possible modal actions -->
        <slot name="actions" :cancel="handleCancel">
          <!--
            Click on cancel button
            @event cancel
          -->
          <app-button
            text
            :color="cancelColor"
            data-test="cancel"
            @click="handleCancel"
          >
            {{ cancelText }}
          </app-button>
          <!--
            Click on confirm button
            @event submit
          -->
          <app-button
            :color="submitColor"
            :loading="isSubmitting"
            :disabled="submitIsDisabled"
            :form="formId"
            type="submit"
            @click="$emit('submit')"
          >
            {{ submitText }}
          </app-button>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import ModalMixin from './mixins/ModalMixin';

export default {
  name: 'AppModal',
  mixins: [ModalMixin],
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitIsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    /**
     * Confirmation button text
     * Only if actions slot is not overwritten
     */
    submitText: {
      type: String,
      required: false,
      default: 'Enregistrer',
    },
    /**
     * Confirmation button color
     * Only if actions slot is not overwritten
     */
    submitColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    /**
     * Cancel button text
     * Only if actions slot is not overwritten
     */
    cancelText: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    /**
     * Cancel button color
     * Only if actions slot is not overwritten
     */
    cancelColor: {
      type: String,
      required: false,
      default: 'blue-grey',
    },
    /**
     * Form id which will be used at submitting
     */
    formId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Force loading in the DOM at parent component mounting
     */
    eager: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specify if clicking outside or pressing Esc will close the modal
     */
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specify if focus will return to the first child of the dialog
     */
    retainFocus: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    modalTransition () {
      return this.isMobile ? 'dialog-bottom-transition' : 'dialog-transition';
    },
  },
};
</script>

<style lang="scss">
.app-modal {
  border-radius: 12px !important;

  &__content {
    border-radius: 12px !important;
    display: flex;
    flex-direction: column;
  }

  &__headline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 0;
  }

  &__body {
    flex: 1 0 auto;
    font-size: 13px;
  }
}
</style>