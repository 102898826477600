<template>
  <n-list
    :items="patients"
    class="patient-compact-card-list-selector"
    :class="{ 'patient-compact-card-list-selector--disabled': disabled }"
  >
    <template #item="{ item: patient }">
      <app-panel
        v-test="'patient'"
        v-ripple
        class="patient-compact-card-list-selector__result"
        :class="{ 'patient-compact-card-list-selector__result--selected': isPatientSelected(patient) }"
        is-outlined
        is-flat
        dense
        @click.native="selectMatchingPatient(patient)"
      >
        <patient-details
          :patient="patient"
          :show-keywords="false"
          :show-anonymized-identifier="false"
          show-birth-informations
          x-small
        />
        <n-divider />
        <patient-strict-traits-dataset-grid
          dense
          class="flex-grow-1"
          :patient="patient"
        />
        <slot
          name="below"
          :patient="patient"
          :is-selected="isPatientSelected(patient)"
        />
      </app-panel>
    </template>
  </n-list>
</template>

<script>
import Patient from '@/modules/patient/models/Patient';

import AppPanel from '@/components/ui/panel/AppPanel.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';
import PatientStrictTraitsDatasetGrid from '@/modules/patient/components/PatientStrictTraitsDatasetGrid.vue';


/**
 * Une liste sélectionnable de patient sous la forme de carte indiquant des informations essentielles
 * telles que la civilité ou encore les différents traits stricts
 */
export default {
  name: 'PatientCompactCardListSelector',
  components: {
    AppPanel,
    PatientDetails,
    PatientStrictTraitsDatasetGrid,
  },
  props: {
    patients: {
      type: Array,
      required: true,
      validator: patients => patients.every(patient => patient instanceof Patient),
    },
    selectedPatientIri: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectMatchingPatient (patient) {
      this.$emit('update:selected-patient-iri', patient['@id']);
    },
    isPatientSelected (patient) {
      return patient['@id'] === this.selectedPatientIri;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .panel.panel--outlined.patient-compact-card-list-selector__result--selected {
    border-color: var(--v-primary-base) !important;
  }
}

.patient-compact-card-list-selector {
  color: var(--v-content-base);
  font-size: 13px;

  &--disabled {
    opacity: .5;
    pointer-events: none;
  }

  &__result {
    cursor: pointer;
    user-select: none;
  }
}
</style>