export const CARD_TYPES = {
  CPX: 'CPX',
  VITALE: 'VITALE',
};

export const CARD_TYPE_DEFINITIONS = {
  [CARD_TYPES.CPX]: {
    i_slotType: 3,
    localStorageKey: CARD_TYPES.CPX,
  },
  [CARD_TYPES.VITALE]: {
    i_slotType: 2,
    localStorageKey: CARD_TYPES.VITALE,
  },
};