// App stores

// Modules stores
import app from './app/app';
import auth from './auth/auth';
import MercurePlugin from './plugins/mercurePlugin';
import MssConnectionManagerPlugin from './plugins/mssConnectionManagerPlugin';
import TokenExpirationPlugin from './plugins/tokenExpirationPlugin';
import AgendaStore from '@/modules/agenda/store';
import ContactStore from '@/modules/contact/store';
import DocumentsStore from '@/modules/documents/store';
import PatientStore from '@/modules/patient/store';
import SecureMessagingStore from '@/modules/secureMessaging/store';
import UsersStore from '@/modules/users/store';

// Plugins
import cardReadersStore from '@/store/cardReaders';

export default {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    auth,
    cardReaders: cardReadersStore,
    agenda: AgendaStore,
    patient: PatientStore,
    documents: DocumentsStore,
    users: UsersStore,
    secureMessaging: SecureMessagingStore,
    contact: ContactStore,
  },
  plugins: [TokenExpirationPlugin, MercurePlugin, MssConnectionManagerPlugin],
};