import * as Sentry from '@sentry/vue';
import Vue from 'vue';

import { getEnv } from '@/utils/functions/env';

export function initialise() {
  if (getEnv('VUE_APP_SENTRY_ENABLED') === 'false') {
    return;
  }
  const tracePropagationTargets = import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGETS
    ? import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGETS
        .split(',')
        .filter((value) => value !== '')
        .map((value) => new RegExp(value, 'g'))
    : [];

  Sentry.init({
    Vue,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: false }),
    ],
    environment: import.meta.env.VUE_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
  });
}

export function addMessageError(message) {
  Sentry.captureMessage(message, 'error');
}