/**
 * Liste des types de vue de calendrier disponibles dans l'agenda
 */
export const APPOINTMENT_DISPLAY_TYPES = Object.freeze([
  {
    text: 'Patient',
    value: 'patient',
  },
  {
    text: 'Motif',
    value: 'motive',
  },
]);