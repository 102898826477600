import PrescriptionLppLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/prescriptionLppLine/PrescriptionLppLine';

export default class PrescriptionCustomLppLine extends PrescriptionLppLine {
  constructor (options = {}) {
    super(options);

    this.label = options.label || null;
  }

  getLabel () {
    return this.label;
  }
}