<template>
  <div>
    <app-replacement-content-list-modal
      v-if="selectedInsuredReplacementFields && selectedInsuredReplacementFields.conflicts.length"
      v-test="'vital-card-insured-merge-selector-modal'"
      title="Attention"
      confirm-text="Importer"
      :replacements="selectedInsuredReplacementFields.conflicts"
      :is-open="selectedInsuredReplacementFields.conflicts.length > 0"
      @close="selectedInsuredReplacementFields = null"
      @confirm="onConflictsModalSubmit"
    >
      <template #prepend>
        <div>
          <p>Certaines données sont déjà renseignées dans le dossier du patient.</p>
          <p>Sélectionnez celles que vous souhaitez remplacer.</p>
        </div>
      </template>
    </app-replacement-content-list-modal>
    <vital-card-insureds-modal
      v-test="'vital-card-insureds-modal'"
      :insureds="vitaleCardContent"
      :is-open.sync="isCardInsuredsModalOpened"
      @click:insured="loadInsured"
    />
    <app-fullscreen-form
      v-model="editingPatient"
      v-test="'patient-form'"
      :form="PatientForm"
      :title="labels.title"
      :submit-text="labels.submit"
      :use-local-copy="false"
      :show-back-button="showBackButton"
      @cancel="handleCancel"
      @submitSuccess="handleSubmitSuccess"
      @submitError="handleSubmitError"
    >
      <template #actions="{ formValidation, isSubmitting, formId }">
        <card-reader-status-icon
          :type="CARD_TYPES.VITALE"
          @click="handleVitaleButtonClick"
          @statusChange="onVitaleCardReaderStatusChange"
        />
        <n-button
          v-test="'submit'"
          :loading="isSubmitting"
          :disabled="! canSubmit(formValidation)"
          type="submit"
          :form="formId"
          bg-color="primary"
          :label="labels.submit"
        />
      </template>
    </app-fullscreen-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem';
import { BASE_ROUTE_NAMES } from '@/constants';
import Address from '@/models/Address';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import { INS_IDENTITY_STATUS } from '@/modules/patient/constants/insIdentityStatus';
import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice';
import Patient from '@/modules/patient/models/Patient';
import comparePatientValues from '@/modules/patient/utils/comparePatientValues';
import NovaTools from '@/nova-tools/NovaTools';
import { CARD_STATUS } from '@/services/card-reader/constants/cardStatus';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import { isPartnerAccess } from '@/services/partnerService';
import { softInclude } from '@/utils/functions/words';

import CardReaderStatusIcon from '@/components/ui/cardReader/CardReaderStatusIcon.vue';
import AppFullscreenForm from '@/components/ui/form/AppFullscreenForm.vue';
import AppReplacementContentListModal from '@/components/ui/replacementContent/AppReplacementContentListModal.vue';
import PatientForm from '@/modules/patient/components/patientForm/PatientForm.vue';
import VitalCardInsuredsModal from '@/modules/patient/components/patientForm/VitalCardInsuredsModal.vue';

;

const DEFAULT_PATIENT_ADDRESS = new Address({ country: 'FR' });
const INSURED_FIELD_LABELS = {
  firstName: '1er prénom de naissance',
  firstNames: 'Prénom(s)',
  birthName: 'Nom de naissance',
  familyName: 'Nom utilisé',
  birthDate: 'Date de naissance',
  gender: 'Sexe',
  civility: 'Civilité',
  nir: 'NIR',
  'billDataset.birthRank': 'Rang de naissance',
  'billDataset.fund': 'Caisse',
  'billDataset.center': 'Centre',
  'billDataset.grandRegime': 'Grand Régime',
  'billDataset.quality': 'Qualité du bénéficiaire',
};

export default {
  name: 'PatientFormView',
  components: {
    AppFullscreenForm,
    AppReplacementContentListModal,
    VitalCardInsuredsModal,
    CardReaderStatusIcon,
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.redirectToPreviousPage = from?.name === 'patients.consultation';
      if (to.params.uuid) {
        const patient = vm.getPatientByUUID(to.params.uuid);
        let identityDevice = null;
        if (patient.insIdentity.identityDevice) {
          // L'api ne fournissant qu'un IRI pour le dispositif
          // un get est nécessaire afin d'hydrater complètement son modèle
          identityDevice = await InsIdentityDevice.fetch(patient.insIdentity.identityDevice.getUuid());
        }
        vm.setEditingPatient({
          ...patient,
          insIdentity: {
            ...patient.insIdentity,
            identityDevice,
          },
        });
        return;
      }
      if (to.params.prefilled_patient) {
        vm.setEditingPatient(to.params.prefilled_patient);
        return;
      }
      if (to.params.patientIdentity) {
        vm.setEditingPatientFromPartnerIdentity(to.params.patientIdentity);
      }
    });
  },
  props: {
    /**
     * Permet d'ignorer la redirection au retour et à la création, et d'émettre un évènement correspondant à l'action à la place
     */
    ignoreRedirection: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient,
      default: null,
    },
  },
  data () {
    return {
      CARD_TYPES,
      editingPatient: new Patient({ address: DEFAULT_PATIENT_ADDRESS }),
      PatientForm,
      isCardInsuredsModalOpened: false,
      selectedInsuredReplacementFields: null,
      redirectToPreviousPage: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('patient', ['getPatientByUUID']),
    ...mapGetters('cardReaders', ['getCardContent']),
    isEditing () {
      return !! this.editingPatient?.['@id'];
    },
    labels () {
      return {
        title: this.isEditing ? 'Edition d\'un patient' : 'Nouveau patient',
        submit: this.isEditing ? 'Modifier le patient' : 'Créer le patient',
      };
    },
    vitaleCardContent () {
      return this.getCardContent(CARD_TYPES.VITALE);
    },
    canEditInsStrictFeatures () {
      return this.hasPermission('update_ins_strict_features');
    },
    isPartnerAccess () {
      return isPartnerAccess();
    },
    showBackButton () {
      return ! this.isPartnerAccess;
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler: 'setEditingPatient',
    },
  },
  methods: {
    setEditingPatient (patient) {
      if (! patient) {
        return;
      }
      this.editingPatient = new Patient({
        ...patient,
        address: patient?.address || DEFAULT_PATIENT_ADDRESS,
      });
    },
    async setEditingPatientFromPartnerIdentity (patientIdentityQueryParam) {
      if (Object.keys(patientIdentityQueryParam).length === 0) {
        return;
      }
      const patientIdentity = new URLSearchParams(atob(patientIdentityQueryParam));
      const patientTraits = Object.fromEntries(patientIdentity.entries());
      const devices = await InsIdentityDevice.fetchAll();
      const defaultDevice = devices.filter(device => device.attest).shift();

      this.editingPatient = new Patient({
        ...this.patient,
        birthName: patientTraits.familyName,
        familyName: patientTraits.familyName,
        firstNames: patientTraits.firstNames,
        birthDate: patientTraits.birthDate,
        birthPlaceCode: patientTraits.birthPlaceCode,
        gender: patientTraits.gender,
        address: DEFAULT_PATIENT_ADDRESS,
        insIdentity: { identityDevice: defaultDevice },
      });
    },
    onConflictsModalSubmit (conflictsToMerge) {
      this.mergeInsuredDataToPatient([
        ...this.selectedInsuredReplacementFields?.replaceable || [],
        ...conflictsToMerge,
      ]);
    },
    handlePartnerAccess () {
      if (this.isEditing) {
        return;
      }
      this.$router.push({ name: BASE_ROUTE_NAMES.PARTNER_EMPTY_PAGE });
    },
    handleSubmitSuccess (patient) {
      this.$emit('submit-success', patient);
      if (this.ignoreRedirection) {
        return;
      }

      if (this.isPartnerAccess) {
        this.handlePartnerAccess();
        return;
      }
      if (this.redirectToPreviousPage) {
        this.$router.go(- 1);
        return;
      }
      this.$router.push({
        name: ROUTE_NAMES.PATIENT_FILE,
        params: { uuid: new Patient(patient).getUuid() },
      });
    },
    handleSubmitError () {
      NovaTools.notify.error(`La ${ this.isEditing ? 'modification' : 'création' } du patient a échoué. Veuillez vérifier les données saisies.`);
    },
    handleCancel () {
      this.$emit('cancel');
      if (this.ignoreRedirection || this.isPartnerAccess) {
        return;
      }
      this.$router.go(- 1);
    },
    handleVitaleButtonClick () {
      if (! this.vitaleCardContent) {
        return;
      }
      if (this.vitaleCardContent.length > 1) {
        this.openVitalModal();
        return;
      }
      this.loadInsured(new Patient(this.vitaleCardContent[0].patient));
    },
    openVitalModal () {
      this.isCardInsuredsModalOpened = true;
    },
    loadInsured (insured) {
      const { conflicts, replaceable } = comparePatientValues(this.editingPatient, insured, {
        labels: INSURED_FIELD_LABELS,
        excludeResolver: (key, valueA, valueB, path) => {
          if (path.includes('referringPhysician')) {
            return true;
          }
          const isInsuredField = Object.keys(INSURED_FIELD_LABELS).find(fieldPath => fieldPath.includes(path));
          if (this.editingPatient.insIdentity.status === INS_IDENTITY_STATUS.QUALIFIED.value) {
            return this.canEditInsStrictFeatures
              ? ! isInsuredField
              : (['gender', 'birthName', 'firstName', 'firstNames', 'birthDate', 'birthPlaceCode'].includes(path) || ! isInsuredField);
          }
          return ! isInsuredField;
        },
      });
      this.selectedInsuredReplacementFields = {
        replaceable,
        conflicts: conflicts.sort((a, b) => a.label.localeCompare(b.label)),
      };
      if (replaceable.length && (conflicts.length === 0)) {
        this.mergeInsuredDataToPatient(replaceable);
        return;
      }
      if ((conflicts.length === 0) && (replaceable.length === 0)) {
        NovaTools.notify.info('Les données de l\'assuré sont identiques à celles du patient', { title: 'Aucun champ à fusionner' });
      }
    },
    mergeInsuredDataToPatient (fieldsToReplace) {
      const insuredFirstNameField = fieldsToReplace.find(field => field.key === 'firstName');
      const insuredFirstNamesField = fieldsToReplace.find(field => field.key === 'firstNames');
      if ((insuredFirstNameField && ! insuredFirstNamesField) && (! softInclude(this.editingPatient.firstNames, insuredFirstNameField.after))) {
        NovaTools.notify.warning(
          'Veuillez sélectionner le champ "Prénom(s)" ou renseigner cette donnée dans le formulaire',
          { title: 'Le prénom de naissance n\'a pas pu être fusionné' },
        );
        if (fieldsToReplace.length === 1) {
          return;
        }
        fieldsToReplace = fieldsToReplace.filter(({ key }) => key !== insuredFirstNameField.key);
      }
      if (insuredFirstNameField && insuredFirstNamesField) {
        fieldsToReplace = [
          insuredFirstNameField,
          insuredFirstNamesField,
          ...fieldsToReplace.filter(({ key }) => (key !== insuredFirstNameField.key) && (key !== insuredFirstNamesField.key)),
        ];
      }
      this.editingPatient = ReplacementItem.replace(this.editingPatient, fieldsToReplace);
      NovaTools.notify.success('Les données de l\'assuré ont été fusionnées avec le patient');
    },
    onVitaleCardReaderStatusChange (status) {
      if (status === CARD_STATUS.NO_CARD) {
        this.isCardInsuredsModalOpened = false;
      }
    },
    canSubmit (formValidation) {
      return formValidation.valid || (formValidation.fields.nir?.invalid && this.editingPatient?.nirAllowUnchecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-button {
  &.disabled {
    cursor: default;
  }
}
</style>