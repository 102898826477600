<template>
  <div class="selectable-list-wrapper">
    <app-checkbox
      v-if="items.length > 1 && ! disabled"
      :input-value="allSelected"
      :indeterminate="!allSelected && value.length > 0"
      :disabled="disabled"
      readonly
      class="mb-3 mt-0 pt-0"
      data-test="select-all"
      @click="toggleAll"
    >
      <template #label>
        <span class="selectable-list__checkbox-label">Tous</span>
      </template>
    </app-checkbox>
    <validation-provider
      v-slot="{ errors }"
      ref="validation-provider"
      slim
      :rules="rules"
      :name="label"
      :vid="name"
    >
      <v-item-group
        :value="value"
        :mandatory="minCount > 0"
        multiple
        class="mb-last-0"
      >
        <div
          v-for="(group, index) in groupedItems"
          :key="index"
          class="mb-last-0 mb-3"
        >
          <div
            v-if="groupBy"
            :key="group.parent['@id']"
            class="selectable-list__parent"
          >
            <slot
              name="parent"
              v-bind="{ parent: group.parent }"
            >
              <span>{{ group.parent.name }}</span>
            </slot>
          </div>
          <v-item
            v-for="item in group.items"
            :key="item['@id']"
            class="mb-3"
            data-test="item"
          >
            <div class="d-flex align-center">
              <app-checkbox
                class="mt-0 pt-0"
                :readonly="value.length === minCount && isItemActivated(item)"
                :input-value="isItemActivated(item)"
                :disabled="disabled"
                @change="toggle(item['@id'])"
              />
              <span
                data-test="item-parent"
                class="selectable-list__checkbox-label"
                :class="{
                  'selectable-list__checkbox-label--clickable': items.length > 1,
                  'selectable-list__checkbox-label--disabled': disabled,
                }"
                @click="focusOne(item['@id'])"
              >
                <slot
                  name="item"
                  v-bind="{item}"
                >
                  <span>{{ item.name }}</span>
                </slot>
              </span>
            </div>
          </v-item>
        </div>
      </v-item-group>
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="error--text"
      >
        {{ error }}
      </div>
    </validation-provider>
    <div v-if="items.length === 0">
      <slot name="empty" />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import fieldMixin from '@/mixins/fields';

import AppCheckbox from '@/components/ui/form/AppCheckbox.vue';


/**
 * Ce composant permet d'afficher un ensemble de modèle de donnée (avec '@id').
 * Il est possible de regrouper ces éléments dans des parents (modèles également, avec '@id').
 */
export default {
  name: 'AppSelectableList',
  components: {
    AppCheckbox,
    ValidationProvider,
  },
  mixins: [fieldMixin],
  props: {
    /**
     * Tableau contenant les objets
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Tableau semblable à celui de items, mais ne contenant que les objets cochés
     * @model
     */
    value: {
      type: Array,
      required: true,
    },
    /**
     * Permet d'empêcher la modification
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Chemin vers l'objet du parent
     * Ex : 'parent.subPath'
     */
    groupBy: {
      type: String,
      default: null,
    },
    /**
     * Minimum d'éléments obligatoirement séléctionés
     */
    minCount: {
      type: Number,
      default: 0,
    },
    /**
     * Permet d'empêcher le toggle (déselectionne tous les autres sur focus/clic label) si true
     * @default false
     */
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allSelected () {
      return this.value.length === this.items.length;
    },
    groupedItems () {
      return this.items.reduce((groupedItems, curr) => {
        const groupRef = get(curr, this.groupBy);
        let groupRefId = groupRef?.['@id'];
        if (! groupRefId) {
          groupRefId = 'other';
        }

        if (! groupedItems[groupRefId]) {
          groupedItems[groupRefId] = {
            parent: groupRef || {},
            items: [],
          };
        }

        groupedItems[groupRefId].items.push(curr);
        return groupedItems;
      }, {});
    },
  },
  watch: {
    value: {
      deep: true,
      /**
       * Force l'actualisation de l'état de la validation
       * Sans quoi, vee-validate semble ne pas s'y interesser de lui même
       */
      handler () {
        const validationProvider = this.$refs['validation-provider'];
        if (validationProvider) {
          validationProvider.validate();
        }
      },
    },
  },
  methods: {
    /**
     * Coche tous les items si tout n'est pas sélectionné
     * sinon décoche tous les items en partant de la fin,
     * en prenant soin d'en laisser le minimum nécessaire (voir la props min-count)
     */
    toggleAll () {
      const isChecked = !this.allSelected;
      const threshold = isChecked ? 0 : this.minCount;
      for (let i = this.items.length - 1; i >= threshold; i -= 1) {
        const item = this.items[i];
        const hasItemChanged = this.isItemActivated(item) !== isChecked;
        if (hasItemChanged) {
          this.$emit('changed', {
            item,
            isChecked,
          });
        }
      }
    },
    toggle (itemIri) {
      const item = this.items.find(item => item['@id'] === itemIri);
      const isChecked = ! this.isItemActivated(item);
      this.$emit('changed', {
        item,
        isChecked,
      });
    },
    focusOne (itemIri) {
      if (this.multiple) {
        this.toggle(itemIri);
        return;
      }
      if (this.items.length > 1) {
        this.items.forEach(item => {
          this.$emit('changed', {
            item,
            isChecked: item['@id'] === itemIri,
          });
        });
      }
    },
    isItemActivated (item) {
      return !! this.value.find(_item => _item['@id'] === item['@id']);
    },
  },
};
</script>

<style lang="scss">
.selectable-list-wrapper {
  .selectable-list {
    &__checkbox-label {
      font-size: 13px;
      color: var(--v-text-base);

      &--disabled {
        opacity: .6;
        pointer-events: none;
      }

      &--clickable {
        user-select: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__parent {
      height: 20px;
      font-size: 12px;
      color: var(--v-blue-grey-base);
      margin-bottom: 8px;
    }
  }
}
</style>