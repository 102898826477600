import { ROUTE_NAMES } from '@/modules/contact/constants';

import ContactModule from '@/modules/contact/ContactModule.vue';

export default {
  path: '/contacts',
  component: ContactModule,
  children: [
    {
      path: '/',
      name: ROUTE_NAMES.CONTACT_LISTING,
      component: () => import('@/modules/contact/views/ContactsListing.vue'),
    },
    {
      path: ':type/:uuid',
      name: ROUTE_NAMES.CONTACT_VIEW,
      component: () => import('@/modules/contact/views/ContactView.vue'),
    },
  ],
};