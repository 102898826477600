<template>
  <app-col
    cols="12"
    md="6"
    lg="4"
    class="mb-n5"
  >
    <app-dataset-item
      label="OID"
      :value="localValue"
      vertical
      empty-sentence="Non renseigné"
      separator-char=""
    />
  </app-col>
</template>

<script>
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';

export default {
  name: 'IdentityOidField',
  components: { AppDatasetItem },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>