<template>
  <app-form
    :submit-method="submit"
    class="document-form"
  >
    <app-file-input
      :value="uploadedFiles.map((item) => item.fileUrl)"
      :max-mo-size="10"
      :uploaded-files-max="uploadedFilesMax"
      :has-text-fields-errors="hasTextFieldsErrors"
      :has-uploaded-files-errors="hasUploadedFilesErrors"
      :uploaded-files-count="uploadedFilesCount"
      button-label="Sélectionner des fichiers"
      button-clear-label="Retirer tous les fichiers"
      data-test="document-file-input"
      hide-errors
      rows="1"
      auto-grow
      class="document-form__file-input mb-5"
      rules="required"
      name="file"
      multiple
      @file-selected="uploadFiles"
      @invalid-file="uploadFiles"
      @files-cleared="clearUploadedFiles"
    >
      <template #empty>
        Aucun fichier sélectionné
      </template>
      <template #default>
        <app-row>
          <template v-for="(item, index) in uploadedFiles">
            <app-col
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <div class="d-flex">
                <app-file-preview
                  :src="(item.fileUrl).toString()"
                  :type="item.file.type"
                  icon-size="36"
                  alt="Fichier importé"
                  class="document-form__file-preview"
                />
                <app-icon
                  color="error"
                  icon="delete"
                  tooltip="Retirer ce fichier"
                  class="ml-1"
                  @click="removeUploadedFile(index, true)"
                />
                <app-icon
                  v-if="item.file.shortError"
                  color="error"
                  icon="error"
                  :tooltip="item.file.shortError"
                  class="ml-1"
                />
              </div>
              <app-text-field
                :ref="`textField-${index}`"
                v-model="item.title"
                label="Nom du fichier"
                hide-errors="auto"
                rules="required|max:100"
                name="title"
                data-test="file-name-input"
                :disabled="isTextFieldDisabled(item)"
                @blur="checkTextFieldsErrors"
              />
            </app-col>
          </template>
        </app-row>
        <template v-for="(item, index) in uploadedFiles">
          <div
            v-if="item.file.longError"
            :key="index"
            class="error--text mt-2"
          >
            {{ item.file.longError }}
          </div>
        </template>
      </template>
    </app-file-input>
    <app-alert
      type="info"
      class="mt-5"
    >
      Les fichiers seront importés dans le dossier {{ folderParent ? folderParent.title : 'Général' }}
    </app-alert>
  </app-form>
</template>

<script>
import PatientDocument from '@/modules/patient/models/PatientDocument';
import PatientDocumentFolder from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/models/PatientDocumentFolder';

import AppAlert from '@/components/ui/alert/AppAlert.vue';
import AppFilePreview from '@/components/ui/filePreview/AppFilePreview.vue';
import AppFileInput from '@/components/ui/form/AppFileInput.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';


export default {
  name: 'DocumentForm',
  components: {
    AppForm,
    AppAlert,
    AppFilePreview,
    AppFileInput,
    AppTextField,
  },
  props: {
    /**
     * Le patient dans lequel le fichier sera ajouté
     */
    patient: {
      type: String,
      required: true,
    },
    /**
     * Le dossier dans lequel le fichier sera ajouté
     */
    folderParent: {
      type: PatientDocumentFolder,
      default: null,
    },
  },
  data () {
    return {
      uploadedFiles: [],
      uploadedFilesMax: 12,
      hasTextFieldsErrors: false,
      hasUploadedFilesErrors: false,
      uploadedFilesCount: 0,
    };
  },
  methods: {
    uploadFiles (newFiles) {
      const availableSlots = this.uploadedFilesMax - this.uploadedFilesCount;
      if (availableSlots <= 0) {
        return;
      }
      if (newFiles.length > availableSlots) {
        newFiles.splice(availableSlots);
      }
      newFiles.forEach((file) => {
        this.uploadedFiles.push({
          file,
          fileUrl: URL.createObjectURL(file),
          title: file.name.replace(/\.[^/.]+$/, ''),
        });
      });
      this.uploadedFilesCount += newFiles.length;
      this.manageAllErrors();
    },
    removeUploadedFile (index, checkErrors) {
      this.uploadedFiles.splice(index, 1);
      this.uploadedFilesCount -= 1;
      if (checkErrors) {
        this.manageAllErrors();
      }
    },
    isTextFieldDisabled (item) {
      return !! item.file.longError;
    },
    checkTextFieldsErrors () {
      this.hasTextFieldsErrors = this.uploadedFiles.filter(file => file.title.trim()).length !== this.uploadedFilesCount;
    },
    checkUploadedFilesErrors () {
      this.hasUploadedFilesErrors = !! this.uploadedFiles.filter(file => file.file.longError).length;
    },
    revalidateTextFields () {
      this.uploadedFiles.forEach((item, index) => {
        const textFieldRef = this.$refs[`textField-${index}`][0];
        if (textFieldRef && textFieldRef.validate) {
          textFieldRef.validate();
        }
      });
    },
    manageAllErrors () {
      this.checkTextFieldsErrors();
      this.checkUploadedFilesErrors();
      this.$nextTick(() => {
        this.revalidateTextFields();
      });
    },
    clearUploadedFiles () {
      this.uploadedFiles = [];
      this.hasTextFieldsErrors = false;
      this.hasUploadedFilesErrors = false;
      this.uploadedFilesCount = 0;
    },
    async submit () {
      const postResults = await PatientDocument.multiUpload({
        files: this.uploadedFiles,
        patient: this.patient,
        folder: this.folderParent ? this.folderParent.getIri() : null,
      });
      if (Array.isArray(postResults)) {
        let movedIndex = 0;
        postResults.forEach(postResult => {
          postResult.index -= movedIndex;
          if (postResult.success) {
            this.removeUploadedFile(postResult.index, false);
            movedIndex += 1;
          } else {
            const fileName = this.uploadedFiles[postResult.index].file.name;
            this.uploadedFiles[postResult.index].file.longError = `Erreur sur le fichier '${fileName}' : ${postResult.error}`;
            this.uploadedFiles[postResult.index].file.shortError = postResult.error;
          }
        });
        this.manageAllErrors();
        throw new Error('Arrêt intentionnel de submit, gestion des erreurs');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.document-form {
  &__file {
    &-input {
       width: 100%;
    }
    &-preview {
      max-width: 200px;
      height: 200px;
      @media (max-width: 1920px) {
        max-width: 160px;
        height: 160px;
      }
      @media (max-width: 1280px) {
        max-width: 120px;
        height: 120px;
      }
      @media (max-width: 960px) {
        max-width: 80px;
        height: 80px;
      }
      @media (max-width: 600px) {
        max-width: 40px;
        height: 40px;
      }
    }
  }
}
</style>