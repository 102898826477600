export const MEASURES = Object.freeze({
  WEIGHT: {
    name: 'weight',
    label: 'Poids',
    unit: 'Kg',
    editable: true,
  },
  SIZE: {
    name: 'size',
    label: 'Taille',
    unit: 'cm',
    editable: true,
  },
  IMC: {
    name: 'bmi',
    label: 'IMC',
    unit: '',
    editable: false,
  },
  DFG: {
    name: 'gfr',
    label: 'DFG',
    unit: 'mL/min/1,73m²',
    editable: true,
  },
  HEADCIRC: {
    name: 'headcirc',
    label: 'Périmètre crânien',
    unit: 'cm',
    editable: true,
  },
});