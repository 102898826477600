<template>
  <validation-provider
    ref="validator"
    slim
    class="data-matrix-reader__inner"
  >
    <section
      class="data-matrix-reader__inner__video-container"
      :class="{ 'data-matrix-reader__inner__video-container--steam-started': isStreamStarted }"
    >
      <template v-if="! isCameraAvailable">
        <app-icon
          icon="error"
          color="error"
          size="32"
        />
        <h2 class="subtitle-1">
          Caméra non prise en compte par le navigateur
        </h2>
      </template>
      <app-button
        v-if="! isStreamStarted && isCameraAvailable"
        v-test="'start-video-button'"
        color="primary"
        :loading="isInitializingVideo"
        @click="startVideoDecode"
      >
        <app-icon
          icon="camera"
          class="mr-2"
        />
        <span>Démarrer la caméra</span>
      </app-button>
      <template v-if="isStreamStarted">
        <app-icon
          icon="qrcode"
          color="white"
          size="32"
        />
        <p class="data-matrix-reader__inner__video-container__info-text">
          Assurez-vous de scanner un {{ barcodeFormatLabel }} lisible
        </p>
      </template>
      <video
        ref="streaming"
        class="data-matrix-reader__inner__video-container__streaming"
        muted=""
        autoplay=""
        playsinline=""
      />
    </section>
  </validation-provider>
</template>

<script>
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/library';
import { ValidationProvider } from 'vee-validate';

import NovaTools from '@/nova-tools/NovaTools';

const FORMAT_LABELS = {
  5: 'DataMatrix',
  11: 'QRCode',
};

/**
 * Affiche une section de lecture de DataMatrix
 */
export default {
  name: 'BarcodeReader',
  components: { ValidationProvider },
  props: {
    /**
     * Type du barcode à scanner
     * @see https://zxing.github.io/zxing/apidocs/com/google/zxing/BarcodeFormat.html#:~:text=EAN%2D8%201D%20format.
     */
    barcodeFormat: {
      type: Number,
      default: BarcodeFormat.DATA_MATRIX,
      validator: format => Object.keys(BarcodeFormat).includes(`${format}`),
    },
  },
  data () {
    return {
      barCodeReader: null,
      isStreamStarted: false,
      isInitializingVideo: false,
    };
  },
  computed: {
    isCameraAvailable () {
      const getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      return getUserMedia || navigator.mediaDevices?.getUserMedia(contraints => contraints.video);
    },
    barcodeFormatLabel () {
      return FORMAT_LABELS[this.barcodeFormat];
    },
  },
  created () {
    this.barCodeReader = new BrowserMultiFormatReader();
    this.isStreamStarted = false;
    this.isInitializingVideo = false;
  },
  destroyed () {
    // Dans le cas d'une fermeture de la modale, empêche la lecture de tourner en tâche de fond du navigateur
    this.stopVideoStream();
  },
  methods: {
    startVideoDecode () {
      this.isInitializingVideo = true;
      this.barCodeReader.decodeFromVideoDevice(undefined, this.$refs.streaming, async (result) => {
        if (this.isInitializingVideo) {
          this.isInitializingVideo = false;
          this.isStreamStarted = true;
        }
        if (! result) {
          return;
        }
        this.resultHandler(result);
      });
    },
    resultHandler (result) {
      if (result.format !== this.barcodeFormat) {
        this.onNotAcceptedFormat();
        return;
      }
      this.onDataMatrixDetection(result.text);
    },
    onNotAcceptedFormat () {
      const message = 'Ce code n\'est pas au format Datamatrix';
      this.$refs.validator.setErrors([message]);
      NovaTools.notify.warning(message);
    },
    onDataMatrixDetection (resultText) {
      this.$emit('scan-success', resultText);
      this.$refs.validator.setErrors([]);
    },
    stopVideoStream () {
      this.barCodeReader.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.data-matrix-reader {
  height: 100%;
  color: var(--v-content-base);

  &__inner {
    &__file-preview {
      width: 250px;
      height: 250px;
    }

    &__video-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      gap: map-get($spacers, 4);
      padding: map-get($spacers, 6);
      border-radius: 12px;
      background-color: var(--v-secondary-lighten5);
      height: 100%;
      width: 100%;
      aspect-ratio: unset;
      overflow: hidden;
      z-index: 0;

      @include media-md {
        aspect-ratio: 4 / 3;
        height: auto;
      }

      &--steam-started {
        background-color: var(--v-content-darken4);
        justify-content: flex-start;
        color: white;
        font-size: 13px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 40%;
          background: linear-gradient(var(--v-content-darken4), #ffffff00);
          opacity: .7;
          z-index: 0;
        }
      }

      &--steam-started &__streaming {
        display: block;
      }

      &__info-text {
        z-index: 1;
      }

      &__streaming {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        z-index: -1;
      }
    }
  }
}
</style>