<template>
  <keep-alive>
    <router-view
      :key="$route.fullPath"
      data-test="contactModule"
    />
  </keep-alive>
</template>
<script>
export default { name: 'ContactModule' };
</script>