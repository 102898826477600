export const PRACTITIONER = Object.freeze({
  label: 'Praticien',
  value: 'practitioner',
});

export const MEDICAL_SECRETARY = Object.freeze({
  label: 'Secrétaire médicale',
  value: 'medical_secretary',
});

export const FINANCIAL_SECRETARY = Object.freeze({
  label: 'Secrétaire financière',
  value: 'financial_secretary',
});

export const USER_PROFILES = Object.freeze({
  PRACTITIONER,
  MEDICAL_SECRETARY,
  FINANCIAL_SECRETARY,
});