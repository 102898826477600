import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem';
import { CIVILITIES, GENDERS } from '@/constants';
import Patient from '@/modules/patient/models/Patient';

/**
 * Permet de comparer deux patients en retournant les valeurs remplaçables et en conflits
 * @param {Patient} patientA
 * @param {Patient} patientB
 * @param {ReplacementFieldsCompareOptions} options
 * @returns {ReplacementFieldsResult}
 */
export default (patientA, patientB, options = {}) => {
  if (! (patientA instanceof Patient) || ! (patientB instanceof Patient)) {
    throw new Error('Les patients doivent être des instances de Patient');
  }
  return ReplacementItem.getReplacementFieldsFromObjects(patientA, patientB, {
    LabelGetterMap: {
      gender: (value) => GENDERS[value.toUpperCase()].label,
      civility: (value) => CIVILITIES[value.toUpperCase()].label,
      'billDataset.grandRegime': (value) => value.libelle,
    },
    conflictsIgnoreMap: { gender: (oldGender) => oldGender === GENDERS.UNKNOWN.value },
    forceMergeObjectsMap: { 'billDataset.grandRegime': (grandRegimeLeft, grandRegimeRight) => grandRegimeLeft['@id'] === grandRegimeRight['@id'] },
    ...options,
  });
};