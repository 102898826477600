<template>
  <app-panel title="Coordonnées">
    <validation-observer ref="contactPointsObs">
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <app-phone-field
            v-model="mobile.value"
            v-test="'mobile-input'"
            :label="CONTACT_POINT_LABELS.MOBILE.label"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <app-phone-field
            v-model="otherPhone.value"
            v-test="'other-phone-input'"
            :label="CONTACT_POINT_LABELS.OTHER.label"
          />
        </v-col>
        <v-col
          v-for="phone in miscellaneousPhones"
          :key="phone['@id']"
          cols="12"
          md="6"
          lg="4"
        >
          <app-phone-field
            v-model="phone.value"
            v-test="'misc-phone-input'"
            :label="getPhoneFieldLabel(phone.label)"
            @input="setContactPoint({ ...phone })"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <app-text-field
            v-model="email.value"
            v-test="'email-input'"
            :prepend-icon="CONTACT_POINT_LABELS.EMAIL.icon"
            :label="CONTACT_POINT_LABELS.EMAIL.label"
            hide-errors="auto"
            validation-mode="aggressive"
            rules="email"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mt-n4"
        >
          <patient-address-fieldset
            ref="patientAddress"
            v-model="newAddress"
            class="mt-4"
            @input="$emit('update:address', $event)"
          />
        </v-col>
      </v-row>
    </validation-observer>
  </app-panel>
</template>

<script>

import { ValidationObserver } from 'vee-validate';

import { CONTACT_POINTS, CONTACT_POINT_LABELS, MEDIA_TYPES } from '@/constants';
import Address from '@/models/Address';
import ContactPoint from '@/models/ContactPoint';

import AppPhoneField from '@/components/ui/form/AppPhoneField.vue';
import AppTextField from '@/components/ui/form/AppTextField.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import PatientAddressFieldset from '@/modules/patient/components/patientForm/fieldsets/contactInfo/PatientAddressFieldset.vue';

export default {
  name: 'ContactInfoFieldset',
  components: {
    ValidationObserver,
    PatientAddressFieldset,
    AppPanel,
    AppTextField,
    AppPhoneField,
  },
  props: {
    contactPoints: {
      type: Array,
      default: null,
    },
    address: {
      type: Address,
      default: () => new Address(),
    },
  },
  data () {
    return {
      CONTACT_POINT_LABELS,
      newAddress: null,
    };
  },
  computed: {
    mobile () {
      return this.contactPoints.find(cp => cp.label === CONTACT_POINT_LABELS.MOBILE.value);
    },
    otherPhone () {
      return this.contactPoints.find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.OTHER.value));
    },
    miscellaneousPhones () {
      return this.contactPoints.filter(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value)
        && cp.label !== CONTACT_POINT_LABELS.OTHER.value
        && cp.label !== CONTACT_POINT_LABELS.MOBILE.value) ?? [];
    },
    email () {
      return this.contactPoints.find(cp => (cp.media === MEDIA_TYPES.EMAIL.value) && ! cp.label);
    },
  },
  watch: {
    address: {
      immediate: true,
      handler (newAddress) {
        this.newAddress = newAddress;
      },
    },
    'mobile.value': function handleMobileChange() {
      this.setContactPoint({ ...this.mobile });
    },
    'otherPhone.value': function handleOtherPhoneChange() {
      this.setContactPoint({ ...this.otherPhone });
    },
    'email.value': function handleEmailChange() {
      this.setContactPoint(this.email);
    },
  },
  methods: {
    setContactPoint (newContactPoint) {
      const updatedContactPoints = this.contactPoints.map(contactPoint => {
        // Le contactPoint édité est déja enregistré en base
        if (contactPoint['@id'] && (contactPoint['@id'] === newContactPoint['@id'])) {
          return new ContactPoint(newContactPoint);
        }
        // Le contactPoint édité ne fait pas partie de la base mais correspond à un couple media/label déjà existant
        // Note : on permet aux téléphones autres que Autre ou Mobile d'être non uniques dans une même catégorie (importés)
        if ((contactPoint.media === newContactPoint.media)
          && (contactPoint.label === newContactPoint.label)
          && (! contactPoint.label || Object.values(CONTACT_POINTS).find(cp => cp.label.value === contactPoint.label))
        ) {
          return new ContactPoint(newContactPoint);
        }
        return contactPoint;
      });
      this.$emit('update:contactPoints', updatedContactPoints);
    },
    getPhoneFieldLabel (phoneCategory) {
      return Object.values(CONTACT_POINT_LABELS).find(labelItem => labelItem.value === phoneCategory)?.label;
    },
  },
};
</script>

<style></style>