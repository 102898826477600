<template>
  <app-confirm-modal
    :is-open="value !== null"
    title="Supprimer la plage de disponibilité"
    confirm-color="error"
    confirm-text="Supprimer"
    data-test="confirm-modal"
    @cancel="$emit('input', null)"
    @confirm="confirmDeleteAvailabilitySettings"
  >
    <div>
      <p>Êtes-vous sûr(e) de vouloir supprimer cette plage de disponibilité ?</p>
      <div v-if="isRecurrent">
        <p class="mb-0">
          <b>Attention, cette plage de disponibilité est une plage récurrente</b>
        </p>
        <p class="mb-0">
          Pour annuler une partie de la disponibilité récurrente, vous pouvez ajouter une absence sur la période voulue.
        </p>
      </div>
    </div>
  </app-confirm-modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import AvailabilitySettings from '@/modules/agenda/models/events/AvailabilitySettings';

import AppConfirmModal from '@/components/ui/modal/AppConfirmModal.vue';


export default {
  name: 'AvailabilityDeleteConfirmModal',
  components: { AppConfirmModal },
  props: {
    /**
     * Le modèle de settings de plage de disponibilité à supprimer
     * @model
     */
    value: {
      type: AvailabilitySettings,
      default: null,
    },
  },
  computed: {
    isRecurrent () {
      return !! this.value?.recurrencePattern;
    },
  },
  methods: {
    ...mapActions('agenda', ['deleteAvailabilitySettings']),
    ...mapMutations('app', { setSnack: 'SET_SNACK' }),
    async confirmDeleteAvailabilitySettings () {
      try {
        await this.deleteAvailabilitySettings(this.value);
        this.setSnack({ message: 'La plage de disponibilité a été supprimée avec succès' });
      } catch { /* empty */ }
      this.$emit('input', null);
    },
  },
};
</script>