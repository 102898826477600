<template>
  <app-panel
    title="Correspondants"
    header-divided
    class="correspondents-panel mb-last-0"
    :quantity="correspondents.length"
    :actions="actions"
  >
    <div
      v-if="loading"
      class="mb-last-0"
    >
      <div
        v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
        :key="index"
        v-test="'correspondent-skeleton'"
        class="mb-4"
      >
        <app-skeleton-loader
          type="text"
          width="40%"
        />
        <app-skeleton-loader
          type="text"
          width="60%"
        />
      </div>
    </div>
    <div
      v-else-if="correspondents.length > 0"
      class="mb-last-0"
    >
      <correspondents-panel-item
        v-for="correspondent in correspondents"
        :key="correspondent['@id']"
        v-test="correspondent.isReferringPhysician ? 'patient-referring-physician' : 'patient-correspondent'"
        :correspondent="correspondent"
        class="mb-3 correspondents-panel__divider"
        @delete-correspondent="deleteCorrespondent"
        @edit-correspondent="editCorrespondent"
      />
    </div>
    <p
      v-else
      class="mb-0 secondary--text"
      data-test="correspondents-panel-no-data"
    >
      Aucun correspondant
    </p>
    <drawer-modal-form
      v-test="'add-correspondent-panel'"
      title="Ajouter des correspondants"
      :form="AddCorrespondentsForm"
      :value="editingPatient"
      :is-canceled="isCanceled"
      submit-text="Enregistrer"
      @cancel="cancelCorrespondent"
      @submitSuccess="submitSuccess"
    />
    <drawer-modal-form
      v-test="'edit-correspondent-panel'"
      title="Modifier un correspondant"
      :form="EditCorrespondentForm"
      :value="editingCorrespondent"
      submit-text="Enregistrer"
      @cancel="editingCorrespondent = null"
      @submitSuccess="submitEditingSuccess"
    />
  </app-panel>
</template>

<script>
import Patient from '@/modules/patient/models/Patient';
import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, putToAPI } from '@/services/api';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import DrawerModalForm from '@/components/ui/modal/DrawerModalForm.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import CorrespondentsPanelItem from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/CorrespondentsPanelItem.vue';
import AddCorrespondentsForm from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/AddCorrespondentsForm.vue';
import EditCorrespondentForm from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/EditCorrespondentForm.vue';


const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 4;

export default {
  name: 'CorrespondentsPanel',
  components: {
    AppPanel,
    AppSkeletonLoader,
    CorrespondentsPanelItem,
    DrawerModalForm,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      correspondents: [],
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
      loading: false,
      editingPatient: null,
      editingCorrespondent: null,
      actions: {
        add: {
          callback: this.openCorrespondentAdditionForm,
          icon: 'add',
          text: 'Ajouter un correspondant',
        },
      },
      AddCorrespondentsForm,
      EditCorrespondentForm,
      isCanceled: false,
    };
  },
  async created () {
    await this.loadCorrespondents();
  },
  methods: {
    async loadCorrespondents () {
      this.loading = true;

      const { data } = await getFromAPI(
        `/api/patients/${this.patient.getUuid()}/correspondents?order[contact.familyName]=asc&order[contact.firstNames]=asc`,
      );
      this.correspondents = data['hydra:member'] ? data['hydra:member'].map(correspondent => new PatientCorrespondent({
        ...correspondent,
        patient: this.patient.getIri(),
        isReferringPhysician: this.isReferringPhysician(correspondent),
      })) : [];
      this.loading = false;
    },
    openCorrespondentAdditionForm () {
      this.editingPatient = this.patient;
    },
    deleteCorrespondent (correspondent) {
      if (! correspondent.isReferringPhysician) {
        NovaTools.dialog.confirm(
          'Supprimer un correspondant',
          `Voulez-vous vraiment supprimer ${correspondent.contact.getCivilState()} des correspondants de ce patient ?`,
          () => {
            this.confirmDeleteCorrespondent(correspondent);
          },
        );
      }
    },
    async confirmDeleteCorrespondent (correspondent) {
      await putToAPI(correspondent.patient, { data: { deletionCorrespondent: correspondent['@id'] } });
      await this.loadCorrespondents();
      NovaTools.notify.success('Le correspondant a été supprimé avec succès');
    },
    submitSuccess () {
      this.editingPatient = null;
      this.loadCorrespondents();
    },
    editCorrespondent (correspondent) {
      this.editingCorrespondent = correspondent;
    },
    cancelCorrespondent () {
      this.editingPatient = null;
      this.isCanceled = ! this.isCanceled;
    },
    submitEditingSuccess () {
      this.editingCorrespondent = null;
      this.loadCorrespondents();
    },
    isReferringPhysician (correspondent) {
      return correspondent.contact['@id'] === this.patient.referringPhysician?.['@id'];
    },
  },
};
</script>

<style scoped lang="scss">
.correspondents-panel {
  &__divider:not(:first-child) {
    border-top: 1px solid var(--v-secondary-lighten4);
    padding-top: 15px;
    margin-top: 25px;
  }
}
</style>