<template>
  <div class="choice-divider">
    <n-divider />
    <div class="choice-divider__central-part">
      ou
    </div>
    <n-divider />
  </div>
</template>
<script>
import NDivider from '@/nova-ui/NDivider/NDivider.vue';

export default {
  name: 'ChoiceDivider',
  components: { NDivider },
};
</script>
<style lang="scss" scoped>
  .choice-divider {
    display: flex;
    width: 100%;
    max-width: 450px;
    height: 40px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &__central-part {
      border: 1px solid #e9ebf0;
      border-radius: 25px;
      width: 30px;
      height:30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>