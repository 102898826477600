<template>
  <app-modal-form
    :title="tlsiModalMixin_modalFormTitle"
    submit-text="Valider"
    :form="forms[currentStep].form"
    v-bind="forms[currentStep].props"
    :width="forms[currentStep].width"
    persistent
    :is-opened="tlsiModalMixin_isOpen"
    @cancel="handleCancel"
    @submitSuccess="forms[currentStep].methodOnSuccess"
  >
    <template #actions-left>
      <n-button
        v-if="currentStep === 2"
        v-test="'previous-step-btn'"
        label="Précédent"
        bg-color="primary"
        @click="previousStep"
      />
    </template>
  </app-modal-form>
</template>

<script>
import { mapGetters } from 'vuex';

import tlsiModalMixin from '@/modules/patient/components/sidebar/tlsi/tlsiModalMixin';
import { TLSI_TYPES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import AppModalForm from '@/components/ui/modal/AppModalForm.vue';
import AatiBeneficiarySelectorForm from '@/modules/patient/components/sidebar/tlsi/aati/AatiBeneficiarySelectorForm.vue';
import AatiConfirmationForm from '@/modules/patient/components/sidebar/tlsi/aati/AatiConfirmationForm.vue';
import AatiForm from '@/modules/patient/components/sidebar/tlsi/aati/AatiForm.vue';
import AatiResultModal from '@/modules/patient/components/sidebar/tlsi/aati/AatiResultModal.vue';



export default {
  name: 'AatiModalForm',
  components: { AppModalForm },
  mixins: [tlsiModalMixin(TLSI_TYPES.AATI)],
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      AatiBeneficiarySelectorForm,
      AatiForm,
      AatiConfirmationForm,
      currentStep: 0,
      apiRequestParams: null,
      selectedBeneficiary: null,
      source: null,
    };
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasVitaleCard']),
    forms () {
      return [
        {
          form: AatiBeneficiarySelectorForm,
          props: {
            patient: this.patient,
            prescriberData: this.apiRequestParams?.prescriberData ?? null,
          },
          width: '720',
          methodOnSuccess: (event) => this.openAatiForm(event),
        },
        {
          form: AatiForm,
          props: {
            formData: this.apiRequestParams,
            patient: this.patient,
            beneficiary: this.selectedBeneficiary,
            dataSource: this.source,
          },
          width: '50%',
          methodOnSuccess: (event) => this.openAatiConfirmationForm(event),
        },
        {
          form: AatiConfirmationForm,
          props: {
            patient: this.patient,
            beneficiary: this.selectedBeneficiary,
            formData: this.apiRequestParams,
          },
          width: '50%',
          methodOnSuccess: (event) => this.handleAatiRequestSuccess(event),
        },
      ];
    },
  },
  watch: {
    getHasVitaleCard: {
      immediate: true,
      handler () {
        if (this.source === 'CV' && ! this.getHasVitaleCard) {
          this.resetModalForm();
        }
      },
    },
  },
  mounted () {
    this.setApiRequestParams();
  },
  methods: {
    openAatiForm ({ selectedBeneficiary, dataSource, apiRequestParams }) {
      this.selectedBeneficiary = selectedBeneficiary.patient;
      this.source = dataSource;
      this.apiRequestParams = {
        ...this.apiRequestParams,
        ...apiRequestParams,
      };
      this.nextStep();
    },
    openAatiConfirmationForm (formData) {
      this.apiRequestParams = {
        ...this.apiRequestParams,
        ...formData,
      };
      this.nextStep();
    },
    handleAatiRequestSuccess (responsePayload) {
      if (responsePayload) {
        this.tlsiModalMixin_closeModalForm();
        this.openAatiResultModal(responsePayload);
      }
    },
    openAatiResultModal (responsePayload) {
      const { id } = NovaTools.modal.append(AatiResultModal, {
        responsePayload,
        isOpen: true,
      }, {
        'update:is-open': () => {
          this.resetModalForm();
          NovaTools.modal.destroy(id);
        },
      });
    },
    handleCancel () {
      NovaTools.dialog.confirm(
        'Téléservice AATi',
        'Êtes-vous sûr(e) de vouloir abandonner la saisie d\'un arrêt de travail ?',
        () => {
          this.tlsiModalMixin_closeModalForm();
          this.resetModalForm();
        },
      );
    },
    setApiRequestParams () {
      this.apiRequestParams = {
        patient: null,
        template: null,
        vitaleData: null,
        medicalLeaveData: {
          patientConsent: false,
          nature: null,
          type: null,
          ald: false,
          pregnancy: false,
          atmp: false,
          atmpDate: null,
          childDeath: false,
          childDeathDate: null,
          aldPartial: false,
          atmpPartial: false,
          atmpPartialDate: null,
          motiveCode: null,
          motiveLabel: null,
          motiveInformation: null,
        },
        backgroundData: {
          thirdPartyAccident: false,
          thirdPartyAccidentDate: null,
          thermalCure: false,
          warPensioner: false,
        },
        durationData: {
          startDate: null,
          endDate: null,
          endPartialDate: null,
        },
        prescriberData: {
          code: null,
          label: null,
          familyName: null,
          firstName: null,
          isLiberal: true,
          rpps: null,
          am: null,
          company: null,
          address: null,
          finessStructure: null,
          amStructure: null,
          siretStructure: null,
        },
        employerData: {
          company: null,
          address: {
            street: null,
            zipcode: null,
            city: null,
          },
          phone: null,
          email: null,
        },
        patientVisitingAddressData: {
          source: null,
          stair: null,
          floor: null,
          apartment: null,
          building: null,
          code: null,
          street: null,
          zipcode: null,
          city: null,
          phone: null,
        },
        outingData: {
          outing: false,
          outingDate: null,
          freeTime: false,
          freeTimeDate: null,
          motiveFreeTime: null,
        },
        activityData: {
          activity: false,
          activityDate: null,
        },
      };
    },
    resetModalForm () {
      this.currentStep = 0;
      this.selectedBeneficiary = null;
      this.source = null;
      this.setApiRequestParams();
    },
    nextStep() {
      this.currentStep += 1;
    },
    previousStep() {
      this.currentStep -= 1;
    },
  },
};
</script>