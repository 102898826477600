<template>
  <svg
    role="button"
    width="300"
    class="icon-pro-sante-connect-couleurs"
    @click="connect"
  >
    <use xlink:href="#icon-pro-sante-connect-couleurs" />
  </svg>
</template>
<script>

export default {
  name: 'PscButton',
  props: {
    isInAssociationMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    connect () {
      const responseType = 'code';
      const authUrl = `${import.meta.env.VUE_APP_PRO_SANTE_CONNECT_BASE_URL}/auth/`;
      const redirectUrl = `${import.meta.env.VUE_APP_PRO_SANTE_CONNECT_REDIRECT_URL}${this.isInAssociationMode
        ? '/parametres/pro-sante-connect' : ''}`;
      const clientId = import.meta.env.VUE_APP_PRO_SANTE_CONNECT_CLIENT_ID;
      const scope = 'openid scope_all';
      const state = this.generateValue(27);
      const nonce = this.generateValue(43);
      const eidas = 'eidas1';

      const parameters = [];
      parameters.push(`response_type=${responseType}`);
      parameters.push(`scope=${scope}`);
      parameters.push(`client_id=${clientId}`);
      parameters.push(`state=${state}`);
      parameters.push(`redirect_uri=${redirectUrl}`);
      parameters.push(`nonce=${nonce}`);
      parameters.push(`acr_values=${eidas}`);

      localStorage.setItem('psc', JSON.stringify({
        state,
        nonce,
      }));

      const url = `${authUrl}?${parameters.join('&')}`;
      window.open(url, '_self');
    },
    generateValue (length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
  },
};
</script>