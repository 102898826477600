import { extend } from 'vee-validate';

/**
 * Classe servant à définir une règle de validation pour un champ d'un formulaire
 */
export default class RuleValidator {
  /**
   * @param {String} name
   * @param {Function} validator
   * @param {Object} options
   */
  constructor (name, validator, options = {}) {
    this.name = name;
    this.validator = validator;
    this.options = options;
  }

  /**
   * Permet d'enregistrer la règle de manière globale
   */
  register () {
    extend(this.name, {
      validate: this.validator,
      ...this.options,
    });
  }
}