import Vue from 'vue';
import VueRouter from 'vue-router';

import routerDefinition, { checkUserLoggedIn } from '.';

Vue.use(VueRouter);

const router = new VueRouter(routerDefinition);
router.beforeEach(checkUserLoggedIn);

export default router;