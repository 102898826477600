
import ApiEntity from '@/models/ApiEntity';
import { PRESCRIPTION_TYPES } from '@/modules/patient/constants';
import PrescriptionActLine from '@/modules/patient/models/prescriptionLine/PrescriptionActLine';
import PrescriptionCodifiedLppLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/prescriptionLppLine/PrescriptionCodifiedLppLine';
import PrescriptionCustomLppLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/prescriptionLppLine/PrescriptionCustomLppLine';
import PrescriptionCompoundedMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine/PrescriptionCompoundedMedicineLine';
import PrescriptionPackagedSpecialtyMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine/PrescriptionPackagedSpecialtyMedicineLine';
import PrescriptionVirtualSpecialtyMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine/PrescriptionVirtualSpecialtyMedicineLine';
import PrescriptionParapharmacyLines
  from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionParapharmacyLine';
import { getFromAPI } from '@/services/api';

export default class Prescription extends ApiEntity {
  constructor (options = {}) {
    super(options);

    this.ald = options.ald || false;
    this.consultation = options.consultation || null;
    this.contentUrl = options.contentUrl || null;
    this.document = options.document || null;
    this.eprescription = options.eprescription || null;
    this.name = options.name || null;
    this.type = options.type || null;
    this.dispensingPharmacy = options.dispensingPharmacy || null;
    this.homeBasedCare = options.homeBasedCare || false;
    this.comment = options.comment || null;

    this.actLines = options.actLines
      ? options.actLines.map(aLine => new PrescriptionActLine(aLine))
      : [];

    // Prescription de produit de santé: Spécialités médicamenteuses
    this.packagedSpecialtyMedicineLines = options.packagedSpecialtyMedicineLines
      ? options.packagedSpecialtyMedicineLines.map(pLine => new PrescriptionPackagedSpecialtyMedicineLine(pLine))
      : [];

    // Prescription de produit de santé: Spécialités virtuelles
    this.virtualSpecialtyMedicineLines = options.virtualSpecialtyMedicineLines
      ? options.virtualSpecialtyMedicineLines.map(pLine => new PrescriptionVirtualSpecialtyMedicineLine(pLine))
      : [];

    // Prescription de produit de santé : Préparation magistrale
    this.compoundedMedicineLines = options.compoundedMedicineLines
      ? options.compoundedMedicineLines.map(pLine => new PrescriptionCompoundedMedicineLine(pLine))
      : [];

    // Prescription de produit de santé : LPPs codifiés
    this.codifiedLppLines = options.codifiedLppLines
      ? options.codifiedLppLines.map(pLppLine => new PrescriptionCodifiedLppLine(pLppLine))
      : [];

    // Prescription de produit de santé : LPPs personnalisés
    this.customLppLines = options.customLppLines
      ? options.customLppLines.map(pLppLine => new PrescriptionCustomLppLine(pLppLine))
      : [];

    // Prescription de produit de santé : Produits de parapharmacie
    this.parapharmacyProductLines = options.parapharmacyProductLines
      ? options.parapharmacyProductLines.map(line => new PrescriptionParapharmacyLines(line))
      : [];
  }

  toString () {
    return this.getAllLines().map(pLine => pLine.toString()).join(' - ');
  }

  getSpecialtyMedicineLines () {
    return [
      ...this.packagedSpecialtyMedicineLines,
      ...this.virtualSpecialtyMedicineLines,
    ];
  }

  getTypeText () {
    return Object.values(PRESCRIPTION_TYPES).find(pType => pType.value === this.type)?.text || 'Inconnu';
  }

  isEprescription () {
    return !! this.eprescription;
  }

  getLppLines () {
    return [
      ...this.customLppLines,
      ...this.codifiedLppLines,
    ];
  }

  getParapharmacyLines () {
    return this.parapharmacyProductLines;
  }

  /**
   * Récupérer toutes les lignes de l'ordonnance
   * @returns {{}}
   */
  getAllLines () {
    return [
      ...this.actLines,
      ...this.getSpecialtyMedicineLines(),
      ...this.getParapharmacyLines(),
      ...this.compoundedMedicineLines,
      ...this.getLppLines(),
    ];
  }

  /**
   * Met à null les IRIs (@id) de l'ordonnance et de ses lignes
   * Sert pour le renouvellement d'ordonnance et l'utilisation d'une ordo type
   */
  emptyIris () {
    this['@id'] = null;
    this.getAllLines().forEach((prescriptionLine) => {
      prescriptionLine['@id'] = null;
    });
  }

  /**
   * Permet de récupérer l'ensemble des ordonnances depuis l'API
   * @param patientIri
   * @param {Object} params
   * @returns {PrescriptionTemplate[]}
   */
  static async fetchAll (patientIri, params = {}) {
    const { data } = await getFromAPI(`${patientIri}/prescriptions`, params);
    return data['hydra:member'].map(prescription => new Prescription(prescription));
  }

  /**
   * Permet de récupérer les ordonnances type filtrées depuis l'API
   * @param patientIri
   * @param {String} filter
   * @returns {PrescriptionTemplate[]}
   */
  static async fetchAllFilterByText (patientIri, filter = '') {
    return this.fetchAll(patientIri, { global: filter });
  }
}