<template>
  <div class="agenda-toolbar-left">
    <agenda-navigation v-if="!isMobile" />
    <div
      v-else
      class="d-flex align-center"
    >
      <div
        class="agenda-toolbar__day"
        @click="isMiniCalendarActive = !isMiniCalendarActive"
      >
        <span class="mr-2">{{ formattedCurrentDate }}</span>
        <v-icon
          size="10"
          color="blue-grey lighten-1"
          class="agenda-toolbar__day-arrow"
          :class="{ active: isMiniCalendarActive }"
        >
          fas fa-chevron-down
        </v-icon>
      </div>
    </div>
    <!-- mini calendrier mobile uniquement -->
    <v-slide-y-transition>
      <div
        v-if="isMiniCalendarActive && isMobile"
        class="agenda-toolbar__mini-calendar"
      >
        <agenda-mini-calendar
          v-click-outside="() => isMiniCalendarActive = false"
          @date-updated="isMiniCalendarActive = false"
        />
        <app-divider />
      </div>
    </v-slide-y-transition>
    <!-- mini calendrier mobile uniquement -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { format } from '@/utils/functions/dates';

import AppDivider from '@/components/ui/divider/AppDivider.vue';
import AgendaMiniCalendar from '@/modules/agenda/components/AgendaMiniCalendar.vue';
import AgendaNavigation from '@/modules/agenda/components/AgendaNavigation.vue';

export default {
  name: 'AgendaToolbarLeft',
  components: {
    AgendaNavigation,
    AgendaMiniCalendar,
    AppDivider,
  },
  data () {
    return { isMiniCalendarActive: false };
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapState('agenda', ['currentDate']),
    formattedCurrentDate () {
      return format(this.currentDate, 'iii dd MMM yyyy');
    },
  },

};
</script>

<style lang="scss" scoped>
.agenda-toolbar {
  &__mini-calendar {
    position: absolute;
    left: 0;
    width: 100%;
    top:100%;
  }

   &__day {
    user-select: none;
    font-weight: 600;
    cursor: pointer;

    &-arrow {
      transition: transform .3s ease;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>