<template>
  <v-container
    class="app-container"
    :class="{ 'app-container--fluid': fluid }"
    :fluid="fluid"
  >
    <slot />
  </v-container>
</template>

<script>
/**
 * Conteneur de l'application
 * @see {@link https://vuetifyjs.com/en/api/v-container/#component-pages}
 */
export default {
  name: 'AppContainer',
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  &:not(.app-container--fluid) {
    max-width: 1200px;
    padding: map-get($spacers, 5)
  }

  &--fluid {
    padding: 0;
  }
}
</style>