<template>
  <div>
    <n-autocomplete
      ref="autocompleteInput"
      v-test="'autocomplete-search-correspondent'"
      :clear-on-select="true"
      :url="url"
      :label="label"
      item-value="@id"
      :item-text="getCorrespondentFullname"
      :min-search-length="3"
      :disabled="disabled"
      @input="selectCorrespondent => $emit('change', selectCorrespondent)"
    >
      <template #selection="{ item }">
        {{ getCorrespondentFullname(item) }}
      </template>
      <template #item="{ item }">
        <v-list-item-action>
          <app-icon icon="user" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ getCorrespondentFullname(item) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ getKnowHow(item) || item.contact.profession }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.contact.practitionerProfile && item.contact.practitionerProfile.rpps">
            {{ item.contact.practitionerProfile.rpps }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </n-autocomplete>
  </div>
</template>

<script>
import Correspondent from '@/modules/contact/models/NewHealthProfessional';
import { NAutocomplete } from '@/nova-ui';

/**
 * Affiche un champ de recherche de correspondent
 * Ce champ affiche la liste des healthProfessionals issus du RPPS
 */
export default {
  name: 'CorrespondentInput',
  components: { NAutocomplete },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Recherchez dans l\'annuaire local',
    },
    url: {
      type: String,
      default: '/api/correspondents',
    },
  },
  methods: {
    getCorrespondentFullname (correspondent) {
      return correspondent ? new Correspondent(correspondent).getCivilState() : '';
    },
    getKnowHow (correspondent) {
      return correspondent ? new Correspondent(correspondent).getKnowHow() : null;
    },
  },
};
</script>