<template>
  <v-dialog
    v-model="localShowModal"
    fullscreen
    persistent
  >
    <fullscreen-layout
      title="Revenir sur easy-care"
      :header-enabled="headerEnabled"
      @goBack="closeModal"
    >
      <iframe
        v-if="localShowModal"
        id="modal-efficience-dmp"
        ref="iframeEfficienceDmp"
        class="iframe-style"
        width="100%"
        loading="lazy"
        :src="url"
      />
    </fullscreen-layout>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex';

import NovaTools from '@/nova-tools/NovaTools';
import { getEnv } from '@/utils/functions/env';
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

import FullscreenLayout from '@/layout/Fullscreen.vue';

export default {
  name: 'EfficienceModalDmp',
  components: { FullscreenLayout },
  mixins: [
    localCopyMixin({
      propertyName: 'showModal',
      copyPropertyName: 'localShowModal',
    }),
  ],
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['isPartnerAccess']),
    headerEnabled () {
      return ! this.isPartnerAccess;
    },
  },
  watch: {
    localShowModal: {
      immediate: true,
      handler (localShowModal) {
        if (! localShowModal) {
          window.removeEventListener('message', this.onMessage);
          return;
        }
        window.addEventListener('message', this.onMessage);
      },
    },
  },
  methods: {
    closeModal () {
      this.localShowModal = false;
    },
    async sendCodePinToEfficienceIframe () {
      const pinCode =  NovaTools.security.codePin.getCodePin();
      if (pinCode === null) {
        return;
      }
      const iframe = this.$refs.iframeEfficienceDmp;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(JSON.stringify({ pinCode }), '*');
      }
    },
    async onMessage ({ origin, data }) {
      if (origin !== getEnv('VUE_APP_ICANOPEE_EFFICIENCE_BASE_URL')) {
        return;
      }
      if (data.type === 'waiting_for_pincode_entry') {
        await this.sendCodePinToEfficienceIframe();
        return;
      }
      if (data.type === 'login' && Object.keys(data.hpInfos).length > 0) {
        window.removeEventListener('message', this.onMessage);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .fullscreen-layout {
    background-color: #FFFFFF;
    ::v-deep {
      .page-layout {
        .page-layout__content {
          padding: 0;
          display: flex;
        }
      }
    }
  }

  .iframe-style {
    border: none;
    flex: 1;
  }
</style>