import VCard from 'vuetify/lib/components/VCard/VCard';
import { mapState } from 'vuex';

import FormMixin from '../../form/mixins/FormMixin';

export default {
  props: {
    /**
     * Indique si la modale reste visible en cliquant sur le fond
     * @default false
     */
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Largeur de la modale
     * @default '480px'
     */
    width: {
      type: String,
      required: false,
      default: '480px',
    },
    /**
     * Le texte du bouton de confirmation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Enregistrer"
     */
    submitText: {
      type: String,
      required: false,
      default: 'Enregistrer',
    },
    /**
     * La couleur du bouton de confirmation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "primary"
     */
    submitColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    /**
     * Le texte du bouton d'annulation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Annuler"
     */
    cancelText: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    /**
     * La couleur du bouton d'annulation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "blue-grey"
     */
    cancelColor: {
      type: String,
      required: false,
      default: 'blue-grey',
    },
  },
  mixins: [FormMixin],
  inheritAttrs: false,
  data () {
    return { VCardWrapper: VCard };
  },
  computed: {
    ...mapState('app', ['isMobile']),
    modalTransition () {
      return this.isMobile ? 'dialog-bottom-transition' : 'dialog-transition';
    },
  },
  methods: {
    handleKey (kbEvent) {
      if (kbEvent.key === 'Escape' && ! this.persistent) {
        this.$emit('cancel');
      }
    },
  },
};