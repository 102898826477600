<template>
  <div style="height: 68px">
    <div style="font-size: 13px;font-weight: 600;color: #37467A;height: 20px">
      Période
    </div>
    <div style="display: flex;gap: 16px;align-items: end">
      <app-select
        v-model="localValue.typeDate"
        :items="typesDate"
        style="width: 33%"
        label="Recherche sur date de"
        item-text="value"
        item-value="value"
        @input="onChangeType"
      />
      <app-date-field
        v-model="localValue.startDate"
        style="width: 33%"
        :disabled="!localValue.typeDate"
        label="Du"
        hide-details
        @input="onChangeStartDate"
      />
      <app-date-field
        v-model="localValue.endDate"
        style="width: 33%"
        :disabled="!localValue.typeDate"
        label="Au"
        hide-details
        @input="onChangeEndDate"
      />
      <app-button
        v-if="localValue.typeDate"
        icon="close"
        @click="reset"
      />
    </div>
  </div>
</template>
<script>
import { format, subDays, add } from '@/utils/functions/dates';

import AppDateField from '@/components/ui/form/AppDateField.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';

export default {
  name: 'PrescriptionPeriodFilter',
  components: {
    AppDateField,
    AppSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    durationMax: {
      type: Number,
      default: 60,
    },
  },
  data () {
    return {
      typesDate: [{
        value: 'prescription',
        label: 'prescrit',
      }, {
        value: 'execution',
        label: 'exécuté',
      }, {
        value: 'modification',
        label: 'modifié',
      }],
      localValue: {
        typeDate: null,
        startDate: null,
        endDate: null,
        labelTag: null,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.localValue = newValue;
      },
    },
  },
  methods: {
    onChangeType () {
      if (this.localValue.typeDate && ! this.localValue.endDate && ! this.localValue.startDate) {
        this.localValue.endDate = format(new Date(), 'yyyy-MM-dd');
        this.localValue.startDate = format(subDays(new Date(), (this.durationMax - 1)), 'yyyy-MM-dd');
      }
      this.update();
    },
    onChangeStartDate () {
      if (this.localValue.typeDate) {
        const endDateReference = format(add(new Date(this.localValue.startDate), { days: (this.durationMax - 1) }), 'yyyy-MM-dd');
        if (this.localValue.endDate > endDateReference) {
          this.localValue.endDate = endDateReference;
        }
      }
      this.update();
    },
    onChangeEndDate () {
      if (this.localValue.typeDate) {
        const startDateReference = format(subDays(new Date(this.localValue.endDate), this.durationMax), 'yyyy-MM-dd');
        if (this.localValue.startDate < startDateReference) {
          this.localValue.startDate = startDateReference;
        }
      }
      this.update();
    },
    update () {
      this.$emit('input', {
        typeDate: this.localValue.typeDate,
        startDate: this.localValue.startDate,
        endDate: this.localValue.endDate,
        labelTag: this.getLabelTag(),
      });
    },
    getLabelTag () {
      if (this.localValue.typeDate) {
        const labelStart = this.localValue.startDate ? ` à partir du <b>${format(this.localValue.startDate, 'dd/MM/yyyy')}</b>` : '';
        const labelEnd = this.localValue.endDate ? ` jusqu'au <b>${format(this.localValue.endDate, 'dd/MM/yyyy')}</b>` : '';
        const typePeriod = this.typesDate.filter(item => item.value === this.localValue.typeDate)[0].label;
        return `<b>${typePeriod}</b>${labelStart}${labelEnd}`;
      }
      return '';
    },
    getDefault () {
      return {
        typeDate: null,
        startDate: null,
        endDate: null,
        labelTag: null,
      };
    },
    reset () {
      this.$emit('input', this.getDefault());
    },
  },
};
</script>

<style scoped>

</style>