<template>
  <section>
    <v-row>
      <v-col
        cols="12"
        :md="!!generatePreviewContent ? '7' : '12'"
      >
        <p>Consultez puis validez les informations ci-dessous afin de générer la lettre de liaison.</p>
        <dmp-sending-action-message :patient-ins="patientIns" />

        <app-api-autocomplete
          :selected.sync="localRecipients"
          :url="patientCorrespondentsUrl"
          label="Destinataires"
          :item-model-class="PatientCorrespondent"
          item-value-field="@id"
          :item-text-field="getRecipientsFieldText"
          :prepend-icon="null"
          multiple
          chips
          clearable
          deletable-chips
          placeholder="Recherchez parmi les correspondants du patient"
          small-chips
          class="mb-4"
        />
        <app-wysiwyg-field
          v-model="localContent"
          rules="required"
          auto-focus
        />
      </v-col>

      <v-col
        v-if="hasPreviewFunction"
        v-test="'letters-form-fields-pdf-preview'"
        cols="12"
        md="5"
        class="letters-form-fields-pdf-preview"
      >
        <div
          v-if="!base64Data"
          v-test="'consultation-letter-preview-notice'"
          class="consultation-letter-preview-notice"
        >
          Merci de saisir un contenu et d'attendre la fin de la prévisualisation
          afin de pouvoir enregistrer le document
        </div>
        <validation-provider ref="validation-provider">
          <div
            v-if="isLoading"
            class="consultation-letter-preview-loading"
          >
            <app-circular-loader
              v-test="'consultation-letter-preview-loading'"
              :size="64"
              :width="4"
            />
          </div>
          <consultation-letter-preview
            v-else-if="base64Data"
            v-test="'consultation-letter-preview'"
            :base64-data="base64Data"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { debounce } from 'lodash';
import { ValidationProvider, validate } from 'vee-validate';

import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent';
import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity';
import { arrayToSentence } from '@/utils/functions/joiner';

import AppApiAutocomplete from '@/components/ui/form/apiAutocomplete/AppApiAutocomplete.vue';
import AppWysiwygField from '@/components/ui/form/AppWysiwygField.vue';
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';
import ConsultationLetterPreview from '@/modules/patient/components/consultation/ConsultationLetterPreview.vue';
import DmpSendingActionMessage from '@/modules/patient/components/insIdentity/DmpSendingActionMessage.vue';


export default {
  name: 'LettersFormFields',
  components: {
    AppApiAutocomplete,
    AppWysiwygField,
    ValidationProvider,
    AppCircularLoader,
    ConsultationLetterPreview,
    DmpSendingActionMessage,
  },
  props: {
    generatePreviewContent: {
      type: Function,
      required: false,
      default: null,
    },
    patientIri: {
      type: String,
      required: true,
    },
    patientIns: {
      type: PatientInsIdentity,
      required: true,
    },
    recipients: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      PatientCorrespondent,
      base64Data: '',
      isLoading: false,
      isPreviewedOk: false,
    };
  },
  computed: {
    patientCorrespondentsUrl () {
      return `${this.patientIri}/correspondents`;
    },
    localRecipients: {
      get () {
        return this.recipients;
      },
      set (recipients) {
        this.$emit('update:recipients', recipients);
      },
    },
    localContent: {
      get () {
        return this.content;
      },
      set (content) {
        this.$emit('update:content', content);
      },
    },
    hasPreviewFunction () {
      return this.generatePreviewContent !== null && typeof this.generatePreviewContent === 'function';
    },
  },
  watch: {
    content () {
      if (this.hasPreviewFunction) {
        this.isPreviewedOk = false;
        if (this.content) {
          this.previewDocumentDebounced();
        }
      }
    },
    recipients () {
      if (this.hasPreviewFunction) {
        this.isPreviewedOk = false;
        if (this.content) {
          this.previewDocument();
        }
      }
    },
    isPreviewedOk () {
      this.validateProviderPreviewHandler();
    },
  },
  mounted () {
    this.validateProviderPreviewHandler();
  },
  methods: {
    getRecipientsFieldText (patientCorrespondent) {
      const {contact} = patientCorrespondent;
      return arrayToSentence([contact?.getCivilState({ shortenFirstNames: true }), contact?.profession], ' · ', ' · ');
    },
    previewDocumentDebounced: debounce(function previewDocumentDebounced () {
      this.previewDocument();
    }, 1000),
    async previewDocument () {
      if (! this.hasPreviewFunction) {
        return;
      }
      this.isLoading = true;
      try {
        this.base64Data = await this.generatePreviewContent();
      } finally {
        this.isLoading = false;
        this.isPreviewedOk = this.base64Data !== null;
      }
    },
    async validateProviderPreviewHandler () {
      if (this.hasPreviewFunction) {
        const result = await validate(this.isPreviewedOk, 'checked');
        this.$refs['validation-provider'].applyResult(result);
      }
    },
  },
};
</script>
<style lang="scss">
.consultation-letter-preview-loading {
  display: flex;
  justify-content: center;
  padding-top: map-get($spacers, 5);
  height: 40vh;
}
.letters-form-fields-pdf-preview {
  min-height: 420px;
  text-align: center;
}
</style>