<template>
  <div class="app-wysiwyg">
    <editor
      ref="editor"
      v-model="localValue"
      v-test="'editor'"
      :api-key="apiKey"
      :init="cpConfig"
      :disabled="disabled"
      @onInit="handleInit"
      @keyup.backspace.native="handleInput"
      @keyup.delete.native="handleInput"
      @keydown.delete.native="$emit('keydown:delete')"
      @onFocus="$emit('focus')"
      @onBlur="$emit('blur')"
    />
    <input
      v-show="false"
      ref="fileInput"
      v-test="'file-input'"
      class="wysiwyg-file-input"
      type="file"
      accept="image/*"
      @change="handleFileInputChange"
    >
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin';

export default {
  name: 'AppWysiwyg',
  components: { Editor },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      apiKey: import.meta.env.VUE_APP_TINY_MCE_API_KEY,
      /**
       * Méthode permettant d'appeler le callback HydrateImageInsertionFormCallback @see {@link HydrateImageInsertionFormCallback}
       */
      hydrateImageInsertionFormCallback: () => {},
    };
  },
  computed: {
    cpConfig () {
      const defaultConfig = {
        ...this.config,
        language: 'fr_FR',
      };
      if (this.config?.plugins?.split(' ').includes('image')) {
        return {
          ...defaultConfig,
          file_picker_types: 'image',
          file_picker_callback: this.filePickerCallback,
        };
      }
      return defaultConfig;
    },
  },
  methods: {
    /**
     * Callback permettant d'hydrater le formulaire d'insertion d'une image
     * Pour plus d'informations : @see {@link https://www.tiny.cloud/docs/configure/file-image-upload/#file_picker_callback}
     * @callback HydrateImageInsertionFormCallback
     * @param blobUri L'uri de l'image à insérer
     * @param metaInfos Les informations liés à l'image (alt, name, etc...)
     *
     * Méthode permettant l'ajout d'une image dans le cache de tinymce
     * ainsi que son insertion dans le document.
     *
     * Elle est appelée lors du clic sur le bouton d'insertion d'un
     * document de la fenêtre modale "Insérer/modifier Image".
     *
     * @param {HydrateImageInsertionFormCallback}
     */
    filePickerCallback (callBack) {
      this.hydrateImageInsertionFormCallback = callBack;
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    handleFileInputChange () {
      const image = this.$refs.fileInput.files[0];
      // Permet de lire le contenu du blob
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => this.hydrateImageInsertionForm(image, reader.result);
    },
    /**
     * Permet d'hydrater le formulaire d'insertion d'une image
     * @param {File} image Les informations sur l'image à insérer @see {@link https://developer.mozilla.org/fr/docs/Web/API/File}
     * @param {String} readerResult Le contenu du fichier @see {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result}
     */
    hydrateImageInsertionForm (image, readerResult) {
      // Ajoute l'image dans le cache de tinymce
      const id = `blobid${(new Date()).getTime()}`;
      const {blobCache} = window.tinymce.activeEditor.editorUpload;
      const base64 = readerResult.split(',')[1];
      const blobInfo = blobCache.create(id, image, base64);
      blobCache.add(blobInfo);

      // Ajoute l'URI, la largeur ainsi que la hauteur dans le formulaire d'insertion d'une image
      this.hydrateImageInsertionFormCallback(blobInfo.blobUri(), { title: image.name });
    },
    getEditor () {
      return this.$refs.editor.editor;
    },
    focus () {
      this.getEditor()?.focus();
    },
    handleInit () {
      this.$emit('init', this.getEditor());
    },
    handleInput () {
      this.$emit('input', this.getEditor().getContent());
    },
  },
};
</script>