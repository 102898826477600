export const MEDIA_TYPES = Object.freeze({
  TELEPHONE: {
    value: 'telephone',
    label: 'Téléphone',
    icon: 'phone',
  },
  FAX: {
    value: 'fax',
    label: 'Télécopie',
    icon: 'fax',
  },
  EMAIL: {
    value: 'email',
    label: 'Email',
    icon: 'email',
  },
});