<template>
  <div class="event-recurrence">
    <v-switch
      v-model="hasRecurrence"
      label="Répéter ?"
      data-test="recurrence-toggle"
    />

    <div
      v-if="hasRecurrence"
      data-test="recurrence-form"
    >
      <span class="d-block">Répéter tou(te)s les :</span>
      <v-row>
        <v-col cols="2">
          <validation-provider
            v-slot="{ errors }"
            slim
            rules="min_value:1"
            name="Fréquence de répétition"
          >
            <v-text-field
              v-model="localValue.INTERVAL"
              type="number"
              :error-messages="errors"
              :hide-details="!errors.length"
              dense
              data-test="reccurence-interval-textfield"
            />
          </validation-provider>
        </v-col>
        <v-col cols="10">
          <validation-provider
            v-slot="{ errors }"
            slim
            rules="required"
            name="Type de répétition"
          >
            <v-select
              v-model="localValue.FREQ"
              dense
              :items="RECURRENCE_FREQUENCIES"
              :error-messages="errors"
              :hide-details="!errors.length"
              cache-items
              item-text="name"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        v-if="hasWeeklyFreq"
        data-test="recurrence-weekly-block"
      >
        <v-col>
          <span class="d-block">Répéter le :</span>
          <validation-provider
            v-slot="{ errors }"
            slim
            rules="required"
          >
            <app-buttons-group
              v-model="localValue.BYDAY"
              :items="weeklyRepetitionDays"
              multiple
              :error-messages="errors"
              :hide-details="!errors.length"
              full-width
              rounded
              data-test="appbuttongroup-weekdays"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="d-block">Se termine</span>
          <validation-provider
            v-slot="{ errors }"
            slim
            rules="required"
          >
            <v-radio-group
              v-model="reccurenceEndOption"
              mandatory
              :error-messages="errors"
              :hide-details="!errors.length"
            >
              <v-radio
                label="Jamais"
                value="NEVER"
                @change="localValue.UNTIL = null"
              />
              <v-row
                no-gutters
                class="d-flex align-baseline"
              >
                <v-col cols="2">
                  <v-radio
                    label="Le"
                    value="DATE"
                  />
                </v-col>
                <v-col cols="10">
                  <v-menu
                    v-model="recurrenceEndDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="recurrenceEndDateFormatted"
                        label="Date de fin"
                        data-test="recurrence-end-date-input"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :disabled="reccurenceEndOption !== 'DATE'"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="localValue.UNTIL"
                      :min="startDateNextDay"
                      data-test="recurrence-end-date-picker"
                      locale-first-day-of-year="4"
                      first-day-of-week="1"
                      @input="recurrenceEndDatePicker = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import { RECURRENCE_FREQUENCIES, RECURRENCE_FREQUENCIES_TYPES, WEEKLY_DAYS } from '@/modules/agenda/constants';
import RecurrencePattern from '@/modules/agenda/models/events/RecurrencePattern';
import { add, format } from '@/utils/functions/dates';

import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue';

export default {
  name: 'EventRecurrence',
  components: {
    ValidationProvider,
    AppButtonsGroup,
  },
  props: {
    value: {
      type: RecurrencePattern,
      required: false,
      default: null,
    },
    /**
     * Format yyyy-MM-dd
     */
    startDate: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      recurrenceEndDatePicker: false,
      weeklyRepetitionDays: WEEKLY_DAYS,
      RECURRENCE_FREQUENCIES,
    };
  },
  computed: {
    localValue: {
      get () {
        return this.value || new RecurrencePattern();
      },
      set (newVal) {
        this.$emit('input', newVal);
      },
    },
    hasWeeklyFreq () {
      return this.localValue?.FREQ === RECURRENCE_FREQUENCIES_TYPES.WEEKLY;
    },
    reccurenceEndOption: {
      get () {
        return this.localValue?.UNTIL
          ? 'DATE'
          : 'NEVER';
      },
      set (endOption) {
        if (endOption === 'NEVER') {
          this.localValue.UNTIL = null;
        } else {
          this.clampUntilDate();
        }
      },
    },
    recurrenceEndDateFormatted () {
      return this.localValue.UNTIL
        ? format(new Date(this.localValue.UNTIL), 'dd/MM/yyyy')
        : null;
    },
    hasRecurrence: {
      get () {
        return !! this.value;
      },
      set (switchValue) {
        this.setRecurrence(switchValue);
      },
    },
    startDateNextDay () {
      const nextDay = add(new Date(this.startDate), { days: 1 });
      return format(nextDay, 'yyyy-MM-dd');
    },
  },
  watch: {
    startDate () {
      this.clampUntilDate();
    },
  },
  methods: {
    clampUntilDate () {
      const untilTs = new Date(this.localValue.UNTIL).getTime();
      const startDateTs = new Date(this.startDate).getTime();
      if (untilTs <= startDateTs) {
        this.localValue.UNTIL = this.startDateNextDay;
      }
    },
    /**
     * @param {Boolean} option Défini une recurrence à null si non défini
     */
    setRecurrence (option) {
      if (! option) {
        this.$emit('input', null);
        return;
      }
      if (this.value) {
        this.$emit('input', new RecurrencePattern(cloneDeep(this.value)));
        return;
      }
      const currentDate = new Date(this.startDate);
      const currentWeekDay = WEEKLY_DAYS.find(weekDay => weekDay.index === currentDate.getDay());
      this.$emit('input', new RecurrencePattern({ BYDAY: [currentWeekDay.value] }));
    },
  },
};
</script>