export const ICANOPEE_COMMANDS = {
  // Général
  GET_SYSTEM_INFORMATION: 'hl_getSystemInformation',

  // Session
  OPEN_SESSION: 'hl_openSession',
  CLOSE_SESSION: 'hl_closeSession',
  GET_SESSION_STATE: 'hl_getSessionState',

  // DMP
  CREATE_DMP_CONNECTOR: 'hl_createDmpConnector',
  SEND_DMP_DOCUMENT: 'hl_sendDocument',

  // Lecteurs de carte
  GET_PCSC_READERS: 'hl_getPcscReaders',

  // CPx
  GET_CPX_CARD: 'hl_getCpxCard',
  GET_CPX_STATUS: 'hl_getCpxStatus', // Route utilisée pour débloquer le monitoring
  READ_CPX_CARD: 'hl_readCpxCard',
  START_CPX_CARD_MONITORING: 'hl_startCpxCardMonitoring',

  // Vitale
  GET_VITALE_CARD: 'hl_getVitaleCard',
  GET_VITALE_INFOS: 'hl_getVitaleInfos',
  READ_VITALE_CARD: 'hl_readVitaleCard',
  START_VITALE_CARD_MONITORING: 'hl_startVitaleCardMonitoring',

  // INSI
  CHECK_INS_IDENTITY: 'hl_checkInsIdentity',

  // TLSi
  CREATE_CONNECTION: 'hl_createConnection',
  CREATE_TLSI_CONNECTOR: 'hl_createTlsiConnector',
  GENERATE_EMPTY_TLSI_REQUEST: 'hl_generateEmptyTlsiRequest',
  GENERATE_EMPTY_TLSI_REQUEST_WITH_RAW_VITALE_DATA: 'hl_generateEmptyTlsiRequestWithRawVitaleData',
  GENERATE_EMPTY_TLSI_REQUEST_WITHOUT_PATIENT_DATA: 'hl_generateEmptyTlsiRequestWithoutPatientData',
  SEND_HTTP_REQUEST: 'hl_sendHttpRequest',

  // ApCV
  CREATE_APCV_CONNECTOR: 'hl_createApcvConnector',
  GET_APCV_CONTEXT: 'hl_getApCvContext',
  RELEASE_APCV_CONTEXT: 'hl_releaseApCvContext',
};