<template>
  <div class="user-dropdown">
    <v-menu
      v-model="isDropDownActive"
      offset-y
      bottom
      content-class="user-dropdown__menu"
      min-width="300"
      max-width="300"
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <app-avatar
            :gender="getCurrentUserGender"
            :type="getCurrentUserType"
            size="32px"
          />
        </div>
      </template>
      <section>
        <header class="user-dropdown__menu__header">
          <app-person-details
            hide-avatar
            :person="getCurrentUser.contact"
          >
            <template #sub-content>
              <span class="user-dropdown__user-email">{{ getCurrentUser.username }}</span>
            </template>
          </app-person-details>
          <div
            v-if="getCurrentUser.isAdmin"
            v-test="'account-is-admin'"
            class="user-dropdown__is-admin"
          >
            <v-icon
              size="10"
              class="mr-2 user-dropdown__is-admin-icon"
            >
              fas fa-crown
            </v-icon>
            <span>Admin</span>
          </div>
        </header>
        <n-divider :spacing="0" />
        <v-list tag="main">
          <v-list-item v-if="!isPartnerAccess" v-test="'settings-link'" :to="{ name: 'settings.account' }">
            <app-icon icon="settings" />Paramètres
          </v-list-item>
          <v-list-group prepend-icon="fa-circle-info">
            <template #activator>
              <span class="subtitle-3">Informations utiles</span>
            </template>
            <v-list class="py-0">
              <v-list-item
                v-for="link in utilLinks"
                :key="link.text"
                v-test="'util-link'"
                :href="link.href"
                :to="link.to"
                :target="link.href ? '_blank' : null"
              >
                {{ link.text }}
              </v-list-item>
            </v-list>
          </v-list-group>
        </v-list>
        <n-divider :spacing="0" />
        <footer class="py-2">
          <v-list-item
            v-test="'logout-link'"
            @click.prevent="logout"
          >
            <v-icon>fa-sign-out-alt</v-icon>Déconnexion
          </v-list-item>
        </footer>
      </section>
    </v-menu>
  </div>
</template>

<script>
/**
 * Onglet Utilisateur de la top bar de l'application
 * Au clic, ouvre un menu avec liens vers des écrans de paramétrage
 * et déconnexion
 */

import { mapGetters, mapActions, mapMutations } from 'vuex';


import { BASE_ROUTE_NAMES } from '@/constants';
import { ROUTE_NAMES } from '@/modules/dataPortability/constants';
import { postToAPI } from '@/services/api';
import { isPartnerAccess } from '@/services/partnerService';

import AppAvatar from '@/components/ui/avatar/AppAvatar.vue';
import AppPersonDetails from '@/components/ui/personDetails/AppPersonDetails.vue';

export default {
  name: 'UserDropdown',
  components: {
    AppAvatar,
    AppPersonDetails,
  },
  data () {
    return {
      isDropDownActive: false,
      utilLinks: [
        {
          text: 'Bioloinc',
          href: 'https://bioloinc.fr/',
        },
        {
          text: 'Classification CCAM',
          href: 'https://www.ameli.fr/accueil-de-la-ccam/index.php',
        },
        {
          text: 'Classification ATC',
          href: 'https://www.whocc.no/atc_ddd_index/',
        },
        {
          text: 'Classification CISP2',
          href: 'https://esante.gouv.fr/sites/default/files/media_entity/documents/cgts_sem_cisp_fiche-detaillee.pdf',
        },
        {
          text: 'Classification internationale des maladies (CIM-10)',
          href: 'https://icd.who.int/browse10/2008/fr',
        },
        {
          text: 'Conditions générales d’utilisation',
          to: { name: BASE_ROUTE_NAMES.TERMS_OF_USE },
        },
        {
          text: 'Installation intellio next',
          href: 'https://www.intellionext.fr/support/installation/',
        },
        {
          text: 'Téléchargement du connecteur DmpConnect-JS2 - Icanopée',
          href: 'https://www.icanopee.fr/telechargement-du-connecteur-dmpconnect-js2/',
        },
        {
          text: 'Portabilité des données de santé',
          to: { name: ROUTE_NAMES.DOCUMENTATION },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser', 'getCurrentUserType', 'getCurrentUserGender']),
    isPartnerAccess () {
      return isPartnerAccess();
    },
  },
  watch: {
    $route () {
      this.isDropDownActive = false;
    },
  },
  methods: {
    ...mapActions('auth', { accountLogout: 'logout' }),
    ...mapMutations('app', ['SET_SNACK']),
    async logout () {
      try {
        const authPayload = localStorage.getItem('authPayload');
        if (authPayload) {
          const { data } = await postToAPI('/api/logout', { refresh_token: JSON.parse(authPayload)?.refresh_token });
          this.SET_SNACK({ message: data['hydra:description'] });
        }
      } finally {
        this.accountLogout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  position: relative;

  &__menu {
    border-radius: 6px;
    background-color: white;
    margin-top: map-get($spacers, 2);

    &__header {
      padding: map-get($spacers, 4);
      gap: map-get($spacers, 4);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    ::v-deep {
      .v-list-item {
        display: flex;
        align-items: center;
        min-height: 34px;
        gap: map-get($spacers, 4);
        font-size: 13px;
        font-weight: 600;

        &:after {
          display: none;
        }
      }

      .v-list-group {
        &__header {
          &__prepend-icon {
            min-width: 0;
            align-self: center;
            margin: 0 !important;
          }

          &__append-icon {
            margin-left: auto !important;
          }
        }

        &__items .v-list-item {
          padding-left: map-get($spacers, 4) * 3;
          font-weight: 400;
          min-height: 28px;
        }
      }
    }
  }

  &__user-email {
    font-size: 12px;
    color: var(--v-secondary-base);
  }

  &__is-admin {
    display: flex;
    align-items: center;
    background-color: var(--v-secondary-base);
    color: white;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: map-get($spacers, 1) map-get($spacers, 2);
    border-radius: 3px;

    &-icon {
      color: inherit;
    }
  }
}
</style>