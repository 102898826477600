<template>
  <div class="patient-search">
    <app-api-autocomplete
      url="/api/patients"
      item-value-field="@id"
      item-text-field="familyName"
      :item-model-class="Patient"
      clearable
      class="mb-4"
      label="Rechercher par nom, prénom, date de naissance..."
      :min-search-length="minimumSearchLength"
      :items-per-page="itemPerPage"
      :additional-parameters="{ archived: false, isDeceased: false }"
      @selected="onSelect"
    >
      <template #item="{ data }">
        <patient-details
          small
          show-birth-informations
          complete-name-informations
          :show-keywords="false"
          :show-anonymized-identifier="false"
          :show-pictograms="false"
          :patient="data.item"
          class="py-2"
        >
          <template #sub-content>
            <section>
              <v-list-item-subtitle
                v-if="getMobile(data.item)"
                class="mt-1"
              >
                <span class="d-flex align-center">
                  <v-icon class="mr-2 lighten-text">fa-mobile-alt</v-icon>
                  {{ getMobile(data.item) }}
                </span>
              </v-list-item-subtitle>
            </section>
          </template>
        </patient-details>
      </template>
    </app-api-autocomplete>
    <create-patient-button
      avoid-redirection
      hide-label
      @click="openPatientFormModal"
    />
    <app-modal-patient-form
      :is-open.sync="isPatientFormModalOpen"
      @patient-set="selectPatient"
    />
  </div>
</template>

<script>
import { CONTACT_POINT_LABELS } from '@/constants';
import Patient from '@/modules/patient/models/Patient';

import AppApiAutocomplete from '@/components/ui/form/apiAutocomplete/AppApiAutocomplete.vue';
import AppModalPatientForm from '@/components/ui/modal/AppModalPatientForm.vue';
import CreatePatientButton from '@/modules/patient/components/CreatePatientButton.vue';
import PatientDetails from '@/modules/patient/components/PatientDetails.vue';



/**
 * Input autocomplete de recherche de patient
 */
export default {
  name: 'PatientSearch',
  components: {
    PatientDetails,
    AppApiAutocomplete,
    AppModalPatientForm,
    CreatePatientButton,
  },
  props: {
    value: {
      type: Patient,
      required: false,
      default: () => new Patient(),
    },
  },
  data () {
    return {
      Patient,
      isPatientFormModalOpen: false,
      minimumSearchLength: 3,
      itemPerPage: 10,
    };
  },
  methods: {
    getMobile (item) {
      return item?.contactPoints?.find(
        el => el.label === CONTACT_POINT_LABELS.MOBILE.value,
      )?.formattedValue;
    },
    async onSelect (patient) {
      this.$emit('input', patient);
    },
    openPatientFormModal () {
      this.isPatientFormModalOpen = true;
    },
    selectPatient (patient) {
      this.onSelect(patient);
      this.isPatientFormModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-search {
  display: flex;
  align-items: center;

  &__patient-civility {
    white-space: normal;
  }

  &__no-data-label {
    font-size: 13px;
    font-weight: 500;
  }
}

.lighten-text {
  color: var(--v-text-lighten3);
}

@include media-md {
  .patient-search {
    &__item-content {
      max-width: 256px;
    }
  }
}
</style>