<template>
  <app-panel
    :title="correspondent.getCivilState()"
    dense
    is-flat
    is-outlined
    :actions="actions"
  >
    <p>
      <span>{{ correspondent.contact.profession }}</span>
    </p>
    <div class="more-infos">
      <app-dataset-item
        label="Spécialité"
        :value="getKnowHow()"
      />
      <app-dataset-item
        label="RPPS"
        :value="rpps"
      />
    </div>
  </app-panel>
</template>

<script>
import Correspondent from '@/modules/contact/models/NewHealthProfessional';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
/**
 * Affiche un correspondant dans un formulaire
 * Par exemple lors de la selection de correspondant à ajouter à un patient
 */
export default {
  name: 'CorrespondentFormItem',
  components: {
    AppPanel,
    AppDatasetItem,
  },
  props: {
    /**
     * Le correspondant à afficher
     */
    correspondent: {
      type: Correspondent,
      required: true,
    },
  },
  data () {
    return {
      actions: {
        remove: {
          callback: this.removeCorrespondent,
          icon: 'close',
          text: 'Retirer',
        },
      },
    };
  },
  computed: {
    rpps () {
      return this.correspondent.contact.practitionerProfile?.rpps;
    },
  },
  methods: {
    removeCorrespondent () {
      this.$emit('remove', this.correspondent);
    },
    getKnowHow () {
      return new Correspondent(this.correspondent).getKnowHow();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .panel__headline {
    margin-bottom: 0 !important;
  }
}

.more-infos {
  margin-top: 12px;
}
</style>