<template>
  <div class="login">
    <authentication-form-wrapper
      v-if="displayLoginForm"
      :title="FORM_HEADINGS.CONNECTION.title"
      :text="FORM_HEADINGS.CONNECTION.text"
      :form="LoginForm"
      submit-text="Se connecter"
    />
    <choice-divider v-if="displayLoginForm" />
    <psc-button v-if="displayLoginForm" />
    <div v-if="isPartnerAccess && !isPartnerAccessLoggedOut">
      <app-circular-loader />
      Identification partenaire en cours ... merci de patienter
    </div>
    <div v-if="isPartnerAccessLoggedOut">
      Vous avez été déconnecté de votre accès partenaire, merci de relancer un appel au service partenaire.
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import { FORM_HEADINGS } from '@/modules/authentication/constants';
import gcuService from '@/services/gcuService';
import { isPartnerAuthentication, removeLocalPartnerAccessLoggedOut, isPartnerAccessLoggedOut , getRoutePartnerAccess } from '@/services/partnerService';


import ChoiceDivider from '@/components/ui/divider/ChoiceDivider.vue';
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';
import AuthenticationFormWrapper from '@/modules/authentication/components/AuthenticationFormWrapper.vue';
import PscButton from '@/modules/authentication/components/PscButton.vue';
import LoginForm from '@/modules/authentication/forms/LoginForm.vue';

export default {
  components: {
    PscButton,
    ChoiceDivider,
    AuthenticationFormWrapper,
    AppCircularLoader,
  },
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (isPartnerAuthentication(to)) {
        removeLocalPartnerAccessLoggedOut();
        await vm.routePartnerAccess(to);
        return;
      }
      if (vm.isPscAuthentication(to)) {
        const pscLocal = vm.getPscFromLocalStorage();
        if (vm.checkStateCsrf(pscLocal.state)) {
          await vm.authenticatePSC(to.query);
          await vm.$router.push('/');
        }
      }
    });
  },
  data () {
    return {
      FORM_HEADINGS,
      LoginForm,
    };
  },
  computed: {
    ...mapGetters('auth', ['isPartnerAccess']),
    isPartnerAccessLoggedOut () {
      return isPartnerAccessLoggedOut();
    },
    displayLoginForm () {
      return ! this.isPartnerAccess && ! this.isPartnerAccessLoggedOut;
    },
  },
  methods: {
    ...mapActions('auth', ['authenticatePSC', 'authenticatePartner', 'logout_nocgu']),
    isPscAuthentication (to) {
      return (to.path === '/' && to.query?.code);
    },
    getPscFromLocalStorage () {
      return JSON.parse(localStorage.getItem('psc'));
    },
    checkStateCsrf (state) {
      return (state === this.getPscFromLocalStorage().state);
    },
    async routePartnerAccess (to) {
      const { gcu } = await this.authenticatePartner(to.query);
      if (gcuService.handleGcuValidation(gcu, () => this.logout_nocgu(), to)) {
        return;
      }
      this.$router.push(await getRoutePartnerAccess(to));
    },
  },
};
</script>
<style lang="scss" scoped>
  .login {
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
</style>