<template>
  <div class="biometric-measures">
    <div
      v-if="patient"
      data-test="biometric-measures"
    >
      <biometric-measures-part
        v-test="'biometric-measures-part'"
        :measures="getCategoryWithPatientData()"
        :patient="patient"
        no-data-text="Aucune donnée"
      />
    </div>
    <div
      v-else
      data-test="biometric-measures-skeleton"
    >
      <v-skeleton-loader
        type="list-item-three-line"
        width="200"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Measure from '@/modules/patient/models/Measure';
import Patient from '@/modules/patient/models/Patient';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

import BiometricMeasuresPart from '@/modules/patient/components/patientFile/measures/BiometricMeasuresPart.vue';


export default {
  name: 'BiometricMeasures',
  components: { BiometricMeasuresPart },
  props: {
    patient: {
      type: Patient,
      default: null,
    },
  },
  data () {
    return { biometrics: null };
  },
  computed: {
    ...mapGetters('patient', ['getHasLoadedMeasuresByPatientUuid', 'isLoadingMeasures', 'getPatientLastMeasure']),
    patientIRI () {
      return this.patient ? this.patient['@id'] : null;
    },
  },
  watch: {
    patient: {
      immediate: true,
      async handler (patient) {
        if (patient) {
          if (! this.getHasLoadedMeasuresByPatientUuid(getUUIDFromIRI(this.patientIRI)) && ! this.isLoadingMeasures) {
            await this.fetchPatientMeasures(this.patientIRI);
          }
          await this.fetchPatientIndicators(this.patientIRI);
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchPatientMeasures', 'fetchPatientIndicators']),
    ...mapGetters('auth', ['getPatientMeasureUserSettings']),
    getCategoryWithPatientData () {
      /**
       * Permet d'ajouter les données de la mesure du patient (date de relève, valeur, utilisateur) à la liste des mesures principales (poids/taille)
       */
      return this.getPatientMeasureUserSettings()
        .filter(measure => measure.enabled)
        .map(measure => ({
          ...measure,
          /**
         * Le tableau des mesures d'un patient peut etre vide, ou ne contenir que le poids ou la taille.
         * Dans ces cas, on crée une instance de mesure pour la mesure manquante parmi poids et taille
         */
          lastPatientMeasure: this.getPatientLastMeasure(this.patient, measure.type) || new Measure({ name: measure.type }),
        }));
    },
  },
};
</script>
<style lang="scss" scoped>
  .biometric-measures {
    font-size: 13px;

    &__new-measure {
      font-weight: 500;
      color: var(--v-primary-base);
      cursor: pointer;
    }
  }
</style>