import ApiEntity from '@/models/ApiEntity';
import { getFromAPI, postToAPI, putToAPI } from '@/services/api';

const ENTITY_URL = '/api/practitioner_profile_contract_partners';

/**
 * Un contrat de confiance MSS
 * Permet de bypasser la vérification INS si trusted est à true
 */
export default class TrustedSender extends ApiEntity {

  constructor (options = {}) {
    super(options);

    this['@type'] = 'PractitionerProfileContractPartner';
    this.practitionerProfile = options.practitionerProfile || null;
    this.email = options.email || null;
    this.trusted = options.trusted;
  }

  /**
   * Permet de récupérer l'ensemble des contrats de confiance depuis l'API
   * @returns {TrustedSender[]}
   */
  static async fetchAll () {
    const { data } = await getFromAPI(ENTITY_URL);
    return data['hydra:member'].map(sender => new TrustedSender(sender));
  }

  /**
   * Permet d'enregistrer un nouveau contrat de confiance
   * @returns {TrustedSender}
   */
  static async insert (trustedSender) {
    const { data } = await postToAPI(ENTITY_URL, trustedSender);
    return new TrustedSender(data);
  }

  /**
   * Permet de modifier un contrat de confiance
   * @returns {TrustedSender}
   */
  static async update (trustedSender) {
    const { data } = await putToAPI(trustedSender.getIri(), { data: trustedSender });
    return new TrustedSender(data);
  }

}