import Vue from 'vue';

import AppModalForm from '@/components/ui/modal/AppModalForm.vue';

export class Modal {
  #state = Vue.observable({
    id: 0,
    instances: {},
  });

  #refGetter = null;

  /**
   * Permet de définir un resolver capable de récupérer l'instance du composant généré
   * @returns {Object}
   */
  set refGetter (refGetter) {
    if (refGetter && this.#refGetter) {
      throw new Error('Attention, un resolver a déjà été instancié, '
        + 'cela peut provoquer certains effets de bords dans les services utilisant ce plugin');
    }
    this.#refGetter = refGetter;
  }

  getRefById (id) {
    return this.#refGetter({ id })[0];
  }

  getAll () {
    return Object.values(this.#state.instances);
  }

  append (component, props, events, id = null) {
    const currentId = id ?? this.#state.id;
    const payload = {
      id: currentId,
      component,
      props,
      events,
    };
    Vue.set(this.#state.instances, currentId, payload);
    this.#state.id += 1;
    return { id: currentId };
  }

  appendDestroyOnClose (component, props, events) {
    const currentId = this.#state.id;
    return this.append(component, props, {
      ...events,
      close: async () => {
        await this.destroy(currentId);
      },
    }, currentId);
  }

  appendModalForm (props, triggerFunction) {
    const currentId = this.#state.id;
    return this.append(AppModalForm, {
      isOpened: true,
      scrollable: false,
      ...props,
    }, {
      close: async (event) => {
        if (props.mustTriggerFunction) {
          triggerFunction(event);
        }
        await this.destroy(currentId);
      },
      cancel: async (event) => {
        if (props.mustTriggerFunction) {
          triggerFunction(event);
        }
        await this.destroy(currentId);
      },
      submitSuccess: async (event) => {
        if (triggerFunction) {
          triggerFunction(event);
        }
        await this.destroy(currentId);
      },
    }, currentId);
  }

  setProps (id, props) {
    if (this.#state.instances[id]) {
      Object.keys(props).forEach(prop => Vue.set(this.#state.instances[id].props, prop, props[prop]));
    }
  }

  destroy (id) {
    Vue.delete(this.#state.instances, id);
  }
}

export default new Modal();