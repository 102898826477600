import ApiFile from '@/models/ApiFile';
import NovaTools from '@/nova-tools/NovaTools';

export default class PatientHealthSummaryDocument extends ApiFile {
  constructor (options = {}) {
    super(options);
    this.name = options.name || null;
    this.patient = options.patient || null;
    this.dmpUniqueId = options.dmpUniqueId || null;
  }

  /**
   * Retourne un texte au format "Synthèse du" suivie de la date au format "dd MMM yyyy · HH:mm"
   * @returns {String}
   */
  getTitle () {
    return `Synthèse du ${NovaTools.dates.format(this.createdAt, 'dd MMM yyyy · HH:mm')}`;
  }
}
