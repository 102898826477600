import { trustedTypes } from 'trusted-types';
import Vue from 'vue';

import { logger } from '@/services/logger';

const plugin = {
  install (Vue) {
    if ('trustedTypes' in window) {
      Vue.prototype.$trustedPolicy = trustedTypes.createPolicy('vue#default', {
        createHTML: (input) => input,
        createScript: (input) => input,
        createScriptURL: (input) => input,
      });

      window.trustedTypes.createPolicy('default', {
        createHTML: string => string,
        createScriptURL: string => string,
        createScript: string => string,
      });

    } else {
      logger.warn('Trusted Types are not supported in this browser.');
    }
  },
};

Vue.use(plugin);