<template>
  <v-sheet
    class="availability-details pa-3"
    color="blue-grey lighten-2"
    width="100%"
  >
    <div class="d-flex flex-column flex-md-row">
      <h3 class="subtitle-3">
        {{ `Disponibilité ${hasRecurrence ? 'récurrente' : 'ponctuelle'}` }}
      </h3>
      <span
        v-if="availability.setting.title"
        v-test="'availability-details-title'"
        class="secondary--text ml-0 ml-md-2"
      >
        {{ availability.setting.title }}
      </span>
    </div>
    <div class="d-flex align-start align-md-center flex-column flex-md-row">
      <p
        v-test="'availability-recurrence'"
        class="availability-details__recurrence mb-2 mb-md-0 mr-md-12"
      >
        {{ hasRecurrence ? availability.toString() : interval }}
      </p>
      <span
        v-test="'availability-details-schedule'"
        class="availability-details__schedule"
      >
        <app-icon
          icon="schedule"
          class="mr-2"
        />
        <span>{{ availability.setting.schedule.name }}</span>
      </span>
      <div
        v-if="availability.setting.appointmentMotives.length"
        v-test="'availability-details-motives'"
        class="mt-2 ml-0 mt-md-0 ml-md-8"
      >
        <app-tag
          v-for="motive in availability.setting.appointmentMotives"
          :key="motive['@id']"
          :color="motive.color"
          x-small
          class="mr-3"
        >
          {{ motive.name }}
        </app-tag>
      </div>
      <span
        v-else
        v-test="'availability-details-any-appointment-motive'"
        class="availability-details__any-appointment-motive mt-2 ml-0 mt-md-auto ml-md-8"
      >
        Tous motifs acceptés.
      </span>
    </div>
  </v-sheet>
</template>

<script>
import Availability from '@/modules/agenda/models/events/Availability';
import { format } from '@/utils/functions/dates';

import AppTag from '@/components/ui/tag/AppTag.vue';



export default {
  name: 'AvailabilityDetails',
  components: { AppTag },
  props: {
    availability: {
      type: Availability,
      required: true,
    },
  },
  computed: {
    hasRecurrence () {
      return this.availability.setting.recurrencePattern;
    },
    interval () {
      const date = new Date(this.availability.setting.date);
      const dayFormat = date.getDate() === 1 ? 'do' : 'd';
      const interval = this.availability.setting.intervals[0];

      return `Le ${format(date, `${dayFormat} MMM yyyy`)} De ${interval.start} à ${interval.end}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.availability-details {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  @include media-md {
    min-height: 60px;
  }

  &__recurrence,
  &__schedule,
  &__any-appointment-motive {
    font-size: 12px;
  }

  &__schedule {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 600;
  }
}
</style>