<template>
  <div>
    <app-button
      v-test="'add-note-link'"
      outlined
      text
      @click="openNoteDrawer"
    >
      <app-icon
        icon="note"
        :class="{ 'mr-2': !isMobile }"
      />
      <template v-if="! isMobile">
        Ajouter une note
      </template>
    </app-button>
    <app-modal-form
      v-test="'note-drawer'"
      title="Ajouter une note"
      :form="NoteForm"
      :value="editingNote"
      :patient="patient"
      auto-focus
      @submitSuccess="onNoteSubmited"
      @cancel="onNoteCanceled"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote';
import Patient from '@/modules/patient/models/Patient';

import AppModalForm from '@/components/ui/modal/AppModalForm.vue';
import NoteForm from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/forms/NoteForm.vue';



export default {
  name: 'MedicalHistory',
  components: { AppModalForm },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      NoteForm,
      editingNote: null,
    };
  },
  computed: { ...mapState('app', ['isMobile']) },
  methods: {
    openNoteDrawer () {
      this.editingNote = new MedicalHistoryNote({ patient: this.patient.getIri() });
    },
    onNoteSubmited () {
      this.editingNote = null;
    },
    onNoteCanceled () {
      this.editingNote = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.medical-history-actions {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 4);
}
</style>@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/forms/NoteForm.vue